module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="container container-hint">\n    <p>\n        '+
((__t=( t('collection::locations:empty:introduction') ))==null?'':__t)+
'\n    </p>\n\n    <div class="row">\n        <div class="col-10 offset-2">\n            <div class="list list-flex">\n                <div class="box box-empty box-hint">\n                    <h3>'+
((__t=( t('collection::locations:empty:drag-and-drop:header') ))==null?'':__t)+
'</h3>\n\n                    <p>'+
((__t=( t('collection::locations:empty:drag-and-drop:body', {storagesUrl: item.storagesUrl}) ))==null?'':__t)+
'</p>\n                    <div class="ico-wrapper">\n                        <span class="ico ico-white ico-border ico-lg">!</span>\n                    </div>\n                </div>\n                <div class="box box-empty box-hint">\n                    <h3>'+
((__t=( t('collection::locations:empty:manage-mode:header') ))==null?'':__t)+
'</h3>\n                    <p>'+
((__t=( t('collection::locations:empty:manage-mode:body', {catalogUrl: item.catalogUrl}) ))==null?'':__t)+
'</p>\n                    <div class="ico-wrapper">\n                        <span class="ico ico-white ico-border ico-lg">!</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';
}
return __p;
};