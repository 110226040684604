module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(view.getTitle()))==null?'':_.escape(__t))+
'</h3>\n\n<form>\n    <fieldset class="filter-range">\n        <label>\n            '+
((__t=(t('collection::objects:filter:range-slider:from:label')))==null?'':_.escape(__t))+
' '+
((__t=(item.unit))==null?'':_.escape(__t))+
'\n            <input type="text" class="component-range-start" value="'+
((__t=( view.getRangeMin() ))==null?'':_.escape(__t))+
'" />\n        </label>\n        <label>\n            '+
((__t=(t('collection::objects:filter:range-slider:to:label')))==null?'':_.escape(__t))+
' '+
((__t=(item.unit))==null?'':_.escape(__t))+
'\n            <input type="text" class="component-range-end" value="'+
((__t=( view.getRangeMax() ))==null?'':_.escape(__t))+
'" />\n        </label>\n        <button class="clear-range"></button>\n    </fieldset>\n    <fieldset class="range-slider"></fieldset>\n</form>';
}
return __p;
};