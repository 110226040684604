module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="modal-body">\n    <button type="button" class="btn" data-action="close">'+
((__t=( icon('close-light') ))==null?'':__t)+
' Close</button>\n\n    <div class="image-slider">\n        ';
 for(var i = 0; i < item.images.length; i++){ 
__p+='\n            ';
 if (item.images[i].restricted !== true){  
__p+='\n                ';
 if (item.images[i].videoUrl) { 
__p+='\n                    <div class="video">\n                        <div class="image-box" data-ratio="'+
((__t=( item.images[i].aspectRatio ))==null?'':_.escape(__t))+
'">\n                            <video src="'+
((__t=(item.images[i].videoUrl))==null?'':_.escape(__t))+
'" muted autoplay loop poster="'+
((__t=(model.getImageUrl(imageSize('detail-zoom'), i)))==null?'':_.escape(__t))+
'">\n                                '+
((__t=( responsiveImage(item.model.getImage(i), imageSize('detail-zoom'), viewportSize('detail-zoom'), '', 'zoom-image') ))==null?'':__t)+
'\n                            </video>\n                            <button type="button" class="btn btn-play" data-action="play-video">'+
((__t=( icon('play') ))==null?'':__t)+
'</button>\n                        </div>\n                    </div>\n                ';
 } else { 
__p+='\n                    <div class="image loading" data-idx="'+
((__t=( i ))==null?'':_.escape(__t))+
'">\n                        <div class="image-box" data-ratio="'+
((__t=( item.images[i].aspectRatio ))==null?'':_.escape(__t))+
'">\n                            '+
((__t=( responsiveImage(item.model.getImage(i), imageSize('detail-zoom'), viewportSize('detail-zoom'), '', 'zoom-image') ))==null?'':__t)+
'\n                        </div>\n                    </div>\n                ';
 } 
__p+='\n            ';
 } 
__p+='\n        ';
 } 
__p+='\n    </div>\n\n    ';
 if (item.images.length > 1) { 
__p+='\n        <button type="button" class="slide-prev">'+
((__t=( icon('prev-big') ))==null?'':__t)+
'</button>\n        <button type="button" class="slide-next">'+
((__t=( icon('next-big') ))==null?'':__t)+
'</button>\n    ';
 } 
__p+='\n</div>\n';
}
return __p;
};