module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h4>\n	';
 if (item.buyerId === App.user.id) { 
__p+='\n		Buying\n	';
 } 
__p+='\n\n	';
 if (item.sellerId === App.user.id) { 
__p+='\n		Selling\n	';
 } 
__p+='\n</h4>\n<div class="box box-object-object">\n	';
 if (item.object.get('objectId')) { 
__p+='\n	    <h5 class="object-id">'+
((__t=(item.object.get('objectId')))==null?'':_.escape(__t))+
'</h5>\n	';
 } 
__p+='\n\n	<div class="headline-wrapper">\n	    <h4>'+
((__t=( item.headline))==null?'':__t)+
'</h4>\n	    <h4 class="subline">'+
((__t=( item.subheadline))==null?'':__t)+
'</h4>\n	</div>\n\n	<div class="image">\n	    <div class="image-3-2">\n	        <div>\n	            '+
((__t=( responsiveImage(item.object.getImage(0), imageSize('grid-object'), viewportSize('grid-object'), item.object.get('properName'), 'grid-image') ))==null?'':__t)+
'\n	        </div>\n	    </div>\n	</div>\n\n	';
 if (item.object.get('forSale') && item.object.get('retailPrice')) { 
__p+='\n		<div class="price">\n			'+
((__t=(item.object.getTextValue('retailPrice')))==null?'':_.escape(__t))+
'\n		</div>\n	';
 } 
__p+='\n</div>\n\n';
 if(item.status === 'new') { 
__p+='\n	';
 if (item.buyerId === App.user.id) { 
__p+='\n		<p>\n			You want buy the item above from '+
((__t=(item.conversation.getUserNameById(item.sellerId)))==null?'':_.escape(__t))+
'. Please negotiate your payment arrangements and shipping charges.\n		</p>\n		<p>\n			<button type="button" data-action="withdraw-offer" class="btn btn-secondary">Withdraw Offer</button>\n		</p>\n	';
 } 
__p+='\n\n	';
 if (item.sellerId === App.user.id) { 
__p+='\n		<p>\n			'+
((__t=(item.conversation.getUserNameById(item.buyerId)))==null?'':_.escape(__t))+
' wants to buy the item above. Please negotiate your payment arrangements and shipping charges.\n			If you have shipped the item, you can complete the sale by clicking the button below.\n		</p>\n\n		<p>\n			<button type="button" data-action="complete-sale" class="btn btn-primary">Complete Sale</button>\n			<button type="button" data-action="decline-offer" class="btn btn-secondary">Decline Offer</button>\n		</p>\n	';
 } 
__p+='\n';
 } 
__p+='\n\n';
 if(item.status === 'declined') { 
__p+='\n	';
 if (item.buyerId === App.user.id) { 
__p+='\n		<p>\n			The seller declined your offer or sold the item to another party.\n		</p>\n	';
 } 
__p+='\n\n	';
 if (item.sellerId === App.user.id) { 
__p+='\n		<p>\n			You declined this offer.\n		</p>\n	';
 } 
__p+='\n';
 } 
__p+='\n\n';
 if(item.status === 'withdrawn') { 
__p+='\n	';
 if (item.buyerId === App.user.id) { 
__p+='\n		<p>\n			You withdrew your offer.\n		</p>\n	';
 } 
__p+='\n\n	';
 if (item.sellerId === App.user.id) { 
__p+='\n		<p>\n			The buyer withdrew from the offer.\n		</p>\n	';
 } 
__p+='\n';
 } 
__p+='\n\n';
 if(item.status === 'completed') { 
__p+='\n	';
 if (item.buyerId === App.user.id) { 
__p+='\n		<p>\n			The sale is completed.\n		</p>\n	';
 } 
__p+='\n\n	';
 if (item.sellerId === App.user.id) { 
__p+='\n		<p>\n			The sale is completed.\n		</p>\n	';
 } 
__p+='\n';
 } 
__p+='\n';
}
return __p;
};