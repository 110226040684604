module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (model.hasImage()){ 
__p+='\n    <div class="nav-set-image">\n        <img src="'+
((__t=(model.getImageUrl(imageSize('thumbnailFixed'))))==null?'':_.escape(__t))+
'" />\n    </div>\n';
 } else { 
__p+='\n    <div class="nav-set-image nav-set-image-placeholder"></div>\n';
 } 
__p+='\n\n<h4>'+
((__t=( item.name ))==null?'':_.escape(__t))+
'</h4>';
}
return __p;
};