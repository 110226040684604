module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="'+
((__t=(model.get('conversationUrl')))==null?'':_.escape(__t))+
'" class="open-conversation">\n    <div class="posts-indicator '+
((__t=(when(item.unreadPostsCount, 'unread-posts')))==null?'':_.escape(__t))+
'" title="'+
((__t=(item.unreadPostsCount))==null?'':_.escape(__t))+
'">\n        ';
 if(item.unreadPostsCount >= 9) { 
__p+='\n            <span class="unread-posts-count">9+</span>\n        ';
 } else if(item.unreadPostsCount >= 1) { 
__p+='            \n            <span class="unread-posts-count">'+
((__t=(item.unreadPostsCount))==null?'':_.escape(__t))+
'</span>\n        ';
 } 
__p+='\n    </div>\n\n    <div class="contact-image">\n        <span class="contact-initials">'+
((__t=(_.initials(item.name || item.email)))==null?'':_.escape(__t))+
'</span>\n        '+
((__t=( responsiveImage(item.profilePicture, imageSize('thumbnailFixed'), viewportSize('grid-object'), item.name || item.email, 'grid-image') ))==null?'':__t)+
'\n    </div>\n\n    <div class="infos">\n        <h2 class=\'contact-name\'>'+
((__t=(item.name || item.email))==null?'':_.escape(__t))+
'</h2>\n\n        ';
 if (model.get('conversationLastMessage')) { 
__p+='\n            <div class="posts-last-message '+
((__t=(when(item.unreadPostsCount, 'is-unread')))==null?'':_.escape(__t))+
'">\n                '+
((__t=( model.get('conversationLastMessage') ))==null?'':_.escape(__t))+
'\n            </div>\n        ';
 } 
__p+='\n    </div>\n\n\n    ';
 if(item.isActive) { 
__p+='\n        '+
((__t=( icon('next')))==null?'':__t)+
'\n    ';
 } 
__p+='\n</a>\n\n<div class="extras">\n    ';
 if(item.accepted === 'INC') { 
__p+='\n        <button data-action="accept-contact" class="btn-primary btn-accept-contact">'+
((__t=(t('community::button:label:friend-request:accept')))==null?'':_.escape(__t))+
'</button>\n    ';
 } 
__p+='\n\n    <div class="actions">\n        <button class="btn btn-secondary btn-caret" data-action="show-actions">\n            '+
((__t=(t('community::contact:actions:label')))==null?'':_.escape(__t))+
'\n        </button>\n\n        <div class="action-layer">\n            <h3>'+
((__t=( t('community::group:action:headline') ))==null?'':_.escape(__t))+
'</h3>\n            <button type="button" class="btn btn-close">'+
((__t=( icon('close-light') ))==null?'':__t)+
'</button>\n            <ul>\n                ';
 if(item.accepted === 'INC') { 
__p+='\n                    <li>\n                        <button data-action="accept-contact" class="btn-accept-contact btn-tertiary">\n                            '+
((__t=(t('community::button:label:friend-request:accept')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small')))==null?'':__t)+
'\n                        </button>\n                    </li>\n                ';
 } 
__p+='\n\n                <li>\n                    <button data-action="remove-contact" class="btn-remove-contact btn-tertiary">\n                        '+
((__t=( t('community::button:label:friend-request:remove') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small')))==null?'':__t)+
'\n                    </button>\n                </li>\n\n            </ul>\n        </div>\n    </div>\n</div>';
}
return __p;
};