'use strict';

var Model = require('base/Colido.Model');

var StorageModel = Model.extend({
    api: '/storages',

    urlRoot: false, //use api from collection. Pass {collection: ...} as option when initializing model. See commandSendMessageToConversation()

    defaults: function(){
        return {
            name: '',
            storType: 'room',
            isRfid: 0,
            isRoot: 1,
            itemCount: 0,
            items: [],
            itemsRecursive: [],
            parent: null,
            path: '',
            pathShortened: '',
            sortOrder: null,
            _empty: false,
        };
    },

    customAttributes: {
        subStorage: function() {
            return this.hasSubStorage() ? 1 : 0;
        },
    },

    /**
     * Indicates, if the storage, has substorages
     * @returns {boolean} Tue, if the item has substorages
     */
    hasSubStorage: function() {
        return this.collection.where({parent: this.get('id')}).length > 0 || (this.get('storages') && this.get('storages').length > 0);
    },

    /**
     * Returns all unique items, that are in items and in itemsRecursive
     * @returns {array} Array with the unique ids
     */
    getItemsRecursive: function() {
        return _.union(this.get('items'), this.get('itemsRecursive'));
    },

    addObject: function(objectId) {
        var oldItems = _.clone(this.get('items'));
        oldItems.push(objectId);
        this.set({'items': _.uniq(oldItems)})

        this.addObjectRecursive(objectId);
    },

    addObjectRecursive: function(objectId) {
        var oldItems = _.clone(this.get('itemsRecursive'));
        oldItems.push(objectId);
        this.set({'itemsRecursive': _.uniq(oldItems)})

        var parent = this.getParent();
        if (parent) {
            parent.addObjectRecursive(objectId);
        }
    },

    removeObject: function(objectId) {
        var oldItems = _.clone(this.get('items'));

        this.set({'items': _.without(oldItems, objectId)});

        this.removeObjectRecursive(objectId);
    },

    removeObjectRecursive: function(objectId) {
        var oldItems = _.clone(this.get('itemsRecursive'));

        this.set({'itemsRecursive': _.without(oldItems, objectId)});

        var parent = this.getParent();
        if (parent) {
            parent.removeObjectRecursive(objectId);
        }
    },

    hasParent: function() {
        return !!this.get('parent');
    },

    getParent: function() {
        var parent = null;

        if (this.hasParent()) {
            parent = this.collection ? this.collection.get(this.get('parent')) : null;
        }

        return parent;
    },

    /**
     * Returns a proper breadcrumb for the storages
     * @returns {string} The breadcrumb of the storage
     */
    getBreadcrumb: function() {
        var breadcrumb = [];

        // Add parents to breadcrumb
        var parentId = this.get('parent');
        if (parentId) {
            breadcrumb.push(this.collection.get(parentId).getBreadcrumb());
        }

        // Add self
        breadcrumb.push(this.get('name'));

        return breadcrumb.join(' / ');
    },
});

module.exports = StorageModel;