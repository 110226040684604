'use strict';

module.exports = {
    localization: {
        'thousand:separator': '.',
        'decimal:separator': ',',
    },

    global: {
        'api:fetch-error': '{data} konnte nicht vom Server geladen werden',

        'lang-de': 'Deutsch',
        'lang-en': 'Englisch',

        // Units sizes
        'units-sizes:cm:short': 'cm',
        'units-sizes:cm:long':  'Zentimeter',
        'units-sizes:inch:short': '"',
        'units-sizes:inch:long':  'Zoll',
        'units-sizes:m:short': 'm',
        'units-sizes:m:long':  'Meter',
        'units-sizes:mm:short': 'mm',
        'units-sizes:mm:long':  'Millimeter',

        // Units weights
        'units-weights:g:short': 'g',
        'units-weights:g:long': 'Gramm',
        'units-weights:kg:short': 'kg',
        'units-weights:kg:long': 'Kilogramm',
        'units-weights:oz:short': 'oz',
        'units-weights:oz:long':  'Unzen',
        'units-weights:ct:short': 'kt',
        'units-weights:ct:long':  'Karat',
        'units-weights:lbs:short': 'lbs',
        'units-weights:lbs:long':  'Pfund und Unzen',

        // Currencies
        'currencies:chf:short': 'CHF',
        'currencies:chf:long': 'Schweizer Franken',
        'currencies:chf:symbol': 'CHF',
        'currencies:chf:amount': '{amount} CHF',

        'currencies:eur:short': 'EUR',
        'currencies:eur:long': 'Euro',
        'currencies:eur:symbol': '€',
        'currencies:eur:amount': '{amount} €',

        'currencies:gbp:short': 'GBP',
        'currencies:gbp:long': 'Britische Pfund',
        'currencies:gbp:symbol': '£',
        'currencies:gbp:amount': '{amount} £',

        'currencies:usd:short': 'USD',
        'currencies:usd:long': 'US Dollar',
        'currencies:usd:symbol': '$',
        'currencies:usd:amount': '{amount} $',

        'currencies:unknown:short': '¤',
        'currencies:unknown:long':  '¤',
        'currencies:unknown:symbol':  '¤',
        'currencies:unknown:amount': '{amount} ¤',

        // Time and Dates
        'objects-selected:count': {
            '0': 'Keine Objekte ausgewählt',
            '1': '{n} Objekt ausgewählt',
            'n': '{n} Objekte ausgewählt',
        },

        // Other
        'objects:count': {
            '0': '{n} Objekte',
            '1': '{n} Objekt',
            'n': '{n} Objekte',
        },

        'month': {
            '1': 'Januar',
            '2': 'Februar',
            '3': 'März',
            '4': 'April',
            '5': 'Mai',
            '6': 'Juni',
            '7': 'Juli',
            '8': 'August',
            '9': 'September',
            '10': 'Oktober',
            '11': 'November',
            '12': 'Dezember',
            'n': 'Unbekannter Monat',
        },

        'relative-date:today': 'Heute',
        'relative-date:yesterday': 'Gestern',

        'object-types:colido-item-set': 'Set',
        'object-types:colido-storage': 'Standort',
        'object-types:colido-catalog': 'Katalog',
        'object-types:colido-object': 'Objekt',

        'modal:button:close': 'Schließen',
        'modal:button:cancel': 'Abbrechen',
        'modal:button:done': 'Fertig',

        'action:share-item': 'Freigeben',
        'item-shared:short': 'Freigegeben',
        'item-shared:long': 'Für andere freigegeben',

        'continents:africa': 'Afrika',
        'continents:asia': 'Asien',
        'continents:australia': 'Australien',
        'continents:europe': 'Europa',
        'continents:north-america': 'Nordamerika',
        'continents:south-america': 'Südamerika',

        'messenger:button:try-again': 'Noch mal',
        'messenger:button:cancel': 'Abbrechen',

        'button:scroll-back-to-top': 'An den Anfang scrollen',
    },

    'drag-n-drop': {
        'trash': 'Papierkorb',
        'remove-from-set': 'Aus Set entfernen',
        'add-to-set': 'Zu Set hinzufügen',
    },

    '404': {
        'headline': 'Die Seite konnte nicht gefunden werden',
        'subheadline': 'Dies tut uns Leid',
        'text': 'Leider ist die angeforderte Seite zur Zeit oder grundsätzlich nicht erreichbar',
    },

    'file-upload': {
        'upload-a-file:placeholder': 'Datei auswählen ...',
        'upload-file': 'Datei hochladen',
        'choose-from-disk': 'Von Festplatte wählen',
    },

    'navigation': {
        'logout': 'Abmelden',
    },

    base: {
        'file-upload:error:500': 'Auf dem Server ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut',
        'file-upload:unknown-error': 'Leider ist ein Fehler aufgetreten',
    },

    user: {
        'validate:name-required': 'Bitte geben Sie Ihren Namen ein.',
        'validate:invalid-email': 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
    },

    'signup': {
        'headline': 'Jetzt kostenlos registrieren',
        'subline': 'Und alle Premium-Funktionen 30 Tage gratis testen!',
        'claim': 'Collection Management rethought',
        'placeholder-email': 'E-Mail-Adresse',
        'placeholder-fullname': 'Benutzername',
        'placeholder-password': 'Wähle ein Passwort',
        'signup': 'Konto erstellen',
        'cancel': 'Abbrechen',
        'pricing-hint': 'Übersicht aller Angebote und Preise anzeigen',
        'pricing-url': 'https://colido.de/de/pricing',
        'signup-success': 'Ihr Konto wurde erfolgreich angelegt.',
        'havent-account': 'Du hast noch keinen Colido-Account?',
        'show-password': 'Anzeigen',
        'hide-password': 'Verstecken',
        'to-the-signup': 'Kostenlos registrieren',
        'signup-terms-agree': 'Hiermit akzeptiere ich die <a href="http://www.colido.de/downloads/Allgemeine_Geschaeftsbedingungen-Colido-de.pdf" target="_blank">AGB</a> und die <a href="http://www.colido.de/downloads/privacy-colido-en.pdf" target="_blank">Datenschutzbestimmungen</a>',

        'password:bad': 'Passwort ist unsicher',
        'password:moderate': 'Passwort ist ok',
        'password:good': 'Passwort ist sicher',

        'tipsy:password': 'Bitte verwenden Sie ein sicheres Passwort<br /><br /> Ein sicheres Passwort besteht aus mindestens 6 Zeichen und beinhaltet Zahlen und Sonderzeichen, sowie Groß- und Kleinbuchstaben.',

        'unknown-error': 'Ein unbekannter Fehler ist aufgtreten',
        'server-errors:email-required': 'Bitte geben Sie eine E-Mail-Adresse an',
        'server-errors:fullname-required': 'Bitte geben Sie Ihren vollständigen Namen ein',
        'server-errors:password-required': 'Bitte geben Sie ein Passwort ein',
        'server-errors:email-already-in-use': 'Die eingegebene E-Mail-Adresse ist bereits registriert',
        'server-errors:unknown-error': 'Ein unbekannter Fehler ist aufgtreten. Bitte versuchen Sie es erneut',
        'server-errors:email-invalid': 'Die eingegebene E-Mail-Adresse ist nicht gültig',
        'server-errors:password-is-to-short': 'Das eingegebene Passwort ist zu kurz',
    },

    'signup-token': {
        'headline': 'Anmeldebestätigung',
        'please-wait': 'Bitte warten ...',
        'back-to-the-loginform': 'Zurück zum Login',
        'no-token-found': 'Der Sicherheitsschlüssel ist nicht gültig', //@todo Make user understandable
        'error': 'error!', //@todo
        'ok': 'ok!', //@todo
    },

    login: {
        'headline': 'Login',
        'placeholder-email': 'E-Mail-Adresse eingeben',
        'placeholder-password': 'Passwort eingeben',
        'sing-in-button-label': 'Login',
        'forgot-password': 'Passwort vergessen?',
        'invalid-email': 'Bitte gib deine E-Mail-Adresse ein',
        'password-required': 'Bitte gib dein Passwort ein',
        'already-registered': 'Du hast bereits einen Account?',
        'to-the-login': 'Zum Login',
        'unknown-error': 'Leider ist ein Fehler aufgetreten',
        'your-interim-password': 'Dein Interims-Passwort',
    },

    'forgot-password': {
        'headline': 'Passwort vergessen?',
        'subline': 'Kein Problem! Wir senden Ihnen eine E-Mail zu, mit deren Hilfe Sie das Passwort zurücksetzen können.',
        'placeholder-email': 'E-Mail eingeben',
        'submit': 'Senden',
        'please-wait': 'Bitte warten ...',
        'invalid-email': 'Bitte geben Sie Ihre E-Mail-Adresse ein',
        'cancel': 'Abbrechen',
        'your-new-password': 'Ihr neues Passwort:',
        'back-to-the-loginform': 'Zurück zum Login',
        'no-token-found': 'Authentifizierungsschlüssel nicht gefunden.',
        'success': 'Sie erhalten in Kürze eine E-Mail',
        'unknown-error': 'Leider ist ein Fehler aufgtreten',
    },

    'forgot-password-token': {
        'headline': 'Ihr Interim-Passwort',
        'please-wait': 'Bitte warten ...',
        'info': 'Bitte notieren Sie sich ihr Interim-Passwort. Aus Sicherheitsgründen sollten Sie im Anschluss ihr Passwort ändern. Hierzu benötigen Sie das Interim-Passwort ein weiteres Mal.',
        'go-to-login': 'Zum Login',
    },

    'print-modal': {
        'modal-title': 'Liste erstellen',
        'advanced-modal-title': 'Listenkonfiguration',
        'modal-subtitle:object-count': {
            0: '{n} Objekte',
            1: '{n} Objekt',
            n: '{n} Objekte',
        },
        'generate-button': 'Erstellen',
        'abort-button': 'Abbrechen',
        'form:single': 'Eins',
        'form:fit': 'Mehrere',
        'form:report-type': 'Listentyp',
        'form:report-type:summary': 'Zusammenfassung',
        'form:report-type:detailed': 'Detailliert',
        'form:report-type:appraisal': 'Gutachten ',
        'form:title': 'Listentitel',
        'form:title-placeholder': 'Listentitel eingeben',
        'form:items-per-page': 'Objekte pro Seite',
        'form:print-summery': 'Zusammenfassung',
        'form:print-summery-yes': 'Ja',
        'form:print-summery-no': 'Nein',
        'form:page-size': 'Papierformat',
        'form:page-size:a4': 'DIN A4',
        'form:page-size:letter': 'US Letter',
        'form:printing-options': 'Druckoptionen',
        'form:printing-options:hint': 'Bitte wählen Sie alle Informationen, die Ihre Liste enthalten soll. Alle anderen Felder werden nicht mit ausgegeben.',

        'messages-success:pdf:message': 'Ihre Liste {filename} steht zum Download bereit.',
        'messages-success:pdf:download-button': 'Liste downloaden',
        'messages-progress:pdf:message': 'Ihre Liste wird nun erstellt. Sie werden benachrichtigt, sobald die Liste zum Download bereit steht.',
        'messages-error:pdf:message': 'Leider konnte die gewünschte Liste nicht erstellt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',

        'messages-progress:csv:message': 'Ihr Export wird nun erstellt. Sie werden benachrichtigt, sobald der Export zum Download bereit steht.',
        'messages-success:csv:message': 'Ihr Export {filename} steht zum Download bereit.',
        'messages-success:csv:download-button': 'Export downloaden',
        'messages-error:csv:message': 'Leider konnte der gewünschte Export nicht erstellt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
    },

    'label-print-modal': {
        'modal-title': 'Labelkonfiguration',
        'modal-subtitle:object-count': {
            0: '{n} Objekte',
            1: '{n} Objekt',
            n: '{n} Objekte',
        },
        'generate-button': 'Generate',
        'abort-button': 'Cancel',

        'messages-success:pdf:message': 'Die Label-Datei {filename} steht zum Download bereit.',
        'messages-success:pdf:download-button': 'Download',
        'messages-progress:pdf:message': 'Ihre Labels werden nun erstellt. Sie werden benachrichtigt, sobald die Labels zum Download bereit steht.',
        'messages-error:pdf:message': 'Leider konnten die gewünschten Labels nicht erstellt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
        'text:introduction': 'Wählen Sie zunächst das gewünschte Papierformat sowie das Endformat Ihrer Labels. Die Labels werden bei der Ausgabe auf dem Druckbogen formatfüllend platziert. Um das Bogenlayout zu beeinflussen, können Sie Stegbreiten einstellen oder einen Layout-Versatz zur Mitte definieren.',
        'label-headline:paper-size': 'Papierformat',
        'label:din-a4': 'DIN A4',
        'label:letter': 'Letter',
        'label:custom-format': 'Eigenes Format',
        'placeholder:paper-width': 'Breite',
        'label:paper-width': 'Breite',
        'placeholder:paper-height': 'Höhe',
        'label:paper-height': 'Höhe',
        'label-headline:labelformat': 'Labelformat',
        'placeholder:label-width': 'Breite',
        'label:label-width': 'Breite',
        'placeholder:label-height': 'Höhe',
        'label:label-height': 'Höhe',
        'label-headline:section-layout': 'Bogenlayout',
        'placeholder:horizontal-gap': 'Steg horizontal',
        'label:horizontal-gap': 'Steg horizontal',
        'placeholder:vertical-gap': 'Steg vertikal',
        'label:vertical-gap': 'Steg vertikal',
        'hint:gap': 'Um den Abstand der Labels zueinander einzustellen, definieren Sie hier horizontale und vertikale Stegbreiten',
        'placeholder:offset-horizontal': 'Versatz horizontal',
        'label:offset-horizontal': 'Versatz horizontal',
        'hint:offset': 'Das Bogenlayout wird standardmässig auf dem Druckbogen zentriert. Um das Bogenlayout auf dem Druckbogen zu verschieben, können Sie hier Angaben zum Versatz machen',
        'placeholder:vertical-offset': 'Versatz vertikal',
        'label:vertical-offset': 'Versatz vertikal',
        'label:print-lines': 'Hilfslinien drucken',
        'label:print-lines-yes': 'Ja',
        'label:print-lines-no': 'Nein',
        'hint:print-lines': 'Die Hilfslinien helfen beim anschließenden Ausschneiden der Labels',
        'label:show-price': 'Verkaufspreise ausgeben',
        'label:show-price-yes': 'Ja',
        'label:show-price-no': 'Nein',
        'label:label-type': 'Aufstelllabel',
        'label:label-type-yes': 'Ja',
        'label:label-type-no': 'Nein',
        'hint:label-type': 'Aufstelllabels werden in der Mitte gefaltet und können anschließend wie ein Tischkärtchen aufgestellt werden',
        'label:print-logo': 'Logo drucken',
        'label:print-logo-yes': 'Ja',
        'label:print-logo-no': 'Nein',
        'hint:print-logo': 'Bitte laden Sie in Ihren Profil-Einstellungen eine Logo Datei hoch',
        'label:convert-currency': 'Währungen umrechnen',
        'hint:convert-currency': 'Lassen Sie alle Verkaufspreise zu tagesaktuellen Kursen in die angegebene Währung umrechnen und auf den Labels ausgegeben',
        'option:currency:no': 'nicht umrechnen',
        'option:currency:gbp': 'in Britisches Pfund',
        'option:currency:chf': 'in Schweizer Franken',
        'option:currency:usd': 'in US Dollar',
        'option:currency:eur': 'in Euro',
        'label:conversion-type': 'Verkaufspreise runden',
        'option:conversion:up': 'aufrunden',
        'option:conversion:down': 'abrunden',
        'option:conversion:do-not': 'nicht runden',
        'option:conversion:default': 'kaufmännisch runden',
        'label:conversion-rounding': 'Runden auf',
        'option:round-to:none': 'Nachkommastellen',
        'option:round-to:1': 'Einer',
        'option:round-to:10': 'Zehner',
        'option:round-to:100': 'Hunderter',
        'hint:conversion-rounding': 'Geben Sie an, wie »glatt« die Verkaufspreise ausgegeben werden sollen. Preise unter 1000 werden maximal auf Zehner gerundet, Preise unter 100 lediglich auf Einer',
    },

    welcome: {
        'headline': 'Willkommen {name}!',
        'button:close': 'Schließen',
        'introduction': 'Vielen Dank, dass Sie ein kostenloses Colido Konto erstellt haben. Für Ihren schnellen Einstieg haben wir ein fünf-minütiges Einführungsvideo vorbereitet.',
        'info': 'Das Video finden Sie auch im Hilfebereich',
        'button:skip': 'Später anschauen',
    },

    'delete-account': {
        'headline': 'Konto löschen',
        'subline': 'Sind Sie sicher, dass Sie Ihr Colido Konto löschen möchten? Alle erstellten Daten werden ebenfalls gelöscht.',
        'no': 'Abbrechen',
        'yes': 'Konto jetzt löschen',
        'success': '<h2>Schade, dass Sie sich entschlossen haben, Ihr Konto zu löschen. Auf Wiedersehen!</h2>',
        'fail': '<h1>Leider ist ein Fehler aufgetreten.</h1><p>Bitte versuchen Sie es erneut oder senden Sie eine E-Mail an <a href="mailto:support@colido.de">support@colido.de</a>.</p>',
    },

    dashboard: {
        'label': 'Dashboard',
        'headline': 'Dashboard',
        'subline:welcome': 'Willkommen zurück {name}!',
        'button:new-object': 'Objekt erstellen',
        'button:import-database': 'Datenbank Import',

        'headline:conversations': {
            0: 'Keine Mitteilungen',
            n: 'Neue Mitteilungen',
        },
        'button:conversations': 'Colido Talk',
        'button:add-contact': 'Kontakt hinzufügen',

        'headline:collection': 'Aktuelle Objekte',
        'button:collection': 'Objekte',

        'headline:raffle': 'Für Besucher der Munich Show 2017',
        'button:raffle': 'Gewinnspiel',

        'headline:sets': 'Aktuelle Sets',
        'button:sets': 'Sets',

        'headline:storages': 'Aktuelle Standorte',
        'button:storages': 'Standorte',

        'headline:imports-in-progress': 'Aktuelle Imports',

        'headline:getting-started': 'Getting started', // Todo: Obsolte
        'text:getting-started': 'Sit back and watch our five minute introduction to our main features and the interface.', // Todo: Obsolte

        'headline:getting-started-video': 'Learn how in 5 minutes', // Todo: Obsolte
    },

    'new-object': {
        'label': 'Objekt erstellen',
    },

    settings: {
        'label': 'Einstellungen',

        // Account
        'account:label': 'Konto',
        'account:email:label': 'E-Mail-Adresse',
        'account:email:placeholder': 'E-Mail-Adresse eingeben',
        'account:name:label': 'Vollständiger Name',
        'account:name:placeholder': 'Vollständigen Namen eingeben',
        'account:password:label': 'Passwort',
        'account:password:placeholder': 'Passwort eingeben',
        'account:language:label': 'Sprache',
        'account:language:hint': 'Zur Zeit sind eine englische und eine deutsche Version nutzbar. Sobald Ihre gewählte Sprache verfügbar ist, wird Ihr System automatisch umgestellt.',
        'account:language:please-choose': 'Sprache auswählen',
        'account:button:update': 'Speichern',
        'account:button:delete-account': 'Konto löschen',
        'account:button:password-change': 'Passwort ändern',
        'account:unknown-save-error': 'Ihre Änderungen konnten leider nicht gespeichert werden',
        'account:unknown-error': 'Leider ist ein Fehler aufgetreten',
        'account:saveSuccess': 'Ihre Änderungen wurden übernommen',
        'account:headline:general-account-settings': 'Allgemeine Kontoeinstellungen',
        'account:headline:contact-information': 'Kontaktinformationen',
        'account:hint:contact-information-private': 'Ihre Kontaktinformationen werden nicht veröffentlicht.',

        // change password
        'account:change-password:headline': 'Passwort ändern',
        'account:change-password:label': 'Neues Passwort',
        'account:change-password:save': 'Speichern',
        'account:change-password:cancel': 'Abbrechen',
        'account:change-password:placeholderOld': 'Altes Password eingeben',
        'account:change-password:placeholder': 'Neues Passwort',
        'account:change-password:labelOld': 'Altes Passwort',
        'account:change-password:labelRetype': 'Neues Passwort bestätigen',
        'account:change-password:placeholderRetype': 'Passwort erneut eingeben',
        'account:change-password:old-password-was-wrong': 'Die Eingabe Ihres alten Passworts ist nicht korrekt. Bitte versuchen Sie es erneut',

        // Account delete
        'account:delete:headline': 'Konto löschen',
        'account:delete:cancel': 'Abbrechen',
        'account:delete:success': 'Sie werden in Kürze eine E-Mail erhalten',
        'account:delete:info': 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Falls ja, so klicken Sie auf die nachfolgende Schaltfläche und wir werden Ihnen eine Abmelde-E-Mail zukommen lassen',
        'account:delete:button': 'Abmeldelink zusenden',

        // Profile
        'profile:label': 'Profil',
        'profile:picture': 'Profilbild',
        'profile:button:update': 'Speichern',
        'profile:name:label': 'Profilname',
        'profile:name:placeholder': 'Vollständigen Namen eingeben',
        'profile:website:label': 'Internetseite',
        'profile:website:placeholder': 'www.beispiel.de',
        'profile:tele:label': 'Telefonnummer',
        'profile:tele:placeholder': '+00 000 0000 0000',
        'profile:biography:label': 'Biografie',
        'profile:biography:placeholder': 'Geben Sie eine kurze Biografie ein',
        'profile:address:label': 'Straße und Hausnummer',
        'profile:address:placeholder': 'Straße und Hausnummer eingeben',
        'profile:address2:placeholder': 'Zusätzliche Addressinformationen',
        'profile:zip-city:label': 'Postleitzahl und Stadt',
        'profile:zip:placeholder': '00000',
        'profile:city:placeholder': 'Musterstadt',
        'profile:country:label': 'Land',
        'profile:country:please-choose': 'Land auswählen',
        'profile:unknown-error': 'Leider ist ein Fehler aufgetreten',
        'profile:saveSuccess': 'Ihre Änderungen wurden übernommen',
        'profile:picture:take': 'Profilfoto aufnehmen',
        'profile:picture:take-snapshot': 'Schnappschuß!',
        'profile:picture:take-snapshot-cancel': 'Abbrechen',
        'profile:picture:take-snapshot-submit': 'Hochladen',
        'profile:picture:take-snapshot-redo': 'Nochmal',
        'profile:picture:delete': 'Dieses Profilfoto Entfernen',
        'profile:logo:delete': 'Logo entfernen',
        'profile:saveinfo': 'Bearbeiten Sie Ihre Profilinformationen und klicken Sie auf „Speichern“',
        'profile:subline:profile-public': 'Diese Informationen sind auch für andere Nutzer sichtbar',

        // wincode
        'profile:wincode:headline:please-enter-wincode': 'Bitte geben Sie Ihren Code ein',
        'profile:wincode:button:cancel': 'Abbrechen',
        'profile:wincode:button:close': 'Schließen',
        'profile:wincode:button:verify': 'Aktivieren',
        'profile:wincode:placeholder:type-wincode-code': 'Gewinnspiel-Code eingeben',
        'profile:wincode:text:response-success': 'Der Gewinnspiel-Code wurde registriert. Sie nehmen an der Auslosung teil. Eine Gewinnbenachrichtigung geschieht über den COLIDO Talk innerhalb der nächsten 14 Tage.',
        'profile:wincode:text:response:wincode-not-writable': 'Der Gewinnspiel-Code könntet nicht gespeichert werden.',
        'profile:wincode:text:response:wincode-used-by-me': 'Sie haben bereits einen Gewinnspiel-Code registriert und nehmen an der Verlosung teil.',
        'profile:wincode:text:response:wincode-used-by-other': 'Der eingegebene Gewinnspiel-Code ist ungültig oder bereits registriert worden. Bitte überprüfen Sie Ihre Eingabe oder senden Sie uns eine E-Mail an <a href="mailto:support@colido.de?subject=Gewinnspiel-Code bereit verwendet">support@colido.de</a>.',
        'profile:wincode:text:response:wincode-unknown': 'Der eingegebene Gewinnspiel-Code ist ungültig oder bereits registriert worden. Bitte überprüfen Sie Ihre Eingabe oder senden Sie uns eine E-Mail an <a href="mailto:support@colido.de?subject=Gewinnspiel-Code ungültig">support@colido.de</a>.',
        'profile:enter-wincode:button': 'Gewinnspiel-Code eingeben',

        // Seller Profile
        'profile:seller:label:private': 'privat',
        'profile:seller:label:commercial': 'gewerblich',
        'profile:seller:company:label': 'Firma',
        'profile:seller:company:placeholder': 'Firma Name eintippen',
        'profile:seller:contact:label': 'Ansprechpartner',
        'profile:seller:contact:placeholder': 'Ansprechpartner Name/Vorname',
        'profile:seller:address:label': 'Adresse',
        'profile:seller:address:placeholder': 'Straße, Hausnummer',
        'profile:seller:plz:label': 'PLZ',
        'profile:seller:plz:placeholder': 'PLZ eintippen',
        'profile:seller:city:label': 'Ort',
        'profile:seller:city:placeholder': 'Stadt eintippen',
        'profile:seller:country:label': 'Land',
        'profile:seller:country:placeholder': 'Land eintippen',
        'profile:seller:email:label': 'E-Mail',
        'profile:seller:email:placeholder': 'E-Mail eintippen',
        'profile:seller:www:label': 'Webseite',
        'profile:seller:www:placeholder': 'www',
        'profile:seller:phone:label': 'Telefon',
        'profile:seller:phone:placeholder': '+49 555 555555',
        'profile:seller:registration:label': 'Registernummer',
        'profile:seller:registration:placeholder': 'Registernummer eintippen',
        'profile:seller:registrationOffice:label': 'Registration office',
        'profile:seller:registrationOffice:placeholder': 'Type reg. office',
        'profile:seller:uid:label': 'UID',
        'profile:seller:uid:placeholder': 'UID eintippen',
        'profile:seller:agb:label': 'Link zu AGB',
        'profile:seller:agb:placeholder': 'www',
        'profile:seller:dse:label': 'Link zu Datenschutzerklärung',
        'profile:seller:dse:placeholder': 'www',
        'profile:seller:notes:label': 'Sonstige',
        'profile:seller:notes:placeholder': 'Weitere Infos eintippen',

        // Subscription
        'subscription:label': 'Abonnement',
        'subscription:your-subscription:title': 'Abonnement',
        'subscription:free-trial:text': 'Derzeit haben Sie Zugriff auf alle Colido Premium Funktionen. \nAm {freeTrialExpiresAt} wird ihr Konto auf die kostenlose Version „Basic“ \nheruntergestuft. All Ihre Daten bleiben dabei erhalten. \n\nKlicken Sie auf „Abonnement verwalten“ um Informationen \nzu Angebot und Preisen einzusehen.',
        'subscription:free-trial:text-mobile': 'Derzeit haben Sie Zugriff auf alle Colido Premium Funktionen. \nAm {freeTrialExpiresAt} wird ihr Konto auf die kostenlose Version „Basic“ heruntergestuft. All Ihre Daten bleiben dabei erhalten. \n\nFalls Sie ein Upgrade durchführen möchten, nutzen Sie bitte ein Gerät mit einem größeren Bilschirm.',
        'subscription:your-subscription:text': 'Derzeit nutzen Sie die kostenlose Colido Version „Basic“. \n\nBitte klicken Sie auf „Abonnement verwalten“ um Informationen zu Angebot \nund Preisen einzusehen oder um ein Upgrade durchzuführen.',
        'subscription:your-subscription:text-mobile': 'Derzeit nutzen Sie die kostenlose Colido Version „Basic“. \n\nFalls Sie ein Upgrade durchführen möchten, nutzen Sie bitte ein Gerät mit einem größeren Bilschirm.',
        'subscription:button:setup-subscription': 'Abonnement verwalten',

        // Preferences
        'preferences:label': 'Einstellungen',
        'preferences:top-info': 'Passen Sie den Erfassungdialog nach Ihren Vorstellungen an.',
        'preferences:right-info': 'Bitte wählen Sie aus, welche Informationen Sie standardmäßig erfassen möchten. Alle übrigen Felder werden im Erfassungsdialog zunächst ausgeblendet.',
        'preferences:default-units-and-currencies': 'Standard Einheiten und Währungen',
        'preferences:weight-unit': 'Gewichtseinheit',
        'preferences:length-unit': 'Längeneinheit',
        'preferences:currency': 'Währung',
        'preferences:default-input-fields': 'Standard Eingabefelder',
        'preferences:unknown-save-error': 'Ihre Änderungen konnten leider nicht gespeichert werden',

        // Notifications
        'notifications:label': 'E-Mail Benachrichtigungen',
        'notifications:headline': 'Ihre E-Mail Benachrichtigungen',
        'notifications:checkbox:label:account': 'Konto, Sicherheit und Datenschutz',
        'notifications:checkbox:label:company-news': 'Unternehmens-News',
        'notifications:checkbox:label:product-updates': 'Neue Funktionen',
        'notifications:hint': 'Geben Sie an, welche E-Mail-Nachrichten Sie von uns erhalten möchten. Nachrichten bezüglich Ihres Kontos können nicht deaktivert werden.',

        // Trash
        'trash:label': 'Papierkorb',
        'trash:button-empty-trash': 'Papierkorb leeren',
        'trash:button-restore': 'Zurücklegen',
        'trash:note:top': 'Alle gelöschten Objekte werden zehn Tage im Papierkorb vorgehalten. Dadurch haben Sie die Möglichkeit Daten wiederherzustellen, die Sie aus Versehen gelöscht haben.',
        'trash:note:empty-trash': 'Ihr Papierkorb ist leer. Sobald Sie ein Element gelöscht haben, können Sie es an dieser Stelle wiederherstellen.',
        'trash:show-trash-button': 'Papierkorb anzeigen',
        'trash:table:name': 'Name',
        'trash:table:type': 'Typ',
        'trash:table:date': 'Datum',
        'trash:table:time': 'Zeit',
        'trash:response:restore-success': 'Ihr Element wurde erfolgreich wiederhergestellt.',
        'trash:response:restore-error': 'Ihr Element konnte nicht wiederhergestellt werden. ({reason})',
        'trash:response:trash-cleared-success': 'Ihr Papierkorb wurde erfolgreich geleert.',
        'trash:response:trash-cleared-error': 'Ihr Papierkorb konnte nicht geleert werden. ({reason})',
        'trash:subline:item-count': {
            '0': 'Keine Elemente',
            '1': '{n} Element',
            'n': '{n} Elemente',
        },

        // Import
        'import:label': 'Import',
        'import:headline:imports-in-progress': 'Aktuelle Imports',
        'import:button-abort': 'Abbrechen',
        'import:button-import-database': 'Datenbank importieren',
        'import:note-new:label': 'Datenbank importieren',
        'import:note-new:text': 'Nachdem Sie auf „<a href="{importPath}">Datenbank importieren</a>“ geklickt haben,<br /> können Sie Ihre bestehende Datenbank als CSV Datei<br /> importieren. Falls Sie hierzu Hilfe benötigen, schauen Sie sich<br /> den entprechenden Beitrag in unserem <a href="https://colido.zendesk.com/hc/en-us/articles/202896151" target="_blank">Hilfebereich</a> an.',
        'import:note-recent:label': 'Zuletzt importierte Datenbanken',
        'import:note-recent:text': 'Durch Klick auf einen Import, (Liste unten) erlangen Sie Zugriff auf alle jeweils<br /> importierten Objekte. Falls hierbei etwas schiefgelaufen ist,<br /> können Sie im Anschluss die entsprechende Objekte löschen.',
        'import:note-empty': 'Sie haben noch keine Datenbank importiert.',
        'import:state:mapping': 'Zuweisung',
        'import:state:reviewing': 'In Prüfung',
        'import:state:queue': 'In Warteschlange ...',
        'import:state:importing': 'Wird importiert ...',
        'import:state:processing': 'Wird fertiggestellt ...',
        'import:state:waiting': 'Warten ...',
        'import:state:complete': 'Fertiggestellt',
        'import:detail:headline': 'Import: {filename}',
        'import:detail:headline-warnings': 'Import-Warnungen: {filename}',
        'import:object:count': {
            '0': 'beinhaltet {n} Objekte',
            '1': 'beinhaltet {n} Objekt',
            'n': 'beinhaltet {n} Objekte',
        },
        'import:object:count:estimated': 'Beinhaltet {n} Objekte (geschätzt)',
        'import:recent-list:filename': 'Dateiname',
        'import:recent-list:objects': 'Objekte',
        'import:recent-list:warnings': 'Warnungen',
        'import:recent-list:imported-on': 'Import am',
        'import:please-select-field': 'Bitte wählen',
        'import:no-field-selected': 'Nicht ausgeben',

        // Export // not in there yet
        'export:label': 'Export',
        'export:button-abort': 'Abbrechen',
        'export:button-export-database': 'Datenbank exportieren',
        'export:note-new:label': 'Datenbank exportieren',
        'export:note-new:text': ' ',
        'export:note-recent:label': 'Letzte Exporte',
        'export:note-recent:text': ' ',
        'export:note-empty': ' ',
        'export:object:count': {
            '0': ' ',
            '1': ' ',
            'n': ' ',
        },
        'export:object:count:estimated': ' ',
        'export:recent-list:filename': ' ',
        'export:recent-list:objects': ' ',
        'export:recent-list:warnings': ' ',
        'export:recent-list:imported-on': ' ',
        'export:recent-list:type': ' ',
        'export:state:queue': ' ',
        'export:state:exporting': ' ',
        'export:state:processing': ' ',
        'export:state:waiting': ' ',
        'export:state:complete': ' ',

        // Information
        'information:label': 'Informationen',
        'information:terms-and-conditions:label': 'AGB',
        'information:privacy:label': 'Datenschutzerklärung',
        'information:client-informations:label': 'Client-Informationen',
        'information:client-informations:client-version': 'Client-Version',
        'information:client-informations:api-url': 'API-URL',
        'information:client-informations:api-version': 'API-Version',
        'information:client-informations:debug-mode': 'Debug-Modus',

        // Catalogs
        'manage-catalogs:label': 'Katalogverwaltung',
        'manage-catalogs:button:show-create-catalog-form': 'Katalog erstellen',
        'manage-catalogs:button:show-create-first-catalog-form': 'Katalog erstellen',
        'manage-catalogs:button:create-catalog': 'Fertig',
        'manage-catalogs:button:hide-create-form': 'Abbrechen',
        'manage-catalogs:placeholder:catalog-name': 'Katalognamen eingeben',
        'manage-catalogs:button:show-edit-form': 'Bearbeiten',
        'manage-catalogs:button:hide-edit-form': 'Abbrechen',
        'manage-catalogs:button:save-catalog': 'Fertig',
        'manage-catalogs:text:empty-catalogs': 'Um anzufangen, legen Sie Ihren ersten Katalog an. \n\nWenn Sie das erste mal mit Colido arbeiten, können Sie Beispieldaten anlegen lassen, um zu sehen was möglich ist.',

        'catalogs:type:minerals': 'Mineralien',
        'catalogs:type:fossils': 'Fossilien',
        'catalogs:type:coins': 'Münzen (Beta)',
        'catalogs:type:coins2': 'Münzen (Beta *neu)',
        'catalogs:type:bills': 'Geldschein (Beta)',
        'catalogs:type:art': 'Kunstwerke (Beta)',
        'catalogs:type:general': 'Andere (Beta)',
        'catalogs:type:generic': 'Andere (Beta)',
        'catalogs:subtype:placeholder': 'Eigene Typ',
        'catalogs:subtype:shoes': 'Schuhe',
        'catalogs:subtype:watches': 'Uhren',
        'catalogs:subtype:caps': 'Kappen',
        'catalogs:subtype:stamps': 'Briefmarken',
        'catalogs:subtype:books': 'Bücher',

        'catalogs:add-sample-data:label': 'Mit Beispieldaten',
        'catalogs:create:messages-success': 'Ihr Katalog {catalogName} wurde erstellt.',
        'catalogs:create:messages-error': 'Der Katalog konnte nicht erstellt werden.',
        'catalogs:update:messages-success': 'Ihr Katalog wurde zu {catalogName} umbennant',
        'catalogs:update:messages-error': 'Der Katalog konnte nicht aktualisiert werden.',
        'catalogs:delete:message-confirm': 'Sind Sie sicher, dass Sie den Katalog {catalogName} löschen möchten?',
        'catalogs:delete:messages-success': 'Ihr Katalog {catalogName} wurde gelöscht.',
        'catalogs:delete:messages-error': 'Der Katalog konnte nicht gelöscht werden.',
    },

    'helpdesk': {
        'label': 'Hilfe',

        'headline': 'Hilfebereich',
        'subline': ' ',

        // Helpdesk
        'helpdesk:getting-started:label': 'Loslegen',
        'helpdesk:getting-started:text': 'Für den schnellen Einstieg haben wir ein fünf-minütiges Einführungsvideo für Sie vorbereitet.', // Todo
        'helpdesk:getting-started:button': 'Getting started section', // Todo
        'helpdesk:faq:label': 'Fragen und Antworten', // Todo
        'helpdesk:faq:text': 'Wenn Sie Fragen zum Produkt und seinen Funktionen, zum Angebot und den Kosten oder zum Datenschutz haben, besuchen Sie bitte unser Help Desk. Dieses steht zunächst leider nur in englischer Sprache zur Verfügung.', // Todo
        'helpdesk:faq:button': 'Zum Help Desk', // Todo
        'helpdesk:still-have-questions:label': 'Noch Fragen?', // Todo
        'helpdesk:still-have-questions:text': 'Wenn wir Ihnen hier nicht weiterhelfen konnten, schreiben Sie uns doch eine E-Mail an support@colido.de', // Todo
        'helpdesk:still-have-questions:button': 'E-Mail senden', // Todo

        // Getting Started
        'getting-started:label': 'Getting Started', // Todo

        // Contact
        'contact:label': 'Kontakt',
        'contact:name:label': 'Name',
        'contact:name:placeholder': 'Ihr Name',
        'contact:email:label': 'E-Mail',
        'contact:email:placeholder': 'Ihre E-Mail-Adresse',
        'contact:text:label': 'Text',
        'contact:text:placeholder': 'Text eingeben',
        'contact:note:right:label': 'Konnte der Hilfebereich Ihre Frage nicht beantworten?',
        'contact:note:right:text': 'Sie können uns gerne kontaktieren!',
        'contact:button-submit': 'Anfrage senden',
    },

    'collection': {
        'label': 'Kollektion', // main menu //@todo rename 'label' to 'menu' for menu labels
        'label:full-catalog': 'Gesamter Katalog',
        'label:objects': 'Objekte', // sub menu
        'label:scanners': 'Scanner', // sub menu
        'label:sets': 'Sets', // sub menu
        'label:your-shop': 'Im Verkauf',
        'label:storages': 'Standorte', // sub menu
        'label:catalogs': 'Meine Kataloge', // sub menu

        // Catalog
        // 'catalog:update:table-settings:success:message': 'Success',
        'catalog:update:table-settings:error:message': 'Es gab leider einen Fehler beim Speichern der Tabellen-Einstellungen.',

        'scanners:objects:list:headline': 'Scanner ist aktiv für {catalogName}',
        'scanners:objects:list:empty:catalog': 'Legen Sie ein Object mit RFID-Tag auf den Scanner, um es in dieser dynamischen Liste anzuzeigen.',
        'scanners:objects:detail:status:tag-connected': 'Mit diesem Objekt ist ein RFID-Tag verknüpft.',
        'scanners:objects:detail:status:no-tag': 'Mit dem Objekt ist kein RFID-Tag verknüpft. Bitte legen Sie einen Tag auf den Scanner um ihn zu verknüpfen.',
        'scanners:objects:detail:status:valid-tag': 'Wir haben einen RFID-Tag auf dem Scanner gefunden.',
        'scanners:objects:detail:status:used-tag': 'Wir haben einen RFID-Tag auf dem Scanner gefunden, er ist allerdings schon mit einem anderen Objekt verknüpft.',
        'scanners:objects:detail:status:too-many-tags': 'Es sind zu viele RFID-Tags auf dem Scanner. Bitte stellen Sie sicher, dass nur ein Tag auf dem Scanner ist.',
        'scanners:objects:detail:scanner-not-fround': 'Wir konnten keine RFID-Scanner finden. Bitte senden Sie uns eine E-Mail an <a href="mailto:support@colido.de?subject=RFID-Scanner%20nicht%20gefunden">support@colido.de</a>.',

        'scanners:objects:detail:button:remove-tag-from-object': 'Entferne Tag von Objekt',
        'scanners:objects:detail:button:connect-tag-to-object': 'Verknüpfe Tag mit diesem Objekt',

        //Shop
        'shop:label': 'Marktplatz',
        'shop:objects:list:headline': '{typeName}-Marktplatz',
        'shop:objects:list:empty': 'Der Marktplatz ist noch leer.<br /><br />Wenn Sie der erste sein wollen, der hier etwas verkauft,<br />klicken Sie in der Detail-Ansicht eines Objektes auf Aktionen -> Verkaufen.',
        'shop:object:details:buy:label': 'Verkäufer kontaktieren',
        'shop:shipping-address-modal:headline': 'Lieferadresse',
        'shop:shipping-address-modal:text': 'Sorry, in Ihrem Profil ist noch keine Lieferadresse hinterlegt. Bitte geben Sie diese zuerst an.',
        'shop:shipping-address-modal:button-profile': 'Zum Profil',
        'shop:buy-modal:headline': 'Verbindlich kaufen',
        'shop:buy-modal:text': 'Hiermit gibst Du ein verbindliches Angebot zum Kauf des Objekts {objectName} für {amount} zzgl. Versand ab.',
        'shop:buy-modal:buy-button': 'Kaufangebot abgeben',
        'shop:buy-modal:do-not-buy-button': 'Abbrechen',
        'shop:seller-modal:headline': 'Verkäuferprofil',
        'shop:seller-modal:company': 'Firma',
        'shop:seller-modal:contact': 'Kontakt',
        'shop:seller-modal:address': 'Adresse',
        'shop:seller-modal:email': 'E-Mail',
        'shop:seller-modal:www': 'Webseite',
        'shop:seller-modal:phone': 'Telefon',
        'shop:seller-modal:registration': 'Registernummer',
        'shop:seller-modal:uid': 'UID',
        'shop:seller-modal:agb': 'Allgemeine Geschäftsbedingungen',
        'shop:seller-modal:dse': 'Datenschutzerklärung',
        'shop:seller-modal:no-data-text': 'Keine Daten vorhanden für diese Verkäufer',

        'shop:object:action:sell': 'Verkaufen',
        'shop:object:action:withdraw-from-sale': 'Verkauf stoppen',
        'shop:sell-modal:sale:headline': 'Objekt verkaufen',
        'shop:sell-modal:withdraw:headline': 'Verkauf stoppen',
        'shop:sell-modal:sell-button': 'Zum Verkauf stellen',
        'shop:sell-modal:do-not-sell-button': 'Abbrechen',
        'shop:sell-modal:sell-hint': 'Wenn das Objekt zum Verkauf gestellt ist, taucht es in den öffentlichen Marktplätzen von Colido auf. Potentielle Käufer können Sie dann über Colido Talk kontaktieren, um den Kauf mit Ihnen abzuwickeln. Das Einstellen ist kostenlos und für in der Beta-Phase eingestellte Objekte fällt keine Verkaufsprovision an.',
        'shop:sell-modal:withdraw-from-sale-button': 'Aus dem Verkauf nehmen',
        'shop:sell-modal:withdraw-from-sale-hint': 'Das Objekt wird aus allen Marktplätzen entfernt und laufende Offerten werden abgelehnt.',

        'shop:sell:response:success': 'Ihr Objekt wurde erfolgreich zum Verkauf eingestellt: {itemLink}',
        'shop:sell:response:error': 'Es ist leider etwas schiefgelaufen. Bitte versuchen Sie es erneut. Sollte es weiterhin nicht funktionieren kontaktieren Sie uns bitte: <a href="mailto:support@colido.de?subject=Shop-Angebot%20konnte%20nicht%20erstellt%20werden">support@colido.de</a>',
        'shop:sell:withdraw:response:success': 'Ihr Objekt wurde erfolgreich aus dem Verkauf genommen: {itemName}',
        'shop:sell:withdraw:response:error': 'Es ist leider etwas schiefgelaufen. Bitte versuchen Sie es erneut. Sollte es weiterhin nicht funktionieren kontaktieren Sie uns bitte: <a href="mailto:support@colido.de?subject=Shop-Angebot%20konnte%20nicht%gelöscht%20werden">support@colido.de</a>',
        'shop:create:offer:response:error': 'Es ist leider etwas schiefgelaufen. Bitte versuchen Sie es erneut. Sollte es weiterhin nicht funktionieren kontaktieren Sie uns bitte: <a href="mailto:support@colido.de?subject=Shop-Offerte%20konnte%20nicht%20erstellt%20werden">support@colido.de</a>',
        'shop:create:offer:response:error-buyer-and-seller-same-user': 'Sie können dieses Objekt nicht von sich selbst kaufen.',

        // Translations for objects
        'objects:list:headline': '{catalogName}: Katalog',
        'objects:list:add-button': 'Objekt erstellen',
        'objects:list:empty': 'Diese Liste ist leer.',
        'objects:list:empty:catalog': 'Ihr Katalog beinhaltet keine Objekte. Erfassen Sie ein erstes Objekt.',
        'objects:list:empty:filter': 'Zu den ausgewählten Filterkriterien wurden leider keine Objekte gefunden.',
        'objects:list:empty:search': 'Zu dem eingegebenen Suchbegriff wurde leider kein Objekt gefunden.',
        'objects:count': {
            '0': 'Objekte',
            '1': 'Objekt',
            'n': 'Objekte',
        },
        'objects:view:sort:label': 'Sortierung',
        'objects:view:sort:name': 'Name',
        'objects:view:sort:id': 'Objektnummer',
        'objects:view:sort:locality': 'Fundort',
        'objects:view:sort:last-edited': 'Zuletzt',

        'objects:view:button:actions': 'Aktionen',
        'objects:view:button:showroom': 'Showroom',
        'objects:view:button:manage': 'Auswählen',
        'objects:view:button:delete': 'Löschen',
        'objects:view:button:done': 'Fertig',
        'objects:view:button:select-all': 'Alle auswählen',
        'objects:view:button:print-list': 'Liste erstellen (PDF)',
        'objects:view:button:print-label': {
            1: 'Label erstellen (PDF)',
            n: 'Labels erstellen (PDF)',
        },
        'objects:view:button:export': 'Objekte exportieren (CSV)',
        'objects:view:button:edit': 'Umbenennen',
        'objects:view:button:share': 'Freigeben',
        'objects:view:manage:hint': 'Bitte wählen Sie Objekte aus',
        'objects:view:button:edit:set': 'Set infos bearbeiten',
        'objects:view:button:delete:set': 'Set löschen',
        'objects:view:button:share:set': 'Set freigeben',
        'objects:view:button:edit:storage': 'Standort umbenennen',
        'objects:view:button:delete:storage': 'Standort löschen',
        'objects:view:button:share:storage': 'Standort freigeben',

        'objects:filter:headline': 'Filter: Kollektion',
        'objects:filter:button': 'Filter',
        'objects:filter:label': 'Filter',
        'objects:filter:button:show': 'Anzeigen',
        'objects:filter:button:cancel': 'Abbrechen',
        'objects:filter:button:go-to-filter-definition:title': 'Zum Filter springen',
        'objects:filter:button:open-filter:title': 'Zum Filter springen',
        'objects:filter:button:remove-filter:title': 'Filter entfernen',
        'objects:filter:material:title': 'Mineralien / Paragenese',
        'objects:filter:material:search': 'Suche',
        'objects:filter:material:search:placeholder': 'Mineral eingeben',
        'objects:filter:material:recent': 'Zuletzt gefiltert',
        'objects:filter:material:common': 'Häufigste',
        'objects:filter:locality:title': 'Fundort',
        'objects:filter:locality:common-countries': 'Häufigste',
        'objects:filter:locality:recent-countries': 'Zuletzt gefiltert',
        'objects:filter:locality:specific-localities': 'Exakter Fundort',
        'objects:filter:locality:specific-localities:type-specific-locality': 'Fundort eingeben',
        'objects:filter:finding-date:title': 'Funddatum',
        'objects:filter:finding-date:from:label': 'von',
        'objects:filter:finding-date:to:label': 'bis',
        'objects:filter:size:title': 'Objekt-Größe',
        'objects:filter:weight:title': 'Gewicht',
        'objects:filter:color:title': 'Farbe',
        'objects:filter:cataloging-date:title': 'Erfassungsdatum',
        'objects:filter:cataloging-date:from:label': 'von',
        'objects:filter:cataloging-date:to:label': 'bis',
        'objects:filter:obtaining-date:title': 'Erhalten',
        'objects:filter:obtaining-date:from:label': 'von',
        'objects:filter:obtaining-date:to:label': 'bis',
        'objects:filter:purchasing-price:title': 'Kaufpreis',
        'objects:filter:estimated-value:title': 'Geschätzter Wert',
        'objects:filter:insurance-value:title': 'Versicherungswert',
        'objects:filter:history:title': 'Objekthistorie',
        'objects:filter:previous-owner:title': 'Vorbesitzer',
        'objects:filter:previous-owner:select:other-prev-owners': 'Weitere Vorbesitzer',
        'objects:filter:range-slider:from:label': 'from',
        'objects:filter:range-slider:to:label': 'to',

        'objects:filter:material:short-title': 'Material',
        'objects:filter:locality:short-title': 'Fundort',
        'objects:filter:finding-date:short-title': 'Gefunden',
        'objects:filter:size:short-title': 'Größe',
        'objects:filter:weight:short-title': 'Gewicht',
        'objects:filter:color:short-title': 'Farbe',
        'objects:filter:cataloging-date:short-title': 'Erfasst',
        'objects:filter:obtaining-date:short-title': 'Erhalten',
        'objects:filter:purchasing-price:short-title': 'Preis',
        'objects:filter:estimated-value:short-title': 'Geschätzt',
        'objects:filter:insurance-value:short-title': 'Versichert',
        'objects:filter:history:short-title': 'Historie',
        'objects:filter:previous-owner:short-title': 'Vorbesitzer',

        'object:emptyName': 'Ohne Namen',
        'object:emptyLocality': 'Kein Fundort',
        'object:emptyDescription': 'Keine Beschreibung',
        'object:dimension-type:micromount': 'Micromount',
        'object:dimension-type:thumbnail': 'Thumbnail',
        'object:dimension-type:miniature': 'Miniature',
        'object:dimension-type:smallCabinet': 'Small Cabinet',
        'object:dimension-type:cabinetSpecimen': 'Cabinet Specimen',
        'object:dimension-type:museumSpecimen': 'Museum Specimen',

        'object:finding-date:none:short-title': 'Unbekannt',
        'object:finding-date:none:title': 'Fundatum unbekannt',

        'object:action:headline': 'Aktionen',
        'object:action:edit': 'Bearbeiten',
        'object:action:show-storage': 'Standort anzeigen',
        'object:action:add-to-set': 'Zu Set hinzufügen',
        'object:action:choose-storage': 'Standort aktualisieren',
        'object:action:change-status': 'Status ändern',
        'object:action:duplicate': 'Duplizieren',
        'object:action:delete': 'Löschen',
        'object:action:confirmDelete': {
            '1': 'Sind sie sicher, dass Sie das Objekt löschen möchten?',
            'n': 'Sind sie sicher, dass Sie {n} Objekte löschen möchten?',
        },
        'object:response:deleted': {
            '0': 'Es wurden keine Objekte gelöscht',
            '1': 'Ihr Objekt {name} wurde aus Ihrem Katalog gelöscht.<br />Falls Sie das Objekt aus Versehen gelöscht haben, können Sie es im Papierkorb wiederherstellen.',
            'n': '{n} Objekte wurden aus Ihrem Katalog gelöscht.<br />Falls Sie die Objekte aus Versehen gelöscht haben, können Sie diese im Papierkorb wiederherstellen.',
        },
        'object:response:deleted:error': {
            '0': 'Es konnten keine Objekte gelöscht werden.',
            '1': 'Ihr Objekt {name} konnte nicht gelöscht werden.',
            'n': '{n} Objekte konnten nicht gelöscht werden.',
        },

        'object:details:actions:label': 'Aktionen',

        //@todo make these headline catalog driven
        'object:details:section:headline:history': 'Historie',
        'object:details:section:headline:status': 'Objektstatus',
        'object:details:section:headline:sets': 'Sets',

        'object:details:assets:label': 'Dateien',
        'object:details:assets:dismiss-all': 'Alle verwerfen',

        'object:details:history:type:undefined:label': 'Unbestimmt',
        'object:details:history:type:none:label': 'Unbestimmt',
        'object:details:history:type:purchased:label': 'Kauf',
        'object:details:history:type:traded-off:label': 'Tausch',
        'object:details:history:type:found:label': 'Fund',
        'object:details:history:type:given:label': 'Geschenk',
        'object:details:history:type:heritage:label': 'Erbe',
        'object:details:history:purchased:from:label': 'Erstanden von',
        'object:details:history:purchased:price:label': 'Erstanden für',
        'object:details:history:purchased:date:label': 'Erstanden am',
        'object:details:history:traded-off:from:label': 'Getauscht mit',
        'object:details:history:traded-off:date:label': 'Getauscht am',
        'object:details:history:found:from:label': 'Gefunden von',
        'object:details:history:found:date:label': 'Gefunden am',
        'object:details:history:given:from:label': 'Geschenk von',
        'object:details:history:given:date:label': 'Erhalten am',
        'object:details:history:heritage:from:label': 'Vererbt von',
        'object:details:history:heritage:date:label': 'Erhalten am',

        'object:details:history:acquired:label': 'Erhalten durch', // Erhalten durch: Tausch etc

        'object:details:estimated-value:label': 'Geschätzter Wert',
        'object:details:estimated-value-currency:label': 'Währung geschätzter Wert',
        'object:details:insurance-value:label': 'Versicherungswert',
        'object:details:insurance-value-currency:label': 'Währung Versicherungswert',
        'object:details:retail-price:label': 'Verkaufspreis',
        'object:details:retail-price-currency:label': 'Währung Verkaufspreis',
        'object:details:size:label': 'Größe',
        'object:details:weight:label': 'Gewicht',
        'object:details:weight-unit:label': 'Gewichtseinheit',
        'object:details:max-crystal-size:label': 'Max. Kristallgröße',
        'object:details:notes:label': 'Notizen',
        'object:details:damages:label': 'Schäden',
        'object:details:color:label': 'Farbe',
        'object:details:custom-color:label': 'Präzise Farbe',
        'object:details:color-specification:label': 'Spezifischere Farbangaben ergänzen',

        'object:details:species-correlation-comma:label': 'und',
        'object:details:species-correlation-on:label': 'auf',
        'object:details:species-correlation-with:label': 'mit',
        'object:details:species-correlation-var:label': 'var',
        'object:details:species-correlation-after:label': 'nach',
        'object:details:species-correlation-pseudomorph:label': 'Pseudomorphose',
        'object:details:species-correlation-partial-pseudomorph:label': 'Partielle Pseudomorphose',
        'object:details:species-correlation-epimorph:label': 'Epimorphose',
        'object:details:species-correlation-after_pseudo:label': 'pseudomorph',
        'object:details:species-correlation-after_partial:label': 'partieller pseudomorph',
        'object:details:species-correlation-after_epimorph:label': 'epimorph',

        'object:details:object-status:in-collection:label': 'In Sammlung',
        'object:details:object-status:lent:label': 'Verliehen',
        'object:details:object-status:lent-to:label': 'Verliehen an',
        'object:details:object-status:missing:label': 'Verschollen',
        'object:details:object-status:deaccessioned:label': 'Veräußert',
        'object:details:object-status:deaccessioned-to:label': 'Veräußert an',
        'object:details:object-status:relation:to:label': 'An',
        'object:details:object-status:relation:to:placeholder': 'Empfänger',

        'object:details:color:black': 'Schwarz',
        'object:details:color:indigo': 'Indigo',
        'object:details:color:orange': 'Orange',
        'object:details:color:gold': 'Gold',
        'object:details:color:grey': 'Grau',
        'object:details:color:purple': 'Violett',
        'object:details:color:yellow': 'Gelb',
        'object:details:color:copper': 'Kupfer',
        'object:details:color:white': 'Weiß',
        'object:details:color:magenta': 'Magenta',
        'object:details:color:green': 'Grün',
        'object:details:color:silver': 'Silber',
        'object:details:color:blue': 'Blau',
        'object:details:color:pink': 'Pink',
        'object:details:color:olive': 'Olivgrün',
        'object:details:color:colorless': 'Farblos',
        'object:details:color:cyan': 'Cyan',
        'object:details:color:red': 'Rot',
        'object:details:color:brown': 'Braun',
        'object:details:color:undefined': 'undefiniert',

        'object:details:storage:undefined': 'Nicht bestimmt',
        //'object:details:storage:none': 'Sonstige',
        //'object:details:storage:in-transit': 'Unterwegs',
        //'object:details:storage:unknown': 'Unbekannt',
        'object:details:sets:undefined': 'Ohne Zugehörigkeit',

        // Inputfields
        'inputfields:object-id:name': 'Objektnummer',
        'inputfields:mindat-id:name': 'minID by mindat.org',
        'inputfields:damage-repairs-treatments:name': 'Schäden, Reparaturen, Aufbereitungen',
        'inputfields:description:name': 'Beschreibung',
        'inputfields:dimensions:name': 'Maße',
        'inputfields:dimensions-sizeUnit:name': 'Maßeinheit',
        'inputfields:dimensions-sizeHeight:name': 'Height',
        'inputfields:dimensions-sizeWidth:name': 'Width',
        'inputfields:dimensions-sizeDepth:name': 'Depth',
        'inputfields:main-colors:name': 'Primär-Farben',
        'inputfields:note:name': 'Notizen',
        'inputfields:object-history:name': 'Objekthistorie',
        'inputfields:obtained:name': 'Erhalten durch',
        'inputfields:obtained-date:name': 'Erhalten am',
        'inputfields:obtained-from:name': 'Erhalten von',
        'inputfields:object-image:name': 'Objektbild',
        'inputfields:object-status:name': 'Objektstatus',
        'inputfields:precise-color-information:name': 'Präzise Farbe',
        'inputfields:previous-owners-and-collections:name': 'Besitzer und Kollektionen',
        'inputfields:set-membership:name': 'Set-Mitgliedschaften',
        'inputfields:species:name': 'Mineral',
        'inputfields:specimen-title:name': 'Objektname',
        'inputfields:species-names:name': 'Paragenesis', // for the listprint
        'inputfields:storage-information:name': 'Standort',
        'inputfields:values-and-prices:name': 'Preise und Werte',
        'inputfields:weight:name': 'Gewicht',
        'inputfields:object-weight:name': 'Gewicht', // for the listprint
        'inputfields:dimensions-weight:name': 'Gewicht', // for import
        'inputfields:dimensions-weight-unit:name': 'Gewichtseinheit', // for import
        'inputfields:purchasing-price:name': 'Kaufpreis',
        'inputfields:estimated-value:name': 'Geschätzter Wert',
        'inputfields:insurance-value:name': 'Versicherungswert',
        'inputfields:retail-price:name': 'Verkaufspreis',
        'inputfields:estimated-value-currency:name': 'Währung Geschätzter Wert',
        'inputfields:insurance-value-currency:name': 'Währung Versicherungswert',
        'inputfields:retail-price-currency:name': 'Währung Verkaufspreis',
        'inputfields:dimension-type:name': 'Größenklasse',
        'inputfields:modified-time:name': 'Zuletzt geändert',
        'inputfields:max-crystal-size:name': 'Max. Kristallgröße',
        'inputfields:max-crystal-size-unit:name': 'Max. Kristallgrößeneinheit',
        'inputfields:object-history-from:name': 'Erhalten von',
        'inputfields:object-history-date:name': 'Erhalten am',
        'inputfields:signature:name': 'Unterschrift',

        // Change status
        'change-status:headline': 'Status ändern',
        'change-status:response': {
            '0': '{n} Objekte erhielten den Status {status}',
            '1': '{objectLink} erhielt den Status {status}',
            'n': '{n} Objekte erhielten den Status {status}',
        },
        'change-status:placeholder-recipient': 'Ergänzen Sie den Empfänger (optional)',
        'change-status:button-change-status': 'Status ändern',

        //Search
        'search:headline:terms': 'Suche nach {terms}',
        'search:headline:result-detailpage': 'Suchergebnisse für {keyword}',
        'search:placeholder': 'Suche',
        'search:what-are-you-looking-for': 'Wonach suchen Sie?',

        // Set lists
        'sets:overview': 'Übersicht',
        'sets:headline': '{catalogName}: Sets',
        'sets:count': {
            '0': 'Keine Sets',
            '1': '{n} Set',
            'n': '{n} Sets',
        },

        // Set details
        'set:defaultName': 'Set',
        'set:headline': 'Set: {name}',
        'set:label:set-price': 'Gesamtpreis',
        'set:label:set-sum-total': 'Summe Einzelpreise',
        'set:description:show-more-text:label': 'Mehr Text anzeigen',
        'set:edit:name:placeholder': 'Setnamen eingeben',
        'set:edit:number:placeholder': 'Setnummer eingeben',
        'set:edit:price:placeholder': 'Setpreis eingeben',
        'set:edit:sum-field:label': 'Summenfeld',
        'set:edit:sum-field:no-field-selected': 'Nicht ausgeben',
        'set:edit:set-currency:label': 'Währung',
        'set:edit:set-price:label': 'Gesamtpreis',
        'set:edit:description:placeholder': 'Beschreibung',
        'set:edit:add-new-set:label': 'Set erstellen',
        'set:edit:cancel': 'Abbrechen',
        'set:edit:create': 'Fertig',
        'set:edit:done': 'Speichern',
        'set:edit:delete': 'Löschen',
        'set:edit:share': 'Freigeben',
        'set:edit:label': 'Bearbeiten',
        'set:response:add:success': 'Das Set {name} wurde erstellt.',
        'set:response:add:error': 'Das Set {name} konnte nicht erstellt werden',
        'set:response:add-to-set': {
            '0': 'Kein Objekt wurde zum Set {setLink} hinzugefügt',
            '1': '{objectLink} wurde zum Set {setLink} hinzugefügt',
            'n': '{n} Objekte wurden zum Set {setLink} hinzugefügt',
        },
        'set:response:add-to-set:failed': {
            '0': 'Leider konnte kein Objekt zum Set {setLink} hinzugefügt werden',
            '1': '{objectLink} konnte leider nicht zum Set {setLink} hinzugefügt werden',
            'n': '{n} Objekte konnten leider nicht zum Set {setLink} hinzugefügt werden',
        },
        'set:response:remove-from-set': {
            '0': 'Kein Objekt wurde vom Set {setLink} enfernt',
            '1': '{objectLink} wurde vom Set {setLink} enfernt',
            'n': '{n} Objekte wurden vom Set {setLink} enfernt',
        },
        'set:response:remove-from-set:failed': {
            '0': 'Leider konnte kein Objekt vom Set {setLink} entfernt werden',
            '1': '{objectLink} konnte leider nicht vom Set {setLink} entfernt werden',
            'n': '{n} Objekte konnten leider nicht vom Set {setLink} entfernt werden',
        },
        'set:question:remove-from-set': {
            0: 'Möchten Sie wirklich {n} Objekte aus Ihrem Set {setName} entfernen?',
            1: 'Möchten Sie wirklich {n} Objekt aus Ihrem Set {setName} entfernen?',
            n: 'Möchten Sie wirklich {n} Objekte aus Ihrem Set {setName} entfernen?',
        },
        'set:response:deleted': 'Das Set {name} wurde gelöscht.<br />Falls Sie das Set versehentlich gelöscht haben, können Sie es im Papierkorb wiederherstellen.',
        'set:response:add:show': 'Set anzeigen',
        'set:add-new-set': 'Set erstellen',
        'set:object-count': {
            '0': 'Keine Objekte',
            '1': '{n} Objekt',
            'n': '{n} Objekte',
        },
        'set:addToSet:headline': 'Zu Set hinzufügen',
        'set:addToSet:selected-objects': {
            '0': 'Keine Objekte ausgewählt',
            '1': '{n} Objekt ausgewählt',
            'n': '{n} Objekte ausgewählt',
        },
        'set:confirm-delete-set': 'Sind Sie sicher, dass Sie das Set {name} löschen möchten?',
        'set:empty:introduction': 'Dieses Set ist noch leer. Um Objekte hinzuzufügen wechseln Sie zunächst in den Katalog<br />und nutzen dann eine der beiden folgenden Möglichkeiten:',
        'set:empty:drag-and-drop:header': 'Drag and Drop',
        'set:empty:drag-and-drop:body': 'In der Miniaturansicht können Sie ein Objekt einfach anfassen und verschieben. Bewegen Sie es dann auf ein Set in der links auftauchenden Liste.',
        'set:empty:manage-mode:header': 'Massenedit',
        'set:empty:manage-mode:body': 'Nutzen Sie die Schaltfläche „Auswählen“ um mehrere Objekte markieren zu können. Im Anschluss wählen Sie unter dem Punkt „Aktionen“ ein Set aus.',
        'set:headline:share': 'Freigabe: {name}',
        'set:share:button:sharing-options': 'Freigabeoptionen',
        'set:share:label:share-by': 'Link teilen via',
        'set:share:label:share-label': 'Label PDF',
        'set:share:label:share-label-button': 'Share-Label erstellen',
        'set:share:empty-share:headline': 'Entschuldigung',
        'set:share:empty-share:text': 'Das gewünschte Set ist nicht mehr erreichbar',

        // set share modal
        'set:share:status': 'Freigabestatus',
        'set:share:status:active': 'Freigegeben',
        'set:share:status:disabled': 'Privat',
        'set:share:copy-link': 'Link kopieren',
        'set:share:copy-link:success': 'In der Zwischenablage!',
        'set:share:copy-link:error': 'Der Link konnte nicht in die Zwischenablage kopiert werden. Scheinbar unterstützt Ihr Browser diese Funktion nicht. Bitte kopieren Sie den Link manuell.',
        'set:share:info': 'Durch Weitergabe des oben gezeigten Freigabelinks, können Sie Freunden und Kollegen Zugang zu diesem Set gewähren.', // + You have perfect control over which informations will be revealed in the sharing options.
        'set:share:extern-link': 'Freigegebenes Set anschauen',

        // Location stuff
        'locations:headline': '{catalogName}: Standorte',
        'locations:storages:overview': 'Übersicht',
        'locations:storages:headline': 'Standorte',
        'locations:edit:label': 'Bearbeiten',
        'locations:edit:done': 'Speichern',
        'locations:edit:cancel': 'Abbrechen',
        'locations:edit:delete': 'Löschen',
        'locations:headline-storage-location': 'Lagerort',
        'locations:label-in-storage': 'Lagernd',
        //'locations:LabelInTransit': ' ',
        //'locations:LabelLent': ' ',
        //'locations:LabelSold': ' ',
        'locations:label-undefined': 'Unbestimmt',
        'locations:headline-storages': 'Konkrete Lagerorte',
        'locations:headline-other-locations': 'Weitere Standorte',
        'locations:headline-undefined': 'Objekte mit unbestimmten Standort',
        'locations:label:location': 'Lagerort',
        'locations:label:storage': 'Lagerort',
        'location:object-count': {
            '0': 'Keine Objekte',
            '1': '{n} Objekt',
            'n': '{n} Objekte',
        },
        'locations:headline:storage': 'Lagerort:',
        'locations:headline:location': 'Standort:',
        'locations:response:movedItem': { // Should be obsolete
            0: '{n} items were moved to your location {storageLink}',
            1: '{n} item was moved to your location {storageLink}',
            n: '{n} items were moved to your location {storageLink}',
        },
        'locations:response:change-location': {
            0: 'Keine Objekte wurden zum {storageType} {storageLink} bewegt',
            1: '{objectLink} wurde zum {storageType} {storageLink} bewegt',
            n: '{n} Objekte wurden zum {storageType} {storageLink} bewegt',
        },
        'locations:response:change-location:error': {
            0: 'Keine Objekte konnten zum {storageType} {storageLink} bewegt werden',
            1: '{objectLink} konnte nicht zum {storageType} {storageLink} bewegt werden',
            n: '{n} Objekte konnten nicht zum {storageType} {storageLink} bewegt werden',
        },
        'locations:response:removedItem': {
            0: 'Kein Objekt wurde vom Standort {storageLink} entfernt',
            1: '{n} Objekt wurde vom Standort {storageLink} entfernt',
            n: '{n} Objekte wurden vom Standort {storageLink} entfernt',
        },
        'locations:response:deleted': 'Ihr Lagerort {name} wurde gelöscht.',
        'locations:response:deleted:unknown-error': 'Ihr Lagerort {name} konnte leider nicht gelöscht werden. Bitte versuchen Sie es erneut',

        // Location selection/update
        'locations:update-location:headline': 'Standort wählen',
        'locations:update-location:selected-objects': {
            0: 'Keine Objekte ausgewählt',
            1: '{n} Objekt ausgewählt',
            n: '{n} Objekte ausgewählt',
        },
        'locations:response:create-storage-success': '{name} wurde erstellt',
        'locations:response:create-storage-show': 'Lagerort anzeigen',
        'locations:confirm-delete-location': 'Sind Sie sicher, dass Sie {name} löschen möchten?',
        'locations:successfully-deleted-location': '{name} wurde gelöscht',

        // Location Modal: Manage Storages
        'locations:manage-storages:title': 'Lagerorte verwalten',
        'locations:manage-storages:object-count': {
            0: 'Keine Lagerorte',
            1: '{n} Lagerort',
            n: '{n} Lagerorte',
        },
        'locations:manage-storages:cancel': 'Abbrechen',
        'locations:manage-storages:create': 'Fertig',
        'locations:manage-storages:save': 'Speichern',
        'locations:manage-storages:manageMode': 'Bearbeitungs-Modus',
        'locations:manage-storages:trash': 'Papierkorb',
        'locations:manage-storages:arrange': 'Sortieren',
        'locations:manage-storages:name': 'Umbenennen',
        'locations:manage-storages:simple-error': 'Etwas ist schief gelaufen',
        'locations:manage-storages:add-new-storage': 'Lagerort hinzufügen',
        'locations:manage-storages:info-text': 'Verschieben Sie Lagerorte im Bearbeitungs-Modus „Sortieren“ oder legen Sie sie in den Papierkorb. Wechseln Sie in den Bearbeitungsmodus „Umbenennen“ um neue Namen zu vergeben.',
        'locations:empty:introduction': 'Dieser Lagerort ist noch leer.<br/>Sie haben zwei verschiedene Möglichkeiten, um Objekte hierher zu verschieben:',
        'locations:empty:drag-and-drop:header': 'Drag and Drop',
        'locations:empty:drag-and-drop:body': 'Wechseln Sie zunächst zur <a href="{storagesUrl}">Standort-Übersicht</a>. Innerhalb der Miniaturansicht können Sie nun Objekte in die Lagerorte verschieben.',
        'locations:empty:manage-mode:header': 'Masseneditierung',
        'locations:empty:manage-mode:body': 'Nutzen Sie die Schaltfläche „Auswählen“ im <a href="{catalogUrl}">Katalog</a> um mehrere Objekte markieren zu können. Im Anschluss wählen Sie unter dem Punkt „Aktionen“ einen Lagerort aus.',

        // Create stuff
        'create:headline': 'Objekt erstellen',
        'create:button:create': 'Speichern',
        'create:button:cancel': 'Abbrechen',
        'create:button:create-and-done': 'Speichern',
        'create:button:create-and-duplicate': 'Speichern und Duplizieren',
        'create:button:hide-hidden-fields': 'Versteckte Felder ausblenden',
        'create:button:show-hidden-fields': 'Versteckte Felder einblenden',
        'create:response:success:create-and-duplicate': 'Ihr Objekt {itemLink} wurde erstellt und dupliziert. Sie bearbeiten jetzt die Kopie.',
        'create:response:success:create': 'Ihr Objekt {itemLink} wurde erstellt.',
        'create:response:error:create': 'Beim Speichern ist leider etwas fehlgeschlagen. Bitte versuchen Sie es erneut.',

        // Edit stuff
        'edit:headline': 'Objekt bearbeiten',
        'edit:button:save': 'Speichern',
        'edit:button:cancel': 'Abbrechen',
        'edit:button:show-hidden-fields': 'Versteckte Felder zeigen',
        'edit:button:hide-hidden-fields': 'Versteckte Felder ausblenden',

        'edit:hint:required': '(Pflichtangabe)',
        'edit:hint:optional': '(optional)',

        'edit:base:message:wait-upload': 'Bitte warten Sie, bis alle Bilder hochgeladen worden sind',
        'edit:base:message:mandatory-fields': 'Erfassen Sie zumindest die Information {field1}, um speichern zu können', // Please enter at least an {field1} to create your object.

        'edit:base:field:object-number': 'Objektnummer',
        'edit:base:field:paragenesis': 'Paragenesis',

        'edit:object-id-selection:label-object-id': 'Objektnummer',
        'edit:object-id-selection:placeholder-object-id': 'Nummer eingeben',
        'edit:object-id-selection:last-inserted-was': 'Letzte Nr: ',

        'edit:image-selection:button-import-photobox': 'Photobox-Bilder importieren',
        'edit:image-selection:button-enable-dropbox': 'Mit Dropbox verbinden',
        'edit:image-selection:button-loading-dropbox': 'Lade Dropbox...',
        'edit:image-selection:button-import-from-dropbox': 'Aus Dropbox importieren',

        'edit:image-selection:button-choose-from-disk': 'Bilder hochladen',
        'edit:image-selection:button-take-picture': 'Foto aufnehmen',
        'edit:image-size:headline': 'Bild-Auflösungen',
        'edit:image-size:size:label:full': 'Original',
        'edit:image-size:size:label:large': 'Groß',
        'edit:image-size:size:label:medium': 'Mittel',
        'edit:image-size:size:label:small': 'Klein',
        'edit:image-size:hint': 'Laden Sie verkleinerte Bilder hoch um Speicherplatz zu sparen:\nGroß (~800kb/img), Mittel (~200kb/img), Klein (~50kb/img)\nDie tatsächlichen Größen können abweichen.',
        'edit:asset-selection:button-choose-from-disk': 'Dateien hochladen',

        'edit:rfid-connection:headline-rfid-connection': 'RFID Verknüpfung',
        'edit:rfid-connection:button-scan-object': 'Objekt scannen',
        'edit:rfid-connection:label-scanner': 'Scanner',
        'edit:rfid-connection:state-no-object-found': 'Kein Objekt gefunden',
        'edit:rfid-connection:state-object-connected': 'RFID Objekt verbunden',
        'edit:rfid-connection:button-rescan': 'Erneut Scannen',
        'edit:rfid-connection:button-disconnect': 'Verknüpfung lösen',

        'edit:species-selection:HeadlineSpecies': 'Minerale / Paragenesis',
        'edit:placeholder-species': 'Erstes Mineral eingeben',
        'edit:placeholder-add-species': 'Weiteres Mineral eingeben',

        'edit:species-selection:headline-proper-name': 'Objektname',
        'edit:placeholder-proper-name': 'Objektname eingeben',

        'edit:locality-selection:headline-locality': 'Fundort',

        // 'edit:locality-selection:locality-mine:hint': 'Add mine or area – or full, comma-separated information',
        'edit:locality-selection:locality-mine:hint': 'Trennen Sie mit Kommata und staffeln Sie von klein nach groß (zB: Sweet Home Mine, Mount Bross, Alma District, Colorado, United States)',

        'edit:dimension-selection:input-width': 'Breite',
        'edit:dimension-selection:input-height': 'Höhe',
        'edit:dimension-selection:input-depth': 'Tiefe',
        'edit:placeholder-weight': 'Gewicht erfassen',
        'edit:placeholder-weight-in-lbs': 'Gewicht erfassen in Pfund',
        'edit:placeholder-weight-in-oz': 'und Unzen',
        'edit:label-weight': 'Gewicht',
        'edit:label-weight-in-lbs': 'Gewicht in lbs',
        'edit:label-weight-in-oz': 'oz',
        'edit:placeholder-max-crystal-size': 'Maximale Kristallgröße erfassen',
        'edit:label-max-crystal-size': 'Max. Kristallgröße',

        'edit:description:headline-description': 'Beschreibung',
        'edit:description:placeholde-type-object-description': 'Objektbeschreibung verfassen',

        'edit:storage:headline': 'Standortinformation',
        'edit:storage:button-choose-storage': 'Standort wählen',
        'edit:storage:button-remove-storage': 'Auswahl zurücknehmen',

        'edit:set:headline': 'Set-Mitgliedschaft',
        'edit:set:button-choose-set': 'Zu Set hinzufügen',
        'edit:set:button-remove-set': 'Auswahl zurücknehmen',

        'edit:prices-values:headline': 'Preise und Werte',
        'edit:prices-values:input-estimated-value': 'Geschätzten Wert erfassen',
        'edit:prices-values:input-insurance-value': 'Versicherungswert erfassen',
        'edit:prices-values:input-retail-price': 'Verkaufspreis erfassen',
        'edit:prices-values:label-estimated-value': 'Geschätzter Wert',
        'edit:prices-values:label-insurance-value': 'Versicherungswert',
        'edit:prices-values:label-retail-price': 'Verkaufspreis',

        'edit:notes:headline': 'Notizen',
        'edit:notes:placeholder': 'Notizen verfassen',

        'edit:damage-repairs-treatments:headline': 'Schäden, Reparaturen, Aufbereitungen',
        'edit:damage-repairs-treatments:placeholder': 'Objektzustand erfassen',

        'edit:object-history:date:label': 'Am',
        'edit:object-history:date:year:placeholder': 'Jahr',
        'edit:object-history:date:month:placeholder': 'Monat',
        'edit:object-history:date:day:placeholder': 'Tag',
        'edit:object-history:price:label': 'Für',
        'edit:object-history:price:placeholder': 'Kaufpreis erfassen',
        'edit:object-history:from:label': 'Von',
        'edit:object-history:from:placeholder': 'Bezugsquelle erfassen',

        'edit:object-history:previous-owners:label': 'Besitzer und Kollektionen',
        'edit:object-history:previous-owners:placeholder': 'Weitere Besitzer und Kollektionen erfassen',
        'edit:general:x-hidden-fields': {
            0: '0 Eingabefelder werden zur Zeit ausgeblendet:',
            1: '1 Eingabefeld wird zur Zeit ausgeblendet:',
            n: '{n} Eingabefelder werden zur Zeit ausgeblendet:',
        },
        'edit:drop-image-for-upload': 'Ziehen Sie Bilder hierher, um sie hochzuladen',
        'edit:message:unsaved-changes': 'Sie haben nicht gespeicherte Änderungen. Möchten Sie den Dialog wirklich verlassen?',

        'edit:response:success:update': 'Ihr Objekt {itemLink} wurde erfolgreich aktualisiert.',
        'edit:response:error:update': 'Wir konnten die Änderungen leider nicht speichern. Bitte versuchen Sie es erneut.',

        // Photobox Album
        'album:response:deleted': 'Ihr Album {name} wurde gelöscht.',
        'album:modal:cancel': 'Abbrechen',
        'album:modal:done': 'Fertig',
        'album:modal:new-albums': 'Neue Alben',
        'album:modal:unused-images': 'Unbenutzte Bilder',
        'album:modal:photobox-image-library': 'Photobox Bildbibliothek',
        'album:modal:choose-images-and-click-done': 'Wählen Sie Bilder und klicken Sie auf „fertig“',
        'album:modal:click-on-album-to-use-images': 'Klicken Sie auf eine Album um die Bilder zu nutzen',
        'album:modal:rfid-connected': 'RFID verknüpft',
        'album:modal:no-rfid-connected': 'kein RFID verknüpft',
        'album:modal:album:modal:images': {
            0: 'Keine Bilder',
            1: '{n} Bild',
            n: '{n} Bilder',
        },
        'album:modal:album:modal:selected': {
            0: 'Ausgewählt: 0',
            1: 'Ausgewählt: {n}',
            n: 'Ausgewählt: {n}',
        },

        // Import
        'import:headline': 'Datenbank importieren',
        'import:button:cancel': 'Abbrechen',
        'import:button:next': 'Nächster Schritt',
        'import:button:start-over': 'Neu beginnen',
        'import:button:save-and-done': 'Abschließen',
        'import:button:start-import': 'Nächster Schritt',
        'import:button:re-match': 'Neu verknüpfen',
        'import:button:dismiss': 'Verwerfen',
        'import:button:include': 'Aufnehmen',
        'import:subline-step': 'Schritt {step} von {stepTotal}',
        'import:button-done': 'Abschließen',
        'import:button-next': 'Nächster Schritt',
        'import:match-invalid': 'Bitte verknüpfen oder verwerfen Sie die markierten Spalten',
        'import:response:import-started': 'Ihr Import wurde zur Warteschlange hinzugefügt. Sie werden informiert, sobald Ihr Import abgeschlossen ist.',
        'import:response:import-finished': 'Ihr Import von {name} wurde fertiggestellt.',
        'import:button:show-import': 'Import anzeigen',
        'import:upload:text:introduction': 'Durch den Import einer bereits existierenden Datenbank können Sie Ihren Colido-Katalog in nur wenigen Minuten aufbauen. Allerdings ist diese Funktion noch im Beta-Status. Sollten sich also Probleme zeigen, schreiben Sie uns bitte eine E-Mail an',
        'import:upload:headline:import-file-type': 'CSV-Datei hochladen',
        'import:upload:text:limitation': 'Der Import ist zur Zeit auf 5000 Objekte beschränkt',
        'import:match:headline:match-columns': 'Spalten verknüpfen',
        'import:match:text:match-introduction': 'Indem Sie die Spalten Ihrer hochgeladenen Datenbank mit den Datenfeldern in Colido verknüpfen, stellen Sie sicher, dass Ihre Datenbank korrekt importiert wird. Spalten, die Sie nicht importieren möchten, können Sie verwerfen. Wir haben bereits versucht erste Felder zu verknüpfen.',
        'import:match:headline:default-units': 'Standard-Einheiten und Währungen',
        'import:match:text:default-units-instruction': 'Wählen Sie zunächst die Maßeinheiten und Währungen, die Sie standardmäßig verwenden. Informationen, die in Ihrer Datenbank bereits enthalten sind, werden hierdurch nicht überschrieben.',
        'import:match:headline:missing-fields': 'Fehlende Felder',
        'import:match:text:missing-fields': 'Falls Sie ein spezielles Datenfeld bei Colido vermissen, verknüpfen Sie Ihre entsprechende Spalte einfach mit dem Notizen- oder Beschreibungsfeld.',
        'import:match:headline:please-check': 'Bitte prüfen',
        'import:match:text:please-check': 'Sind alle Spalten, die Sie übernehmen wollen blau markiert? Sind alle Spalten richtig verknüpft und sind alle Maßeinheiten und Währungen korrekt?',
        'import:match:text:columns-matched': 'Spalten verknüpft',
        'import:match:text:columns-dismissed': 'Spalten verworfen',
        'import:review:text:look-at-object-table': 'Bitte schauen Sie sich die unten stehende Objektliste genau an. Wenn Sie das Gefühl haben, dass alles richtig verknüpft wurde, klicken Sie bitte auf',
        'import:review:text:fix': 'Falls etwas schiefgelaufen ist, gehen Sie zurück zum',
        'import:review:link:done': '„Abschließen“',
        'import:review:link:previous-step': 'vorherigen Schritt',
        'import:text:duplciate-key': 'Falls es bereits ein Objekt mit der gleichen »Objektnummer« gibt:',
        'import:label:duplciate-key-discard': 'Nichts tun',
        'import:label:duplciate-key-update': 'Das existierende Objekt aktualisieren',
        'import:label:duplciate-key-create': 'Ein Duplikat anlegen',

        // Column-filter
        'column-filter:button': 'Spalten einrichten',
        'column-filter:done': 'Fertig',
        'column-filter:title': 'Spaltendarstellung',
        'column-filter:shown': 'eingeblendet',
        'column-filter:hidden': 'ausgeblendet',

        'view-switch-detail:label': 'Ansicht',
        'view-switch-detail:list-view': 'Listenansicht',
        'view-switch-detail:grid-view': 'Miniaturansicht',
        'manage-storages:label': 'Lagerorte verwalten',

        'list-control:sort-by:label': 'Sortierung',
        'list-control:sort-by:last-edited': 'Bearbeitet',
        'list-control:sort-by:name': 'Name',
        'list-control:sort-by:locality': 'Fundort',
        'list-control:sort-by:object-id': 'Nummer',
        'list-control:sort-by:object-count': 'Objektmenge',

        'list-control:manage-objects:label': 'Auswählen',
        'list-control:manage-objects:select-all': 'Alle auswählen',
        'list-control:manage-objects:deselect-all': 'Auswahl aufheben',
        'list-control:manage-objects:actions': 'Aktionen',
        'list-control:manage-objects:delete': 'Löschen',
        'list-control:manage-objects:please-select-some-objects': 'Wählen Sie einige Objekte aus',
        'list-control:manage-objects:add-to-set': 'Zu Set hinzufügen',
        'list-control:manage-objects:remove-from-set': 'Von Set entfernen',
        'list-control:manage-objects:update-location': 'Standort aktualisieren',
        'list-control:manage-objects:change-status': 'Status ändern',
        'list-control:manage-objects:done': 'Beenden',
    },

    'share': {
        'share-set:response:error': 'Leider konnte Ihr Set nicht freigegeben werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal',
        'confirm:disable:set:share': 'Sind Sie sicher, dass Sie die Freigabe für dieses Set aufheben möchten? Der generierte Link zum Set wird im Anschluss nicht mehr erreichbar sein. Sobald Sie das Set erneut freigeben, wird ein neuer Link generiert.',
        'headline:set': '{title}',
        'headline:location': '{title}',
        'headline:object': '{title}',
        'subheadline:attribution': 'freigegeben von {name}',
        'by-mail:label': 'E-Mail',
        'by-mail:subject': 'Mein Mineralien Set »{name}« ist online!', //@todo
        'by-mail:body': 'Hi,\nmein Mineralien Set »{name}« ist nun online zugänglich und unter folgendem Link erreichbar: \n{url}\n\nPräsentiert von www.Colido.de', //@todo
        'by-twitter:label': 'Twitter',
        'by-twitter:body': 'Mein #Mineralien Set »{title}« jetzt online', //Check if restuling message is still under 140 chars if you change this title is limited to 80 chars //@todo
        'by-facebook:label': 'Facebook',
        'by-colido-talk:label': 'Colido Talk',
    },

    'restrictions': {
        'modal:title:filter': 'Der Katalog-Filter<br /> ist Teil unseres<br /> Premium-Angebots',
        'modal:title:sets': 'Zusätzliche Sets<br /> sind Teil unseres<br /> Premium-Angebots',
        'modal:title:images': 'Zusätzliche Bilder<br /> sind Teil unseres<br /> Premium-Angebots',
        'modal:title:storages': 'Die Standort-Verwaltung<br /> ist Teil unseres<br /> Premium-Angebots',
        'modal:title:quota': 'Zusätzlicher Speicherplatz<br /> ist Teil unseres<br /> Premium-Angebots',
        'modal:title:print': 'Konfigurierbare Listen sind Teil unseres Premium-Angebots',
        'modal:title:advanced-labelprint': 'Erweiterte Optionen <br />sind Teil unseres<br /> Premium-Angebots',
        'modal:btn:upgrade': 'Konto upgraden',
        'modal:btn:plans-and-pricing': 'Weitere Informationen zum Premium-Angebot',
        'sets:btn:delete': 'Set löschen',
        'quota:exceeded': 'Sie haben Ihren Speicherplatz von {quota} um {exceededBy} überzogen.',
        'quota:space-left': '{spaceLeft} Ihres Speicherplatzes sind noch frei.',
    },

    'subscription': {
        'modal:edit': 'Bearbeiten',
        'modal:cancel': 'Abbrechen',
        'modal:save': 'Speichern',

        'cancel': 'Abbrechen',

        'quota:label:usage-indicator': '{amount} belegt',
        'quota:text:exceeded-hint': 'Sie haben Ihr Speicherlimit erreicht.\nUm neue Objekte erfassen zu können, \nlöschen Sie bitte alte Objekte \nund Bilder oder führen Sie ein Upgrade durch.',

        'plan:text:currently-active': 'Derzeit nutzen Sie unseren {planName} Plan.',
        'plan:text:renewal-monthly': 'Ihr Abonnement wird am {validUntil} automatisch<br />zu einem monatlichen Preis von {amount} erneuert.',
        'plan:text:renewal-annually': 'Ihr Abonnement wird am {validUntil} automatisch<br />zu einem jährlichen Preis von {amount} erneuert.',
        'plan:text:upcoming-plan': 'Dieser Plan läuft noch bis zum {validUntil}.<br />Anschließend wechseln Sie in den {upcomingPlanName} Plan.',
        'plan:text:active': 'Aktiv',

        'plan:text:switch:from:basic:to:plus-monthly': 'Sie möchten Ihr Konto auf den Colido Plus Plan hochstufen. Ihre gewählte Zahlungsmethode wird monatlich mit {chosenPlanAmountMonthly} belastet.',
        'plan:text:switch:from:basic:to:plus-annually': 'Sie möchten Ihr Konto auf den Colido Plus Plan hochstufen. Ihre gewählte Zahlungsmethode wird jährlich mit {chosenPlanAmountAnnually} belastet.',
        'plan:text:switch:from:plus-monthly:to:basic': 'Sie möchten Ihr Konto auf den kostenlosen Basic Plan zurücksetzen. Bis zum {activePlanValidUntil} können Sie weiterhin die Premium-Funktionen nutzen.',
        'plan:text:switch:from:plus-monthly:to:plus-annually': 'Sie möchten zur jährlichen Zahlweise wechseln. Ihr gewähltes Zahlungsmittel wird direkt mit dem Jahresbeitrag von {chosenPlanAmountAnnually} weniger dem bereits bezahltem aktuellen Monatsbeitrag von {accountBalance} belastet.',
        'plan:text:switch:from:plus-annually:to:basic': 'Sie möchten Ihr Konto auf den kostenlosen Basic Plan zurücksetzen. Bis zum {activePlanValidUntil} können Sie weiterhin die Premium-Funktionen nutzen.',
        'plan:text:switch:from:plus-annually:to:plus-monthly': 'Sie möchten zur monatlichen Zahlweise wechseln. Da Sie für das laufende Jahr bereits voll bezahlt haben, wird die Umstellung erst zum {activePlanValidUntil} in Kraft treten.',

        'payment:headline:payment-method': 'Zahlungsmethode',
        'payment:headline:pay-with-creditcard': 'Mit Kreditkarte zahlen',
        'payment:headline:checkout-with-paypal': 'Via PayPal zahlen',
        'payment:headline:or': 'oder',
        'payment:button:proceed-to-billing-address': 'Zur Rechnungsadresse',
        'payment:button:save': 'Speichern',
        'payment:button:change-payment-method': 'Zahlungsmethode ändern',
        'payment:label:creditcard': 'Kreditkarte',
        'payment:label:paypal': 'PayPal',
        'payment:placeholder:cardholder': 'Name des Karteninhabers angeben',
        'payment:label:cardholder': 'Name auf der Karte',
        'payment:placeholder:card-number': 'Kartennummer eingeben',
        'payment:label:card-number': 'Karten Nr',
        'payment:placeholder:expiration-date': 'Ablaufdatum angeben (mm/jj)',
        'payment:label:expiration-date': 'MM/JJ',
        'payment:placeholder:cvc': 'Prüfnummer angeben',
        'payment:label:cvc': 'Prüfnummer',
        'payment:text:edit:hint': 'Sie können mit Kreditkarte oder via Paypal zahlen.\nIhr Abonnment wird {billingInterval} abgerechnet. \nSie können Ihr Abonnement jederzeit kündigen.',
        'payment:modal:title': 'Zahlungsmethode',
        'payment:creditcard:error': 'TODO',
        'payment:creditcard:error:empty': 'TODO',
        'payment:creditcard:error:card-holder': 'TODO',
        'payment:creditcard:error:card-number': 'TODO',
        'payment:creditcard:error:expire': 'TODO',
        'payment:creditcard:error:format': 'TODO',
        'payment:creditcard:error:CVV-CVC-CVS': 'TODO',

        'payment:text:charged-by-creditcard': 'Zur Zahlung wird Ihre Kreditkarte verwendet ({issuer}, endet auf {number}).',
        'payment:text:charged-by-paypal': 'Zur Zahlung wird Ihr Paypal-Konto ({account}) verwendet.',

        'payment:label:self-declare-country-of-residency': 'Hiermit erkläre ich, dass mein Wohnsitzland {residencyCountry} ist',
        'payment:text:country-of-residency': 'Ihr Wohnsitzland ist {residencyCountry}',

        'subscription:button:update-payment-method': 'Zahlungsmethode aktualisieren',
        'subscription:button:change-plan': 'Ändern',
        'subscription:button:cancel-subscription': 'Abonnement kündigen',

        'setup:title': 'Colido Upgrade',
        'setup:plans': 'Abonnement ändern oder kündigen',
        'setup:subline': 'Mehr Speicherplatz, noch bessere Funktionen',
        'setup:info-text': 'Der Plus Plan bietet Ihnen noch mehr Möglichkeiten Ihre Sammlung bestmöglich zu verwalten. Falls Sie sich für die jährliche Zahlweise entschließen, sparen Sie einen ganzen Monatsbeitrag.',
        'setup:choose-billing:label': 'Wählen Sie eine Zahlweise',
        'setup:choose-billing:monthly:label': 'Monatlich',
        'setup:choose-billing:annually:label': 'Jährlich',
        'setup:choose-billing:annually:hint': 'ein Monat gratis',
        'setup:choose-a-plan': 'Wählen Sie einen Abo-Plan',
        'setup:choose-a-plan:basic:hint': 'Kostenlos',
        'setup:choose-a-plan:basic:sub-hint': 'für immer',
        'setup:choose-a-plan:per-month:hint': '/Monat*',
        'setup:choose-a-plan:per-year:hint': '/Jahr*',
        'setup:choose-a-plan:choose-plan:button': 'Plan wählen',
        'setup:choose-a-plan:choose-plan:selected': 'Ausgewählt',
        'setup:choose-a-plan:choose-plan:all-prices-with-vat': ' * alle Preise inklusive Mehrwertsteuer',
        'setup:choose-a-plan:plan:close': 'Schließen',
        'setup:choose-a-plan:plan:select-paid': 'Wählen Sie einen Premium Plan',
        'setup:choose-a-plan:plan:tax-info': '*alle Preise inklusive gültiger MwSt',
        'setup:proceed-to-payment:button': 'Zur Zahlungsmethode',
        'setup:next:button': 'Zur Zahlungsmethode',
        'setup:cancel:button': 'Abbrechen',
        'setup:save:button': 'Speichern',
        'setup:check-before-pay:hint': 'Bitte überprüfen Sie abermals Ihre Angaben und klicken Sie im Anschluss \nauf „Jetzt zahlen“ um das Abonnement abzuschließen.',

        'setup:plan-display:title': 'Gebuchter Plan',
        'setup:plan-display:table:plan': 'Plan',
        'setup:plan-display:table:billing': 'Zahlweise',
        'setup:plan-display:table:status': 'Status',
        'setup:plan-display:table:price': 'Preis',
        'setup:plan-display:table:first-year-discount-price': 'Erstes Jahr Rabatt Preis',
        'setup:plan-display:table:including-50-discount': 'beinhaltet 50% Rabatt',
        'setup:plan-display:table:tax': 'MwSt',
        'setup:plan-display:table:total': 'Gesamt',
        'setup:plan-display:table:tax-country': 'Steuerliches Empfangsland',
        'setup:plan-display:table:learn-more': 'Mehr erfahren',
        'setup:plan-display:change-plan': 'Abonnement ändern',
        'setup:plan-display:table:status-active': 'aktiv',
        'setup:plan-display:table:status-upcoming': 'zukünftig',
        'setup:plan-display:table:active-until': 'Dieser Plan ist bis zum {endDate} aktiv',
        'setup:plan-display:table:active-from': 'Dieser Plan wird am {startDate} aktiviert werden',
        'setup:plan-display:table:enter-voucher-code': 'Gutschein angeben',
        'setup:plan-display:table:billingInterval:monthly': 'monatlich',
        'setup:plan-display:table:billingInterval:annually': 'jährlich',
        'setup:button:proceed-to-payment': 'Zur Zahlungsmethode',
        'setup:button:proceed-to-billing-address': 'Zur Rechnungsadresse',
        'setup:button:proceed-to-checkout': 'Zur Kasse',
        'setup:voucher:headline:please-enter-voucher': 'Bitte geben Sie Ihren Gutschein-Code ein',
        'setup:voucher:button:cancel': 'Abbrechen',
        'setup:voucher:button:verify': 'Prüfen',
        'setup:voucher:placeholder:type-voucher-code': 'Gutschein-Code eingeben',
        'setup:voucher:text:response-success': 'Der Gutschein-Code ist gültig. Bitte warten ...',
        'setup:voucher:text:response:code-expired': 'Der Gutschein-Code ist nicht länger gültig.',
        'setup:voucher:text:response:code-invalid-for-selected-plan': 'Der eingegebene Gutschein-Code gilt ausschließlich für jährlich berechneten Plus Plan. Bitte ändern Sie Ihre Auswahl entsprechend.',
        'setup:voucher:text:response:code-amount-exceeded': 'Der Gutschein-Code ist ungültig. Bitte überprüfen Sie Ihre Eingabe oder senden Sie uns eine E-Mail an <a href="mailto:support@colido.de?subject=Gutschein-Code ungültig">support@colido.de</a>.',
        'setup:voucher:text:response:code-invalid': 'Der Gutschein-Code ist ungültig. Bitte überprüfen Sie Ihre Eingabe oder senden Sie uns eine E-Mail an <a href="mailto:support@colido.de?subject=Gutschein-Code ungültig">support@colido.de</a>.',
        'setup:enter-voucher-code:button': 'Gutschein-Code eingeben',
        'setup:link:change-your-plan': 'Wechseln Sie den Plan',

        'setup:billing-address:title': 'Rechnungsadresse',
        'setup:billing-address:save': 'Speichern',
        'setup:billing-address:hint': 'Falls Sie als Geschäftskunde eine ordentliche Rechnung benötigen, können Sie hier Ihre Umsatzsteuernummer und Rechnungsadresse angeben.',
        'setup:billing-address:vat:title': 'Umsatzsteuer-Identifikationsnummer',
        'setup:billing-address:vat:vat-number:label': 'USt-IdNr.',
        'setup:billing-address:vat:vat-number:placeholder': 'USt-Identifikationsnummer eingeben',
        'setup:billing-address:vat:hint': 'Falls Sie Inhaber einer europäischen USt-Identifikationsnummer sind, füllen Sie dieses Feld bitte aus',
        'setup:billing-address:billing-address:label': 'Rechnungsadresse',
        'setup:billing-address:billing-address:your-name:label': 'Name',
        'setup:billing-address:billing-address:name:label': 'Name',
        'setup:billing-address:billing-address:name:placeholder': 'Vollständigen Namen eingeben',
        'setup:billing-address:billing-address:company:label': 'Unternehmen',
        'setup:billing-address:billing-address:company:placeholder': 'Firmennamen eingeben',
        'setup:billing-address:billing-address:street:label': 'Straße / Hausnummer',
        'setup:billing-address:billing-address:street:placeholder': 'Straße und Hausnummer eingeben',
        'setup:billing-address:billing-address:zip:label': 'PLZ',
        'setup:billing-address:billing-address:zip:placeholder': 'Postleitzahl eingeben',
        'setup:billing-address:billing-address:city:label': 'Stadt',
        'setup:billing-address:billing-address:city:placeholder': 'Stadt eingeben',
        'setup:billing-address:billing-address:choose-country:label': 'Land wählen',
        'setup:billing-address:billing-address:country:label': 'Land wählen',
        'setup:billing-address:billing-address:empty': 'Rechnungsadresse nicht angegeben',
        'setup:billing-address:billing-address:change-billing-address': 'Rechnungsadresse ändern',
        'setup:billing-address:error:fullname': 'Bitte geben Sie einen Namen oder eine Firma an',
        'setup:billing-address:error:company': 'Bitte geben Sie einen Namen oder eine Firma an',
        'setup:billing-address:error:country': 'Bitte wählen Sie ein Land',

        'setup:tax:title': 'Angabe Wohnsitzland',
        'setup:tax:country': 'Land des Wohnsitzes',
        'setup:tax:no-country-selected': 'Kein Wohnsitzland angegeben',
        'setup:tax:change-country': 'Wohnsitzland ändern',
        'setup:tax:eu-hint': 'Um den neuen Verbraucherrechte-Richtlinien der EU entsprechen \nzu können, müssen wir Ihren Wohnsitz erfassen. Dies ermöglicht uns \ndie korrekte Umsatzsteuerzahlung an das entsprechende Land. \nIhr zu zahlender Gesamtpreis ändert sich hierdurch nicht.',
        'setup:tax:choose-country': 'Land wählen',
        'setup:tax:self-declare-country': 'Ich erkläre mein Wohnsitzland selbst',
        'setup:tax:save': 'Speichern',
        'setup:tax:modal:title': 'Land des Wohnsitzes',

        'payment-history:title': 'Zahlungen',
        'payment-history:table:plan': 'Plan',
        'payment-history:table:amount': 'Zahlung',
        'payment-history:table:date': 'Datum',
        'payment-history:table:payment-method': 'Zahlungsmethode',
        'payment-history:table:receipt': 'Rechnung',
        'payment-history:table:id': 'Nummer',
        'payment-history:table:item:receipt': 'Rechnung laden',
        'payment-history:table:empty': 'Bisher wurden keine Zahlungen getätigt',

        'quota:title': 'Speicherplatz',
        'overview:title': 'Abonnement',

        'setup:button:proceed-checkout': 'Zur Kasse',
        'setup:button:pay-amount': 'Jetzt {amount} zahlen',
        'setup:button:upgrade-amount': 'Jetzt hochstufen für {amount} {currency}',
        'setup:button:switch-plan': 'Plan ändern',
        'setup:message:error-checkout-process': 'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
        'setup:button:try-again': 'Versuchen Sie es erneut',

        'help:title': 'Benötigen Sie Hilfe?',
        'help:text': 'Senden Sie uns eine E-Mail an <a href="mailto:support@colido.de">support@colido.de</a><br />und wir melden uns schnellstmöglich zurück.',
    },

    community: {
        'label': 'Talk',
        'headline': 'Colido Talk',
        'subheadline': 'Let’s talk collections!',
        'tab:label:contacs': 'Kontakte',
        'tab:label:groups': 'Gruppen',
        'tab:label:broadcasts': 'Broadcast',
        'groups-count': {
            0: 'Keine Gruppen',
            1: '{n} Gruppe',
            n: '{n} Gruppen',
        },
        'broadcasts-count': {
            0: 'Keine Broadcast-Liste',
            1: '{n} Broadcast-Liste',
            n: '{n} Broadcast-Listen',
        },
        'contacts-count': {
            0: 'Keine Kontakte',
            1: '{n} Kontakt',
            n: '{n} Kontakte',
        },
        'contacts-pending-count': {
            0: 'Keine ausstehende Kontaktanfragen',
            1: '{n} ausstehende Kontaktanfrage',
            n: '{n} ausstehende Kontaktanfragen',
        },
        'group:button:label:create': 'Neue Gruppe',
        'broadcast:button:label:create': 'Neue Liste',
        'button:label:invite-friends': 'Kontakte einladen',
        'button:label:friend-request:accept': 'Annehmen',
        'button:label:friend-request:remove': 'Entfernen',
        'button:label:conversation:submit': 'Senden',
        'input:placeholder:new-post': 'Nachricht eingeben',
        'info:friend-request:sent': 'Wartet auf Bestätigung durch Kontakt',
        'contacts:empty': 'Sie haben noch keine Kontakte zu Colido hinzugefügt. Bitte laden Sie Freunde und Partner ein, um mit diesen in Colido kommunizieren zu können.\n\nSobald Ihre Kontakte die Anfrage bestätigt haben, können Sie private Nachrichten versenden und Chat-Gruppen einrichten.',
        'groups:empty': 'Sie wurden bisher zu keiner Chat-Gruppe eingeladen. Sie können allerdings selbst eine Gruppe anlegen und Kontakte aus Ihrer Kontaktliste einladen.',
        'contact:action:confirm-delete': 'Sind Sie sicher, dass Sie den Kontakt {name} entfernen möchten?',
        'contact:response:deleted': 'Ihr Kontakt »{name}« wurde entfernt',
        'contact:response:deleted:error': 'Ihr Kontakt »{name}« konnte nicht entfernt werden',
        'contact:response:accepted': 'Der Kontakt »{name}« wurde hinzugefügt',
        'contact:response:accepted:error': 'Der Kontakt »{name}« konnte nicht hinzugefügt werden',
        'contact:response:added': 'Die Kontaktanfrage an {name} wurde versandt. Sobald der Kontakt Ihre Anfrage bestätigt hat, können Sie miteinander kommunizieren',
        'contact:response:added:error': 'Die Kontaktanfrage an {name} konnte nicht gesendet werden',
        'contact:response:duplicate': '»{name}« ist bereits in Ihrer Kontaktliste',
        'contact:response:pending': 'An diese E-Mail-Adresse wurde bereits eine Anfrage gesendet',
        'contact:response:invalid-email': 'Die E-Mail-Adresse {name} ist ungültig',
        'contact:actions:label': 'Aktionen',
        'group:action:confirm-leave': 'Sind Sie sicher, dass Sie die Gruppe »{name}« verlassen möchten?',
        'group:action:confirm-delete': 'Sind Sie sicher, dass Sie die Gruppe »{name}« löschen möchten?',
        'group:response:left': 'Sie haben die Gruppe »{name}« verlassen',
        'group:response:left:error': 'Das Verlassen der Gruppe hat nicht funktioniert',
        'group:response:deleted': 'Sie haben die Gruppe »{name}« gelöscht',
        'group:response:deleted:error': 'Das Löschen der Gruppe »{name}« hat nicht funktioniert',
        'group:actions:label': 'Aktionen',
        'group:action:headline': 'Aktionen',
        'group:action:rename': 'Gruppe umbenennen',
        'group:action:change-users': 'Kontakte einladen',
        'group:action:leave': 'Gruppe verlassen',
        'group:action:delete': 'Gruppe löschen',
        'group:input:label:name': 'Gruppenname',
        'group:input:placeholder:name': 'Gruppennamen eingeben',
        'group:button:label:create:submit': 'Gruppe erstellen',
        'group:button:label:update:submit': 'Speichern',
        'group:legend:contacts': 'Kontakte hinzufügen',
        'group:legend:current-members': 'Aktuelle Mitglieder',
        'group:response:invalid-form': 'Eine Gruppe benötigt einen Gruppennamen sowie mindestens ein weiteres Mitglied.',
        'group:modal:title:edit': 'Gruppe editieren',
        'group:show-all-users': 'Alle {n} Mitglieder anzeigen',
        'broadcasts:empty': 'Mit der Broadcast-Funktion können Sie eine Liste mit Kontakten erstellen, an die Sie schnell und einfach private Nachrichten versenden können.\n\nDie Empfänger einer Nachricht sehen weder, an welche anderen Kontakte diese Nachricht versendet wurde, noch dass die Nachricht über die Broadcast-Funktion gesendet wurde.',
        'broadcast:action:confirm-delete': 'Sind Sie sicher, dass Sie die Broadcast-Liste »{name}« löschen möchten?',
        'broadcast:legend:contacts': 'Empfänger',
        'broadcast:input:label:name': 'Neue Broadcast-Liste',
        'broadcast:input:placeholder:name': 'Listenname eingeben',
        'broadcast:button:label:create:submit': 'Broadcast-Liste anlegen',
        'broadcast:modal:title:edit': 'Broadcast-Liste bearbeiten',
        'broadcast:action:rename': 'Broadcast-Liste umbenennen',
        'broadcast:action:change-users': 'Empfänger bearbeiten',
        'broadcast:action:delete': 'Broadcast-Liste löschen',
        'button:label:new-contacts:submit': 'Einladung versenden',
        'button:label:send-message': 'Private Nachricht senden',
        'button:label:edit-profile': 'Profil editieren',
        'button:label:send-friend-request': 'Kontaktanfrage senden',
        'broadcast:response:deleted': 'Sie haben die Broadcast-Liste »{name}« gelöscht',
        'broadcast:response:deleted:error': 'Das Löschen der Broadcast-Liste »{name}« hat nicht funktioniert',
        'input:label:new-contacts:email': 'E-Mail-Adresse',
        'input:placeholder:new-contacts:email': 'info@example.de',
        'input:label:new-contacts:message': 'Nachricht (optional)',
        'new-contacts:hint': 'Bitte geben Sie die E-Mail-Adresse des Kontakts ein, den Sie zu Colido hinzufügen oder einladen möchten. Sie haben zusätzlich die Möglichkeit eine persönliche Nachricht mitzusenden.',
        'button:label:group:leave': 'Gruppe verlassen',
        'conversation-post:empty': 'Schreiben Sie jetzt Ihre erste Nachricht!',
        'conversation-post:group-renamed': '{username} hat diese Gruppe in »{toTitle}« umbenannt', //provided by API
        'conversation-post:user-left-group': '{username} hat die Gruppe verlassen', //provided by API
        'conversation-post:user-joined-group': '{username} ist der Gruppe beigetreten', //provided by API
        'conversation-post:contact-request-sent': 'Kontaktanfrage von {username} gesendet.', //provided by API //both sender and recipient receive the same message
        'conversation-post:contact-request-accepted': 'Kontaktanfrage von {username} akzeptiert.', //provided by API //both sender and recipient receive the same message
        'notification:new-message': 'Neue Colido Talk Nachricht',
        'notification:unread-messages': {
            0: 'Sie haben {n} ungelesene Nachrichten.',
            1: 'Sie haben {n} ungelesene Nachricht.',
            n: 'Sie haben {n} ungelesene Nachrichten.',
        },
        'restriction-modal:headline': 'Bitte Profil einrichten',
        'restriction-modal:text': 'Um Colido Talk nutzen zu können, müssen Sie zunächst kurz Ihr Profil einrichten.',
        'restriction-modal:button-profile': 'Zum Profil',
        'share-set:message-default-contact': 'Das Set {setName} wurde mit {name} geteilt',
        'share-set:message-default-group': 'Das Set {setName} wurde mit der Gruppe {name} geteilt',
        'share-set:message-default-broadcast': 'Das Set {setName} wurde an die Broadcast-Liste {name} versendet',
        'share-set:message-sahred-contact': 'Das Set {setName} ist nun freigegeben und wurde mit {name} geteilt',
        'share-set:message-sahred-group': 'Das Set {setName} ist nun freigegeben und wurde mit der Gruppe {name} geteilt',
        'share-set:message-sahred-broadcast': 'Das Set {setName} ist nun freigegeben und wurde an die Broadcast-Liste {name} versendet',
        'share-set:unavailable': 'Das geteilte Set ist nicht mehr verfügbar',
        'share-set:object-count': {
            '0': 'Keine Objekte',
            '1': '{n} Objekt',
            'n': '{n} Objekte',
        },
        'share-set:modal:go-back': 'Zurück zu Colido Talk',
        'share-menu:title': 'Teilen Sie Ihre Sets',
        'share-menu:empty': 'Bitte geben Sie zuerst ein Set frei',
        'share-conversation:modal:headline': 'Teilen in Colido Talk',

        'shop:post:offer-completed:buyer': 'Sie haben ein neues Objekt {targetObjectLink} in Ihrem Katalog {targetCatalogLink}.',
        'shop:post:offer-completed:seller': 'Das Objekt wurde aus dem öffentlichen Marktplatz entfernt und einige Attribute wurden geändert (Status: veräußert, Standort: Out of collection, ...).',
    },
};