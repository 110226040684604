module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="edit-container page-container" data-step="1">\n    <form method="post" id="createObjectForm" class="form-horizontal" autocomplete="off">\n        <header class="modal-header">\n            <div class="header-part">\n                <ul>\n                    <li>\n                        <h2>'+
((__t=(t('collection::edit:headline')))==null?'':_.escape(__t))+
'</h2>\n                        <h2 class="subline">'+
((__t=(item.editHeadline))==null?'':_.escape(__t))+
'</h2>\n                    </li>\n                    <li class="actions">\n                        <div class="hidden-fields-button">\n                            <button type="button" class="btn btn-clean" data-action="hide-hidden-fields">'+
((__t=(t('collection::edit:button:hide-hidden-fields')))==null?'':_.escape(__t))+
'</button>\n                            <button type="button" class="btn btn-clean" data-action="show-hidden-fields">'+
((__t=(t('collection::edit:button:show-hidden-fields')))==null?'':_.escape(__t))+
'</button>\n                        </div>\n\n                        <button type="reset" class="btn btn-secondary" data-action="cancel-edit-object">'+
((__t=(t('collection::edit:button:cancel')))==null?'':_.escape(__t))+
'</button>\n                        <div class="btn-submit-wrapper" data-gravity="e">\n                            <button type="button" class="btn btn-primary" data-action="done-edit-object">'+
((__t=(t('collection::edit:button:save')))==null?'':_.escape(__t))+
'</button>\n                            <div class="btn-submit-wrapper-layer"></div>\n                        </div>\n                    </li>\n                </ul>\n            </div>\n        </header>\n\n        <div class="modal-body">\n            <div class="modal-content container component-listing">\n                ';
 _.each(item.fields, function(field){ 
__p+='\n                    <div class="field-'+
((__t=(_.camelToHyphen(field.name)))==null?'':_.escape(__t))+
'-region"></div>\n                ';
 }) 
__p+='\n            </div>\n        </div>\n    </form>\n\n    <div class="drop-files-overlay">\n        <div class="drop-files-overlay-inner">\n            <span>'+
((__t=( t('collection::edit:drop-image-for-upload') ))==null?'':_.escape(__t))+
'</span>\n        </div>\n    </div>\n</div>';
}
return __p;
};