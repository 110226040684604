'use strict';

// Add string functions
var ltrim = require('vendor/underscore.string/ltrim');
var rtrim = require('vendor/underscore.string/rtrim');
var truncate = require('vendor/underscore.string/truncate');

_.mixin({
    ltrim: ltrim,
    rtrim: rtrim,
    truncate: truncate,
});

// Add colido functions
_.mixin({
    /**
     * Returns array of "slotCount" length. Each slot containing the number of hits in this sub range
     * Ie. if you have 50 random values in the range from 0-100 and you want to know the distribution for
     * 10 slots _.distribution([...], 10) will return an array with the count of numbers between 0 - 10
     * in the first array field, and the count of numbers between 10 - 20 in the second, and so on.
     *
     * @param {arra} values array of numbers
     * @param {integer} slotCount number of ranges the distribution
     * @returns {array} The distribution of the values over slotCount ranges
     */
    distribution: function(values, slotCount) {
        slotCount = parseInt(slotCount, 10) > 1 ? parseInt(slotCount, 10) : 1;
        var result = [];
        var count = slotCount;

        while (count--) {
            result[count] = 0;
        }

        var max = _.max(values);
        var min = _.min(values);
        var range = max - min;
        if (max !== -Infinity && max !== min) {
            var slotWidth = range / slotCount;

            for (var i = 0, count = values.length; i < count; i++) {
                if (_.isNumber(values[i])) {
                    var slotIndex = Math.ceil((values[i] - min) / slotWidth);
                    slotIndex = slotIndex >= 1 ? slotIndex - 1 : 0;

                    result[slotIndex]++;
                }
            }
        }

        return result;
    },

    /**
     * Same as distribution but returns percentages instead of absolute counts per sub range,
     * where the highest slot(s) will define 100%;
     *
     * @param {arra} values array of numbers
     * @param {integer} slotCount number of ranges the distribution
     * @returns {array} The distribution of the values over slotCount ranges
     */
    relativeDistribution: function(values, slotCount) {
        var result = _.distribution(values, slotCount);
        var max = _.max(result);

        if (max > 0) {
            for(var i = 0, count = result.length; i < count; i++) {
                result[i] = (result[i] / max) * 100;
            }
        }

        return result;
    },

    /**
     * Makes first letter lowercase
     * @param {string} string The input string
     * @returns {string} The output string
     */
    lcfirst: function(string) {
        if (!_.isString(string)) {
            return string;
        }

        return string.charAt(0).toLowerCase() + string.slice(1);
    },

    /**
     * Makes first letter uppercase
     * @param {string} string The input string
     * @returns {string} The output string
     */
    ucfirst: function(string) {
        if (!_.isString(string)) {
            return string;
        }

        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    /**
     * Makes the string cammelCased
     * @param {string} string The input string
     * @returns {string} The output string
     */
    camelcase: function(string) {
        if (!_.isString(string)) {
            return string;
        }

        return string.toLowerCase().replace(/-(.)/g, function(match, group1) {
            return group1.toUpperCase();
        });
    },

    /**
     * Converts a camel case string in to a hyphenated string
     * @param {string}  string The string to convert
     * @param {string} concat (Optional) The char to concatenate
     * @returns {string}
     */
    camelToHyphen: function(string, concat) {
        if (!_.isString(string)) {
            return string;
        }

        concat = concat || '-';

        return string.replace(/([a-z])([A-Z])/g, '$1' + concat + '$2').toLowerCase();
    },

    /**
     * Converts a space separated string into a initials
     * @param {string}  string The string to convert
     * @param {integer} maxLength (Optional) 1 or 2 number of initials to be returned
     * @returns {string}
     */
    initials: function(string, maxLength) {
        if (!_.isString(string)) {
            return string;
        }

        maxLength = maxLength || 1;

        var firstChars = _.map(_.compact(string.trim().split(' ')), function(part) {
            return part.charAt(0);
        }).join('');

        var initials = string.charAt(0);

        if (maxLength > 1 && firstChars.length > 1) {
            initials += firstChars[firstChars.length - 1];
        }

        return initials.toUpperCase();
    },

    /**
     * Returns a part of an array for the given count and offset
     * @param {Array}  arr    The base array
     * @param {number} count  The number of items to return
     * @param {number} offset Array postion, where to start.
     * @returns {Array} The part of the array
     */
    part: function(arr, count, offset) {
        if (!_.isArray(arr)) {
            return [];
        }

        // Set proper count and offset
        count = Math.abs(count);
        offset = offset || 0;

        // Set a proper offset
        if (Math.abs(offset) > arr.length) {
            offset = offset % arr.length;
        }

        // Set proper count
        if (count > arr.length) {
            count = arr.length;
        }

        var part1 = [];
        var part2 = [];

        if (offset >= 0) {
            part1 = arr.slice(offset, Math.min(offset + count, arr.length));

            // Take from beginning?
            if (offset + count > arr.length) {
                var diff = offset + count - arr.length;
                part2 = arr.slice(0, diff);
            }
        } else {
            var start = arr.length + offset;
            part1 = arr.slice(start, Math.min(start + count, arr.length));

            // Take from beginning?
            if (start + count > arr.length) {
                part2 = arr.slice(0, count + offset);
            }
        }

        return [].concat(part1, part2);
    },

    /**
     * Micro template
     * @see http://mir.aculo.us/2011/03/09/little-helpers-a-tweet-sized-javascript-templating-engine/
     * @param {string} s The string
     * @param {object} d The data
     * @returns {string} The templated string
     */
    tpl: function(s, d) {
        for (var p in d) {
            s = s.replace(new RegExp('{' + p + '}', 'g'), d[p]);
        }

        return s;
    },

    //@TODO Switch to lodash then remove these Math functions (decimalAdjust, round, ceil, floor)

    /**
    * Decimal adjustment of a number.
    *
    * @param {String}  type  The type of adjustment.
    * @param {Number}  value The number.
    * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
    * @returns {Number} The adjusted value.
    */
    decimalAdjust: function(type, value, exp) {
        // If the exp is undefined or zero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }

        value = +value;
        exp = +exp;

        // If the value is not a number or the exp is not an integer...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }

        exp = exp * -1;

        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));

        // Shift back
        value = value.toString().split('e');

        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    },

    // Decimal round
    round: function(value, exp) {
        return this.decimalAdjust('round', value, exp);
    },

    // Decimal floor
    floor: function(value, exp) {
        return this.decimalAdjust('floor', value, exp);
    },

    // Decimal ceil
    ceil: function(value, exp) {
        return this.decimalAdjust('ceil', value, exp);
    },
});