module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form id="search-form" class="search-inner">\n    <fieldset>\n        <button type="submit" class="btn btn-clean">'+
((__t=( icon('search') ))==null?'':__t)+
'</button>\n        <fieldset class="search-terms">\n            <input type="search" name="search" placeholder="'+
((__t=( model.get('search') ? '' : t('collection::search:placeholder') ))==null?'':_.escape(__t))+
'" autocomplete="off" />\n\n        </fieldset>\n        <button class="btn btn-secondary clear-all" data-action="clear-all"></button>\n    </fieldset>\n</form>';
}
return __p;
};