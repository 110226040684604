module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="listcontrol-wrapper listcontrol-wrapper-default">\n    <ul>\n        <li class="grid-sort-select-wrapper">\n            <label>'+
((__t=(t('collection::list-control:sort-by:label')))==null?'':_.escape(__t))+
':</label>\n            <select name="sort" class="grid-sort-select">\n                <option value="lastEdited.desc" '+
((__t=(when(model.attributes.sort === 'lastEdited.desc', 'selected')))==null?'':_.escape(__t))+
'>\n                    '+
((__t=(t('collection::list-control:sort-by:last-edited')))==null?'':_.escape(__t))+
'\n                </option>\n                <option value="name.asc" '+
((__t=(when(model.attributes.sort === 'name.asc', 'selected')))==null?'':_.escape(__t))+
'>\n                    '+
((__t=(t('collection::list-control:sort-by:name')))==null?'':_.escape(__t))+
'\n                </option>\n                <option value="itemCount.desc" '+
((__t=(when(model.attributes.sort === 'itemCount.desc', 'selected')))==null?'':_.escape(__t))+
'>\n                    '+
((__t=(t('collection::list-control:sort-by:object-count')))==null?'':_.escape(__t))+
'\n                </option>\n            </select>\n        </li>\n    </ul>\n</div>';
}
return __p;
};