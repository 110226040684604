module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset>\n    <label for="create-group-name">'+
((__t=(t('community::group:input:label:name')))==null?'':_.escape(__t))+
'</label>\n    <input type="text" name="groupName" id="create-group-name" placeholder="'+
((__t=(t('community::group:input:placeholder:name')))==null?'':_.escape(__t))+
'" value="'+
((__t=(item.name))==null?'':_.escape(__t))+
'" maxlength="25" required />\n</fieldset>\n\n';
 if (item.users.length && item.type !== 'broadcast') { 
__p+='\n    <fieldset>\n        <legend>'+
((__t=(t('community::group:legend:current-members')))==null?'':_.escape(__t))+
'</legend>\n        <ul class="group-members">\n            ';
 _.each(item.users, function(user){ 
__p+='\n                <li>\n                    <input type="hidden" name="contacts[]" value="'+
((__t=(user.id))==null?'':_.escape(__t))+
'">\n                    <div class="contact-image">\n                        <span class="contact-initials">'+
((__t=(_.initials(user.name)))==null?'':_.escape(__t))+
'</span>\n                        '+
((__t=( responsiveImage(user.profilePicture, imageSize('thumbnailFixed'), viewportSize('grid-object'), user.name, 'grid-image') ))==null?'':__t)+
'\n                    </div>\n                    <span class="contact-name">'+
((__t=(user.name))==null?'':_.escape(__t))+
'</span>\n                </li>\n            ';
 }) 
__p+='\n        </ul>\n    </fieldset>\n';
 } 
__p+='\n\n';
 if (item.possibleContacts.length) { 
__p+='\n    <fieldset>\n        <legend>'+
((__t=(t('community::group:legend:contacts')))==null?'':_.escape(__t))+
'</legend>\n        <ul class="contacts-list">\n            ';
 _.each(item.possibleContacts, function(contact){ 
__p+='\n                <li>\n                    <div class="contact-input">\n                        <input\n                            type="checkbox"\n                            name="contacts[]"\n                            value="'+
((__t=(contact.get('userId')))==null?'':_.escape(__t))+
'"\n                            id="group-contact-'+
((__t=(contact.get('userId')))==null?'':_.escape(__t))+
'-'+
((__t=(view.cid))==null?'':_.escape(__t))+
'"\n                            '+
((__t=(when(contact.isChecked, 'checked="checked"')))==null?'':_.escape(__t))+
'\n                            />\n                        <label for="group-contact-'+
((__t=(contact.get('userId')))==null?'':_.escape(__t))+
'-'+
((__t=(view.cid))==null?'':_.escape(__t))+
'">\n                            <div class="contact-image">\n                                <span class="contact-initials">'+
((__t=(_.initials(contact.get('name'))))==null?'':_.escape(__t))+
'</span>\n                                '+
((__t=( responsiveImage(contact.get('profilePicture'), imageSize('thumbnailFixed'), viewportSize('grid-object'), contact.get('name'), 'grid-image') ))==null?'':__t)+
'\n                            </div>\n                            '+
((__t=(contact.get('name')))==null?'':_.escape(__t))+
'\n                        </label>\n                    </div>\n                </li>\n            ';
 }) 
__p+='\n        </ul>\n    </fieldset>\n';
 } 
__p+='\n\n<fieldset>\n    <button type="submit" class="btn-primary" data-action="group-submit">\n        '+
((__t=(ifelse(model.isNew(), t('community::group:button:label:create:submit'), t('community::group:button:label:update:submit'))))==null?'':_.escape(__t))+
'\n    </button>\n</fieldset>';
}
return __p;
};