module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row status-selection">\n    <div class="page-col-offset input-help">\n        ';
 if (!item.isGroupChild) { 
__p+='\n            <span class="create-step-number">&nbsp;</span>\n        ';
 } 
__p+='\n        <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n    </div>\n\n    <div class="page-col-6 history-type">\n        ';
 if (!item.isGroupChild || item.field.editOptions.showLabelInGroup) { 
__p+='\n            <label class="label-headline">\n                '+
((__t=(t('collection::object:details:section:headline:status')))==null?'':_.escape(__t))+
'\n            </label>\n        ';
 } 
__p+='\n        <div class="option-select">\n            <input id="field-status-in-collection" type="radio" name="status" value="in-collection" '+
((__t=(when(item.objectStatus === 'in-collection', 'checked')))==null?'':_.escape(__t))+
' />\n            <label for="field-status-in-collection" class="option-select-secondary">'+
((__t=(t('collection::object:details:object-status:in-collection:label')))==null?'':_.escape(__t))+
'</label>\n\n            <input id="field-status-lent" type="radio" name="status" value="lent" '+
((__t=(when(item.objectStatus === 'lent', 'checked')))==null?'':_.escape(__t))+
'>\n            <label for="field-status-lent" class="option-select-secondary">'+
((__t=(t('collection::object:details:object-status:lent:label')))==null?'':_.escape(__t))+
'</label>\n\n            <input id="field-status-missing" type="radio" name="status" value="missing" '+
((__t=(when(item.objectStatus === 'missing', 'checked')))==null?'':_.escape(__t))+
'>\n            <label for="field-status-missing" class="option-select-secondary">'+
((__t=(t('collection::object:details:object-status:missing:label')))==null?'':_.escape(__t))+
'</label>\n\n            <input id="field-status-deaccessioned" type="radio" name="status" value="deaccessioned" '+
((__t=(when(item.objectStatus === 'deaccessioned', 'checked')))==null?'':_.escape(__t))+
'>\n            <label for="field-status-deaccessioned" class="option-select-secondary">'+
((__t=(t('collection::object:details:object-status:deaccessioned:label')))==null?'':_.escape(__t))+
'</label>\n        </div>\n    </div>\n</div>\n\n<!-- Lent to / Deaccessioned to -->\n<div class="row status-relation">\n    <div class="page-offset-default page-col-1">\n        <label class="row-label" for="field-'+
((__t=(item.relationField.name))==null?'':_.escape(__t))+
'">\n            '+
((__t=(t('collection::object:details:object-status:relation:to:label')))==null?'':_.escape(__t))+
'\n        </label>\n    </div>\n    <div class="page-col-2">\n        <input type="text"\n               id="field-'+
((__t=(item.relationField.name))==null?'':_.escape(__t))+
'"\n               name="relation"\n               placeholder="'+
((__t=(t('collection::object:details:object-status:relation:to:placeholder')))==null?'':_.escape(__t))+
'"\n               value="'+
((__t=(model.get(item.relationField.name)))==null?'':_.escape(__t))+
'" />\n    </div>\n</div>';
}
return __p;
};