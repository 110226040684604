module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<dl>\n    <dt>'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</dt>\n    <dd>\n        ';
 if (item.customColors) { 
__p+='\n            <p class="object-custom-color">'+
((__t=(item.customColors))==null?'':_.escape(__t))+
'</p>\n        ';
 } 
__p+='\n\n        ';
 if (model.get(item.field.name).length) { 
__p+='\n            <ul class="object-colors">\n                ';
 _.each(model.get(item.field.name), function(color){ 
__p+='\n                    <li class="object-color object-color-swatch-'+
((__t=(color))==null?'':_.escape(__t))+
'">\n                        '+
((__t=(t('collection::object:details:color:' + color)))==null?'':_.escape(__t))+
'\n                    </li>\n                ';
 }) 
__p+='\n            </ul>\n        ';
 } 
__p+='\n    </dd>\n</dl>';
}
return __p;
};