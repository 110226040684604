module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="objects-listing is-viewmode-grid '+
((__t=( when(model && model.get('controlMode') === 'manage', 'is-manage-mode') ))==null?'':_.escape(__t))+
'">\n    <div class="container container-list-objects">\n        <div class="viewmode viewmode-grid">\n            <div class="list list-flex objects-grid-container"></div>\n        </div>\n    </div>\n</div>\n\n<button type="button" class="btn btn-back-to-top" data-action="back-to-top" title="'+
((__t=( t('global::button:scroll-back-to-top') ))==null?'':_.escape(__t))+
'">\n    '+
((__t=(icon("up")))==null?'':__t)+
'\n</button>';
}
return __p;
};