module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<section class="object-assets">\n    <h3>'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</h3>\n    \n    <ul class="assets">\n        ';
 _.each(model.get(item.field.name), function(asset) { 
__p+='\n            <li>\n                <a href="'+
((__t=(asset.downloadUrl))==null?'':_.escape(__t))+
'" download>\n                    <span class="image">\n                        '+
((__t=( responsiveImage(asset, imageSize('grid-set')) ))==null?'':__t)+
'\n                    </span>\n                    ';
 if (asset.title) { 
__p+='\n                        <span>'+
((__t=(asset.title))==null?'':_.escape(__t))+
'</span>\n                    ';
 } 
__p+='\n                    <span class="btn btn-primary">Download</span>\n                </a>\n            </li>\n        ';
 }) 
__p+='\n    </ul>\n</section>';
}
return __p;
};