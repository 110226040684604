'use strict';

var searchExtension = {
    searchFields: {},

    search: function(terms) {
        //search case insensitive
        terms = terms.map(function(term){ return term.toLowerCase(); });

        var matchingIds = this.map(function(item){
            var isItemMatching = _.every(terms, function(term){
                return _.any(this.searchFields, function(field){
                    var value = item.get(field);
                    value = _.isArray(value) ? value.join(',') : value;

                    return _.isString(value) ? value.toLowerCase().indexOf(term) > -1 : false;
                });
            }.bind(this));

            if (isItemMatching) {
                return item.id;
            }
        }.bind(this));

        return _.without(matchingIds, undefined);
    },
};

module.exports = searchExtension;