module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td>\n    '+
((__t=( item.name ))==null?'':_.escape(__t))+
'\n</td>\n<td>\n    '+
((__t=( t('global::object-types:' + _.camelToHyphen(item.type)) ))==null?'':_.escape(__t))+
'\n</td>\n<td>\n    '+
((__t=( date(item.timestamp) ))==null?'':_.escape(__t))+
'\n</td>\n<td>\n    '+
((__t=( time(item.timestamp) ))==null?'':_.escape(__t))+
'\n</td>\n<td>\n    <button type="button" class="btn btn-primary btn-m" data-action="restore" data-id="'+
((__t=( item.id ))==null?'':_.escape(__t))+
'"><span>'+
((__t=( t('settings::trash:button-restore') ))==null?'':_.escape(__t))+
'</span>'+
((__t=( icon('restore') ))==null?'':__t)+
'</button>\n</td>';
}
return __p;
};