module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="site-not-found-container">\n    <header class="content-header">\n        <h2>'+
((__t=(t('404::headline')))==null?'':_.escape(__t))+
'</h2>\n        <h2 class="subline">'+
((__t=(t('404::subheadline')))==null?'':_.escape(__t))+
'</h2>\n    </header>\n\n    <div class="container">\n        <p>'+
((__t=(t('404::text')))==null?'':_.escape(__t))+
'</p>\n    </div>\n</div>';
}
return __p;
};