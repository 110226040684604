module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="form-component import-component import-component-match" data-state="pending">\n    <div class="row row-slim import-match-default-units">\n        <div class="col-2 input-help">\n            <span class="step-number">'+
((__t=( item.step ))==null?'':_.escape(__t))+
'</span>\n        </div>\n        <div class="col-6">\n            <label class="label-headline">'+
((__t=( t('collection::import:match:headline:default-units') ))==null?'':_.escape(__t))+
'</label>\n            <p class="component-intro">\n                '+
((__t=( t('collection::import:match:text:default-units-instruction') ))==null?'':_.escape(__t))+
'\n            </p>\n        </div>\n    </div>\n    <div class="row import-match-default-units">\n        <div class="offset-2 col-2">\n            <select name="size" data-apicolumn="defaultUnitSize"></select>\n        </div>\n        <div class="col-2">\n            <select name="weight" data-apicolumn="defaultUnitWeight"></select>\n        </div>\n        <div class="col-2">\n            <select name="currency" data-apicolumn="defaultCurrency"></select>\n        </div>\n    </div>\n    <div class="row import-match-introduction">\n        <div class="col-2 input-help">\n            <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n        </div>\n        <div class="col-6">\n            <label class="label-headline">'+
((__t=( t('collection::import:match:headline:match-columns') ))==null?'':_.escape(__t))+
'</label>\n            <p class="component-intro">\n                '+
((__t=( t('collection::import:match:text:match-introduction') ))==null?'':_.escape(__t))+
'\n            </p>\n            <p class="error-message">'+
((__t=( t('collection::import:match-invalid') ))==null?'':_.escape(__t))+
'</p>\n        </div>\n    </div>\n    <div class="row import-match-columns">\n        <div class="col-12" id="subregion-match-columns"></div>\n    </div>\n    <div class="row row-slim import-match-hints">\n        <div class="col-10 offset-2">\n            <div class="list list-flex">\n                <div class="box box-hint">\n                    <h3>'+
((__t=( t('collection::import:match:headline:missing-fields') ))==null?'':_.escape(__t))+
'</h3>\n                    <p>'+
((__t=( t('collection::import:match:text:missing-fields') ))==null?'':_.escape(__t))+
'</p>\n\n                    <div class="ico-wrapper">\n                        <span class="ico ico-white ico-border ico-lg">!</span>\n                    </div>\n                </div>\n                <div class="box box-hint">\n                    <h3>'+
((__t=( t('collection::import:match:headline:please-check') ))==null?'':_.escape(__t))+
'</h3>\n                    <p>'+
((__t=( t('collection::import:match:text:please-check') ))==null?'':_.escape(__t))+
'</p>\n\n                    <div class="ico-wrapper">\n                        <span class="ico ico-white ico-border ico-lg">!</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="row import-match-submit">\n        <div class="col-10 offset-2">\n            <button type="button" class="btn btn-primary" data-action="start-import">'+
((__t=( t('collection::import:button:start-import') ))==null?'':_.escape(__t))+
'</button>\n        </div>\n    </div>\n    <div class="row import-match-selected import-folded-information">\n        <div class="col-2 input-help">\n            <span class="step-number">'+
((__t=( item.step ))==null?'':_.escape(__t))+
'</span>\n        </div>\n        <div class="col-6">\n            <p>\n                <span id="matched-columns">0</span> '+
((__t=( t('collection::import:match:text:columns-matched') ))==null?'':_.escape(__t))+
'\n                /\n                <span id="dismissed-columns">0</span> '+
((__t=( t('collection::import:match:text:columns-dismissed') ))==null?'':_.escape(__t))+
'\n            </p>\n            <button type="button" class="btn btn-m btn-primary btn-primary-used" data-action="re-match">'+
((__t=( t('collection::import:button:re-match') ))==null?'':_.escape(__t))+
'</button>\n        </div>\n    </div>\n</div>';
}
return __p;
};