module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td class="td-empty" colspan="50">\n    <a href="'+
((__t=(item.objectNewPath))==null?'':_.escape(__t))+
'">\n        <span class="empty-plus" data-action="add-object">+</span>\n        '+
((__t=(t('collection::objects:list:add-button')))==null?'':_.escape(__t))+
'\n    </a>\n</td>';
}
return __p;
};