'use strict';

/**
 * Base class for a filtered collection
 * @type {*|void|Object}
 */

var Backbone = require('backbone');
var sortExtension = require('base/mixins/sort');
var searchExtension = require('base/mixins/search');
var objectsCollectionExtension = require('base/mixins/objectsCollection');

require('backbone-filteredcollection');

var FilteredCollection = Backbone.FilteredCollection.extend({
    /**
     * Convenience method, calls save method on base collection
     */
    save: function () {
        return this.collection.save();
    },

    getFilteredCount: function(filter) { //@todo this is a bloody hack. find a new better collection filter plugin.
        return this.collection.getFilteredCount([
            this.originalFilter,
            filter,
        ]);
    },

    initialize: function() {
        Backbone.FilteredCollection.prototype.initialize.apply(this, arguments);

        this.filterComponents = this.collection.filterComponents;
        this.componentViews = this.collection.componentViews;
    },
});

_.extend(FilteredCollection.prototype, sortExtension, searchExtension, objectsCollectionExtension);

module.exports = FilteredCollection;