module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="shared-set" data-action="share-set" title="'+
((__t=(item.name))==null?'':_.escape(__t))+
'">\n    <h4 class="set-name">'+
((__t=(item.name))==null?'':_.escape(__t))+
'</h4>\n    <div class="set-image">\n        '+
((__t=( responsiveImage(model.getImage(), imageSize('grid-set'), viewportSize('grid-object'), item.name) ))==null?'':__t)+
'\n    </div>\n    <div class="object-count">\n        '+
((__t=(t('community::share-set:object-count', item.itemCount)))==null?'':_.escape(__t))+
'\n    </div>\n</button>';
}
return __p;
};