'use strict';

var ItemView = require('base/Colido.ItemView');
var SetModel = require('modules/collection/modules/set/models/set');

var dropTargetTemplate = require('modules/collection/templates/partials/drop-target.hbs');
var template = require('modules/collection/templates/partials/drop-control.hbs');

require('jquery-humans-dnd');

var DropControlView = ItemView.extend({

    template: template,

    modelEvents: {
        'change': 'render',
    },

    templateHelpers: function () {
        var templateHelpers = this.defaultTemplateHelpers();

        return {
            dropTarget: function (data) {
                data = _.extend(templateHelpers, data);

                return dropTargetTemplate(data);
            },
            SetModel: SetModel,
        };
    },

    onRender: function() {
        ItemView.prototype.onRender.apply(this, arguments);

        this.applyDropControl();
    },

    /**
     * Applies the global DND control
     */
    applyDropControl: function() {
        this.$('.drop-target').humansDrop({
            overClass: 'drop-target-over',
            onEnter:function(event, humansDrop) {
                humansDrop.$el.parents('.drop-control').addClass('contains-drag');
            },
            onOver:function(event, humansDrop) {
                humansDrop.$el.parents('.drop-control').addClass('contains-drag');
            },
            onLeave:function(event, humansDrop) {
                humansDrop.$el.parents('.drop-control').removeClass('contains-drag');
            },
            onDrop: function(event, humansDrop, humansDrag) {
                var action = humansDrop.$el.data('action');
                var objectId = humansDrag.getTargetElement().data('id');

                humansDrop.$el.parents('.drop-control').removeClass('contains-drag');

                switch (action) {
                    case 'remove':
                        this._removeObjectFromSet(objectId);
                        break;

                    case 'trash':
                        this._deleteObject(objectId);
                        break;

                    case 'trash-set':
                        this._deleteSet(objectId);
                        break;
                }
            }.bind(this),
        });
    },

    onBeforeDestroy: function() {
        this.$('.drop-target').humansDrop('destroy');
    },

    /**
     * Removes the model from the set
     * @param objectId ID of the object
     */
    _removeObjectFromSet: function(objectId) {
        var catalog = App.CollectionModule.catalogs.getActiveCatalog();
        var objectModel = catalog.objects.get(objectId);

        var setModel = this.model.get('setModel');

        App.radio('collection').execute('remove:from:set', objectModel, setModel);
    },

    /**
     * deletes the set
     * @param setId ID of the set
     */
    _deleteSet: function(setId) {
        var catalog = App.CollectionModule.catalogs.getActiveCatalog();
        var setModel = catalog.sets.get(setId);

        App.radio('collection:set').execute('delete', setModel);
    },

    /**
     * deletes the item
     * @param objectId ID of the object
     */
    _deleteObject: function(objectId) {
        var catalog = App.CollectionModule.catalogs.getActiveCatalog();
        var objectModel = catalog.objects.get(objectId);

        App.radio('collection').execute('delete', objectModel);
    },
});

module.exports = DropControlView;