module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row">\n    <div class="page-col-offset input-help">\n        ';
 if (!item.isGroupChild) { 
__p+='\n          <span class="create-step-number">&nbsp;</span>\n        ';
 } 
__p+='\n        <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n    </div>\n    <div class="page-col-3">\n        ';
 if (!item.isGroupChild || !item.field.editOptions.hideLabelInGroup) { 
__p+='\n          <label for="'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'" class="label-headline">\n            '+
((__t=(item.field.label))==null?'':_.escape(__t))+
'\n          </label>\n        ';
 } 
__p+='\n        <textarea name="locality"\n                  id="'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'"\n                  placeholder="'+
((__t=(item.field.placeholder))==null?'':_.escape(__t))+
'"\n                  data-autosize="true"\n                  rows="1"></textarea>\n\n        <p class="hint">\n          '+
((__t=( t('collection::edit:locality-selection:locality-mine:hint') ))==null?'':_.escape(__t))+
'\n        </p>\n    </div>\n</div>';
}
return __p;
};