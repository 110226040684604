module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form class="page-container">\n    <header class="modal-header">\n        <ul>\n            <li>\n                <h2>'+
((__t=(t('label-print-modal::modal-title')))==null?'':_.escape(__t))+
'</h2>\n                <h2 class="subline">\n                    '+
((__t=(t('label-print-modal::modal-subtitle:object-count', view.getOption('itemIds').length)))==null?'':_.escape(__t))+
'\n                </h2>\n            </li>\n            <li class="actions">\n                <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('print-modal::abort-button')))==null?'':_.escape(__t))+
'</button>\n                <button type="submit" class="btn btn-primary">'+
((__t=(t('print-modal::generate-button')))==null?'':_.escape(__t))+
'</button>\n            </li>\n        </ul>\n    </header>\n    <div class="modal-body advanced-print-modal '+
((__t=(when(item.format === 'custom', 'is-custom-format') ))==null?'':_.escape(__t))+
'">\n        <div class="component-listing">\n            <div>\n            \n                <div class="row row-large">\n                    <div class="page-col-4">\n                        <p>\n                            '+
((__t=(t('label-print-modal::text:introduction')))==null?'':_.escape(__t))+
'\n                        </p>\n                    </div>\n                </div>\n                <div class="row row-slim">\n                    <div class="page-col-6">\n                        <label class="label-headline">'+
((__t=(t('label-print-modal::label-headline:paper-size')))==null?'':_.escape(__t))+
'</label>\n                    </div>\n                </div>\n                <div class="row row-large">\n                    <div class="page-col-5">\n                        <div class="option-select">\n                            <input id="printlabel-format-a4" type="radio" name="format" value="a4" '+
((__t=(when(item.format === 'a4', 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-format-a4" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:din-a4')))==null?'':_.escape(__t))+
'</label>\n\n                            <input id="printlabel-format-letter" type="radio" name="format" value="letter" '+
((__t=(when(item.format === 'letter', 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-format-letter" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:letter')))==null?'':_.escape(__t))+
'</label>\n\n                            <input id="printlabel-format-custom" type="radio" name="format" value="custom" '+
((__t=(when(item.format === 'custom', 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-format-custom" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:custom-format')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n\n                    <div class="row row-slim custom-detail-format">\n                        <div class="page-col-1 page-col-before-spacer">\n                            <div class="input-label-group">\n                                <input type="number" step="any" name="pageWidth" value="'+
((__t=( item.pageWidth ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('label-print-modal::placeholder:paper-width')))==null?'':_.escape(__t))+
'">\n                                <label data-position="left" for="pageWidth">'+
((__t=(t('label-print-modal::label:paper-width')))==null?'':_.escape(__t))+
'</label>\n                            </div>\n                        </div>\n                        <div class="page-col-spacer">\n                            <span class="spacer spacer-grotesk">×</span>\n                        </div>\n                        <div class="page-col-1">\n                            <div class="input-label-group">\n                                <input type="number" step="any" name="pageHeight" value="'+
((__t=( item.pageHeight ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('label-print-modal::placeholder:paper-height')))==null?'':_.escape(__t))+
'">\n                                <label data-position="left" for="pageHeight">'+
((__t=(t('label-print-modal::label:paper-height')))==null?'':_.escape(__t))+
'</label>\n                            </div>\n                        </div>\n                        <div class="page-col-1">\n                            <select name="units" class="select-secondary"></select>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n\n            <div>\n                <div class="row row-slim">\n                    <div class="page-col-6">\n                        <label class="label-headline">'+
((__t=(t('label-print-modal::label-headline:labelformat')))==null?'':_.escape(__t))+
'</label>\n                    </div>\n                </div>\n                <div class="row row-large">\n                    <div class="page-col-1 page-col-before-spacer">\n                        <div class="input-label-group">\n                            <input type="number" step="any" name="labelWidth" value="'+
((__t=( item.labelWidth ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('label-print-modal::placeholder:label-width')))==null?'':_.escape(__t))+
'">\n                            <label data-position="left" for="labelWidth">'+
((__t=(t('label-print-modal::label:label-width')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                    <div class="page-col-spacer">\n                        <span class="spacer spacer-grotesk">×</span>\n                    </div>\n                    <div class="page-col-1">\n                        <div class="input-label-group">\n                            <input type="number" step="any" name="labelHeight" value="'+
((__t=( item.labelHeight ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('label-print-modal::placeholder:label-height')))==null?'':_.escape(__t))+
'">\n                            <label data-position="left" for="labelHeight">'+
((__t=(t('label-print-modal::label:label-height')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                    <div class="page-col-1">\n                        <select name="units" class="select-secondary"></select>\n                    </div>\n                </div>\n            </div>\n\n            <div>\n                <div class="row row-slim">\n                    <div class="page-col-6">\n                        <label class="label-headline">'+
((__t=(t('label-print-modal::label-headline:section-layout')))==null?'':_.escape(__t))+
'</label>\n                    </div>\n                </div>\n                <div class="row row-medium">\n                    <div class="page-col-1-5">\n                        <div class="input-label-group">\n                            <input type="number" step="any" name="labelMarginHorizontal" value="'+
((__t=( item.labelMarginHorizontal ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('label-print-modal::placeholder:horizontal-gap')))==null?'':_.escape(__t))+
'">\n                            <label data-position="left" for="labelMarginHorizontal">'+
((__t=(t('label-print-modal::label:horizontal-gap')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                    <div class="page-col-1-5">\n                        <div class="input-label-group">\n                            <input type="number" step="any" name="labelMarginVertical" value="'+
((__t=( item.labelMarginVertical ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('label-print-modal::placeholder:vertical-gap')))==null?'':_.escape(__t))+
'">\n                            <label data-position="left" for="labelMarginVertical">'+
((__t=(t('label-print-modal::label:vertical-gap')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                    <div class="page-col-1">\n                        <select name="units" class="select-secondary"></select>\n                    </div>\n                    <div class="page-col-1">\n                        <div class="input-info">\n                            '+
((__t=(icon("info")))==null?'':__t)+
'\n                            <p class="hint">'+
((__t=( t('label-print-modal::hint:gap')))==null?'':__t)+
'</p>\n                        </div>\n                    </div>\n                </div>\n                \n                <div class="row row-large">\n                    <div class="page-col-1-5">\n                        <div class="input-label-group">\n                            <input type="number" step="any" name="offsetX" value="'+
((__t=( item.offsetX ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('label-print-modal::placeholder:offset-horizontal')))==null?'':_.escape(__t))+
'">\n                            <label data-position="left" for="offsetX">'+
((__t=(t('label-print-modal::label:offset-horizontal')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                    <div class="page-col-1-5">\n                        <div class="input-label-group">\n                            <input type="number" step="any" name="offsetY" value="'+
((__t=( item.offsetY ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('label-print-modal::placeholder:vertical-offset')))==null?'':_.escape(__t))+
'">\n                            <label data-position="left" for="offsetY">'+
((__t=(t('label-print-modal::label:vertical-offset')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                    <div class="page-col-1">\n                        <select name="units" class="select-secondary"></select>\n                    </div>\n                    <div class="page-col-1">\n                        <div class="input-info">\n                            '+
((__t=(icon("info")))==null?'':__t)+
'\n                            <p class="hint">'+
((__t=( t('label-print-modal::hint:offset')))==null?'':__t)+
'</p>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div>\n                <div class="row row-slim">\n                    <div class="page-col-2">\n                        <label class="label-headline">'+
((__t=(t('label-print-modal::label:print-lines')))==null?'':_.escape(__t))+
'</label>\n                    </div>\n                    <div class="page-col-2">\n                        <div class="option-select">\n                            <input id="printlabel-printlines-yes" type="radio" name="printLines" value="1" '+
((__t=(when(item.printLines == 1, 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-printlines-yes" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:print-lines-yes')))==null?'':_.escape(__t))+
'</label>\n\n                            <input id="printlabel-printlines-no" type="radio" name="printLines" value="0" '+
((__t=(when(item.printLines == 0, 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-printlines-no" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:print-lines-no')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                    <div class="page-col-1">\n                        <div class="input-info">\n                            '+
((__t=(icon("info")))==null?'':__t)+
'\n                            <p class="hint">'+
((__t=( t('label-print-modal::hint:print-lines')))==null?'':__t)+
'</p>\n                        </div>\n                    </div>\n                </div>\n                \n                <div class="row row-slim">\n                    <div class="page-col-2">\n                        <label class="label-headline">'+
((__t=(t('label-print-modal::label:print-logo')))==null?'':_.escape(__t))+
'</label>\n                    </div>\n                    <div class="page-col-2">\n                        <div class="option-select">\n                            <input id="printlabel-printlogo-yes" type="radio" name="printLogo" value="1" '+
((__t=(when(item.printLogo == 1, 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-printlogo-yes" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:print-logo-yes')))==null?'':_.escape(__t))+
'</label>\n\n                            <input id="printlabel-printlogo-no" type="radio" name="printLogo" value="0" '+
((__t=(when(item.printLogo == 0, 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-printlogo-no" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:print-logo-no')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                    <div class="page-col-1">\n                        <div class="input-info">\n                            '+
((__t=(icon("info")))==null?'':__t)+
'\n                            <p class="hint">'+
((__t=( t('label-print-modal::hint:print-logo')))==null?'':__t)+
'</p>\n                        </div>\n                    </div>\n                </div>\n\n                <div class="row row-large">\n                    <div class="page-col-2">\n                        <label class="label-headline">'+
((__t=(t('label-print-modal::label:label-type')))==null?'':_.escape(__t))+
'</label>\n                    </div>\n                    <div class="page-col-2">\n                        <div class="option-select">\n                            <input id="printlabel-doubled-yes" type="radio" name="isFoldingLabel" value="1" '+
((__t=(when(item.isFoldingLabel == 1, 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-doubled-yes" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:label-type-yes')))==null?'':_.escape(__t))+
'</label>\n\n                            <input id="printlabel-doubled-no" type="radio" name="isFoldingLabel" value="0" '+
((__t=(when(item.isFoldingLabel == 0, 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-doubled-no" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:label-type-no')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                    <div class="page-col-1">\n                        <div class="input-info">\n                            '+
((__t=(icon("info")))==null?'':__t)+
'\n                            <p class="hint">'+
((__t=( t('label-print-modal::hint:label-type')))==null?'':__t)+
'</p>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            \n            <div>\n                <div class="row row-slim">\n                    <div class="page-col-2">\n                        <label class="label-headline">'+
((__t=(t('label-print-modal::label:show-price')))==null?'':_.escape(__t))+
'</label>\n                    </div>\n                    <div class="page-col-2">\n                        <div class="option-select">\n                            <input id="printlabel-printprice-yes" type="radio" name="printPrice" value="1" '+
((__t=(when(item.printPrice == 1, 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-printprice-yes" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:show-price-yes')))==null?'':_.escape(__t))+
'</label>\n\n                            <input id="printlabel-printprice-no" type="radio" name="printPrice" value="0" '+
((__t=(when(item.printPrice == 0, 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="printlabel-printprice-no" class="option-select-secondary">'+
((__t=(t('label-print-modal::label:show-price-no')))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                </div>\n\n                <div class="row row-slim">\n                    <div class="page-col-2">\n                        <label class="label-headline">'+
((__t=(t('label-print-modal::label:convert-currency')))==null?'':_.escape(__t))+
'</label>\n                    </div>\n                    <div class="page-col-2">\n                        <div class="page-col-2">\n                            <select name="convertCurrency" class="select-secondary">\n                                <option value="none" '+
((__t=(when(item.convertCurrency == "none", 'selected') ))==null?'':_.escape(__t))+
' >'+
((__t=(t('label-print-modal::option:currency:no')))==null?'':_.escape(__t))+
'</option>\n                                <option value="eur"  '+
((__t=(when(item.convertCurrency == "eur", 'selected') ))==null?'':_.escape(__t))+
' >'+
((__t=(t('label-print-modal::option:currency:eur')))==null?'':_.escape(__t))+
'</option>\n                                <option value="usd"  '+
((__t=(when(item.convertCurrency == "usd", 'selected') ))==null?'':_.escape(__t))+
' >'+
((__t=(t('label-print-modal::option:currency:usd')))==null?'':_.escape(__t))+
'</option>\n                                <option value="gbp"  '+
((__t=(when(item.convertCurrency == "gbp", 'selected') ))==null?'':_.escape(__t))+
' >'+
((__t=(t('label-print-modal::option:currency:gbp')))==null?'':_.escape(__t))+
'</option>\n                                <option value="chf"  '+
((__t=(when(item.convertCurrency == "chf", 'selected') ))==null?'':_.escape(__t))+
' >'+
((__t=(t('label-print-modal::option:currency:chf')))==null?'':_.escape(__t))+
'</option>\n                            </select>\n                        </div>\n                    </div>\n                    <div class="page-col-1">\n                        <div class="input-info">\n                            '+
((__t=(icon("info")))==null?'':__t)+
'\n                            <p class="hint">'+
((__t=( t('label-print-modal::hint:convert-currency')))==null?'':__t)+
'</p>\n                        </div>\n                    </div>\n                </div>\n\n                <div class="row row-slim">\n                    <div class="page-col-2">\n                        <label class="label-headline">'+
((__t=( t('label-print-modal::label:conversion-type')))==null?'':__t)+
'</label>\n                    </div>\n                    <div class="page-col-2">\n                        <div class="page-col-2">\n                            <select name="roundCurrency" class="select-secondary">\n                                <option value="none"   '+
((__t=(when(item.roundCurrency == "none", 'selected') ))==null?'':_.escape(__t))+
'>'+
((__t=( t('label-print-modal::option:conversion:do-not')))==null?'':__t)+
'</option>\n                                <option value="normal" '+
((__t=(when(item.roundCurrency == "normal", 'selected') ))==null?'':_.escape(__t))+
'>'+
((__t=( t('label-print-modal::option:conversion:default')))==null?'':__t)+
'</option>\n                                <option value="ceil"   '+
((__t=(when(item.roundCurrency == "ceil", 'selected') ))==null?'':_.escape(__t))+
'>'+
((__t=( t('label-print-modal::option:conversion:up')))==null?'':__t)+
'</option>\n                                <option value="floor"  '+
((__t=(when(item.roundCurrency == "floor", 'selected') ))==null?'':_.escape(__t))+
'>'+
((__t=( t('label-print-modal::option:conversion:down')))==null?'':__t)+
'</option>\n                            </select>\n                        </div>\n                    </div>\n                </div>\n\n                <div class="row row-large">\n                    <div class="page-col-2">\n                        <label class="label-headline">'+
((__t=( t('label-print-modal::label:conversion-rounding')))==null?'':__t)+
'</label>\n                    </div>\n                    <div class="page-col-2">\n                        <div class="page-col-2">\n                            <select name="conversionRounding" class="select-secondary">\n                                <option value="0"   '+
((__t=(when(item.roundNCurrency == 0, 'selected') ))==null?'':_.escape(__t))+
'>'+
((__t=( t('label-print-modal::option:round-to:none')))==null?'':__t)+
'</option>\n                                <option value="1"   '+
((__t=(when(item.roundNCurrency == 1, 'selected') ))==null?'':_.escape(__t))+
'>'+
((__t=( t('label-print-modal::option:round-to:1')))==null?'':__t)+
'</option>\n                                <option value="10"  '+
((__t=(when(item.roundNCurrency == 10, 'selected') ))==null?'':_.escape(__t))+
'>'+
((__t=( t('label-print-modal::option:round-to:10')))==null?'':__t)+
'</option>\n                                <option value="100" '+
((__t=(when(item.roundNCurrency == 100, 'selected') ))==null?'':_.escape(__t))+
'>'+
((__t=( t('label-print-modal::option:round-to:100')))==null?'':__t)+
'</option>\n                            </select>\n                        </div>\n                    </div>\n                    <div class="page-col-1">\n                        <div class="input-info">\n                            '+
((__t=(icon("info")))==null?'':__t)+
'\n                            <p class="hint">'+
((__t=( t('label-print-modal::hint:conversion-rounding')))==null?'':__t)+
'</p>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</form>';
}
return __p;
};