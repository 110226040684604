module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="sly-slider">\n    <!--button data-action="add-contact" class="btn btn-add-contact">\n        '+
((__t=(t('dashboard::button:add-contact')))==null?'':_.escape(__t))+
' '+
((__t=( icon('add-small') ))==null?'':__t)+
'\n    </button-->\n    <ul class="conversations-list"></ul>\n</div>\n\n';
 if (view.getOption('infiniteView')) { 
__p+='\n    <div class="sly-scroll"><div class="sly-scroll-handle"></div></div>\n';
 } 
__p+='';
}
return __p;
};