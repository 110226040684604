module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (view.getOption('model')) { 
__p+='\n    <h3 class="with-button">\n        '+
((__t=( model.attributes.name ))==null?'':_.escape(__t))+
'\n        <button type="button" class="btn btn-round btn-primary" data-action="select-storage">'+
((__t=( icon('deposit') ))==null?'':__t)+
'</button>\n    </h3>\n';
 } 
__p+='\n\n<ul class="list list-selection list-selection-vertical list-selection-add-support">\n\n</ul>\n\n';
 if (view.getOption('level') <= view.getOption('maxLevel')) { 
__p+='\n    <div class="box box-empty box-add-storage" data-mode="view" data-empty="true">\n        <div class="box-inner">\n            <div class="empty-view">\n                <button type="button" class="btn btn-clean btn-secondary" data-action="toggle-creation">'+
((__t=( t('collection::locations:manage-storages:add-new-storage') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('add-small') ))==null?'':__t)+
'</button>\n            </div>\n            <div class="empty-edit">\n                <form method="post" data-role="form-add-location">\n                    <input type="text"\n                           name="name"\n                           placeholder="Type storage name"\n                           />\n\n                    <div class="empty-edit-controls">\n                        <button type="reset"\n                                class="btn btn-clean btn-secondary h-pull-left"\n                                data-action="reset">'+
((__t=( t('collection::locations:manage-storages:cancel') ))==null?'':_.escape(__t))+
'</button>\n\n                        <button type="submit"\n                                class="btn btn-clean btn-primary h-pull-right"\n                                data-action="create"\n                                disabled>'+
((__t=( t('collection::locations:manage-storages:create') ))==null?'':_.escape(__t))+
'</button>\n                    </div>\n                </form>\n            </div>\n        </div>\n    </div>\n';
 } 
__p+='';
}
return __p;
};