module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="form-component import-component import-component-upload" data-state="pending">\n    <div class="row import-upload-part import-upload-part-introduction">\n        <div class="col-6 offset-2">\n            <p class="component-intro">\n                '+
((__t=( t('collection::import:upload:text:introduction') ))==null?'':_.escape(__t))+
'\n                <a href="mailto:support@colido.de?subject=Import feature feedback" class="link-border">support@colido.de</a>\n            </p>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-2 input-help">\n            <span class="step-number">'+
((__t=( item.step ))==null?'':_.escape(__t))+
'</span>\n        </div>\n        <div class="col-6">\n            <label class="label-headline">'+
((__t=( t('collection::import:upload:headline:import-file-type') ))==null?'':_.escape(__t))+
'</label>\n\n            <div id="subregion-file-select" class="import-upload-part import-upload-part-file-select">\n\n            </div>\n            \n            <div class="import-limitation-hint">\n                <p class="hint">\n                    '+
((__t=( t('collection::import:upload:text:limitation') ))==null?'':_.escape(__t))+
'\n                </p>\n            </div>\n\n            <div class="import-upload-part import-folded-information">\n                <p>Uploaded: <span id="selected-file"></span></p>\n                <button type="button" class="btn btn-m btn-primary btn-primary-used" data-action="start-over">'+
((__t=( t('collection::import:button:start-over') ))==null?'':_.escape(__t))+
'</button>\n            </div>\n        </div>\n    </div>\n</div>';
}
return __p;
};