module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\n    <h2>'+
((__t=( t('settings::label') ))==null?'':_.escape(__t))+
': '+
((__t=( t('settings::export:label') ))==null?'':_.escape(__t))+
'</h2>\n</header>\n\n<div class="container container-dark container-unfinished-list">\n    <label>'+
((__t=(t('Exports in progress')))==null?'':_.escape(__t))+
'</label>\n    <div class="unfinished-exports-region"></div>\n</div>\n\n<div class="container">\n    <fieldset class="form-note">\n        <button type="button" class="btn btn-big-dotted btn-big-plus" data-action="export">'+
((__t=(t('settings::export:button-export-database')))==null?'':_.escape(__t))+
'</button>\n\n        <label>'+
((__t=(t('settings::export:note-new:label')))==null?'':_.escape(__t))+
'</label>\n        <p>'+
((__t=(t('settings::export:note-new:text')))==null?'':__t)+
'</p>\n    </fieldset>\n\n    <fieldset class="form-note">\n        <label>'+
((__t=(t('settings::export:note-recent:label')))==null?'':_.escape(__t))+
'</label>\n        <p>'+
((__t=(t('settings::export:note-recent:text')))==null?'':__t)+
'</p>\n    </fieldset>\n\n    <div class="recent-exports-region container-finished-list"></div>\n\n    <fieldset class="form-note container-finished-empty hint">\n        '+
((__t=(t('settings::export:note-empty')))==null?'':__t)+
'\n    </fieldset>\n</div>';
}
return __p;
};