module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header">\n    <h2 class="subscription-modal-title">'+
((__t=(t('subscription::modal:edit')))==null?'':_.escape(__t))+
'</h2>\n\n    <div class="actions">\n        <button class="btn btn-secondary" data-action="cancel">'+
((__t=(t('subscription::modal:cancel')))==null?'':_.escape(__t))+
'</button>\n        <div class="btn-submit-wrapper" data-gravity="e">\n            <button class="btn btn-primary" data-action="save" disabled>'+
((__t=(t('subscription::modal:save')))==null?'':_.escape(__t))+
'</button>\n            <div class="btn-submit-wrapper-layer"></div>\n        </div>\n    </div>\n</header>\n\n<div class="modal-body">\n    <section></section>\n</div>';
}
return __p;
};