'use strict';

var Controller = require('base/Colido.Controller');
var ShopObjectListLayout = require('./views/layout/shop-object-list');
var ShopSettingsObjectListModel = require('./models/settings-object-list');

var ShopModuleController = Controller.extend({
    routes: {
        'shops/:shopId(/search/:search)(/filter/*filter)(/sort/:sort)(/show/:id)(/scrollTo/:sid)': 'actionShop',
    },

    initialize: function (options) {
        this.module = this.getOption('module');
    },

    /**
     * Action for listing the shop items
     */
    actionShop: function(shopId, search, filter, sort, id, sid) {
        this.onDataReady(this.module.shops, function() {
            var shop = this.module.shops.get(shopId);

            if (!shop) {
                console.log('@todo: forward to shop 404');

                return;
            }

            App.requireData([
                shop.objects,
                // shop.offers,
                App.CollectionModule.speciesCollection,
                App.radio().request('currencies:collection'),
                App.BaseModule.unitsCollection,
            ], function() {
                App.CollectionModule.controller.objectsList(shop.objects, ShopObjectListLayout, {
                    setModel: null,
                    locationModel: null,
                    importModel: null,
                    importWarningsModel: null,
                    search: search,
                    filter: filter,
                    sort: sort,
                    id: id,
                    sid: sid,
                    overrideSettingsObjectListModel: ShopSettingsObjectListModel,
                });
            }.bind(this))
        }.bind(this));
    },
});

module.exports = ShopModuleController;