module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header modal-buttons-right">\n    <button type="button" class="btn btn-primary" data-action="save" disabled="disabled">'+
((__t=(t('settings::account:change-password:save')))==null?'':_.escape(__t))+
'</button>\n    <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('settings::account:change-password:cancel')))==null?'':_.escape(__t))+
'</button>\n    <h2>'+
((__t=(t('settings::account:change-password:headline')))==null?'':_.escape(__t))+
'</h2>\n</header>\n<div class="modal-body">\n    <div class="container page-container">\n        <div class="page-col-2">\n            <fieldset>\n                <label>'+
((__t=(t('settings::account:change-password:labelOld')))==null?'':_.escape(__t))+
'</label>\n                <input name="password-old" value="" placeholder="'+
((__t=(t('settings::account:change-password:placeholderOld')))==null?'':_.escape(__t))+
'" type="password" required>\n            </fieldset>\n        </div>\n\n        <div class="page-col-2">\n            <fieldset>\n                <label>'+
((__t=(t('settings::account:change-password:label')))==null?'':_.escape(__t))+
'</label>\n                <input name="password" value="" placeholder="'+
((__t=(t('settings::account:change-password:placeholder')))==null?'':_.escape(__t))+
'" type="password" required>\n            </fieldset>\n        </div>\n\n        <div class="page-col-2">\n            <fieldset>\n                <label>'+
((__t=(t('settings::account:change-password:labelRetype')))==null?'':_.escape(__t))+
'</label>\n                <input name="retyped-password" value="" placeholder="'+
((__t=(t('settings::account:change-password:placeholderRetype')))==null?'':_.escape(__t))+
'" type="password" required>\n            </fieldset>\n        </div>\n    </div>\n</div>';
}
return __p;
};