module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\n    <h2>'+
((__t=( t('settings::label') ))==null?'':_.escape(__t))+
': '+
((__t=( t('settings::information:label') ))==null?'':_.escape(__t))+
'</h2>\n</header>\n\n<form class="settings-information-form" method="post">\n    <div class="container">\n        <section>\n            <div class="form-50-left">\n                <fieldset class="data-info">\n                    <!--<a href="/settings/information/about-colido" class="a-arrow">About Colido</a>-->\n                    <a href="http://colido.de/downloads/terms_and_conditions-colido.pdf" class="a-arrow" target="_blank">'+
((__t=(t('settings::information:terms-and-conditions:label')))==null?'':_.escape(__t))+
'</a>\n                    <a href="http://colido.de/downloads/privacy-colido.pdf" class="a-arrow" target="_blank">'+
((__t=(t('settings::information:privacy:label')))==null?'':_.escape(__t))+
'</a>\n                    <!--<a href="/settings/information/privacy-statements" class="a-arrow">Privacy Statement</a>-->\n                </fieldset>\n            </div>\n            <div class="form-50-right">\n                <fieldset class="data-info">\n                    <a href="/settings/information/client-informations" class="a-arrow">'+
((__t=(t('settings::information:client-informations:label')))==null?'':_.escape(__t))+
'</a>\n                    <!--\n                            <a href="/settings/information/server-informations" class="a-arrow">Server-Informations</a>-->\n                </fieldset>\n            </div>\n        </section>\n    </div>\n</form>';
}
return __p;
};