module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="detail-header-region"></div>\n\n<div class="locations-detail-container list-layout-scroll-container">\n    <div class="list-header-region"></div>\n\n    ';
 if (item.subStorages) { 
__p+='\n        <div class="container container-dark container-sub-storages">\n            <div class="container">\n                <div class="listcontrol-light">\n                    <h3>'+
((__t=(t('collection::locations:headline-storages')))==null?'':_.escape(__t))+
'</h3>\n                    <!-- <button class="btn btn-dashed" data-action="add-storage">Add new storage</button> -->\n                </div>\n\n                <div id="subStoragesRegion"></div>\n            </div>\n        </div>\n    ';
 } 
__p+='\n\n    <div class="container container-with-dnd listcontrol-region" data-state="default"></div>\n    <div class="container container-with-dnd objects-list-region"></div>\n</div>\n\n<div class="drop-control-region"></div>';
}
return __p;
};