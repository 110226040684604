'use strict';

var TrackingModule = require('modules/tracking/module');
var AuthenticationModule = require('modules/authentication/module');
var BaseModule = require('modules/base/module');
var DashboardModule = require('modules/dashboard/module');
var CollectionModule = require('modules/collection/module');
var CommunityModule = require('modules/community/module');
var HelpdeskModule = require('modules/helpdesk/module');
var SettingsModule = require('modules/settings/module');

module.exports = function (App) {
    App.module('TrackingModule', TrackingModule);
    App.module('AuthenticationModule', AuthenticationModule);
    App.module('BaseModule', BaseModule);
    App.module('CollectionModule', CollectionModule);
    App.module('DashboardModule', DashboardModule);

    if (Colido.config.useCommunityModule) {
        App.module('CommunityModule', CommunityModule);
    }

    App.module('HelpdeskModule', HelpdeskModule);
    App.module('SettingsModule', SettingsModule);
};