'use strict';

var Model = require('base/Colido.Model');

var PlanModel = Model.extend({
    api: function() {
        return '/accounts/' + App.user.id + '/receipts';
    },

    defaults: function() {
        return {
            title: 'Plus Plan',
            amount: Math.random() * 100,
            date: '2015-01-20',
            paymentMethod: 'Visa 4***-****-****-3123',
            url: 'https://www.google.de',
            receiptNumber: Math.random() * 100,
        };
    },
});

module.exports = PlanModel;