module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row">\n    <div class="page-col-offset input-help">\n        ';
 if (!item.isGroupChild) { 
__p+='\n            <span class="create-step-number">&nbsp;</span>\n        ';
 } 
__p+='\n        <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n    </div>\n    <div class="page-col-6">\n        <label class="label-headline">'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</label>\n\n        <div class="storage-selection-wrapper">\n            <button type="button" class="btn btn-primary" data-action="choose-storage">\n                '+
((__t=( t('collection::edit:storage:button-choose-storage') ))==null?'':_.escape(__t))+
'\n            </button>\n            <input type="hidden" name="storageId">\n            <button type="button" class="btn btn-clean" data-action="remove-storage">\n                '+
((__t=( t('collection::edit:storage:button-remove-storage') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('trash') ))==null?'':__t)+
'\n            </button>\n        </div>\n    </div>\n</div>';
}
return __p;
};