module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header">\n    <button type="button" class="btn btn-back" data-action="close">\n        '+
((__t=( icon('prev') ))==null?'':__t)+
'\n    </button>\n\n    <h2>'+
((__t=( view.getOption('title') ))==null?'':_.escape(__t))+
'</h2>\n    ';
 if (view.getOption('subTitle')) { 
__p+='\n        <h2 class="subline">\n            '+
((__t=( view.getOption('subTitle') ))==null?'':_.escape(__t))+
'\n        </h2>\n    ';
 } 
__p+='\n</header>\n\n<div class="modal-body">\n    <iframe class="iframe iframe--full" width="100%" height="100%" src="'+
((__t=( view.getOption('url') ))==null?'':_.escape(__t))+
'" frameborder="0"></iframe>\n</div>';
}
return __p;
};