module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="image-list-container ';
 if (collection.length > 0) { 
__p+='has-images';
 } 
__p+='">\n    <div class="image-list-actions-wrapper">\n        <button type="button" class="btn btn-clean btn-secondary" data-action="dismiss-all">\n            '+
((__t=(t('collection::object:details:assets:dismiss-all')))==null?'':_.escape(__t))+
'\n        </button>\n    </div>\n\n    <ul class="assets-list list-file-upload '+
((__t=(when(view.getOption('field').type === 'images' ,'list list-flex ')))==null?'':_.escape(__t))+
'"></ul>\n</div>';
}
return __p;
};