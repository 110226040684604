module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form id="creditcard-form" autocomplete="on">\n    <fieldset>\n        <legend '+
((__t=( when(view.getOption('isSetup'), 'data-step-number="3"')))==null?'':__t)+
'>\n            '+
((__t=( t('subscription::payment:headline:pay-with-creditcard') ))==null?'':_.escape(__t))+
'\n        </legend>\n\n        <fieldset>\n            <ul class="available-creditcards">\n                ';
 _.each(item.supportedCreditcards, function(card) { 
__p+='\n                    <li data-type="'+
((__t=( card.type ))==null?'':_.escape(__t))+
'">\n                        <img src="/images/payment/'+
((__t=( card.icon ))==null?'':_.escape(__t))+
'" alt="'+
((__t=( card.name ))==null?'':_.escape(__t))+
'" title="'+
((__t=( card.name ))==null?'':_.escape(__t))+
'">\n                    </li>\n                ';
 }); 
__p+='\n            </ul>\n        </fieldset>\n\n        <!-- Cardholder name -->\n        <fieldset class="input-label-group">\n            <input type="text"\n                name="cardHolder"\n                id="card-holder"\n                autocomplete="cc-name"\n                placeholder="'+
((__t=( t('subscription::payment:placeholder:cardholder') ))==null?'':_.escape(__t))+
'"\n            />\n            <label for="card-holder" data-position="right">'+
((__t=( t('subscription::payment:label:cardholder') ))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n\n        <!-- Card number -->\n        <fieldset class="input-label-group">\n            <input type="text"\n                name="cardNumber"\n                id="card-number"\n                autocomplete="cc-number"\n                placeholder="'+
((__t=( t('subscription::payment:placeholder:card-number') ))==null?'':_.escape(__t))+
'"\n            />\n            <label for="card-number" data-position="right">'+
((__t=( t('subscription::payment:label:card-number') ))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n\n        <!-- Expires -->\n        <fieldset class="input-label-group">\n            <input type="text"\n                name="cardExpires"\n                id="card-expires"\n                autocomplete="cc-exp"\n                placeholder="'+
((__t=( t('subscription::payment:placeholder:expiration-date') ))==null?'':_.escape(__t))+
'"\n            />\n            <label for="card-expires" data-position="right">'+
((__t=( t('subscription::payment:label:expiration-date') ))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n\n        <!-- CVC -->\n        <fieldset class="input-label-group input-label-group-cvv">\n            <input type="text"\n                name="cardCvv"\n                id="card-cvv"\n                autocomplete="cc-csc"\n                placeholder="'+
((__t=( t('subscription::payment:placeholder:cvc') ))==null?'':_.escape(__t))+
'"\n            />\n            <label for="card-cvv" data-position="right">'+
((__t=( t('subscription::payment:label:cvc') ))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n\n        ';
 if (!view.getOption('isSetup')) { 
__p+='\n            <fieldset class="submit">\n                <button type="submit" class="btn btn-primary" data-action="save-payment">'+
((__t=( t('subscription::payment:button:save') ))==null?'':_.escape(__t))+
'</button>\n            </fieldset>\n        ';
 } 
__p+='\n    </fieldset>\n</form>\n';
}
return __p;
};