'use strict';

var Model = require('base/Colido.Model');

var SubscriptionModel = Model.extend({
    url: function() {
        return App.getApiUrl('/accounts/' + App.user.id + '/subscription');
    },

    name: 'account:subscription',

    defaults: function() {
        return {
            id: -1, //hack to allow PATCH request (needs isNew() to be false, otherwise always uses POST)
            setupStep: 0,
            subscriptionActive: false,
            subscriptionBalance: 0, // positiv = open amount! negative = credit
            currentBillingInterval: 'monthly',
            upcomingBillingInterval: 'monthly',
            braintreeToken: '',
            plans: [],
            sumAmount: 10.08,
            sumAmountVat: 1.92,
            sumAmountTotal: 12,
            vatId: '',
            vatIdValid: true,
            paymentMethod: null,
            billingAddress: {
                fullname:   '',
                company:    '',
                street:     '',
                zip:        '',
                city:       '',
                country:    '',
            },
            currency: 'EUR',
            taxCountry: 'DE',
        };
    },

    validate: function(values) {
        var errors = {};

        // if (_.isEmpty(values.email) || !values.email.match(/.*@.*/)){
        //     errors.email = App.t('user::validate:invalidEmail');
        // }

        return _.isEmpty(errors) ? null : errors;
    },

    customAttributes: {
        selectedPlan: function() {
            var plan = _.findWhere(this.get('plans'), {selected: true});

            return _.extend({}, plan, {
                amount: plan['amount' + _.ucfirst(this.get('upcomingBillingInterval'))],
                amountVat: plan['amount' + _.ucfirst(this.get('upcomingBillingInterval') + 'Vat')],
                amountTotal: plan['amount' + _.ucfirst(this.get('upcomingBillingInterval') + 'Total')],
            });
        },

        activePlan: function() {
            var plan = _.findWhere(this.get('plans'), {active: true});

            return _.extend({}, plan, {
                amount: plan['amount' + _.ucfirst(this.get('currentBillingInterval'))],
                amountVat: plan['amount' + _.ucfirst(this.get('currentBillingInterval') + 'Vat')],
                amountTotal: plan['amount' + _.ucfirst(this.get('currentBillingInterval') + 'Total')],
            });
        },

        willSwitchPlan: function() {
            return (this.get('selectedPlan').id !== this.get('activePlan').id || this.get('upcomingBillingInterval') !== this.get('currentBillingInterval'));
        },
    },
});

module.exports = SubscriptionModel;