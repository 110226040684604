'use strict';

var Wreqr = require('base/Colido.Wreqr');
var StorageModel = require('./models/storage');

var LocationWreqr = Wreqr.extend({
    name: 'collection:location',

    /**
     * Deletes a single object or the selected objects in a collection.
     * @param {LocationModule.StorageModel} storageModel The storage model, that should be deleted
     */
    commandDelete: function(storageModel) {
        if (storageModel instanceof StorageModel) {
            var catalog = storageModel.collection.catalog;

            storageModel.destroy({
                success: function(){
                    Messenger().info({
                        message: App.t('collection::locations:response:deleted', {
                            name: _.escape(storageModel.get('name')),
                            storageType: storageModel.get('storType') === 'status'
                                ? App.t('collection::locations:label:location').toLowerCase()
                                : App.t('collection::locations:label:storage').toLowerCase(),
                        }),
                        actions: {
                            trash: {
                                label: App.t('settings::trash:show-trash-button'),
                                action: function() {
                                    App.router.navigate('/catalogs/' + catalog.id + '/trash', {trigger: true});
                                },
                            },
                        },
                    });
                },
                error: function(){
                    Messenger().error({
                        message:  App.t('collection::locations:response:deleted:unknown-error', {name: _.escape(storageModel.get('name'))}),
                    });
                },
            });
        }
    },

    /**
     * Creates a new storage for the given name
     * @param {string} name   The desired name of the storage
     * @param {number} parentId Parent storage Id or 0
     * @option {boolean} default: true
     */
    commandCreateStorage: function(catalog, name, parentId, showMessage) {
        if (_.isUndefined(showMessage)) {
            showMessage = true;
        }

        var model = new catalog.storages.model(null, {collection: catalog.storages});

        model.save({
            name: name,
            parent: parentId,
        }, {
            error: function(model, errorObject){
                Messenger().error({
                    message: errorObject.responseJSON.message,
                });
            },
            success: function(model) {
                catalog.storages.add(model);

                // Trigger creation of storage
                App.vent.trigger('collection:storage-created', model);

                // Trigger sort on main collection
                if (model.collection) {
                    model.collection.sort();
                }

                if (showMessage) {
                    Messenger().post({
                        type: 'success',
                        message: App.t('collection::locations:response:create-storage-success', {name: _.escape(model.get('name'))}),
                        actions: {
                            show: {
                                label: App.t('collection::locations:response:create-storage-show'),
                                action: function() {
                                    App.router.navigate('/catalogs/' + catalog.id + '/storages/' + model.get('id'), {trigger: true});
                                },
                            },
                        },
                    });
                }
            },
        });
    },
});

module.exports = LocationWreqr;