'use strict';

;(function(){
    'use strict';

    ///////////////////////////////////////////////////////////////////
    //                                                               //
    //  This file is shared by every one.                            //
    //  To change config values, copy /config/develop-sample.js      //
    //  to /config/develop.js                                        //
    //  and change your personal values there.                       //
    //                                                               //
    ///////////////////////////////////////////////////////////////////

    window.Colido.configDefault =  {
        basePath: '',
        apiUrl: 'https://api.colido.de',
        apiVersion: 'v2',
        socketUrl: 'wss://api.colido.de:4712',
        showMissingTranslations: false,
        debug: false,
        useIntercom: false,
        useZendesk: true,
        usePiwik: false,
        useCommunityModule: true,
        useShopModule: true,
        usePhotobox: false,
        useDropbox: true,
        dropboxAppKey: '4rl9hwsghzk58sb',
        allowSharing: true,
        allowSignup: true,

        continents: [
            {name: 'Africa',          id: 21},
            {name: 'Asia',            id: 18},
            {name: 'Australia',       id: 31},
            {name: 'Europe',          id: 24},
            {name: 'North America',   id: 41},
            {name: 'South America',   id: 34},
        ],
    };
})();