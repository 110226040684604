module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div>\n    <div class="row row-slim">\n        <div class="col-12">\n            <h3>'+
((__t=( item.name ))==null?'':_.escape(__t))+
'</h3>\n        </div>\n    </div>\n    <div class="row row-slim">\n        <div class="col-12">\n            <input type="text" name="status-recipient" placeholder="'+
((__t=( t('collection::change-status:placeholder-recipient') ))==null?'':_.escape(__t))+
'">\n        </div>\n    </div>\n    <div class="row row-slim">\n        <div class="col-12">\n            <button type="button" class="btn btn-primary" data-action="change-status">'+
((__t=( t('collection::change-status:button-change-status') ))==null?'':_.escape(__t))+
'</button>\n        </div>\n    </div>\n</div>';
}
return __p;
};