module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 for (var i = 0; i < item.activeFilterButtons.length; i++) { 
__p+='\n    <li class="active-filter">'+
((__t=( item.activeFilterButtons[i] ))==null?'':__t)+
'</li>\n';
 } 
__p+='';
}
return __p;
};