module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<section class="object-text">\n    <h4>'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</h4>\n    '+
((__t=( multiline(model.get(item.field.name))))==null?'':__t)+
'\n</section>';
}
return __p;
};