module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</h3>\n\n<ol class="object-species-list">\n    ';
 _.each(model.get(item.field.name), function(species){  
__p+='\n        <li>\n            ';
 if (species.correlation){ 
__p+='\n                <span class="object-species-correlation">\n                    '+
((__t=(t('collection::object:details:species-correlation-' + species.correlation + ':label')))==null?'':_.escape(__t))+
'\n                </span>\n            ';
 } 
__p+='\n            <div class="object-species">\n                <strong class="name">'+
((__t=(species.name))==null?'':_.escape(__t))+
'</strong>\n                <span class="object-species-chemical-structure">\n                	'+
((__t=( species.chemicalFormula ))==null?'':__t)+
'\n                </span>\n            </div>\n        </li>\n    ';
 }) 
__p+='\n</ol>\n';
}
return __p;
};