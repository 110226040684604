module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="list-selection-row '+
((__t=( ifelse(model.hasSubSet() && !item.isRecent && false, 'has-child', 'no-child') ))==null?'':_.escape(__t))+
' '+
((__t=( when(model.attributes.restricted === true, 'is-restricted') ))==null?'':_.escape(__t))+
'">\n    <li>\n        <h4>'+
((__t=(item.name))==null?'':_.escape(__t))+
'</h4>\n        ';
 if (model.hasSubSet() && !item.isRecent && false) { 
__p+='\n            '+
((__t=( icon('next') ))==null?'':__t)+
'\n        ';
 } else { 
__p+='\n            '+
((__t=( icon('deposit') ))==null?'':__t)+
'\n        ';
 } 
__p+='\n    </li>\n    ';
 if (!model.hasSubSet() && !item.isRecent && false) { 
__p+='\n        <li class="add-child">\n            <button type="button" class="btn btn-dashed" data-action="add-set">'+
((__t=( icon('add-small')))==null?'':__t)+
'</button>\n        </li>\n    ';
 } 
__p+='\n</div>';
}
return __p;
};