module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (model.get(item.field.name)) { 
__p+='\n    <dl>\n        <dt>'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</dt>\n        <dd>'+
((__t=(view.getText()))==null?'':_.escape(__t))+
'</dd>\n    </dl>\n';
 } 
__p+='';
}
return __p;
};