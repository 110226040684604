'use strict';

var Model = require('base/Colido.Model');

var SignupModel = Model.extend({
    //api: '/signup',

    defaults: {
        email: '',
        password: '',
        fullname: '',
        signUpOk: '',
        language: '',
    },

    validate: function(values) {
        var errors = {};

        if (_.isEmpty(values.email) || !values.email.match(/.*@.*/)){
            errors.email = App.t('forgot-password::invalid-email');
        }

        if (_.isEmpty(values.password)){
            errors.password = '_.isEmpty(values.password)';
        }

        if (_.isEmpty(values.fullname)){
            errors.fullname = '_.isEmpty(values.fullname)';
        }

        if (values.signUpOk !== 1){
            errors.signUpOk = 'values.signUpOk !== 1';
        }

        return _.isEmpty(errors) ? null : errors;
    },
});

module.exports = SignupModel;