'use strict';

var Marionette = require('backbone-marionette');
var Controller = require('./controller');
var Wreqr = require('./wreqr');

var EditModule = require('./modules/edit/module');
var AlbumModule = require('./modules/album/module');
var SetModule = require('./modules/set/module');
var ShopModule = require('./modules/shop/module');
var LocationModule = require('./modules/location/module');
var ImportModule = require('./modules/import/module');
var ExportModule = require('./modules/export/module');
var PreferencesModule = require('./modules/preferences/module');
var TrashModule = require('./modules/trash/module');

var CatalogsCollection = require('./collections/catalogs');
var ObjectsCollection = require('./collections/objects');
var StatusCollection = require('./collections/status');
var SpeciesCollection = require('./collections/species');

var CollectionModule = Marionette.Module.extend({
    startWithParent: true,

    initialize: function(moduleName, app) {
        this.listenTo(app.vent, 'logged-in', function(){
            app.loadData(this.catalogs);
            app.loadData(this.speciesCollection);
        }.bind(this));
    },

    onStart: function(options) {
        var app = this.app;

        this.catalogs = new CatalogsCollection();

        App.module('CollectionModule.EditModule', EditModule);
        App.module('CollectionModule.AlbumModule', AlbumModule);
        App.module('CollectionModule.SetModule', SetModule);
        App.module('CollectionModule.LocationModule', LocationModule);
        App.module('CollectionModule.ImportModule', ImportModule);
        App.module('CollectionModule.ExportModule', ExportModule);
        App.module('CollectionModule.PreferencesModule', PreferencesModule);
        App.module('CollectionModule.TrashModule', TrashModule);

        if (Colido.config.useShopModule) {
            App.module('CollectionModule.ShopModule', ShopModule);
        }

        this.speciesCollection = new SpeciesCollection(); //@todo remove when catalog support is refatored

        this.statusCollection = new StatusCollection([
            {
                key: 'in-collection',
                name: app.t('collection::object:details:object-status:in-collection:label'),
                hasRecipient: false,
                position: 1,
            },
            {
                key: 'missing',
                name: app.t('collection::object:details:object-status:missing:label'),
                hasRecipient: false,
                position: 2,
            },
            {
                key: 'lent',
                name: app.t('collection::object:details:object-status:lent:label'),
                hasRecipient: true,
                position: 3,
            },
            {
                key: 'deaccessioned',
                name: app.t('collection::object:details:object-status:deaccessioned:label'),
                hasRecipient: true,
                position: 4,
            },
        ]);

        var controller = this.controller = new Controller({
            app: app,
            module: this,
        });

        this.wreqr = new Wreqr({
            app: app,
            module: this,
        });

        app.addInitializer(function(){
            app.router.processAppRoutes(controller, controller.routes);
        }.bind(this));
    },
});

module.exports = CollectionModule;