module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="location-container">\n    <div class="list-layout-scroll-container">\n        <header class="content-header">\n            <h2>'+
((__t=(t('collection::locations:headline', {catalogName: view.catalog.get('name')})))==null?'':_.escape(__t))+
'</h2>\n            <h2 class="subline">\n                '+
((__t=(t('collection::locations:label-in-storage')))==null?'':_.escape(__t))+
': <span id="count-storage">0</span>\n                /\n                <span class="header-location"></span>\n                /\n                '+
((__t=(t('collection::locations:label-undefined')))==null?'':_.escape(__t))+
': <span id="count-undefined">0</span>\n            </h2>\n\n            <div id="search" class="search-region search-header"></div>\n        </header>\n\n        <div class="container container-dark locations-list-header">\n            <div class="container">\n                <div class="listcontrol-light">\n                    <h3>'+
((__t=(t('collection::locations:headline-storages')))==null?'':_.escape(__t))+
'</h3>\n                    <button class="btn btn-secondary btn-secondary-2" data-action="manage-storages" data-targetstate="manage">'+
((__t=(t('collection::manage-storages:label')))==null?'':_.escape(__t))+
'</button>\n                </div>\n                <div id="regionStorageList"></div>\n            </div>\n            <div class="container">\n                <h3>'+
((__t=(t('collection::locations:headline-other-locations')))==null?'':_.escape(__t))+
'</h3>\n                <div id="regionLocationList"></div>\n            </div>\n        </div>\n\n        <div class="container container-with-dnd listcontrol-region" data-state="default"></div>\n\n        <div class="container container-undefined">\n            <h3>'+
((__t=(t('collection::locations:headline-undefined')))==null?'':_.escape(__t))+
'</h3>\n            <div id="regionUndefinedList"></div>\n        </div>\n    </div>\n</div>';
}
return __p;
};