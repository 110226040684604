module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="contacts-metainfo">\n    <h4 class="contacts-count">'+
((__t=(view.children.length))==null?'':_.escape(__t))+
'</h4> <button data-action="show-add-contacts-form" class="btn-primary btn-contacts-add">'+
((__t=(t('community::button:label:invite-friends')))==null?'':_.escape(__t))+
' '+
((__t=( icon('add-small')))==null?'':__t)+
'</button>\n\n    <form>\n        <fieldset>\n            '+
((__t=( multiline(t('community::new-contacts:hint'))))==null?'':__t)+
'\n        </fieldset>\n\n        <fieldset>\n            <label for="newContactEmail">'+
((__t=(t('community::input:label:new-contacts:email')))==null?'':_.escape(__t))+
'</label>\n            <input id="newContactEmail" type="email" name="newContactEmail" placeholder="'+
((__t=(t('community::input:placeholder:new-contacts:email')))==null?'':_.escape(__t))+
'"></input>\n        </fieldset>\n\n        <fieldset>\n            <label for="newContactsMessage">'+
((__t=(t('community::input:label:new-contacts:message')))==null?'':_.escape(__t))+
'</label>\n            <textarea id="newContactsMessage" name="newContactMessage" rows="1" data-autosize="true"></textarea>\n        </fieldset>\n\n        <fieldset>\n            <button type="submit" class="btn-primary">'+
((__t=(t('community::button:label:new-contacts:submit')))==null?'':_.escape(__t))+
'</button>\n        </fieldset>\n    </form>\n</div>\n\n<ul class="contacts-list"></ul>';
}
return __p;
};