'use strict';

var HeaderView = require('modules/collection/views/layout/header');

var template = require('modules/collection/modules/location/templates/layout/detail-header.hbs');

var DetailHeaderView = HeaderView.extend({
    template: template,

    ui: {
        container: 'header',
        toggleEditMode: '.toggle-editmode',
        headerForm: '#form-edit-storage',
        buttonDeleteStorage: 'button[data-action="delete-storage"]',
        storageName: '.storage-name',
        nameInput: 'input[name="name"]',
    },

    events: {
        'click @ui.toggleEditMode': 'toggleEditMode',
        'click @ui.storageName': 'toggleEditMode',
        'reset @ui.headerForm': 'resetHeaderForm',
        'submit @ui.headerForm': 'submitHeaderForm',
        'click @ui.buttonDeleteStorage': 'onActionDeleteStorage',
    },

    viewData: {
        backLink: function() {
            var link = '/catalogs/' + this.catalog.id + '/storages';

            var parent = this.locationModel.getParent();
            if (parent) {
                link += '/' + parent.id;
            }

            return link;
        },
    },

    initialize: function(options) {
        HeaderView.prototype.initialize.apply(this, arguments);
        this.locationModel = options.locationModel;
        this.catalog = this.getOption('catalog');

        // Listen to edit, delete from listcontrol
        if (this.getOption('editable')) {
            this.listenTo(App.radio('collection:listcontrol').vent, 'edit', this.toggleEditMode.bind(this));
            this.listenTo(App.radio('collection:listcontrol').vent, 'delete', this.onActionDeleteStorage.bind(this));
        }
    },

    /**
     * Toggles the editmode in the header
     * @param {object=} event The event object
     * @return {void}
     */
    toggleEditMode: function(event) {
        if (!$('body').hasClass('is-showroom')) {
            this.ui.container.toggleClass('is-editmode');
            this.ui.nameInput.val(this.model.get('locationModel').get('name'));
            if (this.ui.container.hasClass('is-editmode')) {
                _.delay(function() {
                    var $nameInput = this.ui.nameInput;
                    $nameInput.focus();
                    if ($nameInput.val().length > 0) {
                        $nameInput[0].setSelectionRange(0, $nameInput.val().length);
                    }
                }.bind(this), 400);
            }
        }
    },

    /**
     * Resets the header form
     * @param {object} event The event object
     * @return {void}
     */
    resetHeaderForm: function(event) {
        event.preventDefault();
        this.ui.container.toggleClass('is-editmode');
        this.ui.nameInput.val(this.locationModel.get('name'));
    },

    /**
     * Submits the header form
     * @param {object} event The event object
     * @return {void}
     */
    submitHeaderForm: function(event) {
        event.preventDefault();
        var newName = this.ui.nameInput.val();
        this.locationModel.save({name: newName}, {patch: true});
        this.ui.storageName.html(_.escape(this.locationModel.getBreadcrumb()));
        this.ui.container.toggleClass('is-editmode');
    },

    /**
     * Is triggered, when delete is pressed
     */
    onActionDeleteStorage: function(event) {
        if (event) {
            event.preventDefault();
        }

        var model = this.model.get('locationModel');

        // Ask user for confirmation as the action can't be undone
        if (!window.confirm(App.t('collection::locations:confirm-delete-location', {name: model.get('name')}))) {
            return;
        }

        var catalog = model.collection.catalog;

        App.router.navigate('/catalogs/' + catalog.id + '/storages', {trigger: true});

        App.radio('collection:location').execute('delete', model);
    },
});

module.exports = DetailHeaderView;