module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(t('subscription::quota:title')))==null?'':_.escape(__t))+
'</h3>\n\n<div class="quota-bar">\n	<progress value="'+
((__t=(item.quotaUsage))==null?'':_.escape(__t))+
'" max="'+
((__t=(item.licenseQuota))==null?'':_.escape(__t))+
'">\n	    '+
((__t=(byte(item.quotaUsage)))==null?'':_.escape(__t))+
'\n	</progress>\n\n	<span class="quota-min">0</span>\n	<span class="quota-usage" style="left: '+
((__t=(Math.min(item.quotaUsage/item.licenseQuota*100, 100)))==null?'':_.escape(__t))+
'%;" data-percent-used="'+
((__t=(item.quotaUsage/item.licenseQuota*100))==null?'':_.escape(__t))+
'" title="'+
((__t=(number(item.quotaUsage)))==null?'':_.escape(__t))+
' Bytes">\n	    '+
((__t=( t('subscription::quota:label:usage-indicator', {amount: byte(item.quotaUsage)}) ))==null?'':_.escape(__t))+
'\n	</span>\n	<span class="quota-max">'+
((__t=(byte(item.licenseQuota)))==null?'':_.escape(__t))+
'</span>\n</div>\n\n';
 if (item.quotaUsage > item.licenseQuota) { 
__p+='\n    <div class="hint">\n        '+
((__t=( multiline(t('subscription::quota:text:exceeded-hint'))))==null?'':__t)+
'\n    </div>\n';
 } 
__p+='';
}
return __p;
};