module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header" data-state="view">\n    <div class="container-showroom-grid">\n        <div class="header-part header-part-view">\n            <a href="'+
((__t=( item.backLink ))==null?'':_.escape(__t))+
'" class="btn btn-back">'+
((__t=( icon('prev') ))==null?'':__t)+
'</a>\n            <h2>\n                ';
 if (view.locationModel.get('storType') === 'status') { 
__p+='\n                    '+
((__t=( t('collection::locations:headline:location') ))==null?'':_.escape(__t))+
'\n                    <span class="location-name">'+
((__t=( view.locationModel.get('name') ))==null?'':_.escape(__t))+
'</span>\n                ';
 } else { 
__p+='\n                    <span class="toggle-headline">\n                        '+
((__t=( t('collection::locations:headline:storage') ))==null?'':_.escape(__t))+
'\n                        <span class="storage-name">'+
((__t=( view.locationModel.getBreadcrumb() ))==null?'':_.escape(__t))+
'</span>\n                    </span>\n                ';
 } 
__p+='\n            </h2>\n            <h2 class="subline">\n                <span id="object-count">'+
((__t=(view.locationModel.getItemsRecursive().length))==null?'':_.escape(__t))+
'</span> '+
((__t=(t('collection::objects:count', view.locationModel.getItemsRecursive().length)))==null?'':_.escape(__t))+
'\n            </h2>\n\n            ';
 if (view.locationModel.get('storType') != 'status') { 
__p+='\n            <div class="btn-group">\n                <button id="toggle-edit" class="btn toggle-editmode btn-secondary btn-m"> '+
((__t=( t('collection::locations:edit:label') ))==null?'':_.escape(__t))+
'</button>\n            </div>\n            ';
 } 
__p+='\n\n            ';
 if (view.getOption('search')){ 
__p+='\n                <div id="search" class="search-region search-header"></div>\n            ';
 } 
__p+='\n        </div>\n\n        <div class="header-part header-part-edit">\n            <form id="form-edit-storage" method="post">\n                <ul>\n                    <li class="input">\n                        <input type="text"\n                               name="name"\n                               placeholder="Type storage name"\n                               value="'+
((__t=(item.name))==null?'':_.escape(__t))+
'"\n                               />\n                    </li>\n                    <li class="actions">\n                        <button type="submit" class="btn btn-primary btn-m">'+
((__t=(t('collection::locations:edit:done')))==null?'':_.escape(__t))+
'</button>\n                        <button type="reset" class="btn btn-secondary btn-m">'+
((__t=(t('collection::locations:edit:cancel')))==null?'':_.escape(__t))+
' '+
((__t=( icon('close-light') ))==null?'':__t)+
'</button>\n                        ';
 if (item.storType != 'status') { 
__p+='\n                            <button type="button" data-action="delete-storage" class="btn btn-clean btn-tertiary btn-danger">'+
((__t=(t('collection::locations:edit:delete')))==null?'':_.escape(__t))+
' '+
((__t=( icon('trash') ))==null?'':__t)+
'</button>\n                        ';
 } 
__p+='\n                    </li>\n                </ul>\n            </form>\n        </div>\n\n        ';
 if (view.getOption('showroomOptions')){ 
__p+='\n            <div class="showroom-options-region"></div>\n        ';
 } 
__p+='\n\n        ';
 if (view.getOption('viewSwitch')) { 
__p+='\n            <div class="view-switch-region"></div>\n        ';
 } 
__p+='\n    </div>\n</header>';
}
return __p;
};