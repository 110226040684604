'use strict';

var LayoutView = require('base/Colido.LayoutView');
var BackboneModel = require('backbone').Model;

var ConversationPostShareSetView = require('./conversation-post-share-set');
var ConversationPostOfferProcessView = require('./conversation-post-offer-process');
var ConversationPostOfferCompletedView = require('./conversation-post-offer-completed');

var template = require('modules/community/templates/partials/conversation-post.hbs');

var ConversationPostItemView = LayoutView.extend({
    template: template,

    tagName: 'li',

    className: function() {
        var classNames = ['post'];

        if (this.isFirstPostOfDay()){
            classNames.push('first-post-of-day');
        }

        if (this.isFirstPostOfUser()){
            classNames.push('first-post-of-user');
        }

        if (this.isPostOfCurrentUser()){
            classNames.push('post-of-current-user');
        }

        if (this.isSystemPost()){
            classNames.push('system-post');
        }

        if (this.isSharedSetPost()){
            classNames.push('set-post');
        }

        if (this.isOfferProcessPost()){
            classNames.push('offer-process-post');
        }

        if (this.isOfferCompletedPost()){
            classNames.push('offer-completed-post');
        }

        return classNames.join(' ');
    },

    isFirstPostOfDay: function() {
        var previousPost = this.model.collection.getPreviousModel(this.model);
        var currentPost = this.model;

        if (previousPost) {
            var previousPostDate = new Date(previousPost.get('created') * 1000);
            var currentPostDate = new Date(currentPost.get('created') * 1000);

            return currentPostDate.getDate() !== previousPostDate.getDate();
        }

        return true;
    },

    isFirstPostOfUser: function() {
        var previousPost = this.model.collection.getPreviousModel(this.model);
        var currentPost = this.model;

        if (!previousPost || previousPost.get('userId') !== currentPost.get('userId')) {
            return true;
        }

        return false;
    },

    isPostOfCurrentUser: function() {
        return App.user.id === this.model.get('userId');
    },

    isSystemPost: function() {
        return this.model.get('type') === 'system';
    },

    isSharedSetPost: function() {
        return this.model.get('type') === 'set';
    },

    isOfferProcessPost: function() {
        return this.model.get('type') === 'offer';
    },

    isOfferCompletedPost: function() {
        return this.model.get('type') === 'offer-completed';
    },

    viewData: {
        authorName: function() {
            var authorData = this.model.get('authorData');

            return  authorData ? authorData.name || authorData.userId : 'Unkown User'; //@todo remove id, translate
        },

        profilePicture: function() {
            var authorData = this.model.get('authorData');

            return  authorData ? authorData.profilePicture : null;
        },
    },

    onRender: function() {
        var postType = this.model.get('type');

        if (postType === 'set') {
            this.addRegion('postMessageRegion', '.post-message');
            this.postMessageRegion.show(new ConversationPostShareSetView({
                model: new BackboneModel(this.model.get('data')),
                conversation: this.model.collection.conversation,
            }));
        }

        if (postType === 'offer' || postType === 'offer-completed') {
            var offerData = this.model.get('data');
            var shopType = offerData.shopType;
            var objectData = offerData.objectData;

            var shop = App.CollectionModule.ShopModule.shops.get(shopType);
            var objectModel = new shop.objects.model(objectData, {collection: shop.objects});

            var offerModel = shop.offers.get(offerData.id);

            if (!offerModel) {
                offerModel = new shop.offers.model({id: offerData.id}, {collection: shop.offers});

                shop.offers.add(offerModel);
            }

            this.addRegion('postMessageRegion', '.post-message');
            this.postMessageRegion.$el.addClass('loading');

            offerModel.fetch({
                success: function(model) {
                    if (this.isDestroyed) { return; }

                    this.postMessageRegion.$el.removeClass('loading');

                    var ViewClass = postType === 'offer' ? ConversationPostOfferProcessView : ConversationPostOfferCompletedView;
                    this.postMessageRegion.show(new ViewClass({
                        model: offerModel,
                        shop: shop,
                        objectModel: objectModel,
                        conversation: this.model.collection.conversation,
                    }));
                }.bind(this),
            });
        }
    },
});

module.exports = ConversationPostItemView;