module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (!view.getOption('viewSwitchDetail')) { 
__p+='\n    <label>'+
((__t=(t('collection::view-switch-detail:label')))==null?'':_.escape(__t))+
'</label>\n';
 } 
__p+='\n\n<div class="option-select">\n    <!-- Grid -->\n    <input id="changeViewGrid'+
((__t=( when(view.getOption('viewSwitchDetail'), '-detail') ))==null?'':_.escape(__t))+
'"\n           type="radio"\n           name="view'+
((__t=( when(view.getOption('viewSwitchDetail'), '-detail') ))==null?'':_.escape(__t))+
'"\n           value="grid"\n           '+
((__t=( when(model.get('viewMode') === 'grid', 'checked="checked"') ))==null?'':_.escape(__t))+
'>\n    <label for="changeViewGrid'+
((__t=( when(view.getOption('viewSwitchDetail'), '-detail') ))==null?'':_.escape(__t))+
'" class="view-mode-label">'+
((__t=( icon('view-grid') ))==null?'':__t)+
'<span>'+
((__t=(t('collection::view-switch-detail:grid-view')))==null?'':_.escape(__t))+
'</span></label>\n\n    <!-- List -->\n    <input id="changeViewList'+
((__t=( when(view.getOption('viewSwitchDetail'), '-detail') ))==null?'':_.escape(__t))+
'"\n           type="radio"\n           name="view'+
((__t=( when(view.getOption('viewSwitchDetail'), '-detail') ))==null?'':_.escape(__t))+
'"\n           value="list"\n           '+
((__t=( when(model.get('viewMode') === 'list', 'checked="checked"') ))==null?'':_.escape(__t))+
'>\n    <label for="changeViewList'+
((__t=( when(view.getOption('viewSwitchDetail'), '-detail') ))==null?'':_.escape(__t))+
'" class="view-mode-label">'+
((__t=( icon('view-list') ))==null?'':__t)+
'<span>'+
((__t=(t('collection::view-switch-detail:list-view')))==null?'':_.escape(__t))+
'</span></label>\n\n    <!-- Showroom -->\n    <!--\n    ';
 if (!view.getOption('viewSwitchDetail') && !view.getOption('disableShowroomViewSwitch')) { 
__p+='\n            <input id="changeViewShowroom'+
((__t=( when(view.getOption('viewSwitchDetail'), '-detail') ))==null?'':_.escape(__t))+
'"\n                   type="radio"\n                   name="view'+
((__t=( when(view.getOption('viewSwitchDetail'), '-detail') ))==null?'':_.escape(__t))+
'"\n                   value="showroom">\n            <label for="changeViewShowroom'+
((__t=( when(view.getOption('viewSwitchDetail'), '-detail') ))==null?'':_.escape(__t))+
'" class="view-mode-label">'+
((__t=( icon('view-showroom') ))==null?'':__t)+
'<span>Showroom</span></label>\n    ';
 } 
__p+='\n    -->\n</div>';
}
return __p;
};