'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone-marionette');
var LayoutView = require('base/Colido.LayoutView');

var template = require('../../templates/partials/modal-list-item.hbs');

var ModalItemView = LayoutView.extend({
    template: template,

    className: 'modal',

    additionalClassName: '',
    additionalBgClassName: '',

    regions: {
        contentRegion: '.modal-content-region',
    },

    modalContent: null,
    startTransitionStarted: false,

    emptyView: LayoutView.extend({
        template: function(){ return 'Please provide a Marionette.View'; },
    }),

    initialize: function(options) {
        LayoutView.prototype.initialize.apply(this, arguments);

        var content = this.model.get('content');

        if (_.isFunction(content)) {
            var ContentView = content;
            content = new ContentView();
        }

        this.modalContent = content instanceof Marionette.View ? content : new this.emptyView();

        this.$el.addClass(this.getOption('additionalClassName'));
        this.onAfterClose = this.getOption('onAfterClose');
        this.onBeforeClose = this.getOption('onBeforeClose');

        this.model.set('view', this); //used to return a view from App.addModal()

        this.listenTo(Backbone.history, 'route', this.close.bind(this));
        this.listenTo(this.modalContent, 'close:modal', this.close.bind(this));

        this.handleTransitionEvent = this.handleTransitionEvent.bind(this);
        this.$el.on('transitionend webkitTransitionEnd', this.handleTransitionEvent);
    },

    onRender: function() {
        this.contentRegion.show(this.modalContent);

        if (this.modalContent.$el.find('.modal-footer').length) {
            this.$el.addClass('has-footer');
        }
    },

    onAttach: function() {
        this.open();
    },

    /**
     * Optional callback. Called before modal is closed. If this function returns false, it can prevent the closing
     * @returns {boolean}
     */
    onBeforeClose: function(data) {},

    /**
     * Optional callback. Called after modal is closed.
     */
    onAfterClose: function(data) {},

    open: function() {
        setTimeout(function(){
            this.$el.addClass('modal-visible');

            if (this.getOption('additionalBgClassName')) {
                this.triggerMethod('addBg', this.getOption('additionalBgClassName'));
            }
        }.bind(this), 1);
    },

    close: function(data) {
        // make sure, that startTransitionEnded was set. without it, handleTransitionEvent would never be fired
        if (!this.startTransitionStarted) {
            return;
        }

        if (this.onBeforeClose(data) !== false) {
            this.$el.data('close-data', data);
            this.$el.removeClass('modal-visible');

            if (this.getOption('additionalBgClassName')) {
                this.triggerMethod('removeBg', this.getOption('additionalBgClassName'));
            } else {
                this.triggerMethod('removeBg', null);
            }
        }
    },

    handleTransitionEvent: function(event) {
        var originalEvent = event.originalEvent;
        this.startTransitionStarted = true;

        if (event.target === event.currentTarget && _.contains(['transform', '-webkit-transform'], originalEvent.propertyName)) {
            if (this.$el.hasClass('modal-visible')) {
                this.modalContent.triggerMethod('after:open');
            } else {
                var data = this.$el.data('close-data');
                this.model.destroy();
                // this.onAfterClose(data);
                this.triggerMethod('after:close', data);
                this.modalContent.triggerMethod('after:close', data);
            }
        }
    },
});

module.exports = ModalItemView;