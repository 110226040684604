module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="box box-object-album box-border box-object-unused-album" data-id="'+
((__t=( item.id ))==null?'':_.escape(__t))+
'">\n    <div class="headline-wrapper">\n        <h4>'+
((__t=(item.title))==null?'':_.escape(__t))+
'</h4>\n        <button type="button" class="btn btn-clean btn-secondary" data-action="delete-unused">'+
((__t=(t('collection::album:modal:delete')))==null?'':_.escape(__t))+
'</button>\n    </div>\n\n    <ul>\n       ';
 for (var i = 0; i < item.images.length; i++) { 
__p+='\n            <li data-imageid="'+
((__t=( item.images[i].id ))==null?'':_.escape(__t))+
'"\n                data-imageidx="'+
((__t=( i ))==null?'':_.escape(__t))+
'"\n                ';
 if (model.isImagesSelected(i)) { 
__p+='class="is-selected"';
 } 
__p+='>\n                <img src="'+
((__t=(model.getImageUrl(imageSize('small'), i)))==null?'':_.escape(__t))+
'" />\n            </li>\n       ';
 } 
__p+='\n    </ul>\n\n    <div class="meta-info">\n        <span class="item-count">\n            '+
((__t=(t('collection::album:modal:images', model.getImageCount())))==null?'':_.escape(__t))+
'\n            /\n            '+
((__t=(t('collection::album:modal:selected', model.getSelectedCount())))==null?'':_.escape(__t))+
'\n        </span>\n    </div>\n</div>';
}
return __p;
};