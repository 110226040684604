module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ol class="range-distribution">';
 for (var i = 0; i < values.length; i++) { 
__p+='<li style="height: '+
((__t=( values[i]))==null?'':_.escape(__t))+
'%;"></li>';
 } 
__p+='</ol>';
}
return __p;
};