'use strict';

var ItemView = require('base/Colido.ItemView');

var template = require('modules/community/templates/partials/conversations-list-item.hbs');

var CovnersationsListItemView = ItemView.extend({
    template: template,

    tagName: 'li',

    className: function(){
        var classNames = ['conversation-card'];

        if (this.model.get('type') === 'group') {
            classNames.push('conversation-card-group');
        }

        if (this.model.get('type') === 'contact') {
            classNames.push('conversation-card-contact');
        }

        return classNames.join(' ');
    },

    ui: {
        'openConversation': '.open-conversation',
    },

    events: {
        'click @ui.openConversation': 'openConversation',
    },

    modelEvents: {
        'change': 'render',
    },

    // viewData: {
    //     unreadPostsCount: function() {
    //         var conversation = this.model.get('conversation');

    //         return conversation ?  conversation.get('unreadPosts') : 0;
    //     },

    //     isActive: function() {
    //         return this.isActive();
    //     },
    // },

    // initialize: function() {
    //     ItemView.prototype.initialize.apply(this, arguments);

    //     var conversation = this.model.get('conversation');

    //     if (conversation) {
    //         this.listenTo(conversation, 'change', function(){
    //             this.model.trigger('change');
    //         }.bind(this));
    //     }
    // },

    // showActions: function(event) {
    //     event.preventDefault();
    //     this.ui.actionLayer.addClass('is-visible');

    //     // Close actions overlay on next click
    //     setTimeout(function(){
    //         $('body').one('click', this.hideActions.bind(this));
    //     }.bind(this), 0)
    // },

    // hideActions: function() {
    //     if (this.ui.actionLayer.removeClass) {
    //         this.ui.actionLayer.removeClass('is-visible');
    //     }
    // },

    openConversation: function(event) {
        this.trigger('open:conversation', this.model);
    },
});

module.exports = CovnersationsListItemView;