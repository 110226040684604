'use strict';

var ListLayout = require('modules/collection/views/layout/list');
var ObjectHeaderView = require('./object-header');
var EmptyView = require('../partials/list-empty');

var ObjectListLayout = ListLayout.extend({
    getHeaderViewClass: function () {
        return ObjectHeaderView;
    },

    getEmptyViewClass: function () {
        return EmptyView;
    },
});

module.exports = ObjectListLayout;