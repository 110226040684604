'use strict';

require('base/Underscore.Setup');
require('base/Colido.View');
require('base/Marionette.Setup');
require('base/Messenger.Setup');

var Backbone = require('backbone');
var Application = require('base/Colido.Application');
var UserModel = require('modules/global/models/user');
var GlobalModule = require('modules/global/module');
var AppRouter = require('modules/global/app/router');
var AppLayout = require('modules/global/views/layout/app');
var loadModules = require('./modules/index');

// Start application
var App = new Application();

window.App = App;

App.Behaviors = {};

// Start Global module
App.module('Global', GlobalModule);

App.addInitializer(function() {
    this.router = new AppRouter();
    this.user = new UserModel();

    var language = this.user.getLanguage();
    this.setLanguage(language);
    document.documentElement.setAttribute('lang', language); // Append proper lang to document

    // Update language
    this.listenTo(App.radio().vent, 'language:changed', function(language) {
        this.setLanguage(language);
        document.documentElement.setAttribute('lang', language);
    }.bind(this));

    this.rootView = new AppLayout();
});

App.on('start', function(options) {
    Backbone.history.start({pushState: true, root: App.config.basePath});
});

App.vent.on('logged-in', function() {
    var loginRegion = App.rootView.loginRegion;
    loginRegion.$el.addClass('open');
});

loadModules(App);