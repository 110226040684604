'use strict';

var ItemView = require('base/Colido.ItemView');
var template = require('modules/community/templates/partials/conversation-post-offer-completed.hbs');

var ConversationPostOfferCompletedView = ItemView.extend({
    template: template,

    ui: {
        // 'actionOpenCatalog': '[data-action="complete-sale"]',
    },

    events: {
        // 'click @ui.actionCompleteSale': 'actionCompleteSale',
    },

    modelEvents: {
        'change': 'render',
    },

    className: function() {
        return 'offer-completed';
    },

    viewData: {
        targetObjectLink: function() {
            return this.model.get('targetObjectLink');
        },

        targetCatalogLink: function() {
            return this.model.get('targetCatalogLink');
        },
    },
});

module.exports = ConversationPostOfferCompletedView;