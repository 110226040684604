module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\r\n    <h2>'+
((__t=( t('helpdesk::headline') ))==null?'':_.escape(__t))+
'</h2>\r\n    <h2 class="subline">'+
((__t=( t('helpdesk::subline') ))==null?'':_.escape(__t))+
'</h2>\r\n</header>\r\n\r\n<div class="container">\r\n    <div class="container page-container">\r\n    	<div class="page-col-3" id="helpdesk-hint">\r\n            <h3>'+
((__t=( t('helpdesk::helpdesk:faq:label') ))==null?'':_.escape(__t))+
'</h3>\r\n            <p>'+
((__t=( t('helpdesk::helpdesk:faq:text') ))==null?'':_.escape(__t))+
'</p>\r\n            <a href="https://colido.zendesk.com/hc/" target="_blank" class="btn btn-primary">'+
((__t=( t('helpdesk::helpdesk:faq:button') ))==null?'':_.escape(__t))+
' <svg class="ico ico-next-small"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-next-small"></use></svg></a>\r\n		</div>\r\n\r\n		<div class="page-col-2 col-gap">\r\n			<div class="box box-hint">\r\n			    <h4>'+
((__t=( t('helpdesk::helpdesk:still-have-questions:label') ))==null?'':_.escape(__t))+
'</h4>\r\n			    <p>'+
((__t=( t('helpdesk::helpdesk:still-have-questions:text') ))==null?'':_.escape(__t))+
'</p>\r\n				<a href="mailto:support@colido.de" class="btn btn-primary">'+
((__t=( t('helpdesk::helpdesk:still-have-questions:button') ))==null?'':_.escape(__t))+
' </a>\r\n			</div>\r\n		</div>\r\n    </div>\r\n\r\n    <div class="container page-container" id="getting-started-container">\r\n    	<div class="page-col-2">\r\n        	<h3>'+
((__t=( t('helpdesk::helpdesk:getting-started:label') ))==null?'':_.escape(__t))+
'</h3>\r\n			<p>'+
((__t=( t('helpdesk::helpdesk:getting-started:text') ))==null?'':_.escape(__t))+
'</p>\r\n		</div>\r\n\r\n		<div class="page-col-4">\r\n			<div class="video-box video-big">\r\n				<div class="video-header">\r\n					<h4>'+
((__t=( t('dashboard::headline:getting-started-video') ))==null?'':_.escape(__t))+
'</h4>\r\n					<h4 class="subline">5:54</h4>\r\n				</div>\r\n				<div class="video">\r\n					<iframe src="https://player.vimeo.com/video/231860529?color=ffffff&title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>\r\n				</div>\r\n			</div>\r\n		</div>\r\n    </div>\r\n\r\n</div>\r\n';
}
return __p;
};