'use strict';

var ItemView = require('base/Colido.ItemView');

var template = require('modules/authentication/templates/partials/delete-account.hbs');

var DeleteAccountView = ItemView.extend({
    template: template,

    ui: {
        idField: '[name="id"]',
        tokenField: '[name="token"]',
    },

    events: {
        'submit .delete-account-form': 'deleteAccount',
    },

    showDeleteForm: function(id, token){
        this.ui.tokenField.val(token);
        this.ui.idField.val(id);

        setTimeout(function(){
            this.$('form').removeClass('is-hidden');
        }.bind(this), 100);
    },

    deleteAccount: function(){
        event.preventDefault();

        var token = this.ui.tokenField.val();
        var id = this.ui.idField.val();

        this.$('form').html('').addClass('is-loading');

        App.user.deleteAccount(
            id,
            token,
            function() {
                this.showSuccess(App.t('delete-account::success'));
            }.bind(this),
            function() {
                this.showError(App.t('delete-account::fail'));
            }.bind(this)
        );
    },

    showSuccess: function(msg) {
        this.$('form').removeClass('is-hidden'); // make sure, that it is not hidden
        this.$('form').html(msg).removeClass('is-loading').addClass('loading-finish');
    },

    showError: function(msg){
        this.$('form').html(msg).removeClass('is-loading').addClass('loading-finish');
    },
});

module.exports = DeleteAccountView;