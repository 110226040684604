module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(t('collection::objects:filter:previous-owner:title')))==null?'':_.escape(__t))+
'</h3>\n\n<form>\n    <fieldset class="filter-buttons component-filter-buttons">\n        ';
 for (var i = 0; i < item.commonPreviousOwners.length; i++) { 
__p+='\n            <fieldset class="filter-button">\n                <input type="checkbox"\n                    id="objects-filter-previous-owners-'+
((__t=( item.commonPreviousOwners[i].type ))==null?'':_.escape(__t))+
'"\n                    value="'+
((__t=( item.commonPreviousOwners[i].type ))==null?'':_.escape(__t))+
'"\n                    />\n                <label for="objects-filter-previous-owners-'+
((__t=( item.commonPreviousOwners[i].type ))==null?'':_.escape(__t))+
'">\n                    '+
((__t=( item.commonPreviousOwners[i].displayName ))==null?'':_.escape(__t))+
'\n                </label>\n            </fieldset>\n        ';
 } 
__p+='        \n    </fieldset>\n\n    <fieldset class="filter-select">\n        <select>\n            <option value="">'+
((__t=(t('collection::objects:filter:previous-owner:select:other-prev-owners')))==null?'':_.escape(__t))+
'</option>\n\n            ';
 for (var i = 0; i < item.previousOwners.length; i++) { 
__p+='\n                <option value="'+
((__t=( item.previousOwners[i].type ))==null?'':_.escape(__t))+
'">\n                    '+
((__t=( item.previousOwners[i].displayName ))==null?'':_.escape(__t))+
'\n                </option>\n            ';
 } 
__p+='\n        </select>\n        <button class="clear-select"></button>\n    </fieldset>\n</form>';
}
return __p;
};