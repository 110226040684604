module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 _.each(item.users, function(user, index){ 
__p+='\n    ';
 if(view.getOption('groupMemberLimit') === false || index < view.getOption('groupMemberLimit')) { 
__p+='\n        <li>\n            <button class="contact-preview" data-action="show-contact-info" data-user-id="'+
((__t=(user.id))==null?'':_.escape(__t))+
'">\n                <div class="contact-image">\n                    <span class="contact-initials">'+
((__t=(_.initials(user.name)))==null?'':_.escape(__t))+
'</span>\n                    '+
((__t=( responsiveImage(user.profilePicture, imageSize('thumbnailFixed'), viewportSize('grid-object'), user.name, 'grid-image') ))==null?'':__t)+
'\n                </div>\n                <span class="contact-name">'+
((__t=( user.name ))==null?'':_.escape(__t))+
'</span>\n            </button>\n\n            <div class="contact-infos">\n                <button type="button" class="btn btn-close">'+
((__t=( icon('close-light')))==null?'':__t)+
'</button>\n\n                <div class="contact-personal-data">\n                    <div>\n                        <div class="contact-name">'+
((__t=( user.name ))==null?'':_.escape(__t))+
'</div>     \n                        ';
 if (user.biography) { 
__p+='\n                            <div class="contact-website">'+
((__t=( autolink(user.website) ))==null?'':__t)+
'</div>\n                        ';
 } 
__p+='\n                    </div>\n                    <div class="contact-image">\n                        <span class="contact-initials">'+
((__t=(_.initials(user.name)))==null?'':_.escape(__t))+
'</span>\n                        '+
((__t=( responsiveImage(user.profilePicture, imageSize('defaultFixed'), viewportSize('grid-object'), user.name) ))==null?'':__t)+
'\n                    </div>\n                    ';
 if (user.biography) { 
__p+='\n                        <div class="contact-biography">'+
((__t=( user.biography ))==null?'':_.escape(__t))+
'</div>\n                    ';
 } 
__p+='\n                </div>\n\n\n                ';
 var contact = App.CommunityModule.contactsCollection.getByUserId(user.id); 
__p+='\n                ';
 if(contact) { 
__p+='\n                    <a href="'+
((__t=(contact.get('conversationUrl')))==null?'':_.escape(__t))+
'" class="btn btn-primary">\n                        '+
((__t=(t('community::button:label:send-message')))==null?'':_.escape(__t))+
'\n                    </a>\n                ';
 } else if(user.id === App.user.id) { 
__p+='\n                    <a href="/account/profile" class="btn btn-primary">\n                        '+
((__t=(t('community::button:label:edit-profile')))==null?'':_.escape(__t))+
'\n                    </a>\n                ';
 } else { 
__p+='\n                    <button data-action="add-to-contacts" data-user-id="'+
((__t=(user.id))==null?'':_.escape(__t))+
'" class="btn btn-primary">\n                        '+
((__t=(t('community::button:label:send-friend-request')))==null?'':_.escape(__t))+
'\n                    </button>\n                ';
 } 
__p+='\n            </div>\n        </li>\n    ';
 } 
__p+='\n';
 }) 
__p+='\n\n';
 if (view.getOption('groupMemberLimit') !== false && item.users.length > view.getOption('groupMemberLimit')) { 
__p+='\n    <li>\n        <button class="show-group-members" data-action="show-group-members">\n            <div class="contact-image">\n                <span class="contact-initials">+'+
((__t=(item.users.length - view.getOption('groupMemberLimit')))==null?'':_.escape(__t))+
'</span>\n            </div>\n            <span class="contact-name">'+
((__t=(t('community::group:show-all-users', item.users.length)))==null?'':_.escape(__t))+
'</span>\n        </button>\n    </li>\n';
 } 
__p+='';
}
return __p;
};