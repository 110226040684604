'use strict';

var Marionette = require('backbone-marionette');
var Html = require('base/Colido.Html');

var IntercomModule = Marionette.Module.extend({
    startWithParent: true,

    /**
     * States, if intercom module was booted
     * @type {boolean}
     */
    intercomBooted: false,

    /**
     * Appends the intercom tracking code to our application
     */
    appendTrackingCode: function() {
        var ic = window.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', window.intercomSettings);
        } else {
            var i = function () {
                i.c(arguments)
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args)
            };
            window.Intercom = i;

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = '//widget.intercom.io/widget/g5ogvuy4';
            document.body.appendChild(script);
        }
    },

    /**
     * Gathers additional information from the user and sends them to intercom
     */
    updateUserInformation: function() {
        // Make sure we have an intercom settings object
        window.intercomSettings = window.intercomSettings || {};

        var params = {};
        var catalog = App.CollectionModule.catalogs.getActiveCatalog();

        // Object count
        if (App.isDataLoaded('collection:objects')) {
            params.item_count = catalog.objects.length;
        }

        // Set count
        if (App.isDataLoaded('collection:sets')) {
            params.set_count  = catalog.sets.length;
        }

        // Storage count
        if (App.isDataLoaded('collection:locations:storages')) {
            params.location_count = catalog.storages.length - 3;
        }

        // Quota
        if (!_.isUndefined(App.user)
            && !_.isUndefined(App.user.has('quotaUsage'))
            && !_.isUndefined(App.user.has('licenseQuota'))
        ) {
            var usage = App.user.get('quotaUsage');
            var available = App.user.get('licenseQuota');
            var percent = (usage / available * 100).toFixed(2) + '%';

            params.quota_usage = _.tpl('{usage} of {total} ({percent})', {
                usage: Html.formatByte(usage).replace(/\s+/g, ''),
                total: Html.formatByte(available).replace(/\s+/g, ''),
                percent: percent,
            });
        }

        // Active plan
        if (!_.isUndefined(App.user) && !_.isUndefined(App.user.has('runningPlan'))) {
            params.running_plan = App.user.get('runningPlan');
        }

        // Language
        if (!_.isUndefined(App.user)) {
            params.language = App.user.getLanguage();
        }

        // Default configuration
        if (!_.isUndefined(App.user)) {
            if (App.user.has('defaultCurrency')) {
                params.default_currency = App.user.getCurrency();
            }

            if (App.user.has('defaultSizeUnit')) {
                params.default_size_unit = App.user.get('defaultSizeUnit');
            }

            if (App.user.has('defaultWeightUnit')) {
                params.default_weight_unit = App.user.get('defaultWeightUnit');
            }
        }

        // Extend intercom settings object
        window.intercomSettings = _.extend(window.intercomSettings, params);

        // Perform update
        Intercom('update', params);
    },

    onStart: function(options) {
        if (App.config.useIntercom && App.appType !== 'share') {
            this.appendTrackingCode();

            // Listen for router events
            this.listenTo(App.router, 'route', function () {
                if (window.Intercom && this.intercomBooted) {
                    this.updateUserInformation();
                }
            }.bind(this));

            // Listen for login, to boot intercoom
            this.listenTo(App.vent, 'logged-in', function () {
                // Identify intercom user
                if (window.Intercom && App.user) {
                    // Make sure we have an intercom settings object
                    window.intercomSettings = window.intercomSettings || {};

                    // Get proper username
                    var logName = App.user.get('fullname').length > 0
                                ? App.user.get('fullname')
                                : App.user.get('email');

                    // Boot intercom
                    Intercom('boot', {
                        app_id: 'g5ogvuy4',
                        email: App.user.get('email'),
                        user_id: App.user.get('id'),
                        created_at: App.user.get('dtRegistered'),
                        name: logName.trim(),
                    });

                    this.intercomBooted = true;
                }
            }.bind(this));

            // Listen for search events
            this.listenTo(App.vent, 'collection:search', function(keyword, foundObjects) {
                if (window.Intercom && this.intercomBooted) {
                    Intercom('trackEvent', 'search-used', {
                        keyword: keyword,
                        found_objects: foundObjects,
                    });
                }
            }.bind(this));

            // Listen for filter events
            this.listenTo(App.vent, 'collection:filter', function(filter, foundObjects) {
                if (filter && filter.length) {
                    filter = filter.split('/');

                    // Make sure, we have key/value pairs
                    if (filter.length % 2 === 0) {
                        var filterKeys = _.filter(filter, function(value, idx) {
                            return idx % 2 === 0;
                        });

                        var filterValues = _.filter(filter, function(value, idx) {
                            return idx % 2 === 1;
                        });

                        Intercom('trackEvent', 'filter-used', _.extend(_.object(filterKeys, filterValues), {
                            found_objects: foundObjects,
                        }));
                    }
                }
            }.bind(this));

            // Listen for logout, to shutdown intercom
            this.listenTo(App.vent, 'logged-out', function () {
                if (window.Intercom) {
                    Intercom('shutdown');
                    this.intercomBooted = false;
                }
            }.bind(this));
        }
    },
});

module.exports = IntercomModule;