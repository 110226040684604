'use strict';

var Html = require('base/Colido.Html');
var ItemView = require('base/Colido.ItemView');

var template = require('modules/collection/templates/partials/list-grid-item.hbs');

var ListGridItemView = ItemView.extend({
    template: template,

    tagName: 'a',

    attributes: function(){
        return {
            'href': this.getOption('state').getPath() + '/show/' + this.model.id,
            'data-id': this.model.id,
            'draggable': false,
        }
    },

    className: function() {
        var classes = ['box', 'box-object-object'];

        if (this.model.get('selected') === true) {
            classes.push('is-selected');
        }

        if (this.isActive === true) {
            classes.push('is-active');
        }

        return classes.join(' ');
    },

    events: {
        'click': 'clickLink',
    },

    modelEvents: {
        'sync': 'render updateClassName',
        'change': 'render updateClassName',
    },

    viewData: {
        headline: function() {
            var fieldName = this.model.collection.catalog.get('fieldsGridAttributeMap').headline;

            return this.model.getMergedTextValues(fieldName);
        },

        subheadline: function() {
            var fieldName = this.model.collection.catalog.get('fieldsGridAttributeMap').subheadline;

            return this.model.getMergedTextValues(fieldName);
        },
    },

    onShow: function() {
        if (this.isActive === true) {
            this.activate();
        }
    },

    /**
     * Is triggered when the object is clicked
     * @param {object} event The click/tap event
     */
    clickLink: function(event) {
        event.preventDefault();

        var link = $(event.currentTarget);

        if (!link.is('a') || !link.attr('href')) {
            return;
        }

        // Switch selected state
        if (this.options.isManageMode()) {
            if (!link.parents('td').length) {
                // Super hidden feature
                this.checkRangeSelection(event);
                this.model.set('selected', !this.model.get('selected'));
            }
        } else {
            var url = $(event.currentTarget).attr('href');
            App.router.navigate(url, {trigger: true});
        }
    },

    /**
     * Checks, if a range should be selected
     * @param {object} event The click event
     */
    checkRangeSelection: function(event) {
        if (event.originalEvent.shiftKey
            && !this.model.get('selected')
            && this.options.isManageMode()
        ) {
            // Clear a selection from the user
            this.clearUserSelection();

            var relatedCollection = this._parent.collection;
            var position = relatedCollection.indexOf(this.model);
            var positionSelected = -1;

            if (position > 0) {
                var idx = position;
                while (positionSelected < 0 && idx > 0) {
                    idx--;
                    if (relatedCollection.at(idx).get('selected')) {
                        positionSelected = idx;
                    }
                }

                if (positionSelected >= 0) {
                    for (var i = positionSelected; i < position; i++) {
                        relatedCollection.at(i).set('selected', true);
                    }
                }
            }
        }
    },

    updateClassName: function() {
        this.$el.removeClass().addClass(_.result(this, 'className'));
    },

    activate: function() {
        this.isActive = true;
        this.$el.addClass('is-active');
        this.trigger('activated');
    },

    deactivate: function() {
        this.isActive = false;
        this.$el.removeClass('is-active');
        this.trigger('deactivated');
    },
});

module.exports = ListGridItemView;