'use strict';

var ItemView        = require('base/Colido.ItemView');
var template        = require('modules/community/templates/partials/conversation-post-share-set.hbs');
var IframeModalView = require('modules/base/views/layout/iframe-modal');

var ConversationPostShareSetView = ItemView.extend({
    template: template,

    events: {
        'click': 'onClickSet',
    },

    tagName: function() {
        return this.model.get('url') ? 'a' : 'div';
    },

    className: function() {
        return 'shared-set';
    },

    attributes: function() {
        return this.model.get('url') ? {href: this.model.get('url'), target: '_blank'} : {};
    },

    onClickSet: function(event) {
        event.preventDefault();

        var iframeModalView = new IframeModalView({
            title: App.t('community::share-set:modal:go-back'),
            url: this.model.get('url'),
        });

        App.addModal(iframeModalView, {
            additionalClassName: 'modal-iframe',
        });
    },
});

module.exports = ConversationPostShareSetView;