module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row">\n    <div class="page-col-offset input-help">\n        ';
 if (!item.isGroupChild) { 
__p+='\n            <span class="create-step-number">&nbsp;</span>\n        ';
 } 
__p+='\n        <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n    </div>\n    <div class="page-col-6">\n        <label class="label-headline">'+
((__t=( t('collection::edit:set:headline') ))==null?'':_.escape(__t))+
'</label>\n\n        ';
 for(var i = 0; i < view.getOption("collection").length; i++){ 
__p+='\n            <div class="set-selection-wrapper" data-item-id="'+
((__t=( view.getOption("collection").models[i].get("id") ))==null?'':_.escape(__t))+
'">\n                <button type="button" class="btn btn-primary btn-item">\n                    '+
((__t=( view.getOption("collection").models[i].get("name") ))==null?'':_.escape(__t))+
'\n                </button>\n                <button type="button" class="btn btn-clean" data-action="remove-set"\n                        data-forid="'+
((__t=( view.getOption("collection").models[i].get("id") ))==null?'':_.escape(__t))+
'">\n                    '+
((__t=( t('collection::edit:set:button-remove-set') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('trash') ))==null?'':__t)+
'\n                </button>\n            </div>\n        ';
 } 
__p+='\n\n        <div class="set-selection-wrapper">\n            <button type="button" class="btn btn-primary" data-action="choose-set">\n                '+
((__t=( t('collection::edit:set:button-choose-set') ))==null?'':_.escape(__t))+
'\n            </button>\n        </div>\n    </div>\n</div>';
}
return __p;
};