module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table class="table table-overflow table-striped">\n    <thead>\n        <tr>\n            ';
 view.filteredCollection.each(function(match) { 
__p+='\n                <th>\n                    <label>'+
((__t=( match.get('displayname') ))==null?'':_.escape(__t))+
'</label>\n                </th>\n            ';
 }); 
__p+='\n        </tr>\n    </thead>\n    <tbody class="import-review-table">\n        ';
 if (view.filteredCollection.length) { 
__p+='\n            ';
 for(var i = 0; i < view.filteredCollection.at(0).get('examples').length; i++) { 
__p+='\n                <tr>\n                    ';
 view.filteredCollection.each(function(match) { 
__p+='\n                        <td class="td-text"><div>'+
((__t=( match.get('examples')[i] ))==null?'':_.escape(__t))+
'</div></td>\n                    ';
 }) 
__p+='\n                </tr>\n            ';
 } 
__p+='\n        ';
 } 
__p+='\n    </tbody>\n</table>';
}
return __p;
};