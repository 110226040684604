module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="input-file-upload" data-state="pending">\n    <div class="input-file-upload-select">\n        <input\n            type="text"\n            class="input-file-upload-name"\n            placeholder="'+
((__t=(t('file-upload::upload-a-file:placeholder')))==null?'':_.escape(__t))+
'"\n            readonly\n            />\n        <label for="input-file-upload-'+
((__t=(view.cid))==null?'':_.escape(__t))+
'" class="btn btn-primary">\n            '+
((__t=(t('file-upload::choose-from-disk')))==null?'':_.escape(__t))+
'\n        </label>\n        <input\n            type="file"\n            id="input-file-upload-'+
((__t=(view.cid))==null?'':_.escape(__t))+
'"\n            ';
 if (view.getOption('imagesOnly')) { 
__p+='\n                accept="image/*,video/mp4"\n                capture="camera"\n            ';
 } 
__p+='\n            class="hidden-file-input"\n            hidden\n            aria-hidden="true"\n            />\n    </div>\n    <p class="error-message"></p>\n    <div class="input-file-upload-submit">\n        <button type="button" class="btn btn-primary" data-action="upload-file">\n            '+
((__t=(t('file-upload::upload-file')))==null?'':_.escape(__t))+
'\n            <div class="input-file-upload-progress"></div>\n        </button>\n    </div>\n</div>';
}
return __p;
};