'use strict';

var Model = require('base/Colido.Model');

var InputFieldsModel = Model.extend({
    api: '/core/columns',

    defaults: {
        key: null,
        order: null,
        visibility: null,
        deactivatable: null,
    },

    idAttribute: 'key',
});

module.exports = InputFieldsModel;