module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="import-module" data-step="1">\n    <header class="content-header is-sticky" data-state="edit">\n        <div class="header-part header-part-edit">\n            <div class="row">\n                <div class="col-2">\n                    &nbsp;\n                </div>\n                <div class="col-5">\n                    <h2>'+
((__t=( t('collection::import:headline') ))==null?'':_.escape(__t))+
'</h2>\n                    <h2 class="subline">'+
((__t=( t('collection::import:subline-step', {step: 1, stepTotal: 3}) ))==null?'':_.escape(__t))+
'</h2>\n                </div>\n                <div class="col-5">\n                    <div class="h-pull-right">\n                        <button type="button" class="btn btn-secondary" data-action="cancel">'+
((__t=( t('collection::import:button:cancel') ))==null?'':_.escape(__t))+
'</button>\n                        <button type="button" class="btn btn-primary"  disabled data-action="next">'+
((__t=( t('collection::import:button:next') ))==null?'':_.escape(__t))+
'</button>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </header>\n\n    <div class="scroll-container">\n        <div class="container container-dark import-container import-container-upload">\n            <!-- Region for the upload -->\n            <div id="region-upload"></div>\n        </div>\n\n        <div class="container import-container import-container-match">\n            <!-- Region for matching columns -->\n            <div id="region-match"></div>\n        </div>\n\n        <div class="container import-container import-container-review">\n            <!-- Region for the review -->\n            <div id="region-review" class="import-component import-component-review"></div>\n        </div>\n    </div>\n</div>';
}
return __p;
};