module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>'+
((__t=(t('subscription::setup:billing-address:title')))==null?'':_.escape(__t))+
'</h2>\n\n<div class="hint">\n    '+
((__t=( multiline(t('subscription::setup:billing-address:hint'))))==null?'':__t)+
'\n</div>\n\n<form autocomplete="on">\n    <fieldset>\n        <legend '+
((__t=( when(view.getOption('isSetup'), 'data-step-number="4"')))==null?'':__t)+
'>\n            '+
((__t=(t('subscription::setup:billing-address:vat:title')))==null?'':_.escape(__t))+
'\n        </legend>\n        <fieldset class="input-label-group">\n            <input type="text" placeholder="'+
((__t=(t('subscription::setup:billing-address:vat:vat-number:placeholder')))==null?'':_.escape(__t))+
'" name="subscription-vatId" value="'+
((__t=(item.vatId))==null?'':_.escape(__t))+
'" />\n            <label data-position="right">'+
((__t=(t('subscription::setup:billing-address:vat:vat-number:label')))==null?'':_.escape(__t))+
'</label>\n            <p class="hint">\n                '+
((__t=(t('subscription::setup:billing-address:vat:hint')))==null?'':_.escape(__t))+
'\n            </p>\n        </fieldset>\n    </fieldset>\n    <fieldset>\n        <legend '+
((__t=( when(view.getOption('isSetup'), 'data-step-number="5"')))==null?'':__t)+
'>\n            '+
((__t=(t('subscription::setup:billing-address:billing-address:label')))==null?'':_.escape(__t))+
'\n        </legend>\n        <fieldset class="input-label-group">\n            <input type="text" placeholder="'+
((__t=(t('subscription::setup:billing-address:billing-address:name:placeholder')))==null?'':_.escape(__t))+
'" name="subscription-billingAddress[fullname]"\n                   value="'+
((__t=(item.billingAddress.fullname))==null?'':_.escape(__t))+
'" autocomplete="billing name" />\n            <label data-position="right">'+
((__t=(t('subscription::setup:billing-address:billing-address:your-name:label')))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n        <fieldset class="input-label-group">\n            <input type="text" placeholder="'+
((__t=(t('subscription::setup:billing-address:billing-address:company:placeholder')))==null?'':_.escape(__t))+
'" name="subscription-billingAddress[company]"\n                   value="'+
((__t=(item.billingAddress.company))==null?'':_.escape(__t))+
'" autocomplete="billing organization" />\n            <label data-position="right">'+
((__t=(t('subscription::setup:billing-address:billing-address:company:label')))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n        <fieldset class="input-label-group">\n            <input type="text" placeholder="'+
((__t=(t('subscription::setup:billing-address:billing-address:street:placeholder')))==null?'':_.escape(__t))+
'" name="subscription-billingAddress[street]"\n                   value="'+
((__t=(item.billingAddress.street))==null?'':_.escape(__t))+
'" autocomplete="billing street-address" />\n            <label data-position="right">'+
((__t=(t('subscription::setup:billing-address:billing-address:street:label')))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n        <fieldset class="input-label-group">\n            <input type="text" placeholder="'+
((__t=(t('subscription::setup:billing-address:billing-address:zip:placeholder')))==null?'':_.escape(__t))+
'" name="subscription-billingAddress[zip]"\n                   value="'+
((__t=(item.billingAddress.zip))==null?'':_.escape(__t))+
'" autocomplete="billing postal-code" />\n            <label data-position="right">'+
((__t=(t('subscription::setup:billing-address:billing-address:zip:label')))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n        <fieldset class="input-label-group">\n            <!-- autocomplete city no in standard -->\n            <input type="text" placeholder="'+
((__t=(t('subscription::setup:billing-address:billing-address:city:placeholder')))==null?'':_.escape(__t))+
'" name="subscription-billingAddress[city]"\n                   value="'+
((__t=(item.billingAddress.city))==null?'':_.escape(__t))+
'" autocomplete="billing address-level2" />\n            <label data-position="right">'+
((__t=(t('subscription::setup:billing-address:billing-address:city:label')))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n        <fieldset>\n            <select name="subscription-billingAddress[country]" autocomplete="billing country">\n                <option value="">'+
((__t=(t('subscription::setup:billing-address:billing-address:choose-country:label')))==null?'':_.escape(__t))+
'</option>\n                ';
 item.countryCollection.each(function(country){ 
__p+='\n                    <option\n                        '+
((__t=(when(country.id === item.billingAddress.country, 'selected')))==null?'':_.escape(__t))+
'\n                        value="'+
((__t=(country.id))==null?'':_.escape(__t))+
'"\n                    >\n                        '+
((__t=(country.get('name')))==null?'':_.escape(__t))+
'\n                    </option>\n                ';
 }) 
__p+='\n            </select>\n        </fieldset>\n    </fieldset>\n\n    ';
 if (!view.getOption('isSetup')) { 
__p+='\n        <fieldset class="submit">\n                <button type="submit" class="btn btn-primary">'+
((__t=(t('subscription::setup:billing-address:save')))==null?'':_.escape(__t))+
'</button>\n        </fieldset>\n    ';
 } 
__p+='\n</form>';
}
return __p;
};