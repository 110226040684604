module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="object-status '+
((__t=( when(item.hasStatusLayer, 'object-status-action') ))==null?'':_.escape(__t))+
'"\n      '+
((__t=( when(item.hasStatusLayer, 'data-action="show-status"') ))==null?'':_.escape(__t))+
'>\n    '+
((__t=( item.properStatus ))==null?'':_.escape(__t))+
'\n    ';
 if (item.hasStatusLayer) { 
__p+='\n        '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n    ';
 } 
__p+='\n</span>\n\n';
 if (item.hasStatusLayer) { 
__p+='\n    <div class="action-layer action-layer-status">\n        <h3>\n            ';
 if (item.objectStatus === 'in-collection') { 
__p+='\n                '+
((__t=( t('collection::locations:headline-storage-location') ))==null?'':_.escape(__t))+
'\n            ';
 } else { 
__p+='\n                '+
((__t=( t('collection::object:details:object-status:' + item.objectStatus + '-to:label') ))==null?'':_.escape(__t))+
'\n            ';
 } 
__p+='\n        </h3>\n        <button type="button" data-action="hide-storage" class="btn btn-close">'+
((__t=( icon('close-light') ))==null?'':__t)+
'</button>\n        <div class="action-layer-text">\n            ';
 if (item.objectStatus === 'in-collection') { 
__p+='\n                '+
((__t=( model.getStoragePath() ))==null?'':__t)+
'\n            ';
 } else { 
__p+='\n                '+
((__t=( item.objectStatusRelation ))==null?'':_.escape(__t))+
'\n            ';
 } 
__p+='\n        </div>\n    </div>\n';
 } 
__p+='';
}
return __p;
};