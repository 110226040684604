module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<tr>\n    <td class="'+
((__t=(when(item.unseen, 'unseen')))==null?'':_.escape(__t))+
'">'+
((__t=(item.file.filename))==null?'':_.escape(__t))+
'</td>\n    <td>'+
((__t=(item.imported))==null?'':_.escape(__t))+
'</td>\n    <td>\n        ';
 if (item.warnings) { 
__p+='\n            '+
((__t=(item.warnings))==null?'':_.escape(__t))+
'\n        ';
 } else { 
__p+='\n            0\n        ';
 } 
__p+='\n    </td>\n    <td>'+
((__t=(item.created))==null?'':_.escape(__t))+
'</td>\n    <td>\n        <a href="/catalogs/'+
((__t=(model.collection.catalog.id))==null?'':_.escape(__t))+
'/imports/'+
((__t=( item.id ))==null?'':_.escape(__t))+
'" class="btn btn-primary">'+
((__t=( icon('next-small')))==null?'':__t)+
'</a>\n    </td>\n</tr>';
}
return __p;
};