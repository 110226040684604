module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header">\n	<ul>\n		<li>\n    		<h2>'+
((__t=( t('collection::shop:buy-modal:headline')))==null?'':__t)+
'</h2>\n		</li>\n		<li class="actions">\n    		<button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('global::modal:button:close')))==null?'':_.escape(__t))+
'</button>\n		</li>\n	</ul>\n</header>\n\n<div class="modal-body">\n    <p>'+
((__t=(t('collection::shop:buy-modal:text', {amount: '123', objectName: 'Hans'})))==null?'':_.escape(__t))+
'</p>\n\n	<button type="button" class="btn btn-primary" data-action="buy">'+
((__t=(t('collection::shop:buy-modal:buy-button')))==null?'':_.escape(__t))+
'</button>\n	<button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('collection::shop:buy-modal:do-not-buy-button')))==null?'':_.escape(__t))+
'</button>\n</div>';
}
return __p;
};