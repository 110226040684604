module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (!item.isGroupChild) { 
__p+='\n  <div class="row row-nospace">\n      <div class="page-col-offset input-help">\n          <span class="create-step-number">&nbsp;</span>\n          <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n      </div>\n\n      <div class="page-col-3">\n          <label class="label-headline">'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</label>\n      </div>\n  </div>\n';
 } 
__p+='\n\n<!-- Number -->\n<div class="row number-select">\n    <div class="page-offset-default page-col-2">\n        <div class="input-label-group">\n            <input type="number"\n                   id="field-'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'"\n                   step="any"\n                   name="'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'"\n                   placeholder="'+
((__t=(item.field.placeholder))==null?'':_.escape(__t))+
'"\n                   value="'+
((__t=(model.get(item.field.name)))==null?'':_.escape(__t))+
'"\n            />\n            <label data-position="left" for="field-'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'">'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</label>\n        </div>\n    </div>\n\n    <div class="page-col-1">\n        ';
 if (item.unitEnumValues.length === 1) { 
__p+='\n              <span class="number-unit">'+
((__t=(item.unitEnumValues[0].label))==null?'':_.escape(__t))+
'</span>\n        ';
 } else if (item.unitEnumValues.length > 1) { 
__p+='\n            <select name="'+
((__t=(item.field.unitField))==null?'':_.escape(__t))+
'" class="select-secondary">\n                ';
 _.each(item.unitEnumValues, function(enumValue){ 
__p+='\n                    <option value="'+
((__t=(enumValue.name))==null?'':_.escape(__t))+
'" '+
((__t=(when(model.get(item.field.unitField) === enumValue.name, 'selected="selected"')))==null?'':_.escape(__t))+
'>\n                        '+
((__t=(enumValue.label))==null?'':_.escape(__t))+
'\n                    </option>\n                ';
 }) 
__p+='\n            </select>\n        ';
 } 
__p+='\n    </div>\n</div>';
}
return __p;
};