'use strict';

var Controller = require('base/Colido.Controller');
var CommunityLayout = require('./views/layout/community');
var ConversationLayout = require('./views/layout/conversation');
var ConversationNotFoundView = require('./views/layout/conversation-not-found');
var RestrictionModalView = require('./views/layout/restriction-modal');
// var ProfileLayout = require('modules/settings/views/layout/profile');

var CommunityModuleController = Controller.extend({
    routes: {
        'community(/contacts)(/conversation/:id)': 'actionContactList',
        'community/groups(/conversation/:id)': 'actionGroupsList',
        'community/broadcasts(/conversation/:id)': 'actionBroadcastsList',
    },

    actionContactList: function(id) {
        this.actionCommunity('contacts', id);
    },

    actionGroupsList: function(id) {
        this.actionCommunity('groups', id);
    },

    actionBroadcastsList: function(id) {
        this.actionCommunity('broadcasts', id);
    },

    actionCommunity: function(activeTab, id) {
        var requiredData = [
            App.CollectionModule.catalogs,
            'community:contacts',
            'community:groups',
            'community:conversations',
        ];

        if (App.CollectionModule.ShopModule) {
            requiredData.push(App.CollectionModule.ShopModule.shops);

            // this not not loaded anywhere yet (rest ist loaded in module inits)
            // requiredData.push(App.CollectionModule.catalogs.objects);
        }

        this.onDataReady(requiredData, function(ready) {
            activeTab = _.contains(['groups', 'contacts', 'broadcasts'], activeTab) ? activeTab : 'contacts';

            if (!App.user.get('profileName')) {
                this.showRestrictionModal();
            }

            // show list
            var contentRegion = App.rootView.mainRegion.currentView.contentRegion;

            contentRegion.show(new CommunityLayout({
                contacts: this.options.module.contactsCollection,
                groups: this.options.module.groupsCollection,
                conversations: this.options.module.conversationsCollection,
                activeTab: activeTab,
                activeConversationId: id ? parseInt(id, 10) : null,
            }));

            // show conversation
            if (id) {
                var conversationLayout;

                var conversationModel = this.options.module.conversationsCollection.get(id);
                if (conversationModel) {
                    conversationLayout = new ConversationLayout({
                        model: conversationModel,
                        closeUrl: '/community/' + activeTab,
                    });
                } else {
                    conversationLayout = new ConversationNotFoundView({
                        closeUrl: '/community',
                    });
                }
                var extraContentRegion = App.rootView.mainRegion.currentView.extraContentRegion;
                extraContentRegion.show(conversationLayout, {additionalClassName: 'conversation'});
            }
        }.bind(this));
    },

    showRestrictionModal: function(){
        var profileModalView = new RestrictionModalView();
        var options = {
            additionalClassName: 'modal-center modal-restriction modal-restriction-talk',
            additionalBgClassName: 'light-bg',
            onAfterClose: function(path) {
                if (typeof path === 'string') {
                    App.router.navigate(path, {trigger: true});
                } else {
                    App.router.navigate('dashboard', {trigger: true});
                }
            },
        };

        App.addModal(profileModalView, options);
    },
});

module.exports = CommunityModuleController;