'use strict';

var sortExtension = require('base/mixins/sort');

var sortViewExtension = {
    /**
     * Default sort column
     * @type {String}
     */
    sortDefault: '',

    /**
     * Stores the last sort string of the view
     * @type {string}
     */
    sortString: '',

    /**
     * Active sort columns
     * @type {Array}
     */
    sortFields: [],

    /**
     * Sort direction for the columns [ 'asc' | 'desc' ]
     * @type {Array}
     */
    sortDirections: [],

    /**
     * Changes the sort for a collection
     * @param {string} fieldName The field name
     */
    sortByFields: function(fields) {
        if (!_.isString(fields)) {
            if (_.isString(this.sortString) && this.sortString.length > 0) {
                fields = this.sortString;
            } else if (_.isString(this.getOption('sortDefault')) && this.getOption('sortDefault').length > 0) {
                fields = this.getOption('sortDefault');
            }
        }

        this.sortString = fields;

        // Reset current sort
        this.sortFields = [];
        this.sortDirections = [];

        if (_.isString(fields) && fields.length > 0) {
            // Apply sorts
            _.each(fields.split('-'), function(item) {
                var parts = item.split('.');
                var field = parts[0];

                if (field.length) {
                    this.sortFields.push(field);

                    var dir = 'asc';
                    if (parts.length === 2 && (parts[1] === 'asc' || parts[1] === 'desc')) {
                        dir = parts[1];
                    }

                    this.sortDirections.push(dir);
                }
            }.bind(this));

            // Enables the view sort
            this.viewComparator = sortExtension.comparator;
            if (this.collection) {
                this.listenTo(this.collection, 'change', this._sortViews);
            }
        } else {
            this.disableViewSort();
        }
    },

    /**
     * Disables the view sorting
     */
    disableViewSort: function() {
        this.sortString = '';
        this.sortFields = [];
        this.sortDirections = [];
        this.viewComparator = null;
        this.stopListening(this.collection, 'change', this._sortViews);
    },
};

module.exports = sortViewExtension;