'use strict';

var Model = require('base/Colido.Model');

var DefaultTableColumnModel = Model.extend({
    idAttribute: 'key',

    defaults: {
        key: null,
        referencesEntity: null,
        visibility: null,
        order: null,
    },

    customAttributes: {
        name: function(){
            return App.t('collection::inputfields:' + _.camelToHyphen(this.get('referencesEntity').get('key')) + ':name');
        },
    },
});

module.exports = DefaultTableColumnModel;