module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(t('collection::objects:filter:obtaining-date:title')))==null?'':_.escape(__t))+
'</h3>\n\n<form>\n    <fieldset class="filter-range">\n        <label>\n            '+
((__t=(t('collection::objects:filter:obtaining-date:from:label')))==null?'':_.escape(__t))+
'\n            <input type="text" class="component-range-start" />\n        </label>\n        <label>\n            '+
((__t=(t('collection::objects:filter:obtaining-date:to:label')))==null?'':_.escape(__t))+
'\n            <input type="text" class="component-range-end" />\n        </label>\n        <button class="clear-range"></button>\n    </fieldset>\n</form>';
}
return __p;
};