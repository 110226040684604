module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<dl>\n    <dt>'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</dt>\n    <dd>'+
((__t=(item.dimensions))==null?'':_.escape(__t))+
'</dd>\n</dl>';
}
return __p;
};