module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--\n	<header class="content-header">\n		<h2>'+
((__t=( t('settings::label') ))==null?'':_.escape(__t))+
': '+
((__t=( t('settings::notifications:label') ))==null?'':_.escape(__t))+
'</h2>\n	</header>\n-->\n<div class="placeholder-container placeholder-container-notifications">\n\n</div>';
}
return __p;
};