module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form class="settings-profile-form" method="post">\n    <header class="content-header is-sticky">\n        <div class="header-part">\n            <ul>\n                <li>\n                    <h2>'+
((__t=( t('settings::label') ))==null?'':_.escape(__t))+
': '+
((__t=( t('settings::profile:label') ))==null?'':_.escape(__t))+
'</h2>\n                    <h2 class="subline">'+
((__t=( t('settings::profile:subline:profile-public') ))==null?'':_.escape(__t))+
'</h2>\n                </li>\n                <li class="actions">\n                    <button type="submit" class="btn btn-primary" data-action="update-profile" disabled>\n                        '+
((__t=( t('settings::profile:button:update') ))==null?'':_.escape(__t))+
'\n                    </button>\n                </li>\n            </ul>\n        </div>\n    </header>\n\n    <div class="scroll-container">\n        <div class="container container-dark">\n            <fieldset class="container">\n                <input id="seller-profile-private" type="radio" name="sellerProfile" value="private" '+
((__t=( 'commercial'==item.sellerProfile?'':'checked="checked"'))==null?'':__t)+
'>\n                <label for="seller-profile-private" class="btn seller-profile">\n                    '+
((__t=( t('settings::profile:seller:label:private') ))==null?'':_.escape(__t))+
'\n                </label>\n                <input id="seller-profile-commercial" type="radio" name="sellerProfile" value="commercial" '+
((__t=( 'commercial'==item.sellerProfile?'checked="checked"':''))==null?'':__t)+
'>\n                <label for="seller-profile-commercial" class="btn seller-profile">\n                    '+
((__t=( t('settings::profile:seller:label:commercial') ))==null?'':_.escape(__t))+
'\n                </label>\n            </fieldset>\n\n            <fieldset class="container seller-profile-block" data-mode="'+
((__t=(item.sellerProfile))==null?'':_.escape(__t))+
'">\n                <div class="page-row">\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:company:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="company"\n                            value="'+
((__t=(item.sellerCompany))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:company:placeholder') ))==null?'':_.escape(__t))+
'"\n                            required\n                        />\n                    </div>\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:contact:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="contact"\n                            value="'+
((__t=(item.sellerContact))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:contact:placeholder') ))==null?'':_.escape(__t))+
'"\n                            required\n                        />\n                    </div>\n                </div>\n\n                <div class="page-row">\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:address:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="address"\n                            value="'+
((__t=(item.sellerAddress))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:address:placeholder') ))==null?'':_.escape(__t))+
'"\n                            required\n                        />\n                    </div>\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:plz:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="plz"\n                            value="'+
((__t=(item.sellerPlz))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:plz:placeholder') ))==null?'':_.escape(__t))+
'"\n                            maxlength="10"\n                            required\n                        />\n                    </div>\n                </div>\n\n                <div class="page-row">\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:city:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="city"\n                            value="'+
((__t=(item.sellerCity))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:city:placeholder') ))==null?'':_.escape(__t))+
'"\n                            required\n                        />\n                    </div>\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:country:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="country"\n                            value="'+
((__t=(item.sellerCountry))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:country:placeholder') ))==null?'':_.escape(__t))+
'"\n                            required\n                        />\n                    </div>\n                </div>\n\n                <div class="page-row">\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:phone:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="phone"\n                            value="'+
((__t=(item.sellerPhone))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:phone:placeholder') ))==null?'':_.escape(__t))+
'"\n                            required\n                        />\n                    </div>\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:email:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="email"\n                            value="'+
((__t=(item.sellerEmail))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:email:placeholder') ))==null?'':_.escape(__t))+
'"\n                            required\n                        />\n                    </div>\n                </div>\n\n                <div class="page-row">\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:www:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="www"\n                            value="'+
((__t=(item.sellerWww))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:www:placeholder') ))==null?'':_.escape(__t))+
'"\n                        />\n                    </div>\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:registration:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="registration"\n                            value="'+
((__t=(item.sellerRegistration))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:registration:placeholder') ))==null?'':_.escape(__t))+
'"\n                            required\n                        />\n                    </div>\n                </div>\n\n                <div class="page-row">\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:registrationOffice:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="registrationOffice"\n                            value="'+
((__t=(item.sellerRegistrationOffice))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:registrationOffice:placeholder') ))==null?'':_.escape(__t))+
'"\n                            required\n                        />\n                    </div>\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:uid:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="uid"\n                            value="'+
((__t=(item.sellerUid))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:uid:placeholder') ))==null?'':_.escape(__t))+
'"\n                            required\n                        />\n                    </div>\n                </div>\n\n                <div class="page-row">\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:agb:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="agb"\n                            value="'+
((__t=(item.sellerAgb))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:agb:placeholder') ))==null?'':_.escape(__t))+
'"\n                        />\n                    </div>\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::profile:seller:dse:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input\n                            type="text"\n                            name="dse"\n                            value="'+
((__t=(item.sellerDse))==null?'':_.escape(__t))+
'"\n                            placeholder="'+
((__t=( t('settings::profile:seller:dse:placeholder') ))==null?'':_.escape(__t))+
'"\n                        />\n                    </div>\n                </div>\n\n                <div class="page-row">\n                    <div class="page-col-6">\n                        <label>'+
((__t=( t('settings::profile:seller:notes:label') ))==null?'':_.escape(__t))+
'</label>\n                        <textarea\n                            name="notes"\n                            placeholder="'+
((__t=( t('settings::profile:seller:notes:placeholder') ))==null?'':_.escape(__t))+
'"\n                        >'+
((__t=(item.sellerNotes))==null?'':_.escape(__t))+
'</textarea>\n                    </div>\n                </div>\n            </fieldset>\n        </div>\n\n        <div class="container container-dark">\n            <section class="container profile-picture-region"></section>\n        </div>\n\n        <div class="container container-dark">\n            <section class="container profile-logo-region"></section>\n        </div>\n\n        <div class="page-container page-container--slim">\n            ';
 if (App.CommunityModule) { 
__p+='\n                <fieldset class="container">\n                    <div class="page-row">\n                        <div class="page-col-3">\n                            <label class="label-required">'+
((__t=( t('settings::profile:name:label') ))==null?'':_.escape(__t))+
'</label>\n                            <input\n                                type="text"\n                                name="name"\n                                value="'+
((__t=(item.profileName))==null?'':_.escape(__t))+
'"\n                                placeholder="'+
((__t=( t('settings::profile:name:placeholder') ))==null?'':_.escape(__t))+
'"\n                                maxlength="30"\n                                required\n                                />\n                        </div>\n                        <div class="page-col-3">\n                            <label>'+
((__t=( t('settings::profile:website:label') ))==null?'':_.escape(__t))+
'</label>\n                            <input\n                                type="text"\n                                name="website"\n                                value="'+
((__t=(item.profileWebsite))==null?'':_.escape(__t))+
'"\n                                placeholder="'+
((__t=( t('settings::profile:website:placeholder') ))==null?'':_.escape(__t))+
'"\n                                />\n                        </div>\n                    </div>\n\n                    <div class="page-row">\n                        <div class="page-col-6">\n                            <label>'+
((__t=( t('settings::profile:biography:label') ))==null?'':_.escape(__t))+
'</label>\n                            <input\n                                type="text"\n                                name="biography"\n                                value="'+
((__t=(item.profileBiography))==null?'':_.escape(__t))+
'"\n                                placeholder="'+
((__t=( t('settings::profile:biography:placeholder') ))==null?'':_.escape(__t))+
'"\n                                maxlength="200"\n                                />\n                        </div>\n                    </div>\n                </fieldset>\n            ';
 } 
__p+='\n\n            <fieldset class="container">\n                <div class="page-row">\n                    <div class="page-col-6">\n                        <button type="submit" class="btn btn-primary" data-action="update-profile" disabled>\n                            '+
((__t=( t('settings::profile:button:update') ))==null?'':_.escape(__t))+
'\n                        </button>\n                    </div>\n                </div>\n            </fieldset>\n        </div>\n    </div>\n</form>';
}
return __p;
};