'use strict';

var ItemView = require('base/Colido.ItemView');

var template = require('modules/authentication/templates/partials/forgot-password-token.hbs');

var ForgotPasswordTokenView = ItemView.extend({
    template: template,

    showSuccess: function(message) {
        var $message = $('<p>').addClass('success').html(App.t('forgot-password::your-new-password')).hide();
        this.$('h2').after($message);
        $message.slideDown();

        this.$('input[name="password"]').val(message);
    },

    showError: function(message){
        this.$('[type="submit"]').removeClass('is-loading').prop('disabled', false);

        var $message = $('<p>').addClass('error').html(message).hide();
        this.$('h2').after($message);
        $message.slideDown();

        setTimeout(function(){
            $message.slideUp();
        }, 2500);
    },

    removeInput: function(){
        this.$('input[name="password"], p.label').slideUp();
    },
});

module.exports = ForgotPasswordTokenView;