module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="objects-listing is-viewmode-'+
((__t=( model && model.get('viewMode') ))==null?'':_.escape(__t))+
'">\n    <div class="container container-list-objects">\n        <div class="viewmode viewmode-showroom-grid">\n            <div class="container-showroom-grid">\n                <div class="list list-flex objects-showroom-container"></div>\n            </div>\n        </div>\n    </div>\n\n    <div class="object-details-showroom">\n        <div class="object-details-showroom-close">\n            <button type="button" data-action="close-showroom-detail" class="btn btn-close">'+
((__t=( icon('close-strong') ))==null?'':__t)+
'</button>\n        </div>\n        <div class="object-details-showroom-next">\n            <button type="button" data-action="next-showroom-detail">'+
((__t=( icon('showroom_next') ))==null?'':__t)+
'</button>\n        </div>\n        <div class="object-details-showroom-prev">\n            <button type="button" data-action="prev-showroom-detail">'+
((__t=( icon('showroom_previous') ))==null?'':__t)+
'</button>\n        </div>\n        <div class="object-details-showroom-content"></div>\n    </div>\n    <div class="object-details-showroom-overlay"></div>\n</div>\n\n<button type="button" class="btn btn-back-to-top" data-action="back-to-top" title="'+
((__t=( t('global::button:scroll-back-to-top') ))==null?'':_.escape(__t))+
'">\n    '+
((__t=(icon("up")))==null?'':__t)+
'\n</button>';
}
return __p;
};