'use strict';

var Backbone = require('backbone');

var deleteExtension = {
    /**
     * Applies a batch delete for the given models
     * @param {Backbone.Model[]|Number[]} models  Array of models or array of ids
     * @param {object}                    options Options to apply for request
     * @returns {Backbone.ajax} The xhr request
     */
    delete: function(models, options) {
        options = options || {};
        models = _.isArray(models) ? models : [models];

        // Get Models to delete
        var modelsToDelete = [];
        for (var i = 0; i < models.length; i++) {
            var model = this.get(models[i]);
            if (model) {
                modelsToDelete.push(model);
            }
        }

        // Set proper API URL
        options.url = this.collection ? this.collection.url() : this.url();

        // Set proper content type
        options.contentType = 'application/json';

        // Set model ID's
        options.data = JSON.stringify({ids: _.pluck(modelsToDelete, 'id')});

        // Remove items from collection immediate
        if (!options.wait) {
            this.remove(modelsToDelete, {silent: true});
            this.trigger('destroy', modelsToDelete);
        }

        var success = options.success;
        var collection = this;
        options.success = function(resp) {
            // Wait for delete?
            if (options.wait) {
                collection.remove(modelsToDelete, {silent: true})
                collection.trigger('destroy', modelsToDelete);
            }

            // Success callback?
            if (success) {
                success(collection, resp, options);
            }

            if (_.isFunction(collection.resetCollection)) {
                collection.resetCollection();
            } else {
                collection.trigger('reset', collection);
            }

            collection.trigger('sync', collection, resp, options);
        };

        return Backbone.sync('delete', this, options);
    },
};

module.exports = deleteExtension;