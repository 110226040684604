module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="box box-import-match '+
((__t=( when(model.hasColumnSelected(), 'box-import-match-active') ))==null?'':_.escape(__t))+
' '+
((__t=( when(item.dismissed, 'box-import-match-dismissed') ))==null?'':_.escape(__t))+
'">\n    <div class="import-match-content">\n        <div class="import-match-selection">\n            <span class="import-match-import-field">'+
((__t=( item.id ))==null?'':_.escape(__t))+
'</span>\n            <div class="import-match-local-field">\n                <select name="target-field"></select>\n            </div>\n        </div>\n        <ul class="import-match-examples">\n            ';
 for(var i = 0; i < Math.min(item.examples.length, 5); i++) { 
__p+='\n                <li><span>\n                    ';
 if (item.examples[i]) { 
__p+='\n                        '+
((__t=( item.examples[i] ))==null?'':_.escape(__t))+
'\n                    ';
 } else { 
__p+='\n                        &nbsp;\n                    ';
 } 
__p+='\n                </span></li>\n            ';
 } 
__p+='\n        </ul>\n    </div>\n    <div class="import-match-actions">\n        <button type="button" class="btn btn-clean" data-action="dismiss">'+
((__t=( App.t('collection::import:button:dismiss') ))==null?'':_.escape(__t))+
'</button>\n        <button type="button" class="btn btn-clean" data-action="include">'+
((__t=( App.t('collection::import:button:include') ))==null?'':_.escape(__t))+
'</button>\n    </div>\n</div>';
}
return __p;
};