module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="user-image">\n    <span class="contact-initials">'+
((__t=( _.initials(item.profileName || item.fullname) ))==null?'':_.escape(__t))+
'</span>\n	';
 if (_.isObject(item.profilePictureUrl)) { 
__p+='\n    	<img src="'+
((__t=( imagePath(user.profilePictureUrl, imageSize('thumbnailFixed')) ))==null?'':__t)+
'">\n	';
 } 
__p+='\n</span>\n\n<span class="user-name">'+
((__t=( item.profileName || item.fullname ))==null?'':_.escape(__t))+
'</span>\n';
}
return __p;
};