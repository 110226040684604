module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header">\n    <h2>'+
((__t=( t('restrictions::modal:title:quota')))==null?'':__t)+
'</h2>\n    <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=( t('welcome::button:close')))==null?'':_.escape(__t))+
'</button>\n</header>\n\n<div class="modal-body">\n    ';
 if(App.user.get('spaceInBytesLeft') < 0) { 
__p+='\n        <p>'+
((__t=(t('restrictions::quota:exceeded', {quota: byte(App.user.get('licenseQuota')), exceededBy: byte(Math.abs(App.user.get('spaceInBytesLeft')))})))==null?'':_.escape(__t))+
'</p>\n    ';
 } else { 
__p+='\n        <p>'+
((__t=(t('restrictions::quota:space-left', {spaceLeft: byte(App.user.get('spaceInBytesLeft'))})))==null?'':_.escape(__t))+
'</p>\n    ';
 } 
__p+='\n    <a href="/account/subscription" class="btn btn-primary">'+
((__t=(t('restrictions::modal:btn:upgrade')))==null?'':_.escape(__t))+
'</a>\n</div>\n\n<footer class="modal-footer">\n    <a href="/account/subscription">'+
((__t=(t('restrictions::modal:btn:plans-and-pricing')))==null?'':_.escape(__t))+
'</a>\n</footer>';
}
return __p;
};