'use strict';

var HeaderView = require('modules/collection/views/layout/header');

var SetsHeaderView = HeaderView.extend({
    initialize: function (options) {
        HeaderView.prototype.initialize.apply(this, arguments);
        this.model.set('search', null);
        this.options.search = true;
        this.options.showroomOptions = false;
    },

    /**
     *
     * @returns {string} Unescaped Text
     */
    getHeadline: function () {
        var catalog = App.CollectionModule.catalogs.getActiveCatalog();

        return App.t('collection::sets:headline', {catalogName: catalog.get('name')});
    },

    /**
     *
     * @returns {string} Unescaped Text
     */
    getSubline: function () {
        return App.t('collection::sets:count', this.collection.length);
    },
});

module.exports = SetsHeaderView;