module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row">\n    <div class="page-col-offset input-help">\n        ';
 if (!item.isGroupChild) { 
__p+='\n            <span class="create-step-number">&nbsp;</span>\n        ';
 } 
__p+='\n        <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n    </div>\n    <div class="'+
((__t=(ifelse(item.enumDisplayStyle === 'radio', 'page-col-6', 'page-col-3')))==null?'':_.escape(__t))+
'">\n        ';
 if (!item.field.editOptions.hideLabelInGroup) { 
__p+='\n            <label class="label-headline">'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</label>\n        ';
 } 
__p+='\n\n        ';
 if(item.enumDisplayStyle === 'radio') { 
__p+='\n            <div class="option-select">\n                ';
 _.each(item.field.options.enumValues, function(enumValue){ 
__p+='\n                    <input\n                        id="field-enum-value-'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'-'+
((__t=(enumValue.name))==null?'':_.escape(__t))+
'"\n                        type="radio"\n                        name="'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'"\n                        value="'+
((__t=(enumValue.name))==null?'':_.escape(__t))+
'"\n                        '+
((__t=(when(model.get(item.field.name) === enumValue.name, 'checked="checked"')))==null?'':_.escape(__t))+
'\n                    />\n                    <label for="field-enum-value-'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'-'+
((__t=(enumValue.name))==null?'':_.escape(__t))+
'" class="option-select-secondary">\n                        '+
((__t=(enumValue.label))==null?'':_.escape(__t))+
'\n                    </label>\n                ';
 }) 
__p+='\n            </div>\n        ';
 } 
__p+='\n\n        ';
 if(item.enumDisplayStyle === 'select') { 
__p+='\n            <select name="'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'">\n                ';
 _.each(item.field.options.enumValues, function(enumValue){ 
__p+='\n                    <option value="'+
((__t=(enumValue.name))==null?'':_.escape(__t))+
'" '+
((__t=(when(model.get(item.field.name) === enumValue.name, 'selected="selected"')))==null?'':_.escape(__t))+
'>\n                        '+
((__t=(enumValue.label))==null?'':_.escape(__t))+
'\n                    </option>\n                ';
 }) 
__p+='\n            </select>\n        ';
 } 
__p+='\n    </div>\n</div>';
}
return __p;
};