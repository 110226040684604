'use strict';

var LayoutView = require('base/Colido.LayoutView');
var ViewSwitchView = require('./view-switch');
var ActiveFiltersView = require('./active-filters');
var SelectSetOverlayLayout = require('../../modules/set/views/layout/select-set-overlay');
var LabelPrintModalView = require('modules/base/views/layout/label-print-modal');
var AdvancedPrintModalView = require('modules/base/views/layout/advanced-print-modal');
var ChooseStorageModalLayout = require('../../modules/location/views/layout/choose-storage-modal');
var ChangeStatusOverlayLayout = require('../../views/layout/change-status-overlay');
var TableFieldsSettingsModalView = require('../../views/layout/table-fields-settings-modal');
var RestrictionModalView = require('modules/base/views/layout/restriction-filter-modal');
var RestrictionLabelModalView = require('modules/base/views/layout/restriction-labelprint-modal');
var ColidoCollection = require('base/Colido.Collection');
var RestrictionStoragesModalView = require('modules/base/views/layout/restriction-storages-modal');

var template = require('modules/collection/templates/partials/list-control.hbs');

var ListControlView = LayoutView.extend({
    template: template,

    className: function() {
        var viewMode = this.model && this.model.get('viewMode') ? this.model.get('viewMode') : '';
        var classes = ['listcontrol', 'is-viewmode-' + viewMode];
        if (this.collection.length === 0) {
            classes.push('no-items');
        }

        return classes.join(' ');
    },

    attributes: {
        'data-state': 'default',
    },

    ui: {
        'switchToManageObjects': '[data-action="manage-objects"]',
        'printObjectsPdf': '[data-action="print-objects-pdf"]',
        'printObjectsCsv': '[data-action="print-objects-csv"]',
        'printObjectsLabel': '[data-action="print-objects-label"]',
        'switchToDefault': '[data-action="close"]',
        'selectAll': '[data-action="select-all"]',
        'openFilter': '[data-action="open-filter"]',
        'openTableSettingsModal': '[data-action="open-table-settings-modal"]',
        'showActions': '[data-action="show-actions"]',
        'deleteButton': '[data-action="delete"]',
        'triggerEditButton': '[data-action="trigger-edit"]',
        'triggerDeleteButton': '[data-action="trigger-delete"]',
        'triggerShareButton': '[data-action="trigger-share"]',
        'gridSort': '.grid-sort-select',
        'actionLayer': '.action-layer',
        'actionUpdateLocation': '[data-action="update-location"]',
        'actionAddToSet': '[data-action="add-to-set"]',
        'actionRemoveFromSet': '[data-action="remove-from-set"]',
        'actionChangeStatus': '[data-action="change-status"]',
        'showAdditionaAction': '[data-action="show-additional-actions"]',
        'additionalActionLayer': '.additional-action-layer',
        'actionLaunchShowroom': '[data-action="launch-showroom"]',
    },

    events: {
        'click @ui.switchToManageObjects': 'switchListControlView',
        'click @ui.printObjectsPdf': 'printObjectsPdf',
        'click @ui.printObjectsCsv': 'printObjectsCsv',
        'click @ui.printObjectsLabel': 'printObjectsLabel',
        'click @ui.switchToDefault': 'switchListControlView',
        'click @ui.openFilter': 'openFilterModal',
        'click @ui.openTableSettingsModal': 'openTableSettingsModal',
        'click @ui.deleteButton': 'deleteSelectedItems',
        'click @ui.selectAll': 'selectAll',
        'change @ui.gridSort': 'gridSortChange',
        'click @ui.showActions': 'showActions',
        'click @ui.actionUpdateLocation': 'onActionUpdateLocation',
        'click @ui.actionAddToSet': 'onActionAddToSet',
        'click @ui.actionRemoveFromSet': 'onActionRemoveFromSet',
        'click @ui.actionChangeStatus': 'onActionChangeStatus',
        'click @ui.showAdditionaAction': 'showAdditionalActions',
        'click @ui.actionLaunchShowroom': 'onActionLaunchShowroom',
        'click @ui.triggerEditButton': 'onTriggerEdit',
        'click @ui.triggerDeleteButton': 'onTriggerDelete',
        'click @ui.triggerShareButton': 'onTriggerShare',
    },

    modelEvents: {
        'change:controlMode': 'controlModeChanged',
        'change:filterModal': 'filterModalChanged',
        'change:viewMode': 'viewModeChanged',
        'change:sort': 'sortChanged',
        'change:filter': 'filterChanged',
    },

    collectionEvents: {
        'change:selected': 'modelSelectedThrottled',
        'remove': 'modelSelectedThrottled',
        'add remove reset sync': 'checkCollectionLength',
    },

    regions: {
        'viewSwitchRegion': '.view-switch-region',
        'activeFiltersRegion': '.active-filters-region',
    },

    /**
     * Determines if filter button is visible or not
     */
    hasFilter: true,

    initialize: function(options) {
        LayoutView.prototype.initialize.apply(this, arguments);

        this.modelSelectedThrottled = _.throttle(this.modelSelected, 100, {
            leading: true,
            trailing: true,
        });

        this.checkCollectionLength = _.debounce(this.checkCollectionLength, 100, true);
    },

    printObjectsLabel: function() {
        if (App.user.get('licenseLabelPrint')) {
            var selectedItems = this.collection;
            if (this.model.get('controlMode') === 'manage') {
                selectedItems = new ColidoCollection(this.collection.where({selected: true}));
            }

            App.addModal(new LabelPrintModalView({
                itemIds: selectedItems.pluck('id'),
                setModel: selectedItems.setModel,
            }), {
                additionalClassName: 'modal-left modal-label-print',
            });
        } else {
            var restrictionModalView = new RestrictionLabelModalView();
            App.addModal(restrictionModalView, {
                additionalClassName: 'modal-center modal-restriction',
                additionalBgClassName: 'light-bg',
            });
        }
    },

    printObjectsCsv: function() {
        var selectedItems = [];
        var setId = null;

        if (this.model.get('controlMode') === 'manage') {
            selectedItems = new ColidoCollection(this.collection.where({selected: true}));
        } else {
            selectedItems = this.collection.pluck('id');
            setId = this.collection.setModel ? this.collection.setModel.id : null;
        }

        App.radio('collection').execute('print:report', {
            format: 'csv',
            catalogId: this.collection.catalog.id,
            items: selectedItems,
            set: setId,
        });
    },

    printObjectsPdf: function() {
        var selectedItems = this.collection;
        if (this.model.get('controlMode') === 'manage') {
            selectedItems = new ColidoCollection(this.collection.where({selected: true}));
        }

        App.addModal(new AdvancedPrintModalView({
            catalog: this.collection.catalog,
            collection: selectedItems,
        }), {
            additionalClassName: 'modal-left modal-list-print',
        });
    },

    onRender: function() {
        this.model.set('controlMode', 'default');

        this.createSelectpicker(this.ui.gridSort);

        if (this.getOption('viewSwitch')) {
            this.viewSwitchRegion.show(new ViewSwitchView(this.options));
        }

        if (this.getOption('hasFilter')) {
            this.activeFiltersRegion.show(new ActiveFiltersView({
                collection: this.collection,
                activeFilter: this.model.get('filter'),
                stateModel: this.model,
            }))
        }

        this.$el.attr('class', _.result(this, 'className'));
    },

    onAttach: function() {
        var listControlOffsetElements = this.getOption('listControlOffsetElements') ? this.getOption('listControlOffsetElements') : null;

        this.applyFixedListControl(this.$el, this.getOption('scrollContainer'), listControlOffsetElements);

        if (this._getViewMode() === 'showroom') {
            this.disableFixedListControl();
        }
    },

    /**
     * User has clicked a button that switches the list control mode
     * @param {object} event The event object
     * @return {void}
     */
    switchListControlView: function(event) {
        var $curTarget = $(event.currentTarget);
        var controlMode = $curTarget.data('targetstate') || 'default';
        this.model.set('controlMode', controlMode);
    },

    viewData: {
        gridViewSortableFields: function() {
            return this.collection.catalog.getGridViewSortableFields();
        },
    },

    /**
     * User has clicked button to show table settings
     */
    openTableSettingsModal: function() {
        App.addModal(new TableFieldsSettingsModalView({
            tableFields: this.collection.catalog.getTableFields(),
        }), {
            onAfterClose: function(data) {
                if (data) {
                    this.collection.catalog.set({fieldsTableOrder: data});
                    this.collection.trigger('reset');

                    this.collection.catalog.save({fieldsTableOrder: data}, {
                        patch: true,

                        success: function() {
                            // Probably not that interesting
                            // Messenger().info({
                            //     message: App.t('collection::catalog:update:table-settings:success:message'),
                            // });
                        }.bind(this),

                        error: function() {
                            Messenger().error({
                                message: App.t('collection::catalog:update:table-settings:error:message'),
                            });
                        },
                    });
                }
            }.bind(this),

            additionalClassName: 'modal-table-fields-settings-modal modal-small',
        });
    },

    /**
     * User has clicked button to show filter
     */
    openFilterModal: function() {
        if (App.user.get('licenseFilter')) {
            this.model.set('filterModal', true);
        } else {
            var restrictionModalView = new RestrictionModalView();
            var options = {
                additionalClassName: 'modal-center modal-restriction',
                additionalBgClassName: 'light-bg',
            };
            App.addModal(restrictionModalView, options);
        }
    },

    /**
     * Enabled/disables filter button depending on filter modal visibility
     */
    filterModalChanged: function(model, filterModalActive) {
        this.ui.openFilter.prop('disabled', filterModalActive);
    },

    /**
     * User has changed grid sort select box
     * @param {object} event The event object
     * @return {void}
     */
    gridSortChange: function(event) {
        var select = $(event.currentTarget);
        // Selectpicker sometimes reports empty value, check we have an
        // actual value.
        var sortString = select.val();
        if (sortString) {
            this.model.save('sortGrid', sortString);
        }
    },

    /**
     * Changes list control mode when controlMode attribute changes
     */
    controlModeChanged: function(model, controlMode) {
        this.$el.attr('data-state', controlMode);
    },

    /**
     * Re-renders when 'viewMode' attribute has changed in model
     */
    viewModeChanged: function(model, viewMode) {
        this.render();
    },

    /**
     * Re-renders when 'sort' attribute has changed in model
     */
    sortChanged: function(model, sort) {
        // Selectpicker seems to stop responding after the first change
        // event. Work around the issue by manually re-rendering.
        this.render();
    },

    /**
     * Re-renders when 'filter' attribute has changed in model
     */
    filterChanged: function(model, filter) {
        // Selectpicker seems to stop responding after the first change
        // event. Work around the issue by manually re-rendering.
        this.render();
    },

    /**
     * This method will delete all selected items
     * @param {object} event The event object
     */
    deleteSelectedItems: function(event) {
        var selectedItems = this.collection.where({selected: true});

        // Ask user for confirmation as the action can't be undone
        if (!window.confirm(App.t('collection::object:action:confirmDelete', selectedItems.length))) {
            return;
        }

        this.ui.deleteButton.prop('disabled', true).addClass('is-loading');

        // Trigger delete
        App.radio('collection').execute('delete', selectedItems, function() {
            this.ui.deleteButton.removeClass('is-loading');
        }.bind(this));
    },

    /**
     * Is triggered, when the actions button in managed mode is clicked.
     * We will open the actions overlay
     * @param {object} event The click event
     */
    showActions: function(event) {
        if (this.collection.selectedCount() > 0) {
            var $curTarget = $(event.currentTarget);
            var pos = $curTarget.offset();

            this.ui.actionLayer.css({
                top: pos.top - this.getPxSize(2) + 'px',
                left: pos.left - this.getPxSize(2) + 'px',
            }).addClass('is-visible');

            // Close actions overlay on next click
            event.stopPropagation();
            $('body').one('click', function() {
                this.hideActions();
            }.bind(this));
        }
    },

    hideActions: function() {
        if (!this.isDestroyed) {
            this.ui.actionLayer.removeClass('is-visible');
        }
    },

    showAdditionalActions: function(event) {
        var $curTarget = $(event.currentTarget);
        var pos = $curTarget.offset();
        var posRight = $(window).width() - pos.left - $curTarget.outerWidth();

        this.ui.additionalActionLayer.css({
            top: pos.top - this.getPxSize(0.5) + 'px',
            right: posRight + 'px',
        }).addClass('is-visible');

        // Close actions overlay on next click
        event.stopPropagation();
        $('body').one('click', function() {
            this.hideAdditionalActions();
        }.bind(this));
    },

    hideAdditionalActions: function(event) {
        if (!this.isDestroyed) {
            this.ui.additionalActionLayer.removeClass('is-visible');
        }
    },

    /**
     * Is triggered from small action overlay, when the user wants to change location.
     */
    onActionUpdateLocation: function(event) {
        if (!App.user.get('licenseStorages')) {
            var restrictionModalView = new RestrictionStoragesModalView({hasClose: true});
            var options = {
                additionalClassName: 'modal-center modal-restriction',
                additionalBgClassName: 'light-bg',
            };
            App.addModal(restrictionModalView, options);
            event.preventDefault();

            return false;
        }

        var selectedObjects = this.collection.getSelected();

        App.addModal(new ChooseStorageModalLayout({
            objectCount: selectedObjects.length,
        }), {
            additionalClassName: 'modal-left modal-medium modal-select-location',
            onAfterClose: function(data) {
                if (data) {
                    App.radio('collection').execute(
                        'change:storage',
                        this.collection.getSelected(),
                        data.location
                    );
                }
            }.bind(this),
        });
    },

    /**
     * Is triggered from small action overlay, when the user wants to add something to sets
     */
    onActionAddToSet: function() {
        App.addModal(new SelectSetOverlayLayout({
            collection: this.collection,
        }), {
            additionalClassName: 'modal-left modal-medium modal-select-set',
            onAfterClose: function(data) {
                if (data) {
                    App.radio('collection').execute(
                        'add:to:set',
                        this.collection.getSelected(),
                        data.setModel
                    );
                }
            }.bind(this),
        });
    },

    /**
     * Is triggered from small action overlay, when the user wants to remove items from sets
     */
    onActionRemoveFromSet: function() {
        if (this.model.get('setModel') && this.collection.selectedCount() > 0) {
            var question = App.t('collection::set:question:remove-from-set', this.collection.selectedCount(), {
                setName: '»' + this.model.get('setModel').get('name')  + '«',
            });

            if (window.confirm(question)) {
                App.radio('collection').execute(
                    'remove:from:set',
                    this.collection.getSelected(),
                    this.model.get('setModel'),
                    this.modelSelected.bind(this)
                );
            }
        }
    },

    /**
     * Is triggered from small action overlay, when the user wants change the status
     */
    onActionChangeStatus: function() {
        App.addModal(new ChangeStatusOverlayLayout({
            collection: this.collection,
        }), {
            additionalClassName: 'modal-left modal-medium modal-change-status',
            onAfterClose: function(data) {
                if (data) {
                    App.radio('collection').execute(
                        'change:status',
                        this.collection.getSelected(),
                        data.statusModel,
                        data.recipient
                    );
                }
            }.bind(this),
        });
    },

    /**
     * Sets the childview container, based on the selected view
     * @private
     */
    _toggleShowRoom: function() { //@todo is this the right place?
        if (this._getViewMode() === 'showroom-grid') {
            $('body').addClass('is-showroom');
            this.disableFixedListControl();
        } else {
            $('body').removeClass('is-showroom');
            this.enableFixedListControl();
        }
    },

    /**
     * This method will return the current viewmode of the sote
     * @returns {string} The current viewmode
     * @private
     */
    _getViewMode: function() {
        var viewMode = 'grid';
        if (typeof this.model === 'object' && !this.getOption('infiniteView')) {
            viewMode = this.model.get('viewMode');
        }

        return viewMode;
    },

    /**
     * Selects all items in a list
     * @param {object} event The event object
     * @return {void}
     */
    selectAll: function(event) {
        if (this.collection.allSelected()) {
            this.collection.deselectAll();
        } else {
            this.collection.selectAll();
        }
    },

    /**
     * Checks if all select and renames the button
     * @private
     */
    modelSelected: function() {
        if (!this.isDestroyed) {
            var allSelected = this.collection.allSelected();
            var selectedCount = this.collection.selectedCount();
            this.ui.selectAll.html(allSelected ? App.t('collection::list-control:manage-objects:deselect-all') : App.t('collection::list-control:manage-objects:select-all'));
            this.ui.showActions.prop('disabled', selectedCount === 0);
            this.ui.deleteButton.prop('disabled', selectedCount === 0);
            // this.ui.actionPrintLabel.html()
        }
    },

    /**
     * Closes the showroom and switches back to last view
     * @param event The click/tap event
     */
    onCloseShowroom: function() {
        this.ui.viewOption.prop('checked', false);
        var viewModeBefore = this.model.get('viewModeDefault') || 'grid';
        this.$('input[value="' + viewModeBefore + '"]').prop('checked', true).trigger('change');
    },

    /**
     * Sets the viewmode to showroom
     */
    onActionLaunchShowroom: function() {
        this.model.set('viewMode', 'showroom-grid');
    },

    /**
     * Checks, if the collection has items and toggles the class
     */
    checkCollectionLength: function() {
        this.$el.attr('class', _.result(this, 'className'));
    },

    onResize: function() {
        this.hideActions();
        this.hideAdditionalActions();
    },

    onTriggerEdit: function(event) {
        event.preventDefault();
        App.radio('collection:listcontrol').trigger('edit');
    },

    onTriggerDelete: function(event) {
        event.preventDefault();
        App.radio('collection:listcontrol').trigger('delete');
    },

    onTriggerShare: function(event) {
        event.preventDefault();
        App.radio('collection:listcontrol').trigger('share');
    },

    /**
     * Indicates to show the edit option in dropdown
     * @returns {boolean} True, if the edit option should be visible
     */
    showEditOption: function() {
        return this.model.get('locationModel') || this.model.get('setModel') !== null;
    },

    /**
     * Indicates to show the delete option in dropdown
     * @returns {boolean} True, if the delete option should be visible
     */
    showDeleteOption: function() {
        return this.model.get('locationModel') || this.model.get('setModel') !== null;
    },

    /**
     * Indicates to show the share option in dropdown
     * @returns {boolean} True, if the share option should be visible
     */
    showShareOption: function() {
        return this.model.get('setModel') !== null && Colido.config.allowSharing;
    },

    /**
     * Indicates to show the remove from set option for mass actions
     * @returns {boolean} True, if the remove from set option should be visible
     */
    showRemoveFromSetOption: function() {
        return this.model.get('setModel') !== null;
    },

    /**
     * Returns a proper dropdownlabel based on current model for edit, delete ... for the actions dropdown
     * @param {string} type The type [edit | delete | share]
     * @returns {string} The label
     */
    getDropdownLabel: function(type) {
        var label = App.t('collection::objects:view:button:' + type);

        if (this.model.get('setModel')) {
            label = App.t('collection::objects:view:button:' + type + ':set');
        } else if (this.model.get('locationModel')) {
            label = App.t('collection::objects:view:button:' + type + ':storage');
        }

        return label;
    },
});

module.exports = ListControlView;