module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<tr>\n    <td>\n        '+
((__t=(item.file.filename))==null?'':_.escape(__t))+
'\n    </td>\n    <td>\n        '+
((__t=(item.exported))==null?'':_.escape(__t))+
'\n    </td>\n    <td>\n        '+
((__t=(item.created))==null?'':_.escape(__t))+
'\n    </td>\n    <td>\n        '+
((__t=(item.file.type))==null?'':_.escape(__t))+
'\n    </td>\n    <td>\n        <a href="/export/'+
((__t=( item.id ))==null?'':_.escape(__t))+
'" class="btn btn-primary">\n            Download\n        </a>\n    </td>\n    <td>\n        <button class="btn btn-primary" data-action="delete-item">\n            Delete\n        </button>\n    </td>\n</tr>';
}
return __p;
};