'use strict';

var LayoutView = require('base/Colido.LayoutView');
var GroupActionsView = require('./group-actions');
var GroupMembersView = require('./group-members');

var template = require('modules/community/templates/partials/group.hbs');

var GroupView = LayoutView.extend({
    template: template,

    tagName: 'li',

    regions: {
        groupActionsRegion: '.group-actions-region',
        groupMembersRegion: '.group-members-region',
    },

    className: function(){
        var classNames = ['group'];

        if (this.isActive()) {
            classNames.push('is-active');
        }

        return classNames.join(' ');
    },

    modelEvents: {
        'change': 'render',
    },

    viewData: {
        unreadPostsCount: function() {
            var conversation = this.model.get('conversation');

            return conversation ?  conversation.get('unreadPosts') : 0;
        },

        isActive: function() {
            return this.isActive();
        },
    },

    onRender: function() {
        this.groupMembersRegion.show(new GroupMembersView({model: this.model, groupMemberLimit: 4}));
        this.groupActionsRegion.show(new GroupActionsView({model: this.model}));
    },

    isActive: function() {
        return this.getOption('activeConversationId') && this.getOption('activeConversationId') === this.model.get('conversationId');
    },
});

module.exports = GroupView;