'use strict';

var ListLayout = require('modules/collection/views/layout/list');
var ObjectHeaderView = require('./import-object-header');

var ImportObjectListLayout = ListLayout.extend({
    getHeaderViewClass: function () {
        return ObjectHeaderView;
    },
});

module.exports = ImportObjectListLayout;