'use strict';

var LayoutView = require('base/Colido.LayoutView');

var template = require('modules/authentication/templates/layout/index.hbs');

var IndexView = LayoutView.extend({
    template: template,

    regions: {
        'loginRegion': '.login-region',
        'signupRegion': '.signup-region',
        'forgotPasswordRegion': '.forgot-password-region',
        'forgotPasswordTokenRegion': '.forgot-password-token-region',
        'signupTokenRegion': '.signup-token-region',
        'deleteAccountRegion': '.delete-account-region',
        'accountDeleteRegion': '.delete-account-region',
    },

    activate: function(region) {
        _.each(this.getRegions(), function(region){
            region.$el.removeClass('active');
        });

        this.getRegion(region).$el.addClass('active');
    },
});

module.exports = IndexView;