module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="objects-listing is-viewmode-list '+
((__t=( when(model && model.get('controlMode') === 'manage', 'is-manage-mode') ))==null?'':_.escape(__t))+
'">\n    ';
 if (collection.length > 0) { 
__p+='\n        <div class="container container-list-objects">\n            <div class="container-table-scrollbar">\n                <div class="container-table-scrollbar-inner"></div>\n            </div>\n            <div class="viewmode viewmode-list">\n                <table class="table table-overflow table-striped">\n                    <thead>\n                        <tr>\n                            ';
 _.each(fields, function(field){ 
__p+='\n                                <th class="object-column-'+
((__t=(_.camelToHyphen(field.type)))==null?'':_.escape(__t))+
'">\n                                    <label data-sortkey="'+
((__t=(field.name))==null?'':_.escape(__t))+
'" data-sortdir="asc">\n                                        '+
((__t=(field.label))==null?'':_.escape(__t))+
'\n                                        <div class="sort">\n                                            '+
((__t=( icon('sort-up') ))==null?'':__t)+
'\n                                            '+
((__t=( icon('sort-down') ))==null?'':__t)+
'\n                                        </div>\n                                    </label>\n                                </th>\n                            ';
 }) 
__p+='\n                        </tr>\n                    </thead>\n                    <tbody class="objects-table-container"></tbody>\n                </table>\n            </div>\n        </div>\n    ';
 } else { 
__p+='\n        <div class="container container-list-objects">\n            <div class="viewmode viewmode-list">\n                <div class="list list-flex objects-table-container"></div>\n            </div>\n        </div>\n    ';
 } 
__p+='\n</div>\n\n\n\n<button type="button" class="btn btn-back-to-top" data-action="back-to-top" title="'+
((__t=( t('global::button:scroll-back-to-top') ))==null?'':_.escape(__t))+
'">\n    '+
((__t=(icon("up")))==null?'':__t)+
'\n</button>';
}
return __p;
};