'use strict';

var Backbone = require('backbone');
var Wreqr = require('base/Colido.Wreqr');

var ExportModuleWreqr = Wreqr.extend({
    name: 'collection:export',

    /**
     * Is triggered, when an export was updated
     * @param {Backbone.Model} model The model, that was updated
     */
    ventExportUpdated: function(data) {
        if (data.state === 'complete') {
            Messenger().post({
                type: 'success',
                message: App.t('collection::export:response:export-finished', {name: data.file.filename}),
                actions: {
                    show: {
                        label: App.t('collection::export:button:show-export'),
                        action: function () {
                            App.router.navigate('/settings/export', {trigger: true});
                        },
                    },
                },
            });
        }
    },
});

module.exports = ExportModuleWreqr;