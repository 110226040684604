module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="box box-object-album box-object-photobox-album" data-id="'+
((__t=( item.id ))==null?'':_.escape(__t))+
'">\n    <div class="headline-wrapper">\n        <h4>'+
((__t=(item.title))==null?'':_.escape(__t))+
'</h4>\n        <button type="button" class="btn btn-clean btn-secondary" data-action="delete">'+
((__t=(t('collection::album:modal:delete')))==null?'':_.escape(__t))+
'</button>\n    </div>\n\n    <div class="sly-frame">\n        <ul class="sly-slider">\n           ';
 for (var i = 0; i < Math.min(item.images.length, 30); i++) { 
__p+='\n                <li>\n                    <img src="'+
((__t=(model.getImageUrl(imageSize('medium'), i)))==null?'':_.escape(__t))+
'" />\n                </li>\n           ';
 } 
__p+='\n        </ul>\n    </div>\n\n    <div class="meta-info">\n        <span class="item-count">\n            '+
((__t=(t('collection::album:modal:images', model.getImageCount())))==null?'':_.escape(__t))+
'\n        </span>\n        <span class="rfid-connection">\n            ';
 if (model.hasRfidConnection()) { 
__p+='\n                <span class="rfid-connection rfid-connected">\n                    <span class="status-indicator is-on"></span>\n                    '+
((__t=(t('collection::album:modal:rfid-connected')))==null?'':_.escape(__t))+
'\n                </span>\n            ';
 } else { 
__p+='\n                <span class="rfid-connection rfid-disconnected">\n                    <span class="status-indicator is-off"></span>\n                    '+
((__t=(t('collection::album:modal:no-rfid-connected')))==null?'':_.escape(__t))+
'\n                </span>\n            ';
 } 
__p+='\n        </span>\n    </div>\n</div>';
}
return __p;
};