'use strict';

var Collection = require('base/Colido.Collection');
var DefaultInputFieldsModel = require('./DefaultInputFieldsModel');

var DefaultInputFieldsCollection = Collection.extend({
    api: '/core/columns',
    name: 'base:collection-default-input-fields',
    model: DefaultInputFieldsModel,
    comparator: function(item) {
        return item.get('order');
    },
});

module.exports = DefaultInputFieldsCollection;