'use strict';

var Backbone = require('backbone');
var Controller = require('base/Colido.Controller');
var LoginModel = require('./models/login');
var ForgotPasswordModel = require('./models/forgot-password');
var SignupModel = require('./models/signup');
var IndexView = require('./views/layout/index');
var LoginView = require('./views/partials/login');
var SignupView = require('./views/partials/signup');
var SignupTokenView = require('./views/partials/signup-token');
var ForgotPasswordView = require('./views/partials/forgot-password');
//var WelcomeModalView = require('./views/modal/welcome-modal');
var DeleteAccountView = require('./views/partials/delete-account');
var ForgotPasswordTokenView = require('./views/partials/forgot-password-token');

var AuthenticationModuleController = Controller.extend({
    routes: function() {
        var routes = {
            'login': 'actionLogin',
            'login/to/*deepLink': 'actionLogin',
            'logout': 'actionLogout',
            'forgot-password': 'actionForgotPassword',
            'forgot-password-token/:token': 'actionForgotPasswordToken',
            'signup': 'actionSignup',
            'signup-token/:userid/:token': 'actionSignupToken',
            'delete-account/:userid/:token': 'actionDeleteAccount',
            'account-deleted': 'actionAccountDeleted',
        };

        // Add language routes
        for (var lang in App.supportedLanguages) {
            routes[App.supportedLanguages[lang]] = 'actionLang';
        }

        return routes;
    },

    regionContainer: null,
    baseLayout: null,
    loginView: null,
    signupView: null,
    forgotPasswordView: null,
    forgotPasswordTokenView: null,
    signupTokenView: null,
    deleteAccountView: null,

    /**
     * Indicates the container, that was active before language switch
     * @type {string|null}
     */
    reactivateContainer: null,

    initialize: function(options) {
        this.loginModel = new LoginModel();
        this.forgotPasswordModel = new ForgotPasswordModel();
        this.signupModel = new SignupModel();

        this.regionContainer = App.rootView.loginRegion;

        // Listen for a language change
        this.listenTo(App.radio().vent, 'language:changed', function() {
            if (!App.user.hasApiToken()) {
                // Determine which container to reactivate
                for (var region in this.regionContainer.currentView.regions) {
                    var regionClass = this.regionContainer.currentView.regions[region];
                    if (this.regionContainer.currentView.$(regionClass).hasClass('active')) {
                        this.reactivateContainer = region;
                    }
                }

                // Rebuild layout
                this.baseLayout = new IndexView();
                this.regionContainer.show(this.baseLayout);
            }
        }.bind(this));

        // Listen for onShow on region container
        this.listenTo(this.regionContainer, 'show', function() {
            this.loginView = new LoginView({
                model: this.loginModel,
            });

            this.signupView = new SignupView({
                model: this.signupModel,
            });
            this.signupTokenView = new SignupTokenView();

            this.forgotPasswordView = new ForgotPasswordView({
                model: this.forgotPasswordModel,
            });
            this.forgotPasswordTokenView = new ForgotPasswordTokenView();
            this.deleteAccountView = new DeleteAccountView();

            // Append subviews
            this.baseLayout.loginRegion.show(this.loginView);
            this.baseLayout.signupRegion.show(this.signupView);
            this.baseLayout.forgotPasswordRegion.show(this.forgotPasswordView);
            this.baseLayout.forgotPasswordTokenRegion.show(this.forgotPasswordTokenView);
            this.baseLayout.signupTokenRegion.show(this.signupTokenView);
            this.baseLayout.deleteAccountRegion.show(this.deleteAccountView);
            this.initLogin();

            // Reactivate a container?
            if (this.reactivateContainer) {
                this.baseLayout.activate(this.reactivateContainer);
            }
        }.bind(this));

        // Create base layout
        this.baseLayout = new IndexView();
        this.regionContainer.show(this.baseLayout);
    },

    closeApp: function(){
        setTimeout(function(){
            this.regionContainer.$el.removeClass('open');
        }.bind(this), 10);
    },

    doLogin: function(model){
        var loginView = this.loginView;

        if (model.isValid()) {
            model.save(null, {
                success: function(model, response) {
                    App.user.set({
                        id: response.userId,
                        apiToken: response.apiToken,
                    });

                    App.user.login({
                        success: function(){
                            // this is not pretty
                            this.regionContainer.$el.addClass('open');
                            var deepLink = model.get('deepLink') || 'dashboard';
                            App.router.navigate(deepLink, {trigger: true});
                        }.bind(this),
                        error: function(model, response, options) {
                            var message = response.responseJSON && response.responseJSON.shortMsg
                                ? response.responseJSON.shortMsg
                                : App.t('login::unknown-error');
                            loginView.showError(message);
                        },
                    });
                }.bind(this),

                error: function(model, response) {
                    //@todo add proper error message
                    var message = response.responseJSON && response.responseJSON.message
                        ? response.responseJSON.message
                        : App.t('login::unknown-error');

                    loginView.showError(message);
                }.bind(this),
            });
        } else {
            loginView.showError(model.validate(model.attributes));
        }
    },
    /*
    showWelcomeModal: function() {
        var welcomeModal = new WelcomeModalView();
        var options = {
            additionalClassName: 'modal-center',
            additionalBgClassName: 'light-bg',
        };

        setTimeout(function(){
            App.addModal(welcomeModal, options);
        }.bind(this), 1250);
    },*/

    initLogin: function() {
        var loginView = this.loginView;
        var signupView = this.signupView;

        signupView.on('login', function(model) {
            this.doLogin(model);
            //setTimeout(this.showWelcomeModal.bind(this), 250);
        }.bind(this));
        loginView.on('login', function(model) {
            this.doLogin(model);
        }.bind(this));
    },

    actionLogin: function(deepLink) {
        if (deepLink) {
            this.loginModel.set({deepLink: deepLink});
        }

        this.closeApp();
        this.baseLayout.activate('loginRegion');
    },

    actionLogout: function() {
        this.closeApp();
        App.user.logout();
    },

    actionForgotPassword: function(){
        this.closeApp();
        this.baseLayout.activate('forgotPasswordRegion');

        this.forgotPasswordModel.set('email', this.loginView.getEmail());
    },

    actionDeleteAccount: function(userid, token){
        this.closeApp();
        this.baseLayout.activate('deleteAccountRegion');

        var deleteAccountView = this.deleteAccountView;
        deleteAccountView.showDeleteForm(userid, token);
    },

    actionAccountDeleted: function() {
        this.closeApp();
        this.baseLayout.activate('deleteAccountRegion');
        var deleteAccountView = this.deleteAccountView;
        deleteAccountView.showSuccess(App.t('delete-account::success'));
    },

    actionForgotPasswordToken: function(token){
        this.closeApp();
        this.baseLayout.activate('forgotPasswordTokenRegion');
        var tokenView = this.forgotPasswordTokenView;

        if (token){
            $.ajax({
                url: window.Colido.config.apiUrl + '/lostpassword?token=' + token,

                success: function(result){
                    tokenView.showSuccess(result.newPassword);
                    $('.login-form [name="password"]').val('');
                    $('.login-form fieldset[class="submit"]').before('<p>' + App.t('login::your-interim-password') + result.newPassword + '</p>');
                },

                error: function(result){
                    tokenView.showError(result.responseJSON.message);
                    tokenView.removeInput();
                },
            });
        } else {
            tokenView.showError(App.t('forgot-password::no-token-found'));
        }
    },

    actionSignup: function() {
        this.closeApp();
        this.baseLayout.activate('signupRegion');
    },

    actionSignupToken: function(userId, token) {
        this.closeApp();
        this.baseLayout.activate('signupTokenRegion');

        var tokenView = this.signupTokenView;

        if (token && userId){
            // TODO: USE backbonejs
            $.ajax({
                url:window.Colido.config.apiUrl + '/users/' + userId + '/confirm/' + token,
                methods: 'POST',
                success:function(result){
                    tokenView.showSuccess(result.newPassword);
                },
                error:function(result){
                    tokenView.showError(result.responseJSON.message);
                    tokenView.removeInput();
                },
            });
        } else {
            tokenView.showError(App.t('signup-token::no-token-found'));
        }
    },

    /**
     * Set language
     */
    actionLang: function() {
        var lang = Backbone.history.fragment;
        if (_.contains(App.supportedLanguages, lang)) {
            App.user.save('language', lang);
        }

        // Make redirect to proper page
        App.router.navigate(App.user.hasApiToken() ? 'dashboard' : 'login', {trigger: true, replace: true});
    },
});

module.exports = AuthenticationModuleController;