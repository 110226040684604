'use strict';

require('vendor/messenger');
require('vendor/messenger-theme-flat');

// Enable Messenger
Messenger.options = {
    parentLocations: ['#app'],
    extraClasses: 'messenger-fixed messenger-on-bottom messenger-on-left',
    theme: 'flat',
    maxMessages: 4,
    messageDefaults: {
        hideAfter: 10,
        showCloseButton: true,
        hideOnNavigate: true,
    },
};