module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row">\n    <div class="page-col-offset input-help">\n        ';
 if (!item.isGroupChild) { 
__p+='\n                <span class="create-step-number">&nbsp;</span>\n        ';
 } 
__p+='\n        <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n    </div>\n\n    <div class="page-col-6 history-type">\n        <label class="label-headline">'+
((__t=(t('collection::object:details:section:headline:history')))==null?'':_.escape(__t))+
'</label>\n        <div class="option-select">\n            <input id="history-0-type-none"\n                   type="radio"\n                   name="history[0].type"\n                   value="" '+
((__t=( when(item.objectHistory.length === 0, 'checked') ))==null?'':_.escape(__t))+
'\n            />\n            <label for="history-0-type-none" class="option-select-secondary">'+
((__t=(t('collection::object:details:history:type:none:label')))==null?'':_.escape(__t))+
'</label>\n\n            <input id="history-0-type-purchased" type="radio" name="history[0].type" value="purchased" '+
((__t=( when(model.getHistoryValue(0, 'type') === 'purchased', 'checked') ))==null?'':_.escape(__t))+
'>\n            <label for="history-0-type-purchased" class="option-select-secondary">'+
((__t=(t('collection::object:details:history:type:purchased:label')))==null?'':_.escape(__t))+
'</label>\n\n            <input id="history-0-type-traded-off" type="radio" name="history[0].type" value="traded-off" '+
((__t=( when(model.getHistoryValue(0, 'type') === 'traded-off', 'checked') ))==null?'':_.escape(__t))+
'>\n            <label for="history-0-type-traded-off" class="option-select-secondary">'+
((__t=(t('collection::object:details:history:type:traded-off:label')))==null?'':_.escape(__t))+
'</label>\n\n            <input id="history-0-type-given" type="radio" name="history[0].type" value="given" '+
((__t=( when(model.getHistoryValue(0, 'type') === 'given', 'checked') ))==null?'':_.escape(__t))+
'>\n            <label for="history-0-type-given" class="option-select-secondary">'+
((__t=(t('collection::object:details:history:type:given:label')))==null?'':_.escape(__t))+
'</label>\n\n            <input id="history-0-type-found" type="radio" name="history[0].type" value="found" '+
((__t=( when(model.getHistoryValue(0, 'type') === 'found', 'checked') ))==null?'':_.escape(__t))+
'>\n            <label for="history-0-type-found" class="option-select-secondary">'+
((__t=(t('collection::object:details:history:type:found:label')))==null?'':_.escape(__t))+
'</label>\n\n            <input id="history-0-type-heritage" type="radio" name="history[0].type" value="heritage" '+
((__t=( when(model.getHistoryValue(0, 'type') === 'heritage', 'checked') ))==null?'':_.escape(__t))+
'>\n            <label for="history-0-type-heritage" class="option-select-secondary">'+
((__t=(t('collection::object:details:history:type:heritage:label')))==null?'':_.escape(__t))+
'</label>\n        </div>\n    </div>\n</div>\n\n<!-- From / Previous owner -->\n<div class="row history-addition history-addition-from create-step">\n    <div class="page-offset-default page-col-1">\n        <label class="row-label" for="history-0-from">'+
((__t=(t('collection::edit:object-history:from:label')))==null?'':_.escape(__t))+
'</label>\n    </div>\n    <div class="page-col-2">\n        <input type="text"\n               id="history-0-from"\n               name="history[0].from"\n               placeholder="'+
((__t=(t('collection::edit:object-history:from:placeholder')))==null?'':_.escape(__t))+
'"\n               value="'+
((__t=( model.getHistoryValue(0, 'from') ))==null?'':_.escape(__t))+
'">\n    </div>\n</div>\n\n<!-- For / Price -->\n<div class="row history-addition history-addition-for create-step">\n    <div class="page-offset-default page-col-1 ">\n        <label class="row-label">'+
((__t=(t('collection::edit:object-history:price:label')))==null?'':_.escape(__t))+
'</label>\n    </div>\n    <div class="page-col-2">\n        <input type="number"\n               step="any"\n               id="purchasing-price-0"\n               name="history[0].price"\n               placeholder="'+
((__t=(t('collection::edit:object-history:price:placeholder')))==null?'':_.escape(__t))+
'"\n               value="'+
((__t=( model.getHistoryValue(0, 'price') ))==null?'':_.escape(__t))+
'">\n    </div>\n    <div class="page-col-1">\n        <select name="history[0].currency" id="history-currency-0" class="select-secondary"></select>\n    </div>\n</div>\n\n<!-- On / Date -->\n<div class="row history-addition history-addition-on">\n    <div class="page-offset-default page-col-1">\n        <label class="row-label">'+
((__t=(t('collection::edit:object-history:date:label')))==null?'':_.escape(__t))+
'</label>\n    </div>\n    <div class="page-col-2">\n        <div class="history-date">\n            <select name="history[0].date.year" class="date-year"></select>\n            <select name="history[0].date.month" class="date-month"></select>\n            <select name="history[0].date.day" class="date-day"></select>\n        </div>\n    </div>\n</div>\n\n';
 if (item.previousCollectionsField) { 
__p+='\n  <!-- Previous owners and collections -->\n  <div class="row history-previous-owners">\n      <div class="page-offset-default page-col-3">\n          <label for="previous-owners" class="label-headline">'+
((__t=(t('collection::edit:object-history:previous-owners:label')))==null?'':_.escape(__t))+
'</label>\n          <textarea name="'+
((__t=(item.previousCollectionsField.name))==null?'':_.escape(__t))+
'"\n                    id="previous-owners"\n                    placeholder="'+
((__t=(t('collection::edit:object-history:previous-owners:placeholder')))==null?'':_.escape(__t))+
'"\n                    data-autosize="true"\n                    rows="1">'+
((__t=(model.get(item.previousCollectionsField.name)))==null?'':_.escape(__t))+
'</textarea>\n      </div>\n  </div>\n';
 } 
__p+='';
}
return __p;
};