'use strict';

var Model = require('base/Colido.Model');

var LoginModel = Model.extend({
    api: '/authentication',

    defaults: {
        email: '',
        password: '',
        clientIdentifier: 'com.colido.webapp',
    },

    validate: function(values) {
        var errors = {};

        if (_.isEmpty(values.email) || !values.email.match(/.*@.*/)){
            errors.email = App.t('login::invalid-email');
        }

        if (_.isEmpty(values.password)){
            errors.password = App.t('login::password-required');
        }

        return _.isEmpty(errors) ? null : errors;
    },
});

module.exports = LoginModel;