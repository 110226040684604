module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="groups-metainfo">\n    <h4 class="groups-count"></h4>\n    <button data-action="show-create-group-form" class="btn-primary btn-group-create">\n    	'+
((__t=(t(view.getOption('type') === 'broadcast' ? 'community::broadcast:button:label:create' : 'community::group:button:label:create')))==null?'':_.escape(__t))+
' '+
((__t=( icon('add-small')))==null?'':__t)+
'\n    </button>\n\n    <div class="group-form-region"></div>\n</div>\n\n<div class="groups-region"></div>';
}
return __p;
};