module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row row-nospace">\n    <div class="page-col-offset input-help">\n        ';
 if (!item.isGroupChild) { 
__p+='\n          <span class="create-step-number">&nbsp;</span>\n        ';
 } 
__p+='\n        <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n    </div>\n    \n    ';
 if (!item.isGroupChild || !item.field.editOptions.hideLabelInGroup) { 
__p+='\n      <div class="page-col-3">\n          <label class="label-headline">'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</label>\n      </div>\n    ';
 } 
__p+='\n</div>\n<div class="row">\n    <div class="page-col-1 page-offset-default page-col-before-spacer">\n        <div class="input-label-group input-label-group-dimension">\n            <input type="number"\n                   step="any"\n                   name="'+
((__t=(item.widthField.name))==null?'':_.escape(__t))+
'"\n                   value="'+
((__t=(model.get(item.widthField.name)))==null?'':_.escape(__t))+
'"\n                   id="field-'+
((__t=(item.widthField.name))==null?'':_.escape(__t))+
'"\n                   class="input-size-width"\n                   placeholder="'+
((__t=( t('collection::edit:dimension-selection:input-width') ))==null?'':_.escape(__t))+
'">\n            <label data-position="left" for="field-'+
((__t=(item.widthField.name))==null?'':_.escape(__t))+
'">'+
((__t=( t('collection::edit:dimension-selection:input-width') ))==null?'':_.escape(__t))+
'</label>\n        </div>\n    </div>\n    <div class="page-col-spacer">\n        <span class="spacer spacer-grotesk">×</span>\n    </div>\n    <div class="page-col-1 page-col-before-spacer">\n        <div class="input-label-group input-label-group-dimension">\n            <input type="number"\n                   step="any"\n                   name="'+
((__t=(item.heightField.name))==null?'':_.escape(__t))+
'"\n                   value="'+
((__t=(model.get(item.heightField.name)))==null?'':_.escape(__t))+
'"\n                   id="field-'+
((__t=(item.heightField.name))==null?'':_.escape(__t))+
'"\n                   class="input-size-height"\n                   placeholder="'+
((__t=( t('collection::edit:dimension-selection:input-height') ))==null?'':_.escape(__t))+
'">\n            <label data-position="left" for="field-'+
((__t=(item.heightField.name))==null?'':_.escape(__t))+
'">'+
((__t=( t('collection::edit:dimension-selection:input-height') ))==null?'':_.escape(__t))+
'</label>\n        </div>\n    </div>\n\n    ';
 if (item.depthField) { 
__p+='\n        <div class="page-col-spacer">\n            <span class="spacer spacer-grotesk">×</span>\n        </div>\n        <div class="page-col-1">\n            <div class="input-label-group input-label-group-dimension">\n                <input type="number"\n                       step="any"\n                       name="'+
((__t=(item.depthField.name))==null?'':_.escape(__t))+
'"\n                       value="'+
((__t=(model.get(item.depthField.name)))==null?'':_.escape(__t))+
'"\n                       id="field-'+
((__t=(item.depthField.name))==null?'':_.escape(__t))+
'"\n                       class="input-size-depth"\n                       placeholder="'+
((__t=( t('collection::edit:dimension-selection:input-depth') ))==null?'':_.escape(__t))+
'">\n                <label data-position="left" for="field-'+
((__t=(item.depthField.name))==null?'':_.escape(__t))+
'">'+
((__t=( t('collection::edit:dimension-selection:input-depth') ))==null?'':_.escape(__t))+
'</label>\n            </div>\n        </div>\n    ';
 } 
__p+='\n    <div class="page-col-1">\n        <select name="'+
((__t=(item.unitField.name))==null?'':_.escape(__t))+
'" class="select-size-unit select-secondary"></select>\n    </div>\n</div>\n\n<!-- Dimension type -->\n';
 if (item.typeField) { 
__p+='\n    <div class="row row-dimension-type">\n        <div class="page-offset-default page-col-6">\n            <div class="option-select">\n                <input id="dimensionTypeMicromount" type="radio" name="'+
((__t=(item.typeField.name))==null?'':_.escape(__t))+
'" value="micromount">\n                <label for="dimensionTypeMicromount" class="option-select-secondary">'+
((__t=( t('collection::object:dimension-type:micromount') ))==null?'':_.escape(__t))+
'</label>\n\n                <input id="dimensionTypeThumbnail" type="radio" name="'+
((__t=(item.typeField.name))==null?'':_.escape(__t))+
'" value="thumbnail">\n                <label for="dimensionTypeThumbnail" class="option-select-secondary">'+
((__t=( t('collection::object:dimension-type:thumbnail') ))==null?'':_.escape(__t))+
'</label>\n\n                <input id="dimensionTypeMiniature" type="radio" name="'+
((__t=(item.typeField.name))==null?'':_.escape(__t))+
'" value="miniature">\n                <label for="dimensionTypeMiniature" class="option-select-secondary">'+
((__t=( t('collection::object:dimension-type:miniature') ))==null?'':_.escape(__t))+
'</label>\n\n                <input id="dimensionTypeSmallCabinet" type="radio" name="'+
((__t=(item.typeField.name))==null?'':_.escape(__t))+
'" value="smallCabinet">\n                <label for="dimensionTypeSmallCabinet" class="option-select-secondary">'+
((__t=( t('collection::object:dimension-type:smallCabinet') ))==null?'':_.escape(__t))+
'</label>\n\n                <input id="dimensionTypeCabinetSpecimen" type="radio" name="'+
((__t=(item.typeField.name))==null?'':_.escape(__t))+
'" value="cabinetSpecimen">\n                <label for="dimensionTypeCabinetSpecimen" class="option-select-secondary">'+
((__t=( t('collection::object:dimension-type:cabinetSpecimen') ))==null?'':_.escape(__t))+
'</label>\n\n                <input id="dimensionTypeMuseumSpecimen" type="radio" name="'+
((__t=(item.typeField.name))==null?'':_.escape(__t))+
'" value="museumSpecimen">\n                <label for="dimensionTypeMuseumSpecimen" class="option-select-secondary">'+
((__t=( t('collection::object:dimension-type:museumSpecimen') ))==null?'':_.escape(__t))+
'</label>\n            </div>\n        </div>\n    </div>\n';
 } 
__p+='';
}
return __p;
};