module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\r\n    <h2>'+
((__t=( t('helpdesk::label') ))==null?'':_.escape(__t))+
': '+
((__t=( t('helpdesk::getting-started:label') ))==null?'':_.escape(__t))+
'</h2>\r\n</header>\r\n\r\n<div class="container">\r\n    <section>\r\n        <div class="page-col-3">\r\n            <div class="video-box video-big">\r\n                <div class="video-header">\r\n                    <h4>Learn how in 5 minutes</h4>\r\n                    <h4 class="subline">5:54</h4>\r\n                </div>\r\n                <div class="video">\r\n                    <iframe src="https://player.vimeo.com/video/231860529?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" width="100%" height="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </section>\r\n</div>\r\n';
}
return __p;
};