module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\r\n    <h2>'+
((__t=( t('helpdesk::label') ))==null?'':_.escape(__t))+
': '+
((__t=( t('helpdesk::contact:label') ))==null?'':_.escape(__t))+
'</h2>\r\n</header>\r\n\r\n<form>\r\n    <div class="container">\r\n        <div class="form-66-left">\r\n            <label for="contact-name" class="label-headline">'+
((__t=( t('helpdesk::contact:name:label') ))==null?'':_.escape(__t))+
'</label>\r\n            <input type="text" name="contact-name" id="contact-name" placeholder="'+
((__t=( t('helpdesk::contact:name:placeholder') ))==null?'':_.escape(__t))+
'" value="'+
((__t=(App.user.get("fullname")))==null?'':_.escape(__t))+
'">\r\n\r\n            <hr class="invisible" />\r\n\r\n            <label for="contact-email" class="label-headline">'+
((__t=( t('helpdesk::contact:email:label') ))==null?'':_.escape(__t))+
'</label>\r\n            <input type="text" name="contact-email" id="contact-email" placeholder="'+
((__t=( t('helpdesk::contact:email:placeholder') ))==null?'':_.escape(__t))+
'" value="'+
((__t=(App.user.get("email")))==null?'':_.escape(__t))+
'">\r\n\r\n            <hr class="invisible" />\r\n\r\n            <label for="contact-text" class="label-headline">'+
((__t=( t('helpdesk::contact:text:label') ))==null?'':_.escape(__t))+
'</label>\r\n            <textarea name="contact-text" id="contact-text" placeholder="'+
((__t=( t('helpdesk::contact:text:placeholder') ))==null?'':_.escape(__t))+
'" data-autosize="true" rows="1"></textarea>\r\n\r\n            <hr class="invisible" />\r\n\r\n            <button class="btn btn-primary" type="button">'+
((__t=( t('helpdesk::contact:button-submit') ))==null?'':_.escape(__t))+
'</button>\r\n        </div>\r\n\r\n        <div class="form-33-right">\r\n            <fieldset class="form-note">\r\n                <label>'+
((__t=( t('helpdesk::contact:note:right:label') ))==null?'':_.escape(__t))+
'</label>\r\n\r\n                <p>'+
((__t=( t('helpdesk::contact:note:right:text') ))==null?'':_.escape(__t))+
'</p>\r\n            </fieldset>\r\n        </div>\r\n    </div>\r\n</form>';
}
return __p;
};