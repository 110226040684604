module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="filter-button">\n    <input type="checkbox"\n           id="objects-filter-recent-species-'+
((__t=( item.id ))==null?'':_.escape(__t))+
'"\n           value="'+
((__t=( item.id ))==null?'':_.escape(__t))+
'"\n           '+
((__t=(when(item.selected, 'checked="checked"')))==null?'':_.escape(__t))+
'>\n    <label for="objects-filter-recent-species-'+
((__t=( item.id ))==null?'':_.escape(__t))+
'">\n        '+
((__t=( item.name ))==null?'':_.escape(__t))+
'\n    </label>\n</fieldset>';
}
return __p;
};