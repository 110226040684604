module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>'+
((__t=(t('subscription::setup:billing-address:title')))==null?'':_.escape(__t))+
'</h2>\n\n';
 if (item.vatId.length || item.billingAddress.fullname.length || item.billingAddress.company.length || item.billingAddress.street.length || item.billingAddress.zip.length || item.billingAddress.city.length || item.countryCollection.get(item.billingAddress.country)) { 
__p+='\n    <address>\n        <dl>\n            ';
 if (item.vatId.length) { 
__p+='\n                <dt>'+
((__t=(t('subscription::setup:billing-address:vat:title')))==null?'':_.escape(__t))+
'</dt>\n                <dd>'+
((__t=( item.vatId ))==null?'':_.escape(__t))+
'</dd>\n            ';
 } 
__p+='\n\n            ';
 if (item.billingAddress.fullname.length) { 
__p+='\n                <dt>'+
((__t=(t('subscription::setup:billing-address:billing-address:name:label')))==null?'':_.escape(__t))+
'</dt>\n                <dd>'+
((__t=( item.billingAddress.fullname))==null?'':_.escape(__t))+
'</dd>\n            ';
 } 
__p+='\n\n            ';
 if (item.billingAddress.company.length) { 
__p+='\n                <dt>'+
((__t=(t('subscription::setup:billing-address:billing-address:company:label')))==null?'':_.escape(__t))+
'</dt>\n                <dd>'+
((__t=( item.billingAddress.company ))==null?'':_.escape(__t))+
'</dd>\n            ';
 } 
__p+='\n\n            ';
 if (item.billingAddress.street.length) { 
__p+='\n                <dt>'+
((__t=(t('subscription::setup:billing-address:billing-address:street:label')))==null?'':_.escape(__t))+
'</dt>\n                <dd>'+
((__t=( item.billingAddress.street ))==null?'':_.escape(__t))+
'</dd>\n            ';
 } 
__p+='\n\n            ';
 if (item.billingAddress.zip.length) { 
__p+='\n                <dt>'+
((__t=(t('subscription::setup:billing-address:billing-address:zip:label')))==null?'':_.escape(__t))+
'</dt>\n                <dd>'+
((__t=( item.billingAddress.zip ))==null?'':_.escape(__t))+
'</dd>\n            ';
 } 
__p+='\n\n            ';
 if (item.billingAddress.city.length) { 
__p+='\n                <dt>'+
((__t=(t('subscription::setup:billing-address:billing-address:city:label')))==null?'':_.escape(__t))+
'</dt>\n                <dd>'+
((__t=( item.billingAddress.city ))==null?'':_.escape(__t))+
'</dd>\n            ';
 } 
__p+='\n\n            ';
 if (item.countryCollection.get(item.billingAddress.country)) { 
__p+='\n                <dt>'+
((__t=(t('subscription::setup:billing-address:billing-address:country:label')))==null?'':_.escape(__t))+
'</dt>\n                <dd>\n                    '+
((__t=( item.countryCollection.get(item.billingAddress.country).get('name') ))==null?'':_.escape(__t))+
'\n                </dd>\n            ';
 } 
__p+='\n        </dl>\n    </address>\n';
 } else { 
__p+='\n    <p class="hint">\n        '+
((__t=(t('subscription::setup:billing-address:billing-address:empty')))==null?'':_.escape(__t))+
'\n    </p>\n';
 } 
__p+='\n\n<div class="actions">\n    <button class="btn btn-primary" data-action="edit">'+
((__t=( t('subscription::setup:billing-address:billing-address:change-billing-address') ))==null?'':_.escape(__t))+
'</button>\n</div>';
}
return __p;
};