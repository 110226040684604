module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\n    <h2>'+
((__t=(item.name))==null?'':_.escape(__t))+
': '+
((__t=( t('settings::import:label') ))==null?'':_.escape(__t))+
'</h2>\n</header>\n\n<div class="container container-dark container-unfinished-list">\n    <label>'+
((__t=(t('settings::import:headline:imports-in-progress')))==null?'':_.escape(__t))+
'</label>\n    <div id="region-unfinished-imports"></div>\n</div>\n\n<div class="container">\n    <fieldset class="form-note info-new">\n        <a href="'+
((__t=(item.importsPath))==null?'':_.escape(__t))+
'" class="btn btn-big-dotted btn-big-plus">\n            '+
((__t=(t('settings::import:button-import-database')))==null?'':_.escape(__t))+
'\n        </a>\n\n        <label>'+
((__t=(t('settings::import:note-new:label')))==null?'':_.escape(__t))+
'</label>\n        <p>'+
((__t=( t('settings::import:note-new:text', {importPath: item.importsPath}) ))==null?'':__t)+
'</a></p>\n    </fieldset>\n\n    <fieldset class="form-note">\n        <label>'+
((__t=(t('settings::import:note-recent:label')))==null?'':_.escape(__t))+
'</label>\n        <p>'+
((__t=(t('settings::import:note-recent:text')))==null?'':__t)+
'</p>\n    </fieldset>\n\n    <div id="region-recent-imports" class="container-finished-list"></div>\n\n    <fieldset class="form-note container-finished-empty hint">\n        '+
((__t=(t('settings::import:note-empty')))==null?'':__t)+
'\n    </fieldset>\n</div>';
}
return __p;
};