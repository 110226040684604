module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\n    <h2>'+
((__t=(item.name))==null?'':_.escape(__t))+
': '+
((__t=( t('settings::trash:label') ))==null?'':_.escape(__t))+
'</h2>\n    <h2 class="subline">'+
((__t=( t('settings::trash:subline:item-count', 0) ))==null?'':_.escape(__t))+
'</h2>\n</header>\n\n<div class="container">\n    <fieldset class="form-note">\n        <p>'+
((__t=( t('settings::trash:note:top') ))==null?'':_.escape(__t))+
'</p>\n        <button class="btn btn-primary btn-trash" data-action="empty-trash" '+
((__t=(when(collection.length === 0, 'disabled="disabled"')))==null?'':_.escape(__t))+
'>\n        	'+
((__t=( t('settings::trash:button-empty-trash') ))==null?'':_.escape(__t))+
'\n        </button>\n    </fieldset>\n\n    <div class="trash-list-region"></div>\n</div>';
}
return __p;
};