'use strict';

var Model = require('base/Colido.Model');

var CreditCardModel = Model.extend({
    defaults: function() {
        return {
            cardHolder: '',
            cardNumber: '',
            cardExpires: '',
            cardCvv: '',
        };
    },

    /**
     * Validates the creditcard input and returns an object with the validation states
     * @return {object} An object with validation states
     */
    validate: function(values) {
        var errors = {};

        //Check card holder
        if (_.isEmpty(values.cardHolder)){
            errors.cardHolder = App.t('subscription::payment:creditcard:error:card-holder');
        }

        //Check card number
        if (_.isEmpty(values.cardNumber) || $.payment.validateCardNumber(values.cardNumber) === false){
            errors.cardNumber = App.t('subscription::payment:creditcard:error:card-number');
        }

        //Check card expire date
        if (_.isEmpty(values.cardExpires)){
            errors.cardExpires = App.t('subscription::payment:creditcard:error:expire');
        }

        var cardExpiresIsValid = false;
        var cardExpiresDateParts = values.cardExpires.split('/');
        if (cardExpiresDateParts.length === 2) {
            var cardExpiresMonth = cardExpiresDateParts[0].trim();
            var cardExpiresYear = cardExpiresDateParts[1].trim();
            cardExpiresIsValid = $.payment.validateCardExpiry(cardExpiresMonth, cardExpiresYear);
        }

        if (cardExpiresIsValid === false){
            errors.cardExpires = App.t('subscription::payment:creditcard:error:format');
        }

        // Check CVV, CVC, CVS
        if (_.isEmpty(values.cardCvv)){
            errors.cardCvv = App.t('subscription::payment:creditcard:error:CVV-CVC-CVS');
        }

        var cardCvvIsValid = false;
        if (values.cardCvv.length > 0 && values.cardNumber.length > 0) {
            var cardType = $.payment.cardType(values.cardNumber);
            cardCvvIsValid = $.payment.validateCardCVC(values.cardCvv, cardType);
        }

        if (cardCvvIsValid === false){
            errors.cardCvv = App.t('subscription::payment:creditcard:error:format');
        }

        return _.isEmpty(errors) ? null : errors;
    },
});

module.exports = CreditCardModel;