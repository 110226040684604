module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="btn btn-secondary btn-caret" data-action="show-actions">\n    '+
((__t=(t('community::group:actions:label')))==null?'':_.escape(__t))+
'\n</button>\n\n<div class="action-layer">\n    <h3>'+
((__t=( t('community::group:action:headline') ))==null?'':_.escape(__t))+
'</h3>\n    <button type="button" class="btn btn-close">'+
((__t=( icon('close-light') ))==null?'':__t)+
'</button>\n    <ul>\n        <li>\n            <button data-action="rename-group">\n                '+
((__t=( t('community::group:action:rename') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n        <li>\n            <button data-action="change-users-group">\n                '+
((__t=( t('community::group:action:change-users') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n        ';
 if(item.type === 'broadcast'){ 
__p+='\n            <li>\n                <button data-action="delete-group">\n                    '+
((__t=( t('community::group:action:delete') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n        ';
 }else{ 
__p+='\n            <li>\n                <button data-action="leave-group">\n                    '+
((__t=( t('community::group:action:leave') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n        ';
 } 
__p+='\n    </ul>\n</div>';
}
return __p;
};