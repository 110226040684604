module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>'+
((__t=(t('subscription::payment-history:title')))==null?'':_.escape(__t))+
'</h2>\n<table class="table table-overflow table-striped table-without-margin">\n    <thead>\n        <tr>\n            <th><label>'+
((__t=(t('subscription::payment-history:table:plan')))==null?'':_.escape(__t))+
'</label></th>\n            <th><label>'+
((__t=(t('subscription::payment-history:table:amount')))==null?'':_.escape(__t))+
'</label></th>\n            <th><label>'+
((__t=(t('subscription::payment-history:table:date')))==null?'':_.escape(__t))+
'</label></th>\n            <th><label>'+
((__t=(t('subscription::payment-history:table:payment-method')))==null?'':_.escape(__t))+
'</label></th>\n            <th><label>'+
((__t=(t('subscription::payment-history:table:receipt')))==null?'':_.escape(__t))+
'</label></th>\n            <th><label>'+
((__t=(t('subscription::payment-history:table:id')))==null?'':_.escape(__t))+
'</label></th>\n        </tr>\n    </thead>\n    <tbody class="subscription-receipt-list"></tbody>\n</table>';
}
return __p;
};