'use strict';

var Controller = require('base/Colido.Controller');

var IndexLayout = require('./views/layout/index');
var AccountLayout = require('./views/layout/account');
var SubscriptionLayout = require('./views/layout/subscription');
var SubsriptionSetupLayout = require('./views/layout/subscription/setup');
var NotificationsLayout = require('./views/layout/notifications');
var ExportLayout = require('./views/layout/export');
var InformationLayout = require('./views/layout/information');
var ClientInformationsLayout = require('./views/layout/information/client-informations');
var ManageCatalogsLayout = require('./views/layout/manage-catalogs');
var ProfileLayout = require('./views/layout/profile');

var ReceiptsCollection = require('./collections/receipts');
var SubscriptionModel = require('./models/subscription');

var SettingsController = Controller.extend({
    routes: {
        'settings': 'actionCatalogs',
        'account': 'actionAccount',
        'account/account': 'actionAccount',
        'account/profile': 'actionProfile',
        'account/subscription': 'actionSubscription',
        'account/subscription/setup': 'actionSubscriptionSetup',
        'settings/notifications': 'actionNotifications',
        'settings/export': 'actionExport',
        'settings/information': 'actionInformation',
        'settings/information/client-informations': 'actionClientInformations',
        'settings/catalogs': 'actionCatalogs',
    },

    actionIndex: function() {
        var region = App.rootView.mainRegion.currentView.contentRegion;
        var layout = new IndexLayout();

        region.show(layout);
    },

    actionAccount: function(){
        var region = App.rootView.mainRegion.currentView.contentRegion;
        var layout = new AccountLayout({
            model: App.user,
        });

        region.show(layout);
    },

    actionProfile: function(){
        var region = App.rootView.mainRegion.currentView.contentRegion;
        var layout = new ProfileLayout({
            model: App.user,
        });

        region.show(layout);
    },

    actionSubscription: function(){
        var subscriptionModel = new SubscriptionModel();
        var receiptsCollection = new ReceiptsCollection();

        App.loadData(receiptsCollection);
        App.loadData(subscriptionModel);

        this.onDataReady([
            'account:subscription',
            // 'account:receipts',
        ], function(ready) {
            var region = App.rootView.mainRegion.currentView.contentRegion;

            var layout = new SubscriptionLayout({
                model: subscriptionModel,
                receiptsCollection: receiptsCollection,
            });

            region.show(layout);
        })
    },

    actionSubscriptionSetup: function(){
        var subscriptionModel = new SubscriptionModel();

        App.loadData(subscriptionModel);

        this.onDataReady([
            'account:subscription',
        ], function(ready) {
            if (subscriptionModel.get('subscriptionActive') === true) {
                App.router.navigate('account/subscription', {trigger: true, replace: true});
            }

            var region = App.rootView.mainRegion.currentView.extraContentRegion;
            var layout = new SubsriptionSetupLayout({
                subscriptionModel: subscriptionModel,
            });

            region.show(layout);
        });
    },

    actionNotifications: function(){
        var region = App.rootView.mainRegion.currentView.contentRegion;
        var layout = new NotificationsLayout();

        region.show(layout);
    },

    actionExport: function(){
        App.loadData(App.CollectionModule.ExportModule.exportsCollection, {reload: false}); // correct place for this?

        this.onDataReady([
            'collection:exports',
        ], function(ready) {
            var region = App.rootView.mainRegion.currentView.contentRegion;
            var layout = new ExportLayout({
                collection: App.CollectionModule.ExportModule.exportsCollection,
            });

            region.show(layout);
        });
    },

    actionInformation: function(){
        var region = App.rootView.mainRegion.currentView.contentRegion;
        var layout = new InformationLayout();

        region.show(layout);
    },

    actionClientInformations: function(){
        var region = App.rootView.mainRegion.currentView.contentRegion;
        var layout = new ClientInformationsLayout();

        region.show(layout);
    },

    actionCatalogs: function(){
        this.onDataReady(App.CollectionModule.catalogs, function() {
            var catalogs = App.CollectionModule.catalogs;

            var region = App.rootView.mainRegion.currentView.contentRegion;
            var layout = new ManageCatalogsLayout({
                model: new catalogs.model({}, {collection: catalogs}),
                collection: catalogs,
            });

            region.show(layout);
        });
    },
});

module.exports = SettingsController;
