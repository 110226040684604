module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="form-component import-component import-component-review">\n    <div class="row row-slim">\n        <div class="col-2 input-help">\n            <span class="step-number">'+
((__t=( item.step ))==null?'':_.escape(__t))+
'</span>\n            <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n        </div>\n        <div class="col-6">\n            <p class="component-intro">\n                '+
((__t=( t('collection::import:review:text:look-at-object-table') ))==null?'':_.escape(__t))+
'\n                <a href="javascript:;" class="link-border" data-action="save-import">'+
((__t=( t('collection::import:review:link:done') ))==null?'':_.escape(__t))+
'</a>.\n                '+
((__t=( t('collection::import:review:text:fix') ))==null?'':_.escape(__t))+
'\n                <a href="javascript:;" class="link-border" data-action="step-before">'+
((__t=( t('collection::import:review:link:previous-step') ))==null?'':_.escape(__t))+
'</a>.\n            </p>\n        </div>\n    </div>\n    <div class="row import-review-list">\n        <div class="col-12" id="subregion-review-list">\n\n        </div>\n    </div>\n    <div class="row import-review-duplicates">\n        <div class="col-10 offset-2">\n            <p class="component-intro">'+
((__t=(t('collection::import:text:duplciate-key')))==null?'':_.escape(__t))+
'</p>\n\n            <div class="option-select">\n                <input type="radio" name="on-duplicate-key" value="discard" id="import-duplciate-key-discard" checked="checked" />\n                <label for="import-duplciate-key-discard">\n                    '+
((__t=(t('collection::import:label:duplciate-key-discard')))==null?'':_.escape(__t))+
'\n                </label>\n\n                <input type="radio" name="on-duplicate-key" value="update" id="import-duplciate-key-update" />\n                <label for="import-duplciate-key-update">\n                    '+
((__t=(t('collection::import:label:duplciate-key-update')))==null?'':_.escape(__t))+
'\n                </label>\n\n                <input type="radio" name="on-duplicate-key" value="create" id="import-duplciate-key-create" />\n                <label for="import-duplciate-key-create">\n                    '+
((__t=(t('collection::import:label:duplciate-key-create')))==null?'':_.escape(__t))+
'\n                </label>\n            </div>\n        </div>\n    </div>\n\n    <div class="row import-review-submit">\n        <div class="col-10 offset-2">\n            <button type="button" class="btn btn-primary" data-action="save-import">'+
((__t=( t('collection::import:button:save-and-done') ))==null?'':_.escape(__t))+
'</button>\n        </div>\n    </div>\n</div>';
}
return __p;
};