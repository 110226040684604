module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(t('collection::objects:filter:color:title')))==null?'':_.escape(__t))+
'</h3>\n\n<form>\n    <fieldset class="filter-buttons component-filter-buttons">\n        ';
 for (var i = 0; i < item.availableColors.length; i++) { 
__p+='\n            <fieldset class="filter-button">\n                <input type="checkbox"\n                    id="objects-filter-color-'+
((__t=(item.availableColors[i]))==null?'':_.escape(__t))+
'"\n                    value="'+
((__t=(item.availableColors[i]))==null?'':_.escape(__t))+
'"\n                    />\n                <label for="objects-filter-color-'+
((__t=(item.availableColors[i]))==null?'':_.escape(__t))+
'" class="object-color-swatch-'+
((__t=(item.availableColors[i]))==null?'':_.escape(__t))+
'">\n                    '+
((__t=( view.getFilterValueDisplayName(item.availableColors[i]) ))==null?'':_.escape(__t))+
'\n                </label>\n            </fieldset>\n        ';
 } 
__p+='\n    </fieldset>\n</form>';
}
return __p;
};