module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header data-state="view" class="content-header '+
((__t=(view.model.get('search') ? 'search-active' : ''))==null?'':_.escape(__t))+
'">\n    <div class="container-showroom-grid">\n        <div class="header-part header-part-view">\n            ';
 if (view.getOption('backLink') && view.getBackUrl) { 
__p+='\n                <a href="'+
((__t=( view.getBackUrl() ))==null?'':_.escape(__t))+
'" class="btn btn-back">'+
((__t=( icon('prev') ))==null?'':__t)+
'</a>\n            ';
 } 
__p+='\n            <h2>\n                '+
((__t=(view.getHeadline()))==null?'':_.escape(__t))+
'\n            </h2>\n            <h2 class="subline">\n                '+
((__t=(view.getSubline()))==null?'':_.escape(__t))+
'\n            </h2>\n\n            ';
 if (view.getOption('search')){ 
__p+='\n                <div id="search" class="search-region search-header"></div>\n            ';
 } 
__p+='\n        </div>\n\n        ';
 if (view.getOption('showroomOptions')) { 
__p+='\n            <div class="showroom-options-region"></div>\n        ';
 } 
__p+='\n\n        ';
 if (view.getOption('viewSwitch')) { 
__p+='\n            <div class="view-switch-region"></div>\n        ';
 } 
__p+='\n    </div>\n</header>';
}
return __p;
};