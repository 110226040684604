module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="wincode-modal">\n    <header class="modal-header">\n        <ul>\n            <li>\n                <h2>'+
((__t=( t('settings::profile:wincode:headline:please-enter-wincode') ))==null?'':_.escape(__t))+
'</h2>\n            </li>\n            <li class="actions">\n                <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=( t('settings::profile:wincode:button:cancel') ))==null?'':_.escape(__t))+
'</button>\n            </li>\n        </ul>\n    </header>\n\n    <div class="modal-body">\n        <form method="post">\n            <input type="text" name="wincode" placeholder="'+
((__t=( t('settings::profile:wincode:placeholder:type-wincode-code') ))==null?'':_.escape(__t))+
'">\n            <button type="submit" class="btn btn-primary">'+
((__t=( t('settings::profile:wincode:button:verify') ))==null?'':_.escape(__t))+
'</button>\n        </form>\n\n        <div class="wincode-response">\n            <p></p>\n        </div>\n    </div>\n</div>';
}
return __p;
};