module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="create-container page-container">\n    <form method="post" id="createObjectForm" class="form-horizontal" autocomplete="off">\n        <header class="content-header is-sticky">\n            <div class="header-part">\n                <ul>\n                    <li>\n                        <h2>'+
((__t=(item.catalogName))==null?'':_.escape(__t))+
': '+
((__t=(t('collection::create:headline')))==null?'':_.escape(__t))+
'</h2>\n\n                        <!--div class="row row-slim">\n                            <div class="page-col-offset input-help">\n                                <span class="create-step-number"></span>\n                            </div>\n                            <div class="page-col-4">\n                                <div id="region-objectid-selection"></div>\n                            </div>\n                        </div-->\n                    </li>\n                    <li class="actions">\n                        <button type="reset" class="btn btn-secondary btn-secondary-2">\n                            '+
((__t=(t('collection::create:button:cancel')))==null?'':_.escape(__t))+
'\n                        </button>\n                        <div class="btn-submit-wrapper" data-gravity="e">\n                            <button type="button" class="btn btn-primary" disabled data-action="submit">\n                                '+
((__t=(t('collection::create:button:create-and-done')))==null?'':_.escape(__t))+
'\n                            </button>\n                            <div class="btn-submit-wrapper-layer"></div>\n                        </div>\n                    </li>\n                </ul>\n            </div>\n        </header>\n\n        <div class="scroll-container">\n            <div class="container create-container-inner">\n                ';
 _.each(item.fields, function(field){ 
__p+='\n                    <div class="field-'+
((__t=(_.camelToHyphen(field.name)))==null?'':_.escape(__t))+
'-region"></div>\n                ';
 }) 
__p+='\n            </div>\n\n            <div class="container container-submit btn-group">\n                <div class="row">\n                    <div class="page-offset-default page-col-6">\n                        <div class="btn-submit-wrapper" data-gravity="s">\n                            <button type="button" class="btn btn-primary" disabled data-action="submit">'+
((__t=(t('collection::create:button:create-and-done')))==null?'':_.escape(__t))+
'</button>\n                            <div class="btn-submit-wrapper-layer"></div>\n                        </div>\n\n                        <div class="btn-submit-wrapper" data-gravity="s">\n                            <button type="button" class="btn btn-secondary btn-secondary-2" disabled data-action="submit-similar">'+
((__t=(t('collection::create:button:create-and-duplicate')))==null?'':_.escape(__t))+
'</button>\n                            <div class="btn-submit-wrapper-layer"></div>\n                        </div>\n\n                        <div class="btn-submit-wrapper hidden-fields-button" data-gravity="s">\n                            <button type="button" class="btn btn-clean" data-action="hide-hidden-fields">\n                                '+
((__t=(t('collection::create:button:hide-hidden-fields')))==null?'':_.escape(__t))+
'\n                            </button>\n\n                            <button type="button" class="btn btn-clean" data-action="show-hidden-fields">\n                                '+
((__t=(t('collection::create:button:show-hidden-fields')))==null?'':_.escape(__t))+
'\n                            </button>\n\n                            ';
 if (item.hiddenFields.length > 0) { 
__p+='\n                                <span class="tooltip">\n                                    <h3>'+
((__t=( t('collection::edit:general:x-hidden-fields', item.hiddenFields.length) ))==null?'':_.escape(__t))+
'</h3>\n                                    <ul>\n                                        ';
 for (var i in item.hiddenFields) { 
__p+='\n                                            <li>'+
((__t=( item.hiddenFields[i] ))==null?'':_.escape(__t))+
'</li>\n                                        ';
 } 
__p+='\n                                    </ul>\n                                </span>\n                            ';
 } 
__p+='\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </form>\n    <div class="drop-files-overlay">\n        <div class="drop-files-overlay-inner">\n            <span>'+
((__t=( t('collection::edit:drop-image-for-upload') ))==null?'':_.escape(__t))+
'</span>\n        </div>\n    </div>\n</div>';
}
return __p;
};