'use strict';

var CollectionView = require('base/Colido.CollectionView');
var GroupView = require('./group');
var EmptyView = require('./groups-empty');

var GroupsView = CollectionView.extend({
    tagName: 'ul',

    className: 'groups-list',

    type: 'normal',

    emptyView: EmptyView,

    childView: GroupView,

    childViewOptions: function() {
        return {
            type: this.getOption('type'),
            activeConversationId: this.getOption('activeConversationId'),
        };
    },

    filter: function (model) {
        return model.get('type') === this.getOption('type');
    },

    _shouldAddChild: function(child, index) { //fixes double add bug from collectionEvents: add->render
        return CollectionView.prototype._shouldAddChild.apply(this, arguments) && !this.children.findByModel(child);
    },
});

module.exports = GroupsView;