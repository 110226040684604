module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row">\n    <div class="page-col-offset input-help">\n        ';
 if (!item.isGroupChild) { 
__p+='\n            <span class="create-step-number">&nbsp;</span>\n        ';
 } 
__p+='\n        <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n    </div>\n    <div class="page-col-full">\n        ';
 if (!item.isGroupChild || !item.field.editOptions.hideLabelInGroup) { 
__p+='\n            <label class="label-headline">'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</label>\n        ';
 } 
__p+='\n\n        <div class="assets-list-region '+
((__t=(when(item.field.editOptions.hideLabelInGroup, 'label-hidden')))==null?'':_.escape(__t))+
'"></div>\n\n        <div class="assets-buttons">\n            <button type="button" class="btn btn-primary" data-action="choose-files">\n                '+
((__t=( t('collection::edit:image-selection:button-choose-from-disk') ))==null?'':_.escape(__t))+
'\n            </button>\n            <label for="uplFileSelect-'+
((__t=(view.assetUploadView.cid))==null?'':_.escape(__t))+
'" class="btn btn-primary iOsUplBtn">\n                '+
((__t=( t('collection::edit:image-selection:button-choose-from-disk') ))==null?'':_.escape(__t))+
'\n            </label>\n            <button type="button" class="btn btn-primary" data-action="take-picture">\n                '+
((__t=( t('collection::edit:image-selection:button-take-picture') ))==null?'':_.escape(__t))+
'\n            </button>\n\n            ';
 if (Colido.config.usePhotobox) { 
__p+='\n                <button type="button" class="btn btn-primary" data-action="import-photobox">\n                    '+
((__t=( t('collection::edit:image-selection:button-import-photobox') ))==null?'':_.escape(__t))+
'\n                </button>\n            ';
 } 
__p+='\n\n            ';
 if (Colido.config.useDropbox) { 
__p+='\n                <button type="button" class="btn btn-primary '+
((__t=(when(view.isDropboxLoaded(), 'dropbox-ready')))==null?'':_.escape(__t))+
'" data-action="import-from-dropbox">\n                    '+
((__t=( icon('dropbox') ))==null?'':__t)+
'\n                    <span>\n                        '+
((__t=( t('collection::edit:image-selection:button-import-from-dropbox') ))==null?'':_.escape(__t))+
'\n                    </span>\n                </button>\n            ';
 } 
__p+='\n        </div>\n\n        <div class="images-options">\n            <label>\n                '+
((__t=(t('collection::edit:image-size:headline')))==null?'':_.escape(__t))+
'\n            </label>\n\n            <span class="option-select">\n                <input name="image-resize" id="image-resize-full" type="radio" value="full" checked="checked">\n                <label for="image-resize-full" class="option-select-secondary">'+
((__t=(t('collection::edit:image-size:size:label:full')))==null?'':_.escape(__t))+
'</label>\n\n                <input name="image-resize" id="image-resize-large" type="radio" value="large">\n                <label for="image-resize-large" class="option-select-secondary">'+
((__t=(t('collection::edit:image-size:size:label:large')))==null?'':_.escape(__t))+
'</label>\n\n                <input name="image-resize" id="image-resize-medium" type="radio" value="medium">\n                <label for="image-resize-medium" class="option-select-secondary">'+
((__t=(t('collection::edit:image-size:size:label:medium')))==null?'':_.escape(__t))+
'</label>\n\n                <input name="image-resize" id="image-resize-small" type="radio" value="small">\n                <label for="image-resize-small" class="option-select-secondary">'+
((__t=(t('collection::edit:image-size:size:label:small')))==null?'':_.escape(__t))+
'</label>\n            </span>\n\n            <p class="hint">\n                '+
((__t=( nl2br(t('collection::edit:image-size:hint'))))==null?'':__t)+
'\n            </p>\n        </div>\n    </div>\n</div>';
}
return __p;
};