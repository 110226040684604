module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header ">\n    <div class="header-part-view">\n        <h2>'+
((__t=(t('dashboard::headline')))==null?'':_.escape(__t))+
'</h2>\n        <h2 class="subline">'+
((__t=(t('dashboard::subline:welcome', {name: user.fullname})))==null?'':_.escape(__t))+
'</h2>\n    </div>\n</header>\n\n<section class="container container-imports">\n    <header>\n        <h3>'+
((__t=(t('dashboard::headline:imports-in-progress')))==null?'':_.escape(__t))+
'</h3>\n        <a href="'+
((__t=(item.catalogBasePath))==null?'':_.escape(__t))+
'/imports" class="btn btn-primary">\n            '+
((__t=( icon('next-small')))==null?'':__t)+
'\n        </a>\n    </header>\n    <div class="dashboard-imports-region"></div>\n</section>\n\n<!--div class="container dashboard-new">\n    <a href="/new" class="btn btn-add btn-dashed btn-row">\n        '+
((__t=(t('dashboard::button:new-object')))==null?'':_.escape(__t))+
' '+
((__t=( icon('add-small')))==null?'':__t)+
'\n    </a>\n\n    <a href="/import" class="btn btn-add btn-dashed btn-row">\n        '+
((__t=(t('dashboard::button:import-database')))==null?'':_.escape(__t))+
' '+
((__t=( icon('add-small')))==null?'':__t)+
'\n    </a>\n\n    <a href="/collection" class="btn btn-primary btn-row">\n        '+
((__t=(t('dashboard::button:collection')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small')))==null?'':__t)+
'\n    </a>\n</div-->\n\n';
 if (App.CommunityModule) { 
__p+='\n    <section class="container container-conversations">\n        <header>\n            <h3>'+
((__t=(t('dashboard::headline:conversations', App.CommunityModule.conversationsCollection.length)))==null?'':_.escape(__t))+
'</h3>\n            <a href="/community/contacts" class="btn btn-primary">\n                '+
((__t=(t('dashboard::button:conversations')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small')))==null?'':__t)+
'\n            </a>\n        </header>\n        <div class="dashboard-conversations-region"></div>\n    </section>\n';
 } 
__p+='\n\n<section class="container container-collection">\n    <header>\n        <h3>'+
((__t=(t('dashboard::headline:collection')))==null?'':_.escape(__t))+
'</h3>\n        <a href="'+
((__t=(item.catalogBasePath))==null?'':_.escape(__t))+
'" class="btn btn-primary">\n            '+
((__t=(t('dashboard::button:collection')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small')))==null?'':__t)+
'\n        </a>\n    </header>\n    <div class="dashboard-collection-region"></div>\n</section>\n\n<section class="container container-sets">\n    <header>\n        <h3>'+
((__t=(t('dashboard::headline:sets')))==null?'':_.escape(__t))+
'</h3>\n        <a href="'+
((__t=(item.catalogBasePath))==null?'':_.escape(__t))+
'/sets" class="btn btn-primary">\n            '+
((__t=(t('dashboard::button:sets')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small')))==null?'':__t)+
'\n        </a>\n    </header>\n    <div class="dashboard-sets-region"></div>\n</section>\n\n<section class="container container-storages">\n    <header>\n        <h3>'+
((__t=(t('dashboard::headline:storages')))==null?'':_.escape(__t))+
'</h3>\n        <a href="'+
((__t=(item.catalogBasePath))==null?'':_.escape(__t))+
'/storages" class="btn btn-primary">\n            '+
((__t=(t('dashboard::button:storages')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small')))==null?'':__t)+
'\n        </a>\n    </header>\n    <div class="dashboard-storages-region"></div>\n</section>';
}
return __p;
};