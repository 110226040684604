module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>'+
((__t=(t('subscription::setup:plan-display:title')))==null?'':_.escape(__t))+
'</h2>\n\n<table class="table subscription-booked-plans ';
 if (item.voucherValid) { 
__p+='has-voucher';
 } 
__p+='">\n    <thead>\n        <tr>\n            <th>'+
((__t=(t('subscription::setup:plan-display:table:plan')))==null?'':_.escape(__t))+
'</th>\n            ';
 if (view.getOption('isSetup') === false && item.willSwitchPlan) { 
__p+='\n                <th>'+
((__t=(t('subscription::setup:plan-display:table:status')))==null?'':_.escape(__t))+
'</th>\n            ';
 } 
__p+='\n            <th>'+
((__t=(t('subscription::setup:plan-display:table:billing')))==null?'':_.escape(__t))+
'</th>\n            <th class="number">'+
((__t=(t('subscription::setup:plan-display:table:price')))==null?'':_.escape(__t))+
'</th>\n            <th class="number">'+
((__t=(t('subscription::setup:plan-display:table:tax')))==null?'':_.escape(__t))+
'</th>\n            <th class="number">'+
((__t=(t('subscription::setup:plan-display:table:total')))==null?'':_.escape(__t))+
'</th>\n        </tr>\n    </thead>\n    <tbody>\n        ';
 if (view.getOption('isSetup') === true) { 
__p+='\n            <tr>\n                <th>'+
((__t=(item.selectedPlan.name))==null?'':_.escape(__t))+
'</th>\n                <td>'+
((__t=(t('subscription::setup:plan-display:table:billingInterval:'+item.upcomingBillingInterval)))==null?'':_.escape(__t))+
'</td>\n                <td class="number">'+
((__t=(currency(item.selectedPlan.amount, item.currency)))==null?'':_.escape(__t))+
'</td>\n                <td class="number">'+
((__t=(currency(item.selectedPlan.amountVat, item.currency)))==null?'':_.escape(__t))+
'</td>\n                <td class="number">'+
((__t=(currency(item.selectedPlan.amountTotal, item.currency)))==null?'':_.escape(__t))+
'</td>\n            </tr>\n        ';
 } else { 
__p+='\n            <tr>\n                <th>'+
((__t=(item.activePlan.name))==null?'':_.escape(__t))+
'</th>\n                ';
 if (item.willSwitchPlan) { 
__p+='\n                    <td class="status">\n                        '+
((__t=(t('subscription::setup:plan-display:table:status-active')))==null?'':_.escape(__t))+
'\n                        '+
((__t=( icon('info') ))==null?'':__t)+
'\n                        <span class="tooltip">\n                            '+
((__t=(t('subscription::setup:plan-display:table:active-until', {endDate: date(item.activePlan.validUntil)})))==null?'':_.escape(__t))+
'\n                        </span>\n                    </td>\n                ';
 } 
__p+='\n                <td>'+
((__t=(t('subscription::setup:plan-display:table:billingInterval:'+item.currentBillingInterval)))==null?'':_.escape(__t))+
'</td>\n                <td class="number">'+
((__t=(currency(item.activePlan.amount, item.currency)))==null?'':_.escape(__t))+
'</td>\n                <td class="number">'+
((__t=(currency(item.activePlan.amountVat, item.currency)))==null?'':_.escape(__t))+
'</td>\n                <td class="number">'+
((__t=(currency(item.activePlan.amountTotal, item.currency)))==null?'':_.escape(__t))+
'</td>\n            </tr>\n            ';
 if (item.willSwitchPlan) { 
__p+='\n                <tr>\n                    <th>'+
((__t=(item.selectedPlan.name))==null?'':_.escape(__t))+
'</th>\n                    <td class="status">\n                        '+
((__t=(t('subscription::setup:plan-display:table:status-upcoming')))==null?'':_.escape(__t))+
'\n                        '+
((__t=( icon('info') ))==null?'':__t)+
'\n                        <span class="tooltip">\n                            '+
((__t=(t('subscription::setup:plan-display:table:active-from', {startDate: date(item.activePlan.validUntil + 24 * 60 * 60)})))==null?'':_.escape(__t))+
'\n                        </span>\n                    </td>\n                    <td>'+
((__t=(t('subscription::setup:plan-display:table:billingInterval:'+item.upcomingBillingInterval)))==null?'':_.escape(__t))+
'</td>\n                    <td class="number">'+
((__t=(currency(item.selectedPlan.amount, item.currency)))==null?'':_.escape(__t))+
'</td>\n                    <td class="number">'+
((__t=(currency(item.selectedPlan.amountVat, item.currency)))==null?'':_.escape(__t))+
'</td>\n                    <td class="number">'+
((__t=(currency(item.selectedPlan.amountTotal, item.currency)))==null?'':_.escape(__t))+
'</td>\n                </tr>\n            ';
 } 
__p+='\n        ';
 } 
__p+='\n    </tbody>\n    ';
 if (!item.voucherValid) { 
__p+='\n        <tfoot>\n            <tr>\n                <td colspan="'+
((__t=(ifelse(view.getOption('isSetup'), 5, 6)))==null?'':_.escape(__t))+
'">\n                    <p class="tax-hint">\n                        '+
((__t=(t('subscription::setup:plan-display:table:tax-country')))==null?'':_.escape(__t))+
':\n                        <strong>\n                            ';
 if (item.countryCollection.get(item.taxCountry)) { 
__p+='\n                                '+
((__t=(item.countryCollection.get(item.taxCountry).get('name')))==null?'':_.escape(__t))+
'\n                            ';
 } 
__p+='\n                        </strong>\n                        <!--button>'+
((__t=(t('subscription::setup:plan-display:table:learn-more')))==null?'':_.escape(__t))+
'</button-->\n                    </p>\n                </td>\n            </tr>\n        </tfoot>\n    ';
 } 
__p+='\n</table>\n\n';
 if (item.voucherValid) { 
__p+='\n    <table class="table subscription-applied-voucher">\n        <thead>\n            <tr>\n                <th colspan="2">'+
((__t=(t('subscription::setup:plan-display:table:first-year-discount-price')))==null?'':_.escape(__t))+
'</th>\n                <th class="number">'+
((__t=(t('subscription::setup:plan-display:table:price')))==null?'':_.escape(__t))+
'</th>\n                <th class="number">'+
((__t=(t('subscription::setup:plan-display:table:tax')))==null?'':_.escape(__t))+
'</th>\n                <th class="number">'+
((__t=(t('subscription::setup:plan-display:table:total')))==null?'':_.escape(__t))+
'</th>\n            </tr>\n        </thead>\n        <tbody>\n            <tr>\n                <th>'+
((__t=(item.selectedPlan.name))==null?'':_.escape(__t))+
'</th>\n                <td>'+
((__t=(t('subscription::setup:plan-display:table:including-50-discount')))==null?'':_.escape(__t))+
'</td>\n                <td class="number">'+
((__t=(currency(item.discountAmount, item.currency)))==null?'':_.escape(__t))+
'</td>\n                <td class="number">'+
((__t=(currency(item.discountAmountVat, item.currency)))==null?'':_.escape(__t))+
'</td>\n                <td class="number">'+
((__t=(currency(item.discountAmountTotal, item.currency)))==null?'':_.escape(__t))+
'</td>\n            </tr>\n        </tbody>\n        <tfoot>\n            <tr>\n                <td colspan="'+
((__t=(ifelse(view.getOption('isSetup'), 5, 6)))==null?'':_.escape(__t))+
'">\n                    <p class="tax-hint">\n                        '+
((__t=(t('subscription::setup:plan-display:table:tax-country')))==null?'':_.escape(__t))+
':\n                        <strong>\n                        ';
 if (item.countryCollection.get(item.taxCountry)) { 
__p+='\n                            '+
((__t=(item.countryCollection.get(item.taxCountry).get('name')))==null?'':_.escape(__t))+
'\n                        ';
 } 
__p+='\n                        </strong>\n                    </p>\n                </td>\n            </tr>\n        </tfoot>\n    </table>\n';
 } 
__p+='\n\n';
 if (view.getOption('isSetup') === false) { 
__p+='\n    <div class="subscription-booked-plan-features box">\n        <h4>'+
((__t=(item.activePlan.name))==null?'':_.escape(__t))+
'</h4>\n        <h4 class="subline">'+
((__t=(item.activePlan.subline))==null?'':_.escape(__t))+
'</h4>\n        '+
((__t=( list(item.activePlan.description, 'ul')))==null?'':__t)+
'\n        <span class="status">'+
((__t=(t('subscription::plan:text:active')))==null?'':_.escape(__t))+
'</span>\n    </div>\n\n    <div class="hint">\n        <p>\n            '+
((__t=( t('subscription::plan:text:currently-active', {planName: item.activePlan.name})))==null?'':__t)+
'\n        </p>\n\n        ';
 if (item.activePlan.id === item.selectedPlan.id) { 
__p+='\n            ';
 if (item.activePlan.amountTotal > 0) { 
__p+='\n                <p>\n                    '+
((__t=( t('subscription::plan:text:renewal-' + item.upcomingBillingInterval, {validUntil: date(item.activePlan.validUntil), amount: currency(item.selectedPlan.amountTotal, item.currency)})))==null?'':__t)+
'\n                </p>\n            ';
 } 
__p+='\n        ';
 } else { 
__p+='\n            <p>'+
((__t=( t('subscription::plan:text:upcoming-plan', {validUntil: date(item.activePlan.validUntil), upcomingPlanName: item.selectedPlan.name})))==null?'':__t)+
'</p>\n        ';
 } 
__p+='\n    </div>\n';
 } 
__p+='\n\n\n<div class="actions">\n    <button class="btn btn-primary" data-action="edit">'+
((__t=(t('subscription::setup:plan-display:change-plan')))==null?'':_.escape(__t))+
'</button>\n\n    ';
 if (view.getOption('isSetup') === true && !item.voucherValid) { 
__p+='\n        <button class="btn btn-primary" data-action="enter-voucher">'+
((__t=(t('subscription::setup:plan-display:table:enter-voucher-code')))==null?'':_.escape(__t))+
'</button>\n    ';
 } 
__p+='\n</div>';
}
return __p;
};