module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="drop-target drop-target-'+
((__t=( size ))==null?'':_.escape(__t))+
'" data-action="'+
((__t=( action ))==null?'':_.escape(__t))+
'">\n    <div class="label-wrapper">\n        <label>\n            '+
((__t=( icon(iconName) ))==null?'':__t)+
' '+
((__t=( label ))==null?'':_.escape(__t))+
'\n        </label>\n    </div>\n</div>';
}
return __p;
};