module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<section class="main-info">\n    <div class="meta-wrapper">\n        ';
 if (item.isShop) { 
__p+='\n            <div class="meta-info">\n                ';
 if (item.summarySlot1) { 
__p+='\n                    <span class="object-custom-id">'+
((__t=( item.summarySlot1))==null?'':__t)+
'</span>\n                ';
 } 
__p+='\n\n                <span class="object-actions">\n                    ';
 if (item.sellerProfile && item.sellerProfile.sellerProfile && 'commercial' == item.sellerProfile.sellerProfile) { 
__p+='\n                        <button class="btn btn-secondary" data-action="seller">'+
((__t=( item.sellerFullname))==null?'':__t)+
'</button>\n                    ';
 } else { 
__p+='\n                        '+
((__t=( item.sellerFullname))==null?'':__t)+
'\n                    ';
 } 
__p+='\n                    <button class="btn btn-primary btn-buy" data-action="buy">'+
((__t=(t('collection::shop:object:details:buy:label')))==null?'':_.escape(__t))+
'</button>\n                </span>\n            </div>\n        ';
 } else if (item.isShowroom) { 
__p+='\n            ';
 if (item.summarySlot1 || item.summarySlot2 || item.summarySlot3 || item.summarySlot4) { 
__p+='\n                <div class="meta-info">\n                    ';
 if (item.summarySlot1) { 
__p+='\n                        <span class="object-custom-id">'+
((__t=( item.summarySlot1))==null?'':__t)+
'</span>\n                    ';
 } 
__p+='\n\n                    ';
 if (item.summarySlot2) { 
__p+='\n                        <span>'+
((__t=( item.summarySlot2))==null?'':__t)+
'</span>\n                    ';
 } 
__p+='\n\n                    ';
 if (item.summarySlot3) { 
__p+='\n                        <span>'+
((__t=( item.summarySlot3))==null?'':__t)+
'</span>\n                    ';
 } 
__p+='\n\n                    ';
 if (item.summarySlot4) { 
__p+='\n                        <span>'+
((__t=( item.summarySlot4))==null?'':__t)+
'</span>\n                    ';
 } 
__p+='\n                </div>\n            ';
 } 
__p+='\n\n            <div class="pager">\n                ';
 if (view.getOption('prevLinkUrl')){ 
__p+='\n                    <a href="'+
((__t=(view.getOption('prevLinkUrl')))==null?'':_.escape(__t))+
'" rel="prev">'+
((__t=( icon('prev-small') ))==null?'':__t)+
'</a>\n                ';
 } else { 
__p+='\n                    <a disabled>'+
((__t=( icon('prev') ))==null?'':__t)+
'</a>\n                ';
 } 
__p+='\n\n                ';
 if (view.getOption('nextLinkUrl')){ 
__p+='\n                    <a href="'+
((__t=(view.getOption('nextLinkUrl')))==null?'':_.escape(__t))+
'" rel="next">'+
((__t=( icon('next-small') ))==null?'':__t)+
'</a>\n                ';
 } else { 
__p+='\n                    <a disabled>'+
((__t=( icon('next-small') ))==null?'':__t)+
'</a>\n                ';
 } 
__p+='\n            </div>\n        ';
 } else { 
__p+='\n            <div class="meta-info">\n                ';
 if (item.summarySlot1) { 
__p+='\n                    <span class="object-custom-id">'+
((__t=( item.summarySlot1))==null?'':__t)+
'</span>\n                ';
 } 
__p+='\n                <div class="action-slot-1-region"></div>\n\n                <span class="object-actions">\n                    <button class="btn btn-secondary btn-caret" data-action="show-actions">'+
((__t=(t('collection::object:details:actions:label')))==null?'':_.escape(__t))+
'</button>\n                </span>\n            </div>\n        ';
 } 
__p+='\n    </div>\n\n    <div class="headline-wrapper">\n        <h1>'+
((__t=( item.headline || t('collection::object:emptyName')))==null?'':__t)+
'</h1>\n        ';
 if (item.subheadline) { 
__p+='\n            <h2 class="subline">\n                '+
((__t=( item.subheadline))==null?'':__t)+
'\n            </h2>\n        ';
 } 
__p+='\n    </div>\n</section>\n\n<section class="more-info">\n    ';
 if ((item.summarySlot2 || item.summarySlot3|| item.summarySlot4) && !item.isShowroom) { 
__p+='\n        <section class="meta-info">\n            ';
 if (item.summarySlot2) { 
__p+='\n                <span>'+
((__t=( item.summarySlot2))==null?'':__t)+
'</span>\n            ';
 } 
__p+='\n\n            ';
 if (item.summarySlot3) { 
__p+='\n                <span>'+
((__t=( item.summarySlot3))==null?'':__t)+
'</span>\n            ';
 } 
__p+='\n\n            ';
 if (item.summarySlot4) { 
__p+='\n                <span>'+
((__t=( item.summarySlot4))==null?'':__t)+
'</span>\n            ';
 } 
__p+='\n        </section>\n    ';
 } 
__p+='\n\n    ';
 _.each(item.fields, function(sectionFields){ 
__p+='\n        ';
 //dont add whitespace here. we make use of :empty selector in css
__p+='\n        <section>';
 _.each(sectionFields, function(field){ 
__p+='<div class="field-'+
((__t=(_.camelToHyphen(field.name)))==null?'':_.escape(__t))+
'-region component-region component-'+
((__t=(_.camelToHyphen(field.type)))==null?'':_.escape(__t))+
'-region"></div>';
 }) 
__p+='</section>\n    ';
 }) 
__p+='\n</section>\n\n';
 if (!item.isShowroom) { 
__p+='\n    <div class="action-layer action-layer-actions">\n        <h3>'+
((__t=( t('collection::object:action:headline') ))==null?'':_.escape(__t))+
'</h3>\n        <button type="button" class="btn btn-close">'+
((__t=( icon('close-light') ))==null?'':__t)+
'</button>\n        <ul>\n            <li>\n                <button data-action="edit-object">\n                    '+
((__t=( t('collection::object:action:edit') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n            <li>\n                <button data-action="add-to-set">\n                    '+
((__t=( t('collection::object:action:add-to-set') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n            <li '+
((__t=( when(!App.user.get('licenseStorages'), 'class="restricted"')))==null?'':__t)+
'>\n                <button data-action="choose-storage">\n                    '+
((__t=( t('collection::object:action:choose-storage') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n            <li>\n                <button data-action="change-status">\n                    '+
((__t=( t('collection::object:action:change-status') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n            <li>\n                <button data-action="print-objects-pdf">\n                    '+
((__t=(t('collection::objects:view:button:print-list')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n            <li>\n                <button data-action="print-objects-label">\n                    '+
((__t=(t('collection::objects:view:button:print-label', 1)))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n            <li>\n                <button data-action="duplicate-object">\n                    '+
((__t=( t('collection::object:action:duplicate') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n\n            ';
 if (App.user.get('licenseSeller') && App.user.get('showShop')) { 
__p+='\n                <li>\n                    <button data-action="sell">\n                        '+
((__t=( ifelse(item.forSale, t('collection::shop:object:action:withdraw-from-sale'), t('collection::shop:object:action:sell')) ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                    </button>\n                </li>\n            ';
 } 
__p+='\n            <li class="action-layer-item-space">\n                <button data-action="delete-object">\n                    '+
((__t=( t('collection::object:action:delete') ))==null?'':_.escape(__t))+
'\n                </button>\n            </li>\n        </ul>\n    </div>\n';
 } 
__p+='';
}
return __p;
};