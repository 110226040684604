module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form>\n    <header class="modal-header">\n        <ul>\n            <li>\n                <h2>'+
((__t=(t('print-modal::advanced-modal-title')))==null?'':_.escape(__t))+
'</h2>\n                <h2 class="subline">\n                    '+
((__t=(t('print-modal::modal-subtitle:object-count', view.collection.length)))==null?'':_.escape(__t))+
'\n                </h2>\n            </li>\n            <li class="actions">\n                <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('print-modal::abort-button')))==null?'':_.escape(__t))+
'</button>\n                <button type="submit" class="btn btn-primary">'+
((__t=(t('print-modal::generate-button')))==null?'':_.escape(__t))+
'</button>\n            </li>\n        </ul>\n    </header>\n    <div class="modal-body advanced-print-modal">\n        <div class="row row-reduced-margin">\n            <div class="page-col-3">\n                <label class="label-headline">'+
((__t=(t('print-modal::form:title')))==null?'':_.escape(__t))+
'</label>\n\n                <input type="text" placeholder="'+
((__t=(t('print-modal::form:title-placeholder')))==null?'':_.escape(__t))+
'" name="title" />\n            </div>\n        </div>\n        <div class="row row-reduced-margin">\n\n\n            <div class="page-col-2">\n                <label class="label-headline">'+
((__t=(t('print-modal::form:print-summery')))==null?'':_.escape(__t))+
'</label>\n\n                <div class="option-select">\n                    <input id="print-summery-yes" type="radio" name="printSummery" value="1" checked>\n                    <label for="print-summery-yes" class="option-select-secondary">'+
((__t=(t('print-modal::form:print-summery-yes')))==null?'':_.escape(__t))+
'</label>\n\n                    <input id="print-summery-no" type="radio" name="printSummery" value="0">\n                    <label for="print-summery-no" class="option-select-secondary">'+
((__t=(t('print-modal::form:print-summery-no')))==null?'':_.escape(__t))+
'</label>\n                </div>\n            </div>\n\n            <div class="page-col-3">\n                <label class="label-headline">'+
((__t=(t('print-modal::form:items-per-page')))==null?'':_.escape(__t))+
'</label>\n\n                <div class="option-select">\n                    <input id="print-itemsPerPage-fit" type="radio" name="itemsPerPage" value="fit" checked>\n                    <label for="print-itemsPerPage-fit" class="option-select-secondary">'+
((__t=(t('print-modal::form:fit')))==null?'':_.escape(__t))+
'</label>\n\n                    <input id="print-itemsPerPage-single" type="radio" name="itemsPerPage" value="single">\n                    <label for="print-itemsPerPage-single" class="option-select-secondary">'+
((__t=(t('print-modal::form:single')))==null?'':_.escape(__t))+
'</label>\n                </div>\n            </div>\n\n        </div>\n\n        <div class="row row-reduced-margin table-columns-region">\n            <div class="page-col-full">\n                <label class="label-headline">\n                    '+
((__t=(t('print-modal::form:printing-options')))==null?'':_.escape(__t))+
'\n                </label>\n            </div>\n\n            <div class="page-col-3">\n                <ol class="table-columns dd dd-list">\n                    ';
 _.each(item.printFields, function(field){ 
__p+='\n                        <li class="dd-item dd-item-never-handle">\n                            <input\n                                type="checkbox"\n                                id="toggle-table-field-'+
((__t=(field.fieldName))==null?'':_.escape(__t))+
'"\n                                value="'+
((__t=(field.fieldName))==null?'':_.escape(__t))+
'"\n                                '+
((__t=(when(field.active, 'checked="checked"')))==null?'':_.escape(__t))+
'\n                                '+
((__t=(when(!field.deactivatable, 'disabled="disabled"')))==null?'':_.escape(__t))+
'\n                            />\n                            <label class="toggle" for="toggle-table-field-'+
((__t=(field.fieldName))==null?'':_.escape(__t))+
'">\n                                '+
((__t=(field.label))==null?'':_.escape(__t))+
'\n                            </label>\n                        </li>\n                    ';
 }) 
__p+='\n                </ol>\n            </div>\n\n            <div class="page-col-2">\n                <fieldset class="form-note">\n                    <p>'+
((__t=(t('print-modal::form:printing-options:hint')))==null?'':_.escape(__t))+
'</p>\n                </fieldset>\n            </div>\n        </div>\n    </div>\n</form>';
}
return __p;
};