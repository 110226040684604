module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h4>'+
((__t=(item.name))==null?'':_.escape(__t))+
'</h4>\n'+
((__t=( icon('deposit') ))==null?'':__t)+
'';
}
return __p;
};