module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="'+
((__t=(model.get('conversationUrl')))==null?'':_.escape(__t))+
'" class="open-conversation">\n    <div class="posts-indicator '+
((__t=(when(item.unreadPostsCount, 'unread-posts')))==null?'':_.escape(__t))+
'" title="'+
((__t=(item.unreadPostsCount))==null?'':_.escape(__t))+
'">\n        ';
 if (item.unreadPostsCount >= 9) { 
__p+='\n            <span class="unread-posts-count">9+</span>\n        ';
 } else if(item.unreadPostsCount >= 1) { 
__p+='\n            <span class="unread-posts-count">'+
((__t=(item.unreadPostsCount))==null?'':_.escape(__t))+
'</span>\n        ';
 } 
__p+='\n    </div>\n\n    <div class="group-image">\n        ';
 _.each(item.users, function(user, index){ 
__p+='\n            ';
 if (index < 4) { 
__p+='\n                <div class="contact-image">\n                    <span class="contact-initials">'+
((__t=(_.initials(user.name)))==null?'':_.escape(__t))+
'</span>\n                    '+
((__t=( responsiveImage(user.profilePicture, imageSize('thumbnailFixed'), viewportSize('grid-object'), user.name, 'grid-image') ))==null?'':__t)+
'\n                </div>\n            ';
 } 
__p+='\n        ';
 }) 
__p+='\n    </div>\n\n    <div class="infos">\n        <h2 class=\'group-name\'>'+
((__t=(item.name))==null?'':_.escape(__t))+
'</h2>\n\n        ';
 if (model.get('conversationLastMessage')) { 
__p+='\n            <div class="posts-last-message '+
((__t=(when(item.unreadPostsCount, 'is-unread')))==null?'':_.escape(__t))+
'">\n                '+
((__t=( model.get('conversationLastMessage') ))==null?'':_.escape(__t))+
'\n            </div>\n        ';
 } 
__p+='\n    </div>\n\n    ';
 if (item.isActive) { 
__p+='\n        '+
((__t=( icon('next')))==null?'':__t)+
'\n    ';
 } 
__p+='\n</a>\n\n<div class="extras">\n    <div class="group-members-region"></div>\n    <div class="group-actions-region"></div>\n</div>';
}
return __p;
};