'use strict';

var Backbone = require('backbone');
var Wreqr = require('base/Colido.Wreqr');
var SetModel = require('./models/set');
var ShareModel = require('modules/share/models/share');

var SetModuleWreqr = Wreqr.extend({
    name: 'collection:set',

    /**
     * Deletes a single object or the selected objects in a collection.
     * @param {SetModel} setModel The set Model, that should be deleted
     */
    commandDelete: function(setModel) {
        if (setModel instanceof SetModel) {
            var catalog = setModel.collection.catalog;

            setModel.destroy({
                error: function(model, errorObject){
                    Messenger().error({
                        message: errorObject.responseJSON.message,
                    });
                },
                success: function(){
                    Messenger().info({
                        message: App.t('collection::set:response:deleted', {name: _.escape(setModel.get('name'))}),
                        actions: {
                            trash: {
                                label: App.t('settings::trash:show-trash-button'),
                                action: function() {
                                    App.router.navigate('/catalogs/' + catalog.id + '/trash', {trigger: true});
                                },
                            },
                        },
                    });
                },
            });
        }
    },

    /**
     * Creates a new set for the given name
     * @param {string} name   The desired name of the set
     * @option {boolean} default: true
     */
    commandCreateSet: function(catalog, name, showMessage) {
        if (_.isUndefined(showMessage)) {
            showMessage = true;
        }

        catalog.sets.create({
            name: name,
        }, {
            wait: true,
            success: function(model) {
                //var collection = model.collection;
                //collection.sortByFields();

                // Trigger creation of set
                App.vent.trigger('collection:set-created', model);

                if (showMessage) {
                    Messenger().post({
                        type: 'success',
                        message: App.t('collection::set:response:add:success', {name: _.escape(model.get('name'))}),
                        actions: {
                            show: {
                                label: App.t('collection::set:response:add:show'),
                                action: function() {
                                    App.router.navigate('/catalogs/' + catalog.id + '/sets/' + model.get('id'), {trigger: true});
                                },
                            },
                        },
                    });
                }
            },
            error: function(){
                Messenger().error({
                    message: App.t('collection::set:response:add:error', {name: _.escape(name)}),
                });
            },
        });
    },

    /**
     * Resolves the share URL for the given Set ID
     * @param {Number} id      ID of the set
     * @param {Object} options Options for callbacks
     */
    commandResolveShareUrl: function(catalog, id, options) {
        options = options || {};

        var setModel = catalog.sets.get(id);
        var successCallback = options.success || null;
        var errorCallback = options.error || null;

        if (setModel && setModel instanceof SetModel && setModel.isShared()) {
            var shareModel = new ShareModel({
                token: setModel.get('shareToken'),
                catalog: catalog,
            });
            shareModel.fetch({
                success: function() {
                    if (_.isFunction(successCallback)) {
                        successCallback.call(this, shareModel.get('url'), shareModel);
                    }
                }.bind(this),
                error: function() {
                    if (_.isFunction(errorCallback)) {
                        errorCallback.call(this, shareModel);
                    }
                }.bind(this),
            });
        }
    },

    /**
     * Command for sharing an unshared set
     * @param {Number} id      ID of the set
     * @param {Object} options Options for callbacks
     */
    commandShareSet: function(catalog, id, options) {
        options = options || {};

        var setModel = catalog.sets.get(id);
        var successCallback = options.success || null;
        var errorCallback = options.error || null;

        if (setModel && setModel instanceof SetModel) {
            if (!setModel.isShared()) {
                // Create new share model
                var shareModel = new ShareModel({
                    type: 'set',
                    typeId: id,
                    catalog: catalog,
                });

                // Save share model
                shareModel.save({}, {
                    wait: true,
                    success: function(model) {
                        var shareToken = model.id;
                        setModel.save({shareToken: shareToken}, {patch: true});

                        if (_.isFunction(successCallback)) {
                            successCallback.call(this, shareModel.get('url'), shareModel);
                        }
                    }.bind(this),
                    error: function() {
                        if (_.isFunction(errorCallback)) {
                            errorCallback.call(this, arguments);
                        }
                    }.bind(this),
                });
            } else { // Just resolve URL
                this.commandResolveShareUrl(id, options);
            }
        }
    },
});

module.exports = SetModuleWreqr;