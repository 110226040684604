module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\n    <h2>'+
((__t=(t('settings::label')))==null?'':_.escape(__t))+
': '+
((__t=(t('subscription::overview:title')))==null?'':_.escape(__t))+
'</h2>\n</header>\n\n<div class="container container-dark">\n    <section class="subscription-details-quota-region"></section>\n</div>\n\n<div class="container">\n    <section class="subscription-details-plan-display-region"></section>\n    <section class="subscription-details-payment-method-display-region"></section>\n    <section class="subscription-details-billing-address-display-region"></section>\n    <section class="subscription-details-receipt-list-region"></section>\n\n    <section class="subscription-details-empty-region"></section>\n    <section class="subscription-details-contact">\n        <h2>'+
((__t=(t('subscription::help:title')))==null?'':_.escape(__t))+
'</h2>\n        <p class="hint">\n            '+
((__t=( t('subscription::help:text')))==null?'':__t)+
'\n        </p>\n    </section>\n</div>';
}
return __p;
};