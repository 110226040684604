'use strict';

var Collection = require('base/Colido.Collection');
var TrashModel = require('../models/trash');

var TrashCollection = Collection.extend({
    api: function() {
        var api = '/trash';

        if (this.catalog) {
            api =  this.catalog.api + '/' + this.catalog.id + '/trash';
        }

        return api;
    },

    getName: function() {
        var name = 'collection:trash';

        if (this.catalog) {
            name =  this.catalog.collection.name + ':' + this.catalog.id + ':trash';
        }

        return name;
    },

    name: 'collection:trash',

    model: TrashModel,

    initialize: function(models, options) {
        options = options || {};

        if (!options.catalog) {
            return Colido.config.debug && console.log('You have to provide a catalog model as option when initializing a trash collection');
        }

        this.catalog = options.catalog;

        this.name = this.getName();

        this.listenTo(App.radio(this.name).vent, 'updated', function(data) {
            this.setFromWebsocket(data, {remove: false});
        }.bind(this));

        this.listenTo(App.radio(this.name).vent, 'removed', function(data) {
            this.remove(data); //data [1,2,3]
        }.bind(this));

        this.listenTo(App.radio(this.name).vent, 'added', function(data) {
            this.add(data);
        }.bind(this))
    },

    comparator: function(item) {
        // show the newest first
        return -item.get('timestamp');
    },

    /**
     * Deletes all Data from the Trash
     * @param success
     * @param error
     */
    emptyTrash: function (success, error) {
        $.ajax({
            url: _.result(this, 'url'),
            method: 'DELETE',
            contentType: 'application/json',
            success: function (data) {
                this.reset();

                if (_.isFunction(success)) {
                    success(data);
                }
            }.bind(this),
            error: function (data) {
                if (_.isFunction(error)) {
                    error(data);
                }
            }.bind(this),
        });
    },

    /**
     * Restores one specific item from the Trash.
     *
     * @param id
     * @param success
     * @param error
     */
    restoreItem: function(model, success, error) {
        var catalog = model.collection.catalog;

        $.ajax({
            url: _.result(model, 'url'),
            method: 'RESTORE', // TODO: this is bad: https://www.safaribooksonline.com/library/view/restful-web-services/9780596809140/ch01s12.html
            contentType: 'application/json',
            success: function (data) {
                this.remove(model);

                switch (data) {
                    case 'ColidoItemMineral':
                        var objectModel = new catalog.objects.model({
                            'id': model.id,
                        });

                        objectModel.fetch({
                            'success': function () {
                                catalog.objects.add(objectModel);

                                // TODO this is a QUICKFIX
                                // App.vent.trigger('collection:locations:storages:reload');
                                // App.vent.trigger('collection:sets:reload');
                            },
                            error: function () {
                                console.log('That was not an Objekt-Error');
                            },
                        });
                        break;

                    case 'ColidoItemSet':
                    default:
                        // TODO this is a QUICKFIX
                        // App.vent.trigger('collection:locations:storages:reload');
                        // App.vent.trigger('collection:sets:reload');
                        break;
                }

                if (_.isFunction(success)) {
                    success(data);
                }
            }.bind(this),

            error: function (xhr, ajaxOptions, thrownError) {
                if (_.isFunction(error)) {
                    error(xhr.responseText);
                }
            }.bind(this),
        });
    },
});

module.exports = TrashCollection;