'use strict';

var Backbone = require('backbone');
var Wreqr = require('base/Colido.Wreqr');
var SettingsLabelPrint = require('modules/collection/models/label-print-settings');

var CollectionModuleWreqr = Wreqr.extend({
    name: 'collection',

    /**
     * Deletes a single object or the selected objects in a collection.
     * @param {ObjectModel|Array} objects  A single object or an array of object models
     * @param {function} successCallback Callback to apply, when operation was successful
     * @param {function} errorCallback Callback to apply, when operation was not successful
     */
    commandDelete: function(objects, successCallback, errorCallback) {
        objects = _.isArray(objects) ? objects : [objects];

        if (objects.length) {
            var catalog = objects[0].collection.catalog;
            var objectName = objects[0].get('properName');

            var objectIds = _.pluck(objects, 'id');
            catalog.objects.delete(objectIds, { //batch delete
                success: function() {
                    Messenger().info({
                        message: App.t('collection::object:response:deleted', objects.length, {name: _.escape(objectName)}),
                        actions: {
                            trash: {
                                label: App.t('settings::trash:show-trash-button'),
                                action: function() {
                                    App.router.navigate('/catalogs/' + catalog.id + '/trash', {trigger: true});
                                },
                            },
                        },
                    });

                    // Apply callback?
                    if (_.isFunction(successCallback)) {
                        successCallback.apply(this, arguments);
                    }
                },
                error: function() {
                    Messenger().error({
                        message: App.t('collection::object:response:deleted:error', objects.length, {name: _.escape(objectName)}),
                    });

                    if (_.isFunction(errorCallback)) {
                        errorCallback.apply(this, arguments);
                    }
                },
            });
        }
    },

    /**
     * Changes the status of a single object or the selected objects in a collection
     * @param {ObjectsCollection|ObjectModel} objects      A single object or the objects collection
     * @param {CollectionModule.StatusModel}  statusModel  The model for the new status
     * @param {string}                        recipient    (Optional) The recipient for the status
     */
    commandChangeStatus: function(objects, statusModel, recipient) {
        objects = _.isArray(objects) ? objects : [objects];
        recipient = recipient || '';

        if (objects.length) {
            var firstObject = objects[0];
            var catalog = firstObject.collection.catalog;
            var objectIds = _.pluck(objects, 'id');

            _.each(objectIds, function(objectId) {
                var currentObjectModel = catalog.objects.get(objectId);
                currentObjectModel.set({
                    objectStatus: statusModel.get('key'),
                    objectStatusRelation: recipient,
                });

                // Update storage to unknow location
                if (statusModel.get('key') === 'missing') {
                    var unknowLocation = catalog.storages.getUnknowLocation();
                    if (unknowLocation && unknowLocation.id) {
                        currentObjectModel.set({storageId: unknowLocation.id});
                    }
                }
            });

            // Apply patch
            catalog.objects.save();

            var objectLink = '';
            if (objects.length == 1) {
                objectLink = App.html.link({
                    url: '/catalogs/' + catalog.id + '/show/' + firstObject.id,
                    label: '»' + _.escape(firstObject.get('properName')) + '«',
                });
            }

            Messenger().info({
                message: App.t('collection::change-status:response', objects.length, {
                    status: statusModel.get('name'),
                    objectLink: objectLink,
                }),
            });
        }
    },

    /**
     * Changes the storage of a single object or the selected objects in a collection
     * @param {ObjectsCollection|ObjectModel} objects       A single object or the objects collection
     * @param {LocationModule.StorageModel}  storageModel The model for the new storage
     */
    commandChangeStorage: function(objects, storageModel) {
        objects = _.isArray(objects) ? objects : [objects];

        if (objects.length) {
            var firstObject = objects[0];
            var catalog = firstObject.collection.catalog;
            var objectIds = _.pluck(objects, 'id');

            _.each(objectIds, function(objectId) {
                var currentObjectModel = catalog.objects.get(objectId);
                currentObjectModel.set({storageId: storageModel.get('id')});
            });

            var objectLink = '';
            if (objects.length == 1) {
                objectLink = App.html.link({
                    url: '/catalogs/' + catalog.id + '/storages/' + storageModel.id + '/show/' + firstObject.id,
                    label: '»' + _.escape(firstObject.get('properName')) + '«',
                });
            }

            var storageLink = App.html.link({
                url: '/catalogs/' + catalog.id + '/storages/' + storageModel.id,
                label: '»' + _.escape(storageModel.get('name').trim()) + '«',
            });

            // Apply patch
            catalog.objects.save({
                success: function(){
                    Messenger().success({
                        message: App.t('collection::locations:response:change-location', objects.length, {
                            storageLink: storageLink,
                            storageType: storageModel.get('storType') === 'status'
                                ? App.t('collection::locations:label:location').toLowerCase()
                                : App.t('collection::locations:label:storage').toLowerCase(),
                            objectLink: objectLink,
                        }),
                    });
                },
                error: function(){
                    Messenger().error({
                        message: App.t('collection::locations:response:change-location:error', objects.length, {
                            storageLink: storageLink,
                            storageType: storageModel.get('storType') === 'status'
                                ? App.t('collection::locations:label:location').toLowerCase()
                                : App.t('collection::locations:label:storage').toLowerCase(),
                            objectLink: objectLink,
                        }),
                    });
                },
            });
        }
    },

    /**
     * Adds selected items to the given set
     * @param {ObjectsCollection|ObjectModel} objects     A single object or the objects collection
     * @param {SetModule.SetModel}            setModel    The relevant set model
     */
    commandAddToSet: function(objects, setModel) {
        objects = _.isArray(objects) ? objects : [objects];

        if (objects.length) {
            var firstObject = objects[0];
            var objectIds = _.pluck(objects, 'id');
            var catalog = firstObject.collection.catalog;
            var setBasePath = '/catalogs/' + catalog.id + '/sets/';

            setModel.addItems(objectIds,
                function(relModel) {
                    var setLink = App.html.link({
                        url: setBasePath + setModel.id,
                        label: '»' + _.escape(setModel.get('name').trim()) + '«',
                    });

                    var objectLink = '';
                    if (objects.length == 1) {
                        objectLink = App.html.link({
                            url: setBasePath + setModel.id + '/show/' + firstObject.id,
                            label: '»' + _.escape(firstObject.get('properName')) + '«',
                        });
                    }

                    Messenger().info({
                        message: App.t('collection::set:response:add-to-set', objects.length, {
                            setLink: setLink,
                            objectLink: objectLink,
                        }),
                    });
                }.bind(this),
                function(relModel) {
                    var setLink = App.html.link({
                        url: setBasePath + setModel.id,
                        label: '»' + _.escape(setModel.get('name').trim()) + '«',
                    });

                    var objectLink = '';
                    if (objects.length == 1) {
                        objectLink = App.html.link({
                            url: setBasePath + setModel.id + '/show/' + firstObject.id,
                            label: '»' + _.escape(firstObject.get('properName')) + '«',
                        });
                    }

                    Messenger().error({
                        message: App.t('collection::set:response:add-to-set:failed', objects.length, {
                            setLink: setLink,
                            objectLink: objectLink,
                        }),
                    });
                }
            );
        }
    },

    /**
     * Removes the given object or objects from the set
     * @param {ObjectsCollection|ObjectModel} objects         A single object or the objects collection
     * @param {SetModule.SetModel}            setModel        The relevant set model
     * @param {function}                      successCallback Callback to apply, when operation was successful
     * @param {function}                      errorCallback   Callback to apply, when operation was not successful
     */
    commandRemoveFromSet: function(objects, setModel, successCallback, errorCallback) {
        objects = _.isArray(objects) ? objects : [objects];

        if (objects.length) {
            var firstObject = objects[0];
            var objectIds = _.pluck(objects, 'id');
            var catalog = firstObject.collection.catalog;
            var setBasePath = '/catalogs/' + catalog.id + '/sets/';

            setModel.removeItems(objectIds,
                function(relModel) {
                    var setLink = App.html.link({
                        url: setBasePath + setModel.id,
                        label: '»' + _.escape(setModel.get('name').trim()) + '«',
                    });

                    var objectLink = '';
                    if (objects.length == 1) {
                        objectLink = App.html.link({
                            url: setBasePath + setModel.id + '/show/' + firstObject.id,
                            label: '»' + _.escape(firstObject.get('properName')) + '«',
                        });
                    }

                    Messenger().info({
                        message: App.t('collection::set:response:remove-from-set', objects.length, {
                            setLink: setLink,
                            objectLink: objectLink,
                        }),
                    });

                    // Apply callback?
                    if (_.isFunction(successCallback)) {
                        successCallback.apply(this, arguments);
                    }
                }.bind(this),
                function(relModel) {
                    var setLink = App.html.link({
                        url: setBasePath + setModel.id,
                        label: '»' + _.escape(setModel.get('name').trim()) + '«',
                    });

                    var objectLink = '';
                    if (objects.length == 1) {
                        objectLink = App.html.link({
                            url: setBasePath + setModel.id + '/show/' + firstObject.id,
                            label: '»' + _.escape(firstObject.get('properName')) + '«',
                        });
                    }

                    Messenger().error({
                        message: App.t('collection::set:response:remove-from-set:failed', objects.length, {
                            setLink: setLink,
                            objectLink: objectLink,
                        }),
                    });

                    if (_.isFunction(errorCallback)) {
                        errorCallback.apply(this, arguments);
                    }
                }
            );
        }
    },

    /**
     * Creates the labels for the given params
     *
     * @param {object}      params Options
     * @param {string}      params.type                   (default|share)
     * @param {Array}       params.items                  (Array[int]) [12,32,..] > ids of the items
     * @param {boolean}     params.directDownload         (true|false)
     * @param {string}      params.token                  ("dfgkh8ohg53zq3z9hz539huethort")
     * @param {string|null} params.url                    ("http://www.gdgdfgfdg.com") only on type share
     * @param {string|null} params.title                  ("Kekse") only on type share
     * @param {number}      params.labelMarginHorizontal  (0)
     * @param {number}      params.labelMarginVertical    (26.25)
     * @param {number}      params.labelWidth             (95)
     * @param {number}      params.labelHeight            (42.5)
     * @param {number}      params.labelPadding           (4)
     * @param {number}      params.pageWidth              (210)
     * @param {number}      params.pageHeight             (297)
     * @param {number}      params.fontSizeScale          (1.1)
     * @param {boolean}     params.printLogo              (true|false)
     * @param {number}      params.offsetX                (0)
     * @param {number}      params.offsetY                (0)
     * @param {boolean}     params.printLines             (true|false)
     * @param {boolean}     params.isFoldingLabel         (true|false)
     * @param {boolean}     params.printPrice             (true|false)
     */
    commandPrintLabel: function(params) {
        params = params || {};

        var defaultSettings = new SettingsLabelPrint();
        var data = defaultSettings.toJSON();

        data = _.extend(data, params);

        // Set user token
        if (App.user && App.user.has('apiToken')) {
            data.token = App.user.get('apiToken');
        }

        var profileLogoUrl = App.user.get('profileLogoUrl');
        data.logoUrl = profileLogoUrl ? profileLogoUrl.baseUrl + '500x500/' + profileLogoUrl.fileName : null;

        data.type = data.url ? 'share' : 'default';

        Messenger().info({
            message: App.t('label-print-modal::messages-progress:pdf:message'),
        });

        var jqxhr = $.ajax({
            method: 'POST',
            url: App.getApiUrl('/label'),
            data: JSON.stringify(data),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
        });
        jqxhr.done(function(response) {
            Messenger().success({
                message: App.t('label-print-modal::messages-success:pdf:message', {filename: response.filename}),
                hideAfter: 0,
                actions: {
                    download: {
                        label: App.t('label-print-modal::messages-success:pdf:download-button'),
                        action: function() {
                            var a = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
                            a.href = App.getApiUrl('/label') + '/' + response.id;

                            setTimeout(function(){
                                var event = new MouseEvent('click');
                                a.dispatchEvent(event);
                            }, 1);
                        }.bind(this),
                    },
                },
            });
        }.bind(this));
        jqxhr.fail(function(response) {
            Messenger().error({
                message: App.t('label-print-modal::messages-error:pdf:message'),
            });
        }.bind(this));
    },

    commandPrintReport: function(params) {
        params = params || {};

        params.format       = params.format || 'pdf';           // {string} pdf|csv
        params.title        = params.title || '';               // {string}
        params.reportType   = params.reportType || 'advanced';  // {string} simple|advanced
        params.itemsPerPage = params.itemsPerPage || 'fit';     // {string} single|fit
        params.pageSize     = params.pageSize || 'a4';          // {string} a4 > currently only
        params.columns      = params.columns || [];             // {array[string]} ["ad","tr",...] > keys of the columns
        params.items        = params.items || [];               // {array[int]} [12,32,..] > ids of the items
        params.set          = params.set || null;               // {int|null} setid | null
        params.directDownload = params.directDownload || false; // {boolean} true|false
        params.token        = App.user.get('apiToken');         // {string }the token (for what?)

        if (!_.isArray(params.items)) {
            params.items = [params.items];
        }

        Messenger().info({
            message: App.t('print-modal::messages-progress:' + params.format  + ':message'),
        });

        var jqxhr = $.ajax({
            url: App.getApiUrl('/reports'),
            data: JSON.stringify(params),
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            type: 'POST',
        });
        jqxhr.done(function(response) {
            Messenger().success({
                message: App.t('print-modal::messages-success:' + params.format + ':message', {filename: response.filename}),
                hideAfter: 0,
                actions: {
                    download: {
                        label: App.t('print-modal::messages-success:' + params.format + ':download-button'),
                        action: function() {
                            var a = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
                            a.href = App.getApiUrl('/reports') + '/' + response.id;

                            setTimeout(function(){
                                var event = new MouseEvent('click');
                                a.dispatchEvent(event);
                            }, 1);
                        }.bind(this),
                    },
                },
            });
        }.bind(this));
        jqxhr.fail(function(response) {
            Messenger().error({
                message: App.t('print-modal::messages-error:' + params.format + ':message'),
            });
        }.bind(this));
    },

    requestSpeciesName: function(value) {
        var species = App.CollectionModule.speciesCollection.get(value);

        return species && species.get('name') ? species.get('name') : value;
    },
});

module.exports = CollectionModuleWreqr;