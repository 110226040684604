'use strict';

var ItemView = require('base/Colido.ItemView');
var FilterModalContentView = require('modules/base/views/layout/filter-modal');
var ParameterizedCollection = require('base/Colido.ParameterizedCollection');

var FilterModalView = ItemView.extend({
    initialize: function(options) {
        var baseFilter = this.getBaseFilter();
        var content = new FilterModalContentView({
            collection: this.collection,
            baseCollection: this.prepareBaseCollection(options.baseCollection, baseFilter),
            activeFilter: this.model.get('filter'),
            onFilterChange: function(newFilter) {
                var filterParts = [];
                // Manually add pre-existing filter value not included
                // in the filter modal.
                if (baseFilter) { filterParts.push(baseFilter); }
                if (newFilter) { filterParts.push(newFilter); }
                this.model.set('filter', filterParts.join('/'));
                this.modal.modalContent.trigger('close:modal');
            }.bind(this),
        });

        var options = {
            additionalClassName: 'modal-filter',
            onAfterClose: function() {
                this.model.set('filterModal', false);
            }.bind(this),
        }

        this.modal = App.addModal(content, options);

        ItemView.prototype.initialize.call(this, options);
    },

    /**
     * Extracts filter value that isn't included in the filter modal.
     * Continent locality is accessible via the left-hand navigation and
     * needs to be manually added to the filter value created by the filter
     * modal.
     */
    getBaseFilter: function () {
        var filter = this.model.get('filter') || '';
        filter = (filter.match(/(locality:continent\/\d+)/) || [null])[0];

        return filter;
    },

    /**
     * Creates a collection suitable for calculating the result count in the
     * filter modal.
     */
    prepareBaseCollection: function (allObjectsCollection, baseFilter) {
        // Create a pre-filtered list of objects. Apply all criteria that
        // aren't covered by FilterModal, i.e. search, set, location as well
        // as 'locality' filter.
        var parameterizedCollection = new ParameterizedCollection(null, {
            collection: allObjectsCollection,
        });

        parameterizedCollection.params.set({
            setModel: this.model.get('setModel'),
            search: this.model.get('search'),
            locationModel: this.model.get('locationModel'),
            importModel: this.model.get('importModel'),
            importWarningsModel: this.model.get('importWarningsModel'),
            filter: baseFilter,
        });

        return parameterizedCollection;
    },
});

module.exports = FilterModalView;