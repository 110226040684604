module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="species-container '+
((__t=( when(item.active, 'is-active') ))==null?'':_.escape(__t))+
'" data-species="'+
((__t=( item.speciesIndex ))==null?'':_.escape(__t))+
'">\n\n    ';
 if(item.index > 0) { 
__p+='\n        <div class="row correlation-container-morph '+
((__t=( when(_.contains(['after_pseudo', 'after_partial', 'after_epimorph'], item.correlation), 'is-active') ))==null?'':_.escape(__t))+
'">\n            <div class="page-col-offset input-help">\n                <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n            </div>\n            <div class="page-col-6">\n                <div class="option-select">\n                    <input id="speciesMorphCorrelationPseudomorph-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'"\n                           type="radio"\n                           name="speciesMorphCorrelation['+
((__t=( item.index ))==null?'':_.escape(__t))+
']"\n                           value="after_pseudo"\n                        '+
((__t=( when(item.correlation === 'after_pseudo' || !_.contains(['after_pseudo', 'after_partial', 'after_epimorph'], item.correlation), 'checked') ))==null?'':_.escape(__t))+
'>\n                    <label for="speciesMorphCorrelationPseudomorph-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'" class="option-select-secondary">\n                        '+
((__t=( t('collection::object:details:species-correlation-pseudomorph:label') ))==null?'':_.escape(__t))+
'\n                    </label>\n\n                    <input id="speciesMorphCorrelationPartialPseudomorph-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'"\n                           type="radio"\n                           name="speciesMorphCorrelation['+
((__t=( item.index ))==null?'':_.escape(__t))+
']"\n                           value="after_partial"\n                        '+
((__t=( when(item.correlation === 'after_partial', 'checked') ))==null?'':_.escape(__t))+
'>\n                    <label for="speciesMorphCorrelationPartialPseudomorph-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'" class="option-select-secondary">\n                        '+
((__t=( t('collection::object:details:species-correlation-partial-pseudomorph:label') ))==null?'':_.escape(__t))+
'\n                    </label>\n\n                    <input id="speciesMorphCorrelationEpimorph-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'"\n                           type="radio"\n                           name="speciesMorphCorrelation['+
((__t=( item.index ))==null?'':_.escape(__t))+
']"\n                           value="after_epimorph"\n                        '+
((__t=( when(item.correlation === 'after_epimorph', 'checked') ))==null?'':_.escape(__t))+
'>\n                    <label for="speciesMorphCorrelationEpimorph-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'" class="option-select-secondary">\n                        '+
((__t=( t('collection::object:details:species-correlation-epimorph:label') ))==null?'':_.escape(__t))+
'\n                    </label>\n                </div>\n            </div>\n        </div>\n\n        <div class="row correlation-container is-active">\n            <div class="page-col-offset input-help">\n                <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n            </div>\n            <div class="page-col-6">\n                <div class="option-select">\n                    <input id="speciesCorrelationComma-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'"\n                           type="radio"\n                           name="speciesCorrelation['+
((__t=( item.index ))==null?'':_.escape(__t))+
']"\n                           value="comma"\n                        '+
((__t=( when(item.correlation === 'comma', 'checked') ))==null?'':_.escape(__t))+
'>\n                    <label for="speciesCorrelationComma-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'" class="option-select-secondary">\n                        '+
((__t=( t('collection::object:details:species-correlation-comma:label') ))==null?'':_.escape(__t))+
'\n                    </label>\n\n                    <input id="speciesCorrelationOn-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'"\n                           type="radio"\n                           name="speciesCorrelation['+
((__t=( item.index ))==null?'':_.escape(__t))+
']"\n                           value="on"\n                        '+
((__t=( when(item.correlation === 'on', 'checked') ))==null?'':_.escape(__t))+
'>\n                    <label for="speciesCorrelationOn-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'" class="option-select-secondary">\n                        '+
((__t=( t('collection::object:details:species-correlation-on:label') ))==null?'':_.escape(__t))+
'\n                    </label>\n\n                    <input id="speciesCorrelationWith-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'"\n                           type="radio"\n                           name="speciesCorrelation['+
((__t=( item.index ))==null?'':_.escape(__t))+
']"\n                           value="with"\n                        '+
((__t=( when(item.correlation === 'with', 'checked') ))==null?'':_.escape(__t))+
'>\n                    <label for="speciesCorrelationWith-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'" class="option-select-secondary">\n                        '+
((__t=( t('collection::object:details:species-correlation-with:label') ))==null?'':_.escape(__t))+
'\n                    </label>\n\n                    <input id="speciesCorrelationVar-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'"\n                           type="radio"\n                           name="speciesCorrelation['+
((__t=( item.index ))==null?'':_.escape(__t))+
']"\n                           value="var"\n                        '+
((__t=( when(item.correlation === 'var', 'checked') ))==null?'':_.escape(__t))+
'>\n                    <label for="speciesCorrelationVar-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'" class="option-select-secondary">\n                        '+
((__t=( t('collection::object:details:species-correlation-var:label') ))==null?'':_.escape(__t))+
'\n                    </label>\n\n                    <input id="speciesCorrelationAfter-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'"\n                           type="radio"\n                           name="speciesCorrelation['+
((__t=( item.index ))==null?'':_.escape(__t))+
']"\n                           value="after"\n                        '+
((__t=( when(_.contains(['after_pseudo', 'after_partial', 'after_epimorph'], item.correlation), 'checked') ))==null?'':_.escape(__t))+
'>\n                    <label for="speciesCorrelationAfter-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'" class="option-select-secondary">\n                        '+
((__t=( t('collection::object:details:species-correlation-after:label') ))==null?'':_.escape(__t))+
'\n                    </label>\n                </div>\n            </div>\n        </div>\n    ';
 } 
__p+='\n\n    <div class="row material-addition">\n        <div class="page-col-offset input-help"></div>\n        <div class="page-col-3">\n            <input type="text"\n                   id="species-'+
((__t=( item.index ))==null?'':_.escape(__t))+
'"\n                   name="species['+
((__t=( item.index ))==null?'':_.escape(__t))+
']"\n                   class="input-species"\n                   placeholder="'+
((__t=( ifelse(item.index === 0, t('collection::edit:placeholder-species'), t('collection::edit:placeholder-add-species')) ))==null?'':_.escape(__t))+
'"\n                   data-type="add-specimen"\n                   value="'+
((__t=( item.name ))==null?'':_.escape(__t))+
'">\n        </div>\n    </div>\n</div>';
}
return __p;
};