'use strict';

var ColidoLayoutView = require('base/Colido.LayoutView');
var Sortable = require('sortable');

var template = require('../../templates/layout/table-fields-settings-modal.hbs');

var TableFieldsSettingsModalView = ColidoLayoutView.extend({
    template: template,

    regions: {
        header: '.modal-header',
        body: '.modal-body',
    },

    ui: {
        actionClose: '[data-action="close"]',
        sortableList: '.table-columns',
    },

    events: {
        'click @ui.actionClose': 'actionClose',
    },

    viewData: {
        tableFields: function() {
            return this.getOption('tableFields');
        },
    },

    actionClose: function (event) {
        var tableFields = [];

        this.ui.sortableList.find('input[type="checkbox"]').each(function(index, field){
            field = $(field);

            tableFields.push({
                fieldName: field.val(),
                active: field.is(':checked'),
            })
        });

        this.trigger('close:modal', tableFields);
    },

    onRender: function () {
        this.sortable = Sortable.create(this.ui.sortableList[0], {
            draggable: 'li',
            handle: '.drag-handle',
            ghostClass: 'drag-placeholder',
            chosenClass: 'drag-active',
            sort: true,
            animation: 150,
        });
    },

    onBeforeDestroy: function () {
        this.sortable.destroy()
    },
});

module.exports = TableFieldsSettingsModalView;