module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<section class="object-full-locality">\n    <h4>'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</h4>\n    <p>'+
((__t=(item.fullLocality))==null?'':_.escape(__t))+
'</p>\n</section>';
}
return __p;
};