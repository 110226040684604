'use strict';

var Backbone = require('backbone');
var LayoutView = require('base/Colido.LayoutView');
var NavigationView = require('./navigation');

var template = require('../../templates/layout/main.hbs');

var MainLayout = LayoutView.extend({
    template: template,

    extraContentPaths: [//path dont start with /
       /\/show\/\d+/,
       /^account\/subscription\/setup/,
       /\/import$/,
       /\/duplicate\/\d+/,
       /\/conversation\/\d+/,
    ],

    /**
     * Path of the original app favicon
     * @type {string}|{null}
     */
    originalFavicon: null,

    regions: {
        navRegion: '#nav',
        contentRegion: '#content',
        extraContentRegion: '#extra-content',
    },

    ui: {
        'mobileToggle': '.btn[data-action="toggle-menu"]',
    },

    events: {
        'click @ui.mobileToggle': 'toggleMobileMenu',
    },

    initialize: function() {
        this.extraContentRegion.on('show empty', this.toggleExtraContent.bind(this));
        this.listenTo(App.router, 'route', this.toggleExtraContent.bind(this));

        this.contentRegion.on('show', this.removeLoader.bind(this));
        this.extraContentRegion.on('show', this.removeLoader.bind(this));
        this.extraContentRegion.on('show', this.addExtraContentAdditionalClassNames.bind(this));

        // Scroll up the content, when view was swapped
        this.contentRegion.on('swap', function(view, region) {
            region.$el.scrollTop(0);
        });

        // Listen for a language change
        this.listenTo(App.radio().vent, 'language:changed', function() {
            if (App.user.hasApiToken()) {
                if (this.contentRegion.currentView) {
                    var contentRegionOptions = this.contentRegion.currentView.options || {};
                    this.contentRegion.show(new this.contentRegion.currentView.constructor(contentRegionOptions));
                }

                if (this.extraContentRegion.currentView) {
                    var extraContentRegionOptions = this.extraContentRegion.currentView.options || {}
                    this.extraContentRegion.show(new this.extraContentRegion.currentView.constructor(extraContentRegionOptions));
                }

                if (this.navRegion.currentView) {
                    var navRegionOptions = this.navRegion.currentView.options || {};
                    this.navRegion.show(new this.navRegion.currentView.constructor(navRegionOptions));
                }
            }
        }.bind(this));

        // Update favicon
        if (App.CommunityModule) {
            this.listenTo(
                App.CommunityModule.conversationsCollection,
                'update reset change:unreadPosts',
                this.updateCommunityRelatedInfo.bind(this)
            );
        }
    },

    onAttach: function() {
        this.navRegion.show(new NavigationView());

        $(this.extraContentRegion.el).on('transitionend webkitTransitionEnd', function(event){
            var originalEvent = event.originalEvent;

            if (event.target === event.currentTarget && _.contains(['transform', '-webkit-transform'], originalEvent.propertyName)) {
                if (App.rootView.mainRegion.$el.hasClass('show-extra-content')) {
                    App.vent.trigger('show:extra-content');
                } else {
                    this.clearExtraContent();
                    App.vent.trigger('hide:extra-content');
                }
            }
        }.bind(this));
    },

    toggleExtraContent: function() {
        var path = Backbone.history.fragment;

        if (this.isPathExtraContent(path)) {
            App.rootView.mainRegion.$el.addClass('show-extra-content');
        } else {
            App.rootView.mainRegion.$el.removeClass('show-extra-content');
            App.rootView.mainRegion.$el.removeClass('show-extra-content-' + this.extraContentRegion.additionalClassName);
        }
    },

    clearExtraContent: function() {
        var path = Backbone.history.fragment;

        if (!this.isPathExtraContent(path)) {
            this.extraContentRegion.empty();
            $('body').removeClass('is-detail-mode');
            this.removeExtraContentAdditionalClassNames();
        }
    },

    isPathExtraContent: function(path) {
        for (var i = 0; i < this.extraContentPaths.length; i++) {
            if (path.match(this.extraContentPaths[i])) {
                return true;
            }
        }

        return false;
    },

    showLoader: function() {
        this.contentRegion.empty();
        this.extraContentRegion.empty();
        $(this.contentRegion.el).addClass('loading'); //$el somehow is not yet resolved when this is called
        $(this.extraContentRegion.el).addClass('loading');
    },

    removeLoader: function() {
        this.contentRegion.$el.removeClass('loading');
        this.extraContentRegion.$el.removeClass('loading');
    },

    toggleMobileMenu: function(event) {
        $('body').toggleClass('has-opened-nav');
    },

    addExtraContentAdditionalClassNames: function(view, region, options) {
        options = options || {};

        if (options.additionalClassName) {
            this.extraContentRegion.additionalClassName = options.additionalClassName;
            this.extraContentRegion.$el.addClass(options.additionalClassName);
            App.rootView.mainRegion.$el.addClass('show-extra-content-' + options.additionalClassName);
        }
    },

    removeExtraContentAdditionalClassNames: function() {
        if (this.extraContentRegion.additionalClassName) {
            this.extraContentRegion.$el.removeClass(this.extraContentRegion.additionalClassName);
            this.extraContentRegion.additionalClassName = '';
        }
    },

    /**
     * Updates community related information, such as favicon or notifications
     */
    updateCommunityRelatedInfo: function() {
        if (App.CommunityModule) {
            var count = App.CommunityModule.conversationsCollection.getUnreadPostsCount();

            // Store original icon
            if (!this.originalFavicon) {
                this.originalFavicon = $('#main-shortcut-icon').attr('href');
            }

            // Update icon
            if (count > 0) {
                $('#main-shortcut-icon').attr('href', '/images/app/colido-icon-96-message.png');
            } else {
                $('#main-shortcut-icon').attr('href', this.originalFavicon);
            }

            // Send notification if page is not visible
            if (document.visibilityState
                && document.visibilityState !== 'visible'
                && count > 0
            ) {
                var notification = App.sendNotification(App.t('community::notification:new-message'), {
                    body: App.t('community::notification:unread-messages', count),
                    icon: '/images/app/colido-icon-96.png',
                    tag: 'new-community-message',
                });

                // If we have a notification, add onclick handler
                if (notification) {
                    notification.onclick = function(event) {
                        window.focus();
                        event.currentTarget.close();
                    };
                }
            }
        }
    },
});

module.exports = MainLayout;