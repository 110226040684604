'use strict';

var ItemView = require('base/Colido.ItemView');

var template = require('modules/settings/templates/partials/subscription/creditcard-form.hbs');

require('vendor/jquery.payment');

var CreditCardFormView = ItemView.extend({
    template: template,

    ui: {
        form: 'form',
        inputFields: 'input',
        cardHolder: 'input[name="cardHolder"]',
        cardNumber: 'input[name="cardNumber"]',
        cardExpires: 'input[name="cardExpires"]',
        cardCvv: 'input[name="cardCvv"]',
        smartInputs: '.input-label-group input, .input-label-group textarea',
        saveButton: '[type="submit"]',
        availableCreditCards: '.available-creditcards',
    },

    events: {
        'submit @ui.form': 'onSubmit',
        'change @ui.inputFields': 'checkCreditcardInput',
        'blur @ui.inputFields': 'checkCreditcardInput',
        'keyup @ui.inputFields': 'checkCreditcardInput',
        'keydown @ui.inputFields': 'checkCreditcardInput',
        'keyup @ui.smartInputs': 'checkInputWithLabel',
        'keydown @ui.smartInputs': 'checkInputWithLabel',
    },

    isSetup: false,

    onShow: function() {
        this.ui.cardNumber.payment('formatCardNumber');
        this.ui.cardExpires.payment('formatCardExpiry');
        this.ui.cardCvv.payment('formatCardCVC');
    },

    onSubmit: function(event) {
        if (event) {
            event.preventDefault();
        }

        this.updateModel();

        if (this.model.isValid()) {
            this.triggerMethod('creditcardProvided', this.model);
        } else {
            this.showError(this.model.validationError);
        }
    },

    checkCreditcardInput: function(event) {
        this.updateModel();

        var errors = this.model.validate(this.model.attributes);
        this.showError(errors, true);

        // Update cardtype
        if (event.currentTarget === this.ui.cardNumber[0]) {
            var cardType = $.payment.cardType(this.ui.cardNumber.val());
            if (cardType) {
                this.ui.availableCreditCards.toggleClass('is-identified', true).attr('data-card', cardType);
            } else {
                this.ui.availableCreditCards.toggleClass('is-identified', false).removeAttr('data-card');
            }
        }
    },

    updateModel: function() {
        var cardType = $.payment.cardType(this.ui.cardNumber.val());
        this.model.set({
            cardHolder: this.ui.cardHolder.val(),
            cardNumber: this.ui.cardNumber.val(),
            cardExpires: this.ui.cardExpires.val().replace(/\s/g, ''),
            cardCvv: this.ui.cardCvv.val(),
            cardType: cardType,
        });
    },

    /**
     * Is triggered, when the user types something into the creditcard fields
     * @param {object} event The keyup/keydown/blur event
     */
    showError: function(errors, ignoreEmptyFields) {
        var fields = [
            'cardHolder',
            'cardNumber',
            'cardExpires',
            'cardCvv',
        ];

        fields.forEach(function(field){
            var element = this.ui[field];
            var ignoreField = ignoreEmptyFields && element.val().length === 0;

            if (errors && errors[field]) {
                if (ignoreField) {
                    element.removeClass('has-error');
                    element.removeClass('is-valid');
                } else {
                    element.addClass('has-error');
                    element.removeClass('is-valid');
                }
            } else {
                if (!ignoreField) {
                    element.addClass('is-valid');
                }
                element.removeClass('has-error');
            }
        }.bind(this));
    },

    viewData: {
        supportedCreditcards: function() {
            return [
                {
                    name: 'MasterCard',
                    icon: 'mastercard.png',
                    type: 'mastercard',
                },
                {
                    name: 'Visa',
                    icon: 'visa.png',
                    type: 'visa',
                },
                {
                    name: 'American Express',
                    icon: 'amex.png',
                    type: 'amex',
                },
                {
                    name: 'Discover',
                    icon: 'discover.png',
                    type: 'discover',
                },
                {
                    name: 'JCB',
                    icon: 'jcb.png',
                    type: 'jcb',
                },
                {
                    name: 'Diner\'s Club',
                    icon: 'diners-club.png',
                    type: 'dinersclub',
                },
            ];
        },
    },

    /**
     * Event for checking the label input group
     * @param {object} event The event object
     * @return {void}
     */
    checkInputWithLabel: function(event) {
        var input = $(event.currentTarget);
        input.closest('.input-label-group').toggleClass('has-value', input.val().length > 0);
    },
});

module.exports = CreditCardFormView;