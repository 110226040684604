module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form class="forgot-password-form" method="post">\n    <h2>'+
((__t=(t('forgot-password::headline')))==null?'':_.escape(__t))+
'</h2>\n\n    <fieldset>\n        <p class="h2-subline">'+
((__t=(t('forgot-password::subline')))==null?'':_.escape(__t))+
'</p>\n        <input name="email" type="email" value="'+
((__t=(item.email))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('forgot-password::placeholder-email')))==null?'':_.escape(__t))+
'">\n    </fieldset>\n\n    <fieldset class="submit">\n        <button type="submit" class="btn btn-primary">'+
((__t=(t('forgot-password::submit')))==null?'':_.escape(__t))+
'</button>\n\n        <a href="/login" class="btn btn-text">'+
((__t=(t('forgot-password::cancel')))==null?'':_.escape(__t))+
'</a>\n    </fieldset>\n</form>';
}
return __p;
};