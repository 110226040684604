module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="'+
((__t=(item.catalogUrl))==null?'':_.escape(__t))+
'" class="catalog-link"><h4 class="catalog-name">'+
((__t=(item.name))==null?'':_.escape(__t))+
'</h4>\n<h5>'+
((__t=(item.typeName))==null?'':_.escape(__t))+
'</h5></a>\n<form>\n    <p>\n        <input type="text" name="catalog-name" value="'+
((__t=(item.name))==null?'':_.escape(__t))+
'" />\n    </p>\n    <p class="submit">\n        <button type="submit">\n            '+
((__t=(t('settings::manage-catalogs:button:save-catalog')))==null?'':_.escape(__t))+
'\n        </button>\n        <button type="reset" data-action="hide-edit-form">\n            '+
((__t=(t('settings::manage-catalogs:button:hide-edit-form')))==null?'':_.escape(__t))+
'\n        </button>\n    </p>\n</form>\n<div class="actions">\n    <button type="button" data-action="show-edit-form">\n        '+
((__t=(t('settings::manage-catalogs:button:show-edit-form')))==null?'':_.escape(__t))+
'\n    </button>\n    <button type="button" data-action="delete-catalog">'+
((__t=( icon('trash')))==null?'':__t)+
'</button>\n</div>';
}
return __p;
};