module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="album-modal-container list-view-photobox">\n    <header class="modal-header">\n        <div class="header-part header-part-photobox">\n            <ul>\n                <li>\n                    <h2>'+
((__t=(t('collection::album:modal:photobox-image-library')))==null?'':_.escape(__t))+
'</h2>\n                    <h2 class="subline">'+
((__t=(t('collection::album:modal:click-on-album-to-use-images')))==null?'':_.escape(__t))+
'</h2>\n                </li>\n                <li class="actions">\n                    <button type="button" class="btn btn-secondary" data-action="cancel">'+
((__t=(t('collection::album:modal:cancel')))==null?'':_.escape(__t))+
'</button>\n                </li>\n            </ul>\n        </div>\n        <div class="header-part header-part-unused">\n            <ul>\n                <li>\n                    <h2>'+
((__t=(t('collection::album:modal:photobox-image-library')))==null?'':_.escape(__t))+
'</h2>\n                    <h2 class="subline">'+
((__t=(t('collection::album:modal:choose-images-and-click-done')))==null?'':_.escape(__t))+
'</h2>\n                </li>\n                <li class="actions">\n                    <button type="button" class="btn btn-secondary" data-action="cancel">'+
((__t=(t('collection::album:modal:cancel')))==null?'':_.escape(__t))+
'</button>\n                    <button type="button" class="btn btn btn-primary" data-action="done">'+
((__t=(t('collection::album:modal:done')))==null?'':_.escape(__t))+
'</button>\n                </li>\n            </ul>\n        </div>\n    </header>\n\n    <div class="modal-body">\n        <div class="modal-content container">\n            <div class="listcontrol" data-state="default">\n                <div class="listcontrol-inner">\n                    <ul class="listcontrol-wrapper listcontrol-wrapper-default">\n                        <li>\n                            <div class="option-select">\n                                <input id="changeListPhotobox"\n                                       type="radio"\n                                       name="listView"\n                                       value="photobox"\n                                       checked>\n                                <label for="changeListPhotobox">'+
((__t=(t('collection::album:modal:new-albums')))==null?'':_.escape(__t))+
' <span id="count-photobox" class="option-select-count"></span></label>\n\n                                <input id="changeListUnused"\n                                       type="radio"\n                                       name="listView"\n                                       value="unused">\n                                <label for="changeListUnused">'+
((__t=(t('collection::album:modal:unused-images')))==null?'':_.escape(__t))+
' <span id="count-unused" class="option-select-count"></span></label>\n                            </div>\n                        </li>\n                    </ul>\n                </div>\n            </div>\n\n            <div class="container album-lists">\n                <div class="list-view-album region-photobox" id="region-photobox"></div>\n                <div class="list-view-album region-unused" id="region-unused"></div>\n            </div>\n        </div>\n    </div>\n</div>';
}
return __p;
};