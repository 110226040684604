module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table class="table table-overflow table-striped table-without-margin table-exports">\n    <thead>\n    <tr>\n        <th>'+
((__t=(t('settings::export:recent-list:filename')))==null?'':_.escape(__t))+
'</th>\n        <th>'+
((__t=(t('settings::export:recent-list:objects')))==null?'':_.escape(__t))+
'</th>\n        <th>'+
((__t=(t('settings::export:recent-list:imported-on')))==null?'':_.escape(__t))+
'</th>\n        <th>'+
((__t=(t('settings::export:recent-list:type')))==null?'':_.escape(__t))+
'</th>\n        <th>&nbsp;</th>\n        <th>&nbsp;</th>\n    </tr>\n    </thead>\n    <tbody></tbody>\n</table>';
}
return __p;
};