module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="container ';
 if (view.getOption('infiniteView')) { 
__p+='sly-frame';
 } 
__p+='">\n    ';
 if (view.getOption('infiniteView')) { 
__p+='\n        <div class="list list-flex sly-slider" id="locationsList"></div>\n    ';
 } else { 
__p+='\n        <div class="container">\n            <div class="list list-flex" id="locationsList"></div>\n        </div>\n    ';
 } 
__p+='\n</div>';
}
return __p;
};