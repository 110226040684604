'use strict';

var ListControlView = require('modules/collection/views/partials/list-control');

var template = require('modules/collection/modules/set/templates/partials/list-control.hbs');

var SetListControlView = ListControlView.extend({
    template: template,

    hasFilter: false,
});

module.exports = SetListControlView;