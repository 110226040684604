'use strict';

var ItemView = require('base/Colido.ItemView');
var LoginModel = require('../../models/login');

var template = require('modules/authentication/templates/partials/signup.hbs');

var SignupView = ItemView.extend({
    template: template,

    ui: {
        'passwordInput': '[data-name="password"]',
        'passwordStrength': '.password-strength',
        'inputs': 'input',
        'submitButton': 'button[data-name="submit"]',
    },

    events: {
        'submit .signup-form': 'submitSignup',
        'click [data-action="show-password"]': 'showPassword',
        'click [data-action="hide-password"]': 'hidePassword',
        'keyup @ui.passwordInput': 'changePassword',
        'change @ui.inputs': 'validateForm',
        'keyup @ui.inputs': 'validateForm',
    },

    validateForm: function(){
        this.model.set({
            email: this.$('[data-name=email]').val(),
            password: this.$('[data-name=password]').val(),
            fullname: this.$('[data-name=fullname]').val(),
            signUpOk: this.$('[data-name=signUpOk]:checked').length,
        });
    },

    changePassword: function(event){
        var pass = $(event.currentTarget).val();
        var level = this.ratePassword(pass, 3);

        this.ui.passwordStrength.removeClass('good');
        this.ui.passwordStrength.removeClass('moderate');
        this.ui.passwordStrength.removeClass('bad');

        if (level > 0) {
            this.ui.passwordStrength.addClass('bad');
        }
        if (level >= 2) {
            this.ui.passwordStrength.removeClass('bad');
            this.ui.passwordStrength.addClass('moderate');
        }
        if (level >= 3) {
            this.ui.passwordStrength.removeClass('bad');
            this.ui.passwordStrength.removeClass('moderate');
            this.ui.passwordStrength.addClass('good');
        }
    },

    ratePassword: function(str, maxLevel){
        var level = 0;

        var alphabetKlein = 'abcdefghijklmnopqrstuvwxyz'.split('');
        var hasAlphabetKlein = false;
        var alphabetGross = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        var hasAlphabetGross = false;
        var numerics = '0123456789'.split('');
        var hasNumerics = false;
        var lengthToBeGood = 6;
        var hasLengthToBeGood = false;
        var allNaturalChars = [];
        allNaturalChars = allNaturalChars.concat(alphabetKlein);
        allNaturalChars = allNaturalChars.concat(alphabetGross);
        allNaturalChars = allNaturalChars.concat(numerics);
        var hasUnNaturalChars = false;

        hasLengthToBeGood = hasLengthToBeGood || str.length >= lengthToBeGood;

        for (var i = 0; i < str.length; i++) {
            var char = str[i];
            if (_.indexOf(allNaturalChars, char) === -1) {
                hasUnNaturalChars = hasUnNaturalChars || true;
            }

            hasAlphabetKlein = hasAlphabetKlein || (_.indexOf(alphabetKlein, char) > -1);
            hasAlphabetGross = hasAlphabetGross || (_.indexOf(alphabetGross, char) > -1);
            hasNumerics = hasNumerics || (_.indexOf(numerics, char) > -1);
        }

        level += (hasAlphabetKlein) ? 1 : 0;
        level += (hasAlphabetGross) ? 1 : 0;
        level += (hasNumerics) ? 1 : 0;
        level += (hasLengthToBeGood) ? 1 : 0;
        level += (hasUnNaturalChars) ? 1 : 0;

        level = (maxLevel / 5) * level;

        // special
        if (str.length >= lengthToBeGood) {
            level = Math.max(2, level);
        } else if (str.length != 0) {
            level = 1;
        }

        return level;
    },

    showPassword: function(){
        this.ui.passwordInput.attr('type', 'text');
        this.ui.passwordInput.parent().addClass('password-not-hidden');
    },

    hidePassword: function(){
        this.ui.passwordInput.attr('type', 'password');
        this.ui.passwordInput.parent().removeClass('password-not-hidden');
    },

    submitSignup: function(event){
        event.preventDefault();
        var passwordOk = true;

        if (this.ui.passwordStrength.hasClass('bad')) {
            passwordOk = false;
        }

        this.$('.signup-form p.error').slideUp('normal', function() {
            $(this).remove();
        });

        this.$('.signup-form fieldset.error').removeClass('error');
        this.$('.signup-form [type="submit"]').addClass('is-loading').prop('disabled', true);
        this.$('.signup-form [type="reset"]').prop('disabled', true);
        this.$('.signup-form input').prop('disabled', true);

        this.model.set({
            email: this.$('[data-name=email]').val(),
            password: this.$('[data-name=password]').val(),
            fullname: this.$('[data-name=fullname]').val(),
            signUpOk: this.$('[data-name=signUpOk]:checked').length,
            language: $('.language-container input:checked').val(),
        });

        if (this.model.isValid() && passwordOk) {
            // TODO: USE backbonejs
            var jqxhr = $.post(App.getApiUrl('/users'), {'email': this.model.get('email'), 'password': this.model.get('password'), 'fullname': this.model.get('fullname'), 'language': this.model.get('language')});
            jqxhr.done(function() {
                this.showSuccess(App.t('signup::signup-success'));

                var loginModel = new LoginModel();
                loginModel.set('email', this.model.get('email'));
                loginModel.set('password', this.model.get('password'));

                this.trigger('login', loginModel);
            }.bind(this));
            jqxhr.fail(function(response) {
                var messageKey = response.responseJSON && response.responseJSON.message
                    ? 'signup::server-errors:' + response.responseJSON.message
                    : 'forgot-password::unknown-error';

                this.showError(messageKey);
            }.bind(this));
        } else {
            if (!passwordOk) {
                this.showError('signup::server-errors:password-is-to-short');
            } else {
                this.showError(this.model.validate(this.model.attributes));
            }
        }
    },

    showSuccess: function(msg){
        this.$('.signup-form p.error').slideUp('normal', function() {
            $(this).remove();
        });
        this.$('.signup-form fieldset.error').removeClass('error');
        this.$('.signup-form input').prop('disabled', true);
        this.$('.signup-form [type="submit"]').removeClass('is-loading');
        this.$('.signup-form fieldset').slideUp();
    },

    showError: function(errorObject) {
        this.$('.signup-form p.error').slideUp('normal', function() {
            $(this).remove();
        });
        this.$('.signup-form fieldset.error').removeClass('error');
        this.$('.signup-form [type="submit"]').removeClass('is-loading').prop('disabled', false);
        this.$('.signup-form [type="reset"]').prop('disabled', false);
        this.$('.signup-form input').prop('disabled', false);

        if (_.isObject(errorObject)){
            for (var err in errorObject) {
                if (errorObject.hasOwnProperty(err)) {
                    this.$('[data-name=' + err + ']').parent().addClass('error');
                }
            }
        } else {
            var messageKey = errorObject;
            var msg = App.t(messageKey);

            if (!msg) {
                msg = errorObject;
            }

            var $msg = $('<p class="error">' + msg + '</p>').hide();
            this.$('[type="submit"]').before($msg);
            $msg.slideDown();

            setTimeout(function(){
                $msg.slideUp();
            }, 5000);
        }
    },
});

module.exports = SignupView;