module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="'+
((__t=(model.get('url')))==null?'':_.escape(__t))+
'" class="open-conversation">\n    <div class="posts-indicator '+
((__t=(when(item.unreadPosts, 'unread-posts')))==null?'':_.escape(__t))+
'" title="'+
((__t=(item.unreadPosts))==null?'':_.escape(__t))+
'">\n        ';
 if(item.unreadPosts >= 9) { 
__p+='\n            <span class="unread-posts-count">9+</span>\n        ';
 } else if(item.unreadPosts >= 1) { 
__p+='            \n            <span class="unread-posts-count">'+
((__t=(item.unreadPosts))==null?'':_.escape(__t))+
'</span>\n        ';
 } 
__p+='\n    </div>\n\n    <div class="infos">\n        <h4 class=\'conversation-name\' title="'+
((__t=(model.get('name')))==null?'':_.escape(__t))+
'">'+
((__t=(model.get('name')))==null?'':_.escape(__t))+
'</h4>\n\n        ';
 if (model.get('lastMessage')) { 
__p+='\n            <p class="conversation-last-massage '+
((__t=(when(item.unreadPosts, 'is-unread')))==null?'':_.escape(__t))+
'" title="'+
((__t=(model.get('lastMessage')))==null?'':_.escape(__t))+
'">\n                '+
((__t=(model.get('lastMessage')))==null?'':_.escape(__t))+
'\n            </p>\n        ';
 } 
__p+='\n    </div>\n    \n    ';
 if (model.get('type') === 'contact') { 
__p+='\n        <div class="contact-image">\n            <span class="contact-initials">'+
((__t=(_.initials(model.get('name'))))==null?'':_.escape(__t))+
'</span>\n            '+
((__t=( responsiveImage(model.get('contact').get('profilePicture'), imageSize('thumbnailFixed'), viewportSize('grid-object'), model.get('name'), 'grid-image') ))==null?'':__t)+
'\n        </div>\n    ';
 } else if (model.get('type') === 'group') { 
__p+='\n        <div class="group-image">\n            ';
 _.each(_.first(model.get('group').get('users'), 4), function(user){ 
__p+='\n                <div class="contact-image">\n                    <span class="contact-initials">'+
((__t=(_.initials(user.name)))==null?'':_.escape(__t))+
'</span>\n                    '+
((__t=( responsiveImage(user.profilePicture, imageSize('thumbnailFixed'), viewportSize('grid-object'), user.name, 'grid-image') ))==null?'':__t)+
'\n                </div>\n            ';
 }) 
__p+='\n        </div>\n    ';
 } 
__p+='\n</a>';
}
return __p;
};