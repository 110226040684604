module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header modal-buttons-right">\n    <button type="button" class="btn btn-primary" data-action="close">\n        '+
((__t=(t('collection::column-filter:done')))==null?'':_.escape(__t))+
'\n    </button>\n\n    <h2>'+
((__t=(t('collection::column-filter:title')))==null?'':_.escape(__t))+
'</h2>\n</header>\n\n<div class="modal-body">\n    <form>\n        <section>\n            <ol  class="table-columns">\n                ';
 _.each(item.tableFields, function(field) { 
__p+='\n                    <li data-key="'+
((__t=(field.name))==null?'':_.escape(__t))+
'"\n                        data-deactivatable="'+
((__t=(true))==null?'':_.escape(__t))+
'"\n                        class="dd-item '+
((__t=(when(field.active, 'active')))==null?'':_.escape(__t))+
'">\n                        <input type="checkbox" id="toggle-table-field-'+
((__t=(field.name))==null?'':_.escape(__t))+
'" '+
((__t=(when(field.active, 'checked="checked"')))==null?'':_.escape(__t))+
' value="'+
((__t=(field.name))==null?'':_.escape(__t))+
'" />\n                        <label for="toggle-table-field-'+
((__t=(field.name))==null?'':_.escape(__t))+
'">'+
((__t=(field.label))==null?'':_.escape(__t))+
'</label>\n                        <div class="drag-handle"></div>\n                    </li>\n                ';
 }) 
__p+='\n            </ol>\n        </section>\n    </form>\n</div>';
}
return __p;
};