module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<section class="object-sets">\n    <h3>'+
((__t=(t('collection::object:details:section:headline:sets')))==null?'':_.escape(__t))+
'</h3>\n\n    <ul>\n        ';
 for(var i = 0; i < item.sets.length; i++){ 
__p+='\n            <li>\n                <a href="/catalogs/'+
((__t=(item.catalog.id))==null?'':_.escape(__t))+
'/sets/'+
((__t=( item.sets[i].id))==null?'':_.escape(__t))+
'">'+
((__t=( item.sets[i].get('name') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'</a>\n            </li>\n        ';
 } 
__p+='\n\n        ';
 if (item.sets.length === 0){ 
__p+='\n            <li>\n                <span>'+
((__t=(t('collection::object:details:sets:undefined')))==null?'':_.escape(__t))+
'</span>\n            </li>\n        ';
 } 
__p+='\n    </ul>\n\n    <button data-action="add-to-set" class="btn btn-clean">\n        '+
((__t=( t('collection::object:action:add-to-set') ))==null?'':_.escape(__t))+
'\n    </button>\n</section>';
}
return __p;
};