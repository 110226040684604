'use strict';

var ItemView = require('base/Colido.ItemView');

var template = require('modules/authentication/templates/partials/login.hbs');

var LoginView = ItemView.extend({
    template: template,

    ui: {
        languageSwitch: '.option-select input',
    },

    events: {
        'submit .login-form': 'login',
        'change @ui.languageSwitch': 'handleLanguageChange',
    },

    onShow: function() {
        var userLang = App.user.getLanguage();
        this.ui.languageSwitch.filter('[value="' + userLang + '"]').prop('checked', true);
    },

    login: function(event) {
        event.preventDefault();

        this.$('.login-form p.error').slideUp('normal', function() {
            $(this).remove();
        });
        this.$('.login-form fieldset.error').removeClass('error');
        this.$('.login-form [type="submit"]').addClass('is-loading').prop('disabled', true);
        this.$('.login-form input').prop('disabled', true);

        this.model.set({
            email: this.$('[name=email]').val().trim(),
            password: this.$('[name=password]').val(),
        });

        this.trigger('login', this.model);
    },

    showError: function(errorObject) {
        this.$('.login-form p.error').slideUp('normal', function() {
            $(this).remove();
        });
        this.$('.login-form fieldset.error').removeClass('error');
        this.$('.login-form [type="submit"]').removeClass('is-loading').prop('disabled', false);
        this.$('.login-form input').prop('disabled', false);

        if (_.isObject(errorObject)){
            console.log(errorObject);
            for (var err in errorObject) {
                this.$('[name=' + err + ']').parent().addClass('error');
            }
        } else {
            var $msg = $('<p class="error">' + errorObject + '</p>').hide();
            this.$('.login-form h2').after($msg);
            $msg.slideDown();

            setTimeout(function(){
                $msg.slideUp();
            }, 2500);
        }
    },

    getEmail: function() {
        return this.$('[name=email]').val().trim();
    },

    /**
     * Handles the event, when a user changes the language
     * @param event
     */
    handleLanguageChange: function(event) {
        App.user.set('language', this.ui.languageSwitch.filter(':checked').val());
    },
});

module.exports = LoginView;