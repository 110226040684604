'use strict';

var Controller = require('base/Colido.Controller');

var PreferencesLayout = require('./views/layout/preferences');

var PreferencesController = Controller.extend({
    routes: {
        'catalogs/:catalogId/preferences': 'actionList',
    },

    actionList: function(catalogId){
        this.onDataReady(App.CollectionModule.catalogs, function() {
            var catalog = App.CollectionModule.catalogs.get(catalogId);

            if (!catalog) {
                console.log('@todo: forward to catalog 404');

                return;
            }

            App.requireData([
                App.BaseModule.unitsCollection,
            ], function() {
                var region = App.rootView.mainRegion.currentView.contentRegion;
                var layout = new PreferencesLayout({
                    model: catalog,
                });
                region.show(layout);
            });
        })
    },

    // actionPreferences: function(){
    //     this.onDataReady([
    //         'global:currencies',
    //         'base:units',
    //         'base:collection-input-fields',
    //     ], function(ready) {
    //         var region = App.rootView.mainRegion.currentView.contentRegion;
    //         var layout = new PreferencesLayout();

    //         region.show(layout);
    //     });
    // },
});

module.exports = PreferencesController;