module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="post-creation-day">\n    <span>'+
((__t=(date(item.created)))==null?'':_.escape(__t))+
'</span>\n</div>\n\n<div class="post-author-image">\n	<span class="contact-initials">'+
((__t=(_.initials(item.authorName)))==null?'':_.escape(__t))+
'</span>\n    '+
((__t=( responsiveImage(item.profilePicture, imageSize('thumbnailFixed'), viewportSize('grid-object'), item.authorName) ))==null?'':__t)+
'\n</div>\n\n<div class="post-author-name">\n    '+
((__t=(item.authorName))==null?'':_.escape(__t))+
'\n</div>\n\n<div class="post-creation-time">\n    '+
((__t=(time(item.created)))==null?'':_.escape(__t))+
'\n</div>\n\n<div class="post-message">\n	';
  if (item.type === 'text') { 
__p+='\n    	<p>'+
((__t=( autolink(nl2br(item.data))))==null?'':__t)+
'</p>\n	';
  } else if (item.type === 'system') { 
__p+='\n		<p>'+
((__t=( t(item.data.messageKey, item.data.messageData)))==null?'':__t)+
'</p>\n	';
 } 
__p+='\n</div>';
}
return __p;
};