module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (item.url) { 
__p+='\n    <h4 class="set-name">'+
((__t=(item.name))==null?'':_.escape(__t))+
'</h4>\n    <div class="set-image">\n        '+
((__t=( responsiveImage(item.image, imageSize('default'), viewportSize('grid-object'), item.name) ))==null?'':__t)+
'\n    </div>\n    <div class="object-count">\n        '+
((__t=(t('community::share-set:object-count', item.objectCount)))==null?'':_.escape(__t))+
'\n    </div>\n';
 } else { 
__p+='\n    <p>'+
((__t=(t('community::share-set:unavailable')))==null?'':_.escape(__t))+
'</p>\n';
 } 
__p+='\n';
}
return __p;
};