module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(t('collection::objects:filter:finding-date:title')))==null?'':_.escape(__t))+
'</h3>\n\n<form>\n    <fieldset class="filter-range">\n        <label>\n            '+
((__t=(t('collection::objects:filter:finding-date:from:label')))==null?'':_.escape(__t))+
'\n            <select class="component-range-start">\n                ';
 for(var i = 1900; i <= new Date().getFullYear(); i++){ 
__p+='\n                    <option value="'+
((__t=( i ))==null?'':_.escape(__t))+
'">'+
((__t=( i ))==null?'':_.escape(__t))+
'</option>\n                ';
 } 
__p+='\n            </select>\n        </label>\n        <label>\n            '+
((__t=(t('collection::objects:filter:finding-date:to:label')))==null?'':_.escape(__t))+
'\n            <select class="component-range-end">\n                ';
 for(var i = 1901; i < new Date().getFullYear(); i++){ 
__p+='\n                    <option value="'+
((__t=( i ))==null?'':_.escape(__t))+
'">'+
((__t=( i ))==null?'':_.escape(__t))+
'</option>\n                ';
 } 
__p+='\n                <option value="'+
((__t=( new Date().getFullYear() ))==null?'':_.escape(__t))+
'" selected="selected">'+
((__t=( new Date().getFullYear() ))==null?'':_.escape(__t))+
'</option>\n            </select>\n        </label>\n        <button class="clear-range"></button>\n    </fieldset>\n\n    <fieldset class="filter-buttons component-filter-buttons">\n        <fieldset class="filter-button">\n            <input type="checkbox" id="objects-filter-finding-date-unknown" name="objects-filter-finding-date-unknown" value="none" /><label for="objects-filter-finding-date-unknown">'+
((__t=(t('collection::object:finding-date:none:title')))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n    </fieldset>\n</form>';
}
return __p;
};