module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (!item.isGroupChild) { 
__p+='\n    <div class="row row-nospace">\n        <div class="page-col-offset input-help">\n                <span class="create-step-number">&nbsp;</span>\n                <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n        </div>\n        <div class="page-col-3">\n            <label for="field-'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'" class="label-headline">'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</label>\n        </div>\n    </div>\n';
 } 
__p+='\n\n<input\n    type="hidden"\n    name="'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'"\n    id="field-'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'"\n    placeholder="'+
((__t=(item.field.placeholder))==null?'':_.escape(__t))+
'"\n    value="'+
((__t=(item.value))==null?'':_.escape(__t))+
'"\n/>\n\n';
 if(item.value) { 
__p+='\n    <div class="row">\n        <div class="page-offset-default page-col-3">\n            <div class="scanner-tag">\n                <span class="tag-icon" title="'+
((__t=(item.value))==null?'':_.escape(__t))+
'">\n                    '+
((__t=( icon('scanner-tag', null, 'tag-connected') ))==null?'':__t)+
'\n                </span>\n                <p class="tag-hint">\n                    '+
((__t=(t('collection::scanners:objects:detail:status:tag-connected')))==null?'':_.escape(__t))+
'\n                </p>\n            </div>\n        </div>\n        <div class="page-col-3">\n            <button data-action="clear-tag" class="btn-primary">\n                '+
((__t=(t('collection::scanners:objects:detail:button:remove-tag-from-object')))==null?'':_.escape(__t))+
'\n            </button>\n        </div>\n    </div>\n';
 } else { 
__p+='\n    ';
 if(item.scanners.length) { 
__p+='\n        ';
 if(item.scanners.length > 1) { 
__p+='\n            <div class="row">\n                <div class="page-col-3 page-offset-default">\n                    <select data-action="select-active-scanner">\n                        ';
 item.scanners.each(function(scanner){ 
__p+='\n                            <option\n                                value="'+
((__t=(scanner.id))==null?'':_.escape(__t))+
'"\n                                '+
((__t=(when(scanner.id === item.activeScanner.id, 'selected="selected"')))==null?'':_.escape(__t))+
'>\n                                    '+
((__t=(scanner.get('name')))==null?'':_.escape(__t))+
'\n                            </option>\n                        ';
  }) 
__p+='\n                    </select>\n            </div>\n        ';
 } 
__p+='\n        <div class="row">\n            <div class="page-col-3 page-offset-default">\n                <div class="scanner-tag">\n                    <span class="tag-icon"></span>\n                    <p class="tag-hint"></p>\n                </div>\n            </div>\n            <div class="page-col-3">\n                <button data-action="set-tag" class="btn-primary">\n                    '+
((__t=(t('collection::scanners:objects:detail:button:connect-tag-to-object')))==null?'':_.escape(__t))+
'\n                </button>\n            </div>\n        </div>\n    ';
 } else { 
__p+='\n        <div class="row">\n            <div class="page-col-3 page-offset-default">\n                <p>'+
((__t=( t('collection::scanners:objects:detail:scanner-not-fround')))==null?'':__t)+
'</p>\n            </div>\n        </div>\n    ';
 } 
__p+='\n';
 } 
__p+='';
}
return __p;
};