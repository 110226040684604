'use strict';

var Collection = require('base/Colido.Collection');
var DefaultTableColumnModel = require('./DefaultTableColumnModel');

var DefaultTableColumnCollection = Collection.extend({
    api: '/core/columns',
    name: 'base:collection-default-table-column',
    model: DefaultTableColumnModel,
    comparator: function(item) {
        return item.get('order');
    },
});

module.exports = DefaultTableColumnCollection;