module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (item._empty){ 
__p+='\n    <div class="box box-empty box-object-set ';
 if (item.restricted) { 
__p+='box-is-restricted';
 } 
__p+='" data-mode="view" data-empty="true" data-type="set">\n        <div class="box-inner">\n            <div class="empty-view">\n                <div class="headline-wrapper">\n                    <h4 class="action">'+
((__t=( t('collection::set:add-new-set') ))==null?'':_.escape(__t))+
'</h4>\n                </div>\n                <span class="empty-plus" data-action="toggle-mode">+</span>\n            </div>\n            <div class="empty-edit">\n                <form method="post" data-role="form-add-set">\n                    <input type="text"\n                           name="name"\n                           placeholder="'+
((__t=(t('collection::set:edit:name:placeholder')))==null?'':_.escape(__t))+
'"\n                           />\n\n                    <div class="image">\n                        <div class="image-3-2">\n                            <div>\n                                <button type="reset"\n                                       class="btn btn-clean btn-secondary h-pull-left"\n                                       data-action="reset">'+
((__t=(t('collection::set:edit:cancel')))==null?'':_.escape(__t))+
'</button>\n                                <button type="submit"\n                                        class="btn btn-clean btn-primary h-pull-right"\n                                        data-action="create"\n                                        disabled>'+
((__t=(t('collection::set:edit:create')))==null?'':_.escape(__t))+
'</button>\n                            </div>\n                        </div>\n                    </div>\n\n                    <span class="object-count">'+
((__t=( t('collection::set:object-count', 0) ))==null?'':_.escape(__t))+
'</span>\n                </form>\n            </div>\n        </div>\n    </div>\n';
 } else { 
__p+='\n    <a href="'+
((__t=( item.path ))==null?'':_.escape(__t))+
'"\n       class="box box-stacked box-object-set ';
 if (item.displayImage) { 
__p+='box-has-image';
 } 
__p+=' ';
 if (item.restricted) { 
__p+='box-is-restricted';
 } 
__p+='"\n	   data-mode="view"\n	   data-empty="false"\n	   data-type="set"\n	   data-id="'+
((__t=( item.id ))==null?'':_.escape(__t))+
'"\n       data-shared="'+
((__t=( ifelse(item.isShared, 'true', 'false') ))==null?'':_.escape(__t))+
'"\n       >\n    	<div class="box-inner">\n            ';
 if(item.setId) { 
__p+='\n                <h5 class="set-id">'+
((__t=(item.setId))==null?'':_.escape(__t))+
'</h5>\n            ';
 } 
__p+='\n            <div class="headline-wrapper">\n                <h4>'+
((__t=( item.name ))==null?'':_.escape(__t))+
'</h4>\n            </div>\n\n            ';
 if (item.displayImage) { 
__p+='\n                <div class="image">\n                    <div class="image-3-2">\n                        <div>\n                            <div>'+
((__t=( responsiveImage(model.getImage(), imageSize('grid-set')) ))==null?'':__t)+
'</div>\n                        </div>\n                    </div>\n                </div>\n            ';
 } 
__p+='\n\n            <span class="object-count">\n                '+
((__t=( t('collection::set:object-count', item.itemCount) ))==null?'':_.escape(__t))+
'\n            </span>\n\n            ';
 if (Colido.config.allowSharing) { 
__p+='\n                ';
 if (item.isShared) { 
__p+='\n                    <span class="shared-item" data-action="share"><span>'+
((__t=( t('global::item-shared:short') ))==null?'':_.escape(__t))+
'</span>'+
((__t=( icon('share') ))==null?'':__t)+
'</span>\n                ';
 } else { 
__p+='\n                    <button class="btn btn-share-item" data-action="share">\n                        <span>'+
((__t=( t('global::action:share-item') ))==null?'':_.escape(__t))+
'</span>'+
((__t=( icon('share') ))==null?'':__t)+
'\n                    </button>\n                ';
 } 
__p+='\n            ';
 } 
__p+='\n   		</div>\n	</a>\n';
 } 
__p+='';
}
return __p;
};