module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td>'+
((__t=(item.title))==null?'':_.escape(__t))+
'</td>\n<td>'+
((__t=(currency(item.amount, item.currency)))==null?'':_.escape(__t))+
'</td>\n<td>'+
((__t=(item.date))==null?'':_.escape(__t))+
'</td>\n<td>'+
((__t=(item.paymentMethod))==null?'':_.escape(__t))+
'</td>\n<td><a href="'+
((__t=(item.url))==null?'':_.escape(__t))+
'" download="colido-receipt-'+
((__t=(item.receiptNumber))==null?'':_.escape(__t))+
'.pdf">'+
((__t=( icon('download')))==null?'':__t)+
' '+
((__t=(t('subscription::payment-history:table:item:receipt')))==null?'':_.escape(__t))+
'</a></td>\n<td>'+
((__t=(item.receiptNumber))==null?'':_.escape(__t))+
'</td>';
}
return __p;
};