module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (view.getOption('model')) { 
__p+='\n    <h3 class="with-button">\n        '+
((__t=( model.attributes.name ))==null?'':_.escape(__t))+
'\n        <button type="button" class="btn btn-round btn-primary" data-action="select-set">'+
((__t=( icon('deposit') ))==null?'':__t)+
'</button>\n    </h3>\n';
 } 
__p+='\n\n<ul class="list list-selection list-selection-vertical '+
((__t=( when(!view.getOption('isRecent'), 'list-selection-add-support') ))==null?'':_.escape(__t))+
'"></ul>\n\n';
 if (view.getOption('level') < view.getOption('maxLevel') && !view.getOption('isRecent')) { 
__p+='\n    <div class="box box-empty box-add-set '+
((__t=( when(App.user.get('canCreateSet') !== true, 'box-is-restricted') ))==null?'':_.escape(__t))+
'" data-mode="view" data-empty="true">\n        <div class="box-inner">\n            <div class="empty-view">\n                <button type="button" class="btn btn-clean btn-secondary" data-action="toggle-creation">'+
((__t=(t('collection::set:edit:add-new-set:label')))==null?'':_.escape(__t))+
' '+
((__t=( icon('add-small') ))==null?'':__t)+
'</button>\n            </div>\n            <div class="empty-edit">\n                <form method="post" data-role="form-add-location">\n                    <input type="text"\n                           name="name"\n                           placeholder="'+
((__t=(t('collection::set:edit:name:placeholder')))==null?'':_.escape(__t))+
'"\n                           />\n\n                    <div class="empty-edit-controls">\n                        <button type="reset" class="btn btn-clean btn-secondary h-pull-left" data-action="reset">\n                            '+
((__t=(t('collection::set:edit:cancel')))==null?'':_.escape(__t))+
'\n                        </button>\n\n                        <button type="submit" class="btn btn-clean btn-primary h-pull-right" data-action="create" disabled>\n                            '+
((__t=(t('collection::set:edit:create')))==null?'':_.escape(__t))+
'\n                        </button>\n                    </div>\n                </form>\n            </div>\n        </div>\n    </div>\n';
 } 
__p+='';
}
return __p;
};