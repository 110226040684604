module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="showroom-options">\n    <ul>\n        ';
 if (!app.isShare()) { 
__p+='\n            <li class="close">\n                <button class="btn btn-close" data-action="close-showroom">'+
((__t=( icon('close-strong') ))==null?'':__t)+
'</button>\n            </li>\n        ';
 } 
__p+='\n    </ul>\n</div>';
}
return __p;
};