'use strict';

var CollectionView = require('base/Colido.CollectionView');
var ChangeStatusListItemView = require('./change-status-list-item');

var ChangeStatusListView = CollectionView.extend({
    childView: ChangeStatusListItemView,
    tagName: 'ul',
    className: 'list list-selection list-selection-vertical',
});

module.exports = ChangeStatusListView;