module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="manage-storage-modal-container">\n    <header class="modal-header">\n    	<ul>\n			<li>\n				<h2>'+
((__t=(t('collection::locations:manage-storages:title')))==null?'':_.escape(__t))+
'</h2>\n				<h2 class="subline object-count">\n                    '+
((__t=(t('collection::locations:manage-storages:object-count', 0)))==null?'':_.escape(__t))+
'\n				</h2>\n			</li>\n			<li class="actions">\n                <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('collection::locations:manage-storages:cancel')))==null?'':_.escape(__t))+
'</button>\n                <button type="button" class="btn btn-primary" data-action="save" disabled="disabled">'+
((__t=(t('collection::locations:manage-storages:save')))==null?'':_.escape(__t))+
'</button>\n        	</li>\n        </ul>\n    </header>\n    <div class="modal-body">\n        <div class="storages-list">\n\n            <label class="mode-label">\n                '+
((__t=(t('collection::locations:manage-storages:manageMode')))==null?'':_.escape(__t))+
'\n            </label>\n\n            <label class="label-radio">\n\n                <button class="radio" name="managingmode" data-action="manage-mode-arrange" value="arrange" data-checked="checked">\n                    '+
((__t=(t('collection::locations:manage-storages:arrange')))==null?'':_.escape(__t))+
'\n                </button>\n            </label>\n            <label class="label-radio">\n\n                <button class="radio" name="managingmode" data-action="manage-mode-name" value="name">\n                    '+
((__t=(t('collection::locations:manage-storages:name')))==null?'':_.escape(__t))+
'\n                </button>\n            </label>\n\n            <div class="scollLayer">\n                <div class="dd dd-list-nestable">\n                </div>\n\n                <button class="button btn-dashed" data-action="add-new-storage">'+
((__t=(t('collection::locations:manage-storages:add-new-storage')))==null?'':_.escape(__t))+
'</button>\n\n                <p class="info">\n                    '+
((__t=(t('collection::locations:manage-storages:info-text')))==null?'':_.escape(__t))+
'\n                </p>\n            </div>\n\n\n        </div>\n        <div class="storages-trash">\n            <h3>'+
((__t=(t('collection::locations:manage-storages:trash')))==null?'':_.escape(__t))+
'</h3>\n            <div class="dd dd-list-nestable">\n                <ol class="dd-list">\n                </ol>\n            </div>\n        </div>\n    </div>\n</div>';
}
return __p;
};