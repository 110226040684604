'use strict';

var ItemView = require('base/Colido.ItemView');

var template = require('modules/authentication/templates/partials/forgot-password.hbs');

var ForgotPasswordView = ItemView.extend({
    template: template,

    events: {
        'submit .forgot-password-form': 'requestForgotPassword',
    },

    modelEvents: {
        'change': 'render',
    },

    showSuccess: function(message) {
        this.$('p.error').slideUp('normal', function() {
            $(this).remove();
        });
        this.$('fieldset.error').removeClass('error');
        this.$('[type="submit"]').addClass('is-loading').prop('disabled', true);
        this.$('[type="reset"]').prop('disabled', true); //@todo now links, fix code
        this.$('input').prop('disabled', true);

        this.$('fieldset').slideUp();
        this.$('input[type="text"]').slideUp();
        this.$('[type="submit"]').slideUp();
        this.$('[type="reset"]').slideUp();
        var $message = $('<p>').addClass('success').html(message);
        this.$('h2').after($message);
    },

    requestForgotPassword: function(event){
        event.preventDefault();

        this.$('[type="submit"]').addClass('is-loading').prop('disabled', true);
        this.$('[type="reset"]').prop('disabled', true);
        this.$('h2 + .error').remove();

        this.model.set({
            email: this.$('[name=email]').val(),
        });

        if (this.model.isValid()) {
            this.model.save(null, {
                success: function(model, response) {
                    this.showSuccess(App.t('forgot-password::success'));
                }.bind(this),

                error: function(model, response) {
                    //@todo add proper error message
                    var message = response.responseJSON && response.responseJSON.message
                                ? response.responseJSON.message
                                : App.t('forgot-password::unknown-error');

                    this.showError(message);
                }.bind(this),

            });
        } else {
            this.showError(this.model.validate(this.model.attributes));
        }
    },

    showError: function(errorObject) {
        this.$('p.error').slideUp('normal', function() {
            $(this).remove();
        });
        this.$('fieldset.error').removeClass('error');
        this.$('[type="submit"]').removeClass('is-loading').prop('disabled', false);
        this.$('[type="reset"]').prop('disabled', false);
        this.$('input').prop('disabled', false);

        if (_.isObject(errorObject)){
            for (var error in errorObject) {
                this.$('[name=' + error + ']').parent().addClass('error');
            }
        } else {
            var $msg = $('<p class="error">' + errorObject + '</p>').hide();
            this.$('h2').after($msg);
            $msg.slideDown();

            setTimeout(function(){
                $msg.slideUp();
            }, 2500);
        }
    },
});

module.exports = ForgotPasswordView;