'use strict';

var Backbone = require('backbone');
var FastClick = require('fastclick');
var LayoutView = require('base/Colido.LayoutView');
var ModalListView = require('../partials/modal-list');
var MainLayout = require('./main');

var template = require('../../templates/layout/app.hbs');

var AppLayout = LayoutView.extend({
    template: template,

    el: '#app',

    regions: {
        loginRegion: '#login',
        mainRegion: '#main',
        modalsRegion: '#modals',
    },

    events: {
        'click a': 'clickHandler',
        'click #main': 'hideMobileMenu',
    },

    /**
     * Collection for handling modal windows
     * @type {ModalsCollection}
     */
    modalsCollection: null,

    initialize: function() {
        this.render().triggerMethod('attach');

        FastClick.attach(document.body);

        this.listenTo(App.vent, 'logged-in', function() {
            this.mainRegion.show(new MainLayout());
        }.bind(this));

        this.$el.removeClass('loading');

        // Listen for routing to append fragment to body
        this.listenTo(App.router, 'route', function(a) {
            var fragment = Backbone.history.getFragment().replace(/\//g, '-');
            $('body').attr('data-fragment', fragment);
        });

        // Attach modal list view
        this.modalsRegion.show(new ModalListView({
            collection: App.radio().request('modals:collection'),
        }));

        return LayoutView.prototype.initialize.apply(this, arguments);
    },

    isInternalLink: function(event) {
        var target = $(event.currentTarget);
        var href = target.attr('href');

        return href && !href.match(/^([a-z-]+:|\/\/)/) && target.attr('rel') !== 'external';
    },

    clickHandler: function(event) {
        if (this.isInternalLink(event) && !event.isDefaultPrevented()) {
            var href = $(event.currentTarget).attr('href');
            App.router.navigate(href, {trigger: true});
            event.preventDefault();
        }
    },

    addModal: function(content, options) {
        return App.radio().request('modals:collection').add({content: content, options: options}).get('view');
    },

    hasModal: function(content, options) {
        return App.radio().request('modals:collection').length;
    },

    toggleFullscreen: function(event) {
        if (!document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement
        ) { // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    },

    /**
     * Hides the mobile menu
     * @param event
     */
    hideMobileMenu: function(event) {
        var eventTarget = $(event.target);

        var closeMenu = !eventTarget.hasClass('btn-menu-toggle') &&
                        eventTarget.parents('.btn-menu-toggle').length === 0 &&
                        !eventTarget.hasClass('leave-mobile-menu') &&
                        eventTarget.parents('.leave-mobile-menu').length === 0 &&
                        !eventTarget.hasClass('btn-reveal') &&
                        eventTarget.parents('.btn-reveal').length === 0;

        if (closeMenu) {
            $('body').removeClass('has-opened-nav');
        }
    },
});

module.exports = AppLayout;