'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone-marionette');

var PiwikModule = Marionette.Module.extend({
    startWithParent: true,

    /**
     * Instance of the piwik tracker
     * @type {null}|{object}
     */
    piwikTracker: null,

    appendTrackingCode: function() {
        var baseUrl = '//stat.colido.de/';

        window._paq = [];
        _paq.push(['setTrackerUrl', baseUrl + 'piwik.php']);

        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = baseUrl + 'piwik.js';
        document.body.appendChild(script);
    },

    /**
     * Initializes Piwik and returns true, if it was initialized
     * @returns {boolean} True, if piwik was initialized
     */
    piwikInitialized: function () {
        if (!this.piwikTracker
            && window.Piwik
        ) {
            this.piwikTracker = Piwik.getAsyncTracker();

            // Set a proper site ID
            this.piwikTracker.setSiteId(App.config.usePiwik);

            // Base user setup
            if (App.user && App.user.get('id')) {
                this.piwikTracker.setUserId(App.user.get('id'));
                this.piwikTracker.setCustomVariable(1, 'email', App.user.get('email'), 'visit');
            }
        }

        // If we have a piwick tracker, we will set a proper URL
        if (this.piwikTracker) {
            this.piwikTracker.setCustomUrl(Backbone.history.location.href.replace(/^\/|\/$/g, ''));
        }

        return this.piwikTracker !== null;
    },

    onStart: function(options) {
        if (App.config.usePiwik > 0) {
            // Appends the tracking code
            this.appendTrackingCode();

            // Listen for router events
            this.listenTo(App.router, 'route', function() {
                if (this.piwikInitialized()) {
                    this.piwikTracker.trackPageView();
                }
            }.bind(this));

            // Listen for search events
            this.listenTo(App.vent, 'collection:search', function(keyword) {
                if (this.piwikInitialized()) {
                    this.piwikTracker.trackSiteSearch(keyword);
                }
            }.bind(this));

            // Listen for filter events
            this.listenTo(App.vent, 'collection:filter', function(filter) {
                if (this.piwikInitialized() && filter && filter.length) {
                    filter = filter.split('/');

                    // Make sure, we have key/value pairs
                    if (filter.length % 2 === 0) {
                        var filterKeys = _.filter(filter, function(value, idx) {
                            return idx % 2 === 0;
                        });

                        var filterValues = _.filter(filter, function(value, idx) {
                            return idx % 2 === 1;
                        });

                        _.each(_.object(filterKeys, filterValues), function(value, key) {
                            this.piwikTracker.trackEvent('filter', key, value);
                        }.bind(this));
                    }
                }
            }.bind(this));

            // Listen object creation events
            this.listenTo(App.vent, 'collection:object-created', function(model) {
                if (this.piwikInitialized()) {
                    this.piwikTracker.trackEvent('collection', 'object-created', model.get('properName'));
                }
            }.bind(this));

            // Listen object edit events
            this.listenTo(App.vent, 'collection:object-edited', function(model) {
                if (this.piwikInitialized()) {
                    this.piwikTracker.trackEvent('collection', 'object-edited', model.get('properName'));
                }
            }.bind(this));

            // Listen for set creation events
            this.listenTo(App.vent, 'collection:set-created', function(model) {
                if (this.piwikInitialized()) {
                    this.piwikTracker.trackEvent('collection', 'set-created', model.get('name'));
                }
            }.bind(this));

            // Listen for storage creation events
            this.listenTo(App.vent, 'collection:storage-created', function(model) {
                if (this.piwikInitialized()) {
                    this.piwikTracker.trackEvent('collection', 'storage-created', model.get('name'));
                }
            }.bind(this));
        }
    },
});

module.exports = PiwikModule;