module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form class="settings-account-form" method="post">\n    <header class="content-header is-sticky">\n        <div class="header-part">\n            <ul>\n                <li>\n                    <h2>'+
((__t=( t('settings::label') ))==null?'':_.escape(__t))+
': '+
((__t=( t('settings::account:label') ))==null?'':_.escape(__t))+
'</h2>\n                </li>\n                <li class="actions">\n                    <button type="submit" class="btn btn-primary" data-action="update-account" disabled>\n                        '+
((__t=( t('settings::account:button:update') ))==null?'':_.escape(__t))+
'\n                    </button>\n                </li>\n            </ul>\n        </div>\n    </header>\n\n    <div class="scroll-container">\n        <div class="container container-dark">\n            <section class="container account-quota-region"></section>\n        </div>\n\n        <div class="page-container page-container--slim">\n            <fieldset class="container basic-account-settings">\n                <h3>'+
((__t=( t('settings::account:headline:general-account-settings') ))==null?'':_.escape(__t))+
'</h3>\n\n                <div class="page-row">\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::account:email:label') ))==null?'':_.escape(__t))+
'</label>\n                        <input name="email" value="'+
((__t=(item.email))==null?'':_.escape(__t))+
'"\n                               placeholder="'+
((__t=( t('settings::account:email:placeholder') ))==null?'':_.escape(__t))+
'"\n                               type="email"\n                               disabled>\n                    </div>\n\n                    ';
 if (Colido.config.allowSignup) { 
__p+='\n                        <div class="page-col-3">\n                            <label>'+
((__t=( t('settings::account:password:label') ))==null?'':_.escape(__t))+
'</label>\n                            <input name="password"\n                                   value="***********"\n                                   placeholder="'+
((__t=( t('settings::account:password:placeholder') ))==null?'':_.escape(__t))+
'"\n                                   type="password"\n                                   disabled>\n\n                            <button class="input-inlinetext"\n                                    type="button"\n                                    data-action="change-password">'+
((__t=( t('settings::account:button:password-change') ))==null?'':_.escape(__t))+
'</button>\n                        </div>\n                    ';
 } 
__p+='\n                </div>\n\n                <div class="page-row">\n                    <div class="page-col-3">\n                        <label>'+
((__t=( t('settings::account:language:label') ))==null?'':_.escape(__t))+
'</label>\n                        <select name="language"></select>\n                    </div>\n                </div>\n            </fieldset>\n\n            <fieldset class="container account-address-settings">\n                <h3>'+
((__t=( t('settings::account:headline:contact-information') ))==null?'':_.escape(__t))+
'</h3>\n                <div class="page-row">\n                    <div class="page-col-3">\n                        <div class="page-row">\n                            <div class="page-col-3">\n                                <label>'+
((__t=( t('settings::account:name:label') ))==null?'':_.escape(__t))+
'</label>\n                                <input name="name" value="'+
((__t=(item.fullname))==null?'':_.escape(__t))+
'"\n                                       placeholder="'+
((__t=( t('settings::account:name:placeholder') ))==null?'':_.escape(__t))+
'"\n                                       type="text">\n                            </div>\n                        </div>\n\n                        <div class="page-row">\n                            <div class="page-col-3">\n                                <label>'+
((__t=( t('settings::profile:address:label') ))==null?'':_.escape(__t))+
'</label>\n                                <input name="address" value="'+
((__t=(item.address ))==null?'':_.escape(__t))+
'"\n                                       placeholder="'+
((__t=( t('settings::profile:address:placeholder') ))==null?'':_.escape(__t))+
'"\n                                       type="text">\n\n                                <input name="address2" value="'+
((__t=(item.address2 ))==null?'':_.escape(__t))+
'"\n                                       placeholder="'+
((__t=( t('settings::profile:address2:placeholder') ))==null?'':_.escape(__t))+
'"\n                                       type="text">\n                            </div>\n                        </div>\n\n                        <div class="page-row">\n                            <div class="page-col-3 zip-city-input-group">\n                                    <label>'+
((__t=( t('settings::profile:zip-city:label') ))==null?'':_.escape(__t))+
'</label>\n                                    <input name="zip" value="'+
((__t=(item.zip ))==null?'':_.escape(__t))+
'"\n                                           placeholder="'+
((__t=( t('settings::profile:zip:placeholder') ))==null?'':_.escape(__t))+
'"\n                                           type="text">\n\n                                    <input name="city" value="'+
((__t=(item.city ))==null?'':_.escape(__t))+
'"\n                                           placeholder="'+
((__t=( t('settings::profile:city:placeholder') ))==null?'':_.escape(__t))+
'"\n                                           type="text">\n                            </div>\n                        </div>\n\n                        <div class="page-row">\n                            <div class="page-col-3">\n                                <label>'+
((__t=( t('settings::profile:country:label') ))==null?'':_.escape(__t))+
'</label>\n                                <select name="country"></select>\n                            </div>\n                        </div>\n\n                        <div class="page-row">\n                            <div class="page-col-3">\n                                <label>'+
((__t=( t('settings::profile:tele:label') ))==null?'':_.escape(__t))+
'</label>\n                                <input name="telefon" value="'+
((__t=(item.phone))==null?'':_.escape(__t))+
'"\n                                       placeholder="'+
((__t=( t('settings::profile:tele:placeholder') ))==null?'':_.escape(__t))+
'"\n                                       type="text">\n                            </div>\n                        </div>\n                    </div>\n                    ';
 if (Colido.config.allowSignup) { 
__p+='\n                        <div class="page-col-2 col-gap">\n                            <p class="hint">\n                                '+
((__t=( t('settings::account:hint:contact-information-private') ))==null?'':_.escape(__t))+
'\n                            </p>\n                        </div>\n                    ';
 } 
__p+='\n                </div>\n            </fieldset>\n\n            ';
 if (Colido.config.allowSignup) { 
__p+='\n                <fieldset class="container notifications">\n                    <h3>'+
((__t=(t('settings::notifications:headline')))==null?'':_.escape(__t))+
'</h3>\n                    <ul class="checkbox-list">\n                        <li>\n                            <input type="checkbox" id="notification-account" checked="checked" disabled="disabled" />\n                            <label for="notification-account">'+
((__t=(t('settings::notifications:checkbox:label:account')))==null?'':_.escape(__t))+
'</label>\n                        </li>\n                        <li>\n                            <input type="checkbox" name="notificationCompanyNews" value="true" id="notification-company-news" '+
((__t=( when(item.notificationCompanyNews, 'checked="checked"')))==null?'':__t)+
' />\n                            <label for="notification-company-news">'+
((__t=(t('settings::notifications:checkbox:label:company-news')))==null?'':_.escape(__t))+
'</label>\n                        </li>\n                        <li>\n                            <input type="checkbox" name="notificationProductUpdates" value="true" id="notification-product-updates" '+
((__t=( when(item.notificationProductUpdates, 'checked="checked"')))==null?'':__t)+
' />\n                            <label for="notification-product-updates">'+
((__t=(t('settings::notifications:checkbox:label:product-updates')))==null?'':_.escape(__t))+
'</label>\n                        </li>\n                    </ul>\n                    <p class="hint">\n                        '+
((__t=(t('settings::notifications:hint')))==null?'':_.escape(__t))+
'\n                    </p>\n                </fieldset>\n            ';
 } 
__p+='\n\n            <section class="container container-submit">\n                <div class="page-col-3">\n                    <fieldset class="submit">\n                        <button type="submit" class="btn btn-primary" data-action="update-account" disabled>\n                            '+
((__t=( t('settings::account:button:update') ))==null?'':_.escape(__t))+
'\n                        </button>\n                    </fieldset>\n                </div>\n\n                ';
 if (Colido.config.allowSignup) { 
__p+='\n                    <div class="page-col-3 text-align-right">\n                        <fieldset class="submit">\n                            <button type="submit" class="btn btn-danger btn-clean" data-action="delete-account">\n                                '+
((__t=( t('settings::account:button:delete-account') ))==null?'':_.escape(__t))+
'\n                            </button>\n                        </fieldset>\n                    </div>\n                ';
 } 
__p+='\n            </section>\n        </div>\n    </div>\n</form>';
}
return __p;
};