module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="modal-share-set page-container '+
((__t=( when(view.model.isShared(), 'link-access-active') ))==null?'':_.escape(__t))+
'">\n    <header class="modal-header">\n        <div class="header-part">\n            <ul>\n                <li>\n                    <h2>'+
((__t=( t('collection::set:headline:share', {name: view.model.get('name')}) ))==null?'':_.escape(__t))+
'</h2>\n                    <h2 class="subline">\n                        '+
((__t=( t('global::objects:count', view.model.get('items').length) ))==null?'':_.escape(__t))+
'\n                    </h2>\n                </li>\n                <li class="actions">\n                    <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('global::modal:button:close')))==null?'':_.escape(__t))+
'</button>\n                </li>\n            </ul>\n        </div>\n    </header>\n\n    <div class="modal-body">\n        <div class="modal-content container">\n            <div class="region-share-main-settings">\n                <div class="row row-link-access">\n                    <div class="page-col-4">\n                        <label for="sharing">'+
((__t=( t('collection::set:share:status') ))==null?'':_.escape(__t))+
'</label>\n                        <div class="option-select">\n                            <input id="external-link-access-active" type="radio" name="externalLinkAccess" value="1" '+
((__t=( when(view.model.isShared(), 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="external-link-access-active" class="option-select-secondary">'+
((__t=( t('collection::set:share:status:active') ))==null?'':_.escape(__t))+
'</label>\n\n                            <input id="external-link-access-disabled" type="radio" name="externalLinkAccess" value="0" '+
((__t=( when(!view.model.isShared(), 'checked') ))==null?'':_.escape(__t))+
'>\n                            <label for="external-link-access-disabled" class="option-select-secondary">'+
((__t=( t('collection::set:share:status:disabled') ))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </div>\n                </div>\n                <div class="row row-share-url">\n                    <div class="page-col-4">\n                        <div class="input-button-group">\n                            <input type="text" name="shareLink" id="share-link" value="" class="input-no-focus" readonly>\n                            <button type="button" class="btn btn-m btn-primary" data-action="copy-link" data-clipboard-target="#share-link">'+
((__t=( t('collection::set:share:copy-link') ))==null?'':_.escape(__t))+
'</button>\n                        </div>\n                    </div>\n                    <!--\n                    <div class="page-col-2">\n                        <button type="button" class="btn btn-m btn-primary-used" data-action="share-options">\n                            '+
((__t=( t('collection::set:share:button:sharing-options') ))==null?'':_.escape(__t))+
'\n                        </button>\n                    </div>\n                    -->\n                </div>\n                <div class="row row-introduction">\n                    <div class="page-col-4">\n                        <p>\n                            '+
((__t=( t('collection::set:share:info') ))==null?'':_.escape(__t))+
'\n                            <br />\n                            <a href="" data-action="open-link" target="_blank">'+
((__t=( t('collection::set:share:extern-link') ))==null?'':_.escape(__t))+
'</a>\n                        </p>\n                    </div>\n                </div>\n\n                <div class="row row-share-options share-column-settings-region"></div>\n\n                <div class="row row-share-options">\n                    <div class="page-col-4">\n                        <label>'+
((__t=( t('collection::set:share:label:share-label') ))==null?'':_.escape(__t))+
'</label>\n                        <button type="button" class="btn btn-m btn-primary" data-action="share-label">'+
((__t=( t('collection::set:share:label:share-label-button') ))==null?'':_.escape(__t))+
'</button>\n                    </div>\n                </div>\n                <div class="row row-share-options">\n                    <div class="page-col-4">\n                        <h3>'+
((__t=( t('collection::set:share:label:share-by') ))==null?'':_.escape(__t))+
'</h3>\n                        ';
 if (App.CommunityModule) { 
__p+='\n                            <button type="button" class="btn btn-m btn-primary btn-colido-talk" data-action="share-colido-talk">\n                                '+
((__t=(t('share::by-colido-talk:label')))==null?'':_.escape(__t))+
'\n                            </button>\n                        ';
 } 
__p+='\n                        <button type="button" class="btn btn-m btn-primary" data-action="share-email">\n                            '+
((__t=(t('share::by-mail:label')))==null?'':_.escape(__t))+
'\n                        </button>\n                        <button type="button" class="btn btn-m btn-primary btn-facebook" data-action="share-facebook">\n                            '+
((__t=(t('share::by-facebook:label')))==null?'':_.escape(__t))+
'\n                        </button>\n                        <button type="button" class="btn btn-m btn-primary btn-twitter" data-action="share-twitter">\n                            '+
((__t=(t('share::by-twitter:label')))==null?'':_.escape(__t))+
'\n                        </button>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';
}
return __p;
};