'use strict';

var LayoutView = require('base/Colido.LayoutView');
var GroupsView = require('../partials/groups');
var GroupFormView = require('modules/community/views/partials/group-form');

var template = require('modules/community/templates/layout/groups.hbs');

var GroupsLayoutView = LayoutView.extend({
    template: template,

    regions: {
        groupFormRegion: '.group-form-region',
        groupsRegion: '.groups-region',
    },

    ui: {
        'groupsCount': '.groups-count',
        'showCreateGroupForm': '[data-action="show-create-group-form"]',
        'groupsMetaInfo': '.groups-metainfo',
    },

    events: {
        'click @ui.showCreateGroupForm': 'showCreateGroupForm',
    },

    type: 'normal',

    initialize: function() {
        this.groups = this.getOption('groups');
    },

    onRender: function() {
        this.groupsView = new GroupsView({
            collection: this.groups,
            activeConversationId: this.getOption('activeConversationId'),
            type: this.getOption('type'),
        });

        this.groupsRegion.show(this.groupsView);

        this.updateGroupsCount();
        this.listenTo(this.groups, 'add remove', this.updateGroupsCount.bind(this));
    },

    updateGroupsCount: function() {
        var count = this.groupsView._showingEmptyView ? 0 : this.groupsView.children.length;
        var countString = App.t(this.getOption('type') === 'broadcast' ? 'community::broadcasts-count' : 'community::groups-count', count);

        this.ui.groupsCount.text(countString);
    },

    showCreateGroupForm: function() {
        var groupFormView = new GroupFormView({
            model: new this.groups.model({type: this.getOption('type')}),
        });

        this.groupFormRegion.show(groupFormView);
        this.listenTo(groupFormView, 'group:saved', this.hideCreateGroupForm.bind(this));

        this.ui.groupsMetaInfo.addClass('show-form');
    },

    hideCreateGroupForm: function() {
        this.ui.groupsMetaInfo.removeClass('show-form');
        this.ui.showCreateGroupForm.focus();
    },
});

module.exports = GroupsLayoutView;