module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="listcontrol-wrapper listcontrol-wrapper-default">\n    <ul>\n        ';
 if (view.getOption('hasFilter')) { 
__p+='\n            <li class="filter-button">\n                <button class="btn btn-filter '+
((__t=(when(model.get('filter'), 'active')))==null?'':_.escape(__t))+
'" data-action="open-filter">\n                    '+
((__t=(t('collection::objects:filter:button')))==null?'':_.escape(__t))+
'\n                    '+
((__t=( icon('filter') ))==null?'':__t)+
'\n                </button>\n            </li>\n\n            ';
 } 
__p+='\n        <li class="view-switch-region hidden-if-empty"></li>\n        <li class="list-sort-select-wrapper hidden-if-empty">\n            <button class="btn btn-tertiary" data-action="open-table-settings-modal">\n                '+
((__t=(t('collection::column-filter:button')))==null?'':_.escape(__t))+
'\n            </button>\n        </li>\n        <li class="grid-sort-select-wrapper hidden-if-empty">\n            <label>'+
((__t=(t('collection::list-control:sort-by:label')))==null?'':_.escape(__t))+
':</label>\n            <select name="sort" class="grid-sort-select">\n                ';
 _.each(item.gridViewSortableFields, function(field){ 
__p+='\n                    <option value="'+
((__t=(field.name))==null?'':_.escape(__t))+
'.'+
((__t=(field.gridview.sortDirection))==null?'':_.escape(__t))+
'" '+
((__t=(when(model.get('sortGrid') === field.name + '.' + field.gridview.sortDirection, 'selected')))==null?'':_.escape(__t))+
'>\n                        '+
((__t=(field.gridview.label))==null?'':_.escape(__t))+
'\n                    </option>\n                ';
 }) 
__p+='\n            </select>\n        </li>\n        ';
 if (!view.getOption('disableShowroomViewSwitch')) { 
__p+='\n            <li class="showroom-button hidden-if-empty">\n                <button class="btn btn-secondary" alt="Launch Showroom" data-action="launch-showroom">\n                    <span>'+
((__t=(t('collection::objects:view:button:showroom')))==null?'':_.escape(__t))+
'</span>'+
((__t=( icon('view-showroom') ))==null?'':__t)+
'\n                </button>\n            </li>\n        ';
 } 
__p+='\n        ';
 if (view.getOption('showListActions')) { 
__p+='\n            <li class="actions-button hidden-if-empty">\n                <button class="btn btn-secondary btn-secondary-2 btn-caret" data-action="show-additional-actions">\n                    '+
((__t=(t('collection::objects:view:button:actions')))==null?'':_.escape(__t))+
'\n                </button>\n            </li>\n            <li class="manage-button hidden-if-empty">\n                <button class="btn btn-secondary btn-secondary-2" data-action="manage-objects" data-targetstate="manage">\n                    '+
((__t=(t('collection::objects:view:button:manage')))==null?'':_.escape(__t))+
'\n                </button>\n            </li>\n        ';
 } 
__p+='\n    </ul>\n\n    <div class="active-filters-region"></div>\n</div>\n\n<div class="additional-action-layer">\n    <h3>'+
((__t=(t('collection::objects:view:button:actions')))==null?'':_.escape(__t))+
'</h3>\n    <button type="button" class="btn btn-close">'+
((__t=( icon('close-light') ))==null?'':__t)+
'</button>\n    <ul>\n        ';
 if (view.showEditOption && view.showEditOption()) { 
__p+='\n            <li>\n                <button data-action="trigger-edit">\n                    '+
((__t=( view.getDropdownLabel('edit') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n        ';
 } 
__p+='\n\n        ';
 if (view.showDeleteOption && view.showDeleteOption()) { 
__p+='\n            <li>\n                <button data-action="trigger-delete">\n                    '+
((__t=( view.getDropdownLabel('delete') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n        ';
 } 
__p+='\n\n        ';
 if (view.showShareOption && view.showShareOption()) { 
__p+='\n            <li>\n                <button data-action="trigger-share">\n                    '+
((__t=( view.getDropdownLabel('share') ))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n        ';
 } 
__p+='\n\n        <li>\n            <button data-action="manage-objects" data-targetstate="manage">\n                '+
((__t=(t('collection::objects:view:button:manage')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n\n        <li>\n            <button data-action="print-objects-pdf">\n                '+
((__t=(t('collection::objects:view:button:print-list')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n\n        <li>\n            <button data-action="print-objects-label">\n                '+
((__t=(t('collection::objects:view:button:print-label', 2)))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n\n        <li>\n            <button data-action="print-objects-csv">\n                '+
((__t=(t('collection::objects:view:button:export')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n    </ul>\n</div>\n\n<div class="listcontrol-wrapper listcontrol-wrapper-manage">\n    <ul>\n        <li>\n            <button class="btn-clean" data-action="select-all">\n                '+
((__t=(t('collection::list-control:manage-objects:select-all')))==null?'':_.escape(__t))+
'\n            </button>\n        </li>\n        <li>\n            <button class="btn-clean btn-caret" data-action="show-actions" disabled>\n                '+
((__t=(t('collection::list-control:manage-objects:actions')))==null?'':_.escape(__t))+
'\n            </button>\n        </li>\n        <li>\n            <button class="btn btn-clean btn-danger" data-action="delete" disabled>\n                '+
((__t=(t('collection::list-control:manage-objects:delete')))==null?'':_.escape(__t))+
'\n            </button>\n        </li>\n        <li class="close">\n            <span class="hint">\n                '+
((__t=(t('collection::list-control:manage-objects:please-select-some-objects')))==null?'':_.escape(__t))+
'\n            </span>\n            <button class="btn-close-text btn-primary btn-round" data-action="close" data-targetstate="default">\n                '+
((__t=(t('collection::list-control:manage-objects:done')))==null?'':_.escape(__t))+
' '+
((__t=( icon('close-light') ))==null?'':__t)+
'\n            </button>\n        </li>\n    </ul>\n</div>\n\n<div class="action-layer">\n    <h3>'+
((__t=(t('collection::objects:view:button:actions')))==null?'':_.escape(__t))+
'</h3>\n    <button type="button" class="btn btn-close">'+
((__t=( icon('close-light') ))==null?'':__t)+
'</button>\n    <ul>\n        <li>\n            <button data-action="add-to-set">\n                '+
((__t=(t('collection::list-control:manage-objects:add-to-set')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n        ';
 if (view.showRemoveFromSetOption && view.showRemoveFromSetOption()) { 
__p+='\n            <li>\n                <button data-action="remove-from-set">\n                    '+
((__t=(t('collection::list-control:manage-objects:remove-from-set')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n                </button>\n            </li>\n        ';
 } 
__p+='\n        <li '+
((__t=( when(!App.user.get('licenseStorages'), 'class="restricted"')))==null?'':__t)+
'>\n            <button data-action="update-location">\n                '+
((__t=(t('collection::list-control:manage-objects:update-location')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n        <li>\n            <button data-action="change-status">\n                '+
((__t=(t('collection::list-control:manage-objects:change-status')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n        <li>\n            <button data-action="print-objects-pdf">\n                '+
((__t=(t('collection::objects:view:button:print-list')))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n        <li>\n            <button data-action="print-objects-label">\n                '+
((__t=(t('collection::objects:view:button:print-label', 2)))==null?'':_.escape(__t))+
' '+
((__t=( icon('next-small') ))==null?'':__t)+
'\n            </button>\n        </li>\n    </ul>\n</div>';
}
return __p;
};