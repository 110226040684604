'use strict';

var Controller = require('base/Colido.Controller');

var TrashLayout = require('./views/layout/trash');
var TrashCollection = require('./collections/trash');

var TrashController = Controller.extend({
    routes: {
        'catalogs/:catalogId/trash(/sort/:sort)': 'actionList',
    },

    actionList: function(catalogId){
        this.onDataReady(App.CollectionModule.catalogs, function() {
            var catalog = App.CollectionModule.catalogs.get(catalogId);

            App.requireData([
                catalog.trash,
            ], function() {
                var region = App.rootView.mainRegion.currentView.contentRegion;
                var layout = new TrashLayout({
                    model: catalog,
                    collection: catalog.trash,
                });
                region.show(layout);
            });
        })
    },
});

module.exports = TrashController;