'use strict';

var Html = require('base/Colido.Html');
var ItemView = require('base/Colido.ItemView');

var template = require('modules/collection/templates/partials/list-table-item.hbs');

var ListTableItemView = ItemView.extend({
    template: template,

    tagName: 'tr',

    className: function() {
        var classes = [];

        if (this.model.get('selected') === true) {
            classes.push('is-selected');
        }

        if (this.isActive === true) {
            classes.push('is-active');
        }

        return classes.join(' ');
    },

    ui: {
        'cellLink': 'td a',
        'tableCell': 'td',
    },

    events: {
        'click': 'clickLink',
        'click @ui.cellLink': 'clickLink',
        'click @ui.tableCell': 'clickTableCell',
    },

    modelEvents: {
        'sync': 'render updateClassName',
        'change': 'render updateClassName',
    },

    viewData: {
        fields: function() {
            return this.getOption('fields');
        },
    },

    onShow: function() {
        if (this.isActive === true) {
            this.activate();
        }
    },

    getFormattedModelData: function(field, options){
        if (this.formatters[field.type]) {
            return this.formatters[field.type].bind(this)(field, options);
        } else {
            console.warn('Missing formatter for field type', field.type, 'in field', field.name);

            return _.escape(this.model.get(field.name));
        }
    },

    //NEEDS TO RETURN ESCAPED DATA!
    formatters: {
        group: function(field, options) {
            var text = [];
            _.each(field.sourceFields, function(sourceFieldName){
                text.push(this.model.getTextValue(sourceFieldName));
            }.bind(this))

            return _.compact(text).join(field.tableview.mergeSeparator || ', ');
        },

        datetime: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        string: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        text: function(field, options) {
            var data = this.model.get(field.name);

            if (options && _.isNumber(options.truncate)) {
                data = _.truncate(data, options.truncate);
            }

            return _.escape(data);
        },

        html: function(field, options) {
            var data = this.model.get(field.name);

            return data;
        },

        enum: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        colors: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        weight: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        length: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        monetaryValue: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        dimensions: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        dimensionType: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        locality: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        images: function(field, options) {
            var objectBasePath = this.getOption('state').getPath() + '/show/';
            var objectUrl = objectBasePath + this.model.id;

            return Html.link({
                url: objectUrl,
                classes: '',
                label: Html.responsiveImage(this.model.getImage(0), this.imageSizes.table),
            });
        },

        species: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        sets: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        number: function(field, options) {
            return this.model.getTextValue(field.name);
        },

        //@addfield

        // obtained: function(){
        //     return this.model.get('obtained'); // is jetzt net so toll, dass ich das manuel weiterleiten muss..
        // },

        // obtainedDate: function(){
        //     return this.model.get('obtainedDate');
        // },

        // obtainedFrom: function(){
        //     return this.model.get('obtainedFrom');
        // },

        // properPurchasingPrice: function() {
        //     if (this.model.get('objectHistory').length) {
        //         var historyEntry = this.model.get('objectHistory')[0];
        //         if (historyEntry.price) {
        //             return Html.formatCurrency(historyEntry.price, historyEntry.currency);
        //         }
        //     }

        //     return '';
        // },
    },

    /**
     * Is triggered when the object is clicked
     * @param {object} event The click/tap event
     */
    clickLink: function(event) {
        event.preventDefault();

        var link = $(event.currentTarget);

        if (!link.is('a') || !link.attr('href')) {
            return;
        }

        // Switch selected state
        if (this.options.isManageMode()) {
            if (!link.parents('td').length) {
                // Super hidden feature
                this.checkRangeSelection(event);
                this.model.set('selected', !this.model.get('selected'));
            }
        } else {
            var url = $(event.currentTarget).attr('href');
            App.router.navigate(url, {trigger: true});
        }
    },

    /**
     * Checks, if a range should be selected
     * @param {object} event The click event
     */
    checkRangeSelection: function(event) {
        if (event.originalEvent.shiftKey
            && !this.model.get('selected')
            && this.options.isManageMode()
        ) {
            // Clear a selection from the user
            this.clearUserSelection();

            var relatedCollection = this._parent.collection;
            var position = relatedCollection.indexOf(this.model);
            var positionSelected = -1;

            if (position > 0) {
                var idx = position;
                while (positionSelected < 0 && idx > 0) {
                    idx--;
                    if (relatedCollection.at(idx).get('selected')) {
                        positionSelected = idx;
                    }
                }

                if (positionSelected >= 0) {
                    for (var i = positionSelected; i < position; i++) {
                        relatedCollection.at(i).set('selected', true);
                    }
                }
            }
        }
    },

    /**
     * Is triggereden when a cell is clicked in viewmode list
     * @param {object} event The click/tap event
     */
    clickTableCell: function(event) {
        event.preventDefault();
        if (this.options.isManageMode()) {
            this.checkRangeSelection(event);
            this.model.set('selected', !this.model.get('selected'));
        } else {
            var url = $(event.currentTarget).parents('tr').find('a').attr('href');
            if (url) {
                App.router.navigate(url, {trigger: true});
            }
        }
    },

    updateClassName: function() {
        this.$el.removeClass().addClass(_.result(this, 'className'));
    },

    activate: function() {
        this.isActive = true;
        this.$el.addClass('is-active');
        this.trigger('activated');
    },

    deactivate: function() {
        this.isActive = false;
        this.$el.removeClass('is-active');
        this.trigger('deactivated');
    },
});

module.exports = ListTableItemView;