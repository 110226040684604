module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header is-sticky">\n    <h2>'+
((__t=(t('subscription::setup:title')))==null?'':_.escape(__t))+
'</h2>\n    <h2 class="subline">'+
((__t=(t('subscription::setup:subline')))==null?'':_.escape(__t))+
'</h2>\n\n    <div class="actions">\n        <a href="/account/subscription" class="btn btn-secondary">'+
((__t=(t('subscription::cancel')))==null?'':_.escape(__t))+
'</a>\n        <div class="btn-submit-wrapper" data-gravity="e">\n            <button class="btn btn-primary" data-action="next-step">'+
((__t=(t('subscription::setup:next:button')))==null?'':_.escape(__t))+
'</button>\n            <div class="btn-submit-wrapper-layer"></div>\n        </div>\n    </div>\n</header>\n<div class="scroll-container">\n    ';
 if(item.setupStep > 0) { 
__p+='\n        <div class="container container-dark">\n            <section class="subscription-details-plan-display-region"></section>\n            <section class="subscription-details-payment-method-display-region"></section>\n            <section class="subscription-details-billing-address-display-region"></section>\n            <section class="subscription-details-residency-country-display-region"></section>\n        </div>\n    ';
 } 
__p+='\n\n    <div class="container">\n        <section class="subscription-details-edit-region"></section>\n\n        <section class="submit">\n            ';
 if (view.isReadyToPay()) { 
__p+='\n                <p>\n                    '+
((__t=(t('subscription::setup:check-before-pay:hint')))==null?'':_.escape(__t))+
'\n                </p>\n            ';
 } 
__p+='\n\n            <button class="btn btn-primary" data-action="next-step">'+
((__t=(t('subscription::setup:next:button')))==null?'':_.escape(__t))+
'</button>\n\n            ';
 if (!item.voucherValid && item.setupStep >= 1 && !view.isReadyToPay()) { 
__p+='\n                <button type="button" class="hint link link-border" data-action="enter-voucher-code">'+
((__t=(t('subscription::setup:enter-voucher-code:button')))==null?'':_.escape(__t))+
'</button>\n            ';
 } 
__p+='\n        </section>\n    </div>\n</div>';
}
return __p;
};