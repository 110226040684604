'use strict';

var HeaderView = require('modules/collection/views/layout/header');

var UndefinedStorageHeaderView = HeaderView.extend({
    /**
     *
     * @returns {string} Unescaped Text
     */
    getHeadline: function () {
        return 'Undefined storage';
    },
});

module.exports = UndefinedStorageHeaderView;