'use strict';

var ItemView = require('base/Colido.ItemView');
var template = require('modules/collection/templates/partials/list-table-new-item.hbs');

var ListTableNewItemView = ItemView.extend({
    template: template,

    tagName: 'tr',

    events: {
        'click': 'itemClicked',
    },

    viewData: {
        objectNewPath: function(){
            return this.getOption('state').getPath() + '/new';
        },
    },

    /**
     * Disables link when manage mode is active
     */
    itemClicked: function (event) {
        var isManageMode = this.getOption('isManageMode');

        if (isManageMode()) {
            event.preventDefault();
        }
    },
});

module.exports = ListTableNewItemView;