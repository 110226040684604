module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(t('collection::objects:filter:locality:title')))==null?'':_.escape(__t))+
'</h3>\n\n<form>\n    <fieldset class="filter-buttons component-filter-buttons">\n    	<legend>'+
((__t=(t('collection::objects:filter:locality:common-countries:label')))==null?'':_.escape(__t))+
'</legend>\n        ';
 for (var i = 0; i < item.commonCountries.length; i++) { 
__p+='\n            <fieldset class="filter-button">\n                <input type="checkbox"\n                    id="objects-filter-common-locality-'+
((__t=( item.commonCountries[i].type ))==null?'':_.escape(__t))+
'"\n                    value="'+
((__t=( item.commonCountries[i].type ))==null?'':_.escape(__t))+
'"\n                    />\n                <label for="objects-filter-common-locality-'+
((__t=( item.commonCountries[i].type ))==null?'':_.escape(__t))+
'">\n                    '+
((__t=( item.commonCountries[i].displayName ))==null?'':_.escape(__t))+
'\n                </label>\n            </fieldset>\n        ';
 } 
__p+='\n    </fieldset>\n    <fieldset class="filter-buttons component-filter-buttons">\n    	<legend>'+
((__t=(t('collection::objects:filter:locality:recent-countries:label')))==null?'':_.escape(__t))+
'</legend>\n        ';
 for (var i = 0; i < item.recentCountries.length; i++) { 
__p+='\n            <fieldset class="filter-button">\n                <input type="checkbox"\n                    id="objects-filter-recent-locality-'+
((__t=( item.recentCountries[i].type ))==null?'':_.escape(__t))+
'"\n                    value="'+
((__t=( item.recentCountries[i].type ))==null?'':_.escape(__t))+
'"\n                    />\n                <label for="objects-filter-recent-locality-'+
((__t=( item.recentCountries[i].type ))==null?'':_.escape(__t))+
'">\n                    '+
((__t=( item.recentCountries[i].displayName ))==null?'':_.escape(__t))+
'\n                </label>\n            </fieldset>\n        ';
 } 
__p+='\n    </fieldset>\n    <fieldset class="filter-buttons component-filter-buttons">\n    	<legend>'+
((__t=(t('collection::objects:filter:locality:specific-localities:label')))==null?'':_.escape(__t))+
'</legend>\n        ';
 for (var i = 0; i < item.specificLocalities.length; i++) { 
__p+='\n            <fieldset class="filter-button">\n                <input type="checkbox"\n                    id="objects-filter-specific-locality-'+
((__t=( item.specificLocalities[i].type ))==null?'':_.escape(__t))+
'"\n                    value="'+
((__t=( item.specificLocalities[i].type ))==null?'':_.escape(__t))+
'"\n                    />\n                <label for="objects-filter-specific-locality-'+
((__t=( item.specificLocalities[i].type ))==null?'':_.escape(__t))+
'">\n                    '+
((__t=( item.specificLocalities[i].displayName ))==null?'':_.escape(__t))+
'\n                </label>\n            </fieldset>\n        ';
 } 
__p+='\n        <fieldset>\n        	<input type="search" placeholder="'+
((__t=(t('collection::objects:filter:locality:specific-localities:type-specific-locality')))==null?'':_.escape(__t))+
'" />\n        </fieldset>\n    </fieldset>\n</form>';
}
return __p;
};