'use strict';

var HeaderView = require('modules/collection/views/layout/header');
var SetModel = require('../../models/set');
var ShareSetLayout = require('./share-set-overlay');
var Html = require('base/Colido.Html');

var template = require('modules/collection/modules/set/templates/layout/object-header.hbs');

var ObjectHeaderView = HeaderView.extend({
    template: template,

    ui: {
        'container': 'header',
        'toggleEditMode': '[data-action="toggle-edit"]',
        'headerForm': '#form-edit-set',
        'deleteButton': '.btn[data-action="delete"]',
        'setName': '.set-name',
        'shareButton': '[data-action="share"]',
        'nameInput': '[name="name"]',
        'idInput': '[name="setId"]',
        'priceInput': '[name="setPrice"]',
        'sumFieldSelect': '[name="sumField"]',
        'setCurrency': '[name="setCurrency"]',
        'setDescriptionTextarea': '[name="setDescription"]',
        'showSetDescription': '[data-action="show-set-description"]',
    },

    events: {
        'click @ui.toggleEditMode': 'toggleEditMode',
        'click @ui.setName': 'toggleEditMode',
        'reset @ui.headerForm': 'resetHeaderForm',
        'submit @ui.headerForm': 'submitHeaderForm',
        'click @ui.deleteButton': 'deleteItem',
        'click @ui.shareButton': 'onShareSet',
        'click @ui.showSetDescription': 'toggleSetDescription',
    },

    viewData: {
        objectCount: function() {
            return this.collection.length;
        },

        backLink: function() {
            return this.model.getPath(null, {setModel: true}); //a little hacky: routes to .../sets
        },

        isShared: function() {
            return this.model.get('setModel') instanceof SetModel && this.model.get('setModel').isShared();
        },

        isEditable: function() {
            return this.getOption('editable') && this.model.get('setModel') instanceof SetModel;
        },

        isShareable: function() {
            return this.getOption('shareable') && this.model.get('setModel') instanceof SetModel && Colido.config.allowSharing;
        },

        setModel: function() {
            return this.model.get('setModel');
        },

        monetaryFields: function() {
            var catalog = this.model.get('catalog');
            var monetaryFields = _.where(catalog.get('fields'), {displayType: 'monetaryValue'});

            var returnArray = _.map(monetaryFields, function(field){
                return {name: field.name, label: field.detailview.label};
            });

            return returnArray.sort(function(entry){
                return entry.name === 'retailPrice' ? -1 : 1; //show retailPrice first
            });
        },

        currencies: function() {
            var currencyCollection = App.radio().request('currencies:collection');

            var returnArray = currencyCollection.map(function(currency){
                return {name: currency.get('key'), label: currency.get('name')};
            });

            return returnArray.sort(function(currency){
                return currency.name === App.user.getCurrency() ? -1 : 1; //show user default currency first
            });
        },
    },

    initialize: function (options) {
        HeaderView.prototype.initialize.apply(this, arguments);
        this.listenTo(this.model.get('setModel'), 'change', this.render);

        this.listenTo(this.model, 'change:setModel', function() {
            if (!this.isDestroyed) {
                if (this.model.previous('setModel') instanceof SetModel) {
                    this.stopListening(this.model.previous('setModel'));
                }

                if (this.model.get('setModel')) {
                    this.listenTo(this.model.get('setModel'), 'change', this.render);
                }
            }
        }.bind(this));

        // Listen to edit, delete from listcontrol
        if (this.getOption('editable') && this.model.get('setModel') instanceof SetModel) {
            this.listenTo(App.radio('collection:listcontrol').vent, 'edit', this.toggleEditMode.bind(this));
            this.listenTo(App.radio('collection:listcontrol').vent, 'delete', this.deleteItem.bind(this));
            this.listenTo(App.radio('collection:listcontrol').vent, 'share', this.onShareSet.bind(this));
        }
    },

    /**
     * Toggles the editmode in the header
     * @param {object=} event The event object
     * @return {void}
     */
    toggleEditMode: function(event) {
        if (!$('body').hasClass('is-showroom')) {
            this.ui.container.toggleClass('is-editmode');
            this.ui.nameInput.val(this.model.get('setModel').get('name'));
            if (this.$('header').hasClass('is-editmode')) {
                window.setTimeout(function () {
                    this.ui.nameInput.focus();
                    if (this.ui.nameInput.val().length > 0) {
                        this.ui.nameInput[0].setSelectionRange(0, this.ui.nameInput.val().length);
                    }
                }.bind(this), 400);
            }
        }
    },

    /**
     * Resets the header form
     * @param {object} event The event object
     * @return {void}
     */
    resetHeaderForm: function(event) {
        event.preventDefault();

        this.ui.container.toggleClass('is-editmode');

        var setModel = this.model.get('setModel');

        this.ui.nameInput.val(setModel.get('name'));
        this.ui.idInput.val(setModel.get('setId'));
        this.ui.priceInput.val(setModel.get('setPrice'));
        this.ui.sumFieldSelect.val(setModel.get('sumField'));
        this.ui.setDescriptionTextarea.val(setModel.get('setDescription'));
        this.ui.setCurrency.val(setModel.get('setCurrency'));
    },

    /**
     * Submits the header form
     * @param {object} event The event object
     * @return {void}
     */
    submitHeaderForm: function(event) {
        event.preventDefault();

        this.model.get('setModel').save({
            name: this.ui.nameInput.val(),
            setId: this.ui.idInput.val(),
            setPrice: Number(this.ui.priceInput.val()),
            setCurrency: this.ui.setCurrency.val(),
            sumField: this.ui.sumFieldSelect.val(),
            setDescription: this.ui.setDescriptionTextarea.val(),
        }, {
            success: function(){
                this.render();
                // Messenger().success({
                //     message: App.t('collection::locations:response:change-location', objects.length, {
                //         storageLink: storageLink,
                //         storageType: storageModel.get('storType') === 'status'
                //             ? App.t('collection::locations:label:location').toLowerCase()
                //             : App.t('collection::locations:label:storage').toLowerCase(),
                //         objectLink: objectLink,
                //     }),
                // });
            }.bind(this),
            error: function(){
                // Messenger().error({
                //     message: App.t('collection::locations:response:change-location:error', objects.length, {
                //         storageLink: storageLink,
                //         storageType: storageModel.get('storType') === 'status'
                //             ? App.t('collection::locations:label:location').toLowerCase()
                //             : App.t('collection::locations:label:storage').toLowerCase(),
                //         objectLink: objectLink,
                //     }),
                // });
                this.render();
            }.bind(this),
        });

        // this.ui.setName.html(_.escape(this.getHeadline()));
        // this.ui.container.toggleClass('is-editmode');
    },

    /**
     * Deletes the item
     * @param {object=} event The event object
     */
    deleteItem: function(event) {
        if (event) {
            event.preventDefault();
        }

        // Ask user for confirmation as the action can't be undone
        if (!window.confirm(App.t('collection::set:confirm-delete-set', {name: this.model.get('setModel').get('name')}))) {
            return;
        }

        var catalog = this.model.get('catalog');

        App.router.navigate('/catalogs/' + catalog.id + '/sets', {trigger: true});
        App.radio('collection:set').execute('delete', this.model.get('setModel'));
    },

    /**
     *
     * @returns {string} Unescaped Text
     */
    getHeadline: function () {
        if (this.model.get('setModel') instanceof SetModel) {
            return App.t('collection::set:headline', {name: this.model.get('setModel').get('name')});
        }
    },

    /**
     *
     * @returns {string} Unescaped Text
     */
    getSubline: function () {
        return App.t('collection::objects:count', this.collection.length);
    },

    onShareSet: function() {
        App.addModal(new ShareSetLayout({
            model: this.model.get('setModel'),
        }), {
            additionalClassName: 'modal-left modal-medium modal-share-set',
            onAfterClose: function(data) {
                if (data) {
                    // Todo: Do some really fancy stuff here
                }
            }.bind(this),
        });
    },

    toggleSetDescription: function() {
        this.ui.container.toggleClass('show-description');
    },
});

module.exports = ObjectHeaderView;