'use strict';

var Backbone = require('backbone');

var patchExtension = {
    applyPatchExtension: function () {
        this.updatedModels = {};
        this.on('change:synced', this.onChangeSynced);
    },

    onChangeSynced: function (model, attrs) {
        this.updatedModels[model.id] = model;
    },

    /**
     * Patches all models, that have been changed before
     * @param {object} options Options to pass to sync request.
     * @returns {Backbone.ajax} The xhr request
     */
    save: function(options) {
        options = options || {};
        options.url = this.collection ? this.collection.url() : this.url();

        options.attrs = _.map(this.updatedModels, function(model) {
            return model.updatedAttributes || {};
        });

        var success = options.success;
        var collection = this;
        options.success = function(resp) {
            if (!_.isArray(resp)) {
                return false;
            }

            // Merge collection with new model attributes
            collection.set(resp, _.extend(options, {
                add: false,
                remove: false,
            }));

            collection.clearUpdatedModels();

            if (success) {
                success(collection, resp, options);
            }

            collection.trigger('sync', collection, resp, options);
        };

        return Backbone.sync('patch', this, options);
    },

    /**
     * Clears all updated models
     */
    clearUpdatedModels: function() {
        _.each(this.updatedModels, function(model) {
            model.clearUpdatedAttributes();
        });

        this.updatedModels = {};
    },
};

module.exports = patchExtension;