module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 _.each(item.fields, function(field){ 
__p+='\n    <td class="object-column-'+
((__t=(field.type))==null?'':_.escape(__t))+
'">'+
((__t=( view.getFormattedModelData(field, {truncate: 200})))==null?'':__t)+
'</td>\n';
 }) 
__p+='';
}
return __p;
};