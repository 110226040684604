'use strict';

var Marionette = require('backbone-marionette');

Marionette.proxyGetOption = function(optionName, defaultValue) {
    return Marionette.getOption(this, optionName) || defaultValue;
};

Marionette.Behaviors.behaviorsLookup = function() {
    return App.Behaviors;
};