module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\n    <a href="/settings/information" class="btn btn-back">'+
((__t=( icon('prev')))==null?'':__t)+
'</a>\n    <h2>'+
((__t=( t('settings::label') ))==null?'':_.escape(__t))+
': '+
((__t=( t('settings::information:label') ))==null?'':_.escape(__t))+
'</h2>\n    <h2 class="subline">'+
((__t=(t('settings::information:client-informations:label')))==null?'':_.escape(__t))+
'</h2>\n</header>\n\n<div class="container">\n    <dl>\n        <dt>'+
((__t=(t('settings::information:client-informations:client-version')))==null?'':_.escape(__t))+
'</dt>\n        <dd title="'+
((__t=(App.commit))==null?'':_.escape(__t))+
'">'+
((__t=(App.version))==null?'':_.escape(__t))+
'</dd>\n\n        <dt>'+
((__t=(t('settings::information:client-informations:api-url')))==null?'':_.escape(__t))+
'</dt>\n        <dd>'+
((__t=(App.config.apiUrl))==null?'':_.escape(__t))+
'</dd>\n\n        <dt>'+
((__t=(t('settings::information:client-informations:api-version')))==null?'':_.escape(__t))+
'</dt>\n        <dd>'+
((__t=(App.config.apiVersion))==null?'':_.escape(__t))+
'</dd>\n\n        <dt>'+
((__t=(t('settings::information:client-informations:debug-mode')))==null?'':_.escape(__t))+
'</dt>\n        <dd>'+
((__t=(App.config.debug ? "active" : "inactive"))==null?'':_.escape(__t))+
'</dd>\n    </dl>\n</div>';
}
return __p;
};