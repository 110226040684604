module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (item.objectId) { 
__p+='\n    <h5 class="object-id">'+
((__t=(item.objectId))==null?'':_.escape(__t))+
'</h5>\n';
 } 
__p+='\n<div class="headline-wrapper">\n    <h4>'+
((__t=( item.headline))==null?'':__t)+
'</h4>\n    <h4 class="subline">'+
((__t=( item.subheadline))==null?'':__t)+
'</h4>\n</div>\n\n<div class="image">\n    <div class="image-3-2">\n        <div>\n            '+
((__t=( responsiveImage(model.getImage(0), imageSize('grid-object'), viewportSize('grid-object'), item.properName, 'grid-image') ))==null?'':__t)+
'\n        </div>\n    </div>\n</div>\n\n';
 if (model.collection.catalog.isShop && item.forSale && item.retailPrice) { 
__p+='\n	<div class="price">\n		'+
((__t=(model.getTextValue('retailPrice')))==null?'':_.escape(__t))+
'\n	</div>\n';
 } 
__p+='';
}
return __p;
};