'use strict';

var CompositeView = require('base/Colido.CompositeView');
var ContactView = require('./contact');
var EmptyView = require('./contacts-empty');

var templateContacts = require('modules/community/templates/partials/contacts.hbs');
var templateContactsPending = require('modules/community/templates/partials/contacts-pending.hbs');

var ContactsView = CompositeView.extend({
    childView: ContactView,

    childViewOptions: function() {
        return {
            activeConversationId: this.getOption('activeConversationId'),
        };
    },

    emptyView: EmptyView,

    childViewContainer: '.contacts-list',

    type: 'accepted',

    collectionEvents: {
        'add remove change': 'render',
    },

    ui: {
        'contactsCount': '.contacts-count',
        'showAddContactsForm': '[data-action="show-add-contacts-form"]',
        'contactsMetaInfo': '.contacts-metainfo',
        'addContactsForm': 'form',
        'newContactEmail': '[name="newContactEmail"]',
        'newContactMessage': '[name="newContactMessage"]',
    },

    events: {
        'click @ui.inviteContacts': 'inviteContacts',
        'click @ui.showAddContactsForm': 'showAddContactsForm',
        'submit @ui.addContactsForm': 'addContacts',
        'input @ui.newContactMessage': 'fixTextareaSize',
    },

    getTemplate: function() {
        return this.getOption('type') === 'accepted' ? templateContacts : templateContactsPending;
    },

    onRender: function() {
        CompositeView.prototype.onRender.apply(this, arguments);

        if (this._showingEmptyView && this.getOption('type') !== 'accepted') {
            this.$el.hide();
        } else {
            this.$el.show();
        }

        this.updateContactsCount();
    },

    onResize: function() {
        this.fixTextareaSize(this.ui.newContactMessage);
    },

    updateContactsCount: function() {
        var count = this._showingEmptyView ? 0 : this.children.length;

        var translationKey = this.getOption('type') === 'accepted' ? 'community::contacts-count' : 'community::contacts-pending-count';

        this.ui.contactsCount.text(App.t(translationKey, count));
    },

    _shouldAddChild: function(child, index) { //fixes double add bug from collectionEvents: add->render
        return CompositeView.prototype._shouldAddChild.apply(this, arguments) && !this.children.findByModel(child);
    },

    showAddContactsForm: function() {
        this.ui.contactsMetaInfo.addClass('show-form');
        this.ui.newContactEmail.focus();
    },

    hideAddContactsForm: function() {
        this.ui.contactsMetaInfo.addClass('show-form');
        this.ui.showAddContactsForm.focus();
    },

    addContacts: function(event) {
        event ? event.preventDefault() : null;

        var contact = this.ui.newContactEmail.val().trim();
        var message = this.ui.newContactMessage.val().trim();

        // var button = $(event ? event.currentTarget : null);
        if (contact.match(/.+@.+/)) {
            App.radio('community').execute('add:contacts', contact, {
                message: message,

                success: function() {
                    if (this.isDestroyed) { return; }

                    this.ui.newContactEmail.val('');
                }.bind(this),

                error: function() {
                    // button.prop('disabled', false).removeClass('is-loading');
                    if (this.isDestroyed) { return; }

                    this.showAddContactsForm();
                }.bind(this),
            });

            this.hideAddContactsForm();
        } else {
            Messenger().error({
                message: App.t('community::contact:response:invalid-email', {name: _.escape(contact)}),
            });
        }

        // button.prop('disabled', true).addClass('is-loading');
        // console.log('addContacts', event)
    },
});

module.exports = ContactsView;