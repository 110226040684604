module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\n    <h2>'+
((__t=( t('settings::label') ))==null?'':_.escape(__t))+
': '+
((__t=( t('settings::manage-catalogs:label') ))==null?'':_.escape(__t))+
'</h2>\n</header>\n\n<div class="container">\n    <section class="create-form-section" data-mode="view">\n        <div class="form-container">\n            <form class="add-catalog">\n                <p>\n                    <input type="text" name="catalog-name" value="'+
((__t=(item.name))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('settings::manage-catalogs:placeholder:catalog-name')))==null?'':_.escape(__t))+
'" />\n                    <select name="catalog-type">\n                        <option value="minerals">'+
((__t=(t('settings::catalogs:type:minerals')))==null?'':_.escape(__t))+
'</option>\n                        <option value="fossils">'+
((__t=(t('settings::catalogs:type:fossils')))==null?'':_.escape(__t))+
'</option>\n                        <option value="coins2">'+
((__t=(t('settings::catalogs:type:coins2')))==null?'':_.escape(__t))+
'</option>\n                        <option value="bills">'+
((__t=(t('settings::catalogs:type:bills')))==null?'':_.escape(__t))+
'</option>\n                        <option value="art">'+
((__t=(t('settings::catalogs:type:art')))==null?'':_.escape(__t))+
'</option>\n                        <option value="generic">'+
((__t=(t('settings::catalogs:type:general')))==null?'':_.escape(__t))+
'</option>\n                    </select>\n                    <select name="catalog-subtype" id="subtype" disabled>\n                    </select>\n                    <input type="checkbox" name="add-sample-data" checked="checked" id="settings-catalogs-add-sample-data" />\n                    <label for="settings-catalogs-add-sample-data">\n                        '+
((__t=(t('settings::catalogs:add-sample-data:label')))==null?'':_.escape(__t))+
'\n                    </label>\n                </p>\n                <p class="submit">\n                    <button type="submit" disabled="disabled">\n                        '+
((__t=(t('settings::manage-catalogs:button:create-catalog')))==null?'':_.escape(__t))+
'\n                    </button>\n                    <button type="reset" data-action="hide-create-form">\n                        '+
((__t=(t('settings::manage-catalogs:button:hide-create-form')))==null?'':_.escape(__t))+
'\n                    </button>\n                </p>\n            </form>\n            <button type="button" data-action="show-create-form">\n                ';
 if(App.CollectionModule.catalogs.length > 0) { 
__p+='\n                    '+
((__t=(t('settings::manage-catalogs:button:show-create-catalog-form')))==null?'':_.escape(__t))+
'\n                ';
 } else { 
__p+='\n                    '+
((__t=(t('settings::manage-catalogs:button:show-create-first-catalog-form')))==null?'':_.escape(__t))+
'\n                ';
 } 
__p+='\n            </button>\n        </div>\n    </section>\n    <section class="catalogs-region container"></section>\n</div>';
}
return __p;
};