module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header set-header" data-state="view">\n    <div class="container-showroom-grid">\n        <div class="header-part header-part-view">\n            ';
 if (view.getOption('backLink') && view.getBackUrl) { 
__p+='\n                <a href="'+
((__t=( view.getBackUrl() ))==null?'':_.escape(__t))+
'" class="btn btn-back">'+
((__t=( icon('prev') ))==null?'':__t)+
'</a>\n            ';
 } else { 
__p+='\n                <a href="'+
((__t=( item.backLink ))==null?'':_.escape(__t))+
'" class="btn btn-back">'+
((__t=( icon('prev') ))==null?'':__t)+
'</a>\n            ';
 } 
__p+='\n\n            <h2>\n                ';
 if (view.getOption('editable')){ 
__p+='\n                    <span class="set-name toggle-headline">'+
((__t=(view.getHeadline()))==null?'':_.escape(__t))+
'</span>\n                ';
 } else { 
__p+='\n                    '+
((__t=(view.getHeadline()))==null?'':_.escape(__t))+
'\n                ';
 } 
__p+='\n\n                ';
 if (item.setModel.get('setId')){ 
__p+='\n                    <span class="set-id">'+
((__t=(item.setModel.get('setId')))==null?'':_.escape(__t))+
'</span>\n                ';
 } 
__p+='\n            </h2>\n            <h2 class="subline">\n                <span id="object-count">'+
((__t=(item.objectCount))==null?'':_.escape(__t))+
'</span>\n                '+
((__t=(view.getSubline()))==null?'':_.escape(__t))+
'\n\n                ';
 if (item.isShared) { 
__p+='\n                    <span class="shared-item" data-action="share">'+
((__t=( t('global::item-shared:long') ))==null?'':_.escape(__t))+
'</span>\n                ';
 } 
__p+='\n            </h2>\n\n            ';
 if(item.setModel.get('setPrice')){ 
__p+='\n                <p class="set-price">'+
((__t=(t('collection::set:label:set-price')))==null?'':_.escape(__t))+
': '+
((__t=(currency(item.setModel.get('setPrice'), item.setModel.get('setCurrency'))))==null?'':_.escape(__t))+
'</p>\n            ';
 } 
__p+='\n\n            ';
 if(item.setModel.get('sumField')){ 
__p+='\n                <p class="set-sum-total">'+
((__t=(t('collection::set:label:set-sum-total')))==null?'':_.escape(__t))+
': '+
((__t=(currency(item.setModel.getSum(), item.setModel.get('setCurrency'))))==null?'':_.escape(__t))+
'</p>\n            ';
 } 
__p+='\n\n            ';
 if(item.setModel.get('setDescription')){ 
__p+='\n                <p class="set-description">\n                    '+
((__t=(item.setModel.get('setDescription')))==null?'':_.escape(__t))+
'\n                    <button data-action="show-set-description" title="'+
((__t=(t('collection::set:description:show-more-text:label')))==null?'':_.escape(__t))+
'">\n                        <span>'+
((__t=(t('collection::set:description:show-more-text:label')))==null?'':_.escape(__t))+
'</span>\n                    </button>\n                </p>\n            ';
 } 
__p+='\n\n            ';
 if (item.isShareable || item.isEditable) { 
__p+='\n                <div class="btn-group">\n                    ';
 if (item.isShareable) { 
__p+='\n                        <button type="button" class="btn btn-primary btn-m" data-action="share">\n                            '+
((__t=(t('collection::set:edit:share')))==null?'':_.escape(__t))+
'\n                        </button>\n                    ';
 } 
__p+='\n                    ';
 if (item.isEditable) { 
__p+='\n                        <button type="button" class="btn btn-secondary btn-m" data-action="toggle-edit">\n                            '+
((__t=(t('collection::set:edit:label')))==null?'':_.escape(__t))+
'\n                        </button>\n                    ';
 } 
__p+='\n                </div>\n            ';
 } 
__p+='\n\n            ';
 if (view.getOption('search')){ 
__p+='\n                <div id="search" class="search-region search-header"></div>\n            ';
 } 
__p+='\n        </div>\n\n        ';
 if (view.getOption('editable')){ 
__p+='\n            <div class="header-part header-part-edit">\n                <form id="form-edit-set" method="post">\n                    <ul>\n                        <li class="input">\n                            <input type="text"\n                                   name="name"\n                                   placeholder="'+
((__t=(t('collection::set:edit:name:placeholder')))==null?'':_.escape(__t))+
'"\n                                   value="'+
((__t=(item.setModel.get('name')))==null?'':_.escape(__t))+
'"\n                                   />\n                            <input type="text"\n                                   name="setId"\n                                   placeholder="'+
((__t=(t('collection::set:edit:number:placeholder')))==null?'':_.escape(__t))+
'"\n                                   value="'+
((__t=(item.setModel.get('setId')))==null?'':_.escape(__t))+
'"\n                                   />\n                            <p>\n                                <label for="setPrice">'+
((__t=(t('collection::set:edit:set-price:label')))==null?'':_.escape(__t))+
'</label>\n                                <input type="text"\n                                       name="setPrice"\n                                       id="setPrice"\n                                       value="'+
((__t=(item.setModel.get('setPrice')))==null?'':_.escape(__t))+
'"\n                                       />\n                                <select name="setCurrency" id="setCurrencyField">\n                                    ';
 _.each(item.currencies, function(currency) { 
__p+='\n                                        <option value="'+
((__t=(currency.name))==null?'':_.escape(__t))+
'" '+
((__t=(when(item.setModel.get('setCurrency') === currency.name, 'selected="selected')))==null?'':_.escape(__t))+
'>'+
((__t=(currency.label))==null?'':_.escape(__t))+
'</option>\n                                    ';
 }) 
__p+='\n                                </select>\n                            </p>\n                            <p>\n                                <label for="setSumField">'+
((__t=(t('collection::set:edit:sum-field:label')))==null?'':_.escape(__t))+
'</label>\n                                <select name="sumField" id="setSumField">\n                                    <option value="" '+
((__t=(when(item.setModel.get('sumField') === '', 'selected="selected')))==null?'':_.escape(__t))+
'>'+
((__t=(t('collection::set:edit:sum-field:no-field-selected')))==null?'':_.escape(__t))+
'</option>\n\n                                    ';
 _.each(item.monetaryFields, function(field) { 
__p+='\n                                        <option value="'+
((__t=(field.name))==null?'':_.escape(__t))+
'" '+
((__t=(when(item.setModel.get('sumField') === field.name, 'selected="selected')))==null?'':_.escape(__t))+
'>'+
((__t=(field.label))==null?'':_.escape(__t))+
'</option>\n                                    ';
 }) 
__p+='\n                                </select>\n                            </p>\n                            <textarea name="setDescription"\n                                placeholder="'+
((__t=(t('collection::set:edit:description:placeholder')))==null?'':_.escape(__t))+
'"\n                                data-autosize="true"\n                                rows="2">'+
((__t=(item.setModel.get('setDescription')))==null?'':_.escape(__t))+
'</textarea>\n                        </li>\n                        <li class="actions">\n                            <button type="submit" class="btn btn-primary btn-m">\n                                '+
((__t=(t('collection::set:edit:done')))==null?'':_.escape(__t))+
'\n                            </button>\n                            <button type="reset" class="btn btn-secondary btn-m">\n                                '+
((__t=(t('collection::set:edit:cancel')))==null?'':_.escape(__t))+
' '+
((__t=( icon('close-light') ))==null?'':__t)+
'\n                            </button>\n                            <button type="button" class="btn btn-clean btn-tertiary btn-danger" data-action="delete">\n                                '+
((__t=(t('collection::set:edit:delete')))==null?'':_.escape(__t))+
' '+
((__t=( icon('trash') ))==null?'':__t)+
'\n                            </button>\n                        </li>\n                    </ul>\n                </form>\n            </div>\n        ';
 } 
__p+='\n\n        ';
 if (view.getOption('showroomOptions')) { 
__p+='\n            <div class="showroom-options-region"></div>\n        ';
 } 
__p+='\n\n        ';
 if (view.getOption('viewSwitch')) { 
__p+='\n            <div class="view-switch-region"></div>\n        ';
 } 
__p+='\n    </div>\n</header>';
}
return __p;
};