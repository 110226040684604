module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="/catalogs/'+
((__t=(model.collection.catalog.id))==null?'':_.escape(__t))+
'/storages/'+
((__t=(item.id))==null?'':_.escape(__t))+
'" class="box box-stacked box-object-location" data-mode="view" data-type="location">\n    <div class="headline-wrapper">\n        <h4>'+
((__t=(item.name))==null?'':_.escape(__t))+
'</h4>\n    </div>\n    <span class="object-count">\n        '+
((__t=(t('collection::location:object-count', model.getItemsRecursive().length)))==null?'':_.escape(__t))+
'\n    </span>\n</a>';
}
return __p;
};