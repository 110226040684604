module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul class="level-2 level-set revealable"></ul>\n<button class="btn btn-reveal">\n    '+
((__t=( icon('up') ))==null?'':__t)+
'\n    '+
((__t=( icon('down') ))==null?'':__t)+
'\n</button>';
}
return __p;
};