module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p>\n    '+
((__t=( t('subscription::setup:info-text') ))==null?'':__t)+
'\n</p>\n\n<form>\n    <fieldset>\n        <legend '+
((__t=( when(view.getOption('isSetup'), 'data-step-number="1"')))==null?'':__t)+
' class="label-billing-cycle">\n            '+
((__t=(t('subscription::setup:choose-billing:label')))==null?'':_.escape(__t))+
'\n        </legend>\n\n        <!-- do not wrap these. will break css sibling magic -->\n        <input\n            type="radio"\n            name="subscription-billing-interval"\n            value="monthly"\n            id="subscription-billing-interval-monthly"\n            '+
((__t=(when(item.subscriptionActive && item.currentBillingInterval === 'monthly' || !item.subscriptionActive && item.upcomingBillingInterval === 'monthly', 'checked="checked"')))==null?'':_.escape(__t))+
'\n        />\n        <label for="subscription-billing-interval-monthly" class="btn">\n            '+
((__t=(t('subscription::setup:choose-billing:monthly:label')))==null?'':_.escape(__t))+
'\n        </label>\n\n        <input\n            type="radio"\n            name="subscription-billing-interval"\n            value="annually"\n            id="subscription-billing-interval-annually"\n            '+
((__t=(when(item.subscriptionActive && item.currentBillingInterval === 'annually' || !item.subscriptionActive && item.upcomingBillingInterval === 'annually', 'checked="checked"')))==null?'':_.escape(__t))+
'\n        />\n        <label for="subscription-billing-interval-annually" class="btn">\n            '+
((__t=(t('subscription::setup:choose-billing:annually:label')))==null?'':_.escape(__t))+
'\n            <span class="hint">'+
((__t=(t('subscription::setup:choose-billing:annually:hint')))==null?'':_.escape(__t))+
'</span>\n        </label>\n\n        <fieldset class="plans">\n            <legend '+
((__t=( when(view.getOption('isSetup'), 'data-step-number="2"')))==null?'':__t)+
'>\n                '+
((__t=(t('subscription::setup:choose-a-plan')))==null?'':_.escape(__t))+
'\n            </legend>\n\n            <ul>\n                ';
 for (var i = 0; i < item.plans.length; i++) { 
__p+='\n                    <li class="plan">\n                        <input\n                            type="radio"\n                            name="subscription-plan"\n                            id="subscription-plan-'+
((__t=(item.plans[i].id))==null?'':_.escape(__t))+
'"\n                            value="'+
((__t=(item.plans[i].id))==null?'':_.escape(__t))+
'"\n                            '+
((__t=(when(item.subscriptionActive && item.plans[i].active || !item.subscriptionActive && item.plans[i].selected, 'checked="checked"')))==null?'':_.escape(__t))+
'\n                        />\n                        <label for="subscription-plan-'+
((__t=(item.plans[i].id))==null?'':_.escape(__t))+
'">\n                            <span>'+
((__t=(item.plans[i].name))==null?'':_.escape(__t))+
'</span>\n                        </label>\n                        <div class="info-header">\n                            <h3>'+
((__t=(item.plans[i].name))==null?'':_.escape(__t))+
'</h3>\n                            <h4>'+
((__t=(item.plans[i].subline))==null?'':_.escape(__t))+
'</h4>\n                            <div class="price-monthly">\n                                ';
 if (item.plans[i].amountMonthlyTotal > 0) { 
__p+='\n                                    <em>'+
((__t=(item.plans[i].amountMonthlyTotal))==null?'':_.escape(__t))+
'</em> '+
((__t=(item.plans[i].currency))==null?'':_.escape(__t))+
' '+
((__t=(t('subscription::setup:choose-a-plan:per-month:hint')))==null?'':_.escape(__t))+
'\n                                ';
 } else { 
__p+='\n                                    <em>'+
((__t=(t('subscription::setup:choose-a-plan:basic:hint')))==null?'':_.escape(__t))+
'</em> '+
((__t=(t('subscription::setup:choose-a-plan:basic:sub-hint')))==null?'':_.escape(__t))+
'\n                                ';
 } 
__p+='\n                            </div>\n                            <div class="price-annually">\n                                ';
 if (item.plans[i].amountAnnuallyTotal > 0) { 
__p+='\n                                    <em>'+
((__t=(item.plans[i].amountAnnuallyTotal))==null?'':_.escape(__t))+
'</em> '+
((__t=(item.plans[i].currency))==null?'':_.escape(__t))+
' '+
((__t=(t('subscription::setup:choose-a-plan:per-year:hint')))==null?'':_.escape(__t))+
'\n                                ';
 } else { 
__p+='\n                                    <em>'+
((__t=(t('subscription::setup:choose-a-plan:basic:hint')))==null?'':_.escape(__t))+
'</em> '+
((__t=(t('subscription::setup:choose-a-plan:basic:sub-hint')))==null?'':_.escape(__t))+
'\n                                ';
 } 
__p+='\n                            </div>\n                        </div>\n                        <div class="info-body">\n                            '+
((__t=( list(item.plans[i].description, 'ul')))==null?'':__t)+
'\n                            <label for="subscription-plan-'+
((__t=(item.plans[i].id))==null?'':_.escape(__t))+
'" class="btn btn-primary">\n                                <span class="choose">'+
((__t=(t('subscription::setup:choose-a-plan:choose-plan:button')))==null?'':_.escape(__t))+
'</span>\n                                <span class="selected">'+
((__t=(t('subscription::setup:choose-a-plan:choose-plan:selected')))==null?'':_.escape(__t))+
'</span>\n                            </label>\n                        </div>\n                    </li>\n                ';
 } 
__p+='\n            </ul>\n        </fieldset>\n\n        <p class="tax-info">'+
((__t=(t('subscription::setup:choose-a-plan:choose-plan:all-prices-with-vat')))==null?'':_.escape(__t))+
'</p>\n    </fieldset>\n\n    <p class="switch-plan-info"></p>\n\n    ';
 if (!view.getOption('isSetup')) { 
__p+='\n        <fieldset class="submit">\n                <button type="submit" class="btn btn-primary">'+
((__t=(t('subscription::setup:save:button')))==null?'':_.escape(__t))+
'</button>\n        </fieldset>\n    ';
 } 
__p+='\n</form>';
}
return __p;
};