module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="modal-select-location">\n    <header class="modal-header">\n        <div class="header-part">\n            <ul>\n                <li>\n                    <h2>'+
((__t=( t('collection::locations:update-location:headline') ))==null?'':_.escape(__t))+
'</h2>\n                    ';
 if(view.getOption('objectCount')) { 
__p+='\n                        <h2 class="subline">\n                            '+
((__t=( t('collection::locations:update-location:selected-objects', view.getOption('objectCount')) ))==null?'':_.escape(__t))+
'\n                        </h2>\n                    ';
 } 
__p+='\n                </li>\n                <li class="actions">\n                    <button type="button" class="btn btn-secondary btn-has-icon btn-icon-right" data-action="cancel">'+
((__t=(t('global::modal:button:close')))==null?'':_.escape(__t))+
' '+
((__t=( icon('close-light') ))==null?'':__t)+
'</button>\n                </li>\n            </ul>\n        </div>\n    </header>\n\n    <div class="modal-body">\n        <div class="modal-content container">\n            <div class="vertical-lanes" data-level="0">\n                <div class="lane" data-level="0">\n                    <div class="lane-content">\n                        <!-- Locations -->\n                        <div id="region-locations"></div>\n\n                        <hr class="divider-default">\n\n                        <h3>'+
((__t=( t('collection::locations:storages:headline') ))==null?'':_.escape(__t))+
'</h3>\n\n                        <!-- Storages -->\n                        <div id="region-storages-0"></div>\n                    </div>\n                </div>\n\n                <div class="lane" data-level="1">\n                    <div class="lane-content" id="region-storages-1"></div>\n                </div>\n\n                <div class="lane" data-level="2">\n                    <div class="lane-content" id="region-storages-2"></div>\n                </div>\n\n                <div class="lane" data-level="3">\n                    <div class="lane-content" id="region-storages-3"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';
}
return __p;
};