'use strict';

// Extension for both filtered and default collection
var objectsCollectionExtension = {
    /**
     * Returns an array of the selected model or an empty array
     * @returns {array} The array with the ID's or an empty array
     */
    getSelected: function() {
        return this.where({selected: true});
    },

    /**
     * Returns an array of the selected ID's or an empty array
     * @returns {array} The array with the ID's or an empty array
     */
    getSelectedIds: function() {
        return _.pluck(this.where({selected: true}), 'id');
    },

    /**
     * Returns the number of selected items
     * @returns {number}
     */
    selectedCount: function() {
        return this.where({selected: true}).length;
    },

    /**
     * Checks if all models are selected
     * @returns {boolean} True, if all selected. Otherwise false.
     */
    allSelected: function() {
        return this.selectedCount() === this.length;
    },

    selectAll: function() {
        this.each(function(model) {
            model.set('selected', true);
        });
    },

    /**
     * Removes the selection from all models
     * @param {object=} options (Optional) Options
     * @param {boolean=} options.silent (Optional) Use silent updating
     */
    deselectAll: function(options) {
        options = options || {};

        var silent = options.silent || false

        this.each(function(model) {
            model.set({selected: false}, {silent: silent});
        });
    },

    getPreviousModel: function(model) {
        var modelIndex = this.indexOf(model);

        return modelIndex > 0 ? this.at(modelIndex - 1) : null;
    },

    getNextModel: function(model) {
        var modelIndex = this.indexOf(model);

        return modelIndex < this.length - 1 ? this.at(modelIndex + 1) : null;
    },

    getPreviousModelUrl: function(model, basePath) {
        var previousModel = this.getPreviousModel(model);

        return previousModel ? basePath + previousModel.get('id') : null;
    },

    getNextModelUrl: function(model, basePath) {
        var nextModel = this.getNextModel(model);

        return nextModel ? basePath + nextModel.get('id') : null;
    },
};

module.exports = objectsCollectionExtension;