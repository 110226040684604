'use strict';

var Html = require('base/Colido.Html');
var ItemView = require('base/Colido.ItemView');

var template = require('modules/collection/templates/partials/list-grid-item.hbs');

var ListGridItemView = ItemView.extend({
    template: template,

    tagName: 'button',

    attributes: function(){
        return {
            type: 'button',
            'data-id': this.model.id,
        }
    },

    className: 'box box-object-object',

    events: {
        'click': 'clickButton',
    },

    modelEvents: {
        'sync': 'render',
        'change': 'render',
    },

    viewData: {
        headline: function() {
            var fieldName = this.model.collection.catalog.get('fieldsGridAttributeMap').headline;

            return this.model.getMergedTextValues(fieldName);
        },

        subheadline: function() {
            var fieldName = this.model.collection.catalog.get('fieldsGridAttributeMap').subheadline;

            return this.model.getMergedTextValues(fieldName);
        },
    },

    /**
     * Is triggered when the object is clicked
     */
    clickButton: function() {
        this.trigger('showroom:detail:selected', this.$el);
    },
});

module.exports = ListGridItemView;