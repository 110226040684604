module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="braintree-loading-layer"></div> <!-- todo move to css onlye -->\n\n<div class="hint">\n    '+
((__t=( multiline(t('subscription::payment:text:edit:hint', {billingInterval: item.upcomingBillingInterval}))))==null?'':__t)+
'\n</div>\n\n<div class="payment-method-creditcard"></div>\n\n<div class="payment-method-paypal">\n    <h3 data-step-number="'+
((__t=( t('subscription::payment:headline:or') ))==null?'':_.escape(__t))+
'">\n        '+
((__t=( t('subscription::payment:headline:checkout-with-paypal') ))==null?'':_.escape(__t))+
'\n    </h3>\n\n    <div id="paypal-container" class="paypal-container"></div>\n</div>';
}
return __p;
};