module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="'+
((__t=(item.storageBasePath))==null?'':_.escape(__t))+
'/'+
((__t=(item.id))==null?'':_.escape(__t))+
'" class="box box-stacked box-object-storage" data-mode="view" data-empty="false" data-type="storage">\n    <div class="headline-wrapper">\n        <h4>'+
((__t=(item.name))==null?'':_.escape(__t))+
'</h4>\n    </div>\n    <span class="object-count">\n        '+
((__t=(t('collection::location:object-count', model.getItemsRecursive().length)))==null?'':_.escape(__t))+
'\n    </span>\n</a>';
}
return __p;
};