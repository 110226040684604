'use strict';

var Model = require('base/Colido.Model');

var ForgotPasswordModel = Model.extend({
    api: '/lostpassword',

    defaults: {
        email: '',
        clientIdentifier: 'com.colido.webapp',
        host: location.host,
    },

    validate: function(values) {
        var errors = {};

        if (_.isEmpty(values.email) || !values.email.match(/.*@.*/)){
            errors.email = App.t('forgot-password::invalid-email');
        }

        return _.isEmpty(errors) ? null : errors;
    },
});

module.exports = ForgotPasswordModel;