'use strict';

var CompositeView = require('base/Colido.CompositeView');
var ListShowroomItemView = require('./list-showroom-item');
var EmptyView = require('./list-empty');
var DetailView = require('./detail');
var Hammer = require('vendor/hammer-2.0.4');

var template = require('modules/collection/templates/partials/list-showroom.hbs');

require('jquery-humans-dnd');

var ListShowroomView = CompositeView.extend({
    template: template,

    childView: ListShowroomItemView,

    emptyView: EmptyView,

    showVirtualItem: false,

    childViewContainer: '.objects-showroom-container',

    ui: {
        'listingContainer': '.objects-listing',
        'detailObjectShowroom': '.object-details-showroom',
        'closeObjectShowroom': '[data-action="close-showroom-detail"]',
        'nextObjectShowroom': '[data-action="next-showroom-detail"]',
        'prevObjectShowroom': '[data-action="prev-showroom-detail"]',
        'overlayObjectShowroom': '.object-details-showroom-overlay',
        'mobileBackToTop': '[data-action="back-to-top"]',
    },

    events: {
        'click @ui.closeObjectShowroom': 'onCloseObjectShowroom',
        'click @ui.overlayObjectShowroom': 'onCloseObjectShowroom',
        'click @ui.nextObjectShowroom': 'onNextObjectShowroom',
        'click @ui.prevObjectShowroom': 'onPrevObjectShowroom',
        'click @ui.mobileBackToTop': 'mobileBackToTop',
    },

    childEvents: {
        'showroom:detail:selected': 'onShowroomDetailSelected',
        'showroom:detail:select:id': 'onShowroomDetailSelectId',
    },

    modelEvents: {
        'change:activeModel': 'activeModelChanged',
    },

    reorderOnSort: false,

    /**
     * Reference for the slideshow in showroom view
     * @type {null|object}
     */
    slideshow: null,

    /**
     * Direction for the slideshow
     * @type {null|string}
     */
    slideshowDirection: null,

    /**
     * Reference of the item, that triggered the showroom overlays
     * @type {null|CollectionModule.ListShowroomItemView}
     */
    showRoomDetailRelation: null,

    /**
     * Reference for the showroom detail overlay
     * @type {null|CollectionModule.DetailView}
     */
    showroomDetailView: null,

    /**
     * @todo: Move this to compositeview, if possible!?
     */
    scrollPosition: 0,

    /**
     * Hammer manager
     * @type {null|object}
     */
    hammerManagerSlideshow: null,

    //private properties
    _isDetailMode: false,

    _isManageMode: false,

    startDelays: [],

    initialize: function(options) {
        CompositeView.prototype.initialize.apply(this, arguments);

        if (this.model.get('activeModel')) {
            this.toggleDetailMode(true);
        }

        this.listenTo(App.vent, 'scrollContainerScroll', this.onLayoutScrollContainerScroll.bind(this));
    },

    emptyViewOptions: function() {
        return {
            collection: this.collection,
            model: this.model,
            isInfiniteView: this.getOption('infiniteView'),
            viewMode: this._getViewMode(),
        };
    },

    /**
     * Options to pass to childView
     * @param [object} model The model
     * @param {int}    index The index of the model
     * @returns {object} The child view options
     */
    childViewOptions: function(model, index) {
        return {
            viewMode: this._getViewMode(),

            tableFields: this.model.get('tableFields'),

            isManageMode: this.isManageMode.bind(this), //can change after child render. so needs to be a function

            isDetailMode: this.isDetailMode.bind(this), //can change after child render. so needs to be a function

            isInfiniteView: this.getOption('infiniteView'),

            viewData: {
                objectBasePath: this.model.getPath() + '/show/',
                objectNewPath: this.model.getPath() + '/new',
            },

            state: this.model,

            isActive: this.model.get('activeModel') ? this.model.get('activeModel').id === model.id : false,
        };
    },

    /**
     * Callback for redering process
     * @return {void}
     */
    onBeforeRender: function() {
        CompositeView.prototype.onBeforeRender.apply(this, arguments);

        this.scrollPosition = $('#content').scrollTop();

        $('body').addClass('is-showroom');
        this.disableFixedListControl();
    },

    onLayoutScrollContainerScroll: function(event) {
        if (!_.isObject(this.ui.mobileBackToTop) || this.isDestroyed) {
            return;
        }

        if ($(event.currentTarget).scrollTop() > 250) {
            //btn-back-to-top
            this.ui.mobileBackToTop.addClass('is-visible');
        } else {
            this.ui.mobileBackToTop.removeClass('is-visible');
        }
    },

    onShow: function () {
        // On first render, attempt to set scroll position after CSS
        // transition has finished.
        // @todo: Set scroll position on CSS transition end event
        setTimeout(function () {
            var relatedScrollContainer = $('.list-layout-scroll-container').length
                ? '.list-layout-scroll-container'
                : '#content';

            this.setListScrollPosition();

            // Set active model for share
            if (App.appType === 'share') {
                var element = null;

                if (this.getOption('openChildModel')) {
                    element = this.children.findByModel(this.getOption('openChildModel'));
                } else if (this.children.length === 1) {
                    element = this.children.findByIndex(0);
                }

                if (element) {
                    this.triggerMethod('showroom:detail:selected', element);
                }
            }
        }.bind(this), 400);
    },

    /**
     * Is called, everytime the children are rendered.
     * We will set correct sorts for ListView and GridView
     * @return {void}
     */
    onRenderCollection: function() {
        // Set scrollposition
        $('#content').scrollTop(this.scrollPosition);
    },

    /**
     * Is triggered, when an external source (shared showroom) wants the detailview of a new item
     * @param {number}|{null} ID of the object or null, when the view should be hidden
     */
    onShowroomDetailSelectId: function(id) {
        if (id && Number(id) > 0) {
            var model = this.collection.get(id);
            if (model) {
                var element = this.children.findByModel(model);
                if (element) {
                    if (this.showroomDetailView && this.showRoomDetailRelation) {
                        this.renderRealContentIntoDetailView(model)
                    } else {
                        this.onShowroomDetailSelected(element);
                    }
                }
            }
        } else {
            this.closeShowroomDetail();
        }
    },

    /**
     * Is called when the showroom detail view is shown
     * @param {object} item The child view item
     */
    onShowroomDetailSelected: function(item) {
        this.showRoomDetailRelation = item;

        // We need to disable scroll
        var scrollTop = $('.list-layout-scroll-container').scrollTop();
        $('.list-layout-scroll-container').css({
            overflow: 'hidden',
        });

        var el = item.$el;

        var top = el.offset().top;
        var left = el.offset().left;
        var w = el.outerWidth();
        var h = el.outerHeight();

        this.ui.detailObjectShowroom.css({
            marginTop: scrollTop + 'px',
            top: top + (h / 2) + 'px',
            left: left + (w / 2) + 'px',
            width: w + 'px',
            height: h + 'px',
        });

        this.ui.overlayObjectShowroom.css({marginTop: scrollTop + 'px'});

        // Provide preview content
        _.delay(function() {
            var clone = item.$el.clone();
            clone.off().on('click', function(event) {
                event.preventDefault();
            });

            this.ui.detailObjectShowroom.addClass('is-active');
            this.ui.overlayObjectShowroom.addClass('is-visible').css({marginTop: scrollTop + 'px'});
            this.ui.detailObjectShowroom.find('.object-details-showroom-content').empty().append(clone);

            // Align image to top
            var imagePos = clone.find('.image').position();
            clone.find('.image').css({
                bottom: 'auto',
                top: this.getRemSize(imagePos.top) + 'rem',
            });
        }.bind(this), 10);

        // Provide real positioning
        _.delay(function() {
            this.ui.detailObjectShowroom.css({
                top: '',
                left: '',
                width: '',
                height: '',
            });
            this.ui.detailObjectShowroom.addClass('is-preloaded');
        }.bind(this), 400);

        // Provide real content
        _.delay(function() {
            this.renderRealContentIntoDetailView(item.model);
        }.bind(this), 800);
    },

    //TODO Refactor this, because it is horrible
    /**
     * Renders the content for the given model into the showroom detail view
     * @param {Backbone.Model} model The model to render
     */
    renderRealContentIntoDetailView: function(model) {
        // Destroy previous hammer
        if (this.hammerManagerSlideshow !== null && this.hammerManagerSlideshow.destroy) {
            this.hammerManagerSlideshow.destroy();
            this.hammerManagerSlideshow = null;
        }

        // Destroy previous detail view
        if (this.showroomDetailView && _.isFunction(this.showroomDetailView.destroy)) {
            this.showroomDetailView.$el.off();
            this.showroomDetailView.destroy();
            this.showroomDetailView = null;
        }

        // Set relation for close effect
        var childView = this.children.findByModel(model);
        if (childView) {
            this.showRoomDetailRelation = childView;
        }

        this.showroomDetailView = new DetailView({
            model: model,
            prevLinkUrl: false,
            nextLinkUrl: false,
            isShowroom: true,
            shareModel: this.getOption('model').get('shareModel'),
        });

        this.showroomDetailView.render();
        this.ui.detailObjectShowroom.find('.object-details-showroom-content').empty().append(this.showroomDetailView.$el);

        // Add proper swipe event, that does not block general touch events
        this.hammerManagerSlideshow = new Hammer.Manager(this.showroomDetailView.el, {
            cssProps: {
                userSelect: 'auto',
            },
        });

        this.hammerManagerSlideshow.add([
            new Hammer.Swipe({direction: Hammer.DIRECTION_HORIZONTAL}),
        ]);
        this.hammerManagerSlideshow.on('swipeleft swiperight', function(event) {
            // cancel, if src or dest has parent of class .object-images
            if ($(event.srcEvent.srcElement).parents('.object-images').length != 1) {
                if (event.type === 'swiperight') {
                    this.onPrevObjectShowroom();
                } else if (event.type === 'swipeleft') {
                    this.onNextObjectShowroom();
                }
            }
        }.bind(this));

        // Manually execute 'attach' event callback - the event does not
        // get triggered when view is manually attached to DOM.
        if (_.isFunction(this.showroomDetailView.onAttach)) {
            this.showroomDetailView.onAttach();
        }

        // Update next/prev link
        if (App.appType === 'share') {
            var currentModelIndex = this.collection.indexOf(this.showroomDetailView.model);
            var nextModel = this.collection.at(currentModelIndex + 1);
            var prevModel = this.collection.at(currentModelIndex - 1);
            if (!nextModel) {
                nextModel = this.collection.first();
            }

            if (!prevModel) {
                prevModel = this.collection.last();
            }

            this.ui.nextObjectShowroom.data('href', this.model.getPath() + '/show/' + nextModel.id);
            this.ui.prevObjectShowroom.data('href', this.model.getPath() + '/show/' + prevModel.id);
        }
    },

    /**
     * Shows the next showroom object
     */
    onNextObjectShowroom: function(event) {
        if (event) {
            event.preventDefault();
        }

        if (App.appType === 'share' && this.ui.nextObjectShowroom.data('href').length > 0) {
            App.router.navigate(this.ui.nextObjectShowroom.data('href'), {trigger: true});
        } else if (this.showroomDetailView && this.showroomDetailView.model) {
            var model = this.collection.at(this.collection.indexOf(this.showroomDetailView.model) + 1);

            if (!model) {
                model = this.collection.first();
            }

            this.renderRealContentIntoDetailView(model);
        }
    },

    /**
     * Shows the previous showroom object
     */
    onPrevObjectShowroom: function (event) {
        if (event) {
            event.preventDefault();
        }

        if (App.appType === 'share' && this.ui.prevObjectShowroom.data('href').length > 0) {
            App.router.navigate(this.ui.prevObjectShowroom.data('href'), {trigger: true});
        } else if (this.showroomDetailView && this.showroomDetailView.model) {
            var model = this.collection.at(this.collection.indexOf(this.showroomDetailView.model) - 1);

            if (!model) {
                model = this.collection.last();
            }

            this.renderRealContentIntoDetailView(model);
        }
    },

    /**
     * Is called when the showroom should be closed
     * @param {object} event The event object
     */
    onCloseObjectShowroom: function(event) {
        // if (App.appType === 'share' && this.ui.closeObjectShowroom.attr('href').length) {
        //     App.router.navigate(this.ui.closeObjectShowroom.attr('href'), {trigger: true});
        // } else {
        this.closeShowroomDetail();
        // }
    },

    /**
     * Closes the showroom detail view
     */
    closeShowroomDetail: function() {
        if (this.showRoomDetailRelation && this.showRoomDetailRelation.$el) {
            var el = this.showRoomDetailRelation.$el;

            // Revert object overlay
            var top = el.offset().top;
            var left = el.offset().left;
            var w = el.outerWidth();
            var h = el.outerHeight();
        }

        this.ui.detailObjectShowroom.css({
            top: top + (h / 2) + 'px',
            left: left + (w / 2) + 'px',
            width: w + 'px',
            height: h + 'px',
        }).addClass('is-removed');

        // Remove overlay
        this.ui.overlayObjectShowroom.removeClass('is-visible');

        // Destroy stuff
        _.delay(function() {
            $('.list-layout-scroll-container').removeAttr('style');
            this.ui.detailObjectShowroom.removeClass('is-active is-removed is-preloaded').removeAttr('style');
            if (this.showroomDetailView && _.isFunction(this.showroomDetailView.destroy)) {
                this.showroomDetailView.destroy();
                this.showroomDetailView = null;
                this.showRoomDetailRelation = null;
            }
        }.bind(this), 400);
    },

    /**
     * Destroy plugins and additional listener
     */
    onBeforeDestroy: function() {
        // Make sure, view is not destroyed yet
        if (this.isDestroyed) {
            return;
        }

        // clear the start-delays
        // it could be possible, taht these are not fireed till now
        _.each(this.startDelays, function(timerId){
            clearTimeout(timerId);
        });

        this.startDelays = [];

        // Destroy showroom detail if exist
        if (this.showroomDetailView && this.showroomDetailView.destroy) {
            this.showroomDetailView.destroy();
        }

        // Destroy hammer manager
        if (this.hammerManagerSlideshow !== null && this.hammerManagerSlideshow.destroy) {
            this.hammerManagerSlideshow.destroy();
            this.hammerManagerSlideshow = null;
        }

        // Disable showroom mode
        $('body').removeClass('is-showroom');

        this.enableFixedListControl();

        // Remove lazyrender hints
        $('body').removeClass('is-lazyrender-not-top');
    },

    /**
     * Update sort, after sync
     */
    updateView: function() {
        this.collection.sort();
    },

    getActiveChildView: function() {
        var activeModel = this.model.get('activeModel');
        if (activeModel) {
            return this.children.findByModel(activeModel);
        } else {
            return null;
        }
    },

    /**
     * Sets scroll position so that an element is within the viewport.
     * @param {object} options Options
     * @param {jquery} options.scrollContainer Scroll container jQuery reference, defaults to this.getOption('scrollContainer').
     * @param {jquery} options.element Element to scroll to, defaults to this.getActiveChildView().
     * @param {string} options.align Force alignment instead of using element position to calculate alignment. Accepted values: 'top', 'bottom'.
     * @param {string} options.callback Function to call when scrolling is complete or interrupted.
     */
    setListScrollPosition: function(options) {
        options = _.extend({}, options);

        var scrollContainer = options.scrollContainer || this.getOption('scrollContainer');

        var activeChildView = this.getActiveChildView();
        var element = options.element || activeChildView && activeChildView.$el;

        if (scrollContainer && element && element.length > 0) {
            var scrollElementHeight = scrollContainer.height();
            var elementTopOffset = element.position().top;
            var elementHeight = element.outerHeight();

            var scrollTop = null;

            if (options.align === 'top' || !options.align && elementTopOffset < 0) {
                scrollTop = scrollContainer.scrollTop() + elementTopOffset - 110;
            } else if (options.align === 'bottom' || !options.align && elementTopOffset + elementHeight > scrollElementHeight) {
                scrollTop = scrollContainer.scrollTop() + elementTopOffset + elementHeight - scrollElementHeight + 40;
            }

            if (scrollTop !== null) {
                if (browser.isIos) {
                    scrollContainer.scrollTop(scrollTop);
                    if (_.isFunction(options.callback)) {
                        clearTimeout(this.timeoutSetListScrollPosition);
                        this.timeoutSetListScrollPosition = setTimeout(options.callback, 300);
                    }
                } else {
                    scrollContainer.stop().animate({scrollTop: scrollTop}, {duration: 200, always: options.callback});
                }
            } else {
                if (_.isFunction(options.callback)) {
                    options.callback.apply(this, arguments);
                }
            }
        } else {
            if (_.isFunction(options.callback)) {
                options.callback.apply(this, arguments);
            }
        }
    },

    /**
     * This method will return the current viewmode of the sote
     * @returns {string} The current viewmode
     * @private
     */
    _getViewMode: function() {
        var viewMode = 'grid';
        if (typeof this.model === 'object' && !this.getOption('infiniteView')) {
            viewMode = this.model.get('viewMode');
        }

        return viewMode;
    },

    isManageMode: function() {
        return this._isManageMode;
    },

    isDetailMode: function() {
        return this._isDetailMode;
    },

    activeModelChanged: function (params, activeModel) {
        this.toggleDetailMode(activeModel != null);

        var element, eventName;
        if (activeModel) {
            // Scroll to new active model
            eventName = 'show:extra-content';
        } else {
            // Restore scroll position
            eventName = 'hide:extra-content';
            element = this.findElementClosestToViewportCentre();
        }

        this.lazyRender = false;
        this.stopListeningToScroll();

        var reEnableScroll = function(paramElement) {
            this.setListScrollPosition({
                element: paramElement,
                callback: function() {
                    this.listenToScroll();
                    this.lazyRender = true;
                }.bind(this),
            });
        }.bind(this);

        if (this._firstTimeDetailMode) {
            this.listenToOnce(App.vent, eventName, function() {
                reEnableScroll(element);
            }.bind(this));
        } else {
            reEnableScroll(element);
        }
    },

    toggleDetailMode: function(flag) {
        var wasDetailMode = this._isDetailMode;

        if (_.isUndefined(flag)) {
            this._isDetailMode = !this._isDetailMode;
        } else {
            this._isDetailMode = !!flag;
        }

        if (wasDetailMode === false && this._isDetailMode === true) {
            this._firstTimeDetailMode = true;
        } else {
            this._firstTimeDetailMode = false;
        }

        return this;
    },

    showCollection: function () {
        $('body').toggleClass('is-lazyrender-not-top', this.lazyRenderPage > 1);

        return CompositeView.prototype.showCollection.apply(this, arguments);
    },

    findElementClosestToViewportCentre: function () {
        var containerHeight = this.getOption('scrollContainer').height();
        var targetPos = containerHeight / 2;
        var els = this.getChildrenEl();

        var bestElement = _.reduce(els, function (best, el) {
            var $el = $(el);
            var pos = $el.offset().top + $el.outerHeight() / 2;
            var distance = Math.abs(targetPos - pos);

            if (!best || distance < best.distance) {
                best = {
                    el: $el,
                    distance: distance,
                };
            }

            return best;
        }, null);

        return bestElement.el;
    },

    /**
     * Scrolls the list to top and shows the first elements
     * @param {object} event
     */
    mobileBackToTop: function(event) {
        this.lazyRenderPage = 0;
        this.showCollection(0);
        $('.list-layout-scroll-container').stop().animate({scrollTop: 0});
    },
});

module.exports = ListShowroomView;