'use strict';

var Controller = require('base/Colido.Controller');
var ObjectSettingsListModel = require('modules/collection/models/settings-object-list');
var IndexLayout = require('./views/layout/index');

var DashboardController = Controller.extend({
    routes: {
        '': 'actionRedirect',
        'dashboard': 'actionIndex',
    },

    /**
     * We will perform a redirect to the real dashboard, to get access to the detailview
     */
    actionRedirect: function() {
        App.router.navigate('/dashboard', {trigger: true, replace: true});
    },

    /**
     * Shows the dashboard
     */
    actionIndex: function() {
        this.onDataReady(App.CollectionModule.catalogs, function() {
            var catalog = App.CollectionModule.catalogs.getActiveCatalog();

            if (!catalog) {
                App.router.navigate('/settings/catalogs', {trigger: true});

                return;
            }

            var requiredData = [
                catalog.objects,
                catalog.sets,
                catalog.imports,
            ];

            if (App.CommunityModule) {
                requiredData.push(App.CommunityModule.conversationsCollection);
                requiredData.push(App.CommunityModule.groupsCollection);
                requiredData.push(App.CommunityModule.contactsCollection);
            }

            App.requireData(requiredData, function() {
                var region = App.rootView.mainRegion.currentView.contentRegion;
                var layout = new IndexLayout({
                    catalog: catalog,
                    settingsModel: new ObjectSettingsListModel(),
                });
                region.show(layout);
            });
        });
    },
});

module.exports = DashboardController;