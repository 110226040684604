'use strict';

var Backbone = require('backbone');
var LayoutView = require('base/Colido.LayoutView');

var template = require('../../templates/layout/error-404.hbs');

var Error404View = LayoutView.extend({
    template: template,

    viewData: {
        fragment: function() {
            return Backbone.history.fragment;
        },
    },
});

module.exports = Error404View;