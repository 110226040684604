module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row">\n    <div class="page-col-offset input-help">\n        <span class="create-step-number">&nbsp;</span>\n        <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n    </div>\n    <div class="page-col-6">\n        <label class="label-headline">'+
((__t=(t('collection::object:details:color:label')))==null?'':_.escape(__t))+
'</label>\n\n        <ul class="color-listing">\n            ';
 for (var i = 0; i < item.availableColors.length; i++) { 
__p+='\n                <li class="color-listing-item">\n                    <input id="field-'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'-value-'+
((__t=( item.availableColors[i] ))==null?'':_.escape(__t))+
'"\n                           type="checkbox"\n                           name="'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'[]"\n                           value="'+
((__t=(item.availableColors[i]))==null?'':_.escape(__t))+
'"\n                           '+
((__t=(when(_.contains(item.colors, item.availableColors[i]), 'checked')))==null?'':_.escape(__t))+
'>\n\n                    <label for="field-'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'-value-'+
((__t=( item.availableColors[i] ))==null?'':_.escape(__t))+
'"\n                           class="color-preview color-preview-'+
((__t=( item.availableColors[i] ))==null?'':_.escape(__t))+
'">\n                        <h3>'+
((__t=( t('collection::object:details:color:' + item.availableColors[i]) ))==null?'':_.escape(__t))+
'</h3>\n                        <div class="color-preview-field"></div>\n                    </label>\n                </li>\n            ';
 } 
__p+='\n        </ul>\n    </div>\n</div>\n<div class="row">\n    <div class="page-offset-default page-col-3">\n        <textarea name="customColors"\n                  placeholder="'+
((__t=(item.customColorsPlaceholder))==null?'':_.escape(__t))+
'"\n                  data-autosize="true"\n                  rows="1">'+
((__t=(item.customColors))==null?'':_.escape(__t))+
'</textarea>\n    </div>\n</div>';
}
return __p;
};