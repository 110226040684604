module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (Colido.config.allowSignup) { 
__p+='\n    <div id="div-form-signup" class="div-form">\n        <h3>'+
((__t=(t('signup::havent-account')))==null?'':_.escape(__t))+
'</h3>\n        <a href="/signup" class="btn btn-secondary">'+
((__t=(t('signup::to-the-signup')))==null?'':_.escape(__t))+
'</a>\n    </div>\n';
 } 
__p+='\n\n<div class="claim">'+
((__t=(t('signup::claim')))==null?'':_.escape(__t))+
'</div>\n\n<form class="signup-form" method="post">\n    <h2>'+
((__t=(t('signup::headline')))==null?'':_.escape(__t))+
'</h2>\n    <p class="h2-subline">'+
((__t=(t('signup::subline')))==null?'':_.escape(__t))+
'</p>\n\n    <fieldset>\n        <input data-name="fullname" type="text" value="" placeholder="'+
((__t=(t('signup::placeholder-fullname')))==null?'':_.escape(__t))+
'" tabindex="11">\n    </fieldset>\n\n    <fieldset>\n        <input data-name="email" type="email" value="" placeholder="'+
((__t=(t('signup::placeholder-email')))==null?'':_.escape(__t))+
'" tabindex="12">\n    </fieldset>\n\n    <fieldset class="password">\n        <input data-name="password" type="password" value="" placeholder="'+
((__t=(t('signup::placeholder-password')))==null?'':_.escape(__t))+
'" tabindex="13">\n        <button class="input-inlinetext" type="button"\n                data-action="show-password">'+
((__t=(t('signup::show-password')))==null?'':_.escape(__t))+
'</button>\n        <button class="input-inlinetext" type="button"\n                data-action="hide-password">'+
((__t=(t('signup::hide-password')))==null?'':_.escape(__t))+
'</button>\n        <p class="hint">'+
((__t=( t('signup::tipsy:password')))==null?'':__t)+
'</p>\n    </fieldset>\n\n    <fieldset class="password-checker">\n        <div class="password-strength">\n            <span class="bad">'+
((__t=(t('signup::password:bad')))==null?'':_.escape(__t))+
'</span>\n            <span class="moderate">'+
((__t=(t('signup::password:moderate')))==null?'':_.escape(__t))+
'</span>\n            <span class="good">'+
((__t=(t('signup::password:good')))==null?'':_.escape(__t))+
'</span>\n        </div>\n    </fieldset>\n\n    <fieldset class="terms-agreement">\n        <input data-name="signUpOk" type="checkbox" id="signup-terms-agree-checkbox" tabindex="14">\n        <label for="signup-terms-agree-checkbox">\n            '+
((__t=(t('signup::signup-terms-agree')))==null?'':__t)+
'\n        </label>\n    </fieldset>\n\n    <fieldset class="submit">\n        <button data-name="submit" type="submit" class="btn btn-primary btn-xl" tabindex="15">'+
((__t=(t('signup::signup')))==null?'':_.escape(__t))+
'</button>\n    </fieldset>\n\n    <div class="pricing-link">\n        <a href="'+
((__t=(t('signup::pricing-url')))==null?'':_.escape(__t))+
'" target="_blank">'+
((__t=(t('signup::pricing-hint')))==null?'':_.escape(__t))+
'</a>\n    </div>\n</form>';
}
return __p;
};