module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<section class="object-history">\n    <h3>'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</h3>\n    <dl>\n        ';
 if (item.recentHistory.from) { 
__p+='\n            <dt>'+
((__t=(t('collection::object:details:history:' + item.recentHistory.type + ':from:label')))==null?'':_.escape(__t))+
'</dt>\n            <dd>'+
((__t=(item.recentHistory.from))==null?'':_.escape(__t))+
'</dd>\n        ';
 } 
__p+='\n        ';
 if (item.recentHistory.price) { 
__p+='\n            <dt>'+
((__t=(t('collection::object:details:history:' + item.recentHistory.type + ':price:label')))==null?'':_.escape(__t))+
'</dt>\n            <dd>'+
((__t=(currency(item.recentHistory.price,  item.recentHistory.currency)))==null?'':_.escape(__t))+
'</dd>\n        ';
 } 
__p+='\n        ';
 if (item.recentHistory.date) { 
__p+='\n            <dt>'+
((__t=(t('collection::object:details:history:' + item.recentHistory.type + ':date:label')))==null?'':_.escape(__t))+
'</dt>\n            <dd>'+
((__t=(item.recentHistory.date))==null?'':_.escape(__t))+
'</dd>\n        ';
 } 
__p+='\n        ';
 if (!item.recentHistory.from && !item.recentHistory.price && !item.recentHistory.date) { 
__p+='\n            <dt>'+
((__t=(t('collection::object:details:history:acquired:label')))==null?'':_.escape(__t))+
'</dt>\n            <dd>'+
((__t=(item.recentHistory.type))==null?'':_.escape(__t))+
'</dd>\n        ';
 } 
__p+='\n    </dl>\n</section>';
}
return __p;
};