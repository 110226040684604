module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="modal-change-status">\n    <header class="modal-header">\n        <div class="header-part">\n            <ul>\n                <li>\n                    <h2>'+
((__t=( t('collection::change-status:headline') ))==null?'':_.escape(__t))+
'</h2>\n                    ';
 if (item.mode !== 'select') { 
__p+='\n                        <h2 class="subline">\n                            '+
((__t=( t('global::objects-selected:count', item.selectedObjects) ))==null?'':_.escape(__t))+
'\n                        </h2>\n                    ';
 } 
__p+='\n                </li>\n                <li class="actions">\n                    <button type="button" class="btn btn-secondary btn-has-icon btn-icon-right" data-action="cancel">'+
((__t=(t('global::modal:button:close')))==null?'':_.escape(__t))+
' '+
((__t=( icon('close-light') ))==null?'':__t)+
'</button>\n                </li>\n            </ul>\n        </div>\n    </header>\n\n    <div class="modal-body">\n        <div class="modal-content container">\n            <div class="vertical-lanes" data-level="0">\n                <div class="lane" data-level="0">\n                    <div class="lane-content">\n                        <div id="region-status-main"></div>\n                    </div>\n                </div>\n\n                <div class="lane" data-level="1">\n                    <div class="lane-content" id="region-status-recipient"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';
}
return __p;
};