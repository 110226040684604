'use strict';

var Backbone = require('backbone');
var Wreqr = require('base/Colido.Wreqr');

var CommunityModuleWreqr = Wreqr.extend({
    name: 'community',

    /**
     * Deletes a single contact.
     * @param {ContactModel} contactModel  A single contact model
     * @param {function} options.success Callback to apply, when operation was successful
     * @param {function} options.error Callback to apply, when operation was not successful
     */
    commandDeleteContact: function(contactModel, options) {
        options = options || {};

        if (contactModel) {
            var success = options.success;
            var error = options.error;

            var contactName = contactModel.get('name') || contactModel.get('email');

            contactModel.destroy(_.extend(options, {
                success: function() {
                    Messenger().info({
                        message: App.t('community::contact:response:deleted', {name: _.escape(contactName)}),
                    });

                    if (_.isFunction(success)) {
                        success.apply(this, arguments);
                    }
                },
                error: function() {
                    Messenger().error({
                        message: App.t('community::contact:response:deleted:error', {name: _.escape(contactName)}),
                    });

                    if (_.isFunction(error)) {
                        error.apply(this, arguments);
                    }
                },
            }));
        }
    },

    /**
     * Accepts a incoming contact request
     * @param {ContactModel} contactModel  A single contact model
     * @param {function} options.success Callback to apply, when operation was successful
     * @param {function} options.error Callback to apply, when operation was not successful
     */
    commandAcceptContact: function(contactModel, options) {
        options = options || {};

        if (contactModel) {
            var success = options.success;
            var error = options.error;

            var contactName = contactModel.get('name') || contactModel.get('email');

            contactModel.save({
                accepted: 'YES',
            }, _.extend(options, {
                patch: true,
                success: function() {
                    Messenger().info({
                        message: App.t('community::contact:response:accepted', {name: _.escape(contactName)}),
                    });

                    if (_.isFunction(success)) {
                        success.apply(this, arguments);
                    }
                },

                error: function() {
                    Messenger().error({
                        message: App.t('community::contact:response:accepted:error', {name: _.escape(contactName)}),
                    });

                    if (_.isFunction(error)) {
                        error.apply(this, arguments);
                    }
                },
            }));
        }
    },

    /**
     * Add new contacts
     * @param {Array} contacts  Array of emails or userIds
     * @param {function} options.success Callback to apply, when operation was successful
     * @param {function} options.error Callback to apply, when operation was not successful
     */
    commandAddContacts: function(contacts, options) {
        options = options || {};
        contacts = _.isArray(contacts) ? contacts : [contacts];

        if (contacts.length) {
            var success = options.success;
            var error = options.error;

            _.each(contacts, function(contact){
                var data = {
                    accepted: 'OUT',
                };

                if (_.isString(contact) && contact.match(/.+@.+/)) {
                    data.email = contact;
                }

                if (_.isNumber(parseInt(contact, 10)) && !isNaN(parseInt(contact, 10))) {
                    data.userId = parseInt(contact, 10);
                }

                if (options.message) {
                    data.message = options.message;
                }

                var contactModel = new this.module.contactsCollection.model(data);
                contactModel.save(data, _.extend(options, {
                    success: function(model, response, options) {
                        this.module.contactsCollection.add(model);

                        var contactName = model.get('name') || model.get('email');

                        if (model.get('accepted') === 'YES') {
                            Messenger().info({
                                message: App.t('community::contact:response:duplicate', {name: _.escape(contactName)}),
                            });
                        } else if (options.xhr.status === 201){
                            Messenger().info({
                                message: App.t('community::contact:response:added', {name: _.escape(contactName)}),
                            });
                        } else {
                            Messenger().info({
                                message: App.t('community::contact:response:pending', {name: _.escape(contactName)}),
                            });
                        }

                        if (_.isFunction(success)) {
                            success.apply(this, arguments);
                        }
                    }.bind(this),
                    error: function() {
                        Messenger().error({
                            message: App.t('community::contact:response:added:error', {name: _.escape(contact)}),
                        });

                        if (_.isFunction(error)) {
                            error.apply(this, arguments);
                        }
                    }.bind(this),
                }));
            }.bind(this))
        }
    },

    /**
     * Leave a group.
     * @param {GroupModel} groupModel  A group model
     * @param {function} options.success Callback to apply, when operation was successful
     * @param {function} options.error Callback to apply, when operation was not successful
     */
    commandLeaveGroup: function(groupModel, options) {
        options = options || {};

        if (groupModel) {
            var success = options.success;
            var error = options.error;

            var groupName = groupModel.get('name');

            groupModel.destroy(_.extend(options, {
                success: function() {
                    Messenger().info({
                        message: App.t('community::group:response:left', {name: _.escape(groupName)}),
                    });

                    if (_.isFunction(success)) {
                        success.apply(this, arguments);
                    }
                },
                error: function() {
                    Messenger().error({
                        message: App.t('community::group:response:left:error', {name: _.escape(groupName)}),
                    });

                    if (_.isFunction(error)) {
                        error.apply(this, arguments);
                    }
                },
            }));
        }
    },

    /**
     * Delete a group.
     * @param {GroupModel} groupModel  A group model
     * @param {function} options.success Callback to apply, when operation was successful
     * @param {function} options.error Callback to apply, when operation was not successful
     */
    commandDeleteGroup: function(groupModel, options) {
        options = options || {};

        if (groupModel) {
            var success = options.success;
            var error = options.error;

            var groupName = groupModel.get('name');
            var isBroadcast = groupModel.get('type') === 'broadcast';

            groupModel.destroy(_.extend(options, {
                success: function() {
                    Messenger().info({
                        message: App.t(isBroadcast ? 'community::broadcast:response:deleted' : 'community::group:response:deleted', {name: _.escape(groupName)}),
                    });

                    if (_.isFunction(success)) {
                        success.apply(this, arguments);
                    }
                },
                error: function() {
                    Messenger().error({
                        message: App.t(isBroadcast ? 'community::broadcast:response:deleted:error' : 'community::group:response:deleted:error', {name: _.escape(groupName)}),
                    });

                    if (_.isFunction(error)) {
                        error.apply(this, arguments);
                    }
                },
            }));
        }
    },

    /**
     * Sends a new message to the given conversation
     * @param {Colido.Collection} conversation    The conversation
     * @param {Object}            message         The message object to send ({type: 'text', data: 'hello world'})
     * @param {function}          options.success Callback to apply, when operation was successful
     * @param {function}          options.error   Callback to apply, when operation was not successful
     */
    commandSendMessageToConversation: function(conversation, message, options) {
        options = options || {};

        if (_.isObject(conversation) &&
            conversation.posts &&
            message
        ) {
            var success = options.success;
            var error = options.error;

            var postModel = new conversation.posts.model(null, {collection: conversation.posts});
            postModel.save({
                type: message.type,
                data: message.data,
            }, {
                success: function() {
                    conversation.posts.add(postModel);

                    if (_.isFunction(success)) {
                        success.apply(this, arguments);
                    }
                },
                error: function() {
                    if (_.isFunction(error)) {
                        error.apply(this, arguments);
                    }
                },
            });
        }
    },
});

module.exports = CommunityModuleWreqr;