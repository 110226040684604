module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="delete-account-bg"></div>\n<form class="delete-account-form is-hidden" method="post">\n    <h2>'+
((__t=(t('delete-account::headline')))==null?'':_.escape(__t))+
'</h2>\n    <fieldset class="submit">\n        <p>'+
((__t=(t('delete-account::subline')))==null?'':_.escape(__t))+
'</p>\n    </fieldset>\n    <fieldset class="submit">\n        <button type="submit" class="btn btn-danger btn-secondary">'+
((__t=(t('delete-account::yes')))==null?'':_.escape(__t))+
'</button>\n        <a href="/login" class="btn btn-text">'+
((__t=(t('delete-account::no')))==null?'':_.escape(__t))+
'</a>\n    </fieldset>\n\n    <input type="hidden" name="id" value="" />\n    <input type="hidden" name="token" value="" />\n</form>';
}
return __p;
};