'use strict';

var Controller = require('base/Colido.Controller');
var SettingsObjectListModel = require('./models/settings-object-list');
var ParameterizedCollection = require('base/Colido.ParameterizedCollection');
var SetsListLayout = require('./views/layout/sets-list');
var ObjectListLayout = require('./views/layout/object-list');

var SetModuleController = Controller.extend({
    routes: {
        'catalogs/:catalogId/sets(/sort/:sort)': 'actionList',
        'catalogs/:catalogId/sets/:id(/filter/*filter)(/sort/:sort)(/show/:id)(/scrollTo/:sid)': 'actionDetail',
    },

    initialize: function (options) {
        this.module = this.getOption('module');
    },

    /**
     * Action for listing the sets
     */
    actionList: function(catalogId, sort) {
        this.onDataReady(App.CollectionModule.catalogs, function() {
            var catalog = App.CollectionModule.catalogs.get(catalogId);

            if (!catalog) {
                console.log('@todo: forward to catalog 404');

                return;
            }

            App.requireData([
                catalog.sets,
            ], function() {
                var setParams = new SettingsObjectListModel({
                    catalog: catalog,
                });

                this.listenTo(setParams, 'change:filter change:sort', function(that, value) {
                    App.router.navigate(that.getPath(), {trigger: true});
                });

                var collection = new ParameterizedCollection(undefined, {
                    params: setParams,
                    collection: catalog.sets,
                });

                setParams.set({
                    catalogId: catalogId,
                    sort: sort,
                });

                var region = App.rootView.mainRegion.currentView.contentRegion;
                if (!(region.currentView instanceof SetsListLayout)) {
                    var layout = new SetsListLayout({
                        collection: collection,
                        model: setParams,
                        moduleParams: App.CollectionModule.controller.params,
                    });
                    region.show(layout);
                }
            }.bind(this));
        });
    },

    actionDetail: function (catalogId, setId, filter, sort, id, sid) {
        this.onDataReady(App.CollectionModule.catalogs, function() {
            var catalog = App.CollectionModule.catalogs.get(catalogId);

            if (!catalog) {
                console.log('@todo: forward to catalog 404');

                return;
            }

            App.requireData([
                catalog.objects,
                catalog.sets,
                catalog.storages,
            ], function() {
                App.CollectionModule.controller.objectsList(catalog.objects, ObjectListLayout, {
                    setModel: catalog.sets.get(setId),
                    filter: filter,
                    sort: sort,
                    id: id,
                    sid: sid,
                });
            });
        });
    },
});

module.exports = SetModuleController;