'use strict';

// var Html = require('base/Colido.Html');
var ItemView = require('base/Colido.ItemView');

var template = require('modules/community/templates/partials/contact.hbs');

var ContactView = ItemView.extend({
    tagName: 'li',

    className: function(){
        var classNames = ['contact'];

        if (this.model.get('accepted') !== 'YES') {
            classNames.push('contact-pending');
        }

        if (this.isActive()) {
            classNames.push('is-active');
        }

        return classNames.join(' ');
    },

    ui: {
        'acceptContact': '[data-action="accept-contact"]',
        'removeContact': '[data-action="remove-contact"]',
        'showActions': '[data-action="show-actions"]',
        'actionLayer': '.action-layer',
    },

    events: {
        'click @ui.acceptContact': 'acceptContact',
        'click @ui.removeContact': 'removeContact',
        'click @ui.showActions': 'showActions',
    },

    // modelEvents: {
    //     'sync': 'render',
    //     'change': 'render',
    // },

    template: template,

    viewData: {
        unreadPostsCount: function() {
            var conversation = this.model.get('conversation');

            return conversation ?  conversation.get('unreadPosts') : 0;
        },

        isActive: function() {
            return this.isActive();
        },
    },

    acceptContact: function(event) {
        var button = $(event ? event.currentTarget : null);

        App.radio('community').execute('accept:contact', this.model, {
            wait: true,
            error: function() {
                button.prop('disabled', false).removeClass('is-loading');
            }.bind(this),
        });

        button.prop('disabled', true).addClass('is-loading');
    },

    removeContact: function(event) {
        var contactName = this.model.get('name') || this.model.get('email');
        if (!window.confirm(App.t('community::contact:action:confirm-delete', {name: contactName}))) {
            return;
        }

        var button = $(event ? event.currentTarget : null);

        App.radio('community').execute('delete:contact', this.model, {
            wait: true,
            error: function() {
                button.prop('disabled', false).removeClass('is-loading');
            }.bind(this),
        });

        button.prop('disabled', true).addClass('is-loading');
    },

    isActive: function() {
        return this.getOption('activeConversationId') && this.getOption('activeConversationId') === this.model.get('conversationId');
    },

    showActions: function(event) {
        event.preventDefault();
        this.ui.actionLayer.addClass('is-visible');

        // Close actions overlay on next click
        setTimeout(function(){
            $('body').one('click', this.hideActions.bind(this));
        }.bind(this), 0)
    },

    hideActions: function() {
        if (this.ui.actionLayer.removeClass) {
            this.ui.actionLayer.removeClass('is-visible');
        }
    },
});

module.exports = ContactView;