'use strict';

var Html = require('base/Colido.Html');
var LayoutView = require('base/Colido.LayoutView');
var ChangeStatusOverlayLayout = require('../layout/change-status-overlay');
var SelectSetOverlayLayout = require('modules/collection/modules/set/views/layout/select-set-overlay');
var ChooseStorageModalLayout = require('modules/collection/modules/location/views/layout/choose-storage-modal'); //@todo
var AdvancedPrintModalView = require('modules/base/views/layout/advanced-print-modal');
var RestrictionLabelModalView = require('modules/base/views/layout/restriction-labelprint-modal');
var RestrictionStoragesModalView = require('modules/base/views/layout/restriction-storages-modal');
var EditLayout = require('modules/collection/modules/edit/views/layout/edit');
var LabelPrintModalView = require('modules/base/views/layout/label-print-modal');
var BuyModalView = require('modules/collection/modules/shop/views/layout/buy-modal');
var SellModalView = require('modules/collection/modules/shop/views/layout/sell-modal');
var SellerModalView = require('modules/collection/modules/shop/views/layout/seller-modal');
var ShippingAddressModalView = require('modules/collection/modules/shop/views/layout/shipping-address-modal');

//Component view
var ColorComponent = require('../components/detail/field-colors');
var DimensionsComponent = require('../components/detail/field-dimensions');
var DimensionTypeComponent = require('../components/detail/field-dimension-type');
var WeightComponent = require('../components/detail/field-weight');
var NumberComponent = require('../components/detail/field-number');
var LengthComponent = require('../components/detail/field-length');
var StringComponent = require('../components/detail/field-string');
var TextComponent = require('../components/detail/field-text');
var HtmlComponent = require('../components/detail/field-html');
var EnumComponent = require('../components/detail/field-enum');
var ImagesComponent = require('../components/detail/field-images');
var AssetsComponent = require('../components/detail/field-assets');
var MonetaryValueComponent = require('../components/detail/field-monetary-value');
var StatusComponent = require('../components/detail/field-status');
var LocalityComponent = require('../components/detail/field-locality');
var SpeciesComponent = require('../components/detail/field-species');
var HistoryComponent = require('../components/detail/field-history');
var GroupComponent = require('../components/detail/field-group');
var StorageComponent = require('../components/detail/field-storage');
var SetsComponent = require('../components/detail/field-sets');

var template = require('modules/collection/templates/partials/detail.hbs');

var DetailView = LayoutView.extend({
    template: template,

    tagName: 'article',
    className: 'object-details',

    ui: {
        'highlightStorage': '[data-action="highlight-storage"]',
        'editObject': '[data-action="edit-object"]',
        'actionChooseStorage': '.action-layer-actions [data-action="choose-storage"]', //data-action="choose-storage" is used in child view too. so prevent it from executing twice with as subselector of .action-layer-actions
        'actionAddToSet': '.action-layer-actions [data-action="add-to-set"]',
        'actionChangeStatus': '[data-action="change-status"]',
        'actionDeleteObject': '[data-action="delete-object"]',
        'actionSell': '[data-action="sell"]',
        'actionSeller': '[data-action="seller"]',
        'actionDuplicateObject': '[data-action="duplicate-object"]',
        'actionPrintLabel': '[data-action="print-objects-label"]',
        'actionPrintReport': '[data-action="print-objects-pdf"]',
        'actionBuy': '[data-action="buy"]',
        'showActions': '[data-action="show-actions"]',
        'actionLayerActions': '.action-layer-actions',
        'imagesContainer': '.object-images',
        'moreInfo': '.more-info',
    },

    events: {
        'click @ui.highlightStorage': 'highlightStorage',
        'click @ui.editObject': 'editObject',
        'click @ui.actionChooseStorage': 'onActionChooseStorage',
        'click @ui.actionAddToSet': 'onActionAddToSet',
        'click @ui.actionChangeStatus': 'onActionChangeStatus',
        'click @ui.actionDeleteObject': 'onActionDeleteObject',
        'click @ui.actionDuplicateObject': 'onActionDuplicateObject',
        'click @ui.actionPrintLabel': 'onActionPrintLabel',
        'click @ui.actionPrintReport': 'onActionPrintReport',
        'click @ui.showActions': 'showActions',
        'click @ui.actionBuy': 'actionBuy',
        'click @ui.actionSell': 'actionSell',
        'click @ui.actionSeller': 'actionSeller',
    },

    modelEvents: {
        'sync change change:sets': 'render',
    },

    initialize: function(options) {
        LayoutView.prototype.initialize.apply(this, arguments);

        this.fields = this.model.collection.catalog.getDetailFields({
            isShowroom: this.getOption('isShowroom'),
            shareModel: this.getOption('shareModel'),
        });
    },

    viewData: {
        fields: function() {
            return this.fields;
        },

        headline: function() {
            var catalog = this.model.collection.catalog;
            var fieldName = catalog.get('fieldsDetailSummary').headline;

            return this.model.getMergedTextValues(fieldName);
        },

        subheadline: function() {
            var catalog = this.model.collection.catalog;
            var fieldName = catalog.get('fieldsDetailSummary').subheadline;

            return this.model.getMergedTextValues(fieldName);
        },

        summarySlot1: function() {
            var catalog = this.model.collection.catalog;
            var fieldName = catalog.get('fieldsDetailSummary').slot1;

            return fieldName ? this.model.getMergedTextValues(fieldName) : '';
        },

        summarySlot2: function() {
            var catalog = this.model.collection.catalog;
            var fieldName = catalog.get('fieldsDetailSummary').slot2;

            return fieldName ? this.model.getMergedTextValues(fieldName) : '';
        },

        summarySlot3: function() {
            var catalog = this.model.collection.catalog;
            var fieldName = catalog.get('fieldsDetailSummary').slot3;

            return fieldName ? this.model.getMergedTextValues(fieldName) : '';
        },

        summarySlot4: function() {
            var catalog = this.model.collection.catalog;
            var fieldName = catalog.get('fieldsDetailSummary').slot4;

            return fieldName ? this.model.getMergedTextValues(fieldName) : '';
        },

        sets: function() {
            return this.model.getSets();
        },

        isShowroom: function() {
            return this.getOption('isShowroom');
        },

        isShop: function() {
            return this.isShop();
        },

        catalog: function() {
            return this.model.collection.catalog;
        },
    },

    onRender: function() {
        //show action slot view
        if (!this.getOption('isShowroom') && !this.isShop()) {
            var catalog = this.model.collection.catalog;
            var fieldName = catalog.get('fieldsDetailSummary').actionSlot1;

            if (fieldName) {
                var field = catalog.getDetailField(fieldName);

                var componentView = this.getComponentView(field);

                if (componentView && componentView.hasValue()) {
                    var region = this.addRegion('actionSlot1Region', '.action-slot-1-region');
                    region.show(componentView);
                }
            }
        }

        //show component views
        _.each(this.fields, function(sectionFields) {
            _.each(sectionFields, function(field) {
                var componentView = this.getComponentView(field);
                var regionName = 'field' + _.ucfirst(field.name) + 'Region';
                var regionSelector = '.field-' + _.camelToHyphen(field.name) + '-region';

                if (componentView && componentView.hasValue()) {
                    var region = this.addRegion(regionName, regionSelector);
                    region.show(componentView);
                } else {
                    this.$(regionSelector).remove();
                }
            }.bind(this));
        }.bind(this));

        this.ui.moreInfo.on('scroll', function() {
            var scrollTop = this.ui.moreInfo.scrollTop();
            this.$el.toggleClass('reduced-main-info', scrollTop > 200);
        }.bind(this));
    },

    getComponentView: function(field){
        if (this.components[field.type]) {
            return this.components[field.type].bind(this)(field);
        } else {
            console.warn('Missing detailview component for field', field.name, 'of type', field.type);

            return null;
        }
    },

    components: {
        string: function(field) {
            return new StringComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        text: function(field) {
            return new TextComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        html: function(field) {
            return new HtmlComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        enum: function(field) {
            return new EnumComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        colors: function(field) {
            return new ColorComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        weight: function(field) {
            return new WeightComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        length: function(field) {
            return new LengthComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        monetaryValue: function(field) {
            return new MonetaryValueComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        dimensions: function(field) {
            return new DimensionsComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        dimensionType: function(field) {
            return new DimensionTypeComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        locality: function(field) {
            return new LocalityComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        images: function(field) {
            return new ImagesComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        assets: function(field) {
            return new AssetsComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        species: function(field) {
            return new SpeciesComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        sets: function(field) {
            return new SetsComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        storageId: function(field) {
            return new StorageComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        status: function(field) {
            return new StatusComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        history: function(field) {
            return new HistoryComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        group: function(field) {
            return new GroupComponent({
                model: this.getOption('model'),
                field: field,
            });
        },

        number: function(field) {
            return new NumberComponent({
                model: this.getOption('model'),
                field: field,
            });
        },
        //@addfield
    },

    /**
     * Highlights the storage, where the item is in.
     */
    highlightStorage: function(event) {
        var request = $.ajax(App.getApiUrl('/led/storage/' + this.model.get('storageId') + '/color/52c6e2/duration/5000'));
    },

    /**
     * Opens the edit modal
     * @param event The event click/tap
     */
    editObject: function(event) {
        App.addModal(new EditLayout({
                model: this.model,
                catalog: this.model.collection.catalog,
            }), {
            additionalClassName: 'edit-object-modal',
            onBeforeClose: function(data) {
                return this.modalContent.canBeDestroyed();
            },
        });
    },

    /**
     * Is triggered from footer button, when the user wants to change location
     */
    onActionChooseStorage: function() {
        if (App.user.get('licenseStorages')) {
            App.addModal(new ChooseStorageModalLayout({
                objectCount: 1,
            }), {
                additionalClassName: 'modal-left modal-medium modal-select-location',
                onAfterClose: function(data) {
                    if (data) {
                        App.radio('collection').execute(
                            'change:storage',
                            this.model,
                            data.location
                        );
                    }
                }.bind(this),
            });
        } else {
            var restrictionModalView = new RestrictionStoragesModalView({hasClose: true});
            var options = {
                additionalClassName: 'modal-center modal-restriction',
                additionalBgClassName: 'light-bg',
            };
            App.addModal(restrictionModalView, options);
        }
    },

    /**
     * Is triggered footer button, when the user wants to add something to sets
     */
    onActionAddToSet: function() {
        App.addModal(new SelectSetOverlayLayout({
            model: this.model,
            collection: this.model.collection,
        }), {
            additionalClassName: 'modal-left modal-medium modal-select-set',
            onAfterClose: function(data) {
                if (data) {
                    App.radio('collection').execute(
                        'add:to:set',
                        this.model,
                        data.setModel
                    );
                }
            }.bind(this),
        });
    },

    /**
     * Is triggered from small action overlay, when the user wants change the status
     */
    onActionChangeStatus: function() {
        App.addModal(new ChangeStatusOverlayLayout({
            model: this.model,
            collection: this.model.collection,
        }), {
            additionalClassName: 'modal-left modal-medium modal-change-status',
            onAfterClose: function(data) {
                if (data) {
                    App.radio('collection').execute(
                        'change:status',
                        this.model,
                        data.statusModel,
                        data.recipient
                    );
                }
            }.bind(this),
        });
    },

    /**
     * Is triggered, when the user wants to delete the object
     */
    onActionDeleteObject: function() {
        if (!window.confirm(App.t('collection::object:action:confirmDelete', 1))) {
            return;
        }

        // Navigate away
        App.router.navigate(this.options.closeLinkUrl, {trigger: true, replace: true});

        // Delete item
        App.radio('collection').execute('delete', this.model);
    },

    onActionPrintLabel: function() {
        if (App.user.get('licenseLabelPrint')) {
            App.addModal(new LabelPrintModalView({
                itemIds: [this.model.id],
            }), {
                additionalClassName: 'modal-left modal-label-print',
            });
        } else {
            var restrictionModalView = new RestrictionLabelModalView();
            var options = {
                additionalClassName: 'modal-center modal-restriction',
                additionalBgClassName: 'light-bg',
            };
            App.addModal(restrictionModalView, options);
        }
    },

    onActionPrintReport: function() {
        App.addModal(new AdvancedPrintModalView({
            catalog: this.model.collection.catalog,
            item: this.model,
        }), {
            additionalClassName: 'modal-left modal-list-print',
        });
    },

    /**
     * Is triggered, when the user wants to duplicate the object
     */
    onActionDuplicateObject: function() {
        var id = this.model.id;

        App.router.navigate(this.options.closeLinkUrl, {trigger: true, replace: true});

        // ugly quickfix
        setTimeout(function() {
            $('body').removeClass('is-detail-mode');
            var catalogId = this.model.collection.catalog.id;
            App.router.navigate('/catalogs/' + catalogId + '/duplicate/' + id, {trigger: true});
        }.bind(this), 500);
    },

    /**
     * Is triggered, when the actions button in managed mode is clicked.
     * We will open the actions overlay
     * @param {object} event The click event
     */
    showActions: function(event) {
        var $curTarget = $(event.currentTarget);
        var pos = $curTarget.offset();
        var posRight = $(window).width() - pos.left - $curTarget.width();

        this.ui.actionLayerActions.css({
            top: pos.top - this.getPxSize(2) + 'px',
            right: posRight - this.getPxSize(4) + 'px',
        }).addClass('is-visible');

        // Close actions overlay on next click
        event.stopPropagation();
        $('body').one('click', function() {
            this.hideActions();
        }.bind(this));
    },

    hideActions: function() {
        if (this.ui.actionLayerActions.removeClass) {
            this.ui.actionLayerActions.removeClass('is-visible');
        }
    },

    /**
     * Opens the buy modal
     * @param event
     */
    actionBuy: function(event) {
        // if (App.user.get('shippingAddress')) { //@todo add shipping address to profile/user model/backend
        // if (App.user.get('address')) {
        //     // @todo if we have buy modal again put this back in
        //     App.addModal(new BuyModalView({
        //             model: this.model,
        //             shop: this.model.collection.catalog,
        //         }), {
        //         additionalClassName: 'modal-center',
        //     });
        // }

        var shop = this.model.collection.catalog;
        var offer = new shop.offers.model(null, {collection: shop.offers});

        var data = {
            objectId: this.model.id,
            shopType: shop.id,
        };

        offer.save(data, {
            success: function(model, response, options) {
                shop.offers.add(model);
                App.router.navigate('/community/groups/conversation/' + model.get('conversationId'), {trigger: true});
            }.bind(this),
            error: function(model, response, options) {
                if (response.responseJSON.error && response.responseJSON.error.status === 'buyer-and-seller-same-user') {
                    Messenger().post({
                        type: 'error',
                        message: App.t('collection::shop:create:offer:response:error-buyer-and-seller-same-user'),
                    });
                } else {
                    var errorMessage = Messenger().post({
                        type: 'error',
                        message: App.t('collection::shop:create:offer:response:error'),
                        actions: {
                            'try-again': {
                                label: App.t('global::messenger:button:try-again'),
                                action: function() {
                                    errorMessage.hide();
                                    this.actionBuy(event);
                                }.bind(this),
                            },
                            'cancel': {
                                label: App.t('global::messenger:button:cancel'),
                                action: function() {
                                    errorMessage.hide();
                                }.bind(this),
                            },
                        },
                    });
                }
            },
        });

        // @todo if user shipping address is done put thi s back in
        // } else {
        //     App.addModal(new ShippingAddressModalView({
        //             model: this.model,
        //             shop: this.model.collection.catalog,
        //         }), {
        //         additionalClassName: 'modal-center',
        //     });
        // }
    },

    /**
     * Opens the buy modal
     * @param event
     */
    actionSeller: function(event) {
        var shopId = this.model.collection.catalog.get('type');
        var shop = App.CollectionModule.ShopModule.shops.get(shopId);
        var request = $.getJSON(App.getApiUrl('/shops/seller/' + this.model.attributes.id));
        request.done(function(response) {
            var sellerData = response;

            if (sellerData.sellerProfile) {
                this.model.attributes.sellerProfile = sellerData;
            }

            App.addModal(new SellerModalView({
                model: this.model,
                shop: shop,
            }), {
                additionalClassName: 'modal-center modal-sell-item',
            });
        }.bind(this));
    },

    /**
     * Opens the sell modal
     * @param event
     */
    actionSell: function(event) {
        var shopId = this.model.collection.catalog.get('type');
        var shop = App.CollectionModule.ShopModule.shops.get(shopId);
        // if (App.user.get('shippingAddress')) { //@todo add shipping address to profile/user model/backend
        // if (App.user.get('address')) {
        App.addModal(new SellModalView({
                model: this.model,
                shop: shop,
            }), {
            additionalClassName: 'modal-center modal-sell-item',
        });
        // } else {
        //     App.addModal(new ShippingAddressModalView({
        //             model: this.model,
        //             shop: this.model.collection.catalog,
        //         }), {
        //         additionalClassName: 'modal-center',
        //     });
        // }
    },

    onResize: function() {
        this.hideActions();
    },

    onBeforeDestroy: function() {
        this.ui.moreInfo.off();
    },

    isShop: function() {
        var settingsModel = this.getOption('settings');

        return (settingsModel && settingsModel.get('isShop')) ? true : false;
    },
});

module.exports = DetailView;