'use strict';

var sortExtension = {
    /**
     * Default sort column
     * @type {String}
     */
    sortDefault: '',

    sortString: '',

    /**
     * Active sort columns
     * @type {Array}
     */
    sortFields: [],

    /**
     * Sort direction for the columns [ 'asc' | 'desc' ]
     * @type {Array}
     */
    sortDirections: [],

    /**
     * Map url/nice fieldnames to system fieldnames
     * @type {Object}
     */
    sortFieldsMap: {
        // examples:
        // 'newName': 'systemName',
        // 'name.asc': 'properName.asc-locality.desc', //either multiple full field(s) with direction
        // 'name.desc': 'properName.desc-locality.desc',
        // 'origin': 'locality', //or just a single field name
    },

    /**
     * Override of the compartor functionality
     * @param {Backbone.Model} a Model a
     * @param {Backbone.Model} b Model b
     * @returns {number}
     */
    comparator: function(a, b) {
        if (this.sortFields.length === 0) {
            return 0;
        }

        var cols = this.sortFields;
        var dirs = this.sortDirections;

        // First column that does not have equal values
        var cmp = _.find(cols, function (col) {
            var valueA = _.isString(a.get(col)) ? a.get(col).toLowerCase() : a.get(col);
            var valueB = _.isString(b.get(col)) ? b.get(col).toLowerCase() : b.get(col);

            return valueA !== valueB;
        });

        // undefined means they're all equal, so we're done.
        if (!cmp) { return 0; }

        // Otherwise, use that column to determine the order
        // match the column sequence to the methods for ascending/descending
        // default to ascending when not defined.
        var valueA = _.isString(a.get(cmp)) ? a.get(cmp).toLowerCase() : a.get(cmp);
        var valueB = _.isString(b.get(cmp)) ? b.get(cmp).toLowerCase() : b.get(cmp);
        var direction = dirs[_.indexOf(cols, cmp)] || 'asc';

        // if one of the values is null, simple > or < dont work. so:
        if (_.isNull(valueA) || _.isNull(valueB)){
            if (direction.toLowerCase() === 'asc') {
                return (_.isNull(valueA) && !_.isNull(valueB)) ? -1 : 1;
            } else {
                return (_.isNull(valueA) && !_.isNull(valueB)) ? 1 : -1;
            }
        }

        // simple > or <
        if (direction.toLowerCase() === 'asc') {
            return valueA > valueB ? 1 : -1;
        } else {
            return valueA < valueB ? 1 : -1;
        }
    },

    /**
     * Changes the sort for a collection
     * @param {string} fieldName The field name
     * @return {Colido.Collection} The instance itself for chaning
     */
    sortByFields: function (fields) {
        if (!_.isString(fields)) {
            if (_.isString(this.sortString) && this.sortString.length > 0) {
                fields = this.sortString;
            }

            if (!_.isString(fields)) {
                fields = this.collection ? this.collection.sortDefault : this.sortDefault; //this.collection is used for filtered collections
            }
        }

        this.sortString = fields;

        // Reset current sort
        this.sortFields = [];
        this.sortDirections = [];

        if (_.isString(fields) && fields.length > 0) {
            //this.collection is needed because filtered collections don't inherit original collections sortFieldMap
            fields = (this.collection ? this.collection.sortFieldsMap[fields] : this.sortFieldsMap[fields]) || fields;

            // Apply sorts
            _.each(fields.split('-'), function(item) {
                var parts = item.split('.');
                var field = parts[0];

                field = (this.collection ? this.collection.sortFieldsMap[field] : this.sortFieldsMap[field]) || field;

                if (field.length) {
                    this.sortFields.push(field);

                    var dir = 'asc';
                    if (parts.length === 2 && (parts[1] === 'asc' || parts[1] === 'desc')) {
                        dir = parts[1];
                    }

                    this.sortDirections.push(dir);
                }
            }.bind(this));
        }

        // Trigger sort
        this.sort();

        return this;
    },
};

module.exports = sortExtension;