'use strict';

var HeaderView = require('./header');

var ScannersHeaderView = HeaderView.extend({
    /**
     *
     * @returns {string} Unescaped Text
     */
    getHeadline: function () {
        var catalog = App.CollectionModule.catalogs.getActiveCatalog();

        var headline = App.t('collection::scanners:objects:list:headline', {catalogName: catalog.get('name')});

        return headline;
    },
});

module.exports = ScannersHeaderView;