module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header">\n    <h2>'+
((__t=( t('community::restriction-modal:headline')))==null?'':__t)+
'</h2>\n    <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=( t('global::modal:button:close')))==null?'':_.escape(__t))+
'</button>\n</header>\n\n<div class="modal-body">\n    <p>'+
((__t=(t('community::restriction-modal:text')))==null?'':_.escape(__t))+
'</p>\n\n    <a href="/account/profile" class="btn btn-primary">'+
((__t=(t('community::restriction-modal:button-profile')))==null?'':_.escape(__t))+
'</a>\n</div>';
}
return __p;
};