module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="content-header">\n    <h2>'+
((__t=(t('community::headline')))==null?'':_.escape(__t))+
'</h2>\n    <h2 class="subline">\n        '+
((__t=(t('community::subheadline')))==null?'':_.escape(__t))+
'\n    </h2>\n</header>\n\n<nav class="tabs">\n    <ul>\n        <li>\n            <a href="/community/contacts" class="tab-contacts '+
((__t=(when(view.getOption('activeTab') === 'contacts', 'active')))==null?'':_.escape(__t))+
'">\n                '+
((__t=(t('community::tab:label:contacs')))==null?'':_.escape(__t))+
'\n                <span class="count contacts-posts-count"></span>\n            </a>\n        </li>\n        <li>\n            <a href="/community/groups" class="tab-groups '+
((__t=(when(view.getOption('activeTab') === 'groups', 'active')))==null?'':_.escape(__t))+
'">\n                '+
((__t=(t('community::tab:label:groups')))==null?'':_.escape(__t))+
'\n                <span class="count groups-posts-count"></span>\n            </a>\n        </li>\n        <li>\n            <a href="/community/broadcasts" class="tab-broadcasts '+
((__t=(when(view.getOption('activeTab') === 'broadcasts', 'active')))==null?'':_.escape(__t))+
'">\n                '+
((__t=(t('community::tab:label:broadcasts')))==null?'':_.escape(__t))+
'\n            </a>\n        </li>\n    </ul>\n</nav>\n\n<div class="contacts tabs-content '+
((__t=(when(view.getOption('activeTab') === 'contacts', 'active')))==null?'':_.escape(__t))+
'">\n    <div class="contacts-region"></div>\n    <div class="pending-contacts-region"></div>\n</div>\n\n<div class="groups tabs-content '+
((__t=(when(view.getOption('activeTab') === 'groups', 'active')))==null?'':_.escape(__t))+
'">\n    <div class="groups-region"></div>\n</div>\n\n<div class="broadcasts tabs-content '+
((__t=(when(view.getOption('activeTab') === 'broadcasts', 'active')))==null?'':_.escape(__t))+
'">\n    <div class="broadcasts-region"></div>\n</div>';
}
return __p;
};