'use strict';

var CompositeView = require('base/Colido.CompositeView');
var ListGridItemView = require('./list-grid-item');
var EmptyView = require('./list-empty');
var ListGridNewItemView = require('./list-grid-new-item');

var template = require('modules/collection/templates/partials/list-grid.hbs');

require('jquery-humans-dnd');

var ListGridView = CompositeView.extend({
    template: template,

    childViewContainer: '.objects-grid-container',

    childView: ListGridItemView,

    emptyView: EmptyView,

    newObjectView: ListGridNewItemView,

    getChildView: function (child) {
        if (child.get('virtualItem')) {
            return this.newObjectView;
        } else {
            return this.childView;
        }
    },

    /**
     * Show 'add new object' item only in full catalog, not when
     * search, filter, set or location are active.
     */
    showVirtualItem: function () {
        var model = this.model;

        return !model.get('search')
            && !model.get('filter')
            && !model.get('setModel')
            && !model.get('locationModel')
            && !model.get('importModel')
            && !model.get('importWarningsModel')
            && !model.get('isShop');
    },

    ui: {
        'listingContainer': '.objects-listing',
        'selectOverlay': '.select-overlay',
        'mobileBackToTop': '[data-action="back-to-top"]',
    },

    events: {
        'click @ui.selectOverlay': 'selectOverlayClick',
        'click @ui.mobileBackToTop': 'mobileBackToTop',
    },

    modelEvents: {
        'change:controlMode': 'controlModeChanged',
        'change:activeModel': 'activeModelChanged',
    },

    childEvents: {
        'activated': 'setActiveChildView',
    },

    reorderOnSort: false,

    /**
     * @todo: Move this to compositeview, if possible!?
     */
    scrollPosition: 0,

    /**
     * Enable Drag and Drop
     * @type {boolean}
     */
    enableDnd: true,

    //private properties
    _isDetailMode: false,

    _isManageMode: false,

    startDelays: [],

    initialize: function(options) {
        CompositeView.prototype.initialize.apply(this, arguments);

        if (this.model.get('activeModel')) {
            this.toggleDetailMode(true);
        }

        this.listenTo(App.vent, 'scrollContainerScroll', this.onLayoutScrollContainerScroll.bind(this));
        this.listenTo(App.vent, 'keydown', this.handleKeyDown.bind(this));
    },

    emptyViewOptions: function() {
        return {
            collection: this.collection,
            model: this.model,
            viewMode: 'grid',
        };
    },

    /**
     * Options to pass to childView
     * @param [object} model The model
     * @param {int}    index The index of the model
     * @returns {object} The child view options
     */
    childViewOptions: function(model, index) {
        return {
            viewMode: 'grid',

            isManageMode: this.isManageMode.bind(this), //can change after child render. so needs to be a function

            isDetailMode: this.isDetailMode.bind(this), //can change after child render. so needs to be a function

            state: this.model,

            isActive: this.model.get('activeModel') ? this.model.get('activeModel').id === model.id : false,
        };
    },

    /**
     * Callback for redering process
     * @return {void}
     */
    onBeforeRender: function() {
        CompositeView.prototype.onBeforeRender.apply(this, arguments);

        this.scrollPosition = $('#content').scrollTop();
    },

    onLayoutScrollContainerScroll: function(event) {
        if (!_.isObject(this.ui.mobileBackToTop) || this.isDestroyed) {
            return;
        }

        if ($(event.currentTarget).scrollTop() > 250) {
            //btn-back-to-top
            this.ui.mobileBackToTop.addClass('is-visible');
        } else {
            this.ui.mobileBackToTop.removeClass('is-visible');
        }
    },

    onShow: function () {
        // On first render, attempt to set scroll position after CSS
        // transition has finished.
        // @todo: Set scroll position on CSS transition end event
        setTimeout(function () {
            var relatedScrollContainer = $('.list-layout-scroll-container').length
                ? '.list-layout-scroll-container'
                : '#content';

            if (this.getOption('enableDnd') && !App.isShare()) {
                this.$el.humansDrag({
                    delegate: '.box:not(.box-empty)',
                    offset: [5, 5],
                    scrollContainer: [relatedScrollContainer, '#navigationSets'],
                    helperClass: 'box-drag-helper',
                    revertClass: 'box-drag-revert',
                    dropClass: 'box-drag-dropped',
                    overDropClass: 'box-drag-overdrop',
                    onDragStart: function (event, humansDrag, element, helper) {
                        // Disable the default link behavior
                        $('html').addClass('has-active-drag has-active-drag-object');
                        element.addClass('box-drag-placeholder');
                        helper.removeAttr('href');
                    }.bind(this),
                    onDragEnd: function (event, humansDrag, element, helper) {
                        $('html').removeClass('has-active-drag has-active-drag-object');

                        // Wait, till element was dropped
                        if (!humansDrag.wasDropped) {
                            window.setTimeout(function () {
                                element.removeClass('box-drag-placeholder');
                            }, 400);
                        } else {
                            element.removeClass('box-drag-placeholder');
                        }
                    }.bind(this),
                    onDisable: function (humansDrag) {
                        this.$('.box:not(.box-empty)').removeClass('box-drag-placeholder');
                    }.bind(this),
                });
            }

            this.setListScrollPosition();
        }.bind(this), 400);
    },

    /**
     * Is called, everytime the children are rendered, except the first time.
     * @return {void}
     */
    onRenderCollection: function() {
        $('#content').scrollTop(this.scrollPosition);
    },

    selectOverlayClick: function(event) {
        $(event.currentTarget).next()[0].focus();
    },

    controlModeChanged: function(model, controlMode) {
        var isManage = controlMode === 'manage';
        this.ui.listingContainer.toggleClass('is-manage-mode', isManage);
        this._isManageMode = isManage;

        // Disable DND operations
        this.$el.humansDrag(isManage ? 'disable' : 'enable');
    },

    /**
     * Destroy plugins and additional listener
     */
    onBeforeDestroy: function() {
        // Make sure, view is not destroyed yet
        if (this.isDestroyed) {
            return;
        }

        // clear the start-delays
        // it could be possible, taht these are not fireed till now
        _.each(this.startDelays, function(timerId){
            clearTimeout(timerId);
        });

        this.startDelays = [];

        // Remove lazyrender hints
        $('body').removeClass('is-lazyrender-not-top');
    },

    setActiveChildView: function(activeChildView) {
        this.collection.activate(activeChildView.model)
    },

    getActiveChildView: function() {
        var activeModel = this.model.get('activeModel');
        if (activeModel) {
            return this.children.findByModel(activeModel);
        } else {
            return null;
        }
    },

    /**
     * Sets scroll position so that an element is within the viewport.
     * @param {object} options Options
     * @param {jquery} options.scrollContainer Scroll container jQuery reference, defaults to this.getOption('scrollContainer').
     * @param {jquery} options.element Element to scroll to, defaults to this.getActiveChildView().
     * @param {string} options.align Force alignment instead of using element position to calculate alignment. Accepted values: 'top', 'bottom'.
     * @param {string} options.callback Function to call when scrolling is complete or interrupted.
     */
    setListScrollPosition: function(options) {
        options = _.extend({}, options);

        var scrollContainer = options.scrollContainer || this.getOption('scrollContainer');

        var activeChildView = this.getActiveChildView();
        var element = options.element || activeChildView && activeChildView.$el;

        if (scrollContainer && element && element.length > 0) {
            var scrollElementHeight = scrollContainer.height();
            var elementTopOffset = element.position().top;
            var elementHeight = element.outerHeight();

            var scrollTop = null;

            if (options.align === 'top' || !options.align && elementTopOffset < 0) {
                scrollTop = scrollContainer.scrollTop() + elementTopOffset - 110;
            } else if (options.align === 'bottom' || !options.align && elementTopOffset + elementHeight > scrollElementHeight) {
                scrollTop = scrollContainer.scrollTop() + elementTopOffset + elementHeight - scrollElementHeight + 40;
            }

            if (scrollTop !== null) {
                if (browser.isIos) {
                    scrollContainer.scrollTop(scrollTop);
                    if (_.isFunction(options.callback)) {
                        clearTimeout(this.timeoutSetListScrollPosition);
                        this.timeoutSetListScrollPosition = setTimeout(options.callback, 300);
                    }
                } else {
                    scrollContainer.stop().animate({scrollTop: scrollTop}, {duration: 200, always: options.callback});
                }
            } else {
                if (_.isFunction(options.callback)) {
                    options.callback.apply(this, arguments);
                }
            }
        } else {
            if (_.isFunction(options.callback)) {
                options.callback.apply(this, arguments);
            }
        }
    },

    isManageMode: function() {
        return this._isManageMode;
    },

    isDetailMode: function() {
        return this._isDetailMode;
    },

    activeModelChanged: function (params, activeModel) {
        this.toggleDetailMode(activeModel != null);

        this.children.each(function(childView){
            if (childView.model === activeModel) {
                childView.activate && childView.activate(); //virtual item does not have activate/deactivate
            } else {
                childView.deactivate && childView.deactivate();
            }
        });

        var element, eventName;
        if (activeModel) {
            // Scroll to new active model
            eventName = 'show:extra-content';
        } else {
            // Restore scroll position
            eventName = 'hide:extra-content';
            element = this.findElementClosestToViewportCentre();
        }

        this.lazyRender = false;
        this.stopListeningToScroll();

        var reEnableScroll = function(paramElement) {
            this.setListScrollPosition({
                element: paramElement,
                callback: function() {
                    this.listenToScroll();
                    this.lazyRender = true;
                }.bind(this),
            });
        }.bind(this);

        if (this._firstTimeDetailMode) {
            this.listenToOnce(App.vent, eventName, function() {
                reEnableScroll(element);
            }.bind(this));
        } else {
            reEnableScroll(element);
        }
    },

    toggleDetailMode: function(flag) {
        var wasDetailMode = this._isDetailMode;

        if (_.isUndefined(flag)) {
            this._isDetailMode = !this._isDetailMode;
        } else {
            this._isDetailMode = !!flag;
        }

        if (wasDetailMode === false && this._isDetailMode === true) {
            this._firstTimeDetailMode = true;
        } else {
            this._firstTimeDetailMode = false;
        }

        // Disable DND operations
        this.$el.humansDrag(this._isDetailMode ? 'disable' : 'enable');

        return this;
    },

    showCollection: function () {
        $('body').toggleClass('is-lazyrender-not-top', this.lazyRenderPage > 1);

        return CompositeView.prototype.showCollection.apply(this, arguments);
    },

    findElementClosestToViewportCentre: function () {
        var containerHeight = this.getOption('scrollContainer').height();
        var targetPos = containerHeight / 2;
        var els = this.getChildrenEl();

        var bestElement = _.reduce(els, function (best, el) {
            var $el = $(el);
            var pos = $el.offset().top + $el.outerHeight() / 2;
            var distance = Math.abs(targetPos - pos);

            if (!best || distance < best.distance) {
                best = {
                    el: $el,
                    distance: distance,
                };
            }

            return best;
        }, null);

        return bestElement.el;
    },

    /**
     * Scrolls the list to top and shows the first elements
     * @param {object} event
     */
    mobileBackToTop: function(event) {
        this.lazyRenderPage = 0;
        this.showCollection(0);
        $('.list-layout-scroll-container').stop().animate({scrollTop: 0});
    },

    handleKeyDown: function(event) {
        var state = this.model;
        var activeModel = state.get('activeModel');

        if (activeModel && !App.hasModal()) { //only in detail mode, when no modals are open
            if (event.which === App.keys.LEFTARROW) {
                var previousModel = activeModel && this.collection.getPreviousModel(activeModel); //@todo Why is activeModel.collection different from this.collection?
                var previousModelUrl = previousModel ? state.getPath(previousModel) : null;

                if (previousModelUrl) {
                    App.router.navigate(previousModelUrl, {trigger: true});
                }
            }

            if (event.which === App.keys.RIGHTARROW) {
                var nextModel = activeModel && this.collection.getNextModel(activeModel);
                var nextModelUrl = nextModel ? state.getPath(nextModel) : null;

                if (nextModelUrl) {
                    App.router.navigate(nextModelUrl, {trigger: true});
                }
            }
        }
    },
});

module.exports = ListGridView;