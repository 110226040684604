'use strict';

module.exports = {
    localization: {
        'thousand:separator': ',',
        'decimal:separator': '.',
    },

    global: {
        'api:fetch-error': 'Could not fetch {data} from the server.',

        'lang-de': 'German',
        'lang-en': 'English',

        // Units sizes
        'units-sizes:cm:short': 'cm',
        'units-sizes:cm:long':  'Centimeter',
        'units-sizes:inch:short': 'inch',
        'units-sizes:inch:long':  'Inch',
        'units-sizes:m:short': 'm',
        'units-sizes:m:long':  'Meter',
        'units-sizes:mm:short': 'mm',
        'units-sizes:mm:long':  'Millimeter',

        // Units weights
        'units-weights:g:short': 'g',
        'units-weights:g:long':  'Gram',
        'units-weights:kg:short': 'kg',
        'units-weights:kg:long':  'Kilogram',
        'units-weights:oz:short': 'oz',
        'units-weights:oz:long':  'Ounces',
        'units-weights:ct:short': 'ct',
        'units-weights:ct:long':  'Carat',
        'units-weights:lbs:short': 'lbs',
        'units-weights:lbs:long':  'Pounds and Ounces',

        // Currencies
        'currencies:chf:short': 'CHF',
        'currencies:chf:long': 'Swiss Franc',
        'currencies:chf:symbol': 'CHF',
        'currencies:chf:amount': '{amount} CHF',

        'currencies:eur:short': 'EUR',
        'currencies:eur:long': 'Euro',
        'currencies:eur:symbol': '€',
        'currencies:eur:amount': '€ {amount}',

        'currencies:gbp:short': 'GBP',
        'currencies:gbp:long': 'British Pound Sterling',
        'currencies:gbp:symbol': '£',
        'currencies:gbp:amount': '£ {amount}',

        'currencies:usd:short': 'USD',
        'currencies:usd:long': 'United States Dollar',
        'currencies:usd:symbol': '$',
        'currencies:usd:amount': '$ {amount}',

        'currencies:unknown:short': '¤',
        'currencies:unknown:long': '¤',
        'currencies:unknown:symbol': '¤',
        'currencies:unknown:amount': '¤ {amount}',

        // Time and Dates
        'objects-selected:count': {
            '0': 'No objects selected',
            '1': '{n} object selected',
            'n': '{n} objects selected',
        },

        // Other
        'objects:count': {
            '0': '{n} Objects',
            '1': '{n} Object',
            'n': '{n} Objects',
        },

        'month': {
            '1': 'January',
            '2': 'February',
            '3': 'March',
            '4': 'April',
            '5': 'May',
            '6': 'June',
            '7': 'July',
            '8': 'August',
            '9': 'September',
            '10': 'October',
            '11': 'November',
            '12': 'December',
            'n': 'Unexpected month',
        },

        'relative-date:today': 'Today',
        'relative-date:yesterday': 'Yesterday',

        'object-types:colido-item-set': 'Set',
        'object-types:colido-storage': 'Storage',
        'object-types:colido-catalog': 'Catalog',
        'object-types:colido-object': 'Object',

        'modal:button:close': 'Close',
        'modal:button:cancel': 'Cancel',
        'modal:button:done': 'Done',

        'action:share-item': 'Share',
        'item-shared:short': 'Shared',
        'item-shared:long': 'This item is shared',

        'continents:africa': 'Africa',
        'continents:asia': 'Asia',
        'continents:australia': 'Australia',
        'continents:europe': 'Europe',
        'continents:north-america': 'North America',
        'continents:south-america': 'South America',

        'messenger:button:try-again': 'Try again',
        'messenger:button:cancel': 'Cancel',

        'button:scroll-back-to-top': 'Scroll back to top',
    },

    'drag-n-drop': {
        'trash': 'Trash',
        'remove-from-set': 'Remove from set',
        'add-to-set': 'Add to set',
    },

    '404': {
        'headline': 'This site is not available at the moment',
        'subheadline': 'We are sorry!',
        'text': 'We could not find the page you requested.',
    },

    'file-upload': {
        'upload-a-file:placeholder': 'Upload a file',
        'upload-file': 'Upload file',
        'choose-from-disk': 'Choose from disk',
    },

    navigation: {
        'logout': 'Logout',
    },

    base: {
        'file-upload:error:500': 'A problem occured on the server. Please try again later.',
        'file-upload:unknown-error': 'An unknown error occured',
    },

    user: {
        'validate:name-required': 'Please enter your name.',
        'validate:invalid-email': 'Please enter your email address.',
    },

    'signup': {
        'headline': 'Get started with a free account',
        'subline': 'Including a 30 day trial of all premium features. No credit card required.',
        'claim': 'Collection Management rethought',
        'placeholder-email': 'Enter your email',
        'placeholder-fullname': 'Enter your full name',
        'placeholder-password': 'Create a password',
        'signup': 'Create account now',
        'cancel': 'Cancel',
        'pricing-hint': 'See plans and pricing',
        'pricing-url': 'https://colido.de/en/pricing',
        'signup-success': 'Your account has been successfully created.',
        'havent-account': 'Don’t have an account?',
        'show-password': 'show password',
        'hide-password': 'hide password',
        'to-the-signup': 'Get started',
        'signup-terms-agree': 'I agree to Colido’s <a href="https://www.colido.de/downloads/terms_and_conditions-colido-en.pdf" target="_blank">terms and conditions</a> and have read the <a href="http://www.colido.de/downloads/privacy-colido-en.pdf" target="_blank">privacy statement</a>',

        'password:bad': 'Password is weak',
        'password:moderate': 'Password is ok',
        'password:good': 'Password is great',

        'tipsy:password': 'Please create a strong password. <br /><br /> A strong password is at least 6 characters long and includes capital and lower-case letters, numbers and symbols',

        'unknown-error': 'Sorry, something went wrong',
        'server-errors:email-required': 'Field Email is required',
        'server-errors:fullname-required': 'Field Fullname is required',
        'server-errors:password-required': 'Field password is required',
        'server-errors:email-already-in-use': 'Emailaddress already in use',
        'server-errors:unknown-error': 'An unknown error has occurred',
        'server-errors:email-invalid': 'Your entered email is not valid',
        'server-errors:password-is-to-short': 'Your entered password is to short',
    },

    'signup-token': {
        'headline': 'Signup confirmation',
        'please-wait': 'Please wait …',
        'back-to-the-loginform': 'Back to login',
        'no-token-found': 'No token found.', //@todo Make user understandable
        'error': 'error!', //@todo
        'ok': 'ok!', //@todo
    },

    login: {
        'headline': 'Login to your Colido account',
        'placeholder-email': 'Type your email',
        'placeholder-password': 'Type your password',
        'sing-in-button-label': 'Login',
        'forgot-password': 'Forgot your password?',
        'invalid-email': 'Please enter your email address',
        'password-required': 'Please enter your password',
        'already-registered': 'Already have an account?',
        'to-the-login': 'Login here',
        'unknown-error': 'Sorry, something went wrong.',
        'your-interim-password': 'Your interim password: ',
    },

    'forgot-password': {
        'headline': 'Lost your password?',
        'subline': 'Not a problem. We will send you an email with instructions on how to reset it.',
        'placeholder-email': 'Type your email',
        'submit': 'Reset password',
        'please-wait': 'Please wait …',
        'invalid-email': 'Please enter your email address',
        'cancel': 'Cancel',
        'your-new-password': 'Your new Password:',
        'back-to-the-loginform': 'Back to login',
        'no-token-found': 'No token found.',
        'success': 'You will receive an email shortly',
        'unknown-error': 'Sorry, something went wrong.',
    },

    'forgot-password-token': {
        'headline': 'New interim password',
        'please-wait': 'Please wait...',
        'info': 'Please copy and remember your new interim password. Due to security reasons we advice you to change your password in your account settings afterwards. In order to do this you will need the interim password again.',
        'go-to-login': 'Go to Login',
    },

    'print-modal': {
        'modal-title': 'Basic Object Report',
        'advanced-modal-title': 'List print settings',
        'modal-subtitle:object-count': {
            0: '{n} objects',
            1: '{n} object',
            n: '{n} objects',
        },
        'generate-button': 'Generate',
        'abort-button': 'Cancel',
        'form:single': 'Single',
        'form:fit': 'Fit',
        'form:report-type': 'Report type',
        'form:report-type:summary': 'Summary',
        'form:report-type:detailed': 'Detailed',
        'form:report-type:appraisal': 'Appraisal ',
        'form:title': 'Report title',
        'form:title-placeholder': 'Type report title',
        'form:items-per-page': 'Items per page',
        'form:print-summery': 'Print summary',
        'form:print-summery-yes': 'Yes',
        'form:print-summery-no': 'No',
        'form:page-size': 'Page size',
        'form:page-size:a4': 'DIN A4',
        'form:page-size:letter': 'US Letter',
        'form:printing-options': 'Printing options',
        'form:printing-options:hint': 'Please choose which information you would like to include while printing your selection. All other fields will be hidden on the report.',

        'messages-success:pdf:message': 'Your PDF {filename} is ready to download.',
        'messages-success:pdf:download-button': 'Download PDF',
        'messages-progress:pdf:message': 'The PDF is being generated. You will be notified if it is ready to download',
        'messages-error:pdf:message': 'Sorry, we could not generate the file you requested. Please try again later.',

        'messages-progress:csv:message': 'The CSV file is being generated. You will be notified if it is ready to download.',
        'messages-success:csv:message': 'Your CSV {filename} is ready to download.',
        'messages-success:csv:download-button': 'Download CSV',
        'messages-error:csv:message': 'Sorry, we could not generate the file you requested. Please try again later.',
    },

    'label-print-modal': {
        'modal-title': 'Label print settings',
        'modal-subtitle:object-count': {
            0: '{n} objects',
            1: '{n} object',
            n: '{n} objects',
        },
        'generate-button': 'Generate',
        'abort-button': 'Cancel',

        'messages-success:pdf:message': 'Your PDF »{filename}« is ready to download.',
        'messages-success:pdf:download-button': 'Download PDF',
        'messages-progress:pdf:message': 'The PDF is being generated. You will be notified if it is ready to download',
        'messages-error:pdf:message': 'Sorry, we could not generate the file you requested. Please try again later.',
        'text:introduction': 'Please choose your paper and label sizes. Labels will be placed to fully fit the print sheet. To control the sheet layout specify column gap widths or decenter the layout by defining offset values.',
        'label-headline:paper-size': 'Paper size',
        'label:din-a4': 'DIN A4',
        'label:letter': 'Letter',
        'label:custom-format': 'Custom size',
        'placeholder:paper-width': 'Width',
        'label:paper-width': 'Width',
        'placeholder:paper-height': 'Height',
        'label:paper-height': 'Height',
        'label-headline:labelformat': 'Label size',
        'placeholder:label-width': 'Width',
        'label:label-width': 'Width',
        'placeholder:label-height': 'Height',
        'label:label-height': 'Height',
        'label-headline:section-layout': 'Sheet layout',
        'placeholder:horizontal-gap': 'Gap horizontal',
        'label:horizontal-gap': 'Gap horizontal',
        'placeholder:vertical-gap': 'Gap vertical',
        'label:vertical-gap': 'Gap vertical',
        'hint:gap': 'To control the distance between labels on the print sheet specify vertical and horizontal gap widths',
        'placeholder:offset-horizontal': 'Offset horizontal',
        'label:offset-horizontal': 'Offset horizontal',
        'placeholder:vertical-offset': 'Offset vertical',
        'label:vertical-offset': 'Offset vertical',
        'hint:offset': 'By default the sheet layout will be centered on the print sheet. To reposition the layout on the sheet define its offset from the center',
        'label:print-lines': 'Show reference line',
        'label:print-lines-yes': 'Yes',
        'label:print-lines-no': 'No',
        'hint:print-lines': 'Outlines will display the size of each label on the sheet',
        'label:show-price': 'Show retail prices',
        'label:show-price-yes': 'Yes',
        'label:show-price-no': 'No',
        'label:label-type': 'Stand up card',
        'label:label-type-yes': 'Yes',
        'label:label-type-no': 'No',
        'hint:label-type': 'Stand up cards will be folded in the middle and can then be mounted just like place cards',
        'label:print-logo': 'Include profile logo',
        'label:print-logo-yes': 'Yes',
        'label:print-logo-no': 'No',
        'hint:print-logo': 'Please upload a logo image in your profile settings',
        'label:convert-currency': 'Convert currencies',
        'hint:convert-currency': 'Convert all retail prices into different currencies using the latest exchange rates – and show them on your labels',
        'option:currency:no': 'do not convert',
        'option:currency:gbp': 'into GBP',
        'option:currency:chf': 'into  CHF',
        'option:currency:usd': 'into  USD',
        'option:currency:eur': 'into  EUR',
        'label:conversion-type': 'Round retail prices',
        'option:conversion:up': 'round up',
        'option:conversion:down': 'round down',
        'option:conversion:do-not': 'do not round',
        'option:conversion:default': 'round to nearest',
        'label:conversion-rounding': 'Round to',
        'option:round-to:none': 'decimal places',
        'option:round-to:1': 'ones',
        'option:round-to:10': 'tens',
        'option:round-to:100': 'hundreds',
        'hint:conversion-rounding': 'Define how »smooth« converted prices should be.\n\nPrices below 1000 will be rounded to tens at the most. Prices below 100 will be rounded to ones',
    },

    welcome: {
        'headline': 'Welcome {name}!',
        'button:close': 'Close',
        'introduction': 'Thank you for creating a Colido account. We put together a five minute tutorial video for you to get started',
        'info': 'The video is also available in the help section',
        'button:skip': 'Watch later',
    },

    'delete-account': {
        'headline': 'Delete account',
        'subline': 'Are you sure that you want to delete your Colido account? All data will be lost.',
        'no': 'Cancel',
        'yes': 'Yes, delete now',
        'success': '<h2>Goodbye. We feel sad to see you go!</h2>',
        'fail': '<h1>Something went wrong</h1><p>We are sorry. Please try again or send an email to <a href="mailto:support@colido.de">support@colido.de</a>.</p>',
    },

    dashboard: {
        'label': 'Dashboard',
        'headline': 'Dashboard',
        'subline:welcome': 'Welcome back {name}!',
        'button:new-object': 'Add new object',
        'button:import-database': 'Import database',

        'headline:conversations': {
            0: 'No recent messages',
            n: 'Recent messages',
        },

        'button:conversations': 'Colido Talk',
        'button:add-contact': 'Invite contact',

        'headline:collection': 'Recent objects',
        'button:collection': 'Objects',

        'headline:raffle': 'For visitors of the Munich Show 2017',
        'button:raffle': 'Win code',

        'headline:sets': 'Recent sets',
        'button:sets': 'Sets',

        'headline:storages': 'Recent storages',
        'button:storages': 'Storages',

        'headline:imports-in-progress': 'Imports in progress',

        'headline:getting-started': 'Getting started',
        'text:getting-started': 'Sit back and watch our five minute introduction to our main features and the interface.',

        'headline:getting-started-video': 'Learn how in 5 minutes',
    },

    'new-object': {
        'label': 'Add Object',
    },

    settings: {
        'label': 'Settings',

        // Account
        'account:label': 'Account',
        'account:email:label': 'Email address',
        'account:email:placeholder': 'Type your email',
        'account:name:label': 'Full name',
        'account:name:placeholder': 'Enter your full name',
        'account:password:label': 'Password',
        'account:password:placeholder': 'Enter your password',
        'account:language:label': 'Language',
        'account:language:hint': 'At this point English and German are the only interface language available. We will update your system to your selected language as soon as it becomes available.',
        'account:language:please-choose': 'Choose your language',
        'account:button:update': 'Save',
        'account:button:delete-account': 'Delete account',
        'account:button:password-change': 'Change',
        'account:unknown-save-error': 'Your data could not be saved.',
        'account:unknown-error': 'An unknown error occurred.',
        'account:saveSuccess': 'Your data was saved successfully.',
        'account:headline:general-account-settings': 'General account settings',
        'account:headline:contact-information': 'Contact information',
        'account:hint:contact-information-private': 'Your contact information is kept safe and private',

        // change password
        'account:change-password:headline': 'Change password',
        'account:change-password:label': 'New password',
        'account:change-password:save': 'Save',
        'account:change-password:cancel': 'Cancel',
        'account:change-password:placeholderOld': 'Enter your current password',
        'account:change-password:placeholder': 'Enter your new password',
        'account:change-password:labelOld': 'Current password',
        'account:change-password:labelRetype': 'Password confirmation',
        'account:change-password:placeholderRetype': 'Re-enter your new password',
        'account:change-password:old-password-was-wrong': 'The old password you entered is not correct',

        // Account delete
        'account:delete:headline': 'Delete account',
        'account:delete:cancel': 'Cancel',
        'account:delete:success': 'You will receive an email soon',
        'account:delete:info': 'Are you sure you would like to delete your account? If so, please tap the button below and we will send you a "delete link" via email.',
        'account:delete:button': 'Send delete link',

        // Profile
        'profile:label': 'Profile',
        'profile:picture': 'Profile picture',
        'profile:button:update': 'Save',
        'profile:unknown-error': 'An unknown error occurred.',
        'profile:saveSuccess': 'Your data was saved successfully.',
        'profile:name:label': 'Profile name',
        'profile:name:placeholder': 'Enter your full name',
        'profile:website:label': 'Website',
        'profile:website:placeholder': 'Type website address',
        'profile:tele:label': 'Phone',
        'profile:tele:placeholder': 'Type phone number',
        'profile:biography:label': 'Biography',
        'profile:biography:placeholder': 'Add a short biography if you like',
        'profile:address:label': 'Address',
        'profile:address:placeholder': 'Type street address and number',
        'profile:address2:placeholder': 'Add additional address info',
        'profile:zip-city:label': 'ZIP and City',
        'profile:zip:placeholder': 'Type ZIP',
        'profile:city:placeholder': 'Type city or town',
        'profile:country:label': 'Country',
        'profile:country:please-choose': 'Select Country',
        'profile:picture:take': 'Take picture',
        'profile:picture:take-snapshot': 'Snapshot!',
        'profile:picture:take-snapshot-cancel': 'Cancel',
        'profile:picture:take-snapshot-submit': 'Upload',
        'profile:picture:take-snapshot-redo': 'Again',
        'profile:picture:delete': 'Delete picture',
        'profile:logo:delete': 'Delete logo',
        'profile:saveinfo': 'Edit your profile informations and click „save“',
        'profile:subline:profile-public': 'This information will be visible to others',

        // wincode
        'profile:wincode:headline:please-enter-wincode': 'Please enter your win code',
        'profile:wincode:button:cancel': 'Cancel',
        'profile:wincode:button:close': 'Close',
        'profile:wincode:button:verify': 'Activate',
        'profile:wincode:placeholder:type-wincode-code': 'Type win code',
        'profile:wincode:text:response-success': 'The win code was saved succesfully. You are participating. You will be informed by COLIDO Talk if you win in the next 14 days.',
        'profile:wincode:text:response:wincode-not-writable': 'The win code could not be saved.',
        'profile:wincode:text:response:wincode-used-by-me': 'You have registered a code already an you are participating.',
        'profile:wincode:text:response:wincode-used-by-other': 'The code you entered is not valid or already in use. Please try another code or send an email to <a href="mailto:support@colido.de?subject=Win code is already used">support@colido.de</a>.',
        'profile:wincode:text:response:wincode-unknown': 'The code you entered is not valid or already in use. Please try again or send an email to <a href="mailto:support@colido.de?subject=Win code is not valid">support@colido.de</a>.',
        'profile:enter-wincode:button': 'Enter win code',

        // Seller Profile
        'profile:seller:label:private': 'private',
        'profile:seller:label:commercial': 'commercial',
        'profile:seller:company:label': 'Company',
        'profile:seller:company:placeholder': 'Type your company name',
        'profile:seller:contact:label': 'Contact person',
        'profile:seller:contact:placeholder': 'Type name/surname',
        'profile:seller:address:label': 'Address',
        'profile:seller:address:placeholder': 'Street, Number',
        'profile:seller:plz:label': 'Zip code',
        'profile:seller:plz:placeholder': 'Type zip code',
        'profile:seller:city:label': 'City',
        'profile:seller:city:placeholder': 'Type the city',
        'profile:seller:country:label': 'Country',
        'profile:seller:country:placeholder': 'Type the country',
        'profile:seller:email:label': 'Email',
        'profile:seller:email:placeholder': 'Type the email address',
        'profile:seller:www:label': 'Website',
        'profile:seller:www:placeholder': 'www',
        'profile:seller:phone:label': 'Telephone',
        'profile:seller:phone:placeholder': '+1 555 555555',
        'profile:seller:registration:label': 'Registration number',
        'profile:seller:registration:placeholder': 'Type reg. number',
        'profile:seller:registrationOffice:label': 'Registration office',
        'profile:seller:registrationOffice:placeholder': 'Type reg. office',
        'profile:seller:uid:label': 'Tax number',
        'profile:seller:uid:placeholder': 'Type tax number',
        'profile:seller:agb:label': 'Link to GTC',
        'profile:seller:agb:placeholder': 'www',
        'profile:seller:dse:label': 'Link to privacy policy',
        'profile:seller:dse:placeholder': 'www',
        'profile:seller:notes:label': 'Notes',
        'profile:seller:notes:placeholder': 'Type notes',

        // Subscription
        'subscription:label': 'Subscription',
        'subscription:your-subscription:title': 'Subscription',
        'subscription:free-trial:text': 'You are using the free trial version of all premium features.\nYou will be downgraded to the free basic version automatically \non {freeTrialExpiresAt}. No data will be lost after the downgrade.\n\nPlease click on the button “Setup Subscription” to see \nplans and pricing information.',
        'subscription:free-trial:text-mobile': 'You are using the free trial version of all premium features.\nYou will be downgraded to the free basic version automatically \non {freeTrialExpiresAt}. No data will be lost after the downgrade.\n\nIn order to upgrade to our premium plan, please switch to a device with a bigger screen.',
        'subscription:your-subscription:text': 'You are using the Colido’s free plan “Basic”.\n\nPlease click on the button “Setup Subscription” to see plans and pricing information or upgrade.',
        'subscription:your-subscription:text-mobile': 'You are using the Colido’s free plan “Basic”.\n\nIn order to upgrade to our premium plan, please switch to a device with a bigger screen.',
        'subscription:button:setup-subscription': 'Setup Subscription',

        // Preferences
        'preferences:label': 'Preferences',
        'preferences:top-info': 'You can adjust the preferences of the system and the<br /> cataloging process to your liking.',
        'preferences:right-info': 'Please choose which information you would like to capture while cataloging your objects. All other fields will be hidden on the cataloging dialog.',
        'preferences:default-units-and-currencies': 'Default units and currencies',
        'preferences:weight-unit': 'Weight unit',
        'preferences:length-unit': 'Length unit',
        'preferences:currency': 'Currency',
        'preferences:default-input-fields': 'Default input fields',
        'preferences:unknown-save-error': 'Your data could not be saved.',

        // Notifications
        'notifications:label': 'Notifications',
        'notifications:headline': 'E-Mail Notifications you‘ll receive',
        'notifications:checkbox:label:account': 'Account, security and privacy',
        'notifications:checkbox:label:company-news': 'Colido Company News',
        'notifications:checkbox:label:product-updates': 'Product and Feature Updates',
        'notifications:hint': 'Turn on/off specific email notifications as you like. Notifications regarding your account are not deactivatable.',

        // Trash
        'trash:label': 'Trash',
        'trash:button-empty-trash': 'empty trash',
        'trash:button-restore': 'restore',
        'trash:note:top': 'We keep all your deleted items for 10 days. This will help you, if you deleted anything accidentally.',
        'trash:note:empty-trash': 'Your trash is empty. If you have deleted an item, you can restore it here.',
        'trash:show-trash-button': 'Show trash',
        'trash:table:name': 'Name',
        'trash:table:type': 'Type',
        'trash:table:date': 'Date',
        'trash:table:time': 'Time',
        'trash:response:restore-success': 'Your Item was successfully restored.',
        'trash:response:restore-error': 'Your item could not be restored.',
        'trash:response:trash-cleared-success': 'Your trash was cleared sucessfully.',
        'trash:response:trash-cleared-error': 'Your trash could not be cleared.',
        'trash:subline:item-count': {
            0: 'No items',
            1: '{n} item',
            n: '{n} items',
        },

        // Import
        'import:label': 'Import',
        'import:headline:imports-in-progress': 'Imports in progress',
        'import:button-abort': 'abort',
        'import:button-import-database': 'import database',
        'import:note-new:label': 'Import database',
        'import:note-new:text': 'By clicking on “<a href="{importPath}">Import database</a>” you can import<br /> your old database from a CSV file. To get help<br /> with the import see our <a href="https://colido.zendesk.com/hc/en-us/articles/202896151" target="_blank">helpdesk</a>.',
        'import:note-recent:label': 'Recent database imports',
        'import:note-recent:text': 'By clicking on an import (in the list below) you will see all objects<br /> imported. In case something went wrong with<br /> the import, you will then also be able to delete<br /> the imported objects.',
        'import:note-empty': 'You don’t have any imports yet.',
        'import:state:mapping': 'Mapping',
        'import:state:reviewing': 'In Review',
        'import:state:queue': 'In queue ...',
        'import:state:importing': 'Importing ...',
        'import:state:processing': 'Processing ...',
        'import:state:waiting': 'Waiting ...',
        'import:state:complete': 'Complete',
        'import:detail:headline': 'Import: {filename}',
        'import:detail:headline-warnings': 'Import-Warnings: {filename}',
        'import:object:count': {
            0: 'includes {n} objects',
            1: 'includes {n} object',
            n: 'includes {n} objects',
        },
        'import:object:count:estimated': 'includes {n} objects (estimated)',
        'import:recent-list:filename': 'Filename',
        'import:recent-list:objects': 'Objects',
        'import:recent-list:warnings': 'Warnings',
        'import:recent-list:imported-on': 'Imported on',
        'import:please-select-field': 'Please select field',
        'import:no-field-selected': 'None',

        // Export
        'export:label': 'Export',
        'export:button-abort': 'Abort',
        'export:button-export-database': 'Export database',
        'export:note-new:label': 'Export database',
        'export:note-new:text': 'By clicking on “export database” you can export<br /> csv-files of your database. To get help with<br /> the export see our <a href="helpdesk/helpdesk">helpdesk</a>.',
        'export:note-recent:label': 'Recent database exports',
        'export:note-recent:text': '... please write some nice words here ...',
        'export:note-empty': 'You don’t have any Recent exports yet.',
        'export:object:count': {
            0: 'includes {n} objects',
            1: 'includes {n} object',
            n: 'includes {n} objects',
        },
        'export:object:count:estimated': 'includes {n} objects (estimated)',
        'export:recent-list:filename': 'Filename',
        'export:recent-list:objects': 'Objects',
        'export:recent-list:warnings': 'Warnings',
        'export:recent-list:imported-on': 'Exported on',
        'export:recent-list:type': 'Type',
        'export:state:queue': 'In queue ...',
        'export:state:exporting': 'Exporting ...',
        'export:state:processing': 'Processing ...',
        'export:state:waiting': 'Waiting ...',
        'export:state:complete': 'Complete',

        // Information
        'information:label': 'Information',
        'information:terms-and-conditions:label': 'Terms and Conditions',
        'information:privacy:label': 'Privacy Statement',
        'information:client-informations:label': 'Client-Informations',
        'information:client-informations:client-version': 'Client-Version',
        'information:client-informations:api-url': 'API-URL',
        'information:client-informations:api-version': 'API-Version',
        'information:client-informations:debug-mode': 'Debug-Mode',

        // Catalogs
        'manage-catalogs:label': 'Manage catalogs',
        'manage-catalogs:button:show-create-catalog-form': 'Add new catalog',
        'manage-catalogs:button:show-create-first-catalog-form': 'Create first catalog',
        'manage-catalogs:button:create-catalog': 'Create',
        'manage-catalogs:button:hide-create-form': 'Cancel',
        'manage-catalogs:placeholder:catalog-name': 'Type catalog name',
        'manage-catalogs:button:show-edit-form': 'Edit',
        'manage-catalogs:button:hide-edit-form': 'Cancel',
        'manage-catalogs:button:save-catalog': 'Save',
        'manage-catalogs:text:empty-catalogs': 'It seems you have not started your collection yet.\nTo get started create your first catalog. \n\nIf you are new to Colido, take the opportunity to fill up your catalog with sample data.',

        'catalogs:type:minerals': 'Minerals',
        'catalogs:type:fossils': 'Fossils',
        'catalogs:type:coins': 'Coins (Beta)',
        'catalogs:type:coins2': 'Coins (Beta) *new',
        'catalogs:type:bills': 'Bank notes (Beta)',
        'catalogs:type:art': 'Art (Beta)',
        'catalogs:type:general': 'Other (Beta)',
        'catalogs:type:generic': 'Other (Beta)',
        'catalogs:subtype:placeholder': 'Custom Type',
        'catalogs:subtype:shoes': 'Shoes',
        'catalogs:subtype:watches': 'Watches',
        'catalogs:subtype:caps': 'Caps',
        'catalogs:subtype:stamps': 'Stamps',
        'catalogs:subtype:books': 'Books',

        'catalogs:add-sample-data:label': 'Add sample data',
        'catalogs:create:messages-success': 'Your catalog {catalogName} was successfully created.',
        'catalogs:create:messages-error': 'Sorry, something went wrong while creating the catalog.',
        'catalogs:update:messages-success': 'Your catalog was renamed to {catalogName}.',
        'catalogs:update:messages-error': 'Sorry, something went wrong while saving the catalog.',
        'catalogs:delete:message-confirm': 'Are you sure you want to delete the catalog "{catalogName}"?',
        'catalogs:delete:messages-success': 'Your catalog {catalogName} was deleted.',
        'catalogs:delete:messages-error': 'Sorry, something went wrong while deleting your catalog.',
    },

    helpdesk: {
        'label': 'Help',

        'headline': 'Help Desk',
        'subline': 'You got questions? We’ll try to give answers.',

        // Helpdesk
        'helpdesk:getting-started:label': 'Getting started',
        'helpdesk:getting-started:text': 'Sit back and watch our five minute introduction to our main features and the interface.',
        'helpdesk:getting-started:button': 'Getting started section',
        'helpdesk:faq:label': 'Frequently asked questions',
        'helpdesk:faq:text': 'If you have questions about the service itself, privacy issues or subscriptions and pricing details, please visit our Help Desk.',
        'helpdesk:faq:button': 'Go to help desk',
        'helpdesk:still-have-questions:label': 'Still have questions?',
        'helpdesk:still-have-questions:text': 'In case you didn’t find what you were looking for please send an email to support@colido.de',
        'helpdesk:still-have-questions:button': 'Send email',

        // Getting Started
        'getting-started:label': 'Getting Started',

        // Contact
        'contact:label': 'Contact',
        'contact:name:label': 'Name',
        'contact:name:placeholder': 'Your name',
        'contact:email:label': 'E-Mail',
        'contact:email:placeholder': 'Your e-mail',
        'contact:text:label': 'Text',
        'contact:text:placeholder': 'Type text here',
        'contact:note:right:label': 'The Helpdesk doesn’t answers your questions?',
        'contact:note:right:text': 'Please feel free to contact us!',
        'contact:button-submit': 'Send request',
    },

    collection: {
        'label': 'Collection', // main menu //@todo rename 'label' to 'menu' for menu labels
        'label:full-catalog': 'Full catalog',
        'label:objects': 'Objects', // sub menu
        'label:scanners': 'Scanner', // sub menu
        'label:sets': 'Sets', // sub menu
        'label:your-shop': 'For Sale',
        'label:storages': 'Storages', // sub menu
        'label:catalogs': 'My catalogs', // sub menu

        // Catalog
        // 'catalog:update:table-settings:success:message': 'Success',
        'catalog:update:table-settings:error:message': 'Sorry, there was an error while saving the table settings.',

        'scanners:objects:list:headline': 'Scanner is active for {catalogName}',
        'scanners:objects:list:empty:catalog': 'Put an object with RFID tag on the scanner and it will appear in this dynamic list.',
        'scanners:objects:detail:status:tag-connected': 'A tag is connected.',
        'scanners:objects:detail:status:no-tag': 'No tag is connected with this object. Please put a tag on the scanner, to connect it.',
        'scanners:objects:detail:status:valid-tag': 'We found a tag on your scanner.',
        'scanners:objects:detail:status:used-tag': 'We found a tag on your scanner, but it is already connected to another object.',
        'scanners:objects:detail:status:too-many-tags': 'We found too many tags. Please make sure that only one tag is in range of the scanner.',
        'scanners:objects:detail:scanner-not-fround': 'Sorry, we could not find any scanners. Please contact us at <a href="mailto:support@colido.de?subject=RFID-Scanner%20not%20found">support@colido.de</a>',

        'scanners:objects:detail:button:remove-tag-from-object': 'Remove tag from object',
        'scanners:objects:detail:button:connect-tag-to-object': 'Connect tag with this object',

        //Shop
        'shop:label': 'Market',
        'shop:objects:list:headline': '{typeName} Market',
        'shop:objects:list:empty': 'This market is empty.<br /><br />If you want to be the first to sell here,<br />click on Actions -> Sell in the item\'s detail view.',
        'shop:object:details:buy:label': 'Contact Seller',
        'shop:object:action:sell': 'Sell',
        'shop:object:action:withdraw-from-sale': 'Stop sale',
        'shop:shipping-address-modal:headline': 'Shipping Address',
        'shop:shipping-address-modal:text': 'Sorry, you have not provided a shipping address yet. Please do so in your profile.',
        'shop:shipping-address-modal:button-profile': 'Go to profile',
        'shop:buy-modal:headline': 'Buy',
        'shop:buy-modal:text': 'By clicking buy you are making a binding offer of {amount} plus shipping for the item {objectName}',
        'shop:buy-modal:buy-button': 'Buy item',
        'shop:buy-modal:do-not-buy-button': 'Cancel',
        'shop:sell-modal:sale:headline': 'Sell item',
        'shop:sell-modal:withdraw:headline': 'Withdraw from sale',
        'shop:sell-modal:sell-button': 'Put up for sale',
        'shop:sell-modal:do-not-sell-button': 'Cancel',
        'shop:sell-modal:sell-hint': 'If the item is put up for sale, it will be visible in Colido’s public markets. Potential customers will be able to contact you via Colido Talk to complete the sale. There is no publication fee. A sales commission will only be applied for items put up for sale after the beta phase.',
        'shop:sell-modal:withdraw-from-sale-button': 'Withdraw from sale',
        'shop:sell-modal:withdraw-from-sale-hint': 'The item will be removed from the markets and all pending offers will be declined.',
        'shop:seller-modal:headline': 'Seller Profile',
        'shop:seller-modal:company': 'Company',
        'shop:seller-modal:contact': 'Contact',
        'shop:seller-modal:address': 'Address',
        'shop:seller-modal:email': 'Email',
        'shop:seller-modal:www': 'Website',
        'shop:seller-modal:phone': 'Telephone',
        'shop:seller-modal:registration': 'Registration number',
        'shop:seller-modal:uid': 'Tax number',
        'shop:seller-modal:agb': 'Terms and conditions',
        'shop:seller-modal:dse': 'Privacy policy',
        'shop:seller-modal:no-data-text': 'There is no data about this seller profile',

        'shop:sell:response:success': 'Your item was successfully put up for sale: {itemLink}',
        'shop:sell:response:error': 'Unfortunately something went wrong. Please try again later. If the error persists please contact us at <a href="mailto:support@colido.de?subject=Shop-Sale%20could%20not%20be%20created">support@colido.de</a>',
        'shop:sell:withdraw:response:success': 'Your item was successfully removed from the market: {itemName}',
        'shop:sell:withdraw:response:error': 'Unfortunately something went wrong. Please try again later. If the error persists please contact us at <a href="mailto:support@colido.de?subject=Shop-Sale%20could%20not%20be%deleted">support@colido.de</a>',
        'shop:create:offer:response:error': 'Unfortunately something went wrong. Please try again later. If the error persists please contact us at <a href="mailto:support@colido.de?subject=Shop-Offer%20could%20not%20be%20created">support@colido.de</a>',
        'shop:create:offer:response:error-buyer-and-seller-same-user': 'You can\'t buy this item from yourself.',

        // Translations for objects
        'objects:list:headline': '{catalogName}: Catalog',
        'objects:list:add-button': 'Add new object',
        'objects:list:empty': 'This list is empty.',
        'objects:list:empty:catalog': 'Your catalog is empty.<br/>Please add your first object:',
        'objects:list:empty:filter': 'No objects match the current filter.',
        'objects:list:empty:search': 'Sorry, your search did not yield any results.',
        'objects:count': {
            0: 'Objects',
            1: 'Object',
            n: 'Objects',
        },
        'objects:view:sort:label': 'Sort by',
        'objects:view:sort:name': 'Name',
        'objects:view:sort:id': 'Object No.',
        'objects:view:sort:locality': 'Locality',
        'objects:view:sort:last-edited': 'Last edited',

        'objects:view:button:actions': 'Actions',
        'objects:view:button:showroom': 'Showroom',
        'objects:view:button:manage': 'Mass edit',
        'objects:view:button:delete': 'Delete',
        'objects:view:button:done': 'Done',
        'objects:view:button:select-all': 'Select all',
        'objects:view:button:print-list': 'Create list (PDF)',
        'objects:view:button:print-label': {
            1: 'Create label (PDF)',
            n: 'Create labels (PDF)',
        },
        'objects:view:button:export': 'Export objects (CSV)',
        'objects:view:button:edit': 'Rename',
        'objects:view:button:share': 'Share',
        'objects:view:manage:hint': 'Please select some objects',
        'objects:view:button:edit:set': 'Edit set infos',
        'objects:view:button:delete:set': 'Delete set',
        'objects:view:button:share:set': 'Share set',
        'objects:view:button:edit:storage': 'Rename storage',
        'objects:view:button:delete:storage': 'Delete storage',
        'objects:view:button:share:storage': 'Share storage',

        'objects:filter:headline': 'Filter: Collection',
        'objects:filter:button': 'Filter',
        'objects:filter:label': 'filter',
        'objects:filter:button:show': 'Show',
        'objects:filter:button:cancel': 'Cancel',
        'objects:filter:button:go-to-filter-definition:title': 'Go to filter definition',
        'objects:filter:button:open-filter:title': 'Go to filter definition',
        'objects:filter:button:remove-filter:title': 'Remove filter',
        'objects:filter:material:title': 'Material / Paragenesis',
        'objects:filter:material:search': 'Search',
        'objects:filter:material:search:placeholder': 'Type material',
        'objects:filter:material:recent': 'Recent',
        'objects:filter:material:common': 'Common',
        'objects:filter:locality:title': 'Locality',
        'objects:filter:locality:common-countries': 'Common countries',
        'objects:filter:locality:recent-countries': 'Recent countries',
        'objects:filter:locality:specific-localities': 'Specific localities',
        'objects:filter:locality:specific-localities:type-specific-locality': 'Type specific locality',
        'objects:filter:finding-date:title': 'Finding Date',
        'objects:filter:finding-date:from:label': 'from',
        'objects:filter:finding-date:to:label': 'to',
        'objects:filter:size:title': 'Object Size',
        'objects:filter:weight:title': 'Object Weight',
        'objects:filter:color:title': 'Object Color',
        'objects:filter:cataloging-date:title': 'Cataloging Date',
        'objects:filter:cataloging-date:from:label': 'from',
        'objects:filter:cataloging-date:to:label': 'to',
        'objects:filter:obtaining-date:title': 'Obtaining Date',
        'objects:filter:obtaining-date:from:label': 'from',
        'objects:filter:obtaining-date:to:label': 'to',
        'objects:filter:purchasing-price:title': 'Purchasing Price',
        'objects:filter:estimated-value:title': 'Estimated Value',
        'objects:filter:insurance-value:title': 'Insurance Value',
        'objects:filter:history:title': 'Object History',
        'objects:filter:previous-owner:title': 'Previous Owner',
        'objects:filter:previous-owner:select:other-prev-owners': 'Other prev. owners',
        'objects:filter:range-slider:from:label': 'from',
        'objects:filter:range-slider:to:label': 'to',

        'objects:filter:material:short-title': 'Material',
        'objects:filter:locality:short-title': 'Locality',
        'objects:filter:finding-date:short-title': 'Found',
        'objects:filter:size:short-title': 'Size',
        'objects:filter:weight:short-title': 'Weight',
        'objects:filter:color:short-title': 'Color',
        'objects:filter:cataloging-date:short-title': 'Catalogued',
        'objects:filter:obtaining-date:short-title': 'Obtained',
        'objects:filter:purchasing-price:short-title': 'Price',
        'objects:filter:estimated-value:short-title': 'Estimated',
        'objects:filter:insurance-value:short-title': 'Insured',
        'objects:filter:history:short-title': 'History',
        'objects:filter:previous-owner:short-title': 'Previous Owner',

        'object:emptyName': 'No name',
        'object:emptyLocality': 'No location',
        'object:emptyDescription': 'No description',
        'object:dimension-type:micromount': 'Micromount',
        'object:dimension-type:thumbnail': 'Thumbnail',
        'object:dimension-type:miniature': 'Miniature',
        'object:dimension-type:smallCabinet': 'Small Cabinet',
        'object:dimension-type:cabinetSpecimen': 'Cabinet Specimen',
        'object:dimension-type:museumSpecimen': 'Museum Specimen',

        'object:finding-date:none:short-title': 'unknown',
        'object:finding-date:none:title': 'unknown finding date',

        'object:action:headline': 'Actions',
        'object:action:edit': 'Edit',
        'object:action:show-storage': 'Show storage',
        'object:action:add-to-set': 'Add to set',
        'object:action:choose-storage': 'Update storage',
        'object:action:change-status': 'Change status',
        'object:action:duplicate': 'Duplicate',
        'object:action:delete': 'Delete',
        'object:action:confirmDelete': {
            1: 'Are you sure you want to delete the selected item?',
            n: 'Are you sure you want to delete {n} selected items?',
        },
        'object:response:deleted': {
            0: 'No items have been deleted',
            1: 'Your item {name} has been deleted from your collection.<br>If the object was deleted accidently, you can restore it in the Trash section.',
            n: '{n} items have been deleted from your collection.<br>If the objects were deleted accidently, you can restore them in the Trash section.',
        },
        'object:response:deleted:error': {
            0: 'No items have been deleted',
            1: 'Your item {name} couldn’t been deleted from your collection.',
            n: '{n} items couldn’t been deleted from your collection.',
        },

        'object:details:actions:label': 'Actions',

        //@todo make these headline catalog driven
        'object:details:section:headline:history': 'Object history',
        'object:details:section:headline:status': 'Object status',
        'object:details:section:headline:sets': 'Sets',

        'object:details:assets:label': 'Files',
        'object:details:assets:dismiss-all': 'Dismiss all',

        'object:details:history:type:undefined:label': 'None',
        'object:details:history:type:none:label': 'None',
        'object:details:history:type:purchased:label': 'purchased',
        'object:details:history:type:traded-off:label': 'exchanged',
        'object:details:history:type:found:label': 'found',
        'object:details:history:type:given:label': 'given',
        'object:details:history:type:heritage:label': 'heritage',
        'object:details:history:purchased:from:label': 'Purchased from',
        'object:details:history:purchased:price:label': 'Purchased for',
        'object:details:history:purchased:date:label': 'Purchased on',
        'object:details:history:traded-off:from:label': 'Exchanged with',
        'object:details:history:traded-off:date:label': 'Exchanged on',
        'object:details:history:found:from:label': 'Found by',
        'object:details:history:found:date:label': 'Found on',
        'object:details:history:given:from:label': 'Given by',
        'object:details:history:given:date:label': 'Given on',
        'object:details:history:heritage:from:label': 'Inherited from',
        'object:details:history:heritage:date:label': 'Received on',

        'object:details:history:acquired:label': 'Object was', // Object was: exchanged etc

        'object:details:estimated-value:label': 'Estimated value',
        'object:details:estimated-value-currency:label': 'Estimated value currency',
        'object:details:insurance-value:label': 'Insurance value',
        'object:details:insurance-value-currency:label': 'Insurance value currency',
        'object:details:retail-price:label': 'Retail price',
        'object:details:retail-price-currency:label': 'Retail price currency',
        'object:details:size:label': 'Size',
        'object:details:weight:label': 'Weight',
        'object:details:weight-unit:label': 'Weight unit',
        'object:details:max-crystal-size:label': 'Max. crystal size',
        'object:details:notes:label': 'Notes',
        'object:details:damages:label': 'Damages',
        'object:details:color:label': 'Color',
        'object:details:custom-color:label': 'Custom color',
        'object:details:color-specification:label': 'Add more precise color information',

        'object:details:species-correlation-comma:label': 'and',
        'object:details:species-correlation-on:label': 'on',
        'object:details:species-correlation-with:label': 'with',
        'object:details:species-correlation-var:label': 'var',
        'object:details:species-correlation-after:label': 'after',
        'object:details:species-correlation-pseudomorph:label': 'Pseudomorph',
        'object:details:species-correlation-partial-pseudomorph:label': 'Partial pseudomorph',
        'object:details:species-correlation-epimorph:label': 'Epimorph',
        'object:details:species-correlation-after_pseudo:label': 'pseudomorph',
        'object:details:species-correlation-after_partial:label': 'partial pseudomorph',
        'object:details:species-correlation-after_epimorph:label': 'epimorph',

        'object:details:object-status:in-collection:label': 'In collection',
        'object:details:object-status:lent:label': 'Lent',
        'object:details:object-status:lent-to:label': 'Lent to',
        'object:details:object-status:missing:label': 'Missing',
        'object:details:object-status:deaccessioned:label': 'Deaccessioned',
        'object:details:object-status:deaccessioned-to:label': 'Deaccessioned to',
        'object:details:object-status:relation:to:label': 'To',
        'object:details:object-status:relation:to:placeholder': 'Type recipient',

        'object:details:color:black': 'Black',
        'object:details:color:indigo': 'Indigo',
        'object:details:color:orange': 'Orange',
        'object:details:color:gold': 'Gold',
        'object:details:color:grey': 'Grey',
        'object:details:color:purple': 'Purple',
        'object:details:color:yellow': 'Yellow',
        'object:details:color:copper': 'Copper',
        'object:details:color:white': 'White',
        'object:details:color:magenta': 'Magenta',
        'object:details:color:green': 'Green',
        'object:details:color:silver': 'Silver',
        'object:details:color:blue': 'Blue',
        'object:details:color:pink': 'Pink',
        'object:details:color:olive': 'Olive',
        'object:details:color:colorless': 'Colorless',
        'object:details:color:cyan': 'Cyan',
        'object:details:color:red': 'Red',
        'object:details:color:brown': 'Brown',
        'object:details:color:undefined': 'undefined',

        'object:details:storage:undefined': 'No storage location set',
        //'object:details:storage:none': 'Misc',
        //'object:details:storage:in-transit': 'In transit',
        //'object:details:storage:unknown': 'Unknown',
        'object:details:sets:undefined': 'Not part of a set yet',

        // Inputfields
        'inputfields:object-id:name': 'Object ID',
        'inputfields:mindat-id:name': 'minID by mindat.org',
        'inputfields:damage-repairs-treatments:name': 'Damage, repairs, treatments',
        'inputfields:description:name': 'Description',
        'inputfields:dimensions:name': 'Dimensions',
        'inputfields:dimensions-sizeUnit:name': 'Dimensions unit',
        'inputfields:dimensions-sizeHeight:name': 'Height',
        'inputfields:dimensions-sizeWidth:name': 'Width',
        'inputfields:dimensions-sizeDepth:name': 'Depth',
        'inputfields:main-colors:name': 'Main colors',
        'inputfields:note:name': 'Notes',
        'inputfields:object-history:name': 'Object history',
        'inputfields:obtained:name': 'Obtained',
        'inputfields:obtained-date:name': 'Obtained date',
        'inputfields:obtained-from:name': 'Obtained from',
        'inputfields:object-image:name': 'Object image',
        'inputfields:object-status:name': 'Object status',
        'inputfields:precise-color-information:name': 'Precise color information',
        'inputfields:previous-owners-and-collections:name': 'Owners and collections',
        'inputfields:set-membership:name': 'Set membership',
        'inputfields:species:name': 'Species',
        'inputfields:specimen-title:name': 'Specimen title',
        'inputfields:species-names:name': 'Paragenesis', // for the listprint
        'inputfields:storage-information:name': 'Storage information',
        'inputfields:values-and-prices:name': 'Values and prices',
        'inputfields:weight:name': 'Weight',
        'inputfields:object-weight:name': 'Weight', // for the listprint
        'inputfields:dimensions-weight:name': 'Weight', // for import
        'inputfields:dimensions-weight-unit:name': 'Weight unit', // for import
        'inputfields:purchasing-price:name': 'Purchasing price',
        'inputfields:estimated-value:name': 'Estimated value',
        'inputfields:insurance-value:name': 'Insurance value',
        'inputfields:retail-price:name': 'Retail price',
        'inputfields:estimated-value-currency:name': 'Estimated value currency',
        'inputfields:insurance-value-currency:name': 'Insurance value currency',
        'inputfields:retail-price-currency:name': 'Retail price currency',
        'inputfields:dimension-type:name': 'Size',
        'inputfields:modified-time:name': 'Last edited',
        'inputfields:max-crystal-size:name': 'Max. crystal size',
        'inputfields:max-crystal-size-unit:name': 'Max. crystal size unit',
        'inputfields:object-history-from:name': 'Obtained from',
        'inputfields:object-history-date:name': 'Date obtained',
        'inputfields:signature:name': 'Signature',

        // Change status
        'change-status:headline': 'Change status',
        'change-status:response': {
            0: 'The status {status} was set for {n} items',
            1: 'The status {status} was set for your item {objectLink}',
            n: 'The status {status} was set for {n} items',
        },
        'change-status:placeholder-recipient': 'Type recipient (optional)',
        'change-status:button-change-status': 'Change status',

        //Search
        'search:headline:terms': 'Search for {terms}',
        'search:headline:result-detailpage': 'Search results for "{keyword}"',
        'search:placeholder': 'Search',
        'search:what-are-you-looking-for': 'What are you looking for?',

        // Set lists
        'sets:overview': 'Overview',
        'sets:headline': '{catalogName}: Sets',
        'sets:count': {
            0: 'No sets',
            1: '{n} set',
            n: '{n} sets',
        },

        // Set details
        'set:defaultName': 'Set',
        'set:headline': 'Set: {name}',
        'set:label:set-price': 'Set-price',
        'set:label:set-sum-total': 'Sum total',
        'set:description:show-more-text:label': 'See more text',
        'set:edit:name:placeholder': 'Type set name',
        'set:edit:number:placeholder': 'Type set number',
        'set:edit:price:placeholder': 'Type set price',
        'set:edit:sum-field:label': 'Sum field',
        'set:edit:sum-field:no-field-selected': 'None',
        'set:edit:set-currency:label': 'Currency',
        'set:edit:set-price:label': 'Set-price',
        'set:edit:description:placeholder': 'Description',
        'set:edit:add-new-set:label': 'Add new set',
        'set:edit:cancel': 'Cancel',
        'set:edit:create': 'Create',
        'set:edit:done': 'Done',
        'set:edit:delete': 'Delete',
        'set:edit:share': 'Share',
        'set:edit:label': 'Edit',
        'set:response:add:success': 'Set {name} has been created.',
        'set:response:add:error': 'Set {name} could not been created.',
        'set:response:add-to-set': {
            0: 'No items have been added to set {setLink}',
            1: 'Item {objectLink} has been added to set {setLink}',
            n: '{n} items have been added to set {setLink}',
        },
        'set:response:add-to-set:failed': {
            0: 'Could not add your item to set {setLink}',
            1: 'Could not add your item {objectLink} to set {setLink}',
            n: 'Could not add your items to set {setLink}',
        },
        'set:response:remove-from-set': {
            0: 'No items have been removed from set {setLink}',
            1: 'Item {objectLink} has been removed from set {setLink}',
            n: '{n} items have been removed from set {setLink}',
        },
        'set:response:remove-from-set:failed': {
            0: 'Could not remove your item from set {setLink}',
            1: 'Could not remove your item {objectLink} from set {setLink}',
            n: 'Could not remove your items from set {setLink}',
        },
        'set:question:remove-from-set': {
            0: 'Do you really want to remove {n} objects from your Set {setName}?',
            1: 'Do you really want to remove {n} object from your Set {setName}?',
            n: 'Do you really want to remove {n} objects from your Set {setName}?',
        },
        'set:response:deleted': 'Set {name} has been removed.<br />If the set was deleted accidently, you can restore it in the trash section.',
        'set:response:add:show': 'Show set',
        'set:add-new-set': 'Add new set',
        'set:object-count': {
            0: 'No objects',
            1: '{n} object',
            n: '{n} objects',
        },
        'set:addToSet:headline': 'Add to set',
        'set:addToSet:selected-objects': {
            0: 'No objects selected',
            1: '{n} object selected',
            n: '{n} objects selected',
        },
        'set:confirm-delete-set': 'Are you sure you want to delete the set "{name}"?',
        'set:empty:introduction': 'This set is empty. Go to your catalog to add some objects.<br />There are two simple ways to do so:',
        'set:empty:drag-and-drop:header': 'Drag and Drop',
        'set:empty:drag-and-drop:body': 'In grid view simply start dragging an object and throw it into the desired set on the appearing set list.',
        'set:empty:manage-mode:header': 'Mass edit mode',
        'set:empty:manage-mode:body': 'Click on “Mass edit” on the overview page. Select multiple objects and add them to a desired set.',
        'set:headline:share': 'Share: {name}',
        'set:share:button:sharing-options': 'Sharing options',
        'set:share:label:share-by': 'Share by',
        'set:share:label:share-label': 'Label PDF',
        'set:share:label:share-label-button': 'create share label',
        'set:share:empty-share:headline': 'We are sorry',
        'set:share:empty-share:text': 'The set you are looking for is no longer available',

        // set share modal
        'set:share:status': 'Share status',
        'set:share:status:active': 'Shared',
        'set:share:status:disabled': 'Private',
        'set:share:copy-link': 'Copy link',
        'set:share:copy-link:success': 'Copied to clipboard!',
        'set:share:copy-link:error': 'Link could not be copied to clipboard. It seems, that your browser doesn’t support copy to clipboard. Please try to copy the link manually',
        'set:share:info': 'By sharing the link displayed above with friends and colleagues, they will be able to access your shared set.', // + You have perfect control over which informations will be revealed in the sharing options.
        'set:share:extern-link': 'Go to the shared set',

        // Location stuff
        'locations:headline': '{catalogName}: Storages',
        'locations:storages:overview': 'Overview',
        'locations:storages:headline': 'Storages',
        'locations:edit:label': 'Edit',
        'locations:edit:done': 'Done',
        'locations:edit:cancel': 'Cancel',
        'locations:edit:delete': 'Delete',
        'locations:headline-storage-location': 'Storage location',
        'locations:label-in-storage': 'In storage',
        //'locations:LabelInTransit': 'In transit',
        //'locations:LabelLent': 'Lent',
        //'locations:LabelSold': 'Sold',
        'locations:label-undefined': 'Undefined',
        'locations:headline-storages': 'Storages',
        'locations:headline-other-locations': 'Other locations',
        'locations:headline-undefined': 'Items with undefined storage',
        'locations:label:location': 'Storage',
        'locations:label:storage': 'Storage',
        'location:object-count': {
            0: 'No Objects',
            1: '{n} Object',
            n: '{n} Objects',
        },
        'locations:headline:storage': 'Storage:',
        'locations:headline:location': 'Location:',
        'locations:response:movedItem': { // Should be obsolete
            0: '{n} items were moved to your location {storageLink}',
            1: '{n} item was moved to your location {storageLink}',
            n: '{n} items were moved to your location {storageLink}',
        },
        'locations:response:change-location': {
            0: 'No items were moved to your {storageType} {storageLink}',
            1: '{objectLink} was moved to your {storageType} {storageLink}',
            n: '{n} objects were moved to your {storageType} {storageLink}',
        },
        'locations:response:change-location:error': {
            0: 'No items were moved to your {storageType} {storageLink}',
            1: '{objectLink} could not be moved to your {storageType} {storageLink}',
            n: '{n} objects could not be moved to your {storageType} {storageLink}',
        },
        'locations:response:removedItem': {
            0: '{n} items were removed from your location {storageLink}',
            1: '{n} item was removed from your location {storageLink}',
            n: '{n} items were removed from your location {storageLink}',
        },
        'locations:response:deleted': 'Your {storageType} {name} was removed.<br>If the {storageType} was deleted accidently, you can restore it in the Trash section.',
        'locations:response:deleted:unknown-error': 'Your {storageType} {name} couldn’t be removed. An Unknown error has occurred.',

        // Location selection/update
        'locations:update-location:headline': 'Update storage',
        'locations:update-location:selected-objects': {
            0: 'No objects selected',
            1: '{n} object selected',
            n: '{n} objects selected',
        },
        'locations:response:create-storage-success': '{name} was added to your locations.',
        'locations:response:create-storage-show': 'Show storage',
        'locations:confirm-delete-location': 'Are you sure you want to delete the storage "{name}"?',
        'locations:successfully-deleted-location': 'Storage {name} was successfully deleted',

        // Location Modal: Manage Storages
        'locations:manage-storages:title': 'Manage storages',
        'locations:manage-storages:object-count': {
            0: 'No Storage',
            1: '{n} Storage',
            n: '{n} Storages',
        },
        'locations:manage-storages:cancel': 'Cancel',
        'locations:manage-storages:create': 'Create',
        'locations:manage-storages:save': 'Save',
        'locations:manage-storages:manageMode': 'Managing mode ',
        'locations:manage-storages:trash': 'Trash',
        'locations:manage-storages:arrange': 'Arrange',
        'locations:manage-storages:name': 'Name',
        'locations:manage-storages:simple-error': 'Something went wrong',
        'locations:manage-storages:add-new-storage': 'Add new storage',
        'locations:manage-storages:info-text': 'Drag and drop storages to change their order (“arrange mode”) or move them to the trash to delete them. Choose “name mode” and click or tab on storages’ names to rename.',
        'locations:empty:introduction': 'This storage is empty.<br/>There are two simple ways to add objects to a storage:',
        'locations:empty:drag-and-drop:header': 'Drag and Drop',
        'locations:empty:drag-and-drop:body': 'Go to <a href="{storagesUrl}">Storages</a>.<br/>Drag an object onto the desired storage along the top.',
        'locations:empty:manage-mode:header': 'Manage Mode',
        'locations:empty:manage-mode:body': 'Go to <a href="{catalogUrl}">Catalog</a> and click “Manage Objects”.<br/>Select one or more objects, then click “Actions” > “Update storage”.',

        // Create stuff
        'create:headline': 'Create object',
        'create:button:create': 'Create',
        'create:button:cancel': 'Cancel',
        'create:button:create-and-done': 'Create and done',
        'create:button:create-and-duplicate': 'Create and duplicate',
        'create:button:hide-hidden-fields': 'Hide hidden fields',
        'create:button:show-hidden-fields': 'Show hidden fields',
        'create:response:success:create-and-duplicate': 'Your item {itemLink} was created successfully. You are now seeing the duplicate.',
        'create:response:success:create': 'Your item {itemLink} was created successfully.',
        'create:response:error:create': 'We could not save your item. Please try again.',

        // Edit stuff
        'edit:headline': 'Edit object',
        'edit:button:save': 'Save',
        'edit:button:cancel': 'Cancel',
        'edit:button:show-hidden-fields': 'show hidden fields',
        'edit:button:hide-hidden-fields': 'hide hidden fields',

        'edit:hint:required': '(required)',
        'edit:hint:optional': '(optional)',

        'edit:base:message:wait-upload': 'Please wait, till all images have been uploaded.',
        'edit:base:message:mandatory-fields': 'Please enter at least an {field1} to create your object.',

        'edit:base:field:object-number': 'Object Number',
        'edit:base:field:paragenesis': 'Paragenesis',

        'edit:object-id-selection:label-object-id': 'Object Number',
        'edit:object-id-selection:placeholder-object-id': 'Type object number',
        'edit:object-id-selection:last-inserted-was': 'Last No: ',

        'edit:image-selection:button-import-photobox': 'Import from photobox',
        'edit:image-selection:button-enable-dropbox': 'Connect to Dropbox',
        'edit:image-selection:button-loading-dropbox': 'Connecting to Dropbox...',
        'edit:image-selection:button-import-from-dropbox': 'Import from Dropbox',
        'edit:image-selection:button-choose-from-disk': 'Upload images',
        'edit:image-selection:button-take-picture': 'Take picture',
        'edit:image-size:headline': 'Image resolution',
        'edit:image-size:size:label:full': 'full',
        'edit:image-size:size:label:large': 'large',
        'edit:image-size:size:label:medium': 'medium',
        'edit:image-size:size:label:small': 'small',
        'edit:image-size:hint': 'Reduce image resolutions to save up collection space:\nLarge (~800kb/img), Medium (~200kb/img), Small (~50kb/img)\nThe actual sizes may vary. This process is irreversible.',
        'edit:asset-selection:button-choose-from-disk': 'Upload files',

        'edit:rfid-connection:headline-rfid-connection': 'RFID Connection',
        'edit:rfid-connection:button-scan-object': 'Scan object',
        'edit:rfid-connection:label-scanner': 'Scanner',
        'edit:rfid-connection:state-no-object-found': 'No object found',
        'edit:rfid-connection:state-object-connected': 'RFID object connected',
        'edit:rfid-connection:button-rescan': 'Re-Scan',
        'edit:rfid-connection:button-disconnect': 'Disconnect',

        'edit:species-selection:HeadlineSpecies': 'Species / Paragenesis',
        'edit:placeholder-species': 'Type first species',
        'edit:placeholder-add-species': 'Add another species, variety or pseudomorph',

        'edit:species-selection:headline-proper-name': 'Specimen title',
        'edit:placeholder-proper-name': 'Type specimen title',

        'edit:locality-selection:headline-locality': 'Locality',

        // 'edit:locality-selection:locality-mine:hint': 'Add mine or area – or full, comma-separated information',
        'edit:locality-selection:locality-mine:hint': 'Add comma-separated locality going from small to big (eg: Sweet Home Mine, Mount Bross, Alma District, Colorado, United States)',

        'edit:dimension-selection:input-width': 'Width',
        'edit:dimension-selection:input-height': 'Height',
        'edit:dimension-selection:input-depth': 'Depth',
        'edit:placeholder-weight': 'Type weight',
        'edit:placeholder-weight-in-lbs': 'Type weight in lbs',
        'edit:placeholder-weight-in-oz': 'and oz',
        'edit:label-weight': 'Weight',
        'edit:label-weight-in-lbs': 'Weight in lbs',
        'edit:label-weight-in-oz': 'oz',
        'edit:placeholder-max-crystal-size': 'Type maximum crystal size',
        'edit:label-max-crystal-size': 'Max crystal size',

        'edit:description:headline-description': 'Description',
        'edit:description:placeholde-type-object-description': 'Type object description',

        'edit:storage:headline': 'Location and storage information',
        'edit:storage:button-choose-storage': 'Choose storage',
        'edit:storage:button-remove-storage': 'Remove selection',

        'edit:set:headline': 'Set memberships',
        'edit:set:button-choose-set': 'Add to set',
        'edit:set:button-remove-set': 'Remove selection',

        'edit:prices-values:headline': 'Values and prices',
        'edit:prices-values:input-estimated-value': 'Type estimated value',
        'edit:prices-values:input-insurance-value': 'Type insurance value',
        'edit:prices-values:input-retail-price': 'Type retail price',
        'edit:prices-values:label-estimated-value': 'Estimated value',
        'edit:prices-values:label-insurance-value': 'Insurance value',
        'edit:prices-values:label-retail-price': 'Retail price',

        'edit:notes:headline': 'Notes',
        'edit:notes:placeholder': 'Type notes',

        'edit:damage-repairs-treatments:headline': 'Damage, repairs, treatments',
        'edit:damage-repairs-treatments:placeholder': 'Type damage, repairs and treatments',

        'edit:object-history:date:label': 'On',
        'edit:object-history:date:year:placeholder': 'Year',
        'edit:object-history:date:month:placeholder': 'Month',
        'edit:object-history:date:day:placeholder': 'Day',
        'edit:object-history:price:label': 'For',
        'edit:object-history:price:placeholder': 'Type purchasing price',
        'edit:object-history:from:label': 'From',
        'edit:object-history:from:placeholder': 'Type source',

        'edit:object-history:previous-owners:label': 'Owners and collections',
        'edit:object-history:previous-owners:placeholder': 'Add more owners and collections',
        'edit:general:x-hidden-fields': {
            0: '0 input fields are not being displayed at the moment:',
            1: '1 input field is not being displayed at the moment:',
            n: '{n} input field are not being displayed at the moment:',
        },
        'edit:drop-image-for-upload': 'Drop your images to directly upload them to your object',
        'edit:message:unsaved-changes': 'You have unsaved changes. Do you really want to leave the page?',

        'edit:response:success:update': 'Your item {itemLink} was changed successfully.',
        'edit:response:error:update': 'We could not save your item. Please try again.',

        // Photobox Album
        'album:response:deleted': 'Your album {name} has been deleted.',
        'album:modal:cancel': 'Cancel',
        'album:modal:done': 'Done',
        'album:modal:new-albums': 'New albums',
        'album:modal:unused-images': 'Unused images',
        'album:modal:photobox-image-library': 'Photobox image library',
        'album:modal:choose-images-and-click-done': 'Choose images and click done',
        'album:modal:click-on-album-to-use-images': 'Click on album to use images',
        'album:modal:rfid-connected': 'RFID connected',
        'album:modal:no-rfid-connected': 'no RFID connected',
        'album:modal:album:modal:images': {
            0: 'No images',
            1: '{n} image',
            n: '{n} images',
        },
        'album:modal:album:modal:selected': {
            0: 'Selected: 0',
            1: 'Selected: {n}',
            n: 'Selected: {n}',
        },

        // Import
        'import:headline': 'Import database',
        'import:button:cancel': 'Cancel',
        'import:button:next': 'Next',
        'import:button:start-over': 'Start over',
        'import:button:save-and-done': 'Save and done',
        'import:button:start-import': 'Next',
        'import:button:re-match': 'Re-match',
        'import:button:dismiss': 'Dismiss',
        'import:button:include': 'Include',
        'import:subline-step': 'Step {step} of {stepTotal}',
        'import:button-done': 'Done',
        'import:button-next': 'Next',
        'import:match-invalid': 'Please match or dismiss highlighted columns',
        'import:response:import-started': 'Your import has been added to the queue. You will be informed, when the import was completed.',
        'import:response:import-finished': 'Your import of {name} is now completed.',
        'import:button:show-import': 'Show import',
        'import:upload:text:introduction': 'By importing your already existing database you will be ready to use Colido in no time. However this import feature is still in beta testing. If you experience any problems, please contact us at',
        'import:upload:headline:import-file-type': 'Upload CSV file',
        'import:upload:text:limitation': 'Please note: The import is currently limited to 5000 objects per import',
        'import:match:headline:match-columns': 'Match columns',
        'import:match:text:match-introduction': 'By matching the columns of the imported database file with the Colido information input fields, you can make sure that your collection data will be transferred correctly. We already tried to match some fields for you.',
        'import:match:headline:default-units': 'Set default units and currencies',
        'import:match:text:default-units-instruction': 'At first please select the units and currencies you are using. Specific unit and currency informations from your file will also be imported.',
        'import:match:headline:missing-fields': 'Missing fields',
        'import:match:text:missing-fields': 'If you cannot find a proper matching field for an information set of yours just add it to the notes or description text.',
        'import:match:headline:please-check': 'Please check',
        'import:match:text:please-check': 'Is all the data you want to keep marked blue? Are all columns matched properly and are all units and currencies correct?',
        'import:match:text:columns-matched': 'columns matched',
        'import:match:text:columns-dismissed': 'columns dismissed',
        'import:review:text:look-at-object-table': 'Please have a close look at the object table below. If you feel everything worked out fine just tap on',
        'import:review:text:fix': 'If something went wrong try to fix it in the',
        'import:review:link:done': 'done',
        'import:review:link:previous-step': 'previous step',
        'import:text:duplciate-key': 'In case we find an object with the same »Object number«:',
        'import:label:duplciate-key-discard': 'Do nothing',
        'import:label:duplciate-key-update': 'Update existing objects',
        'import:label:duplciate-key-create': 'Import as duplicate',

        // Column-filter
        'column-filter:button': 'Toggle columns',
        'column-filter:done': 'Done',
        'column-filter:title': 'Toggle columns',
        'column-filter:shown': 'shown',
        'column-filter:hidden': 'hidden',

        'view-switch-detail:label': 'View',
        'view-switch-detail:list-view': 'List view',
        'view-switch-detail:grid-view': 'Grid view',
        'manage-storages:label': 'Manage storages',

        'list-control:sort-by:label': 'Sort by',
        'list-control:sort-by:last-edited': 'Last edited',
        'list-control:sort-by:name': 'Name',
        'list-control:sort-by:locality': 'Locality',
        'list-control:sort-by:object-id': 'Object ID',
        'list-control:sort-by:object-count': 'Object count',

        'list-control:manage-objects:label': 'Manage objects',
        'list-control:manage-objects:select-all': 'Select all',
        'list-control:manage-objects:deselect-all': 'Deselect all',
        'list-control:manage-objects:actions': 'Actions',
        'list-control:manage-objects:delete': 'Delete',
        'list-control:manage-objects:please-select-some-objects': 'Please select some objects',
        'list-control:manage-objects:add-to-set': 'Add to set',
        'list-control:manage-objects:remove-from-set': 'Remove from set',
        'list-control:manage-objects:update-location': 'Update storage',
        'list-control:manage-objects:change-status': 'Change status',
        'list-control:manage-objects:done': 'Done',
    },

    share: {
        'share-set:response:error': 'Unfortunately, we could not share you set. Please try again later.',
        'confirm:disable:set:share': 'Do you want to disable access to this shared set? All links pointing to the shared site will be invalid afterwards. Reactivating sharing of this set will generate a new/different link.',
        'headline:set': '{title}',
        'headline:location': 'Shared location: {title}',
        'headline:object': 'Shared object: {title}',
        'subheadline:attribution': 'shared by {name}',
        'by-mail:label': 'Email',
        'by-mail:subject': 'Check out my specimen set »{name}«',
        'by-mail:body': 'Hey,\nI would like to show you my specimen set »{name}«.\n\nPlease go to: \n{url}\n\nPresented by www.Colido.de',
        'by-twitter:label': 'Twitter',
        'by-twitter:body': 'Visit my #specimen set »{title}«', //Check if restuling message is still under 140 chars if you change this title is limited to 80 chars
        'by-facebook:label': 'Facebook',
        'by-colido-talk:label': 'Colido Talk',
    },

    restrictions: {
        'modal:title:filter': 'Collection filter<br />is available in our<br />premium plans',
        'modal:title:sets': 'Additional sets<br />are available in our<br />premium plans',
        'modal:title:images': 'Additional images<br />are available in our<br />premium plans',
        'modal:title:storages': 'Storage manager<br />is available in our<br />premium plans',
        'modal:title:quota': 'Additional space<br />is available in our<br />premium plans',
        'modal:title:print': 'Advanced printing options<br />are available in our<br />premium plans',
        'modal:title:advanced-labelprint': 'Label printing <br />is available in our<br />premium plans',
        'modal:btn:upgrade': 'Upgrade account',
        'modal:btn:plans-and-pricing': 'See plans and pricing',
        'sets:btn:delete': 'Delete set',
        'quota:exceeded': 'Your have exceeded your quota of {quota} by {exceededBy}.',
        'quota:space-left': 'You have {spaceLeft} of your space left.',
    },

    subscription: {
        'modal:edit': 'Edit',
        'modal:cancel': 'Cancel',
        'modal:save': 'Save',

        'cancel': 'Cancel',

        'quota:label:usage-indicator': '{amount} used',
        'quota:text:exceeded-hint': 'You have reached your collection space limit.\nPlease upgrade or delete some objects or\nimages to be able to add new objects.',

        'plan:text:currently-active': 'You are currently using our {planName} plan.',
        'plan:text:renewal-monthly': 'Your subsciption will be automatically renewed on {validUntil}<br />for a monthly fee of {amount}.',
        'plan:text:renewal-annually': 'Your subsciption will be automatically renewed on {validUntil}<br /> for a yearly fee of <b>{amount}</b>.',
        'plan:text:upcoming-plan': 'This plan will be active until {validUntil}.<br />Afterwards the {upcomingPlanName} plan will be activated.',
        'plan:text:active': 'Active',

        'plan:text:switch:from:basic:to:plus-monthly': 'You are about to upgrade to the Colido Plus plan. You will be charged {chosenPlanAmountMonthly} up-front on a monthly basis.',
        'plan:text:switch:from:basic:to:plus-annually': 'You are about to upgrade to the Colido Plus plan. You will be charged {chosenPlanAmountAnnually} up-front on a yearly basis.',
        'plan:text:switch:from:plus-monthly:to:basic': 'You are about to downgrade to the Colido Basic plan. You can still use the premium features until {activePlanValidUntil}',
        'plan:text:switch:from:plus-monthly:to:plus-annually': 'You are about to switch to a yearly billing cycle. We will directly charge the annual fee of {chosenPlanAmountAnnually} minus your remaining balance of {accountBalance} from the current month.',
        'plan:text:switch:from:plus-annually:to:basic': 'You are about to downgrade to the Colido Basic plan. You can still use the premium faetures until {activePlanValidUntil}',
        'plan:text:switch:from:plus-annually:to:plus-monthly': 'You are about to switch to a monthly billing cycle. Since you have already paid for the annual plan in advance, we will start charging you monthly on {activePlanValidUntil}.',

        'payment:headline:payment-method': 'Payment information',
        'payment:headline:pay-with-creditcard': 'Pay with Credit card',
        'payment:headline:checkout-with-paypal': 'Checkout with PayPal',
        'payment:headline:or': 'or',
        'payment:button:proceed-to-billing-address': 'Proceed to billing address',
        'payment:button:save': 'Save',
        'payment:button:change-payment-method': 'Change payment method',
        'payment:label:creditcard': 'Credit card',
        'payment:label:paypal': 'PayPal',
        'payment:placeholder:cardholder': 'Type name of cardholder',
        'payment:label:cardholder': 'Name on card',
        'payment:placeholder:card-number': 'Type card number',
        'payment:label:card-number': 'Card No',
        'payment:placeholder:expiration-date': 'Type expiration date (mm/yy)',
        'payment:label:expiration-date': 'MM/YY',
        'payment:placeholder:cvc': 'Type CVC',
        'payment:label:cvc': 'CVC',
        'payment:text:edit:hint': 'You can either pay with credit card or checkout with Paypal.\nYour subscription will be billed {billingInterval}. \nYou may cancel your subscription any time.',
        'payment:modal:title': 'Payment method',
        'payment:creditcard:error': 'TODO',
        'payment:creditcard:error:empty': 'TODO',
        'payment:creditcard:error:card-holder': 'TODO',
        'payment:creditcard:error:card-number': 'TODO',
        'payment:creditcard:error:expire': 'TODO',
        'payment:creditcard:error:format': 'TODO',
        'payment:creditcard:error:CVV-CVC-CVS': 'TODO',

        'payment:text:charged-by-creditcard': 'Your subscription will be charged via your {issuer} credit card (ending in {number}).',
        'payment:text:charged-by-paypal': 'Your subscription will be charged via your PayPal account {account}',

        'payment:label:self-declare-country-of-residency': 'I self-declare that my country of residence is {residencyCountry}',
        'payment:text:country-of-residency': 'Your country of residency is {residencyCountry}',

        'subscription:button:update-payment-method': 'Update payment method',
        'subscription:button:change-plan': 'Change',
        'subscription:button:cancel-subscription': 'Cancel subscription',

        'setup:title': 'Upgrade Colido',
        'setup:plans': 'Plans',
        'setup:subline': 'Get extra space and functionality',
        'setup:info-text': 'The Plus plan helps you manage your Collection even better.<br /> If you choose to pay annually, you will get one month for free.',
        'setup:choose-billing:label': 'Choose your billing cycle',
        'setup:choose-billing:monthly:label': 'Monthly',
        'setup:choose-billing:annually:label': 'Annually',
        'setup:choose-billing:annually:hint': 'get one more month free',
        'setup:choose-a-plan': 'Choose a plan',
        'setup:choose-a-plan:basic:hint': 'Free',
        'setup:choose-a-plan:basic:sub-hint': 'forever',
        'setup:choose-a-plan:per-month:hint': '/month*',
        'setup:choose-a-plan:per-year:hint': '/year*',
        'setup:choose-a-plan:choose-plan:button': 'Choose plan',
        'setup:choose-a-plan:choose-plan:selected': 'Selected',
        'setup:choose-a-plan:choose-plan:all-prices-with-vat': ' * all prices including VAT',
        'setup:choose-a-plan:plan:close': 'Close',
        'setup:choose-a-plan:plan:select-paid': 'Select a premium plan please',
        'setup:choose-a-plan:plan:tax-info': '*all prices including VAT',
        'setup:proceed-to-payment:button': 'Proceed to payment',
        'setup:next:button': 'Proceed to payment',
        'setup:cancel:button': 'Cancel',
        'setup:save:button': 'Save',
        'setup:check-before-pay:hint': 'Please check if everything is to your liking and click on the “Pay now” button to start your subscription.',

        'setup:plan-display:title': 'Booked plan',
        'setup:plan-display:table:plan': 'Plan',
        'setup:plan-display:table:billing': 'Billing',
        'setup:plan-display:table:status': 'Status',
        'setup:plan-display:table:price': 'Price',
        'setup:plan-display:table:first-year-discount-price': 'First year discount price',
        'setup:plan-display:table:including-50-discount': 'including 50% discount',
        'setup:plan-display:table:tax': 'Tax',
        'setup:plan-display:table:total': 'Total',
        'setup:plan-display:table:tax-country': 'Tax country',
        'setup:plan-display:table:learn-more': 'Learn more',
        'setup:plan-display:change-plan': 'Change subscription',
        'setup:plan-display:table:status-active': 'active',
        'setup:plan-display:table:status-upcoming': 'upcoming',
        'setup:plan-display:table:active-until': 'This plan is active until {endDate}',
        'setup:plan-display:table:active-from': 'This plan will be active starting {startDate}',
        'setup:plan-display:table:enter-voucher-code': 'Enter Voucher code',
        'setup:plan-display:table:billingInterval:monthly': 'monthly',
        'setup:plan-display:table:billingInterval:annually': 'annually',
        'setup:button:proceed-to-payment': 'Proceed to payment',
        'setup:button:proceed-to-billing-address': 'Proceed to billing address',
        'setup:button:proceed-to-checkout': 'Proceed to checkout',
        'setup:voucher:headline:please-enter-voucher': 'Please enter your voucher code',
        'setup:voucher:button:cancel': 'Cancel',
        'setup:voucher:button:verify': 'Verify',
        'setup:voucher:placeholder:type-voucher-code': 'Type voucher code',
        'setup:voucher:text:response-success': 'The voucher code entered is valid. Please wait ...',
        'setup:voucher:text:response:code-expired': 'The voucher code entered has expired.',
        'setup:voucher:text:response:code-invalid-for-selected-plan': 'The voucher code entered is only suitable for an annually billed plus plan. Please {link} selection accordingly.',
        'setup:voucher:text:response:code-amount-exceeded': 'The voucher code entered is not valid. Please try again or send an email to <a href="mailto:support@colido.de?subject=Voucher code is not valid">support@colido.de</a>.',
        'setup:voucher:text:response:code-invalid': 'The voucher code entered is not valid. Please try again or send an email to <a href="mailto:support@colido.de?subject=Voucher code is not valid">support@colido.de</a>.',
        'setup:enter-voucher-code:button': 'Enter voucher code',
        'setup:link:change-your-plan': 'change your plan',

        'setup:billing-address:title': 'Billing Address',
        'setup:billing-address:save': 'Save',
        'setup:billing-address:hint': 'If you are business customer please enter your billing address.',
        'setup:billing-address:vat:title': 'EU VAT number',
        'setup:billing-address:vat:vat-number:label': 'VAT number',
        'setup:billing-address:vat:vat-number:placeholder': 'Type VAT number',
        'setup:billing-address:vat:hint': 'Fill this field if you re a representing EU VAT payer.',
        'setup:billing-address:billing-address:label': 'Billing address',
        'setup:billing-address:billing-address:your-name:label': 'Your name',
        'setup:billing-address:billing-address:name:label': 'Name',
        'setup:billing-address:billing-address:name:placeholder': 'Type your name',
        'setup:billing-address:billing-address:company:label': 'Company name',
        'setup:billing-address:billing-address:company:placeholder': 'Type company name',
        'setup:billing-address:billing-address:street:label': 'Street address',
        'setup:billing-address:billing-address:street:placeholder': 'Type street address',
        'setup:billing-address:billing-address:zip:label': 'ZIP/postal code',
        'setup:billing-address:billing-address:zip:placeholder': 'Type ZIP/postal code',
        'setup:billing-address:billing-address:city:label': 'City',
        'setup:billing-address:billing-address:city:placeholder': 'Type city',
        'setup:billing-address:billing-address:choose-country:label': 'Choose Country',
        'setup:billing-address:billing-address:country:label': 'Choose Country',
        'setup:billing-address:billing-address:empty': 'No billing address provided',
        'setup:billing-address:billing-address:change-billing-address': 'Change billing address',
        'setup:billing-address:error:fullname': 'Please provide a fullname or company name',
        'setup:billing-address:error:company': 'Please provide a fullname or company name',
        'setup:billing-address:error:country': 'Please select a country',

        'setup:tax:title': 'Country of residency',
        'setup:tax:country': 'Country of residency',
        'setup:tax:no-country-selected': 'No country of residency selected',
        'setup:tax:change-country': 'Change country of residency',
        'setup:tax:eu-hint': 'Due to EU regulations we need to know what your country of residency is.\nThis will help us to know where we have to pay our taxes.\nThis will not affect the price you have to pay.',
        'setup:tax:choose-country': 'Choose Country',
        'setup:tax:self-declare-country': 'I self-declare my country of residency',
        'setup:tax:save': 'Save',
        'setup:tax:modal:title': 'Country of residency',

        'payment-history:title': 'Payment history',
        'payment-history:table:plan': 'Plan',
        'payment-history:table:amount': 'Amount',
        'payment-history:table:date': 'Date',
        'payment-history:table:payment-method': 'Payment method',
        'payment-history:table:receipt': 'Receipt',
        'payment-history:table:id': 'ID',
        'payment-history:table:item:receipt': 'Download receipt',
        'payment-history:table:empty': 'No history yet.',

        'quota:title': 'Collection space',
        'overview:title': 'Subscription',

        'setup:button:proceed-checkout': 'Proceed checkout',
        'setup:button:pay-amount': 'Pay {amount}',
        'setup:button:upgrade-amount': 'Upgrade now for {amount} {currency}',
        'setup:button:switch-plan': 'Switch plan',
        'setup:message:error-checkout-process': 'Unfortunately there was an error while processing your checkout. Please try again.',
        'setup:button:try-again': 'Try again',

        'help:title': 'Need help?',
        'help:text': 'Send us your question to <a href="mailto:support@colido.de">support@colido.de</a><br />and we’ll get back to you shortly.',
    },

    community: {
        'label': 'Talk',
        'headline': 'Colido Talk',
        'subheadline': 'Let’s talk collections',
        'tab:label:contacs': 'Contacts',
        'tab:label:groups': 'Groups',
        'tab:label:broadcasts': 'Broadcast',
        'groups-count': {
            0: 'No groups',
            1: '{n} group',
            n: '{n} groups',
        },
        'broadcasts-count': {
            0: 'No broadcast lists',
            1: '{n} broadcast list',
            n: '{n} broadcast lists',
        },
        'contacts-count': {
            0: 'No contacts',
            1: '{n} contact',
            n: '{n} contacts',
        },
        'contacts-pending-count': {
            0: 'No pending contact requests',
            1: '{n} pending contact request',
            n: '{n} pending contact requests',
        },
        'group:button:label:create': 'New group',
        'broadcast:button:label:create': 'New broadcast list',
        'button:label:invite-friends': 'Invite contacts',
        'button:label:friend-request:accept': 'Accept',
        'button:label:friend-request:remove': 'Remove',
        'button:label:conversation:submit': 'Send',
        'input:placeholder:new-post': 'Type your message',
        'info:friend-request:sent': 'Waiting for recipient to confirm request.',
        'contacts:empty': 'You did not add any contacts to Colido yet. Please invite some friends and partners first.\n\nAs soon as your contacts confirm your invitation, you will be able to sent privat messages or set up group chats.',
        'groups:empty': 'You weren’t invited to a group yet. But you can set up a group yourself and invite some contacts to join.',
        'contact:action:confirm-delete': 'Are you sure you want to delete the contact »{name}«?',
        'contact:response:deleted': 'Your contact »{name}« has been removed',
        'contact:response:deleted:error': 'Your contact »{name}« could not be removed',
        'contact:response:accepted': 'The contact »{name}« has been added',
        'contact:response:accepted:error': 'The contact »{name}« could not be added',
        'contact:response:added': 'Your request was sent to {name}. Please wait until the recipient confirms your request',
        'contact:response:added:error': 'The request could not be sent to {name}',
        'contact:response:duplicate': 'You are already connected with »{name}«',
        'contact:response:pending': 'There already is a contact request pending for this email address',
        'contact:response:invalid-email': 'The email address {name} is not valid',
        'contact:actions:label': 'Actions',
        'group:action:confirm-leave': 'Are you sure you want to leave the group »{name}«?',
        'group:action:confirm-delete': 'Are you sure you want to delete the group »{name}«?',
        'group:response:left': 'You left the group »{name}«',
        'group:response:left:error': 'Leaving the group didn’t work',
        'group:response:deleted': 'You deleted the group »{name}«',
        'group:response:deleted:error': 'Deleting the group »{name}« didn’t work',
        'group:actions:label': 'Actions',
        'group:action:headline': 'Actions',
        'group:action:rename': 'Rename talk',
        'group:action:change-users': 'Invite contacts',
        'group:action:leave': 'Leave group',
        'group:action:delete': 'Delete group',
        'group:input:label:name': 'Group name',
        'group:input:placeholder:name': 'Type group name',
        'group:button:label:create:submit': 'Create group',
        'group:button:label:update:submit': 'Save',
        'group:legend:contacts': 'Add contacts',
        'group:legend:current-members': 'Current members',
        'group:response:invalid-form': 'A group needs a name and at least another member.',
        'group:modal:title:edit': 'Edit group',
        'group:show-all-users': 'Show all {n} users',
        'broadcasts:empty': 'With the broadcast function you can easily create and privately message a list of contacts. Recipients will not know who else received the message and that it was sent with the broadcast function.',
        'broadcast:action:confirm-delete': 'Are you sure you want to delete the broadcast list »{name}«?',
        'broadcast:legend:contacts': 'Recipients',
        'broadcast:input:label:name': 'New broadcast list',
        'broadcast:input:placeholder:name': 'Type list name',
        'broadcast:button:label:create:submit': 'Create broadcast list',
        'broadcast:modal:title:edit': 'Edit broadcast list',
        'broadcast:action:rename': 'Rename broadcast list',
        'broadcast:action:change-users': 'Edit recipients',
        'broadcast:action:delete': 'Delete broadcast list',
        'broadcast:response:deleted': 'You deleted the broadcast list »{name}«',
        'broadcast:response:deleted:error': 'Deleting the broadcast list »{name}« didn’t work',
        'button:label:new-contacts:submit': 'Send invitation',
        'button:label:send-message': 'Send private message',
        'button:label:edit-profile': 'Edit profile',
        'button:label:send-friend-request': 'Send contact request',
        'input:label:new-contacts:email': 'Email address',
        'input:placeholder:new-contacts:email': 'info@example.com',
        'input:label:new-contacts:message': 'Message (optional)',
        'new-contacts:hint': 'Please enter the email address of the person you would like to send a contact request to. You are free to add a custom message as well.',
        'button:label:group:leave': 'Leave group',
        'conversation-post:empty': 'Write your first message now!',
        'conversation-post:group-renamed': '{username} has renamed this group to »{toTitle}«', //provided by API
        'conversation-post:user-left-group': '{username} has left the group.', //provided by API
        'conversation-post:user-joined-group': '{username} has joined the group.', //provided by API
        'conversation-post:contact-request-sent': 'Contact request sent by {username}.', //provided by API //both sender and recipient receive the same message
        'conversation-post:contact-request-accepted': 'Contact request accepted by {username}.', //provided by API //both sender and recipient receive the same message
        'notification:new-message': 'New Colido Talk message',
        'notification:unread-messages': {
            0: 'You have {n} unread messages.',
            1: 'You have {n} unread message.',
            n: 'You have {n} unread messages.',
        },
        'restriction-modal:headline': 'Please setup profile',
        'restriction-modal:text': 'In order to use Colido Talk, you need to setup your profile first.',
        'restriction-modal:button-profile': 'Go to profile',
        'share-set:message-default-contact': 'The set {setName} has been shared with your contact {name}',
        'share-set:message-default-group': 'The set {setName} has been shared with your group {name}',
        'share-set:message-default-broadcast': 'The set {setName} has been shared with your broadcast list {name}',
        'share-set:message-shared-contact': 'The set {setName} has been shared with your contact {name}',
        'share-set:message-shared-group': 'The set {setName} has been shared with your group {name}',
        'share-set:message-shared-broadcast': 'The set {setName} has been shared with your broadcast list {name}',
        'share-set:unavailable': 'The shared set is not available anymore',
        'share-set:object-count': {
            0: 'No objects',
            1: '{n} object',
            n: '{n} objects',
        },
        'share-set:modal:go-back': 'Go back to Colido Talk',
        'share-menu:title': 'Share your sets',
        'share-menu:empty': 'Please share some sets first',
        'share-conversation:modal:headline': 'Share via Colido Talk',

        'shop:post:offer-completed:buyer': 'You have a new item {targetObjectLink} in your catalog {targetCatalogLink}.',
        'shop:post:offer-completed:seller': 'Your item has been unlisted from the shop and some attributes have been changed (Status: deaccessioned, Storage: Out of collection, ...).',
    },
};