'use strict';

var HeaderView = require('modules/collection/views/layout/header.js');
var ImportModel = require('modules/collection/modules/import/models/import');

var template = require('../../templates/layout/import-object-header.hbs');

var ImportObjectHeaderView = HeaderView.extend({
    template: template,

    viewData: {
        objectCount: function() {
            return this.collection.length;
        },
        backLink: function() {
            return '/catalogs/' + this.collection.catalog.id + '/imports';
        },
    },

    /**
     *
     * @returns {string} Unescaped Text
     */
    getHeadline: function () {
        if (this.model.get('importModel') instanceof ImportModel) {
            return App.t('settings::import:detail:headline', {
                filename: this.model.get('importModel').get('file').filename,
            });
        } else if (this.model.get('importWarningsModel') instanceof ImportModel) {
            return App.t('settings::import:detail:headline-warnings', {
                filename: this.model.get('importWarningsModel').get('file').filename,
            });
        }
    },

    /**
     *
     * @returns {string} Unescaped Text
     */
    getSubline: function () {
        return App.t('collection::objects:count', this.collection.length);
    },
});

module.exports = ImportObjectHeaderView;