'use strict';

var CollectionView = require('base/Colido.CollectionView');
var ModalItemView = require('./modal-list-item');

var ModalListView = CollectionView.extend({
    className: 'modals-list',

    events: {
        'click ': 'handleMainBackgroundClick',
    },

    childView: ModalItemView,

    childEvents: {
        'addBg': 'addBg',
        'removeBg': 'removeBg',
    },

    addBg: function(model, additionalBgClassName){
        this.$el.addClass(additionalBgClassName);
    },

    removeBg: function(model, additionalBgClassName){
        // wenn er der letzte ist, die hintergrund nicht entfernen!
        if (this.collection.size() > 1) {
            if (!_.isNull(additionalBgClassName)) {
                this.$el.removeClass(additionalBgClassName);
            }

            // sub bg wird gleich entfernt
            if (this.collection.size() === 2) {
                this.$('.modal-sub-bg').addClass('fade-out-bg');
            }
        } else {
            this.$el.addClass('fade-out-bg');
        }
    },

    childViewOptions: function(model, index){
        return model.get('options');
    },

    initialize: function(options) {
        CollectionView.prototype.initialize.apply(this, arguments);

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.listenTo(App.vent, 'keydown', this.handleKeyDown);
    },

    onBeforeAddChild: function() {
        this.toggleModalsVisible();
        this.toggleSubBackground();
    },

    onRemoveChild: function() {
        this.toggleModalsVisible();
        this.toggleSubBackground();
        if (this.collection.size() === 0) {
            this.$el.removeClass('fade-out-bg');
            this.$el.attr('class', 'modals-list');
        }
    },

    handleMainBackgroundClick: function(event){
        if (event.currentTarget === event.target) {
            this.children.each(function(view){
                view.modalContent.trigger('close:modal');
            });
        }
    },

    toggleModalsVisible: function() {
        this.$el.toggleClass('has-modals', !this.isEmpty());
        $('body').toggleClass('has-modals', this.collection.size() > 0);
    },

    toggleSubBackground: function(){
        if (this.collection.size() > 1) {
            this.$('.modal-sub-bg').remove();

            var element = $('<div class="modal-sub-bg"></div>');
            this.$('.modal').last().before(element);

            element.on('click', function(){
                this.children.last().modalContent.trigger('close:modal');
            }.bind(this));

            setTimeout(function(){
                element.addClass('modal-sub-bg-show');
            }, 1);
        } else {
            this.$('.modal-sub-bg').remove();
        }
    },

    handleKeyDown: function(event) {
        if (event.which === App.keys.ESCAPE) {
            var lastModel = this.collection.last();

            if (lastModel) {
                lastModel.get('view').close();
            }
        }
    },
});

module.exports = ModalListView;