'use strict';

var ItemView = require('base/Colido.ItemView');

var template = require('modules/collection/templates/partials/showroom-options.hbs');

var ShowroomOptionsView = ItemView.extend({
    template: template,

    ui: {
        'closeShowroom': '[data-action="close-showroom"]',
    },

    events: {
        'click @ui.closeShowroom': 'closeShowroom',
    },

    /**
     * Closes the showroom view
     */
    closeShowroom: function() {
        this.model.set('viewMode', this.model.get('viewModeDefault'));
    },
});

module.exports = ShowroomOptionsView;