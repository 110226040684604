'use strict';

var CompositeView = require('base/Colido.CompositeView');
var ConversationsListItemView = require('./conversations-list-item');
// var EmptyView = require('./contacts-empty');

var template = require('modules/community/templates/partials/conversations-list.hbs');

var ConversationsListView = CompositeView.extend({
    template: template,

    className: 'conversations',

    childView: ConversationsListItemView,

    // emptyView: EmptyView,

    childViewContainer: '.conversations-list',

    infiniteView: false,

    // ui: {
    //     'contactsCount': '.contacts-count',
    //     'showAddContactsForm': '[data-action="show-add-contacts-form"]',
    //     'contactsMetaInfo': '.contacts-metainfo',
    //     'addContactsForm': 'form',
    //     'newContactEmail': '[name="newContactEmail"]',
    //     'newContactMessage': '[name="newContactMessage"]',
    // },

    // events: {
    //     'click @ui.inviteContacts': 'inviteContacts',
    //     'click @ui.showAddContactsForm': 'showAddContactsForm',
    //     'submit @ui.addContactsForm': 'addContacts',
    //     'keydown @ui.newContactEmail': 'preventEnterKeySubmit',
    //     'keyup @ui.newContactEmail': 'checkEnterKeySubmit',
    //     'keydown @ui.newContactMessage': 'checkAutosizeTextArea',
    //     'keyup @ui.newContactMessage': 'checkAutosizeTextArea',
    //     'change @ui.newContactMessage': 'checkAutosizeTextArea',
    // },

    childEvents: {
        'open:conversation': 'openConversation',
    },

    sortDefault: 'lastPost.desc',

    filter: function(model) {
        var conversationType = model.get('type');

        return conversationType === 'contact' || conversationType === 'group';
    },

    // onRender: function() {
    //     CompositeView.prototype.onRender.apply(this, arguments);

    //     if (this._showingEmptyView && this.getOption('type') !== 'accepted') {
    //         this.$el.hide();
    //     } else {
    //         this.$el.show();
    //     }

    //     this.updateContactsCount();
    // },

    onAttach: function() {
        if (this.getOption('infiniteView')) {
            this.setSlideeWidth();

            this.slider = this.$el.sly({
                // slidee: '.sly-slider',
                horizontal: true,
                // itemNav: 'basic',
                speed: 300,
                mouseDragging: 1,
                touchDragging: 1,
                elasticBounds: 1,
                releaseSwing: 1,
                scrollBar: this.$('.sly-scroll'),
                dynamicHandle: true,
                dragHandle: true,
            });
        }
    },

    onResize: function() {
        if (this.slider && this.slider.sly) {
            this.setSlideeWidth();
            this.slider.sly('reload');
        }
    },

    /**
     * Destroy plugins and additional listener
     */
    onBeforeDestroy: function() {
        if (this.getOption('infiniteView')) {
            this.slider && this.slider.sly && this.slider.sly('destroy');
        }
    },

    setSlideeWidth: function() {
        var slideeWidth = _.reduce(this.$('.sly-slider').children(), function(adder, element){ return $(element).outerWidth(true) + adder; }, 0);
        this.$('.sly-slider').width(slideeWidth);
    },

    openConversation: function(event, conversation) {
        this.trigger('open:conversation', conversation);
    },

    // showAddContactsForm: function() {
    //     this.ui.contactsMetaInfo.addClass('show-form');
    //     this.ui.newContactEmail.focus();
    // },

    // hideAddContactsForm: function() {
    //     this.ui.contactsMetaInfo.addClass('show-form');
    //     this.ui.showAddContactsForm.focus();
    // },

    // addContacts: function(event) {
    //     event ? event.preventDefault() : null;

    //     var contact = this.ui.newContactEmail.val().trim();
    //     var message = this.ui.newContactMessage.val().trim();

    //     // var button = $(event ? event.currentTarget : null);
    //     if (contact.match(/.+@.+/)) {
    //         App.radio('community').execute('add:contacts', contact, {
    //             message: message,

    //             success: function() {
    //                 if (this.isDestroyed) { return; }

    //                 this.ui.newContactEmail.val('');
    //             }.bind(this),

    //             error: function() {
    //                 // button.prop('disabled', false).removeClass('is-loading');
    //                 if (this.isDestroyed) { return; }

    //                 this.showAddContactsForm();
    //                 this.checkAutosizeTextArea(this.ui.newContactEmail);
    //             }.bind(this),
    //         });

    //         this.hideAddContactsForm();
    //     } else {
    //         Messenger().error({
    //             message: App.t('community::contact:response:invalid-email', {name: _.escape(contact)}),
    //         });
    //     }

    //     // button.prop('disabled', true).addClass('is-loading');
    //     // console.log('addContacts', event)
    // },

    // checkEnterKeySubmit: function(event) {
    //     if (event.which === App.keys.RETURN) {
    //         this.ui.addContactsForm.submit();
    //     }
    // },

    // preventEnterKeySubmit: function(event) {
    //     if (event.which === App.keys.RETURN) {
    //         event.preventDefault();
    //     }
    // },
});

module.exports = ConversationsListView;