'use strict';

var Html = {
    /**
     * Returns a formatted anchor tag
     * @param {object} options Options object
     * options.url The target URL
     * options.label The link label [default: options.url]
     * options.id ID attribute
     * options.classes The classes to set [default: link-border]. Empty string ommits class attribute
     * options.title Title attribute

     * @returns {string} The formatted link or empty string if no options.url is provided
     */
    link: function(options) {
        var html = '';

        if (options && options.url) {
            var url = options.url;
            var label = _.isString(options.label) ? options.label : url;

            var idAttribute = _.isString(options.id) ? ' id="' + options.id + '"' : '';

            var classes = _.isString(options.classes) ? options.classes : 'link-border';
            var classesAttribute = classes.length ? ' class="' + classes + '"' : '';

            var filename = _.isString(options.download) ? options.download : null;
            var downloadAttribute = options.download ? ' download' + (filename ? '="' + filename + '"' : '') : '';

            var titleAttribute = _.isString(options.title) ? ' title="' + options.title + '"' : '';

            html = '<a href="' + url + '"' + idAttribute + classesAttribute + titleAttribute + downloadAttribute + '>' + label + '</a>';
        }

        return html;
    },

    multiline: function(string) {
        var html = '';

        if (_.isString(string)) {
            html = '<p>' + _.escape(string).trim().replace(/\n([ \t]*\n)+/g, '</p><p>').replace(/\n/g, '<br />') + '</p>';
        }

        return html;
    },

    nl2br: function(string) {
        var html = '';

        if (_.isString(string)) {
            html = _.escape(string).trim().replace(/\n/g, '<br />');
        }

        return html;
    },

    list: function(string, tagName) {
        var html = '';

        if (_.isString(string)) {
            tagName = tagName || 'ul';

            html += '<' + tagName + '>';
            html += '<li>' + (string).trim().replace(/\n([ \t]*\n)+/g, '</li><li>').replace(/\n/g, '</li><li>') + '</li>'; // dont escape per _.escape! the strings may have <i> in it
            html += '</' + tagName + '>';
        }

        return html;
    },

    icon: function(name, size, addClasses) {
        var icon = '';

        if ($('svg #icon-' + name).length) {
            var classes = ['ico', 'ico-' + name];

            if (size) {
                classes.push('ico-size-' + size);
            }

            if (addClasses) {
                classes.push(addClasses);
            }

            icon = '<svg class="' + classes.join(' ') + '"><use xlink:href="#icon-' + name + '" /></svg>';
        }

        return icon;
    },

    /**
     * Returns a proper dimensions string, based on given parameters
     * @param {float}  sizeWidth  The width of the object
     * @param {float}  sizeHeight The height of the object
     * @param {float}  sizeDepth  The depth of the object
     * @param {string} sizeUnit   The unit
     * @returns {string} The formatted size
     */
    formatDimensions: function(sizeWidth, sizeHeight, sizeDepth, sizeUnit) {
        var dimensions = '';

        var sizes = [];
        for (var i = 0; i < arguments.length; i++) {
            if (parseFloat(arguments[i]) > 0) {
                sizes.push(arguments[i]);
            }

            if (_.isString(arguments[i])) {
                sizeUnit = arguments[i];
            }
        }

        if (sizes.length) {
            dimensions = sizes.join(' × ') + (sizeUnit ? ' ' + sizeUnit : '')
        }

        return dimensions;
    },

    /**
     * Formats the given number based on the locale
     * @param {number} number The number to format
     * @param {object}  options Options object passed to .toLocaleString() / Fallback only supports maximum/minimumFractionDigits
     * @returns {string}|{number} The formatted number
     */
    formatNumber: function(number, options) {
        if (!_.isNumber(number) || isNaN(number)) {
            return number;
        }

        options = options || {};

        if (App.util.toLocaleStringSupportsLocales()) {
            try {
                return number.toLocaleString(App.getLanguage(), options);
            } catch (exception) {
                console.error('Oops!', 'Language:', App.getLanguage(), 'Options:', options, 'Stack:', exception.stack);
            }
        }

        //defaults minimumFraction to 0, force to be between 0 and 20
        options.minimumFractionDigits = _.isNumber(options.minimumFractionDigits) ? Math.max(0, Math.min(options.minimumFractionDigits, 20)) : 0;

        //defaults maximumFractionDigits to larger of minimumFraction and 3, force to be between 0 and 20
        options.maximumFractionDigits = _.isNumber(options.maximumFractionDigits) ? Math.max(0, Math.min(options.maximumFractionDigits, 20)) : Math.max(options.minimumFractionDigits, 3);

        var numberParts = number.toString().split('.');

        var fractionDigits = numberParts.length === 2 ? numberParts[1].length : 0;
        if (fractionDigits > options.maximumFractionDigits) {
            numberParts = Number(number.toFixed(options.maximumFractionDigits)).toString().split('.');
        }

        fractionDigits = numberParts.length === 2 ? numberParts[1].length : 0;
        if (fractionDigits < options.minimumFractionDigits) {
            numberParts = number.toFixed(options.minimumFractionDigits).split('.');
        }

        numberParts[0] = numberParts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, App.t('localization::thousand:separator'));

        return numberParts.join(App.t('localization::decimal:separator'));
    },

    /**
     * Returns a proper format for the given number and currency
     * @param {number} number           The input number
     * @param {string} currency         currency The ISO code of the currency
     * @param {string} currencyDisplay  Optional, Not supported by custom fallback. How to display the
     *                                  currency in currency formatting.
     *                                  Possible values are "symbol" to use a localized currency symbol
     *                                  such as €, "code" to use the ISO currency code, "name" to use a
     *                                  localized currency name such as "dollar"; the default is "symbol".
     * @returns {string} The formatted number with currency
     */
    formatCurrency: function(number, currency, currencyDisplay) {
        if (!_.isNumber(number) || isNaN(number) || !_.isString(currency)) {
            return number;
        }

        number = Number(number);
        if (App.util.toLocaleStringSupportsLocales()) {
            try {
                return number.toLocaleString(App.getLanguage(), {
                    style: 'currency',
                    currency: currency.toUpperCase(),
                    currencyDisplay: currencyDisplay,
                });
            } catch (exception) {
                console.error('Oops!', 'Language:', App.getLanguage(), 'Currency:', currency, 'CurrencyDisplay:', currencyDisplay, 'Stack', exception.stack);
            }
        }

        var amount = this.formatNumber(number, {minimumFractionDigits: 2, maximumFractionDigits: 2});

        return App.t('global::currencies:' + currency.toLowerCase() + ':amount', {amount: amount});
    },

    formatByte: function(number) {
        if (!_.isNumber(number)) {
            return number;
        }

        if (number > 1024 * 1024 * 1024) {
            return (number / (1024 * 1024 * 1024)).toFixed() + ' GB';
        } else if (number > 1024 * 1024) {
            return (number / (1024 * 1024)).toFixed() + ' MB';
        } else if (number > 1024) {
            return (number / (1024)).toFixed() + ' KB';
        } else if (number === 0) {
            return 0 + ' KB';
        }

        return (number / (1024)).toFixed(1) + ' KB';
    },

    formatDate: function(timestamp, locale) {
        return App.util.getDateFromTimestamp(timestamp, locale);
    },

    formatTime: function(timestamp, locale) {
        return App.util.getTimeFromTimestamp(timestamp, locale);
    },

    formatDateTime: function(timestamp, locale) {
        return this.formatDate(timestamp, locale) + ' ' + this.formatTime(timestamp, locale);
    },

    /**
     * Creates the markup for the responsive image, based on the given parameters
     *
     * @see Colido.ItemView.imageSizes
     * @see Colido.ItemView.imageViewportSizes
     *
     * @param {object}         imagePath     The imagePath object, that contains baseUrl, filename, isPlaceholder
     * @param {string}|{array} sizes         String or array for the image sizes
     * @param {string}|{array} viewportSizes (Optional) String or array for the possible viewport sizes
     * @param {string}         alt           (Optional) Alt description for the image
     * @param {string}         classes       (Optional) Additional classes for the image tag
     * @returns {string} Markup for the image or an empty string
     */
    responsiveImage: function(imagePath, sizes, viewportSizes, alt, classes) {
        classes = classes || '';
        alt     = alt || '';

        // Do we have an image path?
        if (!_.isObject(imagePath)) {
            return '';
        }

        if (/\.svg$/.test(imagePath.fileName)) {
            return '<img src="' + imagePath.baseUrl + imagePath.fileName + '" '  +
                        'alt="' + _.escape(alt) + '"/>';
        }

        // Do we have a proper size?
        if (!_.isArray(sizes) && !_.isString(sizes)) {
            return '';
        }

        // Just a size string?
        if (!_.isArray(sizes)) {
            sizes = [sizes];
        }

        // Check viewport size
        if (_.isUndefined(viewportSizes)) {
            viewportSizes = '';
        }

        if (_.isString(viewportSizes) && viewportSizes.length > 0) {
            viewportSizes = [viewportSizes];
        } else {
            viewportSizes = [];
        }

        var sizeArray = [];
        var src  = '';
        var srcSet = [];
        var targetPath;
        var targetPathFallback;
        var isCropped;
        var aspectRatio = imagePath.aspectRatio || 1.5;
        var qualityNormal = 80;
        var qualityRetina = 40;

        // Higher quality for zoom and detail images
        if (/(zoom-image|detail-image)/.test(classes)) {
            qualityRetina = 75;
        }

        // Generate sizes
        _.each(sizes, function(size, idx) {
            sizeArray = size.split('x');
            isCropped = false;

            if (sizeArray[1].search(/c/) !== -1) {
                sizeArray[1] = parseInt(sizeArray[1]);
                isCropped = true;
            }

            // Determine correct image
            if (imagePath.isPlaceholder) {
                targetPath = !_.contains(['256x256', '50x50'], size)
                           ? imagePath.baseUrl + imagePath.fileName
                           : imagePath.baseUrl + sizeArray[0] + 'x' + sizeArray[1] + '/' + imagePath.fileName;
                targetPathFallback = targetPath;
            } else {
                targetPath = imagePath.baseUrl + (sizeArray[0] * 2) + 'x' + (sizeArray[1] * 2) + (isCropped ? 'c' : '')
                           + 'q' + qualityRetina + '/'
                           + imagePath.fileName;
                targetPathFallback = imagePath.baseUrl + sizeArray[0] + 'x' + sizeArray[1] + (isCropped ? 'c' : '')
                                   + 'q' + qualityNormal + '/'
                                   + imagePath.fileName;
            }

            // Fill src image
            if (idx == 0) {
                src = targetPathFallback;
            }

            // Fill default srcSet
            srcSet.push(targetPath + ' ' + sizeArray[0] + 'w');
        });

        // Is image a grid image?
        if (/grid-image/.test(classes)) {
            if (aspectRatio < 1.5 && aspectRatio > 1.0) {
                classes += ' cropped cropped-hor';
            } else if (aspectRatio > 1.5 && aspectRatio < 2.0) {
                classes += ' cropped cropped-vert';
            }
        }

        return '<img src="' + src +  '" srcset="' + srcSet.reverse().join(',\n') + '"'
               + (viewportSizes.length > 0 ? ' sizes="' + viewportSizes.join(',\n') + '"' : '')
               + (classes.length > 0 ? ' class="' + classes.trim() + '"' : '')
               + (alt.length > 0 ? ' alt="' + _.escape(alt) + '"' : '')
               + ' draggable="false"'
               + '>';
    },

    /**
     * Gets a caret position from a textfield/textarea
     * @param {DOMElement} domElement The DOM element
     * @returns {number} The caret position
     */
    getCaretPosition: function(domElement) {
        var caretPos = 0;
        if (document.selection) {
            domElement.focus();
            var Sel = document.selection.createRange();
            Sel.moveStart('character', -domElement.value.length);
            caretPos = Sel.text.length;
        } else if (domElement.selectionStart || domElement.selectionStart == '0') {
            caretPos = domElement.selectionStart;
        }

        return caretPos;
    },

    /**
     * Sets a caret position for the given input/textarea
     * @param {DOMElement} domElement The DOM element
     * @param {number}     pos        The position to set
     */
    setCaretPosition: function(domElement, pos) {
        if (domElement.setSelectionRange) {
            domElement.focus();
            domElement.setSelectionRange(pos, pos);
        } else if (domElement.createTextRange) {
            var range = domElement.createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos);
            range.moveStart('character', pos);
            range.select();
        }
    },
};

module.exports = Html;