'use strict';

var Marionette = require('backbone-marionette');
var Controller = require('./controller');

var DashboardModule = Marionette.Module.extend({
    startWithParent: true,

    onStart: function(options) {
        var app = this.app;

        var controller = this.controller = new Controller({
            app: app,
            module: this,
        });

        app.addInitializer(function(){
            app.router.processAppRoutes(controller, controller.routes);
        });
    },
});

module.exports = DashboardModule;