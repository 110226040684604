module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="nav-content-wrapper">\n    <div class="nav-wrapper nav-wrapper-main">\n        <ul class="level-1">\n            <li class="account">\n                <button class="account-switcher leave-mobile-menu profile-navigation-region"></button>\n                <div class="subnav-container account-subnav">\n                    <ul class="level-2">\n                        <li>\n                            <a href="/account/profile">'+
((__t=( t('settings::profile:label') ))==null?'':_.escape(__t))+
'</a>\n                        </li>\n                        <li>\n                            <a href="/account/account">'+
((__t=( t('settings::account:label') ))==null?'':_.escape(__t))+
'</a>\n                        </li>\n\n                        ';
 if (Colido.config.allowSignup) { 
__p+='\n                            <li>\n                                <a href="/account/subscription">'+
((__t=(t('settings::subscription:label') ))==null?'':_.escape(__t))+
'</a>\n                            </li>\n                        ';
 } 
__p+='\n                        <li>\n                            <a href="/logout" class="logout">'+
((__t=( t('navigation::logout') ))==null?'':_.escape(__t))+
'</a>\n                        </li>\n                    </ul>\n                </div>\n            </li>\n            <li>\n                <a href="/dashboard">\n                    <span class="nav-icon nav-icon-circle">'+
((__t=( icon('menu-dashboard') ))==null?'':__t)+
'</span>'+
((__t=( t('dashboard::label') ))==null?'':_.escape(__t))+
'\n                </a>\n            </li>\n\n            ';
 if (App.CommunityModule) { 
__p+='\n                <li class="community">\n                    <a href="/community/contacts">\n                        <span class="nav-icon nav-icon-circle">'+
((__t=( icon('menu-community') ))==null?'':__t)+
'</span>'+
((__t=( t('community::label') ))==null?'':_.escape(__t))+
'\n                        <span class="count count-unread-posts">0</span>\n                    </a>\n\n                    <div class="subnav-container">\n                        <ul class="level-2">\n                            <li>\n                                <a href="/community/contacts">'+
((__t=( t('community::tab:label:contacs') ))==null?'':_.escape(__t))+
'</a>\n                            </li>\n                            <li>\n                                <a href="/community/groups">'+
((__t=( t('community::tab:label:groups') ))==null?'':_.escape(__t))+
'</a>\n                            </li>\n                            <li>\n                                <a href="/community/broadcasts">'+
((__t=(t('community::tab:label:broadcasts') ))==null?'':_.escape(__t))+
'</a>\n                            </li>\n                        </ul>\n                    </div>\n                </li>\n            ';
 } 
__p+='\n\n            ';
 if (App.user.get('showShop') && App.CollectionModule.ShopModule.shops.length) { 
__p+='\n                <li class="shops">\n                    <a href="/shops/'+
((__t=(App.CollectionModule.ShopModule.shops.getActiveShop().id))==null?'':_.escape(__t))+
'">\n                        <span class="nav-icon nav-icon-circle">'+
((__t=( icon('menu-shop') ))==null?'':__t)+
'</span> '+
((__t=( t('collection::shop:label') ))==null?'':_.escape(__t))+
'\n                    </a>\n\n                    <div class="subnav-container">\n                        <ul class="level-2">\n                            ';
 App.CollectionModule.ShopModule.shops.each(function(shop){ 
__p+='\n                                <li>\n                                    <a href="/shops/'+
((__t=(shop.id))==null?'':_.escape(__t))+
'">'+
((__t=(shop.get('typeName')))==null?'':_.escape(__t))+
'</a>\n                                </li>\n                            ';
 }) 
__p+='\n                        </ul>\n                    </div>\n                </li>\n            ';
 } 
__p+='\n        </ul>\n\n        <div class="navigation-catalog-region"></div>\n\n        <ul>\n            <li class="secondary helpdesk">\n                <a href="/helpdesk">\n                    <span class="nav-icon nav-icon-circle">'+
((__t=( icon('menu-help') ))==null?'':__t)+
'</span>\n                    '+
((__t=( t('helpdesk::label') ))==null?'':_.escape(__t))+
'\n                </a>\n            </li>\n            <li>\n                <a href="/settings/information">\n                    <span class="nav-icon nav-icon-circle">'+
((__t=( icon('menu-help') ))==null?'':__t)+
'</span>\n                    '+
((__t=( t('settings::information:label') ))==null?'':_.escape(__t))+
'\n                </a>\n            </li>\n        </ul>\n    </div>\n\n    <a href="/" class="logo">Colido'+
((__t=( icon('logo') ))==null?'':__t)+
'</a>\n\n    <div class="nav-wrapper nav-wrapper-sets navigation-dnd-sets-region" id="navigationSets"></div>\n\n    <div class="nav-wrapper nav-wrapper-talks" id="navigation-talks">\n        <section class="navigation-dnd-talks navigation-dnd-talks--contacts">\n            <h3>'+
((__t=( t('community::tab:label:contacs') ))==null?'':_.escape(__t))+
'</h3>\n            <div class="navigation-dnd-talks-contacts-region"></div>\n        </section>\n        <section class="navigation-dnd-talks navigation-dnd-talks--groups">\n            <h3>'+
((__t=( t('community::tab:label:groups') ))==null?'':_.escape(__t))+
'</h3>\n            <div class="navigation-dnd-talks-groups-region"></div>\n        </section>\n        <section class="navigation-dnd-talks navigation-dnd-talks--broadcasts">\n            <h3>'+
((__t=( t('community::tab:label:broadcasts') ))==null?'':_.escape(__t))+
'</h3>\n            <div class="navigation-dnd-talks-broadcasts-region"></div>\n        </section>\n    </div>\n</div>';
}
return __p;
};