'use strict';

var Marionette = require('backbone-marionette');
var Controller = require('./controller');
// var Wreqr = require('./wreqr');

var TrashModule = Marionette.Module.extend({
    startWithParent: true,

    initialize: function(moduleName, app) {
    },

    onStart: function(options) {
        var app = this.app;

        var controller = this.controller = new Controller({
            app: app,
            module: this,
        });

        // this.wreqr = new Wreqr({
        //     app: app,
        //     module: this,
        // });

        app.addInitializer(function(){
            app.router.processAppRoutes(controller, controller.routes);
        }.bind(this));
    },
});

module.exports = TrashModule;