'use strict';

var ItemView = require('base/Colido.ItemView');
var template = require('modules/collection/templates/partials/list-grid-new-item.hbs');

var ListGridNewItemView = ItemView.extend({
    template: template,

    tagName: 'a',

    className: 'box box-empty',

    attributes: function(){
        return {
            href: this.getOption('state').getPath() + '/new',
            draggable: false,
        }
    },

    events: {
        'click': 'itemClicked',
    },

    /**
     * Disables link when manage mode is active
     */
    itemClicked: function (event) {
        var isManageMode = this.getOption('isManageMode');

        if (isManageMode()) {
            event.preventDefault();
        }
    },
});

module.exports = ListGridNewItemView;