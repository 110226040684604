module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form class="signup-token-form" method="post">\n    <h2>'+
((__t=(t('signup-token::headline')))==null?'':_.escape(__t))+
'</h2>\n    <fieldset class="submit">\n        <button type="reset" class="btn btn-primary btn-cancel">'+
((__t=(t('signup-token::back-to-the-loginform')))==null?'':_.escape(__t))+
'</button>\n    </fieldset>\n</form>';
}
return __p;
};