module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if(App.user && App.user.get('licenseStorages')){ 
__p+='\n    <section class="object-storage-location">\n        <h3>'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</h3>\n\n        '+
((__t=( model.getStoragePath() ))==null?'':__t)+
'\n\n        <button data-action="choose-storage" class="btn btn-clean">\n            '+
((__t=( t('collection::object:action:choose-storage') ))==null?'':_.escape(__t))+
'\n        </button>\n    </section>\n';
 } 
__p+='';
}
return __p;
};