'use strict';

var CompositeView = require('base/Colido.CompositeView');
var ListGridItemView = require('./list-grid-item');
var EmptyView = require('./list-empty');

var template = require('modules/collection/templates/partials/list-slider.hbs');

require('jquery-humans-dnd');

var ListSliderView = CompositeView.extend({
    template: template,

    childViewContainer: '.objects-slider-container',

    childView: ListGridItemView,

    emptyView: EmptyView,

    ui: {
        'listingContainer': '.objects-listing',
    },

    reorderOnSort: false,

    /**
     * Refernce of the slider in infite view mode
     * @type {null|object}
     */
    slider: null,

    startDelays: [],

    emptyViewOptions: function() {
        return {
            collection: this.collection,
            model: this.model,
            isInfiniteView: this.getOption('infiniteView'),
            viewMode: 'grid', //@todo check this
        };
    },

    /**
     * Options to pass to childView
     * @param [object} model The model
     * @param {int}    index The index of the model
     * @returns {object} The child view options
     */
    childViewOptions: function(model, index) {
        return {
            state: this.model,
            isManageMode: function() { return false; },
        };
    },

    /**
     * Callback for redering process
     * @return {void}
     */
    onRender: function() {
        CompositeView.prototype.onRender.apply(this, arguments);

        this.startDelays.push(_.delay(function() {
            this.slider = this.$('.sly-frame').sly({
                horizontal: 1,
                itemNav: 'basic',
                speed: 300,
                mouseDragging: 1,
                touchDragging: 1,
                elasticBounds: 1,
                releaseSwing: 1,
                scrollBar: this.$('.sly-scroll'),
                dynamicHandle: 1,
                dragHandle: 1,
            });
        }.bind(this), 10));
    },

    /**
     * Window resize callback
     */
    onResize: function() {
        if (this.slider !== null) {
            this.slider.sly('reload');
        }
    },

    /**
     * Destroy plugins and additional listener
     */
    onBeforeDestroy: function() {
        // Make sure, view is not destroyed yet
        if (this.isDestroyed) {
            return;
        }

        // clear the start-delays
        // it could be possible, taht these are not fireed till now
        _.each(this.startDelays, function(timerId){
            clearTimeout(timerId);
        });

        this.startDelays = [];

        this.slider && this.slider.sly && this.slider.sly('destroy');
    },
});

module.exports = ListSliderView;