'use strict';

var CompositeView = require('base/Colido.CompositeView');
var ListTableItemView = require('./list-table-item');
var EmptyView = require('./list-empty');
var ListTableNewItemView = require('./list-table-new-item');

var template = require('modules/collection/templates/partials/list-table.hbs');

require('jquery-humans-dnd');

var ListTableView = CompositeView.extend({
    template: template,

    childViewContainer: '.objects-table-container',

    childView: ListTableItemView,

    emptyView: EmptyView,

    newObjectView: ListTableNewItemView,

    getChildView: function (child) {
        if (child.get('virtualItem')) {
            return this.newObjectView;
        } else {
            return this.childView;
        }
    },

    /**
     * Show 'add new object' item only in full catalog, not when
     * search, filter, set or location are active.
     */
    showVirtualItem: function () {
        var model = this.model;

        return !model.get('search')
            && !model.get('filter')
            && !model.get('setModel')
            && !model.get('locationModel')
            && !model.get('importModel')
            && !model.get('importWarningsModel')
            && !model.get('isShop');
    },

    ui: {
        'listingContainer': '.objects-listing',
        'selectOverlay': '.select-overlay',
        'tableLabel': 'th label',
        'table': 'table',
        'scrollbarContainer': '.container-table-scrollbar',
        'scrollbarContainerInner': '.container-table-scrollbar-inner',
        'viewmodeList': '.viewmode-list',
        'mobileBackToTop': '[data-action="back-to-top"]',
    },

    events: {
        'click @ui.selectOverlay': 'selectOverlayClick',
        'click @ui.tableLabel': 'listSortChange',
        'click @ui.mobileBackToTop': 'mobileBackToTop',
    },

    modelEvents: {
        'change:controlMode': 'controlModeChanged',
        'change:activeModel': 'activeModelChanged',
        'change:sortList': 'sortListChanged',
    },

    childEvents: {
        'activated': 'setActiveChildView',
    },

    collectionEvents: {
        'reset': 'render',
    },

    reorderOnSort: false,

    /**
     * @todo: Move this to compositeview, if possible!?
     */
    scrollPosition: 0,

    /**
     * Enable Drag and Drop
     * @type {boolean}
     */
    enableDnd: true,

    //private properties
    _isDetailMode: false,

    _isManageMode: false,

    startDelays: [],

    initialize: function(options) {
        CompositeView.prototype.initialize.apply(this, arguments);

        // By default, CompositeViews on re-render children when the
        // collection resets. The list view, however, requires a full
        // re-render because the template distinguishes between empty and
        // non-empty cases.
        this.stopListening(this.collection, 'reset', this._renderChildren);
        this.listenTo(this.collection, 'reset', this.render);

        if (this.model.get('activeModel')) {
            this.toggleDetailMode(true);
        }

        this.listenTo(App.vent, 'scrollContainerScroll', this.onLayoutScrollContainerScroll.bind(this));
        this.listenTo(App.vent, 'keydown', this.handleKeyDown.bind(this));
    },

    emptyViewOptions: function() {
        return {
            collection: this.collection,
            model: this.model,
            viewMode: 'list',
        };
    },

    /**
     * Options to pass to childView
     * @param [object} model The model
     * @param {int}    index The index of the model
     * @returns {object} The child view options
     */
    childViewOptions: function(model, index) {
        return {
            viewMode: 'list',

            fields: this.collection.catalog.getActiveTableFields(),

            isManageMode: this.isManageMode.bind(this), //can change after child render. so needs to be a function

            isDetailMode: this.isDetailMode.bind(this), //can change after child render. so needs to be a function

            state: this.model,

            isActive: this.model.get('activeModel') ? this.model.get('activeModel').id === model.id : false,
        };
    },

    templateHelpers: function() {
        return {
            fields: this.collection.catalog.getActiveTableFields(),
        };
    },

    /**
     * Callback for redering process
     * @return {void}
     */
    onBeforeRender: function() {
        CompositeView.prototype.onBeforeRender.apply(this, arguments);

        this.scrollPosition = $('#content').scrollTop();
    },

    onLayoutScrollContainerScroll: function(event) {
        if (!_.isObject(this.ui.mobileBackToTop) || this.isDestroyed) {
            return;
        }

        if ($(event.currentTarget).scrollTop() > 250) {
            //btn-back-to-top
            this.ui.mobileBackToTop.addClass('is-visible');
        } else {
            this.ui.mobileBackToTop.removeClass('is-visible');
        }
    },

    /**
     * Callback for redering process
     * @return {void}
     */
    onRender: function() {
        CompositeView.prototype.onRender.apply(this, arguments);

        this.startDelays.push(_.delay(function() {
            this.calcScrollbar();

            this.ui.viewmodeList.on('scroll', function(event) {
                this.onViewmodeListScroll(event);
            }.bind(this));

            this.ui.scrollbarContainer.on('scroll', function() {
                this.onScrollbarScroll(event);
            }.bind(this));
        }.bind(this), 100));
    },

    calcScrollbar: function(){
        this.ui.scrollbarContainerInner.css('width', (this.ui.table.width()) + 'px');
    },

    onShow: function () {
        // On first render, attempt to set scroll position after CSS
        // transition has finished.
        // @todo: Set scroll position on CSS transition end event
        setTimeout(function () {
            var relatedScrollContainer = $('.list-layout-scroll-container').length
                ? '.list-layout-scroll-container'
                : '#content';

            if (this.getOption('enableDnd') && !App.isShare()) {
                this.$el.humansDrag({
                    delegate: '.box:not(.box-empty)',
                    offset: [5, 5],
                    scrollContainer: [relatedScrollContainer, '#navigationSets'],
                    helperClass: 'box-drag-helper',
                    revertClass: 'box-drag-revert',
                    dropClass: 'box-drag-dropped',
                    overDropClass: 'box-drag-overdrop',
                    onDragStart: function (event, humansDrag, element, helper) {
                        // Disable the default link behavior
                        $('html').addClass('has-active-drag has-active-drag-object');
                        element.addClass('box-drag-placeholder');
                        helper.removeAttr('href');
                    }.bind(this),
                    onDragEnd: function (event, humansDrag, element, helper) {
                        $('html').removeClass('has-active-drag has-active-drag-object');

                        // Wait, till element was dropped
                        if (!humansDrag.wasDropped) {
                            window.setTimeout(function () {
                                element.removeClass('box-drag-placeholder');
                            }, 400);
                        } else {
                            element.removeClass('box-drag-placeholder');
                        }
                    }.bind(this),
                    onDisable: function (humansDrag) {
                        this.$('.box:not(.box-empty)').removeClass('box-drag-placeholder');
                    }.bind(this),
                });
            }

            this.setListScrollPosition();
        }.bind(this), 400);
    },

    onViewmodeListScroll: function(event){
        if (this.ui.viewmodeList.scrollLeft() != this.ui.scrollbarContainer.scrollLeft()) {
            this.ui.scrollbarContainer.scrollLeft(this.ui.viewmodeList.scrollLeft());
        }
    },

    onScrollbarScroll: function(event){
        if (this.ui.viewmodeList.scrollLeft() != this.ui.scrollbarContainer.scrollLeft()) {
            this.ui.viewmodeList.scrollLeft(this.ui.scrollbarContainer.scrollLeft());
        }
    },

    /**
     * Is called, everytime the children are rendered.
     * We will set correct sorts for ListView and GridView
     * @return {void}
     */
    onRenderCollection: function() {
        this.model.trigger('change:sortList', this.model, this.model.get('sortList'));

        // Set scrollposition
        $('#content').scrollTop(this.scrollPosition);
    },

    /**
     *
     * @param event
     */
    selectOverlayClick: function(event) {
        $(event.currentTarget).next()[0].focus();
    },

    /**
     * List sort has changed
     * @param {object} event The event object
     * @return {void}
     */
    listSortChange: function(event) {
        var $curTarget = $(event.currentTarget);
        var newSort = $curTarget.attr('data-sortkey') + '.' + $curTarget.attr('data-sortdir');
        this.model.save('sortList', newSort);
        this.triggerMethod('change:sort', newSort);
    },

    sortListChanged: function (model, sortList) {
        this.ui.tableLabel.removeClass('sort-active').attr('data-sortdir', 'asc');
        var sort = sortList.split('.');
        var dir = (sort[1] || 'asc') === 'asc' ? 'desc' : 'asc';

        this.ui.tableLabel.filter('[data-sortkey="' + sort[0] + '"]')
            .addClass('sort-active')
            .attr('data-sortdir', dir);
    },

    controlModeChanged: function(model, controlMode) {
        var isManage = controlMode === 'manage';
        this.ui.listingContainer.toggleClass('is-manage-mode', isManage);
        this._isManageMode = isManage;

        // Disable DND operations
        this.$el.humansDrag(isManage ? 'disable' : 'enable');
    },

    /**
     * Window resize callback
     */
    onResize: function() {
        this.calcScrollbar();
    },

    /**
     * Destroy plugins and additional listener
     */
    onBeforeDestroy: function() {
        // Make sure, view is not destroyed yet
        if (this.isDestroyed) {
            return;
        }

        // clear the start-delays
        // it could be possible, taht these are not fireed till now
        _.each(this.startDelays, function(timerId){
            clearTimeout(timerId);
        });

        this.startDelays = [];

        // X-Scroll
        this.ui.viewmodeList.off('scroll');
        this.ui.scrollbarContainer.off('scroll');

        this.$el.humansDrag('destroy');

        // Remove lazyrender hints
        $('body').removeClass('is-lazyrender-not-top');
    },

    /**
     * Update sort, after sync
     */
    updateView: function() {
        this.collection.sort();
    },

    setActiveChildView: function(activeChildView) {
        this.collection.activate(activeChildView.model)
    },

    getActiveChildView: function() {
        var activeModel = this.model.get('activeModel');
        if (activeModel) {
            return this.children.findByModel(activeModel);
        } else {
            return null;
        }
    },

    /**
     * Sets scroll position so that an element is within the viewport.
     * @param {object} options Options
     * @param {jquery} options.scrollContainer Scroll container jQuery reference, defaults to this.getOption('scrollContainer').
     * @param {jquery} options.element Element to scroll to, defaults to this.getActiveChildView().
     * @param {string} options.align Force alignment instead of using element position to calculate alignment. Accepted values: 'top', 'bottom'.
     * @param {string} options.callback Function to call when scrolling is complete or interrupted.
     */
    setListScrollPosition: function(options) {
        options = _.extend({}, options);

        var scrollContainer = options.scrollContainer || this.getOption('scrollContainer');

        var activeChildView = this.getActiveChildView();
        var element = options.element || activeChildView && activeChildView.$el;

        if (scrollContainer && element && element.length > 0) {
            var scrollElementHeight = scrollContainer.height();
            var elementTopOffset = element.position().top;
            var elementHeight = element.outerHeight();

            var scrollTop = null;

            if (options.align === 'top' || !options.align && elementTopOffset < 0) {
                scrollTop = scrollContainer.scrollTop() + elementTopOffset - 110;
            } else if (options.align === 'bottom' || !options.align && elementTopOffset + elementHeight > scrollElementHeight) {
                scrollTop = scrollContainer.scrollTop() + elementTopOffset + elementHeight - scrollElementHeight + 40;
            }

            if (scrollTop !== null) {
                if (browser.isIos) {
                    scrollContainer.scrollTop(scrollTop);
                    if (_.isFunction(options.callback)) {
                        clearTimeout(this.timeoutSetListScrollPosition);
                        this.timeoutSetListScrollPosition = setTimeout(options.callback, 300);
                    }
                } else {
                    scrollContainer.stop().animate({scrollTop: scrollTop}, {duration: 200, always: options.callback});
                }
            } else {
                if (_.isFunction(options.callback)) {
                    options.callback.apply(this, arguments);
                }
            }
        } else {
            if (_.isFunction(options.callback)) {
                options.callback.apply(this, arguments);
            }
        }
    },

    isManageMode: function() {
        return this._isManageMode;
    },

    isDetailMode: function() {
        return this._isDetailMode;
    },

    activeModelChanged: function (params, activeModel) {
        this.toggleDetailMode(activeModel != null);

        this.children.each(function(childView){
            if (childView.model === activeModel) {
                childView.activate && childView.activate(); //virtual item does not have activate/deactivate
            } else {
                childView.deactivate && childView.deactivate();
            }
        });

        var element, eventName;
        if (activeModel) {
            // Scroll to new active model
            eventName = 'show:extra-content';
        } else {
            // Restore scroll position
            eventName = 'hide:extra-content';
            element = this.findElementClosestToViewportCentre();
        }

        this.lazyRender = false;
        this.stopListeningToScroll();

        var reEnableScroll = function(paramElement) {
            this.setListScrollPosition({
                element: paramElement,
                callback: function() {
                    this.listenToScroll();
                    this.lazyRender = true;
                }.bind(this),
            });
        }.bind(this);

        if (this._firstTimeDetailMode) {
            this.listenToOnce(App.vent, eventName, function() {
                reEnableScroll(element);
            }.bind(this));
        } else {
            reEnableScroll(element);
        }
    },

    toggleDetailMode: function(flag) {
        var wasDetailMode = this._isDetailMode;

        if (_.isUndefined(flag)) {
            this._isDetailMode = !this._isDetailMode;
        } else {
            this._isDetailMode = !!flag;
        }

        if (wasDetailMode === false && this._isDetailMode === true) {
            this._firstTimeDetailMode = true;
        } else {
            this._firstTimeDetailMode = false;
        }

        // Disable DND operations
        this.$el.humansDrag(this._isDetailMode ? 'disable' : 'enable');

        return this;
    },

    showCollection: function () {
        $('body').toggleClass('is-lazyrender-not-top', this.lazyRenderPage > 1);

        return CompositeView.prototype.showCollection.apply(this, arguments);
    },

    findElementClosestToViewportCentre: function () {
        var containerHeight = this.getOption('scrollContainer').height();
        var targetPos = containerHeight / 2;
        var els = this.getChildrenEl();

        var bestElement = _.reduce(els, function (best, el) {
            var $el = $(el);
            var pos = $el.offset().top + $el.outerHeight() / 2;
            var distance = Math.abs(targetPos - pos);

            if (!best || distance < best.distance) {
                best = {
                    el: $el,
                    distance: distance,
                };
            }

            return best;
        }, null);

        return bestElement.el;
    },

    /**
     * Scrolls the list to top and shows the first elements
     * @param {object} event
     */
    mobileBackToTop: function(event) {
        this.lazyRenderPage = 0;
        this.showCollection(0);
        $('.list-layout-scroll-container').stop().animate({scrollTop: 0});
    },

    handleKeyDown: function(event) {
        var state = this.model;
        var activeModel = state.get('activeModel');

        if (activeModel && !App.hasModal()) { //only in detail mode, when no modals are open
            if (event.which === App.keys.UPARROW) {
                var previousModel = activeModel && this.collection.getPreviousModel(activeModel); //@todo Why is activeModel.collection different from this.collection?
                var previousModelUrl = previousModel ? state.getPath(previousModel) : null;

                if (previousModelUrl) {
                    App.router.navigate(previousModelUrl, {trigger: true});
                }
            }

            if (event.which === App.keys.DOWNARROW) {
                var nextModel = activeModel && this.collection.getNextModel(activeModel);
                var nextModelUrl = nextModel ? state.getPath(nextModel) : null;

                if (nextModelUrl) {
                    App.router.navigate(nextModelUrl, {trigger: true});
                }
            }
        }
    },

});

module.exports = ListTableView;