module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (view.getOption('talkType') === 'contacts') { 
__p+='\n    <div class="nav-talk-image">\n        <img src="'+
((__t=( imagePath(item.profilePicture, imageSize('thumbnailFixed')) ))==null?'':__t)+
'">\n    </div>\n    <h4>'+
((__t=( item.name || item.email ))==null?'':_.escape(__t))+
'</h4>\n';
 } else { 
__p+='\n    <div class="nav-talk-image nav-talk-image--group">\n    ';
 for (var i = 0; i < Math.min(4, item.users.length); i++) { 
__p+='\n        <div class="contact-image">\n            <span class="contact-initials">'+
((__t=(_.initials(item.users[i].name)))==null?'':_.escape(__t))+
'</span>\n            ';
 if (item.users[i].profilePicture) { 
__p+='\n                <img src="'+
((__t=( imagePath(item.users[i].profilePicture, imageSize('thumbnailFixed')) ))==null?'':__t)+
'" />\n            ';
 } 
__p+='\n        </div>\n    ';
 } 
__p+='\n    </div>\n    <h4>'+
((__t=( item.name || item.email ))==null?'':_.escape(__t))+
'</h4>\n';
 } 
__p+='';
}
return __p;
};