'use strict';

var ItemView = require('base/Colido.ItemView');

var template = require('modules/collection/templates/partials/view-switch.hbs');

var ViewSwitchView = ItemView.extend({
    template: template,

    className: 'view-switch',

    ui: {
        'viewOption': 'input',
    },

    events: {
        'click @ui.viewOption': 'changeCurrentView',
    },

    /**
     * Changes the view of the list
     * @param {object} event The event object
     * @return {void}
     */
    changeCurrentView: function(event) {
        _.delay(function(){
            var viewMode = $(event.currentTarget).val();

            // Save default viewmode
            this.model.save({viewMode: viewMode});

            // Save special viewmode
            if (viewMode === 'showroom-grid') {
                var parentView = this.getOption('parentView');
                if (parentView) {
                    parentView.triggerMethod('switched:to:showroom', 'showroom-grid');
                }
            } else {
                this.model.save({viewModeDefault: viewMode});
            }

            this.model.set('controlMode', 'default');

            if (this.ui.viewOption.blur) {
                this.ui.viewOption.blur();
            }

            // without this delay, the :active/:hover-state of the label on ipad would not be rendered
        }.bind(this), 100);
    },
});

module.exports = ViewSwitchView;