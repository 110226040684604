module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header">\n    <ul>\n        <li>\n            <h2>'+
((__t=(ifelse(item.forSale, t('collection::shop:sell-modal:withdraw:headline'), t('collection::shop:sell-modal:sale:headline'))))==null?'':_.escape(__t))+
'</h2>\n        </li>\n        <li class="actions">\n            <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('global::modal:button:close')))==null?'':_.escape(__t))+
'</button>\n        </li>\n    </ul>\n</header>\n\n<div class="modal-body">\n    <div class="sell-item">\n        <div class="box box-object-object">\n            ';
 if (item.objectId) { 
__p+='\n                <h5 class="object-id">'+
((__t=(item.objectId))==null?'':_.escape(__t))+
'</h5>\n            ';
 } 
__p+='\n            <div class="headline-wrapper">\n                <h4>'+
((__t=( item.headline))==null?'':__t)+
'</h4>\n                <h4 class="subline">'+
((__t=( item.subheadline))==null?'':__t)+
'</h4>\n            </div>\n\n            <div class="image">\n                <div class="image-3-2">\n                    <div>\n                        '+
((__t=( responsiveImage(model.getImage(0), imageSize('grid-object'), viewportSize('grid-object'), item.properName, 'grid-image') ))==null?'':__t)+
'\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="sell-options">\n        <form>\n            ';
 if(item.forSale === false) { 
__p+='\n                <div class="retailPrice">\n                    <fieldset>\n                        <div class="input-label-group">\n                            <input type="number"\n                                   id="field-'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'"\n                                   step="any"\n                                   name="'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'"\n                                   value="'+
((__t=(model.get(item.field.name)))==null?'':_.escape(__t))+
'"\n                                   placeholder="'+
((__t=(item.field.placeholder))==null?'':_.escape(__t))+
'"\n                                   class=""\n                                   />\n                            <label data-position="left" for="field-'+
((__t=(item.field.name))==null?'':_.escape(__t))+
'">'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</label>\n                        </div>\n                    </fieldset>\n\n                    <select name="'+
((__t=(item.field.unitField))==null?'':_.escape(__t))+
'" class="select-secondary"></select>\n                </div>\n\n                <fieldset class="submit">\n                    <button type="submit" class="btn btn-primary" data-action="sell">'+
((__t=(t('collection::shop:sell-modal:sell-button')))==null?'':_.escape(__t))+
'</button>\n                    <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('collection::shop:sell-modal:do-not-sell-button')))==null?'':_.escape(__t))+
'</button>\n                </fieldset>\n\n                <p class="small-print">\n                    '+
((__t=(t('collection::shop:sell-modal:sell-hint')))==null?'':_.escape(__t))+
'\n                </p>\n            ';
 } else { 
__p+='\n                <fieldset class="submit">\n                    <button type="button" class="btn btn-primary" data-action="withdraw">'+
((__t=(t('collection::shop:sell-modal:withdraw-from-sale-button')))==null?'':_.escape(__t))+
'</button>\n                </fieldset>\n\n                <p class="small-print">\n                    '+
((__t=(t('collection::shop:sell-modal:withdraw-from-sale-hint')))==null?'':_.escape(__t))+
'\n                </p>\n            ';
 } 
__p+='\n        </form>\n\n    </div>\n</div>';
}
return __p;
};