module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>'+
((__t=( t('subscription::payment:headline:payment-method') ))==null?'':_.escape(__t))+
'</h2>\n\n';
 if (item.paymentMethod) { 
__p+='\n    ';
 if (item.paymentMethod.type === 'creditcard') { 
__p+='\n        <p class="hint">\n            '+
((__t=( t('subscription::payment:text:charged-by-creditcard',{issuer: _.escape(item.paymentMethod.cardType), number: _.escape(item.paymentMethod.info), expires: _.escape(item.paymentMethod.expires)}) ))==null?'':__t)+
'\n        </p>\n    ';
 } else { 
__p+='\n        <p class="hint">'+
((__t=( t('subscription::payment:text:charged-by-paypal', {account: _.escape(item.paymentMethod.info)})))==null?'':__t)+
'</p>\n    ';
 } 
__p+='\n';
 } 
__p+='\n\n<div class="actions">\n    <button class="btn btn-primary" data-action="edit">'+
((__t=( t('subscription::payment:button:change-payment-method') ))==null?'':_.escape(__t))+
'</button>\n</div>';
}
return __p;
};