module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<section class="object-images">\n    <h3>'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</h3>\n\n    <div class="image-slider">\n        ';
 _.each(model.get(item.field.name), function(image, index){ 
__p+='\n            ';
 if (image.restricted !== true){  
__p+='\n                ';
 if (image.videoUrl) { 
__p+='\n                    <div class="video">\n                        <video src="'+
((__t=(image.videoUrl))==null?'':_.escape(__t))+
'" muted autoplay loop poster="'+
((__t=(model.getImageUrl(imageSize('detail'), index)))==null?'':_.escape(__t))+
'">\n                            '+
((__t=( responsiveImage(model.getImage(index), imageSize('detail'), viewportSize('detail'), '', 'detail-image') ))==null?'':__t)+
'\n                        </video>\n                        <button type="button" class="btn btn-play" data-action="play-video">'+
((__t=( icon('play') ))==null?'':__t)+
'</button>\n                    </div>\n                ';
 } else { 
__p+='\n                    <div class="image loading">\n                        '+
((__t=( responsiveImage(model.getImage(index), imageSize('detail'), viewportSize('detail'), '', 'detail-image') ))==null?'':__t)+
'\n                        ';
 if (image.title) { 
__p+='\n                            <caption>'+
((__t=(image.title))==null?'':_.escape(__t))+
'</caption>\n                        ';
 } 
__p+='\n                    </div>\n                ';
 } 
__p+='\n            ';
 } 
__p+='\n        ';
 }) 
__p+='\n    </div>\n\n    ';
 if (model.get(item.field.name).length > 1){ 
__p+='\n        <ul class="image-slider-pager">\n        ';
 _.each(model.get(item.field.name), function(image, index){ 
__p+='\n                ';
 if (image.restricted !== true){ 
__p+='\n                    <li class="image-detail-preview ';
 if (index == 0) { 
__p+='is-active';
 } 
__p+='" data-action="slide-image" data-idx="'+
((__t=( index ))==null?'':_.escape(__t))+
'">\n                        '+
((__t=( responsiveImage(model.getImage(index), imageSize('detail-preview-thumb'), false) ))==null?'':__t)+
'\n                    </li>\n                ';
 } 
__p+='\n        ';
 }) 
__p+='\n        </ul>\n\n        <button type="button" class="slide-prev">'+
((__t=( icon('prev') ))==null?'':__t)+
'</button>\n        <button type="button" class="slide-next">'+
((__t=( icon('next') ))==null?'':__t)+
'</button>\n    ';
 } 
__p+='\n</section>';
}
return __p;
};