module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<aside class="drop-control">\n    ';
 if (model.get('setModel') === true) { 
__p+='\n        '+
((__t=( dropTarget({ size: '1-1', action: 'trash-set', iconName: 'trash', label: App.t('drag-n-drop::trash') }) ))==null?'':__t)+
'\n    ';
 } else if (model.get('setModel') instanceof SetModel) { 
__p+='\n            '+
((__t=( dropTarget({ size: '1-2', action: 'remove', iconName: 'close-light', label: App.t('drag-n-drop::remove-from-set') }) ))==null?'':__t)+
'\n            '+
((__t=( dropTarget({ size: '1-2', action: 'trash', iconName: 'trash', label: App.t('drag-n-drop::trash') }) ))==null?'':__t)+
'\n    ';
 } else { 
__p+='\n        '+
((__t=( dropTarget({ size: '1-1', action: 'trash', iconName: 'trash', label: App.t('drag-n-drop::trash')}) ))==null?'':__t)+
'\n    ';
 } 
__p+='\n</aside>';
}
return __p;
};