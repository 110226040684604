module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<input type="checkbox" id="toggle-share-field-'+
((__t=(item.name))==null?'':_.escape(__t))+
'" '+
((__t=(when(item.active, 'checked="checked"')))==null?'':_.escape(__t))+
' value="'+
((__t=(item.name))==null?'':_.escape(__t))+
'" />\n<label for="toggle-share-field-'+
((__t=(item.name))==null?'':_.escape(__t))+
'">'+
((__t=(item.label))==null?'':_.escape(__t))+
'</label>';
}
return __p;
};