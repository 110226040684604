module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="image">\n    <button type="button" class="btn btn-right btn-white" data-action="remove-image">\n        '+
((__t=( icon('close-light') ))==null?'':__t)+
'\n    </button>\n\n    <img src="'+
((__t=( model.getImageUrl(imageSize('smedium')) ))==null?'':__t)+
'" class="preview-image" />\n\n    ';
 if (item.uploading) { 
__p+='\n        <progress  class="upload-progress" value="'+
((__t=(item.percentCompleted))==null?'':_.escape(__t))+
'" max="100">\n    ';
 } 
__p+='\n\n    ';
 if (item.importing) { 
__p+='\n        <div class="import-progress"><div></div></div>\n    ';
 } 
__p+='\n</div>';
}
return __p;
};