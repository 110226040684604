module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=''+
((__t=( item.file.filename ))==null?'':_.escape(__t))+
'\n<span class="import-row-count">\n	'+
((__t=( ifelse(item.file.rows < 250, t('settings::import:object:count', item.file.rows), t('settings::import:object:count:estimated', item.file.rows))))==null?'':_.escape(__t))+
'\n</span>\n<span class="import-state">'+
((__t=( t('settings::import:state:' + item.state) ))==null?'':_.escape(__t))+
'</span>';
}
return __p;
};