module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (item.buyerId === App.user.id) { 
__p+='\n	<p>\n		'+
((__t=( t('community::shop:post:offer-completed:buyer', {targetObjectLink: item.targetObjectLink, targetCatalogLink: item.targetCatalogLink})))==null?'':__t)+
'\n	</p>\n';
 } 
__p+='\n\n';
 if (item.sellerId === App.user.id) { 
__p+='\n	<p>\n		'+
((__t=(t('community::shop:post:offer-completed:seller')))==null?'':_.escape(__t))+
'\n	</p>\n';
 } 
__p+='';
}
return __p;
};