module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>'+
((__t=(t('subscription::setup:tax:title')))==null?'':_.escape(__t))+
'</h2>\n\n<p class="hint">\n	';
 if (item.residencyCountry.length) { 
__p+='\n			'+
((__t=(t('subscription::payment:text:country-of-residency', {residencyCountry: view.getCountryLabel(item.residencyCountry)}) ))==null?'':_.escape(__t))+
'\n	';
 } else { 
__p+='\n            '+
((__t=(t('subscription::setup:tax:no-country-selected')))==null?'':_.escape(__t))+
'\n	';
 } 
__p+='\n</p>\n\n<div class="actions">\n    <button class="btn btn-primary" data-action="edit">'+
((__t=( t('subscription::setup:tax:change-country') ))==null?'':_.escape(__t))+
'</button>\n</div>';
}
return __p;
};