module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="post-creation-day">\n    <span>'+
((__t=(t('global::relative-date:today')))==null?'':_.escape(__t))+
'</span>\n</div>\n\n'+
((__t=( multiline(t('community::conversation-post:empty'))))==null?'':__t)+
'';
}
return __p;
};