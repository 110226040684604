module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header">\n	<ul>\n		<li>\n    		<h2>'+
((__t=( t('collection::shop:seller-modal:headline')))==null?'':__t)+
'</h2>\n		</li>\n		<li class="actions">\n    		<button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('global::modal:button:close')))==null?'':_.escape(__t))+
'</button>\n		</li>\n	</ul>\n</header>\n\n<div class="modal-body" style="display:block">\n    ';
 if (item.sellerProfile) { 
__p+='\n        <section>\n        <p>\n        ';
 if (item.sellerProfile.sellerContact) { 
__p+='\n                '+
((__t=( item.sellerProfile.sellerContact))==null?'':__t)+
'<br>\n        ';
 } 
__p+='\n        ';
 if (item.sellerProfile.sellerCompany) { 
__p+='\n                '+
((__t=( item.sellerProfile.sellerCompany))==null?'':__t)+
'\n        ';
 } 
__p+='\n        </p>\n        </section>\n        ';
 if (item.sellerProfile.sellerAddress) { 
__p+='\n            <section>\n                <p>'+
((__t=( item.sellerProfile.sellerAddress))==null?'':__t)+
'\n                    <br>'+
((__t=( item.sellerProfile.sellerPlz?item.sellerProfile.sellerPlz+' ':''))==null?'':__t)+
''+
((__t=( item.sellerProfile.sellerCity))==null?'':__t)+
'\n                    ';
 if (item.sellerProfile.sellerCountry) { 
__p+='\n                        <br>'+
((__t=( item.sellerProfile.sellerCountry))==null?'':__t)+
'\n                    ';
 } 
__p+='\n                </p>\n            </section>\n        ';
 } 
__p+='\n            <section>\n                <p>\n                ';
 if (item.sellerProfile.sellerEmail) { 
__p+='\n                    <a href="mailto:'+
((__t=( item.sellerProfile.sellerEmail))==null?'':__t)+
'">'+
((__t=( item.sellerProfile.sellerEmail))==null?'':__t)+
'</a><br>\n               ';
 } 
__p+='\n                ';
 if (item.sellerProfile.sellerWww) { 
__p+='\n                    <a href="http://'+
((__t=( item.sellerProfile.sellerWww))==null?'':__t)+
'">'+
((__t=( item.sellerProfile.sellerWww))==null?'':__t)+
'</a><br>\n                ';
 } 
__p+='\n                ';
 if (item.sellerProfile.sellerPhone) { 
__p+='\n                    '+
((__t=( item.sellerProfile.sellerPhone))==null?'':__t)+
'\n                ';
 } 
__p+='\n                </p>\n            </section>\n\n        ';
 if (item.sellerProfile.sellerRegistration) { 
__p+='\n            <section>\n                <p>'+
((__t=( item.sellerProfile.sellerRegistration))==null?'':__t)+
'<br>\n                '+
((__t=( item.sellerProfile.sellerRegistrationOffice))==null?'':__t)+
'</p>\n            </section>\n        ';
 } 
__p+='\n        ';
 if (item.sellerProfile.sellerUid) { 
__p+='\n            <section>\n                <p>'+
((__t=( item.sellerProfile.sellerUid))==null?'':__t)+
'</p>\n            </section>\n        ';
 } 
__p+='\n        ';
 if (item.sellerProfile.sellerAgb) { 
__p+='\n            <section>\n                <p><a href="http://'+
((__t=( item.sellerProfile.sellerAgb))==null?'':__t)+
'">'+
((__t=( t('collection::shop:seller-modal:agb') ))==null?'':_.escape(__t))+
'</a></p>\n            </section>\n        ';
 } 
__p+='\n        ';
 if (item.sellerProfile.sellerDse) { 
__p+='\n            <section>\n                <p><a href="http://'+
((__t=( item.sellerProfile.sellerDse))==null?'':__t)+
'">'+
((__t=( t('collection::shop:seller-modal:dse') ))==null?'':_.escape(__t))+
'</a></p>\n            </section>\n        ';
 } 
__p+='\n        ';
 if (item.sellerProfile.sellerNotes) { 
__p+='\n            <section>\n                <p>'+
((__t=( item.sellerProfile.sellerNotes))==null?'':__t)+
'</p>\n            </section>\n        ';
 } 
__p+='\n    ';
 } else { 
__p+='\n        <section>\n            <p>'+
((__t=( t('collection::shop:seller-modal:no-data-text')))==null?'':__t)+
'</p>\n        </section>\n    ';
 } 
__p+='\n\n</div>';
}
return __p;
};