module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="div-form-login" class="div-form">\n    <h3>'+
((__t=(t('login::already-registered')))==null?'':_.escape(__t))+
'</h3>\n    <a href="/login" class="btn btn-secondary">'+
((__t=(t('login::to-the-login')))==null?'':_.escape(__t))+
'</a>\n</div>\n\n<div class="login-container">\n	<form class="login-form" method="post">\n	    <h2>'+
((__t=(t('login::headline')))==null?'':_.escape(__t))+
'</h2>\n	        <fieldset ';
 if (error.email) { 
__p+='class="error"';
 } 
__p+='>\n	    <input name="email" type="email" value="'+
((__t=(item.email))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('login::placeholder-email')))==null?'':_.escape(__t))+
'" tabindex="1">\n        ';
 if (error.email) { 
__p+='\n	        <p class="error"> '+
((__t=(error.email))==null?'':_.escape(__t))+
' </p>\n	    ';
 } 
__p+='\n	</fieldset>\n\n	<fieldset ';
 if (error.password) { 
__p+='class="error"';
 } 
__p+='>\n	    <input name="password" type="password" value="'+
((__t=(item.password))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=(t('login::placeholder-password')))==null?'':_.escape(__t))+
'" tabindex="2">\n        ';
 if (error.password) { 
__p+='\n	        <p class="error"> '+
((__t=(error.password))==null?'':_.escape(__t))+
'</p>\n	    ';
 } 
__p+='\n	</fieldset>\n\n    <fieldset class="submit">\n        <button type="submit" class="btn btn-primary" tabindex="3">'+
((__t=(t('login::sing-in-button-label')))==null?'':_.escape(__t))+
'</button>\n        ';
 if (Colido.config.allowSignup) { 
__p+='\n            <a href="/forgot-password" class="btn btn-text forgotPassword">'+
((__t=(t('login::forgot-password')))==null?'':_.escape(__t))+
'</a>\n        ';
 } 
__p+='\n    </fieldset>\n</form>\n</div>\n\n<div class="language-container">\n    <div class="option-select">\n        <input id="languageEnglish" type="radio" name="language" value="en">\n        <label for="languageEnglish" class="option-select-secondary option-select-xs">En<span>glish</span></label>\n\n        <input id="languageDeutsch" type="radio" name="language" value="de">\n        <label for="languageDeutsch" class="option-select-secondary option-select-xs">De<span>utsch</span></label>\n    </div>\n</div>';
}
return __p;
};