module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>'+
((__t=( t('settings::subscription:your-subscription:title') ))==null?'':_.escape(__t))+
'</h2>\n<div class="subscription-introduction-default">\n    ';
 if(item.freeTrialExpired) { 
__p+='\n        '+
((__t=( multiline(t('settings::subscription:your-subscription:text')) ))==null?'':__t)+
'\n    ';
 } else { 
__p+='\n        '+
((__t=( multiline(t('settings::subscription:free-trial:text', {freeTrialExpiresAt: date(item.freeTrialExpiresAt)})) ))==null?'':__t)+
'\n    ';
 } 
__p+='\n\n    <a href="/account/subscription/setup" class="btn btn-primary">\n        '+
((__t=(t('settings::subscription:button:setup-subscription')))==null?'':_.escape(__t))+
'\n    </a>\n</div>\n\n<div class="subscription-introduction-mobile">\n    ';
 if(item.freeTrialExpired) { 
__p+='\n        '+
((__t=( multiline(t('settings::subscription:your-subscription:text-mobile')) ))==null?'':__t)+
'\n    ';
 } else { 
__p+='\n        '+
((__t=( multiline(t('settings::subscription:free-trial:text-mobile', {freeTrialExpiresAt: date(item.freeTrialExpiresAt)})) ))==null?'':__t)+
'\n    ';
 } 
__p+='\n</div>';
}
return __p;
};