'use strict';

var ItemView = require('base/Colido.ItemView');

var template = require('modules/collection/templates/partials/change-status-recipient-item.hbs');

var ChangeStatusRecipientItemView = ItemView.extend({
    template: template,

    wrapItem: false,

    ui: {
        inputRecipient: 'input[name="status-recipient"]',
        buttonChangeStatus: 'button[data-action="change-status"]',
    },

    events: {
        'click @ui.buttonChangeStatus': 'onChangeStatus',
    },

    /**
     * Is triggered, when change status button is clicked
     */
    onChangeStatus: function() {
        var level = this.$el.parents('.lane').data('level');
        var recipient = this.ui.inputRecipient.val();

        this.triggerMethod('status:selected', level, this.model, recipient);
    },
});

module.exports = ChangeStatusRecipientItemView;