module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(t('collection::objects:filter:material:title')))==null?'':_.escape(__t))+
'</h3>\n\n<form>\n    <fieldset class="filter-buttons component-filter-buttons">\n        <legend>'+
((__t=(t('collection::objects:filter:material:common')))==null?'':_.escape(__t))+
'</legend>\n        ';
 for (var i = 0; i < item.commonSpecies.length; i++) { 
__p+='\n            <fieldset class="filter-button">\n                <input type="checkbox"\n                    id="objects-filter-common-species-'+
((__t=( item.commonSpecies[i].type ))==null?'':_.escape(__t))+
'"\n                    value="'+
((__t=( item.commonSpecies[i].type ))==null?'':_.escape(__t))+
'"\n                    />\n                <label for="objects-filter-common-species-'+
((__t=( item.commonSpecies[i].type ))==null?'':_.escape(__t))+
'">\n                    '+
((__t=( item.commonSpecies[i].displayName ))==null?'':_.escape(__t))+
'\n                </label>\n            </fieldset>\n        ';
 } 
__p+='\n    </fieldset>\n\n    <fieldset class="filter-buttons component-filter-buttons">\n        <legend>'+
((__t=(t('collection::objects:filter:material:recent')))==null?'':_.escape(__t))+
'</legend>\n        <div class="region-recent-list"></div>\n    </fieldset>\n    <fieldset class="filter-buttons">\n        <legend>'+
((__t=(t('collection::objects:filter:material:search')))==null?'':_.escape(__t))+
'</legend>\n        <input type="text" name="search-species" id="search-species" placeholder="'+
((__t=(t('collection::objects:filter:material:search:placeholder')))==null?'':_.escape(__t))+
'">\n    </fieldset>\n</form>';
}
return __p;
};