'use strict';

var CollectionView = require('base/Colido.CollectionView');
var ShareSetView = require('./share-set');
var EmptyView = require('./share-sets-empty');

var ShareSetsView = CollectionView.extend({
    tagName: 'ul',

    className: 'share-sets-list',

    emptyView: EmptyView,

    childView: ShareSetView,

    conversation: null,

    childViewOptions: function() {
        return {
            conversation: this.getOption('conversation'),
        };
    },

    filter: function (model) {
        return model.get('shareToken') !== null;
    },
});

module.exports = ShareSetsView;