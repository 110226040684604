'use strict';

var Controller = require('base/Colido.Controller');
var ParameterizedCollection = require('base/Colido.ParameterizedCollection');
var SettingsObjectListModel = require('./models/settings-object-list');
var ObjectListLayout = require('./views/layout/object-list');
var ScannerObjectListLayout = require('./views/layout/scanner-object-list');
var DetailLayout = require('./views/layout/detail');
var Error404View = require('modules/global/views/layout/error-404');

var CollectionModuleController = Controller.extend({
    routes: {
        'catalogs/:catalogId(/search/:search)(/filter/*filter)(/sort/:sort)(/show/:id)(/scrollTo/:sid)': 'actionList',
        'catalogs/:catalogId(/scanners)(/filter/*filter)(/sort/:sort)(/show/:id)(/scrollTo/:sid)': 'actionListScanners',
        'collection/minerals(/*params)': 'actionForwardLegacyRoutes',
    },

    tagsScanned: [],

    actionForwardLegacyRoutes: function(params) {
        this.onDataReady(App.CollectionModule.catalogs, function() {
            params = params || '';

            var catalog = App.CollectionModule.catalogs.getActiveCatalog();

            if (!catalog) {
                App.router.navigate('/settings/catalogs', {trigger: true});

                return;
            }

            var url = ['/catalogs', catalog.id];

            if (params) {
                url.push(params);
            }

            url = url.join('/');

            App.router.navigate(url, {trigger: true, replace: true});
        });
    },

    actionList: function(catalogId, search, filter, sort, id, sid) {
        this.onDataReady(App.CollectionModule.catalogs, function() {
            var catalog = App.CollectionModule.catalogs.get(catalogId);

            if (!catalog) {
                console.log('@todo: forward to catalog 404');

                return;
            }

            App.requireData([
                catalog.objects,
                catalog.sets,
                catalog.storages,
                App.CollectionModule.speciesCollection,
                App.radio().request('currencies:collection'),
                App.BaseModule.unitsCollection,
            ], function() {
                this.objectsList(catalog.objects, ObjectListLayout, {
                    setModel: null,
                    locationModel: null,
                    importModel: null,
                    importWarningsModel: null,
                    search: search,
                    filter: filter,
                    sort: sort,
                    id: id,
                    sid: sid,
                });
            }.bind(this))
        }.bind(this));
    },

    actionListScanners: function(catalogId, filter, sort, id, sid) {
        this.onDataReady(App.CollectionModule.catalogs, function() {
            var catalog = App.CollectionModule.catalogs.get(catalogId);

            if (!catalog) {
                console.log('@todo: forward to catalog 404');

                return;
            }

            App.requireData([
                catalog.objects,
                catalog.sets,
                catalog.storages,
                App.CollectionModule.speciesCollection,
                App.radio().request('currencies:collection'),
                App.radio().request('scanners:collection'),
                App.BaseModule.unitsCollection,
            ], function() {
                var scannersCollection = App.radio().request('scanners:collection');
                var setModel = new catalog.sets.model();

                if (!filter && !sort && !id) {
                    this.tagsScanned = [];
                }

                scannersCollection.each(function(scanner){
                    this.tagsScanned = _.union(this.tagsScanned, scanner.get('tags'));
                    setModel.set('items', catalog.objects.getObjectIdsByScannerTags(this.tagsScanned));
                }.bind(this));

                this.listenTo(scannersCollection, 'change:tags', function(scannerModel, tags) {
                    this.tagsScanned = _.union(this.tagsScanned, tags);

                    setModel.set('items', catalog.objects.getObjectIdsByScannerTags(this.tagsScanned));
                }.bind(this));

                this.objectsList(catalog.objects, ScannerObjectListLayout, {
                    setModel: setModel,
                    locationModel: null,
                    importModel: null,
                    importWarningsModel: null,
                    scanners: true,
                    filter: filter,
                    sort: sort,
                    id: id,
                    sid: sid,
                });
            }.bind(this))
        }.bind(this));
    },

    objectsList: function(baseCollection, LayoutClass, params) {
        var detailObjectId = params.id;
        var gotoObjectId = params.sid;
        delete params.id;

        params.activeModel = detailObjectId ? baseCollection.get(detailObjectId) : null;
        params.activeModel = gotoObjectId ? baseCollection.get(gotoObjectId) : params.activeModel;
        params.catalog = baseCollection.catalog;

        var SettingsObjectListModelClass = params.overrideSettingsObjectListModel ? params.overrideSettingsObjectListModel : SettingsObjectListModel;
        params =  new SettingsObjectListModelClass(params);

        var filteredCollection = new ParameterizedCollection(undefined, {
            params: params,
            collection: baseCollection,
        });

        // show list
        var contentRegion = App.rootView.mainRegion.currentView.contentRegion;

        if (contentRegion.currentView instanceof LayoutClass && detailObjectId) {
            contentRegion.currentView.model.set('activeModel', params.get('activeModel'));
        } else {
            contentRegion.show(new LayoutClass({
                model: params,
                collection: filteredCollection,
                baseCollection: baseCollection,
            }));
        }

        // show details
        if (detailObjectId) {
            var detailLayout;
            if (params.get('activeModel')) {
                detailLayout = new DetailLayout({
                    model: params.get('activeModel'),
                    params: params,
                    collection: filteredCollection,
                });
            } else {
                detailLayout = new Error404View;
            }

            var extraContentRegion = App.rootView.mainRegion.currentView.extraContentRegion;
            extraContentRegion.show(detailLayout, {additionalClassName: 'collection-object-detail'});
        }

        // scrollTo object
        if (gotoObjectId) {
            var listView = contentRegion.currentView.objectsRegion.currentView;
            var activeModel = listView.children.findByModel(contentRegion.currentView.model.get('activeModel'));
            if (activeModel) {
                listView.setListScrollPosition({
                    element: activeModel.$el,
                    callback: function() {
                        listView.listenToScroll();
                        listView.lazyRender = true;
                    }.bind(listView),
                });
                contentRegion.currentView.model.set('activeModel');
            }
        }
    },
});

module.exports = CollectionModuleController;