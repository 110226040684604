module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li class="catalogs-header">\n    <a href="/settings/catalogs">\n        <span class="nav-icon">'+
((__t=( icon('menu-settings') ))==null?'':__t)+
'</span>'+
((__t=( t('collection::label:catalogs') ))==null?'':_.escape(__t))+
'\n    </a>\n</li>\n<li>\n    <button class="catalog-switcher">\n        '+
((__t=(App.CollectionModule.catalogs.getActiveCatalog().get('name')))==null?'':_.escape(__t))+
'\n    </button>\n    <ul class="catalogs">\n        ';
 App.CollectionModule.catalogs.each(function(catalog){ 
__p+='\n            ';
 if(catalog.id !== App.CollectionModule.catalogs.getActiveCatalog().id) { 
__p+='\n                <li>\n                    <a href="/catalogs/'+
((__t=(catalog.id))==null?'':_.escape(__t))+
'">'+
((__t=(catalog.get('name')))==null?'':_.escape(__t))+
'</a>\n                </li>\n            ';
 } 
__p+='\n        ';
 }) 
__p+='\n    </ul>\n</li>\n\n<li>\n    <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'" class="leave-mobile-menu">\n        <span class="nav-icon">'+
((__t=( icon('menu-catalog') ))==null?'':__t)+
'</span>'+
((__t=( t('collection::label:objects') ))==null?'':_.escape(__t))+
'\n        <span class="count nav-count-objects">'+
((__t=(item.objectsCount))==null?'':_.escape(__t))+
'</span>\n    </a>\n    <div class="subnav-container">\n        <ul class="level-2 level-catalog">\n            <li class="prepend-menu-item">\n                <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'">\n                    '+
((__t=( t('collection::label:full-catalog') ))==null?'':_.escape(__t))+
'\n                </a>\n            </li>\n            ';
 _.each(item.navigationFilteredObjects, function(navigationFilter){ 
__p+='\n                ';
 if (navigationFilter.filterField === 'locality:continent') { 
__p+='\n                    ';
 for (var i = 0; i < App.config.continents.length; i++) { 
__p+='\n                        <li data-count-filter="locality:continent/'+
((__t=(App.config.continents[i].id))==null?'':_.escape(__t))+
'" style="display: none;">\n                            <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'/filter/locality:continent/'+
((__t=(App.config.continents[i].id))==null?'':_.escape(__t))+
'">\n                                '+
((__t=( view.getProperContinentName(App.config.continents[i].name) ))==null?'':_.escape(__t))+
'\n                                <span class="count">0</span>\n                            </a>\n                        </li>\n                    ';
 } 
__p+='\n                ';
 } else { 
__p+='\n                    <li data-count-filter="'+
((__t=(navigationFilter.filterField))==null?'':_.escape(__t))+
'/'+
((__t=(navigationFilter.filterValue))==null?'':_.escape(__t))+
'" style="display: none;">\n                        <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'/filter/'+
((__t=(navigationFilter.filterField))==null?'':_.escape(__t))+
'/'+
((__t=(navigationFilter.filterValue))==null?'':_.escape(__t))+
'">\n                            '+
((__t=(navigationFilter.label))==null?'':_.escape(__t))+
'\n                            <span class="count">0</span>\n                        </a>\n                    </li>\n                ';
 } 
__p+='\n            ';
 }) 
__p+='\n\n            ';
 if (App.user.get('licenseScanners')) { 
__p+='\n                <li>\n                    <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'/scanners/">\n                        '+
((__t=( t('collection::label:scanners') ))==null?'':_.escape(__t))+
'\n                    </a>\n                </li>\n            ';
 } 
__p+='\n        </ul>\n    </div>\n</li>\n\n<li>\n    <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'/sets" class="leave-mobile-menu">\n        <span class="nav-icon">'+
((__t=( icon('menu-sets') ))==null?'':__t)+
'</span>'+
((__t=( t('collection::label:sets') ))==null?'':_.escape(__t))+
'\n        <span class="count nav-count-sets">'+
((__t=(item.setsCount))==null?'':_.escape(__t))+
'</span>\n    </a>\n    <div class="subnav-container navigation-sets-subnav-region"></div>\n</li>\n\n<li>\n    <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'/storages" class="leave-mobile-menu">\n        <span class="nav-icon">'+
((__t=( icon('menu-storages') ))==null?'':__t)+
'</span>'+
((__t=( t('collection::label:storages') ))==null?'':_.escape(__t))+
'\n    </a>\n    <div class="subnav-container navigation-storages-subnav-region"></div>\n</li>\n\n<li data-count-filter="forSale/true">\n    <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'/filter/forSale/true">\n        <span class="nav-icon">'+
((__t=( icon('menu-shop') ))==null?'':__t)+
'</span>'+
((__t=( t('collection::label:your-shop') ))==null?'':_.escape(__t))+
'\n        <span class="count">0</span>\n    </a>\n</li>\n\n\n<li class="secondary">\n    <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'/new">\n        <span class="nav-icon nav-icon-circle">'+
((__t=( icon('menu-new-object') ))==null?'':__t)+
'</span>'+
((__t=( t('new-object::label') ))==null?'':_.escape(__t))+
'\n    </a>\n</li>\n\n<li class="secondary">\n    <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'/imports">\n        <span class="nav-icon">'+
((__t=( icon('menu-import') ))==null?'':__t)+
'</span>'+
((__t=( t('settings::import:label') ))==null?'':_.escape(__t))+
'\n        <span class="nav-info" data-status="none" data-role="import-status">\n            <span class="loading"></span>\n            <span class="count nav-count-imports"></span>\n        </span>\n    </a>\n</li>\n\n<li class="secondary">\n    <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'/trash">\n        <span class="nav-icon">'+
((__t=( icon('menu-trash') ))==null?'':__t)+
'</span>'+
((__t=( t('settings::trash:label') ))==null?'':_.escape(__t))+
'\n    </a>\n</li>\n\n<li class="secondary">\n    <a href="/catalogs/'+
((__t=(item.id))==null?'':_.escape(__t))+
'/preferences">\n        <span class="nav-icon">'+
((__t=( icon('menu-settings') ))==null?'':__t)+
'</span>'+
((__t=( t('settings::preferences:label') ))==null?'':_.escape(__t))+
'\n    </a>\n</li>';
}
return __p;
};