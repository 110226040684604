module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="conversation-header">\n    <a href="'+
((__t=(view.getOption('closeUrl')))==null?'':_.escape(__t))+
'" class="btn btn-back">'+
((__t=( icon('prev') ))==null?'':__t)+
'</a>\n    <a href="'+
((__t=(view.getOption('closeUrl')))==null?'':_.escape(__t))+
'" class="close">'+
((__t=( icon('close-light') ))==null?'':__t)+
' '+
((__t=(t('global::modal:button:close')))==null?'':_.escape(__t))+
'</a>\n\n    <h2 class="conversation-name">\n        ';
 if(model.get('type') === 'contact') { 
__p+='\n            <span class="contact-image">\n                <span class="contact-initials">'+
((__t=(_.initials(model.get('name') || model.get('email'))))==null?'':_.escape(__t))+
'</span>\n                '+
((__t=( responsiveImage(model.get('contact').get('profilePicture'), imageSize('thumbnailFixed'), viewportSize('grid-object'), model.get('name'), 'grid-image') ))==null?'':__t)+
'\n            </span>\n        ';
 } 
__p+='\n        '+
((__t=(model.get('name')))==null?'':_.escape(__t))+
'\n    </h2>\n\n    ';
 if(model.get('type') === 'group' || model.get('type') === 'broadcast') { 
__p+='\n        <div class="group-members-region"></div>\n        <div class="group-actions-region"></div>\n    ';
 } 
__p+='\n</header>\n\n<div class="conversation-posts-region loading"></div>\n\n\n';
 if (model.get('type') === 'contact' && model.get('contact').get('accepted') !== 'YES') { 
__p+='\n    <div class="contact-actions">\n        ';
 if(model.get('contact').get('accepted') === 'INC') { 
__p+='\n            <button data-action="accept-contact" class="btn-accept-contact btn-primary">\n                '+
((__t=(t('community::button:label:friend-request:accept')))==null?'':_.escape(__t))+
'\n            </button>\n        ';
 } 
__p+='\n\n        ';
 if(model.get('contact').get('accepted') === 'OUT') { 
__p+='\n            <p>'+
((__t=(t('community::info:friend-request:sent')))==null?'':_.escape(__t))+
'</p>\n        ';
 } 
__p+='\n\n        <button data-action="remove-contact" class="btn-remove-contact btn-tertiary">\n            '+
((__t=(t('community::button:label:friend-request:remove')))==null?'':_.escape(__t))+
'\n        </button>\n    </div>\n';
 } else { 
__p+='\n    <form class="conversation-form">\n        <fieldset class="share-field">\n            <div class="share-menu">\n                <h4>'+
((__t=(t('community::share-menu:title')))==null?'':_.escape(__t))+
'</h4>\n                <div class="share-sets-region"></div>\n            </div>\n            <button class="btn-show-share-menu" data-action="show-share-menu">\n                '+
((__t=( icon('plus')))==null?'':__t)+
'\n            </button>\n        </fieldset>\n        <fieldset class="message-field">\n            <textarea name="messageInput" rows="1" maxlength="10000" data-autosize="true" placeholder="'+
((__t=(t('community::input:placeholder:new-post')))==null?'':_.escape(__t))+
'"></textarea>\n            <button type="submit" class="btn-primary">'+
((__t=(t('community::button:label:conversation:submit')))==null?'':_.escape(__t))+
'</button>\n        </fieldset>\n    </form>\n';
 } 
__p+='';
}
return __p;
};