'use strict';

var Marionette = require('backbone-marionette');
var Controller = require('./controller');

var HelpdeskModule = Marionette.Module.extend({
    startWithParent: true,

    onStart: function(options) {
        var controller = this.controller = new Controller({
            app: app,
            module: this,
        });

        App.addInitializer(function(){
            App.router.processAppRoutes(controller, controller.routes);
        });
    },
});

module.exports = HelpdeskModule;