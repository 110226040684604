module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="profil-picture">\n\n    <h3>'+
((__t=( t('settings::profile:picture') ))==null?'':_.escape(__t))+
'</h3>\n\n    <div class="page-row">\n        <div class="page-col-2">\n            <fieldset>\n                <div class="image-preview image-preview-1-1">\n                    <label class="btn btn-big-dotted btn-big-plus" data-action="trigger-upload"></label>\n                    <div class="image-preview-content">\n                        <input type="hidden" name="imageId">\n                    </div>\n                    <video class="profile-take-picture-video" autoplay></video>\n                    <canvas class="profile-take-picture-canvas"></canvas>\n                </div>\n            </fieldset>\n        </div>\n\n        <div class="page-col-3 profile-upload-buttons">\n            <div class="image-upload-region"></div>\n\n            <button type="submit" class="btn btn-primary" data-action="take-profile-picture">'+
((__t=( t('settings::profile:picture:take') ))==null?'':_.escape(__t))+
'</button>\n            <button type="submit" class="btn btn-secondary" data-action="delete-image" >'+
((__t=( t('settings::profile:picture:delete') ))==null?'':_.escape(__t))+
'</button>\n            <button type="submit" class="btn btn-primary" data-action="take-profile-picture-snapshot">'+
((__t=( t('settings::profile:picture:take-snapshot') ))==null?'':_.escape(__t))+
'</button>\n\n            <button type="submit" class="btn btn-primary" data-action="take-profile-picture-snapshot-submit">'+
((__t=( t('settings::profile:picture:take-snapshot-submit') ))==null?'':_.escape(__t))+
'</button>\n            <button type="submit" class="btn btn-primary" data-action="take-profile-picture-snapshot-redo">'+
((__t=( t('settings::profile:picture:take-snapshot-redo') ))==null?'':_.escape(__t))+
'</button>\n            <button type="submit" class="btn btn-secondary" data-action="take-profile-picture-snapshot-cancel">'+
((__t=( t('settings::profile:picture:take-snapshot-cancel') ))==null?'':_.escape(__t))+
'</button>\n        </div>\n    </div>\n</div>';
}
return __p;
};