module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(view.getTitle()))==null?'':_.escape(__t))+
'</h3>\n\n<form>\n    <fieldset class="filter-buttons component-filter-buttons">\n        ';
 _.each(item.enumValues, function(enumValue){ 
__p+='\n            <fieldset class="filter-button">\n                <input type="checkbox"\n                    id="objects-filter-size-'+
((__t=(enumValue.name))==null?'':_.escape(__t))+
'"\n                    value="'+
((__t=(enumValue.name))==null?'':_.escape(__t))+
'"\n                    />\n                <label for="objects-filter-size-'+
((__t=(enumValue.name))==null?'':_.escape(__t))+
'">\n                    '+
((__t=(enumValue.label))==null?'':_.escape(__t))+
'\n                </label>\n            </fieldset>\n        ';
 }) 
__p+='\n    </fieldset>\n</form>';
}
return __p;
};