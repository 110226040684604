'use strict';

var LayoutView = require('base/Colido.LayoutView');

var template = require('../../templates/layout/search.hbs');

var SearchView = LayoutView.extend({
    template: template,

    ui: {
        'searchForm': '#search-form',
        'searchInput': '#search-form input',
        'searchTerms': '.search-terms',
        'clearTerm': '[data-action="clear-term"]',
        'clearAll': '[data-action="clear-all"]',
        'searchIcon': 'button[type="submit"]',

        // 'deleteButton': 'header [data-action="delete"]',
        // 'dropTargets': '.drop-target',
        // 'closeShowroom': '[data-action="close-showroom"]',
        // 'viewOption': 'input[name="viewShowroom"]',
    },

    events: {
        'submit @ui.searchForm': 'onSubmit',
        'focus @ui.searchInput': 'expandSearch',
        'click @ui.searchIcon': 'expandSearch',
        'click @ui.clearTerm': 'clearTerm',
        'click @ui.clearAll': 'clearAll',
        'click @ui.searchTerms': 'focusSearchField',
        'keydown @ui.searchInput': 'tempStoreValue',
        'keyup @ui.searchInput': 'checkSearchInputKey',
    },

    initialize: function (options) {
        LayoutView.prototype.initialize.apply(this, arguments);

        if (this.getOption('clearOnInit')
            && this.model.get('search')
            && this.model.get('search').length > 0
        ) {
            this.model.set('search', '', {silent: true});
        }
    },

    onRender: function() {
        var terms = this.model.get('search');
        if (_.isString(terms) && terms.length > 0) {
            terms = _.uniq(terms.split(','));

            var termsList = $('<ul/>');
            terms.forEach(function(term){
                termsList.append($('<li><span class="term">' + _.escape(term) + '</span><button class="clear-term" data-action="clear-term"></button>'));
            });

            this.ui.searchTerms.prepend(termsList);
        }
    },

    onAttach: function () {
        if (this.model.get('search')) {
            this.expandSearch();
        }
    },

    onSubmit: function(event) {
        event ? event.preventDefault() : null;

        var newTerm = this.ui.searchInput.val().trim();
        this.ui.searchInput.val('');
        if (newTerm) {
            this.search(newTerm);
        }
    },

    focusSearchField: function(event) {
        if (event.currentTarget === event.target) {
            this.ui.searchInput.focus();
        }
    },

    search: function(newTerm, options) {
        options = _.extend({}, options);

        var terms = [];
        this.ui.searchTerms.find('li').each(function(index, li){
            terms.push($(li).text())
        });

        if (terms.length === 0 && options.keepSearchFocused) {
            this.model.set('search', true);
        } else {
            if (newTerm) {
                terms.push(encodeURIComponent(newTerm));
            }
            this.model.set('search', _.uniq(terms).join(','));
        }
    },

    clearTerm: function(event) {
        $(event.currentTarget).closest('li').remove();
        this.search();

        event ? event.preventDefault() : null;
    },

    clearAll: function(event) {
        this.ui.searchTerms.find('ul').remove();
        this.ui.searchInput.val('');
        // this.ui.searchInput.blur();
        this.shrinkSearch();
        this.search();

        event ? event.preventDefault() : null;
    },

    expandSearch: function() {
        if (this.ui.searchTerms.find('ul').length) {
            this.ui.searchInput.attr('placeholder', '');
        } else {
            this.ui.searchInput.attr('placeholder', App.t('collection::search:what-are-you-looking-for'));
        }

        if (this.ui.searchInput[0] !== document.activeElement) {
            var searchInput = $(this.ui.searchInput);
            if (searchInput.length) {
                searchInput[0].focus();
            }
        }

        this.$el.closest('header').addClass('search-active');
    },

    shrinkSearch: function() {
        if (this.ui.searchTerms.find('ul').length === 0) {
            this.$el.closest('header').removeClass('search-active');
            this.ui.searchInput.val('');
            this.ui.searchInput.attr('placeholder', 'Search');
        }
    },

    /**
     * Temporarily stores the value of the keyword for backspace action and uses term on tab
     * @param {object} event The keydown event
     */
    tempStoreValue: function(event) {
        if (event.which === App.keys.TAB) {
            this.onSubmit();
        } else {
            var $curTarget = $(event.currentTarget);
            $curTarget.data('val', $curTarget.val());
        }
    },

    /**
     * Removes the last term on backspace
     * @param {object} event The keyup event from the search field
     */
    checkSearchInputKey: function(event) {
        var $curTarget = $(event.currentTarget);
        var dataVal = $curTarget.data('val') || '';
        if (dataVal.length === 0 &&
            event.which === App.keys.BACKSPACE &&
            this.ui.searchTerms.length > 0
        ) {
            this.ui.searchTerms.find('li:last').remove();
            this.search(null, {keepSearchFocused: true});
        }
    },
});

module.exports = SearchView;