'use strict';

var Collection = require('base/Colido.Collection');
var ReceiptModel = require('../models/receipt');

var ReceiptsCollection = Collection.extend({
    api: function() {
        return '/accounts/' + App.user.id + '/receipts';
    },

    name: 'account:receipts',

    model: ReceiptModel,
});

module.exports = ReceiptsCollection;