'use strict';

var HeaderView = require('modules/collection/views/layout/header');

var ObjectDetailHeaderView = HeaderView.extend({
    initialize: function (options) {
        HeaderView.prototype.initialize.apply(this, arguments);
        this.options.viewSwitch = true;
    },

    /**
     *
     * @returns {string} Unescaped Text
     */
    getHeadline: function () {
        var locationModel = this.model.get('locationModel');
        if (locationModel.get('storType') === 'status') {
            return locationModel.get('name');
        } else {
            return locationModel.getBreadcrumb();
        }
    },
});

module.exports = ObjectDetailHeaderView;