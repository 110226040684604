module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<thead>\n    <tr>\n        <th>\n            '+
((__t=( t('settings::trash:table:name') ))==null?'':_.escape(__t))+
'\n        </th>\n        <th>\n            '+
((__t=( t('settings::trash:table:type') ))==null?'':_.escape(__t))+
'\n        </th>\n        <th>\n            '+
((__t=( t('settings::trash:table:date') ))==null?'':_.escape(__t))+
'\n        </th>\n        <th>\n            '+
((__t=( t('settings::trash:table:time') ))==null?'':_.escape(__t))+
'\n        </th>\n        <th>\n            &nbsp;\n        </th>\n    </tr>\n</thead>\n<tbody></tbody>';
}
return __p;
};