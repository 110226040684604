'use strict';

var Marionette = require('backbone-marionette');
var IntercomModule = require('modules/tracking/modules/intercom/module');
var PiwikModule = require('modules/tracking/modules/piwik/module');
var ZendeskModule = require('modules/tracking/modules/zendesk/module');

var TrackingModule = Marionette.Module.extend({
    startWithParent: true,

    onStart: function(options) {
        App.module('TrackingModule.IntercomModule', IntercomModule);
        App.module('TrackingModule.PiwikModule', PiwikModule);
        App.module('TrackingModule.ZendeskModule', ZendeskModule);
    },
});

module.exports = TrackingModule;