module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row row-nospace">\n    <div class="page-col-offset input-help">\n    ';
 if (!item.isGroupChild) { 
__p+='\n        <span class="create-step-number">&nbsp;</span>\n    ';
 } 
__p+='\n        <span class="input-helper">'+
((__t=( icon('info') ))==null?'':__t)+
'</span>\n    </div>\n\n    <div class="page-col-3">\n        <label class="label-headline">'+
((__t=(item.field.label))==null?'':_.escape(__t))+
'</label>\n    </div>\n</div>\n\n';
 _.each(item.field.sourceFields, function(sourceFieldName){ 
__p+='\n    <div class="field-'+
((__t=(_.camelToHyphen(sourceFieldName)))==null?'':_.escape(__t))+
'-region"></div>\n';
 }) 
__p+='';
}
return __p;
};