module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="/catalogs/'+
((__t=(model.collection.catalog.id))==null?'':_.escape(__t))+
'/sets/'+
((__t=(item.id))==null?'':_.escape(__t))+
'">\n    '+
((__t=(item.name))==null?'':_.escape(__t))+
'\n    <span class="count">'+
((__t=(item.items.length))==null?'':_.escape(__t))+
'</span>\n</a>';
}
return __p;
};