module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>'+
((__t=(t('subscription::setup:tax:title')))==null?'':_.escape(__t))+
'</h2>\n\n<div class="hint">\n    '+
((__t=( multiline(t('subscription::setup:tax:eu-hint'))))==null?'':__t)+
'\n</div>\n\n<form>\n    <fieldset>\n        <legend '+
((__t=( when(view.getOption('isSetup'), 'data-step-number="6"')))==null?'':__t)+
'>\n            '+
((__t=(t('subscription::setup:tax:country')))==null?'':_.escape(__t))+
'\n        </legend>\n\n        <fieldset>\n            <select name="subscription-residencyCountry" autocomplete="shipping country">\n                <option value="">'+
((__t=(t('subscription::setup:tax:choose-country')))==null?'':_.escape(__t))+
'</option>\n                ';
 item.countryCollection.each(function(country){ 
__p+='\n                    <option\n                        '+
((__t=(when(country.id === item.residencyCountry, 'selected')))==null?'':_.escape(__t))+
'\n                        value="'+
((__t=(country.id))==null?'':_.escape(__t))+
'">\n                        '+
((__t=(country.get('name')))==null?'':_.escape(__t))+
'\n                    </option>\n                ';
 }) 
__p+='\n            </select>\n        </fieldset>\n        <fieldset class="confirm-checkbox">\n            <input type="checkbox" name="subscription-confirmResidencyCountry" id="subscription-confirmResidencyCountry" /> <label for="subscription-confirmResidencyCountry">'+
((__t=(t('subscription::setup:tax:self-declare-country')))==null?'':_.escape(__t))+
'</label>\n        </fieldset>\n    </fieldset>\n\n    ';
 if (!view.getOption('isSetup')) { 
__p+='\n        <fieldset class="submit">\n                <button type="submit" class="btn btn-primary" disabled>'+
((__t=(t('subscription::setup:tax:save')))==null?'':_.escape(__t))+
'</button>\n        </fieldset>\n    ';
 } 
__p+='\n</form>';
}
return __p;
};