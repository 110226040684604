'use strict';

var Model = require('base/Colido.Model');
var DefaultInputFieldsCollection = require('entities/DefaultInputFieldsCollection');
var DefaultTableColumnCollection = require('entities/DefaultTableColumnCollection');
var Util = require('base/Colido.Util');

var UserModel = Model.extend({
    api: '/users',

    isLoggedIn: false, //true if user data was fetched at least once. this is intentionally not persisted in the user model.

    defaults: {
        fullname: '',
        email: null,
        apiToken: null,
        language: 'en',
        languageIso: 'en',
        storages: [],
        defaultCurrency: 'eur',
        defaultSizeUnit: 'cm',
        defaultWeightUnit: 'g',
        inputFields: [],
        tableFields: null,
        quotaUsage: 0,
        licenseSets: 2,
        licenseImages: 1,
        licenseStorages: false,
        licenseQuota: 262144000, //250 * 1024 * 1024
        licenseFilter: false,
        licenseLabelPrint: false,
        licenseAdvancedLabelPrint: false,
        licensePersonalEmailSupport: false,
        licenseShop: false,
        licenseSeller: false,
        notificationCompanyNews: false,
        notificationProductUpdates: false,
        showShop: true,
    },

    initialize: function() {
        Model.prototype.initialize.apply(this, arguments);

        var existingUserData = localStorage.getItem('colido-user');

        if (existingUserData) {
            this.set(JSON.parse(existingUserData));
        } else {
            this.set('language', Util.getPreferredLanguage());
        }

        this.on('change', this.saveLocal);

        this.listenTo(App.radio('user').vent, 'updated', function(data) {
            this.set(data);
        }.bind(this));

        $(document).ajaxSuccess(function(event, request, settings) {
            var apiLanguage = request.getResponseHeader('Content-Language');
            this.set({language: apiLanguage});
        }.bind(this));
    },

    validate: function(values) {
        var errors = {};

        if (_.isEmpty(values.email) || !values.email.match(/.*@.*/)){
            errors.email = App.t('user::validate:invalid-email');
        }

        return _.isEmpty(errors) ? null : errors;
    },

    saveLocal: function(model) {
        var user = {
            id: this.get('id'),
            fullname: this.get('fullname'),
            email: this.get('email'),
            apiToken: this.get('apiToken'),
            language: this.get('language'),
        };

        try {
            //iOS in private mode lets this thow an exception
            localStorage.setItem('colido-user', JSON.stringify(user));
        } catch(exception) {
            //Do nothing for now. User is still logged in until page refresh.
        }

        // Notify that language was changed
        if (model.changed && model.changed.language) {
            App.radio().trigger('language:changed', model.changed.language);
        }
    },

    hasApiToken: function() {
        return this.get('apiToken') !== null;
    },

    /**
     * Ändert das Passwort des User mit dem übergebenen Passwort.
     *
     * @param oldPassword das alte Passwort als Klartextstring
     * @param password das Passwort als Klartextstring
     * @param success Callback für success
     * @param error Callback für error
     */
    changePassword: function(oldPassword, password, success, error){
        this.save({oldPassword: oldPassword, password: password}, {patch: true, success: success, error: error});
    },

    login: function(callbacks) {
        var success = callbacks && callbacks.success ? callbacks.success : function() {};
        var error = callbacks && callbacks.error ? callbacks.error : function() {};

        if (this.hasApiToken()) {
            $.ajaxSetup({
                headers: {'colido-apitoken': App.user.get('apiToken')},
            });

            this.fetch({
                success: function(model, response, options) {
                    this.isLoggedIn = true;
                    App.vent.trigger('logged-in');
                    success();
                }.bind(this),

                error: function(model, response, options) {
                    error(model, response, options);
                }.bind(this),
            });
        } else {
            error();
        }
    },

    logout: function(reload) {
        if (_.isUndefined(reload)) {
            reload = true;
        }
        this.set('apiToken', null);
        App.router.navigate('login'); //only switch url without triggering
        App.vent.trigger('logged-out');
        if (reload) {
            window.location.reload();
        }
    },

    deleteAccount: function(id, token, successCallback, errorCallback) {
        App.user.logout(false); // make sure, the session is closed

        var data = JSON.stringify({});

        if (!_.isUndefined(token) && token !== false) {
            data = JSON.stringify({
                'token': token,
            });
        }

        // TODO: USE backbonejs
        var jqxhr = $.ajax(
            {
                method: 'DELETE',
                url: App.getApiUrl('/users') + '/' + id,
                contentType: 'application/json',
                processData: false,
                data: data,
            }
        );

        jqxhr.done(successCallback);
        jqxhr.fail(errorCallback);
    },

    /**
     * Returns the language of the user
     * @returns {string} The language of the user
     */
    getLanguage: function() {
        return this.get('language') || Util.getPreferredLanguage();
    },

    getCurrency: function(format) {
        var currency = this.get('defaultCurrency').toLowerCase();

        var catalog = App.CollectionModule.catalogs.getActiveCatalog();

        if (catalog) {
            currency = catalog.get('userAttributes').defaultCurrency;
        }

        if (format) {
            format = _.includes(['short', 'long', 'symbol'], format) ? format : 'symbol';

            return App.t('global::currencies:' + currency + ':symbol');
        }

        return currency;
    },

    customAttributes: {
        inputFields: function() {
            if (_.isUndefined(this._inputField) || this._inputField.length === 0){
                this._initInputFields();
            }

            return this._inputFields;
        },

        tableFields: function() {
            if (_.isUndefined(this._localTableFields) || this._localTableFields.length === 0){
                this._initTableFields();
            }

            return this._localTableFields;
        },

        printFields: function() {
            if (_.isUndefined(this._localPrintFields) || this._localPrintFields.length === 0){
                this._initPrintFields();
            }

            return this._localPrintFields;
        },

        spaceInBytesLeft: function() {
            return this.get('licenseQuota') - this.get('quotaUsage');
        },

        canCreateSet: function () {
            return this.get('licenseSets') > this.get('currentSetCount');
        },

        showShop: function() {
            return App.CollectionModule.ShopModule && this.get('licenseShop') && this.get('showShop', true);
        },
    },

    _initInputFields: function(){
        var inputFields = this.get('inputFields', true); //get native value without going through customAttributes()
        var defaultInputFieldsCollection = App.BaseModule.defaultInputFieldsCollection;

        // merge die vom server erhaltenden Daten mit die der in inputFields. die lokalen Daten haben vorrang
        this._inputFields = this._mergeAndLink(
            inputFields,
            defaultInputFieldsCollection,
            DefaultInputFieldsCollection,
            {
                orderKey: 'groupOrder',
                visibilityKey: 'visibility',
                deactivatableKey: 'deactivatable',
            }
        );

        this._inputFields.sort();

        this.listenTo(defaultInputFieldsCollection, 'add change remove sync', function(){
            this._initInputFields();
        }.bind(this));
    },

    // tableFields werden nicht auf dem Server gespeichert, nur auf dem Client.
    _initTableFields: function(){
        var localTableFields = this._getLocalDefinitionOfTableFields();
        var defaultTableColumnCollection = App.BaseModule.defaultTableColumnCollection;

        // merge die vom server erhaltenden Daten mit die der in localTableFields. die lokalen Daten haben vorrang
        this._localTableFields = this._mergeAndLink(
            localTableFields,
            defaultTableColumnCollection,
            DefaultTableColumnCollection,
            {
                orderKey: 'tableOrder',
                visibilityKey: 'tableVisibility',
                deactivatableKey: 'tableDeactivatable',
            }
        );

        this._localTableFields.sort();

        // falls sich etwas an der Collection ändert, speichere diese Änderung in den Browser-Storage
        if (!_.isUndefined(Storage)) {
            this.listenTo(this._localTableFields, 'add change remove sync', function(){
                localStorage.setItem('_localTableFields', JSON.stringify(this._localTableFields));
            });
        }

        this.listenTo(defaultTableColumnCollection, 'add change remove sync', function(){
            this._initTableFields();
        }.bind(this));
    },

    // tableFields werden nicht auf dem Server gespeichert, nur auf dem Client.
    _initPrintFields: function(){
        var localPrintFields = this._getLocalDefinitionOfPrintFields();
        var defaultPrintColumnCollection = App.BaseModule.printColumnCollection;

        // merge die vom server erhaltenden Daten mit die der in localPrintFields. die lokalen Daten haben vorrang
        this._localPrintFields = this._mergeAndLink(
            localPrintFields,
            defaultPrintColumnCollection,
            DefaultTableColumnCollection,
            {
                orderKey: 'printOrder',
                visibilityKey: 'printVisibility',
                deactivatableKey: 'printDeactivatable',
            }
        );

        this._localPrintFields.sort();

        // falls sich etwas an der Collection ändert, speichere diese Änderung in den Browser-Storage
        if (!_.isUndefined(Storage)) {
            this.listenTo(this._localPrintFields, 'add change remove sync', function(){
                localStorage.setItem('_localPrintFields', JSON.stringify(this._localPrintFields));
            });
        }

        this.listenTo(defaultPrintColumnCollection, 'add change remove sync', function(){
            this._initPrintFields();
        }.bind(this));
    },

    /**
     * Gibt die lokale Definition der TableField wieder.
     *
     * @returns {*}
     * @private
     */
    _getLocalDefinitionOfTableFields: function(){
        var localTableFields = null;

        // falls der Browser Storages unterstützt, versuche diese Daten zu parsen
        if (!_.isUndefined(Storage)) {
            try {
                localTableFields = JSON.parse(localStorage.getItem('_localTableFields'));
            } catch(e) {
                console.error(e); // TODO is debug
            }
        }

        return localTableFields;
    },

    /**
     * Gibt die lokale Definition der PrintField wieder.
     *
     * @returns {*}
     * @private
     */
    _getLocalDefinitionOfPrintFields: function(){
        var localPrintFields = null;

        // falls der Browser Storages unterstützt, versuche diese Daten zu parsen
        if (!_.isUndefined(Storage)) {
            try {
                localPrintFields = JSON.parse(localStorage.getItem('_localPrintFields'));
            } catch(e) {
                console.error(e); // TODO is debug
            }
        }

        return localPrintFields;
    },

    /**
     * Merges the system-default with the user-settings
     *
     * @param userCollection
     * @param serverCollection
     * @param ReturnColumnCollectionClass
     * @param keys
     * @returns {*}
     * @private
     */
    _mergeAndLink: function(userCollection, serverCollection, ReturnColumnCollectionClass, keys){
        var returnColumnCollection = new ReturnColumnCollectionClass();

        var orderKey = keys.orderKey;
        var visibilityKey = keys.visibilityKey;
        var deactivatableKey = keys.deactivatableKey;

        if (_.isUndefined(serverCollection)){
            App.config.debug && console.log('Error: serverCollection is undefined');
        }

        // User gesetzten Felder
        if (!_.isNull(userCollection)){
            _.each(userCollection, function(userColumn){
                var referencesEntity = serverCollection.get(userColumn.key);

                if (referencesEntity){
                    returnColumnCollection.add(this._mergeInputFieldGetOne(
                        referencesEntity,
                        userColumn.order,
                        userColumn.visibility,
                        false,
                        deactivatableKey
                    ));
                }
            }.bind(this));
        }

        // füge die hinzu, auf die nicht vom User referenziert wurden
        serverCollection.each(function(referencesEntity){
            var found = false;
            returnColumnCollection.each(function(find){
                if (find.get('referencesEntity') === referencesEntity){
                    found = true;
                }
            });

            if (!found){
                returnColumnCollection.add(this._mergeInputFieldGetOne(
                    referencesEntity,
                    referencesEntity.get(orderKey),
                    referencesEntity.get(visibilityKey),
                    true,
                    deactivatableKey
                ));
            }
        }.bind(this));

        returnColumnCollection.sort();

        return returnColumnCollection;
    },

    /**
     *
     * @param referencesEntity
     * @param order
     * @param visibility
     * @param newField
     * @param isdeactivatableKey
     * @returns {{key: *, referencesEntity: *, newField: *, order: *, visibility: boolean}}
     * @private
     */
    _mergeInputFieldGetOne: function(referencesEntity, order, visibility, newField, isdeactivatableKey){
        return {
            key: referencesEntity.get('key'),

            referencesEntity: referencesEntity,
            newField: newField,

            order: order,

            // only set the visibility to none if the user was able to do so
            visibility: !visibility && referencesEntity.get(isdeactivatableKey) ? false : true,
        };
    },
});

module.exports = UserModel;