module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form class="settings-peferences-form" method="post">\n    <header class="content-header is-sticky">\n        <div class="header-part">\n            <ul>\n                <li>\n                    <h2>'+
((__t=(item.name))==null?'':_.escape(__t))+
': '+
((__t=( t('settings::preferences:label') ))==null?'':_.escape(__t))+
'</h2>\n                </li>\n                <li class="actions">\n                    <button type="submit" class="btn btn-primary">\n                        '+
((__t=( t('settings::account:button:update') ))==null?'':_.escape(__t))+
'\n                    </button>\n                </li>\n            </ul>\n        </div>\n    </header>\n\n    <div class="scroll-container">\n        <div class="container">\n            <fieldset class="form-note">\n                <p>'+
((__t=( t('settings::preferences:top-info') ))==null?'':__t)+
'</p>\n            </fieldset>\n\n            <fieldset class="page-container unit-preferences">\n                <h3>'+
((__t=( t('settings::preferences:default-units-and-currencies')))==null?'':__t)+
'</h3>\n                <fieldset>\n                    <label>'+
((__t=(t('settings::preferences:length-unit')))==null?'':_.escape(__t))+
'</label>\n                    <select name="length-unit">\n                        ';
 for (var i = 0; i < App.BaseModule.sizeUnitsCollection.length; i++) { 
__p+='\n                            <option '+
((__t=((App.BaseModule.sizeUnitsCollection.models[i].get("key") === item.userAttributes.defaultSizeUnit ? 'selected' : '')))==null?'':_.escape(__t))+
'\n                                value="'+
((__t=(App.BaseModule.sizeUnitsCollection.models[i].get("key")))==null?'':_.escape(__t))+
'">\n                                '+
((__t=(App.BaseModule.sizeUnitsCollection.models[i].get("name")))==null?'':_.escape(__t))+
'\n                            </option>\n                        ';
 } 
__p+='\n                    </select>\n                </fieldset>\n\n                <fieldset>\n                    <label>'+
((__t=(t('settings::preferences:weight-unit')))==null?'':_.escape(__t))+
'</label>\n                    <select name="weight-unit">\n                        ';
 for (var i = 0; i < App.BaseModule.weightUnitsCollection.length; i++) { 
__p+='\n                            <option '+
((__t=((App.BaseModule.weightUnitsCollection.models[i].get("key") === item.userAttributes.defaultWeightUnit ? 'selected' : '')))==null?'':_.escape(__t))+
'\n                                value="'+
((__t=(App.BaseModule.weightUnitsCollection.models[i].get("key")))==null?'':_.escape(__t))+
'">\n                                '+
((__t=(App.BaseModule.weightUnitsCollection.models[i].get("name")))==null?'':_.escape(__t))+
'\n                            </option>\n                        ';
 } 
__p+='\n                    </select>\n                </fieldset>\n\n                <fieldset>\n                    <label>'+
((__t=(t('settings::preferences:currency')))==null?'':_.escape(__t))+
'</label>\n                    <select name="currency"></select>\n                </fieldset>\n            </fieldset>\n\n            <fieldset class="page-container field-preferences">\n                <h3>'+
((__t=( t('settings::preferences:default-input-fields')))==null?'':__t)+
'</h3>\n\n                <div class="page-col-4">\n                    <ol class="table-columns">\n                        ';
 _.each(model.getEditFields(), function(field){ 
__p+='\n                            <li\n                                data-key="'+
((__t=(field.name))==null?'':_.escape(__t))+
'"\n                                data-deactivatable="'+
((__t=(true))==null?'':_.escape(__t))+
'"\n                                class="dd-item '+
((__t=(when(field.active, 'active')))==null?'':_.escape(__t))+
'"\n                            >\n                                <input\n                                    type="checkbox"\n                                    id="toggle-table-field-'+
((__t=(field.name))==null?'':_.escape(__t))+
'"\n                                    '+
((__t=(when(field.active, 'checked="checked"')))==null?'':_.escape(__t))+
'\n                                    value="'+
((__t=(field.name))==null?'':_.escape(__t))+
'"\n                                />\n                                <label for="toggle-table-field-'+
((__t=(field.name))==null?'':_.escape(__t))+
'">\n                                    '+
((__t=(field.label))==null?'':_.escape(__t))+
'\n                                </label>\n                                <div class="drag-handle"></div>\n\n                                ';
 if(field.type === 'group') { 
__p+='\n                                    <ul>\n                                        ';
 _.each(field.sourceFields, function(sourceFieldName){
__p+='\n                                            ';
 var sourceField = model.getFieldDefinition(sourceFieldName); 
__p+='\n                                            <li\n                                                data-key="'+
((__t=(sourceField.name))==null?'':_.escape(__t))+
'"\n                                                data-deactivatable="'+
((__t=(true))==null?'':_.escape(__t))+
'"\n                                                class="dd-item '+
((__t=(when(!_.contains(field.disabledSourceFields, sourceField.name), 'active')))==null?'':_.escape(__t))+
'"\n                                            >\n                                                <input\n                                                    type="checkbox"\n                                                    id="toggle-table-field-'+
((__t=(sourceField.name))==null?'':_.escape(__t))+
'"\n                                                    '+
((__t=(when(!_.contains(field.disabledSourceFields, sourceField.name), 'checked="checked"')))==null?'':_.escape(__t))+
' value="'+
((__t=(sourceField.name))==null?'':_.escape(__t))+
'"\n                                                />\n                                                <label for="toggle-table-field-'+
((__t=(sourceField.name))==null?'':_.escape(__t))+
'">\n                                                    '+
((__t=(sourceField.editview.label))==null?'':_.escape(__t))+
'\n                                                </label>\n                                            </li>\n                                        ';
 }) 
__p+='\n                                    </ul>\n                                ';
 } 
__p+='\n                            </li>\n                        ';
 }) 
__p+='\n                    </ol>\n                </div>\n\n                <div class="page-col-2">\n                    <fieldset class="form-note">\n                        <p>'+
((__t=( t('settings::preferences:right-info') ))==null?'':__t)+
'</p>\n                    </fieldset>\n                </div>\n            </fieldset>\n\n            <fieldset class="page-container">\n                <div class="page-col-3">\n                    <fieldset class="submit">\n                        <button type="submit" class="btn btn-primary">\n                            '+
((__t=( t('settings::account:button:update') ))==null?'':_.escape(__t))+
'\n                        </button>\n                    </fieldset>\n                </div>\n            </fieldset>\n        </div>\n    </div>\n</form>';
}
return __p;
};