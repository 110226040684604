module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header">\n	<ul>\n		<li>\n			<h2>'+
((__t=(t('collection::objects:filter:headline')))==null?'':_.escape(__t))+
'</h2>\n			<h2 class="subline">\n			    <span id="object-count">'+
((__t=( item.baseCollection.length ))==null?'':__t)+
'</span> '+
((__t=(t('collection::objects:count', item.baseCollection.length)))==null?'':_.escape(__t))+
'\n			</h2>\n		</li>\n		<li class="actions">\n			<button class="btn btn-secondary btn-has-icon btn-icon-right" data-action="close">'+
((__t=(t('collection::objects:filter:button:cancel')))==null?'':_.escape(__t))+
'</button>\n            <button class="btn btn-primary btn-has-icon btn-icon-right" data-action="apply-filters">'+
((__t=(t('collection::objects:filter:button:show')))==null?'':_.escape(__t))+
'</button>\n		</li>\n	</ul>\n    <div class="filters-active"></div>\n</header>\n<div class="modal-body">\n\n    <div class="filters">\n       ';
 for (var i = 0; i < view.components.length; i++) { 
__p+='\n            <section>\n                ';
 for (var j = 0; j < view.components[i].length; j++) { 
__p+='\n                    <div class="'+
((__t=(view.components[i][j].name))==null?'':_.escape(__t))+
'-filter-component-region filter-component-region"></div>\n                ';
 } 
__p+='\n            </section>\n       ';
 } 
__p+='\n    </div>\n</div>\n<aside data-action="close">\n    <div>\n        <div class="result-count"></div>\n        <button type="button" class="btn btn-secondary" data-action="apply-filters">'+
((__t=(t('collection::objects:filter:button:show')))==null?'':_.escape(__t))+
'</button>\n    </div>\n</aside>';
}
return __p;
};