module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="container container-hint">\n';
 if (model.get('search')) { 
__p+='\n    <h1>'+
((__t=( t('collection::objects:list:empty:search') ))==null?'':__t)+
'</h1>\n';
 } else if (model.get('filter')) { 
__p+='\n    ';
 if (!view.getOption('isInfiniteView')) { 
__p+='\n        <p class="message-list-empty">\n            '+
((__t=( t('collection::objects:list:empty:filter') ))==null?'':__t)+
'\n        </p>\n    ';
 } 
__p+='\n';
 } else { 
__p+='\n    ';
 if (!view.getOption('isInfiniteView')) { 
__p+='\n        <p class="message-list-empty">\n            '+
((__t=( t('collection::shop:objects:list:empty') ))==null?'':__t)+
'\n        </p>\n    ';
 } 
__p+='\n';
 } 
__p+='\n</div>';
}
return __p;
};