'use strict';

var ItemView = require('base/Colido.ItemView');

var template = require('modules/collection/templates/partials/change-status-list-item.hbs');

var ChangeStatusListItemView = ItemView.extend({
    template: template,

    wrapItem: false,

    events: {
        'click': 'onClickItem',
    },

    /**
     * Is triggered, when the item is klicked
     * We will notify the layout view with the details
     */
    onClickItem: function() {
        var level = this.$el.parents('.lane').data('level');
        if (this.model.get('hasRecipient')) {
            this.triggerMethod('status:goto', level, this.model);
        } else {
            this.triggerMethod('status:selected', level, this.model);
        }
    },

});

module.exports = ChangeStatusListItemView;