'use strict';

var Marionette = require('backbone-marionette');
var Controller = require('./controller');

var AuthenticationModule = Marionette.Module.extend({
    startWithParent: true,

    onStart: function(options) {
        var controller = this.controller = new Controller({
            app: app,
            module: this,
        });

        App.router.addPublicRoutes([
            '^(' + App.supportedLanguages.join('|') + ')$', // Direct route to a language
            /^login$/,
            /^login\/to\/\S+$/,
            /^forgot-password$/,
            /^forgot-password-token\/\S+$/,
            /^signup$/,
            /^delete-account\/\S+$/,
            /^account-deleted$/,
            /^signup-token\/\S+$/,
        ]);

        App.addInitializer(function() {
            App.router.processAppRoutes(controller, _.result(controller, 'routes'));
        });
    },
});

module.exports = AuthenticationModule;