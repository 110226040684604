'use strict';

var Controller = require('base/Colido.Controller');
var IndexLayout = require('./views/layout/index');
var GettingStartedLayout = require('./views/layout/getting-started');
var ContactLayout = require('./views/layout/contact');

var HelpdeskController = Controller.extend({
    routes: {
        'helpdesk':        'actionIndex',
        'helpdesk/getting-started': 'actionGettingStarted',
        //'contact':                  'actionContact',
        'helpdesk/contact':         'actionContact',
    },

    actionIndex: function() {
        var region = App.rootView.mainRegion.currentView.contentRegion;
        var layout = new IndexLayout();
        region.show(layout);
    },
    actionGettingStarted: function() {
        var region = App.rootView.mainRegion.currentView.contentRegion;
        var layout = new GettingStartedLayout();
        region.show(layout);
    },
    actionContact: function() {
        var region = App.rootView.mainRegion.currentView.contentRegion;
        var layout = new ContactLayout();
        region.show(layout);
    },
});

module.exports = HelpdeskController;