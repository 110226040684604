'use strict';

var Backbone = require('backbone');
var LayoutView = require('base/Colido.LayoutView');
var NavigationSetsDndListView = require('../partials/navigation-sets-dnd-list');
var NavigationTalksDndListView = require('../partials/navigation-talks-dnd-list');
var NavigationProfileView = require('../partials/navigation-profile');
var NavigationCatalogView = require('../partials/navigation-catalog');
var FilteredCollection = require('base/Colido.FilteredCollection');

var template = require('../../templates/layout/navigation.hbs');

var NavigationView = LayoutView.extend({
    template: template,

    events: {
        'click @ui.logo': 'goHome',
        'click .btn-reveal': 'toggleReveal',
        'click @ui.accountSwitcher': 'toggleAccount',
    },

    ui: {
        'setsDndList': '#navigationSets',
        'setsSubnavList': '.sets-subnavigation',
        'logo': '.logo',
        'accountSwitcher': '.account-switcher',
        'accountSubnav': '.account',
    },

    regions: {
        dndTalksContactsRegion: '.navigation-dnd-talks-contacts-region',
        dndTalksGroupsRegion: '.navigation-dnd-talks-groups-region',
        dndTalksBroadcastsRegion: '.navigation-dnd-talks-broadcasts-region',
        profileNavigationRegion: '.profile-navigation-region',
        catalogRegion: '.navigation-catalog-region',
        catalogDropRegion: '.navigation-dnd-sets-region',
    },

    initialize: function(options) {
        LayoutView.prototype.initialize.apply(this, options);

        if (App.CollectionModule) {
            var catalogs = App.CollectionModule.catalogs;

            this.listenTo(catalogs, 'add remove reset', this.setActiveCatalog.bind(this));
            this.listenTo(catalogs, 'change:activeCatalog remove', this.showCatalogChildView.bind(this));
        }

        if (App.CommunityModule) {
            this.listenTo(App.CommunityModule.conversationsCollection, 'add remove reset change:unreadPosts', this.updateUnreadPostsCount);
        }

        if (App.CollectionModule.ShopModule) {
            var shops = App.CollectionModule.ShopModule.shops;

            this.listenTo(shops, 'add remove reset', this.setActiveShop.bind(this));
            this.listenTo(shops, 'add remove reset', this.render.bind(this));
        }
    },

    onRender: function() {
        // Render profile
        this.profileNavigationRegion.show(new NavigationProfileView({
            model: App.user,
        }));

        this.showCatalogChildView();

        // Render talks contacts DND
        if (App.isDataLoaded('community:contacts')) {
            this.showTalkChildView(App.CommunityModule.contactsCollection)
        } else {
            this.listenTo(App.vent, 'community:contacts:load', this.showTalkChildView.bind(this));
        }

        // Render talks groups/broadcasts DND
        if (App.isDataLoaded('community:groups')) {
            this.showTalkChildView(App.CommunityModule.groupsCollection)
        } else {
            this.listenTo(App.vent, 'community:groups:load', this.showTalkChildView.bind(this));
        }

        // Update basic counts
        this.updateUnreadPostsCount();
    },

    toggleAccount: function(event) {
        this.ui.accountSubnav.toggleClass('active')
    },

    onAttach: function() {
        this.listenTo(Backbone.history, 'route', this.handleRouteChange.bind(this));
        this.listenTo(Backbone.history, 'route', this.setActiveCatalog.bind(this));

        if (App.CollectionModule.ShopModule) {
            this.listenTo(Backbone.history, 'route', this.setActiveShop.bind(this));
        }
    },

    showCatalogChildView: function() {
        if (App.CollectionModule.catalogs.length) {
            var navigationCatalogView = new NavigationCatalogView({
                collection: App.CollectionModule.catalogs,
            });

            this.listenTo(navigationCatalogView, 'links:updated', this.handleRouteChange.bind(this));

            this.catalogRegion.show(navigationCatalogView);

            this.catalogDropRegion.show(new NavigationSetsDndListView({
                collection: App.CollectionModule.catalogs.getActiveCatalog().sets,
            }));

            this.handleRouteChange();
        } else {
            this.catalogRegion.empty();
        }
    },

    /**
     * Shows the childview for the dnd talks
     * @param {object} collection The contacts/groups collection
     */
    showTalkChildView: function(collection) {
        if (collection.name === 'community:contacts') {
            this.dndTalksContactsRegion.show(new NavigationTalksDndListView({
                talkType: 'contacts',
                collection: collection,
                filter: function(model) {
                    return model.get('accepted') === 'YES';
                },
            }));
        } else if (collection.name === 'community:groups') {
            this.dndTalksGroupsRegion.show(new NavigationTalksDndListView({
                talkType: 'groups',
                collection: collection,
                filter: function(model) {
                    return model.get('type') === 'normal';
                },
            }));

            this.dndTalksBroadcastsRegion.show(new NavigationTalksDndListView({
                talkType: 'broadcasts',
                collection: collection,
                filter: function(model) {
                    return model.get('type') === 'broadcast';
                },
            }));
        }
    },

    updateUnreadPostsCount: function() {
        if (App.CommunityModule) {
            var count = App.CommunityModule.conversationsCollection.getUnreadPostsCount();
            var element = this.$('.count-unread-posts');
            element.text(count).toggle(!!count);
        }
    },

    handleRouteChange: function() {
        var path = '/' + Backbone.history.fragment;
        var link = this.$('a[href="' + path + '"]').last();
        var pathParts = [];

        //if we don't find a link for the requested url, keep traversing the path up until one is found
        while(link.length < 1) {
            pathParts = path.split('/');
            pathParts.pop();
            path = pathParts.length > 1 ? pathParts.join('/') : '/';

            link = this.$('a[href="' + path + '"]').last();
        }

        this.activateLink(link);
        this.revealLists();
    },

    setActiveCatalog: function() {
        var path = '/' + Backbone.history.fragment;
        var pathTokens = /^\/catalogs\/(\d+)/.exec(path);

        var catalogId = pathTokens && pathTokens.length === 2 ? parseInt(pathTokens[1], 10) : null;
        App.CollectionModule.catalogs.setActiveCatalog(catalogId);
    },

    setActiveShop: function() {
        var path = '/' + Backbone.history.fragment;
        var pathTokens = /^\/shops\/(\w+)/.exec(path);

        var shopId = pathTokens && pathTokens.length === 2 ? pathTokens[1] : null;

        App.CollectionModule.ShopModule.shops.setActiveShop(shopId);
    },

    activateLink: function(link) {
        var li = link.closest('li');

        this.$('li').removeClass('active');

        li.addClass('active');
        link.parentsUntil(this.$el, 'li').addClass('active');
    },

    /**
     * Hides the revealble list, when the section is not active anymore
     */
    revealLists: function() {
        this.$('.revealable').each(function(n, list) {
            list = $(list);

            if (!list.parents('li').hasClass('active')) {
                list.removeClass('is-visible');
            }
        });
    },

    /**
     * Is called from the button, that wants to reveal a list
     */
    toggleReveal: function(event) {
        var $curTarget = $(event.currentTarget);

        if ($curTarget.prev().is('ul')) {
            var $list = $curTarget.prev();

            if ($list.hasClass('is-visible')) {
                $list.removeClass('is-visible');
            } else {
                $list.addClass('is-visible');
            }
        }
    },

    goHome: function(event) {
        $(event.currentTarget).blur();
    },
});

module.exports = NavigationView;