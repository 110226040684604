module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="container container-hint">\n';
 if (model.get('search')) { 
__p+='\n    <h1>'+
((__t=( t('collection::objects:list:empty:search') ))==null?'':__t)+
'</h1>\n';
 } else if (model.get('filter')) { 
__p+='\n    ';
 if (!view.getOption('isInfiniteView')) { 
__p+='\n        <p class="message-list-empty">\n            '+
((__t=( t('collection::objects:list:empty:filter') ))==null?'':__t)+
'\n        </p>\n    ';
 } 
__p+='\n';
 } else { 
__p+='\n    ';
 if (!view.getOption('isInfiniteView')) { 
__p+='\n        <p class="message-list-empty">\n            '+
((__t=( t('collection::objects:list:empty:catalog') ))==null?'':__t)+
'\n        </p>\n    ';
 } 
__p+='\n\n    <div class="list list-empty list-flex">\n        <div class="box box-empty">\n            <a href="/catalogs/'+
((__t=(view.collection.catalog.id))==null?'':_.escape(__t))+
'/new" class="link">\n                <div class="headline-wrapper">\n                    <h4 class="action">'+
((__t=(t('collection::objects:list:add-button')))==null?'':_.escape(__t))+
'</h4>\n                </div>\n                <span class="empty-plus" data-action="add-object">+</span>\n            </a>\n        </div>\n    </div>\n\n';
 } 
__p+='\n</div>';
}
return __p;
};