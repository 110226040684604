'use strict';

var Collection = require('base/Colido.Collection');
var InputFieldsModel = require('./InputFieldsModel');

var InputFieldsCollection = Collection.extend({
    api: '/core/columns',
    name: 'base:collection-input-fields',
    model: InputFieldsModel,
    comparator: function(item) {
        return item.get('order');
    },
});

module.exports = InputFieldsCollection;