'use strict';

var Marionette = require('backbone-marionette');
var Controller = require('./controller');
var Wreqr = require('./wreqr');
var ContactsCollection = require('./collections/contacts');
var GroupsCollection = require('./collections/groups');
var ConversationsCollection = require('./collections/conversations');

var CommunityModule = Marionette.Module.extend({
    startWithParent: true,

    onStart: function(options) {
        var app = this.app;

        this.contactsCollection = new ContactsCollection();
        this.groupsCollection = new GroupsCollection();
        this.conversationsCollection = new ConversationsCollection();

        this.listenTo(app.vent, 'logged-in', function(){
            app.loadData(this.contactsCollection);
            app.loadData(this.groupsCollection);
            app.loadData(this.conversationsCollection);
        }.bind(this));

        var controller = this.controller = new Controller({
            app: app,
            module: this,
        });

        this.wreqr = new Wreqr({
            app: app,
            module: this,
        });

        app.addInitializer(function(){
            app.router.processAppRoutes(controller, controller.routes);
        }.bind(this));
    },
});

module.exports = CommunityModule;