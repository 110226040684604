'use strict';

var LayoutView = require('base/Colido.LayoutView');
var SearchView = require('modules/global/views/layout/search');
var ShowroomOptionsView = require('modules/collection/views/partials/showroom-options');
var ViewSwitchView = require('modules/collection/views/partials/view-switch');

var template = require('modules/collection/templates/layout/header.hbs');

var ShopHeaderView = LayoutView.extend({
    template: template,

    collectionEvents: {
        'add remove reset sync': 'render',
    },

    regions: {
        viewSwitchRegion: '.view-switch-region',
        showroomOptionsRegion: '.showroom-options-region',
        searchRegion: '.search-region',
    },

    /**
     *
     * @returns {string} Unescaped Text
     */
    getHeadline: function () {
        var shop = App.CollectionModule.ShopModule.shops.getActiveShop();

        var headline = App.t('collection::shop:objects:list:headline', {typeName: shop.get('typeName')});
        if (!this.options.search && this.model.get('search') && this.model.get('search').length > 0) {
            headline = App.t('collection::search:headline:result-detailpage', {
                keyword: this.model.get('search').replace(/[,]+/g, ' '),
            });
        }

        return headline;
    },

    /**
     *
     * @returns {string} Unescaped Text
     */
    getSubline: function () {
        return App.t('global::objects:count', this.collection.length);
    },

    getBackUrl: function() {
        return this.model.getPath();
    },

    onRender: function() {
        var viewOptions = _.extend(this.options, {
            model: this.model,
            collection: this.collection,
        });

        if (this.$('.showroom-options-region').length) {
            this.showroomOptionsRegion.show(new ShowroomOptionsView(viewOptions));
        }

        if (this.$('.view-switch-region').length) {
            this.viewSwitchRegion.show(new ViewSwitchView(viewOptions));
        }

        if (this.$('.search-region').length) {
            this.searchRegion.show(new SearchView(_.extend({searchTerms: this.getOption('searchTerms')}, viewOptions)));
        }
    },
});

module.exports = ShopHeaderView;