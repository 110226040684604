'use strict';

var ItemView = require('base/Colido.ItemView');
var template = require('modules/community/templates/partials/conversation-post-offer-process.hbs');

var ConversationPostOfferProcessView = ItemView.extend({
    template: template,

    shop: null,

    object: null,

    ui: {
        'actionCompleteSale': '[data-action="complete-sale"]',
        'actionDeclineOffer': '[data-action="decline-offer"]',
        'actionWithdrawOffer': '[data-action="withdraw-offer"]',
    },

    events: {
        'click @ui.actionCompleteSale': 'actionCompleteSale',
        'click @ui.actionDeclineOffer': 'actionDeclineOffer',
        'click @ui.actionWithdrawOffer': 'actionWithdrawOffer',
    },

    modelEvents: {
        'change': 'render',
    },

    className: function() {
        return 'offer-process';
    },

    viewData: {
        object: function() {
            return this.object;
        },

        headline: function() {
            var headline = '';

            if (this.shop) {
                var fieldName = this.shop.get('fieldsGridAttributeMap').headline;
                headline = this.object.getMergedTextValues(fieldName)
            }

            return headline;
        },

        subheadline: function() {
            var subheadline = '';

            if (this.shop) {
                var fieldName = this.shop.get('fieldsGridAttributeMap').subheadline;
                subheadline = this.object.getMergedTextValues(fieldName);
            }

            return subheadline;
        },

        conversation: function() {
            return this.getOption('conversation');
        },
    },

    initialize: function() {
        ItemView.prototype.initialize.apply(this, arguments);

        this.object = this.getOption('objectModel');
        this.shop = this.getOption('shop');
    },

    actionCompleteSale: function(event) {
        this.model.save({
            status: 'completed',
        }, {
            patch: true,
        });
    },

    actionDeclineOffer: function(event) {
        this.model.save({
            status: 'declined',
        }, {
            patch: true,
        });
    },

    actionWithdrawOffer: function(event) {
        this.model.save({
            status: 'withdrawn',
        }, {
            patch: true,
        });
    },
});

module.exports = ConversationPostOfferProcessView;