module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header modal-buttons-right">\n    <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=(t('settings::account:delete:cancel')))==null?'':_.escape(__t))+
'</button>\n    <h2>'+
((__t=(t('settings::account:delete:headline')))==null?'':_.escape(__t))+
'</h2>\n</header>\n<div class="modal-body">\n    <fieldset class="form-note">\n        <p>'+
((__t=(t('settings::account:delete:info')))==null?'':_.escape(__t))+
'</p>\n    </fieldset>\n\n    <fieldset class="form-account-delete-field">\n        <button class="btn btn-primary" data-action="delete">'+
((__t=(t('settings::account:delete:button')))==null?'':_.escape(__t))+
'</button>\n    </fieldset>\n</div>';
}
return __p;
};