module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="'+
((__t=(item.closeUrl))==null?'':_.escape(__t))+
'" class="close">'+
((__t=( icon('close-light') ))==null?'':__t)+
' '+
((__t=(t('global::modal:button:close')))==null?'':_.escape(__t))+
'</a>\n<div class="object-details-region"></div>';
}
return __p;
};