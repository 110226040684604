module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="modal-header">\n    <h2>'+
((__t=( t('community::share-conversation:modal:headline') ))==null?'':_.escape(__t))+
'</h2>\n    <button type="button" class="btn btn-secondary" data-action="close">'+
((__t=( t('global::modal:button:close')))==null?'':_.escape(__t))+
'</button>\n</header>\n\n<div class="modal-body conversations-region"></div>';
}
return __p;
};